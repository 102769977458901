import * as React from 'react';
const SvgStore = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 36 36"
    {...props}
  >
    <path
      fill="#FFA44F"
      stroke="#FFA44F"
      strokeWidth={0.75}
      d="M8.66 18.75a5.383 5.383 0 0 0 4.642-2.701c.201.358.443.691.72.994a5.486 5.486 0 0 0 7.955 0c.278-.303.52-.636.721-.994a5.375 5.375 0 0 0 9.927-3.746l-1.408-7.038a3.758 3.758 0 0 0-3.676-3.015H8.459a3.758 3.758 0 0 0-3.676 3.015l-1.408 7.038A5.389 5.389 0 0 0 8.66 18.75Zm18.88-15a2.255 2.255 0 0 1 2.207 1.809l1.407 7.039a3.89 3.89 0 1 1-7.695 1.021l-.04-.616v-.001l-.617-9.252h4.739Zm-12.838 0h6.597l.622 9.352a3.849 3.849 0 0 1-1.04 2.915 3.903 3.903 0 0 1-2.922 1.233 3.89 3.89 0 0 1-3.88-4.148L14.7 3.75Zm-9.856 8.848 1.407-7.04A2.255 2.255 0 0 1 8.46 3.75h4.739l-.617 9.252v.001l-.04.616a3.89 3.89 0 1 1-7.695-1.021Zm.404 7.594V30A3.754 3.754 0 0 0 9 33.75h18A3.754 3.754 0 0 0 30.75 30v-9.808a7.59 7.59 0 0 1-1.5.558V30A2.253 2.253 0 0 1 27 32.25h-5.25V27a3.75 3.75 0 0 0-7.5 0v5.25H9A2.253 2.253 0 0 1 6.75 30v-9.25a7.59 7.59 0 0 1-1.5-.558ZM15.75 27a2.25 2.25 0 1 1 4.5 0v5.25h-4.5V27Z"
    />
  </svg>
);
export default SvgStore;
