import React, { createContext, ReactNode, useState } from 'react';

const AuthContext = createContext({});

interface Props {
    children?: ReactNode,
};

/**
 * Provide an authentication and authorization client for all sub-components
 * in the React application.
 * @param children - all children of the authentication provider.
 * @returns JSX Element.
 */
export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [auth, setAuth] = useState({});

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
