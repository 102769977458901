import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import DataTable from 'components/DataTable/DataTable';
import { Button, Drawer } from 'components';
import AlertInline from 'components/AlertInline/AlertInline';

// SELECTORS
import { getIsDataUpdateOpen } from 'store/modules/accounts/accounts.selector';

// ACTIONS
import { setIsDataUpdateOpen } from 'store/modules/accounts/accounts.slice';

// ASSETS
import styles from './DataUpdateDrawer.module.scss';

// HELPERS
import { columns, data } from './DataUpdateDrawer.helpers';

const DataUpdateDrawer = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsDataUpdateOpen);
  const fileName = localStorage.getItem('uploadedFile');

  // EVENTS
  const handleClose = () => {
    dispatch(setIsDataUpdateOpen(false));
  };

  const handleUpdateSystem = () => {
    dispatch(setIsDataUpdateOpen(false));
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      size="lg"
      onClose={handleClose}
      title="Update system"
    >
      <AlertInline
        alertMessage="Preview values shown below.  Please review for accuracy of mapping and completeness before submitting update."
        icon
        type="warning"
      />
      <p className={styles['file-name']}>{fileName || '123456789-2032-05-15.xlsx'}</p>
      <DataTable columns={columns as any} data={data} />
      <div className={styles.footer}>
        <Button type="secondary" copy='Cancel' size="md" />
        <Button copy='Update system' size="md" onClick={handleUpdateSystem} />
      </div>
    </Drawer>
  );
};

export default DataUpdateDrawer;
