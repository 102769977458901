import React from 'react';
import Text from '../components/Text/Text';

const TempEmpowerPage: React.FC = () => {
  return (
    <div className='mar--l-100 mar--t-100'>
      <Text>
        404
      </Text>
    </div>
  );
};

export default TempEmpowerPage;
