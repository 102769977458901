import React from 'react';
import { useSelector } from 'react-redux';

// COMPONENTS
import { Box, Tabs } from 'components';
import { IEvent } from 'api/events/events.typings';
import Changelog from '../Changelog/Changelog';
import { ListEvents } from 'pages/Accounts/components';
import EventsFilterPanel from '../EventsFilterPanel/EventsFilterPanel';
import FilterSearchEvents from '../FilterSearchEvents/FilterSearchEvents';

// SELECTORS
import { getEventsFilterOpen } from 'store/modules/events/events.selector';

// ASSETS
import styles from './EventsTabs.module.scss';

// HELPERS
import { useListWidth } from 'hooks/useListWidth';

interface EventsTabsProps {
  events: IEvent[];
}

const EventsTabs:React.FC<EventsTabsProps> = ({ events }) => {
  const isFilterOpen = useSelector(getEventsFilterOpen);
  const listWidth = useListWidth(isFilterOpen);

  const tabs = [
    {
      content: (
        <div className='mar--t-16'>
          <FilterSearchEvents/>
          <div id="mainListArea" className={styles.main}>
            <EventsFilterPanel/>
            <Box width={listWidth}>
              <ListEvents events={events} aria-label="List of Events" />
            </Box>
          </div>
        </div>
      ),
      label: 'Events'
    },
    {
      content: (
        <div>-</div>
      ),
      label: 'Monitoring'
    },
    {
      content: (
        <Changelog/>
      ),
      label: 'Changelog'
    }
  ];

  return (
    <Tabs tabs={tabs} />
  );
};

export default EventsTabs;
