import React from 'react';
import classnames from 'classnames';

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(
  // eslint-disable-next-line react/prop-types
  ({ className, ...props }, ref) => (
    <tbody
      ref={ref}
      className={classnames('[&_tr:last-child]:border-0', className)}
      {...props}
    />
  ));
TableBody.displayName = 'TableBody';

export default TableBody;
