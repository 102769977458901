import React from 'react';
import classnames from 'classnames';
import { Column } from '@tanstack/react-table';

// ASSETS
import styles from './TableCell.module.scss';

interface TableCellProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
  column: Column<any, any>;
}

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  TableCellProps
>(
  // eslint-disable-next-line react/prop-types
  ({ className, ...props }, ref) => {
    // STYLES
    const cssTableCell = classnames(
      styles['table-cell'],
      className,
      {
        [styles.pinned]: props.column.getIsPinned(),
        [styles.pinned__left]: props.column.getIsPinned() === 'left',
        [styles.pinned__right]: props.column.getIsPinned() === 'right'
      }
    );

    const stylesObj: any = {
      maxWidth: props.column.columnDef.maxSize
    };

    if ((props.column.columnDef.meta as any)?.customWidth) {
      stylesObj.width = (props.column.columnDef.meta as any)?.customWidth;
    }

    return (
      (
        <td
          ref={ref}
          className={cssTableCell}
          style={stylesObj}
          {...props}
        />
      )
    );
  });
TableCell.displayName = 'TableCell';

export default TableCell;
