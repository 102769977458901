import React from 'react';

// COMPONENTS
import CitadelUsers from 'pages/CitadelUsers';
import CitadelHomepage from 'pages/CitadelHomepage';
import PlatformConfiguration from 'pages/PlatformConfiguration/PlatformConfiguration';
import Text from 'components/Text/Text';

interface Props {
    name: string;
}

const renderTenateMgnt = (name: string) => {
  return (
    <div>
      <div
        className='mar--b-48'
      >
        <Text appearance='title-sm' color='stone'>{name}</Text>
      </div>
    </div>
  );
};

const PlatformLayout: React.FC<Props> = ({ name }) => {
  let renderComponent;
  switch (name) {
  case 'Applications':
    renderComponent = <CitadelHomepage />;
    break;
  case 'User Management':
    renderComponent = <CitadelUsers />;
    break;
  case 'Tenant Management':
    renderComponent = renderTenateMgnt(name);
    break;
  case 'Tenant Onboarding':
    renderComponent = <PlatformConfiguration name={'Tenant Onboarding'} />;
    break;
  }

  return (
    <div>
      {renderComponent}
    </div>
  )
  ;
};

export default PlatformLayout;
