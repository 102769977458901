
import React, { useState } from 'react';

import primeNaveData from 'components/PrimeNavigation/primeNav.json';

// COMPONENTS
import HeaderNav from 'components/HeaderNav/HeaderNav';
import PrimeNav from 'components/PrimeNavigation/PrimeNavigation';
import SubNav from 'components/SubNav/SubNav';
import PlatformLayout from 'pages/PlatformLayout/PlatformLayout';
import { Breadcrumb } from 'components';

import './platform.scss';

interface PlatformProps {}

const Platform: React.FC<PlatformProps> = () => {
  const [isExpanded, setExpanded] = useState(true);
  const [activeState, setActiveNav] = useState(0);
  const [subNavItems, setSubNavItem] = useState(0);
  const {
    navItems
  } = primeNaveData;
  const subNav = navItems[activeState].subNav;
  const breadcrumbs = navItems[activeState].breadcrumbs;

  return (
    <div
      style={{ height: '100%' }}
    >
      <HeaderNav />
      <PrimeNav
        navItems={primeNaveData.navItems}
        isExpanded={isExpanded}
        setExpanded={setExpanded}
        activeState={activeState}
        setActiveNav={setActiveNav}
        setSubNavItem={setSubNavItem}
        subNav={subNav}
      />
      <div className='flex' style={{ height: '100%' }}>
        {subNav
          ? (
            <SubNav
              title={primeNaveData.navItems[activeState].displayTitle}
              subNavItems={subNav}
              isExpanded={isExpanded}
              setSubNavItem={setSubNavItem}
              setExpanded={setExpanded}
            />
          )
          : null}
        <div className={`${!subNav && 'mar--l-96'} pad--l-24 pad--t-24 width--100-p mar--r-24`}>
          { breadcrumbs
            ? (
              <Breadcrumb
                withRouter={true}
                items={[
                  {
                    title: 'Home',
                    bold: true,
                    href: '/platform/admin'
                  },
                  {
                    title: subNav ? subNav[subNavItems].title : navItems[activeState].title
                  }
                ]}
              />
            )
            : (
              <div>
                <PlatformLayout name={navItems[activeState].title} />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default Platform;
