import { createSlice } from '@reduxjs/toolkit';

const initialAnalyze = {
  layout: {
    isUpdateSystemOpen: false,
    isDataUpdateOpen: false
  },
  updateSystemFile: {} as File
};

const projectSlice = createSlice({
  name: 'PROJECT',
  initialState: initialAnalyze,
  reducers: {
    openUpdateSystem: (state) => {
      state.layout.isUpdateSystemOpen = true;
    },
    closeUpdateSystem: (state) => {
      state.layout.isUpdateSystemOpen = false;
    },
    openDataUpdate: (state) => {
      state.layout.isDataUpdateOpen = true;
    },
    closeDataUpdate: (state) => {
      state.layout.isDataUpdateOpen = false;
    },
    setUpdateSystemFile: (state, action) => {
      state.updateSystemFile = action.payload;
    }
  }
});

export const {
  openUpdateSystem,
  closeUpdateSystem,
  openDataUpdate,
  closeDataUpdate,
  setUpdateSystemFile
} = projectSlice.actions;

export default projectSlice.reducer;
