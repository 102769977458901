import { useQuery } from '@tanstack/react-query';

// DATA
import dataBillingHistory from './mocks/account-billing-history.json';
import dataBilling from './mocks/billing.json';

// TYPINGS
import { DataQueryParam } from 'api/helpers/api.typings';
import { IBilling } from './billing.typings';

// HELPERS
import { filterQueryData } from 'api/helpers/filterQueryData';

export const useBilling = (queryParams: DataQueryParam<IBilling>) => {
  return useQuery({
    queryKey: ['billing'],
    queryFn: () => {
      return dataBilling;
    },
    select: filterQueryData(queryParams, 'serviceAgreement')
  });
};

export const useSingleBill = (billId: string | number) => {
  return useQuery({
    queryKey: ['single-bill'],
    queryFn: () => {
      if (billId) {
        return dataBilling.filter((bill) => bill.billId === Number(billId))[0];
      }

      return [];
    }
  });
};

export const useBillingHistory = () => {
  return useQuery({
    queryKey: ['billing-history'],
    queryFn: () => {
      return dataBillingHistory;
    }
  });
};
