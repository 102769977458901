export const buildTimeOptions = () => {
  const timeOptions = [];
  for (let i = 0; i < 24; i++) {
    // For the label, use '00' for midnight, otherwise format the hour as before
    const labelHour = i === 0 ? '00' : (i % 12 === 0 ? 12 : i % 12);
    const period = i < 12 ? 'am' : 'pm';
    timeOptions.push({
      value: `${i < 10 ? '0' : ''}${i}:00`, // Keeps the same
      label: `${labelHour}:00${period}` // Keeps the label format as before
    });
  }

  // Manually add the 24:00 option after the loop
  timeOptions.push({
    value: '24:00', // Adds the 24:00 value
    label: '12:00pm' // The label for 24:00 could be considered as the start of a new day
  });

  return timeOptions;
};
