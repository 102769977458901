import React from 'react';

// ASSETS
import styles from '../../ConfigurationPage.module.scss';
import { getModifierIcon } from 'widget/ExploreWidget/helpers/utils/getModifierIcon';
import { Modifier } from 'widget/ExploreWidget/helpers/types';

const ModifiersIcons = () => {
  const modifiers: Modifier[] = ['solar', 'ev', 'battery', 'heatPump', 'waterHeater', 'dryer', 'range'];

  return (
    <div className={styles.modifierIcons}>
      {
        modifiers.map((modifier, index) => {
          return (
            <div key={modifier}>
              <img src={getModifierIcon(modifier)} alt="modifier icon"/>
            </div>
          );
        })
      }
    </div>
  );
};

export default ModifiersIcons;
