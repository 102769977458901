import React from 'react';

// COMPONENTS
import { EventHandlerDrawer, EventsTabs } from './components';

// ASSETS
import styles from './EventsList.module.scss';

// HELPERS
import { useEvents } from 'api/events/events.api';
import { useSelector } from 'react-redux';
import { getEventsFilters, getEventsSearchString, getEventsSortBy } from 'store/modules/events/events.selector';

const EventsList = () => {
  const searchString = useSelector(getEventsSearchString);
  const filters = useSelector(getEventsFilters);
  const { key: sortKey, ascending } = useSelector(getEventsSortBy);

  const { data: eventsData, isLoading } = useEvents({ filters, searchString, sortKey, orderAscending: ascending });

  return (
    <div className={`${styles['events-page']} admin--page pad--t-128 pad--l-128 pad--r-36`} role="main">
      <div className={styles.main}>
        <div className={styles.header}>
          <h3>Exceptions Management</h3>
        </div>
        {(!isLoading && eventsData) && (
          <>
            <EventsTabs events={eventsData} />
            <EventHandlerDrawer/>
          </>
        )}
      </div>
    </div>
  );
};

export default EventsList;
