import React from 'react';
import { useParams } from 'react-router-dom';

// COMPONENTS
import { Breadcrumb } from '../../../../components';
import { RateDetailsHeader, RateDetailsInfo, RatePrices, Seasons, TimeOfUse } from './components';
// import { FilterSearchBar } from '../../../../blocks/components';
// import Text from '../../../../components/Text/Text';
// import { ListRates } from './components';

// API
import { useRates } from '../../../../api/analyze/analyze.api';
import { IRate } from '../../../../api/analyze/analyze.typings';

// ASSETS
import styles from './RateDetails.module.scss';

const Rates = () => {
  const { id } = useParams();
  const { data, isLoading } = useRates({ rateId: Number(id) });
  const rate = data as IRate;

  return (
    <div className={'admin--page pad--t-128 pad--l-128 pad--r-36 pad--b-128'} role="main">
      {
        (!isLoading && rate) && (
          <>
            <div className="pad--b-24">
              <Breadcrumb
                withRouter={true}
                items={[
                  {
                    title: 'Home',
                    bold: true
                  },
                  {
                    title: 'Rates',
                    href: '/analyze/rates/'
                  },
                  {
                    title: rate.name
                  }
                ]}
              />
            </div>
            <div className={styles.header}>
              <RateDetailsHeader rate={rate} />
            </div>
            <div className={styles.section}>
              <RateDetailsInfo rate={rate} />
            </div>
            <div className={styles.section}>
              <RatePrices rate={rate} />
            </div>
            <div className={styles.section}>
              <TimeOfUse rate={rate} />
            </div>
            <div className={styles.section}>
              <Seasons rate={rate} />
            </div>
          </>
        )
      }
    </div>
  );
};

export default Rates;
