import React from 'react';
import classnames from 'classnames';

// ASSETS
import styles from './AttributeTag.module.scss';
import RateThumb from '../../assets/rates-thumb.png';

// HELPERS
import { getModifierIcon } from 'widget/ExploreWidget/helpers/utils/getModifierIcon';
import { Modifier } from 'widget/ExploreWidget/helpers/types';

interface AttributeTagProps {
  type?: 'modifier' | 'rate';
  modifier?: Modifier
  label: string;
}

const AttributeTag:React.FC<AttributeTagProps> = ({ type = 'modifier', modifier = 'solar', label }) => {
  const cssContainer = classnames(styles.container, {
    [styles.modifier]: type === 'modifier',
    [styles.rate]: type === 'rate'
  });

  return (
    <div className={cssContainer}>
      <img src={type === 'rate' ? RateThumb : getModifierIcon(modifier)} alt="modifier icon" />
      <p>{label}</p>
    </div>
  );
};

export default AttributeTag;
