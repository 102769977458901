import { RootState } from '../../configureStore';

export const getFiltersSearch = (state: RootState) => {
  const { filters, searchString } = state.analyze;

  return {
    filters,
    searchString
  };
};

export const getIsFilterOpen = (state: RootState) => state.analyze.layout.isFilterOpen;

export const getRecentSearches = (state: RootState) => state.analyze.recentSearches;
