import React, { Fragment } from 'react';

import Button from '../../components/Button/Button';
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox';
import TextInput from '../../components/TextInput/TextInput';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import DatePicker from '../../components/DatePicker/DatePicker';
import Filter from '../../components/Filter/Filter';
import Header from '../../components/Header/Header';
import Link from '../../components/Link/Link';
import Tag from '../../components/Tag/Tag';
import Text from '../../components/Text/Text';

import './filters-page.scss';
import { TagColor } from 'components/Tag/Tag.helpers';

const FiltersPage: React.FC = () => {
  const renderFilterListDemo = (long: boolean | undefined) => {
    const renderTags = (color: string | undefined, copy: string | undefined) => {
      return (
        <div className='pad--b-16 flex'>
          <CustomCheckbox className='mar--r-12' id={`${color}-my-checkbox`} />
          <Tag
            color={color as any}
            copy={copy}
          />
        </div>
      );
    };

    if (long) {
      return (
        <div
          className='bor--1 bor--r-4 mar--l-0 mar--t-8 pad--l-16 pad--r-16 pad--t-16 filter--dropdown'
        >
          <Text className='pad--b-12 pad--t-12 title--md'>
            Filters
          </Text>
          <Text className='bor--2-t-wave pad--b-12 pad--t-12 title--sm'>
            Level
          </Text>
          {renderTags('relax', 'Exception')}
          {renderTags('beacon', 'Warning')}
          {renderTags('cloud', 'Information')}
          <Text className='pad--b-12'>Clear filter</Text>
          <Text className='bor--2-t-wave pad--b-12 pad--t-12 title--sm'>
            Assignee
          </Text>
          {renderTags('stem', 'Resolved')}
          {renderTags('ocean', 'On hold')}
          {renderTags('stone', 'Created')}
          <Text className='pad--b-12'>Clear filter</Text>
          <Text className='bor--2-t-wave pad--b-12 pad--t-12 title--sm'>
            Date range
          </Text>
          <DatePicker className='pad--b-8' />
          <Text className='pad--b-12'>Clear all filters</Text>
          <Button
            className='mar--b-8'
            copy='Button'
            color='magma'
            size='sm'
          />
        </div>
      );
    } else {
      return (
        <div
          className='bor--1 bor--r-4 mar--l-0 mar--t-8 pad--l-16 pad--r-16 pad--t-16 filter--dropdown'
        >
          {renderTags('relax', 'Exception')}
          {renderTags('beacon', 'Warning')}
          {renderTags('cloud', 'Information')}
        </div>
      );
    }
  };

  const renderFpoList = () => {
    const listMap = [1, 2, 3, 4, 5].map((item) => {
      return (
        <li key={item} >FPO item</li>
      );
    });

    return (
      <ul className='fpo--list'>
        {listMap}
      </ul>
    );
  };

  return (
    <Fragment>
      <Header
        className='design-studio pad--b-96 pad--l-128 pad--t-100'
        color='stone'
        text='Filters'
      >
        <div>
          <Text
            className='max-width--900 pad--t-48 title--sm'
            color='white'
          >
          Filters provide a set of controls to reduce items in a collection based on attributes the user is interested in.
          </Text>
        </div>
        <Link
          className='underline dis--block display-heading--sm mar--t-88 body--xl'
          color='white'
          copy='See Our Guidelines'
          href='/'
        />
      </Header>
      <div
        className='mar--l-96 mar--t-96 max-width--lg temp--min-width--md'
      >
        <div
          className='pad--b-400'
        >
          <div>
            <Text
              className='title--sm pad--b-28'
            >
              Filter button
            </Text>
            <Text
              className='pad--b-36 body--lg'
            >
              Conceal filter options in a button to reduce visual clutter. When filter options are limited  it is better to list all options instead of concealing it within a button.
            </Text>
            <Text
              className='pad--b-24 title--sm'
            >
              Inactive
            </Text>
            <Text
              className='pad--b-36 body--lg'
            >
              Secondary style/type button when clicked opens a list of filter options in a popover.
            </Text>
            <Filter
              active
              className='my-filter mar--b-32'
              id='filter--one'
              label='Filters'
              numberOfFilters={2}
            />
            <Text
              className='pad--b-24 title--sm'
            >
              Active
            </Text>
            <Text
              className='pad--b-36 body--lg'
            >
              When one or more filters are active, the button becomes primary and contains an indicator on the amount of filters applied.
            </Text>
            <Filter
              className='my-filter mar--b-32'
              id='filter--two'
              numberOfFilters={2}
            />
            <div>
              <Text
                className='title--sm pad--b-28'
              >
                Sort button
              </Text>
              <Text
                className='pad--b-36 body--lg'
              >
                ...
              </Text>
              <Text
                className='pad--b-24 title--sm'
              >
                Inactive
              </Text>
              <Text
                className='pad--b-36 body--lg'
              >
                Secondary style/type button when clicked opens a list of sort options in a popover.
              </Text>
              <Filter
                className='my-sort  mar--b-32'
                id='filter--three'
                label='Sort'
                numberOfFilters={2}
                sort
              />
              <Text
                className='pad--b-24 title--sm'
              >
                Active
              </Text>
              <Text
                className='pad--b-36 body--lg'
              >
                When sort is active, the button becomes primary and the icon indicates the sort ordering.
              </Text>
              <Filter
                active
                className='my-sort mar--b-32'
                id='filter--four'
                label='Sort'
                numberOfFilters={2}
                sort
              />
            </div>
            {/* ---------------------------------------- */}
            <div>
              <Text
                className='title--sm pad--b-28'
              >
                List or grid view - Active filter
              </Text>
              <div className='flex'>
                <TextInput
                  className='bor--r-1 pad--r-16'
                  placeholder='Search - Add search icon'
                  size="md"
                />
                <Filter
                  className='mar--b-32 mar--l-16'
                  id='filter--five'
                  label='Filters'
                  numberOfFilters={2}
                  size='sm'
                >
                  {renderFilterListDemo(true)}
                </Filter>
                <Filter
                  className='mar--b-32 mar--l-16'
                  id='filter--six'
                  label='Sort'
                  size='sm'
                  sort
                >
                  {renderFilterListDemo(true)}
                </Filter>
                <CustomSelect
                  options={[{
                    value: 1,
                    label: 'Actions'
                  }, {
                    value: 2,
                    label: 'Actions'
                  }, {
                    value: 3,
                    label: 'Actions'
                  }]}
                  size='md'
                  width='auto'
                />
              </div>
              {renderFpoList()}
            </div>
            {/* ---------------------------------------- */}
            <div>
              <Text
                className='title--sm pad--b-28'
              >
                List or grid view - Inline sheet
              </Text>
              <div className='flex'>
                <Filter
                  className='mar--r-16 mar--b-32'
                  id='filter--7'
                  label='Filters'
                  numberOfFilters={2}
                  size='sm'
                >
                  {renderFilterListDemo(true)}
                </Filter>
                <TextInput
                  className='bor--r-1 pad--r-16'
                  placeholder='Search'
                  size="md"
                />
                <Filter
                  className='mar--b-32 mar--l-16'
                  id='filter--8'
                  label='Sort by'
                  size='sm'
                  sort
                >
                  {renderFilterListDemo(true)}
                </Filter>
                <CustomSelect
                  options={[{
                    value: 1,
                    label: 'Actions'
                  }, {
                    value: 2,
                    label: 'Actions'
                  }, {
                    value: 3,
                    label: 'Actions'
                  }]}
                  size='sm'
                  width='auto'
                />
              </div>
              {renderFpoList()}
            </div>
            {/* ---------------------------------------- */}
            <div>
              <Text
                className='title--sm pad--b-28'
              >
                List or grid view
              </Text>
              <div className='flex'>
                <TextInput
                  className='bor--r-1 pad--r-16'
                  placeholder='Search'
                  size="md"
                />
                <Text
                  className='body--lg pad--b-28'
                >
                Filters
                </Text>
                <Filter
                  className='mar--r-16 mar--b-32'
                  id='filter--9'
                  label='Level'
                  numberOfFilters={1}
                  size='sm'
                >
                  {renderFilterListDemo(false)}
                </Filter>
                <Filter
                  className='mar--r-16 mar--b-32'
                  id='filter--11'
                  label='Status'
                  numberOfFilters={1}
                  size='sm'
                >
                  {renderFilterListDemo(false)}
                </Filter>
                <Filter
                  className='mar--r-16 mar--b-32'
                  id='filter--10'
                  label='Assignee'
                  numberOfFilters={1}
                  size='sm'
                >
                  {renderFilterListDemo(false)}
                </Filter>
                <Filter
                  className='mar--b-32 mar--l-16'
                  id='filter--12'
                  label='Sort by'
                  size='sm'
                  sort
                >
                  {renderFilterListDemo(false)}
                </Filter>
                <CustomSelect
                  options={[{
                    value: 1,
                    label: 'Actions'
                  }, {
                    value: 2,
                    label: 'Actions'
                  }, {
                    value: 3,
                    label: 'Actions'
                  }]}
                  size='sm'
                  width='auto'
                />
              </div>
              {renderFpoList()}
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
};

export default FiltersPage;
