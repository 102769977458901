import React from 'react';
import { Box as MuiBox, BoxProps } from '@mui/system';

const Box: React.FC<BoxProps> = (props) => {
  return (
    <MuiBox {...props}>{props.children}</MuiBox>
  );
};

export default Box;
