import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// COMPONENTS
import ExploreNav from './components/ExploreNav/ExploreNav';
import ExploreWidget from 'widget/ExploreWidget/ExploreWidget';

// HELPERS
import { ThemeProvider } from 'hooks/Theme/Theme.context';
import { getCMSJsonUrl, getTheme } from './ExplorePage.helpers';

interface ExplorePageProps {
  theme?: string;
}

const ExplorePage:React.FC<ExplorePageProps> = ({ theme = '' }) => {
  const { theme: themeRoute } = useParams();
  const currentTheme = themeRoute || theme;

  const [cmsData, setCmsData] = useState(null);
  const [cmsJsonUrl, setCmsJsonUrl] = useState(getCMSJsonUrl(currentTheme));

  const contractId = '8511445809';

  useEffect(() => {
    fetch(cmsJsonUrl)
      .then(response => {
        return response.json();
      })
      .then(data => {
        setCmsData(data);
      })
      .catch(error => {
        console.log('Error fetching cms data:', error.message);
      });
  }, [cmsJsonUrl]);

  useEffect(() => {
    fetch('https://explore-pge-dev-api.gridx.com/api/generate-token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        contractId
      })
    })
      .then(res => res.json())
      .then(response => {
        const token = response.token;
        sessionStorage.setItem('token', `${token}`);
      });
  }, []);

  return (
    <div>
      <ThemeProvider theme={getTheme(currentTheme)}>
        <ExploreNav
          onCmsChange={setCmsJsonUrl}
          company={currentTheme}
          menu={[
            {
              label: 'My Account',
              selected: false,
              href: `/${currentTheme}/rates-design`
            },
            {
              label: 'Billing',
              selected: false
            },
            {
              label: 'Usage',
              selected: false,
              href: `/${currentTheme}/usage`
            },
            {
              label: 'Rates & Programs',
              selected: false,
              href: `/${currentTheme}/rates-comparison`
            },
            {
              label: 'Explore',
              selected: true,
              href: `/${currentTheme}/explore`
            }
          ]}
        />
        <ExploreWidget
          content={cmsData}
          contractId={contractId}
          apiUrl={process.env.REACT_APP_EXPLORE_API as string}
        />
      </ThemeProvider>
    </div>
  );
};

export default ExplorePage;
