import React, { useEffect, useState } from 'react';
import { Card, Text } from '@gridx/metronome-components';
import { PricingData } from './RealTimeData.helpers';

interface PricingCurrentPriceProps {
  pgeChartColors: string[]
  currentHour: string
  isMobile: boolean
  data: PricingData[] | undefined
}

export const PricingCurrentPrice = (props: PricingCurrentPriceProps) => {
  const [curPrice, setCurPrice] = useState(0);

  useEffect(() => {
    if (props.data && props.data.length) {
      const priceDetails = props.data[0].priceDetails;
      setCurPrice(Math.round(Number(priceDetails[Number(props.currentHour) + 1].intervalPrice) * 100));
    }
  }, [props.data, props.currentHour]);

  return (
    <Card
      className={`
      ${props.isMobile && 'width--100-p'}
      bor--2-energy flex mar--t-32
      `}
      style={{ borderColor: props.pgeChartColors[0] }}
    >
      <div className='bar--container width--10-p'>
        <span className='bar' style={{ backgroundColor: props.pgeChartColors[3] }} />
        <span className='bar measure' style={{ height: `${curPrice > 100 ? 100 : curPrice}%`, backgroundColor: props.pgeChartColors[2] }} />
      </div>

      <div className='width--90-p'>

        <div
          className='flex jus--space-between mar--b-20'
        >
          <Text
            appearance='body-lg'
          >
            Current Price
          </Text>
        </div>
        <div
          className='flex jus--space-between'
        >
          <Text
            appearance='headline-md'
          >
            {curPrice === 0 ? '' : `${curPrice}¢`}
          </Text>
          <Text
            appearance='body-md'
            color='ocean'
          >
            /kWh
          </Text>
        </div>
      </div>
    </Card>
  );
};
