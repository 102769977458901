import * as React from 'react';
const HomePower = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 36 36"
    {...props}
  >
    <path
      fill="#FFA44F"
      stroke="#FFA44F"
      strokeWidth={0.75}
      d="M15.098 5.694 3.44 14.108a.75.75 0 0 1-.878-1.216l11.658-8.414a3.94 3.94 0 0 1 4.562 0l11.659 8.414a.75.75 0 0 1-.88 1.216L17.903 5.694a2.426 2.426 0 0 0-2.804 0ZM33 23.25h-3.176l3.82-6.365a.75.75 0 1 0-1.287-.77l-4.5 7.5a.75.75 0 0 0 .643 1.135h3.176l-3.82 6.365a.747.747 0 0 0 .644 1.135.75.75 0 0 0 .643-.365l4.5-7.5A.75.75 0 0 0 33 23.25Zm-7.5 0h-3.176l3.82-6.365a.75.75 0 1 0-1.287-.77l-4.5 7.5A.75.75 0 0 0 21 24.75h3.176l-3.82 6.365A.747.747 0 0 0 21 32.25a.75.75 0 0 0 .643-.365l4.5-7.5a.75.75 0 0 0-.643-1.135Zm-7.5 7.5H9a2.253 2.253 0 0 1-2.25-2.25V14.494l-1.5 1.083V28.5A3.753 3.753 0 0 0 9 32.25h9a.75.75 0 1 0 0-1.5Z"
    />
  </svg>
);
export default HomePower;
