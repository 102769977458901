import React from 'react';

// COMPONENTS
import { ListEvents } from 'pages/Accounts/components';
import { EventHandlerDrawer } from 'pages/Events/sections/EventsList/components';

// ASSETS
import styles from './Events.module.scss';

// HELPERS
import { useEvents } from 'api/events/events.api';
import { useSelector } from 'react-redux';
import { getEventsFilters, getEventsSearchString, getEventsSortBy } from 'store/modules/events/events.selector';

const Events = () => {
  const searchString = useSelector(getEventsSearchString);
  const filters = useSelector(getEventsFilters);
  const { key: sortKey, ascending } = useSelector(getEventsSortBy);

  const { data: eventsData, isLoading } = useEvents({ filters, searchString, sortKey, orderAscending: ascending });

  return (
    <div>
      <p className={styles.title}>Events</p>
      {(!isLoading && eventsData) && (
        <>
          <ListEvents events={eventsData} aria-label="List of Events" />
          <EventHandlerDrawer/>
        </>
      )}
    </div>
  );
};

export default Events;
