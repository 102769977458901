import { useQuery } from '@tanstack/react-query';

export const useUsers = (users: any, filters: any, searchString: string, order: string, limit: number) => {
  let currentUsers: any[] = [];
  function search (searchUsers: any[]) {
    const filterName = (item:any, key:string) => {
      return item[key].toLowerCase().indexOf(searchString.toLowerCase()) !== -1;
    };

    const firstNameSearch = searchUsers.filter((user:any) => filterName(user, 'firstName'));
    const lastNameSearch = searchUsers.filter((user:any) => filterName(user, 'lastName'));

    return (
      [...firstNameSearch, ...lastNameSearch]
    );
  }

  function sort (sortUsers: any[]) {
    return (
      [...sortUsers].reverse()
    );
  }

  function filter (filterUsers: any[]) {
    const matchedUsers: any[] = [];
    // THIS WILL HAVE TO BE REFACTOR ONCE ROLES ARE ADDED
    filters.forEach((filter: any) => {
      // Filter by products only
      filterUsers.forEach((user:any) => {
        const {
          products
        } = user;

        products.forEach((product:string) => {
          if (product === filter) {
            matchedUsers.push(user);
          }
        });
      });
    });

    return (
      matchedUsers
    );
  }

  return useQuery({
    queryKey: ['users'],
    queryFn: () => {
      return [] as [];
    },
    select: () => {
      currentUsers = users;

      //   Handle Filters
      if (filters.length >= 1) {
        currentUsers = filter(currentUsers);

        if (currentUsers.length === 0) {
          return [];
        }
      }

      //   Handle Search
      if (searchString.length >= 1) {
        currentUsers = [...search(currentUsers)];
      }

      //   Handle Sort
      if (order === 'ascending') {
        currentUsers = sort(currentUsers);
      }

      if (currentUsers.length >= 1) {
        return currentUsers.slice(0, limit);
      }

      return users;
    }
  });
};
