import * as React from 'react';
const SvgComponent = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 7 7"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill={props.color || '#344145'}
        d="M5.997 4.712c.12.067.197.168.232.302a.493.493 0 0 1-.049.381l-.25.43a.472.472 0 0 1-.303.232.493.493 0 0 1-.38-.048l-1.04-.598v1.2a.48.48 0 0 1-.148.35.48.48 0 0 1-.352.15h-.5a.48.48 0 0 1-.351-.15.48.48 0 0 1-.149-.35v-1.2l-1.039.598a.493.493 0 0 1-.38.048.472.472 0 0 1-.303-.232l-.25-.43a.493.493 0 0 1-.049-.38.472.472 0 0 1 .232-.303l1.04-.602-1.04-.601a.472.472 0 0 1-.232-.303.493.493 0 0 1 .049-.381l.25-.43a.472.472 0 0 1 .303-.232.493.493 0 0 1 .38.049l1.04.597V1.61a.48.48 0 0 1 .148-.351.48.48 0 0 1 .351-.149h.5a.48.48 0 0 1 .352.149.48.48 0 0 1 .148.351v1.2l1.04-.598a.493.493 0 0 1 .38-.05.472.472 0 0 1 .303.233l.25.43c.068.12.084.247.049.38a.472.472 0 0 1-.232.304l-1.04.601 1.04.602Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h7v7H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
