import React from 'react';

// COMPONENTS
import Skeleton from 'components/Skeleton/Skeleton';

// ASSETS
import styles from './RateSummaryLoading.module.scss';

const RateSummaryLoading = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <span className={styles.rateName}>
            <Skeleton variant="rectangular" height={24} width={200} />
          </span>
          <div className={styles.tags}>
            <Skeleton variant="rectangular" height={24} width={80} />
          </div>
        </div>
        <p className={styles.description}>
          <Skeleton variant="rectangular" height={16} width={300} />
        </p>
        <Skeleton variant="rectangular" height={42} width={150} />
      </div>
      <div className={styles.body}>
        <div className={styles.costItem}>
          <span><Skeleton variant="rectangular" height={20} width={300} /></span>
          <span className={styles.value}><Skeleton variant="rectangular" height={20} width={80} /></span>
        </div>
        <div className={styles.costItem}>
          <span><Skeleton variant="rectangular" height={20} width={300} /></span>
          <span className={styles.value}><Skeleton variant="rectangular" height={20} width={80} /></span>
        </div>
      </div>
    </div>
  );
};

export default RateSummaryLoading;
