import React from 'react';

// COMPONENTS
import { FilterSearchBar } from 'blocks/components';
import ListMappings from '../ListMappings/ListMappings';
import ConfigureMappingDrawer from '../ConfigureMappingDrawer/ConfigureMappingDrawer';

// ASSETS
import styles from '../Mapping/Mapping.module.scss';

// HELPERS
import { useMapping } from 'api/accounts/accounts.api';

const Mapping = () => {
  const { data: mappingData, isLoading } = useMapping();

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <h3>Mapping</h3>
      </div>
      <div className={styles.filters}>
        <FilterSearchBar searchPlaceholder='Search networks'/>
      </div>
      {(!isLoading && mappingData) && (
        <ListMappings title="Map" mappings={mappingData} aria-label="List of Mappings" />
      )}
      <ConfigureMappingDrawer/>
    </div>
  );
};

export default Mapping;
