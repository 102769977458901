import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// COMPONENTS
import { BillingSingleView } from 'pages/Accounts/components';
import { Breadcrumb } from 'components';

// ASSETS
import styles from './BillingDetails.module.scss';

// ACTIONS
import { setSingleBilling } from 'store/modules/accounts/accounts.slice';

// SELECTORS
import { getSingleBilling } from 'store/modules/accounts/accounts.selector';

// HELPERS
import { useSingleBill } from 'api/billing/billing.api';
import { IBilling } from 'api/billing/billing.typings';
import { useTheme } from 'hooks/Theme/Theme.context';

const BillingDetails = () => {
  const { id } = useParams();
  const { slug } = useTheme();
  const dispatch = useDispatch();
  const { data: billingData, isLoading } = useSingleBill(id as string);
  const singleBilling = useSelector(getSingleBilling);

  useEffect(() => {
    dispatch(setSingleBilling(billingData as IBilling));
  }, [billingData]);

  return (
    <div className={`${styles['billing-page']} admin--page pad--t-128 pad--l-128 pad--r-36`} role="main">
      {(!isLoading && singleBilling) && (
        <>
          <div className="pad--b-24">
            <Breadcrumb
              withRouter={true}
              items={[
                {
                  title: 'Billing',
                  bold: true,
                  href: `/${slug}/billing`
                },
                {
                  title: `Bill ID ${(singleBilling as IBilling).billId}`
                }
              ]}
            />
          </div>
          {
            singleBilling.billId && (
              <BillingSingleView bill={singleBilling as IBilling}/>
            )
          }
        </>
      )}
    </div>
  );
};

export default BillingDetails;
