import React from 'react';

// COMPONENTS
import { Tabs } from 'components';
import MeterAccounts from '../MeterAccounts/MeterAccounts';
import Billing from '../Billing/Billing';
import Attributes from '../Attributes/Attributes';
import Usage from '../Usage/Usage';
import Events from '../Events/Events';

const AccountTabs = () => {
  const tabs = [
    {
      content: (
        <MeterAccounts/>
      ),
      label: 'Delivery Point'
    },
    {
      content: (
        <Billing/>
      ),
      label: 'Billing'
    },
    {
      content: (
        <Attributes/>
      ),
      label: 'Attributes'
    },
    {
      content: (
        <Usage/>
      ),
      label: 'Usage'
    },
    {
      content: (
        <Events/>
      ),
      label: 'Events'
    }
  ];

  return (
    <Tabs tabs={tabs} />
  );
};

export default AccountTabs;
