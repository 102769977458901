import React from 'react';
import { InfoItem } from 'blocks/components';

// COMPONENTS
import { Tag } from 'components';

// ASSETS
import styles from './ContractDetails.module.scss';
import { IAccount } from 'api/accounts/accounts.typings';

interface ContractDetailsProps {
  account: IAccount
}

const ContractDetails:React.FC<ContractDetailsProps> = ({ account }) => {
  return (
    <div className={styles['contract-details']}>
      <div className={styles['info-box']}>
        <InfoItem title="Status" description={<Tag copy={account.status} size="sm" color='stem' />} />
      </div>
      <div className={styles['info-box']}>
        <InfoItem title="Service Agreement ID" description={account.saId} />
      </div>
      <div className={styles['info-box']}>
        <InfoItem title="Rate" description={account.rate} />
      </div>
      <div className={styles['info-box']}>
        <InfoItem title="Billing Cycle" description={account.billCycle} />
      </div>
      <div className={styles['info-box']}>
        <InfoItem title="Last Invoice" description={account.lastInvoice} />
      </div>
    </div>
  );
};

export default ContractDetails;
