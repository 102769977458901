import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { ColumnChart, Icon, Text } from '@gridx/metronome-components';
import colors from '../../style/colors';
import { PricingData, formatPrice } from './RealTimeData.helpers';

interface PricingBarChartProps {
  isError: boolean
  isFetched: boolean
  data: PricingData[] | undefined
  chartWidth: number
  todayDate: string
  selectDate: string
  currentHour: string
  categories: () => string[]
  pgeChartColors: string[]
  scrollBar: number
}

export const PricingBarChart = (props: PricingBarChartProps) => {
  const [seriesData, setSeriesData] = useState<{ name: string, data: any[] }[]>([]);

  useEffect(() => {
    if (props.data && props.data.length) {
      const prices = formatPrice(props.data[0].priceDetails);
      updateSeriesData(prices);
    }
  }, [props.data, props.currentHour]);

  const updateSeriesData = (prices: number[]) => {
    const data = prices.map((price: number, index: number) => {
      if (index === Number(props.currentHour) + 1) {
        return {
          y: Number(price.toFixed(3)),
          dataLabels: {
            enabled: true,
            align: 'center',
            backgroundColor: colors.sheen,
            borderRadius: '4px',
            shadow: true,
            y: -8,
            style: {
              color: colors.black,
              fontSize: '14px',
              fontFamily: 'Aktifo-B-Medium',
              top: '-24px',
              position: 'relative'
            },
            formatter: function () {
              return 'Now';
            }
          }
        };
      }
      return { y: Number(price.toFixed(3)), dataLabels: { enabled: false } };
    });
    setSeriesData([
      {
        name: 'cents',
        data
      }
    ]);
  };
  const chartHeight = 250;

  const yAxis = {
    gridLineColor: colors.white,
    lineColor: colors.white,
    lineWidth: 1,
    visible: true,
    opposite: false,
    labels: {
      style: {
        color: colors.black,
        fontSize: '12px',
        fontFamily: 'Aktifo-B-Medium'
      }
    },
    title: {
      text: 'Percent'
    },
    tickInterval: 10
  };

  const xAxis = {
    lineWidth: 0,
    visible: true,
    opposite: false,
    labels: {
      style: {
        color: '#000',
        fontSize: '12px',
        fontFamily: 'Aktifo-B-Medium'
      }
    },
    categories: props.categories()
  };
  const plotOptions = {
    pointWidth: 50,
    column: {
      grouping: false
    },
    series: {
      type: 'column',
      pointPadding: 0.18,
      groupPadding: 0,
      shadow: false,
      borderRadius: 4,
      colorByPoint: true,
      zones: [{
        value: 10,
        color: props.pgeChartColors[0] // PG&E BLUE TODO - replace with theme color once chart have been themed
      }, {
        value: 15,
        color: props.pgeChartColors[1] // PG&E BLUE TODO - replace with theme color once chart have been themed
      }, {
        value: 300,
        color: props.pgeChartColors[2] // PG&E BLUE TODO - replace with theme color once chart have been themed
      }]
    }
  };

  return (
    <div className='bor--b-1-wave mar--t-32 pad--b-32'>
      {props.isError && (
        <div className='bg--wave bor--r-6'>
          <div
            className='flex flex--align-center jus--center text--center'
            style={{ height: chartHeight }}
          >
            {moment(props.selectDate).isAfter(moment(props.todayDate))
              ? (
                <div>
                  <Icon.Calendar />
                  <Text>
                    New Day-Ahead prices available at 4PM PST
                  </Text>
                </div>
              )
              : (
                <div>
                  <Icon.AlertCircle />
                  <Text>
                    Error fetching data
                  </Text>
                </div>
              )}
          </div>
        </div>
      )}
      {!props.isFetched &&
        (
          <div
            className='bg--wave bor--r-6 flex flex--align-center icon--loader jus--center'
            style={{ height: chartHeight }}
          >
            <Icon.Loader />
            <Text>
              Loading
            </Text>
          </div>
        )
      }
      {props.isFetched && !props.isError &&
        <ColumnChart
          xAxis={xAxis}
          yAxis={yAxis}
          chart={{
            marginBottom: 60,
            marginLeft: 30,
            scrollablePlotArea: {
              minWidth: props.scrollBar,
              scrollPositionX: 1
            }
          }}
          backgroundColor={colors.white}
          plotBackgroundColor={colors.white}
          plotOptions={plotOptions}
          series={seriesData}
          height={chartHeight}
          width={props.chartWidth}
        />
      }
      <div className='flex flex--align-center mar--t-32'>
        <span className='legend' style={{ backgroundColor: props.pgeChartColors[0] }} />
        <Text
          appearance='title-md'
        >
          &#60;10¢
        </Text>
        <span className='legend med' style={{ backgroundColor: props.pgeChartColors[1] }} />
        <Text
          appearance='title-md'
        >
          10¢-15¢
        </Text>
        <span className='legend' style={{ backgroundColor: props.pgeChartColors[2] }} />
        <Text
          appearance='title-md'
        >
          &#62;15¢
        </Text>
        <div className='visually-hidden'>
          <span className='dotted--line mar--l-12' />
          <Text
            className='mar--l-24'
            appearance='title-md'
          >
            Other Applicable Tariff &#40;BEV1&#41;
          </Text>
        </div>

      </div>
    </div>
  );
};
