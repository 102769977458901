import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// COMPONENTS
import { ActionList, Icon, Popover } from 'components';

// ACTIONS
import { openUpdateSystem } from 'store/modules/project/project.slice';

// ASSETS
import styles from './MoreActions.module.scss';

const MoreActions = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);

  // EVENTS
  const togglePopover = () => {
    setActive(!active);
  };

  // RENDERS
  const activator = (
    <Icon.MoreVertical onClick={togglePopover} />
  );

  const options = [
    {
      content: 'Update System',
      onClick: () => {
        dispatch(openUpdateSystem());
      }
    }
  ];

  return (
    <div className={styles['more-actions']}>
      <Popover
        isOpen={active}
        renderReference = { activator }
        onOutsideClickClose={() => setActive(false)}
        p={0}
        placement='bottom-end'
        zIndex={999}
      >
        <ActionList items={options} />
      </Popover>
    </div>
  );
};

export default MoreActions;
