import React from 'react';

// ASSETS
import styles from './LoadingLongCostEstimates.module.scss';

const LoadingLongCostEstimates = () => {
  return (
    <div className={styles.container}>
      <div className={styles.sun}>
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <g clipPath="url(#clip0_2741_149195)">
            <path d="M16.0002 22.6666C19.6821 22.6666 22.6668 19.6818 22.6668 15.9999C22.6668 12.318 19.6821 9.33325 16.0002 9.33325C12.3183 9.33325 9.3335 12.318 9.3335 15.9999C9.3335 19.6818 12.3183 22.6666 16.0002 22.6666Z" stroke="#F1BD05" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16 1.33325V3.99992" stroke="#F1BD05" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16 28V30.6667" stroke="#F1BD05" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.62646 5.62671L7.5198 7.52004" stroke="#F1BD05" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M24.48 24.48L26.3733 26.3733" stroke="#F1BD05" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1.3335 16H4.00016" stroke="#F1BD05" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M28 16H30.6667" stroke="#F1BD05" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.62646 26.3733L7.5198 24.48" stroke="#F1BD05" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M24.48 7.52004L26.3733 5.62671" stroke="#F1BD05" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_2741_149195">
              <rect width="32" height="32" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className={styles.body}>
        <p className={styles.title}>Almost there, adjusting your project</p>
        <p className={styles.description}>This may take up to a minute</p>
      </div>
    </div>
  );
};

export default LoadingLongCostEstimates;
