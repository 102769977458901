import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

interface PricingHeader {
  priceCurveName: string
  marketName: string
  intervalLengthInMinutes: number
  settlementCurrency: string
  settlementUnit: string
  startTime: string
  endTime: string
  recordCount: number
}

interface PriceDetailsComponent {
  component: string
  intervalPrice: string
}

export interface PriceDetails {
  startIntervalTimeStamp: string,
  intervalPrice: string,
  priceStatus: string,
  priceComponents: PriceDetailsComponent[]
}

export interface PricingData {
  priceHeader: PricingHeader
  priceDetails: PriceDetails[]
}

export const fetchRealTimeDataByRange = (
  apiUrl: string, utility: string, market: string, rate: string, startDate: string, endDate: string
) => {
  const params = new URLSearchParams({
    utility,
    market,
    startdate: startDate,
    enddate: endDate,
    ratename: rate
  });

  return useQuery({
    queryKey: ['rates', rate, utility, startDate, endDate],
    queryFn: () => {
      return axios
        .get(`${apiUrl}?${params.toString()}`)
        .then((res) => res.data.data as PricingData[]);
    }
  });
};

export const downloadFile = (data: any, fileName: string, fileType: string) => {
  const blob = new Blob([data], { type: fileType });
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const formatPrice = (priceDetails: Array<any>) => {
  return priceDetails.map((price: any) => {
    const {
      intervalPrice
    } = price;
    return (intervalPrice * 100);
  });
};

export const rates = (utility: string) => {
  // RATES ARE NOT UPDATING WE NEED TO HAVE DEFAULT RATES ON VALUE CHANGE
  let ratesArray = [] as any;

  switch (utility) {
  case 'PCE':
    ratesArray = [
      { label: 'BEV1', value: 'BEV1' },
      { label: 'BEV2T', value: 'BEV2T' },
      { label: 'BEV2P', value: 'BEV2P' },
      { label: 'BEV2S', value: 'BEV2S' }
    ];
    break;
  case 'EBCE':
    ratesArray = [
      { label: 'B6', value: 'B6' },
      { label: 'B20P', value: 'B20P' },
      { label: 'B20S', value: 'B20S' },
      { label: 'B20T', value: 'B20T' },
      { label: 'BEV1', value: 'BEV1' },
      { label: 'BEV2T', value: 'BEV2T' },
      { label: 'BEV2P', value: 'BEV2P' },
      { label: 'BEV2S', value: 'BEV2S' }
    ];
    break;
  case 'SJCE':
    ratesArray = [
      { label: 'B6', value: 'B6' },
      { label: 'B20P', value: 'B20P' },
      { label: 'B20S', value: 'B20S' },
      { label: 'B20T', value: 'B20T' },
      { label: 'EELEC', value: 'EELEC' }
    ];
    break;
  case '3CE':
    ratesArray = [
      { label: 'B20P', value: 'B20P' },
      { label: 'B20S', value: 'B20S' },
      { label: 'B20T', value: 'B20T' },
      { label: 'BEV1', value: 'BEV1' },
      { label: 'BEV2T', value: 'BEV2T' },
      { label: 'BEV2P', value: 'BEV2P' },
      { label: 'BEV2S', value: 'BEV2S' },
      { label: 'EELEC', value: 'EELEC' }
    ];
    break;
  default:
    ratesArray = [
      { label: 'B6', value: 'B6' },
      { label: 'B20P', value: 'B20P' },
      { label: 'B20S', value: 'B20S' },
      { label: 'B20T', value: 'B20T' },
      { label: 'BEV1', value: 'BEV1' },
      { label: 'BEV2T', value: 'BEV2T' },
      { label: 'BEV2P', value: 'BEV2P' },
      { label: 'BEV2S', value: 'BEV2S' },
      { label: 'EELEC', value: 'EELEC' }
    ];
    break;
  }

  return ratesArray;
};
