import React, { useEffect, useRef, useState } from 'react';

// COMPONENTS
import { RatePriceSelects, RatePriceCharts } from './components';
import { ExpandableSection, Icon, Table } from '../../../../../../components';

// TYPINGS
import { IRate } from '../../../../../../api/analyze/analyze.typings';

// ASSETS
import styles from './RatePrices.module.scss';

// HELPERS
import { data } from './RatePrices.helpers';

interface RatePricesProps {
  rate: IRate
}

const RatePrices:React.FC<RatePricesProps> = () => {
  const [tdWidth, setTdWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const firstTd = containerRef.current.querySelector<HTMLDivElement>('tbody td');
        if (firstTd) {
          setTdWidth(firstTd.offsetWidth);
        }
      }
    };

    handleResize(); // Call once initially

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // RENDERS
  const columns = [
    {
      title: 'Effective date',
      dataIndex: 'date',
      key: 'date',
      render: (text: string) => <div className={styles['date-cell']}><Icon.ChevronRight size={18} /><strong>{text}</strong></div>
    },
    { title: 'Off peak', dataIndex: 'offpeak', key: 'offpeak', align: 'right' as const },
    { title: 'Partial peak', dataIndex: 'partialpeak', key: 'partialpeak', align: 'right' as const },
    { title: 'Peak', dataIndex: 'peak', key: 'peak', align: 'right' as const }
  ];

  return (
    <ExpandableSection title="Prices" icon={<Icon.DollarSign size={18} />} expanded>
      <div className={styles['select-area']}>
        <RatePriceSelects />
      </div>
      <div ref={containerRef}>
        <div className={styles['chart-container']}>
          <RatePriceCharts tdWidth={tdWidth} />
        </div>
        <Table columns={columns} dataSource={data} tableLayout='fixed' />
      </div>
    </ExpandableSection>
  );
};

export default RatePrices;
