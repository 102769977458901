/* eslint-disable indent */
import React from 'react';

import Highcharts from 'highcharts';
import ColumnStackedChart from 'components/ColumnStackedChart/ColumnStackedChart';
import { Chart, ChartData, CompareRateDetails, ConstColors, MapMonthsDisplay, Month, SeriesData } from './types';

import _ from 'lodash';

import colors from 'style/colors';
import RateComparisonLegends from './RateComparisonLegends';

export const constColors: ConstColors = {
  Rate: colors.black,
  Increase: colors.reaction
};

const mapMonthsDisplay: MapMonthsDisplay = {
  1: 'January ',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
};

export const colorsIndex = [colors.air, colors.watt, colors.clean, colors.blue, colors.energy];

let onlegendsClick: (legend: string) => void;

const calculateYAxisMaxMin = (seriesData: Array<Array<SeriesData>>, billMonthsLength: number) => {
  let maxY = 0;
  let minY = 0;

  seriesData.forEach((tagObj: Array<SeriesData>) => {
    for (let i = 0; i < billMonthsLength - 1; i++) {
      let sumMaxVal = 0;
      let sumMinVal = 0;

      tagObj.forEach((tag: SeriesData) => {
        const val = tag.data[i] || 0;
        if (val > 0) sumMaxVal = sumMaxVal + val;
        else sumMinVal = sumMinVal + Math.abs(val);
      });

      maxY = Math.max(maxY, sumMaxVal);
      minY = Math.max(minY, sumMinVal);
    }
  });

  return { minY: Math.floor(minY * -1), maxY: Math.ceil(maxY) };
};

const getChartsSeriesDataObject = (
  seriesDataObj: Array<SeriesData>,
  inactiveLegends: Array<string> = [],
  isLegendColumn = false
) => {
  seriesDataObj.reverse();

  const finalSeriesDataObj = seriesDataObj
  .map((item: SeriesData, index: number) => ({
    ...item,
    type: item.name.indexOf('Rate') > -1 && !isLegendColumn ? 'spline' : 'column',
    data: item.data,
    color:
      item.name === 'Rate' && !isLegendColumn
        ? colors.stone
        : constColors[item.name as keyof ConstColors] || colorsIndex[index],
    ...(item.name.indexOf('Rate') > -1 &&
      !isLegendColumn && {
        topBar: {
          enabled: true,
          color: constColors.Rate
        },
        zIndex: 5
      })
  }))
  .filter((item: SeriesData) => (!isLegendColumn ? !inactiveLegends.includes(item.name) : true));

  return finalSeriesDataObj;
};

const prepareChartsSeriesData = (originalSeriesData: Array<Array<SeriesData>>, inactiveLegends: Array<string> = []) => {
  return originalSeriesData.map((seriesDataObj: Array<SeriesData>) => {
    return getChartsSeriesDataObject(seriesDataObj, inactiveLegends);
  });
};

export const prepareChartsData = (data: CompareRateDetails, inactiveLegends?: Array<string>) => {
  const { availableRates, charts, months } = data;

  const { seriesData: originalSeriesData, chartSeriesKeys } = charts as Chart;

  const legendKeys = [...chartSeriesKeys];
  legendKeys.reverse();

  const chartMonthsDisplay = months.reduce((acc: Array<string>, { firstColumnAttr }: Month) => {
    if (!isNaN(firstColumnAttr)) return [...acc, mapMonthsDisplay[firstColumnAttr as keyof MapMonthsDisplay].charAt(0)];
    return acc;
  }, []);

  const updatedSeriesData: Array<Array<SeriesData>> = prepareChartsSeriesData(
    _.cloneDeep(originalSeriesData),
    inactiveLegends
  );

  const { minY, maxY } = calculateYAxisMaxMin(updatedSeriesData, months.length);

  const legendColmn: Array<SeriesData> = getChartsSeriesDataObject(
    _.cloneDeep(originalSeriesData[0]),
    inactiveLegends,
    true
  );

  const chartData: ChartData = {
    key: 0,
    date: (
      <div style={{ position: 'relative' }}>
        <ColumnStackedChart
          series={legendColmn}
          width={330}
          yAxis={{
            visible: true,
            min: minY,
            max: maxY,
            opposite: true
          }}
        />
        <RateComparisonLegends
          chartSeriesKeys={legendKeys}
          inactiveLegends={inactiveLegends}
          onlegendsClick={onlegendsClick}
        />
      </div>
    )
  };

  availableRates.forEach((rate: string, index: number) => {
    chartData[`${rate}-${index}` as keyof ChartData] = (
      <ColumnStackedChart
        series={updatedSeriesData[index]}
        width={Math.min(320, months.length * 28)}
        xAxis={{
          gridLineDashStyle: 'Dot',
          gridLineWidth: 2,
          lineColor: colors.black,
          categories: chartMonthsDisplay,
          visible: true,
          opposite: true
        }}
        yAxis={{
          visible: false,
          min: minY,
          max: maxY
        }}
        tooltip={{
          formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
            let value: number | string = this.y || '0';
            value = Number.parseFloat(value as string).toFixed(2);

            return `
                <div class="donutTooltip">
                  <span>${value}</span>
                  <span>${this.series.name}</span>
                </div>
              `;
          }
        }}
        chart={{
          backgroundColor: colors.white,
          plotBackgroundColor: colors.white
        }}
        accessibility={{
          enabled: true
        }}
      />
    );
  });

  return chartData;
};

export const useChartsData = (onlegendsClickCB: (legend: string) => void) => {
  onlegendsClick = onlegendsClickCB;

  return prepareChartsData;
};
