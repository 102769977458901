import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

import Logo from '../Logo/Logo';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';

import { DEFAULT_METRONOME_ASIDE_NAVIGATION_LINKS } from '../constants';

import './aside-navigation.scss';
import { PageLayoutButton } from '../Layout/Layout';

type Props = {
  metronomeDemoMode?: boolean,
  pageLinks?: Array<PageLayoutButton>,
  setExpand?: (event?: any) => void,
};

const AsideNavigation: React.FC<Props> = (props: Props) => {
  const [logoState, setLogoState] = useState('gridx');
  const {
    metronomeDemoMode,
    pageLinks,
    setExpand
  } = props;
  const pathname = useLocation().pathname;
  const pathSplit = pathname.split('/');

  const linksArray = pageLinks !== undefined ? pageLinks : DEFAULT_METRONOME_ASIDE_NAVIGATION_LINKS;
  let tempArray: Array<PageLayoutButton> = linksArray;

  if (metronomeDemoMode) {
    if (pathSplit[1] === 'metronome') {
      tempArray = DEFAULT_METRONOME_ASIDE_NAVIGATION_LINKS;
    } else {
      tempArray = [];
    }
  }

  const renderLinks = tempArray.map((link: PageLayoutButton) => {
    const {
      active,
      color,
      displayName,
      href,
      icon,
      pathMatches,
      withRouter
    } = link;

    let isActiveLink = false;
    if (pathMatches && pathMatches.indexOf(pathname) !== -1) {
      isActiveLink = true;
    } else {
      isActiveLink = pathname === href;
    }

    return (
      <li
        key={displayName}
        className={`
        flex flex--align-center mar--l-8 text--stone 
        ${isActiveLink ? 'bor--1-mist tab--on shadow--sm' : ''} 
        ${!active ? 'disable' : ''}
        `}
      >
        {
          withRouter
            ? (
              <Link
                className='flex pad--b-16 link--item pad--l-16 pad--t-16 pad--r-16'
                to={href}
              >
                {icon}
                <Text
                  className='body--lg pad--l-16'
                  color={color}
                  variant='body1'
                >
                  {displayName}
                </Text>
              </Link>
            )
            : (
              <a
                className='flex pad--b-16 link--item pad--l-16 pad--t-16 pad--r-16'
                href={href}
              >
                {icon}
                <Text
                  className='body--lg pad--l-16'
                  color={color}
                  variant='body1'
                >
                  {displayName}
                </Text>
              </a>
            )
        }
      </li>
    );
  });

  return (
    <aside className='aside-navigation bor--r-1 bor-box'>
      <input type='checkbox' name='check-aside-options' value='' id='aside-options' onChange={e => {
        // @ts-ignore
        setExpand(e.target.checked);
        setLogoState(e.target.checked ? 'gridx-wordmark' : 'gridx');
      }}/>
      <div className='sub-nav'>
        <div className='bor--b-1 flex flex--align-center jus--center logo--wrapper shadow--sm'>
          <Logo
            className='dis--inline-block'
            name={logoState}
            size={logoState === 'gridx' ? 'sm' : 'lg'}
          />
        </div>
        <div className='aside-navigation--chevron'>
          <label htmlFor='aside-options'>-</label>
          <Icon.ChevronRight className='bor--1 stone icon--chevron' />
        </div>
        <ul className='pad--l-0 mar--t-28'>
          {renderLinks}
        </ul>
      </div>
    </aside>
  );
};

export default AsideNavigation;
