import React from 'react';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import { FilterPanel } from 'blocks/components';
import FilterCreatedDate from '../FilterCreatedDate/FilterCreatedDate';

// SELECTORS
import { getEventsFilterOpen, getEventsFilters } from 'store/modules/events/events.selector';

// ACTIONS
import { clearEventsFilter, toogleFilter } from 'store/modules/events/events.slice';

const EventsFilterPanel = () => {
  const dispatch = useDispatch();
  const isFilterOpen = useSelector(getEventsFilterOpen);
  const filters = useSelector(getEventsFilters);

  return (
    <AnimatePresence>
      {isFilterOpen && (
        <motion.div
          initial={{ x: '-100%' }}
          animate={{ x: 0 }}
          exit={{ x: '-100%' }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          style={{ zIndex: 10 }}
        >
          <FilterPanel
            aria-label="Filter Accounts"
            onClose={() => dispatch(toogleFilter())}
            onClear={() => dispatch(clearEventsFilter())}
          >
            <FilterCreatedDate selectedFilters={filters} />
          </FilterPanel>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default EventsFilterPanel;
