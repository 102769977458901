import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// ASSETS
import './StackedChart.scss';

// HELPERS
import colors from '../../style/colors';

export interface StackedChartProps {
  width?: number | string | null;
  height?: number | string | null;
  yAxis?: Highcharts.YAxisOptions;
  xAxis?: Highcharts.XAxisOptions;
  chart?: Highcharts.ChartOptions;
  legend?: Highcharts.LegendOptions;
  series: any;
}

const StackedChart:React.FC<StackedChartProps> = ({ yAxis = null, xAxis = null, width = null, height = null, chart = null, legend = null, series }) => {
  const options: Highcharts.Options = {
    chart: {
      type: 'areaspline',
      backgroundColor: colors.aspen,
      plotBackgroundColor: colors.aspen,
      plotBorderWidth: 0,
      plotShadow: false,
      marginRight: 0,
      marginBottom: 0,
      // marginLeft: 0,
      spacingRight: 0,
      height,
      width,
      events: {
        load: function (this: Highcharts.Chart) {
          // Check if the chart's container and its SVG element exist.
          if (this.container && this.container.getElementsByTagName('svg').length > 0) {
            const svg = this.container.getElementsByTagName('svg')[0];

            // Create a title element.
            const title = document.createElementNS('http://www.w3.org/2000/svg', 'title');
            title.textContent = 'Stacked Chart';

            // Prepend the title to the SVG element. This ensures that the title is the first child of the SVG.
            svg.insertBefore(title, svg.firstChild);

            // Set aria-label and role attributes.
            svg.setAttribute('aria-label', 'Stacked Chart');
            svg.setAttribute('role', 'img');
          }
        }
      },
      ...chart
    },
    accessibility: {
      enabled: true,
      description: 'Stacked Chart'
    },
    title: {
      text: ''
    },
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    legend: {
      align: 'left',
      layout: 'vertical',
      itemStyle: {
        fontSize: '14px',
        color: colors.stone
      },
      itemMarginTop: 5,
      margin: 20,
      ...legend
    },
    tooltip: {
      enabled: false
    },
    xAxis: {
      opposite: true,
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        staggerLines: 1,
        style: {
          color: colors.stone,
          fontSize: '14px',
          fontFamily: 'Aktifo-B, sans-serif'
        }
      },
      title: {
        text: null
      },
      ...xAxis
    },
    yAxis: {
      gridLineWidth: 0,
      startOnTick: false,
      min: 100,
      tickInterval: 150,
      offset: 25,
      title: {
        text: null
      },
      labels: {
        formatter: function (): string {
          return '$' + this.value;
        },
        style: {
          color: colors.stone,
          fontSize: '14px',
          fontFamily: 'Aktifo-B, sans-serif'
        }
      },
      ...yAxis
    },
    plotOptions: {
      series: {
        stacking: 'normal'
      },
      areaspline: {
        fillOpacity: 1,
        marker: {
          enabled: false
        }
      }
    },
    series
  };

  return (
    <div className='gridx-stacked-chart'>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default StackedChart;
