import React from 'react';

// COMPONENTS
import ColumnChart from 'components/ColumnChart/ColumnChart';

// ASSETS
import styles from './CostResultChart.module.scss';

// HELPERS
import { LegendOptions, AlignValue, VerticalAlignValue } from 'highcharts';
import useMediaQuery, { breakpoints } from 'hooks/useMediaQuery';

const CostResultChart = () => {
  const isMobile = useMediaQuery(breakpoints.mobileMedium);

  // RENDERS
  const series = [
    {
      name: 'Clean energy scenario',
      type: 'column',
      data: [183, 210, 173, 146, 128, 201, 219, 237, 228, 128, 164, 173],
      color: '#FFD700'
    },
    {
      name: 'Current scenario',
      type: 'line',
      data: [254, 292, 240, 203, 178, 279, 305, 330, 317, 178, 228, 241],
      color: 'black'
    }
  ];

  const legend: LegendOptions = {
    enabled: true,
    align: 'center' as AlignValue,
    verticalAlign: 'bottom' as VerticalAlignValue,
    layout: 'horizontal',
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 0,
    useHTML: true
  };

  return (
    <div className={styles.container}>
      <ColumnChart
        series={series}
        height={368}
        chart={{
          events: {},
          marginLeft: 45,
          marginBottom: isMobile ? 100 : 80
        }}
        xAxis={{
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          visible: true,
          type: 'category'
        }}
        yAxis={{
          opposite: false,
          max: 500,
          visible: true,
          labels: {
            formatter: function () {
              return `$${this.value}`;
            }
          }
        }}
        legend={legend}
        plotOptions={{
          column: {
            borderRadius: 8,
            pointPadding: 0,
            groupPadding: 0.02
          }
        }}
      />
    </div>
  );
};

export default CostResultChart;
