import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';

// COMPONENTS
import ListShowMore from 'components/ListShowMore/ListShowMore';

// HELPERS
import { IAnalyse } from '../../../../../../api/analyze/analyze.typings';
import { ListItem } from '../../../../../../blocks/components';

// ASSETS
import styles from './ListAnalyses.module.scss';
import { Tag } from '../../../../../../components';
import { getCustomerTypeColor } from './ListAnalyses.helpers';
import { TagColor } from 'components/Tag/Tag.helpers';

interface ListAnalysesProps {
  analyses: Array<IAnalyse>;
}

const ListAnalyses:React.FC<ListAnalysesProps> = ({ analyses }) => {
  const [limitShow, setLimitShow] = useState(10);

  // EVENTS
  const handleShowMore = () => {
    setLimitShow(limitShow + 10);
  };

  // RENDERS
  const filteredAnalyses = analyses.slice(0, limitShow);

  return (
    <>
      <AnimatePresence>
        <motion.ul
          className={styles['list-container']}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {
            filteredAnalyses.map((analyse) => {
              return (
                <motion.li
                  key={analyse.id}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.5 }}
                >
                  <ListItem
                    selectable
                    columns={[
                      { body: <span>{analyse.name}</span>, col: 3 },
                      { body: <span>{analyse.customers}</span>, col: 1 },
                      { body: <span>{analyse.description}</span>, col: 5 },
                      { body: <div className={styles['customer-type']}><Tag copy={analyse.type} color={getCustomerTypeColor(analyse.type) as any} /></div>, col: 3 }
                    ]}
                  />
                </motion.li>
              );
            })
          }
        </motion.ul>
      </AnimatePresence>
      <div className={styles['list-footer']}>
        <ListShowMore total={analyses.length} current={limitShow} onShowMore={handleShowMore} />
      </div>
    </>
  );
};

export default ListAnalyses;
