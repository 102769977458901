import React, { useState } from 'react';

// COMPONENTS
import Icon from 'components/Icon/Icon';
import Text from 'components/Text/Text';

// STYLE
import './sub-nav.scss';

interface Props {
    title: string;
    subNavItems: Array<any> | undefined;
    isExpanded: boolean;
    setSubNavItem: (subNavItems: number) => void;
    setExpanded: (isExpanded: boolean) => void;
}

const SubNav: React.FC<Props> = ({ title, subNavItems, isExpanded, setSubNavItem }) => {
  const [activeState, setActive] = useState(subNavItems ? subNavItems[0].id : null);

  const renderSubItems = (subItems:Array<any>) => {
    return subItems.map((item:any) => {
      const {
        id,
        title
      } = item;
      return (
        <li
          key={title}
          className={
            `${id === activeState && 'active'}
            bor--r-4
            mar--l-16
            mar--b-4
            cursor--pointer
            subnav--item-list`
          }
        >
          <div
            onClick={() => setActive(id)}
            role='button'
            tabIndex={0}
            onKeyDown={() => {}}
          >
            <Text
              appearance='body-md'
              color='stone'
              className='pad--t-8 pad--b-8 pad--l-8'
            >
              {title}
            </Text>
          </div>
        </li>
      );
    });
  };

  const [subItemsId, setSubItemsExpanded] = useState({});

  const renderSubmenu = (menuItems:Array<any>) => {
    return menuItems.map((item:any) => {
      const {
        id,
        title,
        subItems
      } = item;
      return (
        <div
          key={title}
          className='mar--b-16'
        >
          <li
            className={
              `${id === activeState && 'active'}
              ${subItems ? 'has-subnav' : ''}
              bor--r-4
              cursor--pointer
              subnav-item`
            }
          >
            <div
              className='flex flex--align-center'
              onClick={() => {
                setSubNavItem(id);
                setActive(id);

                if (subItems) {
                  setSubItemsExpanded(!subItemsId);
                }
              }}
              role='button'
              tabIndex={0}
              onKeyDown={() => {}}
            >
              <Text
                appearance='title-sm'
                color='stone'
                className='pad--t-8 pad--b-8 pad--l-8 product--title label-sm'
              >
                {title}
              </Text>
              {subItems && (
                <div
                  className='flex flex--right flex--align-center transition--rotate'
                  style={{ rotate: `${subItemsId ? '90deg' : '0deg'}` }}
                >
                  <Icon.ChevronRight />
                </div>
              )}
            </div>
          </li>
          {subItems && (
            <ul
              className={
                `${subItemsId ? 'expanded' : ''}
              subnav--list bor--l-1 mar--t-16`
              }
            >
              {renderSubItems(subItems)}
            </ul>
          )}
        </div>
      );
    });
  };

  return (
    <div
      className={
        `sub-nav
        width--0-p
        bor--r-1
        desktop--submenu mar--l-96 pad--t-24 transition--width`
      }
      style={{ minWidth: isExpanded ? '288px' : '0px' }}
    >
      <div
        className='desktop--submenu-container pad--l-24'
      >
        <ul>
          <li className='mar--b-16'>
            <Text
              className='uppercase'
              appearance='title-sm'
              color='ocean'
            >
              {title}
            </Text>
          </li>
          {subNavItems && renderSubmenu(subNavItems)}
        </ul>
      </div>
    </div>
  );
};

export default SubNav;
