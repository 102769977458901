import { useQuery } from '@tanstack/react-query';

// DATA
import populationData from '../../json/data-population.json';
import ratesData from '../../json/data-rates.json';
import analyseListData from '../../json/data-analyse-list.json';

// TYPINGS
import { ICustomerData, IRate, IAnalyse } from './analyze.typings';
import { FilterItem } from 'store/helpers/store.typings';

export const usePopulation = (filters: any, searchString: string) => {
  return useQuery({
    queryKey: ['population'],
    queryFn: () => {
      return populationData as ICustomerData[];
    },
    select: (data) => {
      if (filters.length === 0) return data;

      let filteredPopulation = data;

      filters.forEach((filter: any) => {
        if (filter.value) {
          if (filter.value.length) {
            filteredPopulation = filteredPopulation.filter((customer) => filter.value.includes(customer.customerType));
          }
        }
      });

      // Apply the search filter
      if (searchString && searchString.length > 0) {
        filteredPopulation = filteredPopulation.filter((customer) => customer.name.toLowerCase().includes(searchString.toLowerCase()));
      }

      return filteredPopulation;
    }
  });
};

interface IUseRateParams {
  rateId?: number;
  filters?: FilterItem<IRate>[],
  searchString?: string
}

export const useRates = ({ rateId = 0, filters = [], searchString = '' }: IUseRateParams) => {
  return useQuery({
    queryKey: ['rates'],
    queryFn: () => {
      return ratesData as IRate[];
    },
    select: (data) => {
      if (rateId === 0) {
        if (filters.length === 0) return data;

        let filteredRates = data;

        filters.forEach((filter: any) => {
          if (filter.value) {
            if (filter.value.length) {
              filteredRates = filteredRates.filter((data) => filter.value.includes(data.status));
            }
          }
        });

        // Apply the search filter
        if (searchString && searchString.length > 0) {
          filteredRates = filteredRates.filter((data) => data.name.toLowerCase().includes(searchString.toLowerCase()));
        }

        return filteredRates;
      }

      return data.filter((rate) => rate.id === rateId)[0];
    }
  });
};

export const useAnalyseList = ({ rateId = 0 }: IUseRateParams) => {
  return useQuery({
    queryKey: ['analyses'],
    queryFn: () => {
      return analyseListData as IAnalyse[];
    },
    select: (data) => {
      if (rateId === 0) return data;

      return data.filter((rate) => rate.id === rateId)[0];
    }
  });
};
