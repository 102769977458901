import Highcharts from 'highcharts';

// HELPERS
import colors from '../style/colors';

export const buildDots = (chart: Highcharts.Chart, isRedraw?: boolean) => {
  const width = chart.chartWidth;
  const height = chart.chartHeight;
  const step = 30;

  if (isRedraw) {
    const oldDots = document.querySelectorAll('.dot-grid');
    oldDots.forEach((dot: any) => {
      dot.remove();
    });
  }

  for (let i = 0; i < width; i += step) {
    for (let j = 0; j < height; j += step) {
      chart.renderer.circle(i, j, 1.5).attr({
        fill: colors.stone,
        zIndex: 3,
        class: 'dot-grid'
      }).add();
    }
  }
};
