import React from 'react';

// COMPONENTS
import { ExpandableSection } from 'components';
import { InfoItem } from 'blocks/components';

// ASSETS
import styles from './AccountDetails.module.scss';

// HELPERS
import { IAccount } from 'api/accounts/accounts.typings';

interface AccountDetailsProps {
  account: IAccount
}

const AccountDetails:React.FC<AccountDetailsProps> = () => {
  return (
    <div className={styles.main}>
      <ExpandableSection title="Service account details" expanded>
        <div className={styles.container}>
          <div>
            <div className={styles['info-box']}>
              <InfoItem title="Service Agreement ID" description="2608237351137217" />
            </div>
            <div className={styles['info-box']}>
              <InfoItem title="Rate Code" description="Contract A" />
            </div>
            <div className={styles['info-box']}>
              <InfoItem title="Billing Account Number" description="0000-111-2222" />
            </div>
          </div>
          <div>
            <div className={styles['info-box']}>
              <InfoItem title="Entity ID" description="8478177" />
            </div>
            <div className={styles['info-box']}>
              <InfoItem title="Service Territory" description="Central Florida" />
            </div>
            <div className={styles['info-box']}>
              <InfoItem title="Billing Address" description="P.O. Box 9, Chiefland, FL 32644" />
            </div>
          </div>
          <div>
            <div className={styles['info-box']}>
              <InfoItem title="Contract" description="Joe Chang" />
            </div>
            <div className={styles['info-box']}>
              <InfoItem title="Phone Number" description="+1 (407) 234-5678" />
            </div>
            <div className={styles['info-box']}>
              <InfoItem title="Email Address" description="joe.chang@centralfloridaelectric.com" />
            </div>
          </div>
        </div>
      </ExpandableSection>
    </div>
  );
};

export default AccountDetails;
