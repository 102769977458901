/* eslint-disable indent */
import React from 'react';

import Highcharts from 'highcharts';
import ColumnStackedChart from 'components/ColumnStackedChart/ColumnStackedChart';
import { Chart, ChartData, CompareRateDetails, ConstColors, MapMonthsDisplay, Month, SeriesData } from './types';

import _ from 'lodash';

import colors from '../../../../style/colors';
import RateComparisonLegends from './TableRateCompareLegends';

export const constColors: ConstColors = {
  Rate: colors.black,
  Increase: colors.reaction
};

const mapMonthsDisplay: MapMonthsDisplay = {
  1: 'January ',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
};

export const colorsIndex = [colors.stone, colors.air, colors.sky, colors.blue];

let onlegendsClick: (legend: string) => void;

const calculateYAxisMaxMin = (seriesData: Array<Array<SeriesData>>, billMonthsLength: number) => {
  let maxY = 0;
  let minY = 0;

  seriesData.forEach((tagObj: Array<SeriesData>) => {
    for (let i = 0; i < billMonthsLength - 1; i++) {
      let sumMaxVal = 0;
      let sumMinVal = 0;

      tagObj.forEach((tag: SeriesData) => {
        if (tag.name.indexOf('Rate') > -1) return null;

        const val = tag.data[i] || 0;
        if (val > 0) sumMaxVal = sumMaxVal + val;
        else sumMinVal = sumMinVal + Math.abs(val);
      });

      maxY = Math.max(maxY, sumMaxVal);
      minY = Math.max(minY, sumMinVal);
    }
  });

  return { minY: Math.floor(minY * -1), maxY: Math.ceil(maxY) };
};

const handleRatesDisplay = (ratesData: Array<number>, seriesData: Array<SeriesData>) => {
  const increaseElement = seriesData.find(({ name }: SeriesData) => name === 'Increase');
  if (!increaseElement) return ratesData;
  return ratesData.map((val: number, index: number) => {
    return increaseElement.data[index] > 0 ? 0 : val;
  });
};

const shouldHandleRatesDisplay = (name: string, inactiveLegends: Array<string>) => {
  return name === 'Rate' && inactiveLegends && !inactiveLegends.includes('Increase');
};

const renderTopLine = (event: Highcharts.PointerEventObject) => {
  const { chart }: any = event.target;
  const series = chart.series;

  series.forEach(function (series: any) {
    if (series.userOptions.topBar && series.userOptions.topBar.enabled) {
      series.points?.forEach(function (point: any) {
        if (point?.options?.y > 0) {
          if (!point.topBar) {
            point.topBar = chart.renderer
              .rect(chart.plotLeft + point.barX, chart.plotTop + point.plotY, point.pointWidth, 3)
              .attr({
                fill: series.userOptions.topBar.color,
                height: 1,
                zIndex: 3
              })
              .add();
          } else if (series.visible) {
            point.topBar.attr({
              x: chart.plotLeft + point.barX,
              y: chart.plotTop + point.plotY,
              width: point.pointWidth
            });
          } else {
            point.topBar.attr({
              width: 0
            });
          }
        }
      });
    }
  });
};

const getChartsSeriesDataObject = (
  seriesDataObj: Array<SeriesData>,
  inactiveLegends: Array<string> = [],
  isLegendColumn = false
) => {
  seriesDataObj.reverse();

  return seriesDataObj
    .map((item: SeriesData, index: number) => ({
      ...item,
      type: item.name.indexOf('Rate') > -1 && !isLegendColumn ? 'spline' : 'column',
      data: shouldHandleRatesDisplay(item.name, inactiveLegends)
        ? handleRatesDisplay(item.data, seriesDataObj)
        : item.data,
      color: colorsIndex[index],
      events: {
        render: renderTopLine
      },
      ...(item.name.indexOf('Rate') > -1 &&
        !isLegendColumn && {
          topBar: {
            enabled: true,
            color: constColors.Rate
          },
          zIndex: 5,
          marker: {
            enabled: true,
            symbol: 'circle'
          }
        })
    }))
    .filter((item: SeriesData) => (!isLegendColumn ? !inactiveLegends.includes(item.name) : true));
};

const prepareChartsSeriesData = (originalSeriesData: Array<Array<SeriesData>>, inactiveLegends: Array<string> = []) => {
  return originalSeriesData.map((seriesDataObj: Array<SeriesData>) => {
    return getChartsSeriesDataObject(seriesDataObj, inactiveLegends);
  });
};

export const prepareChartsData = (data: CompareRateDetails, inactiveLegends?: Array<string>) => {
  const { charts, months, rateDetails } = data;

  const { seriesData: originalSeriesData, chartSeriesKeys } = charts as Chart;

  const legendKeys = [...chartSeriesKeys];
  legendKeys.reverse();

  const chartMonthsDisplay = months.reduce((acc: Array<string>, { firstColumnAttr }: Month) => {
    if (!isNaN(firstColumnAttr)) return [...acc, mapMonthsDisplay[firstColumnAttr as keyof MapMonthsDisplay].charAt(0)];
    return acc;
  }, []);

  const updatedSeriesData: Array<Array<SeriesData>> = prepareChartsSeriesData(
    _.cloneDeep(originalSeriesData),
    inactiveLegends
  );

  const { minY, maxY } = calculateYAxisMaxMin(updatedSeriesData, months.length);

  const legendColmn: Array<SeriesData> = getChartsSeriesDataObject(
    _.cloneDeep(originalSeriesData[0]),
    inactiveLegends,
    true
  );

  const chartData: ChartData = {
    key: 0,
    date: (
      <div style={{ position: 'relative' }}>
        <ColumnStackedChart
          series={legendColmn}
          width={260}
          height={288}
          yAxis={{
            visible: true,
            min: minY,
            max: maxY,
            opposite: true,
            labels: {
              formatter: function () {
                return `$${this.value}`;
              }
            }
          }}
        />
        <RateComparisonLegends
          chartSeriesKeys={legendKeys}
          inactiveLegends={inactiveLegends}
          onlegendsClick={onlegendsClick}
        />
      </div>
    )
  };

  rateDetails?.forEach((rate: any, index: number) => {
    chartData[`${rate.ratePlanCode}-${index}` as keyof ChartData] = (
      <ColumnStackedChart
        series={updatedSeriesData[index]}
        width={Math.min(320, months.length * 28)}
        height={288}
        xAxis={{
          lineColor: colors.black,
          categories: chartMonthsDisplay,
          visible: true,
          opposite: true
        }}
        yAxis={{
          visible: false,
          min: minY,
          max: maxY
        }}
        tooltip={{
          formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
            let value: number | string = this.y || '0';
            value = Number.parseFloat(value as string).toFixed(2);

            return `
                <div class="donutTooltip">
                  <span>${value}</span>
                  <span>${this.series.name}</span>
                </div>
              `;
          }
        }}
        chart={{
          backgroundColor: colors.white,
          plotBackgroundColor: colors.white
        }}
        accessibility={{
          enabled: true
        }}
      />
    );
  });

  return chartData;
};

export const useChartsData = (onlegendsClickCB: (legend: string) => void) => {
  onlegendsClick = onlegendsClickCB;

  return prepareChartsData;
};
