import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import classnames from 'classnames';

import './custom-checkbox.scss';

interface Props {
  className?: string | undefined,
  checked?: boolean | undefined,
  color?: string | undefined,
  defaultChecked?: boolean | undefined,
  disabled?: boolean | undefined,
  disableRipple?: boolean | undefined,
  id : string | undefined,
  indeterminate?: boolean | undefined,
  inputProps? : object | undefined,
  onChange?: (() => void) | ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
  required? : boolean | undefined,
  size?: 'xs' | 'sm' | 'md',
  sx?: any,
  value?: any,
};

/**
* Represents a Checkbox.
* @constructor
* @param {string} className
* @param {string} label
* Usage :
* ````js
* <Checkbox className="my-checkbox" checked defaultChecked disabled disableRipple />
* ````
* @augments {Component<Props, State>}
*/
/** */
const CustomCheckbox: React.FC<Props> = (props: Props) => {
  const {
    className,
    checked,
    defaultChecked,
    disabled,
    disableRipple,
    id,
    indeterminate,
    inputProps,
    onChange,
    required,
    sx,
    value,
    size = 'medium'
  } = props;

  // STYLES
  let finalSize = size;
  switch (size) {
  case 'xs':
    finalSize = 'small';
    break;
  case 'sm':
    finalSize = 'small';
    break;
  case 'md':
    finalSize = 'medium';
    break;
  default:
    finalSize = 'medium';
  }

  const cssCustomCheckbox = classnames(className, 'custom--checkbox', {
    'custom--checkbox__xs': size === 'xs'
  });

  return (
    <div className={cssCustomCheckbox}
    >
      <Checkbox
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        disableRipple={disableRipple}
        id={id}
        indeterminate={indeterminate}
        inputProps={inputProps}
        onChange={onChange}
        required={required}
        sx={sx}
        size={finalSize as any}
        value={value}
        aria-label={id}
      />
    </div>

  );
};

export default CustomCheckbox;
