import React from 'react';

// COMPONENTS
import OverlapTimeChart from '../../../../../../../../components/OverlapTimeChart/OverlapTimeChart';

// ASSETS
import styles from './TimeOfUseCharts.module.scss';

// HELPERS
import { series1, series2, series3 } from './TimeOfUseCharts.helpers';

interface TimeOfUseChartsProps {
  tdWidth: { first: number, second: number }
}

const TimeOfUseCharts:React.FC<TimeOfUseChartsProps> = ({ tdWidth }) => {
  return (
    <>
      <div className={styles['empty-box']} style={{ width: tdWidth.first }} />
      <OverlapTimeChart
        series={series1}
        yAxis={{ categories: ['Peak'] }}
        width={tdWidth.second}
        height={160}
      />
      <OverlapTimeChart
        series={series2}
        yAxis={{ categories: ['Peak'] }}
        width={tdWidth.second}
        height={160}
      />
      <OverlapTimeChart
        series={series3}
        yAxis={{ categories: ['Peak'] }}
        width={tdWidth.second}
        height={160}
      />
      <OverlapTimeChart
        series={series1}
        yAxis={{ categories: ['Peak'] }}
        width={tdWidth.second}
        height={160}
      />
    </>
  );
};

export default TimeOfUseCharts;
