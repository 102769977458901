import React from 'react';
import { useDispatch } from 'react-redux';

// COMPONENTS
import { FilterControl } from 'blocks/components';
import Checkbox from 'components/CustomCheckbox/CustomCheckbox';
import Tag from 'components/Tag/Tag';

// ACTIONS
import { removeAccountFilter, setAccountFilter } from 'store/modules/accounts/accounts.slice';

// ASSETS
import styles from 'blocks/components/FilterPanel/FilterPanel.module.scss';
import { useTheme } from 'hooks/Theme/Theme.context';
import { Color, ColorCode } from 'hooks/Theme/Theme.model';

// TYPINGS
import { IAccount } from 'api/accounts/accounts.typings';
import { FilterItem } from 'store/helpers/store.typings';
import { TagColor } from 'components/Tag/Tag.helpers';

interface FilterStatusProps {
  selectedFilters: FilterItem<IAccount>[];
}

const FilterStatus:React.FC<FilterStatusProps> = ({ selectedFilters }) => {
  const { getThemeColor } = useTheme();
  const dispatch = useDispatch();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = event.target;
    if (checked) {
      dispatch(setAccountFilter({ key: 'status', value: id }));
    } else {
      dispatch(removeAccountFilter({ key: 'status', value: id }));
    }
  };

  // RENDERS
  const options = [
    {
      id: 'Active',
      copy: 'Active',
      color: 'stem',
      backgroundColor: getThemeColor(Color.Primary, ColorCode.C200, '')
    },
    {
      id: 'Inactive',
      copy: 'Inactive',
      color: 'heat',
      backgroundColor: getThemeColor(Color.Tertiary, ColorCode.C200, '')
    }
  ];

  return (
    <FilterControl title="Account status" expanded>
      {options.map((option) => (
        <div key={option.id} className={styles['control-item']}>
          <div>
            <Checkbox
              id={option.id}
              onChange={handleCheckboxChange}
              checked={selectedFilters.filter((item) => item.key === 'status' && item.value.includes(option.id)).length > 0}
            />
          </div>
          <div><Tag copy={option.copy} color={option.color as any} backgroundColor={option.backgroundColor} /></div>
        </div>
      ))}
    </FilterControl>
  );
};

export default FilterStatus;
