import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';
import { useDispatch } from 'react-redux';

// COMPONENTS
import { Dropdown, Tag } from 'components';
import ListShowMore from 'components/ListShowMore/ListShowMore';
import { InfoItem, ListItem, ListToolbar } from 'blocks/components';

// ASSETS
import styles from './ListMappings.module.scss';

// ACTIONS
import { setIsConfigureMappingOpen } from 'store/modules/accounts/accounts.slice';

// HELPERS
import { IMapping } from 'api/accounts/accounts.typings';
import { simpleListWithIcon } from 'components/ActionList/stories/ActionList.stories.helpers';

interface ListMappingsProps {
  mappings: Array<IMapping>,
  title?: string,
}

const ListMappings:React.FC<ListMappingsProps> = ({ mappings, title = '' }) => {
  const [selectedItems, setSelectedItems] = useState<{ [key: string]: boolean }>({});
  const [limitShow, setLimitShow] = useState(10);
  const dispatch = useDispatch();

  // EVENTS
  const handleShowMore = () => {
    setLimitShow(limitShow + 10);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const newSelectedItems: { [key: string]: boolean } = {};

    mappings.forEach((mapping) => {
      newSelectedItems[mapping.id] = isChecked;
    });

    setSelectedItems(newSelectedItems);
  };

  const handleSelectItem = (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [id]: isChecked
    }));
  };

  const handleBodyClick = () => {
    dispatch(setIsConfigureMappingOpen(true));
  };

  // RENDERS
  const filteredMappings = mappings.slice(0, limitShow);

  return (
    <>
      <ListToolbar
        selectedCount={Object.values(selectedItems).filter(Boolean).length}
        onSelect={handleSelectAll}
        rightSlot={<Dropdown label="Actions" options={simpleListWithIcon} color='depth' />}
      />
      <AnimatePresence>
        <motion.ul
          className={styles['list-container']}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {
            filteredMappings.map((mapping) => {
              return (
                <motion.li
                  key={mapping.id}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className={styles['list-item']}>
                    <ListItem
                      selectable
                      isSelected={selectedItems[mapping.id]}
                      onSelect={handleSelectItem(mapping.id)}
                      onBodyClick={handleBodyClick}
                      columns={[
                        { body: <InfoItem title={title} description={mapping.map} />, col: 2.5 },
                        { body: <InfoItem title="Virtual Meter ID" description={mapping.virtualMeterId} />, col: 2 },
                        { body: <InfoItem title="Channel Type" description={mapping.channelType} />, col: 2 },
                        { body: <InfoItem title="Effective Date" description={mapping.effectiveDate} />, col: 2 },
                        { body: <InfoItem title="Usage" description={mapping.usage} />, col: 2 },
                        {
                          body: (
                            <div className={styles['customer-type']}>
                              <Tag
                                copy={mapping.status}
                                size="sm"
                                color="stem"
                              />
                            </div>
                          ),
                          col: 1.5
                        }
                      ]}
                    />
                  </div>
                </motion.li>
              );
            })}
        </motion.ul>
      </AnimatePresence>
      <div className={styles['list-footer']}>
        <ListShowMore total={filteredMappings.length} current={limitShow} onShowMore={handleShowMore} />
      </div>
    </>
  );
};

export default ListMappings;
