import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSelector } from 'react-redux';

// SELECTOR
import { getUsageSelectedYear } from 'store/modules/accounts/accounts.selector';
import { calculateYears } from './BillOverviewChart.helpers';
import colors from 'style/colors';

interface BillOverviewChartProps {
  data: any;
}

const BillOverviewChart:React.FC<BillOverviewChartProps> = ({ data }) => {
  // const [billsPerYear, setBillsPerYear] = useState({});
  const selectedYear = useSelector(getUsageSelectedYear);
  const [billOverviewChart, setBillOverviewChart] = useState({
    chart: {
      type: 'column'
    },
    title: {
      text: ''
    },
    series: [
      {
        type: 'column',
        data: [1, 2, 3],
        color: colors.greyDark
      }
    ],
    credits: {
      enabled: false
    },
    yAxis: {
      title: ''
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      column: {
        pointWidth: 100,
        dataLabels: {
          enabled: true, // enables the data labels on top of the bars
          format: '{point.y:,.1f} kWh'
        }
      }
    },
    xAxis: {
      visible: true
    }
  });

  useEffect(() => {
    if (data) {
      calculateYears(data?.list, setBillOverviewChart, billOverviewChart, selectedYear);
    }
  }, [data, selectedYear]);

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={billOverviewChart}
      />
    </div>
  );
};

export default BillOverviewChart;
