import { Theme } from './Theme.model';

import gridxTheme from './schemas/gridx.theme.json';
import bcHydroTheme from './schemas/bchydro.theme.json';
import conedTheme from './schemas/coned.theme.json';
import energyCoTheme from './schemas/energyco.theme.json';
import pgeTheme from './schemas/pge.theme.json';
import pseTheme from './schemas/pse.theme.json';
import sceTheme from './schemas/sce.theme.json';
import smudTheme from './schemas/smud.theme.json';
import southernTheme from './schemas/southern.theme.json';
import sampleTheme from './schemas/sample.theme.json';
import sclTheme from './schemas/scl.theme.json';

export const defaultTheme: Theme = {} as Theme;

export const energyxTheme: Theme = {
  brand: {
    brandName: 'EnergyX',
    brandLogo: '/images/logos/energyx-logo.svg',
    slug: 'energyx',
    logoWidth: 185
  }
};

export const semiloneTheme: Theme = {
  brand: {
    brandName: 'Seminole Electrics',
    brandLogo: 'https://meltwater-apps-production.s3.amazonaws.com/uploads/images/5769abe8bc9015bf33074256/blobid9_1520526358586.png',
    slug: 'seminole',
    logoWidth: 250
  }
};

export {
  gridxTheme,
  bcHydroTheme,
  conedTheme,
  energyCoTheme,
  pgeTheme,
  pseTheme,
  sceTheme,
  smudTheme,
  southernTheme,
  sampleTheme,
  sclTheme
};
