import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

interface GetRateSummaryQueryArgs {
  contractId?: string | number;
  apiUrl?: string;
}

export const useGetRateSummary = ({ apiUrl = '', contractId = 1 }: GetRateSummaryQueryArgs) => {
  return useQuery({
    queryKey: ['rate-analysis', contractId],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryFn: () => {
      return axios
        .get(`${apiUrl}/${contractId}`)
        .then((res) => {
          return res.data;
        });
    }
  });
};
