import { ICustomerData } from '../../../../../../api/analyze/analyze.typings';
import { Color, ColorCode } from '../../../../../../hooks/Theme/Theme.model';

import colors from '../../../../../../style/colors';

export const countQuality = (customers: Array<ICustomerData>) => {
  return customers.reduce((accumulator: any, currentValue) => {
    if (currentValue.quality in accumulator) {
      accumulator[currentValue.quality] += 1;
    } else {
      accumulator[currentValue.quality] = 1;
    }
    return accumulator;
  }, {});
};

export const getCustomColors = (key: string, getThemeColor: Function) => {
  switch (key) {
  case 'successful':
    return getThemeColor(Color.Secondary, ColorCode.C100, colors.ember);
  case 'warning':
    return getThemeColor(Color.Quaternary, ColorCode.C100, colors.energy);
  case 'error':
    return getThemeColor(Color.Primary, ColorCode.C100, colors.amp);
  default:
    return '#333';
  }
};

export const transformData = (customers: Array<ICustomerData>, getThemeColor: Function) => {
  return Object.entries(countQuality(customers)).map(([key, value]) => {
    return {
      name: key,
      y: value as number,
      color: getCustomColors(key, getThemeColor)
    };
  });
};
