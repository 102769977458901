import React, { useContext, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useLocation, useNavigationType } from 'react-router-dom';

// COMPONENTS
import ProjectList from './sections/ProjectList/ProjectList';
import ProjectDetails from './sections/ProjectDetails/ProjectDetails';
import RateComparison from './sections/RateComparison/RateComparison';

// ASSETS
import styles from './ExploreWidget.module.scss';

// HELPERS
import { ActionTypes, ExploreContext, ExploreProvider } from './helpers/ExploreContext';
import CalculationSteps from './sections/CalculationSteps/CalculationSteps';

const ExploreWidget:React.FC = () => {
  const location = useLocation();
  const navigationType = useNavigationType();
  const { dispatch } = useContext(ExploreContext);
  useEffect(() => {
    if (navigationType === 'POP') {
      // back button was pressed, close all drawers
      dispatch({
        type: ActionTypes.OVERRIDE_STATE,
        payload: {
          isProjectSettingsOpen: false,
          isProjectDeleteOpen: false,
          isProjectSaveOpen: false,
          isCostEstimatesOpen: false,
          isEnergyResourcesOpen: false,
          isSolarPanelsOpen: false,
          isElectricVehicleOpen: false,
          isHeatPumpOpen: false,
          isRatesOpen: false,
          isRateDetailsOpen: false,
          appliedResource: null
        }
      });
    }
  }, [location]);

  const queryParams = new URLSearchParams(location.search);
  let ComponentToRender;

  switch (queryParams.keys().next().value) {
  case 'project':
    ComponentToRender = CalculationSteps;
    break;
  case 'rateComparison':
    ComponentToRender = RateComparison;
    break;
  default:
    ComponentToRender = ProjectList;
  }

  return (
    <div className={styles.container}>
      <ComponentToRender/>
    </div>
  );
};

const queryClient = new QueryClient();

interface RootWidgetProps {
  contractId: string;
  apiUrl: string;
  content?: any;
}

const RootWidget:React.FC<RootWidgetProps> = ({ contractId, content, apiUrl }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={true} />}
      <ExploreProvider
        contractId={contractId}
        content={content}
        apiUrl={apiUrl}
      >
        <ExploreWidget/>
      </ExploreProvider>
    </QueryClientProvider>
  );
};

export default RootWidget;
