// HELPERS
import { Color, ColorCode } from 'hooks/Theme/Theme.model';
import colors from 'style/colors';

export const getCustomerTypeColor = (customerType: string) => {
  switch (customerType) {
  case 'Active':
    return 'altitude';
  default:
    return 'primary';
  }
};

export const getCustomerTypeCustomColor = (customerType: string, getThemeColor: Function) => {
  switch (customerType) {
  case 'Active':
    return getThemeColor(Color.Primary, ColorCode.C200, colors.stem);
  case 'Inactive':
    return getThemeColor(Color.Secondary, ColorCode.C200, colors.heat);
  default:
    return '#333';
  }
};

export const getCustomerTypeTextColor = (customerType: string, hasThemePalette: boolean) => {
  switch (customerType) {
  case 'Active':
    return hasThemePalette ? '#fff' : '';
  case 'Inactive':
    return hasThemePalette ? '#fff' : '';
  default:
    return '#333';
  }
};
