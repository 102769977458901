import React, { useState, KeyboardEvent } from 'react';

// COMPONENTS
import Icon from '../../../components/Icon/Icon';

// ASSETS
import styles from './FIlterControl.module.scss';

interface FilterControlProps {
  title: string;
  children?: React.ReactNode;
  expanded?: boolean;
}

const FilterControl:React.FC<FilterControlProps> = ({ title, children, expanded = false }) => {
  const [isOpen, setIsOpen] = useState(expanded);

  // EVENTS
  const handleToogleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleToogleOpen();
    }
  };

  return (
    <div className={styles['filter-control']}>
      <div
        className={styles['filter-control__header']}
        onClick={handleToogleOpen}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <span className={styles['filter-control__title']}>{title}</span>
        <span className={styles['filter-control__icon']}>
          {isOpen ? <Icon.ChevronDown/> : <Icon.ChevronRight/>}
        </span>
      </div>
      {
        isOpen && (
          <div className={styles['filter-control__body']}>
            {children}
          </div>
        )
      }
    </div>
  );
};

export default FilterControl;
