import { useQuery, useMutation } from '@tanstack/react-query';
import { useAxios } from './useAxios';
import { useContext } from 'react';
import { ActionTypes, ExploreContext } from '../helpers/ExploreContext';
import { useNavigate } from 'react-router-dom';
import { Rate, Resource } from './projects.typings';
interface ProjectListQueryArgs {
  contractId?: string | number;
  mock?: any;
}

export const useGetProjectList = ({ contractId = '', mock }: ProjectListQueryArgs) => {
  const axios = useAxios();

  const { dispatch } = useContext(ExploreContext);

  return useQuery({
    queryKey: ['project-list', contractId],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryFn: () => {
      return axios
        .get(`/api/projects/${contractId}`, {
          headers: {
            'x-mock-projects-count': mock?.projectsCount || 0
          }
        })
        .then((res) => {
          // Intercept the response and set manually the data. Example below:
          const tempData = [
            {
              id: 256,
              contractId: '8511445809',
              name: 'Hyundai Ioniq 5',
              rateCode: 'TOU',
              resources: [
                {
                  id: 113,
                  name: 'ev',
                  attributes: {
                    weekendCharging: 'Unpredictable',
                    savingsPerYear: '-360',
                    weekdayDistance: '82',
                    year: '2023',
                    chargingPower: '1.6',
                    model: 'Ioniq 5 SEL',
                    make: 'Hyundai',
                    weekendDistance: '18',
                    weekdayCharging: '3'
                  }
                }
              ],
              savingsPerYear: 520,
              totalCostPerYear: 3045,
              createdTimestamp: '2023-11-30T07:29:29.000+00:00',
              updatedTimestamp: '2023-12-12T13:14:19.000+00:00'
            },
            {
              id: 259,
              contractId: '8511445809',
              name: 'Tesla Model Y',
              rateCode: 'TOU-EV',
              resources: [
                {
                  id: 113,
                  name: 'ev',
                  attributes: {
                    weekendCharging: 'Unpredictable',
                    savingsPerYear: '-380',
                    weekdayDistance: '82',
                    year: '2023',
                    chargingPower: '1.6',
                    model: 'Model Y Long Range',
                    make: 'Tesla',
                    weekendDistance: '18',
                    weekdayCharging: '3'
                  }
                }
              ],
              savingsPerYear: 500,
              totalCostPerYear: 3260,
              createdTimestamp: '2023-11-30T07:29:29.000+00:00',
              updatedTimestamp: '2023-12-12T13:14:19.000+00:00'
            },
            {
              id: 255,
              contractId: '8511445809',
              name: 'Ford Mach E',
              rateCode: 'TOU',
              resources: [
                {
                  id: 113,
                  name: 'ev',
                  attributes: {
                    weekendCharging: 'Unpredictable',
                    savingsPerYear: '-400',
                    weekdayDistance: '82',
                    year: '2023',
                    chargingPower: '1.6',
                    model: 'Model Y Long Range',
                    make: 'Tesla',
                    weekendDistance: '18',
                    weekdayCharging: '3'
                  }
                }
              ],
              savingsPerYear: 480,
              totalCostPerYear: 3325,
              createdTimestamp: '2023-11-30T07:29:29.000+00:00',
              updatedTimestamp: '2023-12-12T13:14:19.000+00:00'
            }
          ];

          dispatch({
            type: ActionTypes.SET_APPLIED_RESOURCE,
            payload: null
          });
          dispatch({
            type: ActionTypes.SET_APPLIED_RATE_CODE,
            payload: null
          });

          // return res.data;
          return tempData;
        });
    }
  });
};

export const useCostSavingDetails = ({ contractId = '' }) => {
  const axios = useAxios();
  const { state, dispatch } = useContext(ExploreContext);

  return useMutation({
    mutationFn: (body: any) => {
      return axios.post(`api/resources/customer/${contractId}/savings`, body).then((res) => res.data);
    },
    onSuccess: (data) => {
      const rateCode = state.projectMode === 'NEW' ? state.appliedRateCode : state.selectedRate?.ratePlanCode;
      const selectedCostSavings = data.rateDetails?.filter((item: any) => {
        return item.ratePlanCode === rateCode;
      });
      if (data.compareRatesDetails) {
        dispatch({
          type: ActionTypes.UPDATE_APP_CACHE,
          payload: { compareRatesDetails: data.compareRatesDetails, costSavings: selectedCostSavings[0]?.totalSavings }
        });
        dispatch({ type: ActionTypes.REFRESH_COST_SAVINGS, payload: false });
      }
    }
  });
};

export const useCreateNewProject = () => {
  const axios = useAxios();
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: (type: any) => {
      return axios
        .post('/api/projects', {
          type: type || null,
          userId: 23
        })
        .then((res) => res.data);
    },
    onMutate: () => {
      navigate('/explore?project');
    },
    onSuccess: (data) => {
      navigate(`/explore?project&projectID=${data.id}`);
    }
  });

  return mutate;
};

export const useCreateProject = ({ contractId = '' }) => {
  const axios = useAxios();
  const { dispatch } = useContext(ExploreContext);

  return useQuery({
    queryKey: ['create-project'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryFn: () => {
      return axios.get(`api/projects/createProject/${contractId}`).then((res) => res.data);
    },
    onSuccess: (data) => {
      const selectedRate = data.rateDetails.find((rate: Rate) => rate.scenarioTags.includes('base'));

      dispatch({
        type: ActionTypes.SET_SELECTED_RATE,
        payload: selectedRate
      });

      dispatch({
        type: ActionTypes.UPDATE_APP_CACHE,
        payload: {
          rateDetails: data.rateDetails,
          compareRatesDetails: data.compareRatesDetails
        }
      });
    }
  });
};

interface CompareRatesPostArgs {
  resources: Resource[];
  selectedRateCode: string;
}

export const useCompareRatesPost = () => {
  const axios = useAxios();
  const { state, dispatch } = useContext(ExploreContext);
  const { mutate } = useMutation({
    mutationFn: ({ resources, selectedRateCode }: CompareRatesPostArgs) => {
      return axios
        .post(`/api/resources/customer/${state.contractId}/savings`, {
          selectedRateCode,
          resources
        })
        .then((res) => res.data);
    },
    onSuccess: (data) => {
      if (data.compareRatesDetails) {
        dispatch({ type: ActionTypes.UPDATE_APP_CACHE, payload: { compareRatesDetails: data.compareRatesDetails } });
      }
    }
  });

  return mutate;
};

export const useCompareCalculate = () => {
  const axios = useAxios();
  const { state, dispatch } = useContext(ExploreContext);
  const { projectResources: resources = [] } = state.cache;

  return useQuery({
    queryKey: ['compare-calculate'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryFn: () => {
      return axios
        .post(`/api/resources/customer/${state.contractId}`, {
          resources
        })
        .then((res) => res.data);
    },
    onSuccess: (data) => {
      if (data.compareRatesDetails) {
        dispatch({ type: ActionTypes.UPDATE_APP_CACHE, payload: { compareRatesDetails: data.compareRatesDetails } });
      }
    }
  });
};
