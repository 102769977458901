import React, { Fragment } from 'react';

import Header from '../../components/Header/Header';
import TextInput from '../../components/TextInput/TextInput';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import Link from '../../components/Link/Link';
import Text from '../../components/Text/Text';

import './input-page.scss';

interface Props {
    size?: 'sm' | 'md' | 'lg' | 'xl',
};

const InputPage: React.FC<Props> = () => {
  const renderInputField = (size: 'sm' | 'md' | 'lg' | 'xl') => {
    return (
      <Fragment>
        <TextInput
          placeholder='Input Value'
          size={size}
        />
        <TextInput
          placeholder='Input Value'
          size={size}
          disabled
        />
        <TextInput
          placeholder='Input Value'
          size={size}
          error
        />
      </Fragment>
    );
  };
  const renderSelect = (size: 'sm' | 'md' | 'lg' | 'xl') => {
    const defaultArray = ['Input Value', 'Input Value', 'Input Value'];

    return (
      <Fragment>
        <CustomSelect
          // @ts-ignore
          selects={defaultArray}
          size={size}
        />
        <CustomSelect
          // @ts-ignore
          selects={defaultArray}
          size={size}
        />
        <CustomSelect
          // @ts-ignore
          selects={defaultArray}
          size={size}
        />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Header
        className='design-studio pad--b-100 pad--l-128 pad--t-100'
        color='stone'
        text='Input Box'
      >

        <Text
          className='max-width--900  title--sm'
          color='white'
        >
          Rogue galaxies a mote of dust suspended in a sunbeam are creatures of the cosmos the ash of stellar alchemy Drake Equation. Vanquish the impossible venture a billion trillion how far away the only home we&apos;ve ever known dream of the mind&apos;s eye.
        </Text>
        <Link
          className='underline dis--block mar--t-60 body--xl'
          color='white'
          copy='See Our Guidelines'
          href='/'
        />
      </Header>
      <div
        className='mar--t-60 mar--l-96 max-width--lg temp--min-width--md'
      >
        <div
          className='pad--r-80'
        >
          <div className='flex mar--t-40 jus--space-between'>
            <div className='input-page--label'>
              <Text
                className='max-width--900 pad--t-40 title--sm'
                color='stone'
                variant='body1'
              >
                Default
              </Text>
            </div>
            <div className='input-page--label'>
              <Text
                className='max-width--900 pad--t-40 title--sm'
                color='stone'
                variant='body1'
              >
                Disabled
              </Text>
            </div>
            <div className='input-page--label'>
              <Text
                className='max-width--900 pad--t-40 title--sm'
                color='stone'
                variant='body1'
              >
                Error
              </Text>
            </div>
          </div>
          <div className='flex mar--t-40 jus--space-between'>
            {renderInputField('xl')}
          </div>
          <div className='flex mar--t-40 jus--space-between'>
            {renderInputField('lg')}
          </div>
          <div className='flex mar--t-40 jus--space-between'>
            {renderInputField('md')}
          </div>
          <div className='flex mar--t-40 jus--space-between'>
            {renderInputField('sm')}
          </div>
        </div>
        <div
          className='pad--t-80 pad--b-80 pad--r-80'
        >
          <div className='flex mar--t-40 jus--space-between'>
            {renderSelect('xl')}
          </div>
          <div className='flex mar--t-40 jus--space-between'>
            {renderSelect('lg')}
          </div>
          <div className='flex mar--t-40 jus--space-between'>
            {renderSelect('md')}
          </div>
          <div className='flex mar--t-40 jus--space-between'>
            {renderSelect('sm')}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default InputPage;
