import React, { useContext, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

// COMPONENTS
import Drawer from 'components/Drawer/Drawer';
import Box from 'components/Box/Box';
import Button from 'components/Button/Button';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';

// ASSETS
import styles from './DrawerSingleModifier.module.scss';

// HELPERS
import { ActionTypes, ExploreContext } from 'widget/ExploreWidget/helpers/ExploreContext';
import useMediaQuery, { breakpoints } from 'hooks/useMediaQuery';
import { getDefaultValues, useSingleModifier } from './DrawerSingleModifier.helpers';
import colors from 'style/colors';

const DrawerSingleModifier = () => {
  const { state, dispatch } = useContext(ExploreContext);
  const [resourceToEdit, setResourceToEdit] = useState<any>();
  const { isOpen, title, description, icon, image, ModifierForm } = useSingleModifier();
  const isMobile = useMediaQuery(breakpoints.mobileMedium);
  const methods = useForm({
    defaultValues: {
      ...getDefaultValues(state.openSingleModifier)
    }
  });
  const queryClient = useQueryClient();
  const { sectionTitle, resources: resourcesContent } = state.content.resourceList;
  let appliedResource = state.appliedResource || [];

  // EFFECTS
  useEffect(() => {
    if (state.openSingleModifier) {
      methods.reset(getDefaultValues(state.openSingleModifier));
    }
  }, [state.openSingleModifier]);

  // EVENTS
  const handleClose = () => {
    dispatch({ type: ActionTypes.SET_SINGLE_MODIFIER_OPEN, payload: null });
  };

  const handleApplyResources = async (addResource: any) => {
    if (resourceToEdit) {
      appliedResource = appliedResource.map((resource: any) => {
        if (_.isEqual(resource, resourceToEdit)) return { ...resource, attributes: addResource.attributes };
        return resource;
      });
    } else appliedResource.push(addResource);

    dispatch({ type: ActionTypes.SET_APPLIED_RESOURCE, payload: [...appliedResource] });
    dispatch({ type: ActionTypes.REFRESH_COST_SAVINGS, payload: true });
    queryClient.removeQueries(['compare-calculate']);

    setResourceToEdit(null);
  };

  const onSubmit = (data: any) => {
    const resourceList = {
      id: null,
      name: state.openSingleModifier,
      attributes: {
        ...data
      }
    };

    handleApplyResources(resourceList);
    dispatch({ type: ActionTypes.SET_SINGLE_MODIFIER_OPEN, payload: null });
  };

  return (
    <FormProvider {...methods}>
      <Drawer
        open={isOpen}
        onClose={handleClose}
        anchor="right"
        width={isMobile ? '100vw' : 400}
        BackdropProps={{ invisible: false }}
        footer={
          <Box display="flex" justifyContent="end">
            <Box mr={1.5}>
              <Button copy="Close" color="secondary" variant="outlined" size="md" onClick={handleClose} />
            </Box>

            <Button copy="Apply" color="secondary" variant="contained" size="md" onClick={methods.handleSubmit(onSubmit)} />
          </Box>
        }
        bodyStyle={{
          backgroundColor: colors.colorBackgroundDisplayNeutral
        }}
        footerStyle={{
          backgroundColor: colors.white,
          borderTop: `1px solid ${colors.wave}`
        }}
      >
        <Box mb={3} my={3}>
          <Box mb={3}>
            <Breadcrumb
              type="backlink"
              items={[
                {
                  href: '#',
                  title: 'Clean energy technologies'
                }
              ]}
            />
          </Box>
          <div className={styles.header}>
            <div className={styles.icon}>
              <div className={styles['icon-item']}>
                <img src={icon || ''} alt={`Icon representing ${title}`} />
              </div>
            </div>
            <p className={styles.title}>{title}</p>
          </div>
          <div className={styles.modifierImage} style={{ backgroundImage: `url(${image})` }} />
          {description && <p className={styles.description}>{description}</p>}
        </Box>
        <div>
          {
            ModifierForm ? <ModifierForm/> : null
          }
        </div>
      </Drawer>
    </FormProvider>
  );
};

export default DrawerSingleModifier;
