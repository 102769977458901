import React from 'react';
import { Icon } from '../..';

export const simpleListWithIcon = [
  {
    startIcon: <Icon.Folder size={18}/>,
    content: 'Add to project',
    onClick: () => {}
  },
  {
    startIcon: <Icon.Copy size={18} />,
    content: 'Clone',
    onClick: () => {}
  },
  {
    startIcon: <Icon.Download size={18} />,
    content: 'Export',
    onClick: () => {}
  },
  {
    startIcon: <Icon.Trash size={18} />,
    content: 'Delete',
    onClick: () => {}
  }
];
