import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';

// COMPONENTS
import { Dropdown, Tag } from 'components';
import ListShowMore from 'components/ListShowMore/ListShowMore';
import { InfoItem, ListItem, ListToolbar } from 'blocks/components';

// ASSETS
import styles from './ListEvents.module.scss';

// ACTIONS
import { setEventHandlerOpen } from '../../../../store/modules/events/events.slice';

// HELPERS
import { simpleListWithIcon } from 'components/ActionList/stories/ActionList.stories.helpers';
import { IEvent } from 'api/events/events.typings';

interface ListEventsProps {
  events: Array<IEvent>
}

const ListEvents:React.FC<ListEventsProps> = ({ events }) => {
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState<{ [key: string]: boolean }>({});
  const [limitShow, setLimitShow] = useState(10);

  // EVENTS
  const handleShowMore = () => {
    setLimitShow(limitShow + 10);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const newSelectedItems: { [key: string]: boolean } = {};

    events.forEach((event) => {
      newSelectedItems[event.id] = isChecked;
    });

    setSelectedItems(newSelectedItems);
  };

  const handleSelectItem = (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [id]: isChecked
    }));
  };

  const handleEventClick = (eventId: number) => {
    dispatch(setEventHandlerOpen({ open: true, id: eventId }));
  };

  // RENDERS
  const filteredEvents = events.slice(0, limitShow);

  return (
    <>
      <ListToolbar
        selectedCount={Object.values(selectedItems).filter(Boolean).length}
        onSelect={handleSelectAll}
        rightSlot={<Dropdown label="Actions" options={simpleListWithIcon} color='depth' />}
      />
      <AnimatePresence>
        <motion.ul
          className={styles['list-container']}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {
            filteredEvents.map((event) => {
              return (
                <motion.li
                  key={event.id}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className={styles['list-item']}>
                    <ListItem
                      selectable
                      isSelected={selectedItems[event.id]}
                      onSelect={handleSelectItem(event.id)}
                      onBodyClick={() => handleEventClick(event.id)}
                      columns={[
                        { body: <InfoItem title="Event ID" description={event.eventId} />, col: 1 },
                        { body: <InfoItem title="ID" description={event.id} />, col: 2 },
                        { body: <InfoItem title="Batch ID" description={event.batchId} />, col: 1 },
                        { body: <InfoItem title="Code" description={event.code} />, col: 1 },
                        { body: <InfoItem title="Ticket" description={event.ticket} />, col: 1.5 },
                        { body: <InfoItem title="Created" description={event.created} />, col: 2.5 },
                        { body: <InfoItem title="Assigned" description={event.assigned} />, col: 1.5 },
                        {
                          body: (
                            <div className={styles['customer-type']}>
                              <Tag
                                copy={event.status}
                                size="sm"
                                color={event.status === 'Exception' ? 'heat' : 'stem'}
                              />
                            </div>
                          ),
                          col: 1.5
                        }
                      ]}
                    />
                  </div>
                </motion.li>
              );
            })}
        </motion.ul>
      </AnimatePresence>
      <div className={styles['list-footer']}>
        <ListShowMore total={40} current={limitShow} onShowMore={handleShowMore} />
      </div>
    </>
  );
};

export default ListEvents;
