export const projectHyundai = {
  id: 256,
  contractId: '8511445809',
  name: 'Hyundai Ioniq 5',
  rateCode: 'TOU',
  resources: [
    {
      id: 113,
      name: 'ev',
      attributes: {
        weekendCharging: 'Unpredictable',
        savingsPerYear: '-360',
        weekdayDistance: '82',
        year: '2023',
        chargingPower: '1.6',
        model: 'Ioniq 5 SEL',
        make: 'Hyundai',
        weekendDistance: '18',
        weekdayCharging: '3'
      }
    }
  ],
  savingsPerYear: 520,
  createdTimestamp: '2023-11-30T07:29:29.000+00:00',
  updatedTimestamp: '2023-12-12T13:14:19.000+00:00',
  compareInfo: {
    contractId: '8511445809',
    analysisStartDate: '2023-06-16',
    analysisEndDate: '2024-01-15',
    rateEffectiveDate: '2024-01-01',
    analysisDate: '2024-01-18',
    billCycleCount: 7,
    currentRateName: 'TOU',
    currentProfileIndicator: '',
    currentAmount: 1570,
    savingsCurrentRate: 185,
    meteraccountId: '6310042005',
    entityId: '8511609883',
    premiseId: '6310042076',
    servicepointId: '6310042005',
    personId: '8615220624',
    message: 'D6F, U',
    rateDetails: [
      {
        rateMarketingDescription: 'This rate plan is best for you if you can reduce some of your electricity usage during the higher priced  peak time of 4 p.m. to 9 p.m. every day (including weekends) and can stay below or close to your Baseline Allowance.',
        scenarioIndex: 'alt2',
        scenarioName: 'TOU|TOUFS',
        scenarioTags: [
          'base'
        ],
        totalSavings: 0,
        rateMarketingName: 'Time-of-Use (Peak Pricing 4-9 p.m. Every Day) E-TOU-C',
        totalAmt: 1570,
        ratePlanCode: 'TOU',
        profileIndicator: '',
        monthlyDetails: [
          {
            billMonth: 6,
            seasonCode: 'To be implemented',
            billStartDate: '2023-06-16',
            monthlyKwh: 467.978633,
            billEndDate: '2023-07-16',
            monthlyCost: 231.84,
            tagAttributes: {
              'Flat Fee': 2.4,
              'Net Energy': 0,
              'Energy Delivered': 229.44
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -23.24,
                percentage: -10
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.26,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 234.21,
                percentage: 101
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 18.47,
                percentage: 8
              }
            ],
            monthlySavings: 0
          },
          {
            billMonth: 7,
            seasonCode: 'To be implemented',
            billStartDate: '2023-07-17',
            monthlyKwh: 503.684167,
            billEndDate: '2023-08-14',
            monthlyCost: 254.08,
            tagAttributes: {
              'Flat Fee': 2.63,
              'Net Energy': 0,
              'Energy Delivered': 251.45
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -21.74,
                percentage: -9
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.48,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 244.26,
                percentage: 96
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 28.93,
                percentage: 11
              }
            ],
            monthlySavings: 0
          },
          {
            billMonth: 8,
            seasonCode: 'To be implemented',
            billStartDate: '2023-08-15',
            monthlyKwh: 473.831167,
            billEndDate: '2023-09-13',
            monthlyCost: 236.61,
            tagAttributes: {
              'Flat Fee': 2.45,
              'Net Energy': 0,
              'Energy Delivered': 234.16
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -22.48,
                percentage: -10
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.31,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 232.02,
                percentage: 98
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 24.619999999999997,
                percentage: 10
              }
            ],
            monthlySavings: 0
          },
          {
            billMonth: 9,
            seasonCode: 'To be implemented',
            billStartDate: '2023-09-14',
            monthlyKwh: 510.220067,
            billEndDate: '2023-10-15',
            monthlyCost: 231.95,
            tagAttributes: {
              'Flat Fee': 2.42,
              'Net Energy': 0,
              'Energy Delivered': 229.53
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -33.17,
                percentage: -14
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 111.95,
                percentage: 48
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.26,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 14.34,
                percentage: 6
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 126.05,
                percentage: 54
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 10.36,
                percentage: 4
              }
            ],
            monthlySavings: 0
          },
          {
            billMonth: 10,
            seasonCode: 'To be implemented',
            billStartDate: '2023-10-16',
            monthlyKwh: 468.371267,
            billEndDate: '2023-11-13',
            monthlyCost: 191.85,
            tagAttributes: {
              'Flat Fee': 2.0100000000000002,
              'Net Energy': 0,
              'Energy Delivered': 189.84
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -39.49,
                percentage: -21
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 207.06,
                percentage: 108
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.87,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 22.27,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              }
            ],
            monthlySavings: 0
          },
          {
            billMonth: 11,
            seasonCode: 'To be implemented',
            billStartDate: '2023-11-14',
            monthlyKwh: 527.013167,
            billEndDate: '2023-12-13',
            monthlyCost: 219.51,
            tagAttributes: {
              'Flat Fee': 2.3000000000000003,
              'Net Energy': 0,
              'Energy Delivered': 217.21
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -40.85,
                percentage: -19
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 232.57,
                percentage: 106
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.14,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 25.49,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              }
            ],
            monthlySavings: 0
          },
          {
            billMonth: 12,
            seasonCode: 'To be implemented',
            billStartDate: '2023-12-14',
            monthlyKwh: 502.991433,
            billEndDate: '2024-01-15',
            monthlyCost: 203.42,
            tagAttributes: {
              'Flat Fee': 2.13,
              'Net Energy': 0,
              'Energy Delivered': 201.29
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -44.93,
                percentage: -22
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 223.35,
                percentage: 110
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.98,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 22.87,
                percentage: 11
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              }
            ],
            monthlySavings: 0
          }
        ]
      },
      {
        rateMarketingDescription: 'This rate plan is for customers with an Electric Vehicle (EV) battery storage  and/or electric heat pump that can charge during off-peak hours and shift other electricity usage to off-peak hours. Customers using over 800% of Baseline Allowance in the last 12 months are ineligible.',
        scenarioIndex: 'alt4',
        scenarioName: 'SPP|SSPFS',
        scenarioTags: [
          'best'
        ],
        totalSavings: 185,
        rateMarketingName: 'Home Charging SPP',
        totalAmt: 1385,
        ratePlanCode: 'SPP',
        profileIndicator: '',
        monthlyDetails: [
          {
            billMonth: 6,
            seasonCode: 'To be implemented',
            billStartDate: '2023-06-16',
            monthlyKwh: 467.978633,
            billEndDate: '2023-07-16',
            monthlyCost: 188.71,
            tagAttributes: {
              'Flat Fee': 1.98,
              'Net Energy': 0,
              'Energy Delivered': 186.73
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 43.58,
                percentage: 23
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.84,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 123.5,
                percentage: 65
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 19.65,
                percentage: 10
              }
            ],
            monthlySavings: 43.129999999999995
          },
          {
            billMonth: 7,
            seasonCode: 'To be implemented',
            billStartDate: '2023-07-17',
            monthlyKwh: 503.684167,
            billEndDate: '2023-08-14',
            monthlyCost: 208.43,
            tagAttributes: {
              'Flat Fee': 2.1799999999999997,
              'Net Energy': 0,
              'Energy Delivered': 206.25
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 48.82,
                percentage: 23
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.03,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 126.68,
                percentage: 61
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 30.75,
                percentage: 15
              }
            ],
            monthlySavings: 45.650000000000006
          },
          {
            billMonth: 8,
            seasonCode: 'To be implemented',
            billStartDate: '2023-08-15',
            monthlyKwh: 473.831167,
            billEndDate: '2023-09-13',
            monthlyCost: 193.74,
            tagAttributes: {
              'Flat Fee': 2.03,
              'Net Energy': 0,
              'Energy Delivered': 191.71
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 43.2,
                percentage: 22
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.89,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 122.33,
                percentage: 63
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 26.18,
                percentage: 14
              }
            ],
            monthlySavings: 42.870000000000005
          },
          {
            billMonth: 9,
            seasonCode: 'To be implemented',
            billStartDate: '2023-09-14',
            monthlyKwh: 510.220067,
            billEndDate: '2023-10-15',
            monthlyCost: 204.18,
            tagAttributes: {
              'Flat Fee': 2.15,
              'Net Energy': 0,
              'Energy Delivered': 202.03
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 20.74,
                percentage: 10
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 62.59,
                percentage: 31
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.99,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 14.74,
                percentage: 7
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 68.18,
                percentage: 33
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 24.77,
                percentage: 12
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 11.01,
                percentage: 5
              }
            ],
            monthlySavings: 27.769999999999982
          },
          {
            billMonth: 10,
            seasonCode: 'To be implemented',
            billStartDate: '2023-10-16',
            monthlyKwh: 468.371267,
            billEndDate: '2023-11-13',
            monthlyCost: 185.17,
            tagAttributes: {
              'Flat Fee': 1.9500000000000002,
              'Net Energy': 0,
              'Energy Delivered': 183.22
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 118.34,
                percentage: 64
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.81,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 22.9,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 41.98,
                percentage: 23
              }
            ],
            monthlySavings: 6.680000000000007
          },
          {
            billMonth: 11,
            seasonCode: 'To be implemented',
            billStartDate: '2023-11-14',
            monthlyKwh: 527.013167,
            billEndDate: '2023-12-13',
            monthlyCost: 208.88,
            tagAttributes: {
              'Flat Fee': 2.2,
              'Net Energy': 0,
              'Energy Delivered': 206.68
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 132.09,
                percentage: 63
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.04,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 26.21,
                percentage: 13
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 48.38,
                percentage: 23
              }
            ],
            monthlySavings: 10.629999999999995
          },
          {
            billMonth: 12,
            seasonCode: 'To be implemented',
            billStartDate: '2023-12-14',
            monthlyKwh: 502.991433,
            billEndDate: '2024-01-15',
            monthlyCost: 196.95,
            tagAttributes: {
              'Flat Fee': 2.07,
              'Net Energy': 0,
              'Energy Delivered': 194.88000000000002
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 130.86,
                percentage: 66
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.92,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 23.520000000000003,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15000000000000002,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 40.5,
                percentage: 21
              }
            ],
            monthlySavings: 6.469999999999999
          }
        ]
      }
    ],
    statusCode: '',
    statusDescription: '',
    compareRatesDetails: {
      currency: 'USD',
      charts: {
        key: 0,
        seriesData: [
          [
            {
              name: 'Flat Fee',
              data: [
                2.4,
                2.63,
                2.45,
                2.42,
                2.0100000000000002,
                2.3000000000000003,
                2.13
              ]
            },
            {
              name: 'Net Energy',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            },
            {
              name: 'Energy Delivered',
              data: [
                229.44,
                251.45,
                234.16,
                229.53,
                189.84,
                217.21,
                201.29
              ]
            },
            {
              name: 'Rate',
              data: [
                231.84,
                254.08,
                236.61,
                231.95,
                191.85,
                219.51,
                203.42
              ]
            },
            {
              name: 'Increase',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            }
          ],
          [
            {
              name: 'Flat Fee',
              data: [
                1.98,
                2.1799999999999997,
                2.03,
                2.15,
                1.9500000000000002,
                2.2,
                2.07
              ]
            },
            {
              name: 'Net Energy',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            },
            {
              name: 'Energy Delivered',
              data: [
                186.73,
                206.25,
                191.71,
                202.03,
                183.22,
                206.68,
                194.88000000000002
              ]
            },
            {
              name: 'Rate',
              data: [
                231.84,
                254.08,
                236.61,
                231.95,
                191.85,
                219.51,
                203.42
              ]
            },
            {
              name: 'Increase',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            }
          ],
          [
            {
              name: 'Flat Fee',
              data: [
                2.3400000000000003,
                2.55,
                2.37,
                2.4699999999999998,
                2.18,
                2.49,
                2.31
              ]
            },
            {
              name: 'Net Energy',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            },
            {
              name: 'Energy Delivered',
              data: [
                222.7616896458,
                243.0297578022,
                226.5876828222,
                235.0321879962,
                206.7103047882,
                236.1741529422,
                219.4613313504
              ]
            },
            {
              name: 'Rate',
              data: [
                231.84,
                254.08,
                236.61,
                231.95,
                191.85,
                219.51,
                203.42
              ]
            },
            {
              name: 'Increase',
              data: [
                0,
                0,
                0,
                3.0821879962000196,
                14.860304788200011,
                16.66415294220002,
                16.041331350400014
              ]
            }
          ],
          [
            {
              name: 'Flat Fee',
              data: [
                2.24,
                2.4299999999999997,
                2.2800000000000002,
                2.47,
                2.27,
                2.5500000000000003,
                2.44
              ]
            },
            {
              name: 'Net Energy',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            },
            {
              name: 'Energy Delivered',
              data: [
                213.41000000000003,
                231.53,
                217.13,
                234.4,
                215.95999999999998,
                242.86,
                231.76
              ]
            },
            {
              name: 'Rate',
              data: [
                231.84,
                254.08,
                236.61,
                231.95,
                191.85,
                219.51,
                203.42
              ]
            },
            {
              name: 'Increase',
              data: [
                0,
                0,
                0,
                2.450000000000017,
                24.109999999999985,
                23.350000000000023,
                28.340000000000003
              ]
            }
          ],
          [
            {
              name: 'Flat Fee',
              data: [
                17.6,
                16.799999999999997,
                17.15,
                18.13,
                16.299999999999997,
                17.03,
                18.42
              ]
            },
            {
              name: 'Net Energy',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            },
            {
              name: 'Energy Delivered',
              data: [
                293.22,
                321.25,
                300.18,
                286.38,
                232.81,
                262.07,
                249.63
              ]
            },
            {
              name: 'Rate',
              data: [
                231.84,
                254.08,
                236.61,
                231.95,
                191.85,
                219.51,
                203.42
              ]
            },
            {
              name: 'Increase',
              data: [
                61.380000000000024,
                67.16999999999999,
                63.56999999999999,
                54.43000000000001,
                40.96000000000001,
                42.56,
                46.21000000000001
              ]
            }
          ]
        ],
        chartSeriesKeys: [
          'Flat Fee',
          'Net Energy',
          'Energy Delivered',
          'Rate',
          'Increase'
        ],
        data: []
      },
      availableRates: [
        'Time-of-Use (Peak Pricing 4-9 p.m. Every Day) E-TOU-C',
        'Home Charging EV2-A',
        'Tiered Rate Plan E-1',
        'Time-of-Use (Peak Pricing 5-8 p.m. Weekdays) E-TOU-D',
        'Electric Home (E-ELEC)'
      ],
      months: [
        {
          key: 1,
          firstColumnAttr: 6,
          compareRate: [
            {
              dif: 0,
              total: 231.84,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.4,
                'Net Energy': 0,
                'Energy Delivered': 229.44
              }
            },
            {
              dif: -43.129999999999995,
              total: 188.71,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 1.98,
                'Net Energy': 0,
                'Energy Delivered': 186.73
              }
            },
            {
              dif: -6.740000000000009,
              total: 225.1,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.3400000000000003,
                'Net Energy': 0,
                'Energy Delivered': 222.7616896458
              }
            },
            {
              dif: -16.189999999999998,
              total: 215.65,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.24,
                'Net Energy': 0,
                'Energy Delivered': 213.41000000000003
              }
            },
            {
              dif: -7.930000000000007,
              total: 223.91,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 17.6,
                'Net Energy': 0,
                'Energy Delivered': 293.22
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -23.24,
                percentage: -10
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.26,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 234.21,
                percentage: 101
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 18.47,
                percentage: 8
              }
            ],
            [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 43.58,
                percentage: 23
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.84,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 123.5,
                percentage: 65
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 19.65,
                percentage: 10
              }
            ],
            [
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.2,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 1',
                totalAmount: 92.46180899999999,
                percentage: 41
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2',
                totalAmount: 130.2998806458,
                percentage: 58
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.1,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 206.46,
                percentage: 96
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 6.95,
                percentage: 3
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 86.91,
                percentage: 39
              },
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 37.78,
                percentage: 17
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.18,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 15.28,
                percentage: 7
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 149.52,
                percentage: 67
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 19.01,
                percentage: 8
              }
            ]
          ]
        },
        {
          key: 2,
          firstColumnAttr: 7,
          compareRate: [
            {
              dif: 0,
              total: 254.08,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.63,
                'Net Energy': 0,
                'Energy Delivered': 251.45
              }
            },
            {
              dif: -45.650000000000006,
              total: 208.43,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.1799999999999997,
                'Net Energy': 0,
                'Energy Delivered': 206.25
              }
            },
            {
              dif: -8.5,
              total: 245.58,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.55,
                'Net Energy': 0,
                'Energy Delivered': 243.0297578022
              }
            },
            {
              dif: -20.120000000000005,
              total: 233.96,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.4299999999999997,
                'Net Energy': 0,
                'Energy Delivered': 231.53
              }
            },
            {
              dif: -11.830000000000013,
              total: 242.25,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 16.799999999999997,
                'Net Energy': 0,
                'Energy Delivered': 321.25
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -21.74,
                percentage: -9
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.48,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 244.26,
                percentage: 96
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 28.93,
                percentage: 11
              }
            ],
            [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 48.82,
                percentage: 23
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.03,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 126.68,
                percentage: 61
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 30.75,
                percentage: 15
              }
            ],
            [
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.4,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 1',
                totalAmount: 86.496531,
                percentage: 35
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2',
                totalAmount: 156.5332268022,
                percentage: 64
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.28,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 216.07,
                percentage: 92
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 15.46,
                percentage: 7
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 95.8,
                percentage: 40
              },
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 42.32,
                percentage: 17
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.36,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 14.29,
                percentage: 6
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 153.37,
                percentage: 63
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 29.76,
                percentage: 12
              }
            ]
          ]
        },
        {
          key: 3,
          firstColumnAttr: 8,
          compareRate: [
            {
              dif: 0,
              total: 236.61,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.45,
                'Net Energy': 0,
                'Energy Delivered': 234.16
              }
            },
            {
              dif: -42.870000000000005,
              total: 193.74,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.03,
                'Net Energy': 0,
                'Energy Delivered': 191.71
              }
            },
            {
              dif: -7.650000000000006,
              total: 228.96,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.37,
                'Net Energy': 0,
                'Energy Delivered': 226.5876828222
              }
            },
            {
              dif: -17.200000000000017,
              total: 219.41,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.2800000000000002,
                'Net Energy': 0,
                'Energy Delivered': 217.13
              }
            },
            {
              dif: -8.580000000000013,
              total: 228.03,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 17.15,
                'Net Energy': 0,
                'Energy Delivered': 300.18
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -22.48,
                percentage: -10
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.31,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 232.02,
                percentage: 98
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 24.619999999999997,
                percentage: 10
              }
            ],
            [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 43.2,
                percentage: 22
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.89,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 122.33,
                percentage: 63
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 26.18,
                percentage: 14
              }
            ],
            [
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.23,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 1',
                totalAmount: 89.47917000000001,
                percentage: 39
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2',
                totalAmount: 137.1085128222,
                percentage: 60
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.14,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 205.51,
                percentage: 94
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 11.620000000000001,
                percentage: 5
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 89.30000000000001,
                percentage: 39
              },
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 37.45,
                percentage: 16
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.23,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 14.78,
                percentage: 6
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 148.1,
                percentage: 65
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 25.33,
                percentage: 11
              }
            ]
          ]
        },
        {
          key: 4,
          firstColumnAttr: 9,
          compareRate: [
            {
              dif: 0,
              total: 231.95,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.42,
                'Net Energy': 0,
                'Energy Delivered': 229.53
              }
            },
            {
              dif: -27.769999999999982,
              total: 204.18,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.15,
                'Net Energy': 0,
                'Energy Delivered': 202.03
              }
            },
            {
              dif: 5.550000000000011,
              total: 237.5,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.4699999999999998,
                'Net Energy': 0,
                'Energy Delivered': 235.0321879962
              }
            },
            {
              dif: 4.920000000000016,
              total: 236.87,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.47,
                'Net Energy': 0,
                'Energy Delivered': 234.4
              }
            },
            {
              dif: -6.060000000000002,
              total: 225.89,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 18.13,
                'Net Energy': 0,
                'Energy Delivered': 286.38
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -33.17,
                percentage: -14
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 111.95,
                percentage: 48
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.26,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 14.34,
                percentage: 6
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 126.05,
                percentage: 54
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 10.36,
                percentage: 4
              }
            ],
            [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 20.74,
                percentage: 10
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 62.59,
                percentage: 31
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.99,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 14.74,
                percentage: 7
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 68.18,
                percentage: 33
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 24.77,
                percentage: 12
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 11.01,
                percentage: 5
              }
            ],
            [
              {
                billLineItemName: 'Winter Energy Charge Tier 1',
                totalAmount: 81.287415,
                percentage: 34
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2',
                totalAmount: 33.7464626082,
                percentage: 14
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.32,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 1',
                totalAmount: 50.704863,
                percentage: 21
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2',
                totalAmount: 69.293447388,
                percentage: 29
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 111.58,
                percentage: 47
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.31,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 7.37,
                percentage: 3
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 110.41,
                percentage: 47
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 5.04,
                percentage: 2
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 78.62,
                percentage: 35
              },
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 17.98,
                percentage: 8
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 66.9,
                percentage: 30
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.2,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 15.77,
                percentage: 7
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 11.25,
                percentage: 5
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 82.54,
                percentage: 37
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 18.44,
                percentage: 8
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 10.65,
                percentage: 5
              }
            ]
          ]
        },
        {
          key: 5,
          firstColumnAttr: 10,
          compareRate: [
            {
              dif: 0,
              total: 191.85,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.0100000000000002,
                'Net Energy': 0,
                'Energy Delivered': 189.84
              }
            },
            {
              dif: -6.680000000000007,
              total: 185.17,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 1.9500000000000002,
                'Net Energy': 0,
                'Energy Delivered': 183.22
              }
            },
            {
              dif: 17.039999999999992,
              total: 208.89,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.18,
                'Net Energy': 0,
                'Energy Delivered': 206.7103047882
              }
            },
            {
              dif: 26.379999999999995,
              total: 218.23,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.27,
                'Net Energy': 0,
                'Energy Delivered': 215.95999999999998
              }
            },
            {
              dif: -0.3400000000000034,
              total: 191.51,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 16.299999999999997,
                'Net Energy': 0,
                'Energy Delivered': 232.81
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -39.49,
                percentage: -21
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 207.06,
                percentage: 108
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.87,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 22.27,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 118.34,
                percentage: 64
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.81,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 22.9,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 41.98,
                percentage: 23
              }
            ],
            [
              {
                billLineItemName: 'Winter Energy Charge Tier 1',
                totalAmount: 157.155669,
                percentage: 75
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2',
                totalAmount: 49.5546357882,
                percentage: 24
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.04,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 205.54,
                percentage: 94
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.13,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 10.42,
                percentage: 5
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 57.6,
                percentage: 30
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 126.48,
                percentage: 66
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.87,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 14.29,
                percentage: 7
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 17.47,
                percentage: 9
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 31.26,
                percentage: 16
              }
            ]
          ]
        },
        {
          key: 6,
          firstColumnAttr: 11,
          compareRate: [
            {
              dif: 0,
              total: 219.51,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.3000000000000003,
                'Net Energy': 0,
                'Energy Delivered': 217.21
              }
            },
            {
              dif: -10.629999999999995,
              total: 208.88,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.2,
                'Net Energy': 0,
                'Energy Delivered': 206.68
              }
            },
            {
              dif: 19.150000000000006,
              total: 238.66,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.49,
                'Net Energy': 0,
                'Energy Delivered': 236.1741529422
              }
            },
            {
              dif: 25.900000000000006,
              total: 245.41,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.5500000000000003,
                'Net Energy': 0,
                'Energy Delivered': 242.86
              }
            },
            {
              dif: -5.269999999999982,
              total: 214.24,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 17.03,
                'Net Energy': 0,
                'Energy Delivered': 262.07
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -40.85,
                percentage: -19
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 232.57,
                percentage: 106
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.14,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 25.49,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 132.09,
                percentage: 63
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.04,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 26.21,
                percentage: 13
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 48.38,
                percentage: 23
              }
            ],
            [
              {
                billLineItemName: 'Winter Energy Charge Tier 1',
                totalAmount: 162.57483,
                percentage: 68
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2',
                totalAmount: 73.5993229422,
                percentage: 31
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.33,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 232.97,
                percentage: 95
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.39,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 9.89,
                percentage: 4
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 64.86,
                percentage: 30
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 141.19,
                percentage: 66
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.09,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 14.78,
                percentage: 7
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 20,
                percentage: 9
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 36.02,
                percentage: 17
              }
            ]
          ]
        },
        {
          key: 7,
          firstColumnAttr: 12,
          compareRate: [
            {
              dif: 0,
              total: 203.42,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.13,
                'Net Energy': 0,
                'Energy Delivered': 201.29
              }
            },
            {
              dif: -6.469999999999999,
              total: 196.95,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.07,
                'Net Energy': 0,
                'Energy Delivered': 194.88000000000002
              }
            },
            {
              dif: 18.350000000000023,
              total: 221.77,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.31,
                'Net Energy': 0,
                'Energy Delivered': 219.4613313504
              }
            },
            {
              dif: 30.78,
              total: 234.2,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.44,
                'Net Energy': 0,
                'Energy Delivered': 231.76
              }
            },
            {
              dif: 2.960000000000008,
              total: 206.38,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 18.42,
                'Net Energy': 0,
                'Energy Delivered': 249.63
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -44.93,
                percentage: -22
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 223.35,
                percentage: 110
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.98,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 22.87,
                percentage: 11
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 130.86,
                percentage: 66
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.92,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 23.520000000000003,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15000000000000002,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 40.5,
                percentage: 21
              }
            ],
            [
              {
                billLineItemName: 'Winter Energy Charge Tier 1',
                totalAmount: 178.832313,
                percentage: 81
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2',
                totalAmount: 40.6290183504,
                percentage: 18
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.16,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15000000000000002,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 222.74,
                percentage: 95
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.29,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 9.02,
                percentage: 4
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15000000000000002,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 61.67,
                percentage: 30
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 139.87,
                percentage: 68
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.01,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 16.26,
                percentage: 8
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 17.94,
                percentage: 9
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 30.15,
                percentage: 15
              }
            ]
          ]
        },
        {
          key: 8,
          firstColumnAttr: 'Total',
          compareRate: [
            {
              dif: 0,
              total: 1570,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 16.34,
                'Net Energy': 0,
                'Energy Delivered': 1552.9199999999998
              }
            },
            {
              dif: -185,
              total: 1385,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 14.559999999999999,
                'Net Energy': 0,
                'Energy Delivered': 1371.5000000000002
              }
            },
            {
              dif: 35,
              total: 1605,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 16.71,
                'Net Energy': 0,
                'Energy Delivered': 1589.7571073472002
              }
            },
            {
              dif: 35,
              total: 1605,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 16.68,
                'Net Energy': 0,
                'Energy Delivered': 1587.05
              }
            },
            {
              dif: -40,
              total: 1530,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 121.42999999999999,
                'Net Energy': 0,
                'Energy Delivered': 1945.54
              }
            }
          ]
        }
      ],
      rateDetails: [
        {
          ratePlanCode: 'ETOUC',
          rateMarketingName: 'Time-of-Use (Peak Pricing 4-9 p.m. Every Day) E-TOU-C',
          totalAmt: 1570,
          totalSavings: 0,
          bestRate: false,
          currentRate: true
        },
        {
          ratePlanCode: 'EV2A',
          rateMarketingName: 'Home Charging EV2-A',
          totalAmt: 1385,
          totalSavings: 185,
          bestRate: true,
          currentRate: false
        },
        {
          ratePlanCode: 'E1',
          rateMarketingName: 'Tiered Rate Plan E-1',
          totalAmt: 1605,
          totalSavings: -35,
          bestRate: false,
          currentRate: false
        },
        {
          ratePlanCode: 'ETOUD',
          rateMarketingName: 'Time-of-Use (Peak Pricing 5-8 p.m. Weekdays) E-TOU-D',
          totalAmt: 1605,
          totalSavings: -35,
          bestRate: false,
          currentRate: false
        },
        {
          ratePlanCode: 'EELEC',
          rateMarketingName: 'Electric Home (E-ELEC)',
          totalAmt: 1530,
          totalSavings: 40,
          bestRate: false,
          currentRate: false
        }
      ],
      bestRates: [
        {
          month: 6,
          total: 188.71
        },
        {
          month: 7,
          total: 208.43
        },
        {
          month: 8,
          total: 193.74
        },
        {
          month: 9,
          total: 204.18
        },
        {
          month: 10,
          total: 185.17
        },
        {
          month: 11,
          total: 208.88
        },
        {
          month: 12,
          total: 196.95
        }
      ],
      tagAttributesList: [
        'Flat Fee',
        'Net Energy',
        'Energy Delivered'
      ]
    }
  }
};

export const projectTesla = {
  id: 259,
  contractId: '8511445809',
  name: 'Tesla Model Y',
  rateCode: 'TOU',
  resources: [
    {
      id: 113,
      name: 'ev',
      attributes: {
        weekendCharging: 'Unpredictable',
        savingsPerYear: '-380',
        weekdayDistance: '82',
        year: '2023',
        chargingPower: '1.6',
        model: 'Model Y Long Range',
        make: 'Tesla',
        weekendDistance: '18',
        weekdayCharging: '3'
      }
    }
  ],
  savingsPerYear: 500,
  createdTimestamp: '2023-11-30T07:29:29.000+00:00',
  updatedTimestamp: '2023-12-12T13:14:19.000+00:00',
  compareInfo: {
    contractId: '8511445809',
    analysisStartDate: '2023-06-16',
    analysisEndDate: '2024-01-15',
    rateEffectiveDate: '2024-01-01',
    analysisDate: '2024-01-18',
    billCycleCount: 7,
    currentRateName: 'TOU',
    currentProfileIndicator: '',
    currentAmount: 1570,
    savingsCurrentRate: 185,
    meteraccountId: '6310042005',
    entityId: '8511609883',
    premiseId: '6310042076',
    servicepointId: '6310042005',
    personId: '8615220624',
    message: 'D6F, U',
    rateDetails: [
      {
        rateMarketingDescription: 'This rate plan is best for you if you can reduce some of your electricity usage during the higher priced  peak time of 4 p.m. to 9 p.m. every day (including weekends) and can stay below or close to your Baseline Allowance.',
        scenarioIndex: 'alt2',
        scenarioName: 'TOU|TOUFS',
        scenarioTags: [
          'base'
        ],
        totalSavings: 0,
        rateMarketingName: 'Time-of-Use (Peak Pricing 4-9 p.m. Every Day) E-TOU-C',
        totalAmt: 1570,
        ratePlanCode: 'TOU',
        profileIndicator: '',
        monthlyDetails: [
          {
            billMonth: 6,
            seasonCode: 'To be implemented',
            billStartDate: '2023-06-16',
            monthlyKwh: 467.978633,
            billEndDate: '2023-07-16',
            monthlyCost: 231.84,
            tagAttributes: {
              'Flat Fee': 2.4,
              'Net Energy': 0,
              'Energy Delivered': 229.44
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -23.24,
                percentage: -10
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.26,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 234.21,
                percentage: 101
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 18.47,
                percentage: 8
              }
            ],
            monthlySavings: 0
          },
          {
            billMonth: 7,
            seasonCode: 'To be implemented',
            billStartDate: '2023-07-17',
            monthlyKwh: 503.684167,
            billEndDate: '2023-08-14',
            monthlyCost: 254.08,
            tagAttributes: {
              'Flat Fee': 2.63,
              'Net Energy': 0,
              'Energy Delivered': 251.45
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -21.74,
                percentage: -9
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.48,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 244.26,
                percentage: 96
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 28.93,
                percentage: 11
              }
            ],
            monthlySavings: 0
          },
          {
            billMonth: 8,
            seasonCode: 'To be implemented',
            billStartDate: '2023-08-15',
            monthlyKwh: 473.831167,
            billEndDate: '2023-09-13',
            monthlyCost: 236.61,
            tagAttributes: {
              'Flat Fee': 2.45,
              'Net Energy': 0,
              'Energy Delivered': 234.16
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -22.48,
                percentage: -10
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.31,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 232.02,
                percentage: 98
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 24.619999999999997,
                percentage: 10
              }
            ],
            monthlySavings: 0
          },
          {
            billMonth: 9,
            seasonCode: 'To be implemented',
            billStartDate: '2023-09-14',
            monthlyKwh: 510.220067,
            billEndDate: '2023-10-15',
            monthlyCost: 231.95,
            tagAttributes: {
              'Flat Fee': 2.42,
              'Net Energy': 0,
              'Energy Delivered': 229.53
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -33.17,
                percentage: -14
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 111.95,
                percentage: 48
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.26,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 14.34,
                percentage: 6
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 126.05,
                percentage: 54
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 10.36,
                percentage: 4
              }
            ],
            monthlySavings: 0
          },
          {
            billMonth: 10,
            seasonCode: 'To be implemented',
            billStartDate: '2023-10-16',
            monthlyKwh: 468.371267,
            billEndDate: '2023-11-13',
            monthlyCost: 191.85,
            tagAttributes: {
              'Flat Fee': 2.0100000000000002,
              'Net Energy': 0,
              'Energy Delivered': 189.84
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -39.49,
                percentage: -21
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 207.06,
                percentage: 108
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.87,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 22.27,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              }
            ],
            monthlySavings: 0
          },
          {
            billMonth: 11,
            seasonCode: 'To be implemented',
            billStartDate: '2023-11-14',
            monthlyKwh: 527.013167,
            billEndDate: '2023-12-13',
            monthlyCost: 219.51,
            tagAttributes: {
              'Flat Fee': 2.3000000000000003,
              'Net Energy': 0,
              'Energy Delivered': 217.21
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -40.85,
                percentage: -19
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 232.57,
                percentage: 106
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.14,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 25.49,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              }
            ],
            monthlySavings: 0
          },
          {
            billMonth: 12,
            seasonCode: 'To be implemented',
            billStartDate: '2023-12-14',
            monthlyKwh: 502.991433,
            billEndDate: '2024-01-15',
            monthlyCost: 203.42,
            tagAttributes: {
              'Flat Fee': 2.13,
              'Net Energy': 0,
              'Energy Delivered': 201.29
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -44.93,
                percentage: -22
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 223.35,
                percentage: 110
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.98,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 22.87,
                percentage: 11
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              }
            ],
            monthlySavings: 0
          }
        ]
      },
      {
        rateMarketingDescription: 'This rate plan is for customers with an Electric Vehicle (EV) battery storage  and/or electric heat pump that can charge during off-peak hours and shift other electricity usage to off-peak hours. Customers using over 800% of Baseline Allowance in the last 12 months are ineligible.',
        scenarioIndex: 'alt4',
        scenarioName: 'SPP|SSPFS',
        scenarioTags: [
          'best'
        ],
        totalSavings: 185,
        rateMarketingName: 'Home Charging SPP',
        totalAmt: 1385,
        ratePlanCode: 'SPP',
        profileIndicator: '',
        monthlyDetails: [
          {
            billMonth: 6,
            seasonCode: 'To be implemented',
            billStartDate: '2023-06-16',
            monthlyKwh: 467.978633,
            billEndDate: '2023-07-16',
            monthlyCost: 188.71,
            tagAttributes: {
              'Flat Fee': 1.98,
              'Net Energy': 0,
              'Energy Delivered': 186.73
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 43.58,
                percentage: 23
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.84,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 123.5,
                percentage: 65
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 19.65,
                percentage: 10
              }
            ],
            monthlySavings: 43.129999999999995
          },
          {
            billMonth: 7,
            seasonCode: 'To be implemented',
            billStartDate: '2023-07-17',
            monthlyKwh: 503.684167,
            billEndDate: '2023-08-14',
            monthlyCost: 208.43,
            tagAttributes: {
              'Flat Fee': 2.1799999999999997,
              'Net Energy': 0,
              'Energy Delivered': 206.25
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 48.82,
                percentage: 23
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.03,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 126.68,
                percentage: 61
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 30.75,
                percentage: 15
              }
            ],
            monthlySavings: 45.650000000000006
          },
          {
            billMonth: 8,
            seasonCode: 'To be implemented',
            billStartDate: '2023-08-15',
            monthlyKwh: 473.831167,
            billEndDate: '2023-09-13',
            monthlyCost: 193.74,
            tagAttributes: {
              'Flat Fee': 2.03,
              'Net Energy': 0,
              'Energy Delivered': 191.71
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 43.2,
                percentage: 22
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.89,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 122.33,
                percentage: 63
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 26.18,
                percentage: 14
              }
            ],
            monthlySavings: 42.870000000000005
          },
          {
            billMonth: 9,
            seasonCode: 'To be implemented',
            billStartDate: '2023-09-14',
            monthlyKwh: 510.220067,
            billEndDate: '2023-10-15',
            monthlyCost: 204.18,
            tagAttributes: {
              'Flat Fee': 2.15,
              'Net Energy': 0,
              'Energy Delivered': 202.03
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 20.74,
                percentage: 10
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 62.59,
                percentage: 31
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.99,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 14.74,
                percentage: 7
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 68.18,
                percentage: 33
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 24.77,
                percentage: 12
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 11.01,
                percentage: 5
              }
            ],
            monthlySavings: 27.769999999999982
          },
          {
            billMonth: 10,
            seasonCode: 'To be implemented',
            billStartDate: '2023-10-16',
            monthlyKwh: 468.371267,
            billEndDate: '2023-11-13',
            monthlyCost: 185.17,
            tagAttributes: {
              'Flat Fee': 1.9500000000000002,
              'Net Energy': 0,
              'Energy Delivered': 183.22
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 118.34,
                percentage: 64
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.81,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 22.9,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 41.98,
                percentage: 23
              }
            ],
            monthlySavings: 6.680000000000007
          },
          {
            billMonth: 11,
            seasonCode: 'To be implemented',
            billStartDate: '2023-11-14',
            monthlyKwh: 527.013167,
            billEndDate: '2023-12-13',
            monthlyCost: 208.88,
            tagAttributes: {
              'Flat Fee': 2.2,
              'Net Energy': 0,
              'Energy Delivered': 206.68
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 132.09,
                percentage: 63
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.04,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 26.21,
                percentage: 13
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 48.38,
                percentage: 23
              }
            ],
            monthlySavings: 10.629999999999995
          },
          {
            billMonth: 12,
            seasonCode: 'To be implemented',
            billStartDate: '2023-12-14',
            monthlyKwh: 502.991433,
            billEndDate: '2024-01-15',
            monthlyCost: 196.95,
            tagAttributes: {
              'Flat Fee': 2.07,
              'Net Energy': 0,
              'Energy Delivered': 194.88000000000002
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 130.86,
                percentage: 66
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.92,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 23.520000000000003,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15000000000000002,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 40.5,
                percentage: 21
              }
            ],
            monthlySavings: 6.469999999999999
          }
        ]
      }
    ],
    statusCode: '',
    statusDescription: '',
    compareRatesDetails: {
      currency: 'USD',
      charts: {
        key: 0,
        seriesData: [
          [
            {
              name: 'Flat Fee',
              data: [
                2.4,
                2.63,
                2.45,
                2.42,
                2.0100000000000002,
                2.3000000000000003,
                2.13
              ]
            },
            {
              name: 'Net Energy',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            },
            {
              name: 'Energy Delivered',
              data: [
                229.44,
                251.45,
                234.16,
                229.53,
                189.84,
                217.21,
                201.29
              ]
            },
            {
              name: 'Rate',
              data: [
                231.84,
                254.08,
                236.61,
                231.95,
                191.85,
                219.51,
                203.42
              ]
            },
            {
              name: 'Increase',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            }
          ],
          [
            {
              name: 'Flat Fee',
              data: [
                1.98,
                2.1799999999999997,
                2.03,
                2.15,
                1.9500000000000002,
                2.2,
                2.07
              ]
            },
            {
              name: 'Net Energy',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            },
            {
              name: 'Energy Delivered',
              data: [
                186.73,
                206.25,
                191.71,
                202.03,
                183.22,
                206.68,
                194.88000000000002
              ]
            },
            {
              name: 'Rate',
              data: [
                231.84,
                254.08,
                236.61,
                231.95,
                191.85,
                219.51,
                203.42
              ]
            },
            {
              name: 'Increase',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            }
          ],
          [
            {
              name: 'Flat Fee',
              data: [
                2.3400000000000003,
                2.55,
                2.37,
                2.4699999999999998,
                2.18,
                2.49,
                2.31
              ]
            },
            {
              name: 'Net Energy',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            },
            {
              name: 'Energy Delivered',
              data: [
                222.7616896458,
                243.0297578022,
                226.5876828222,
                235.0321879962,
                206.7103047882,
                236.1741529422,
                219.4613313504
              ]
            },
            {
              name: 'Rate',
              data: [
                231.84,
                254.08,
                236.61,
                231.95,
                191.85,
                219.51,
                203.42
              ]
            },
            {
              name: 'Increase',
              data: [
                0,
                0,
                0,
                3.0821879962000196,
                14.860304788200011,
                16.66415294220002,
                16.041331350400014
              ]
            }
          ],
          [
            {
              name: 'Flat Fee',
              data: [
                2.24,
                2.4299999999999997,
                2.2800000000000002,
                2.47,
                2.27,
                2.5500000000000003,
                2.44
              ]
            },
            {
              name: 'Net Energy',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            },
            {
              name: 'Energy Delivered',
              data: [
                213.41000000000003,
                231.53,
                217.13,
                234.4,
                215.95999999999998,
                242.86,
                231.76
              ]
            },
            {
              name: 'Rate',
              data: [
                231.84,
                254.08,
                236.61,
                231.95,
                191.85,
                219.51,
                203.42
              ]
            },
            {
              name: 'Increase',
              data: [
                0,
                0,
                0,
                2.450000000000017,
                24.109999999999985,
                23.350000000000023,
                28.340000000000003
              ]
            }
          ],
          [
            {
              name: 'Flat Fee',
              data: [
                17.6,
                16.799999999999997,
                17.15,
                18.13,
                16.299999999999997,
                17.03,
                18.42
              ]
            },
            {
              name: 'Net Energy',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            },
            {
              name: 'Energy Delivered',
              data: [
                293.22,
                321.25,
                300.18,
                286.38,
                232.81,
                262.07,
                249.63
              ]
            },
            {
              name: 'Rate',
              data: [
                231.84,
                254.08,
                236.61,
                231.95,
                191.85,
                219.51,
                203.42
              ]
            },
            {
              name: 'Increase',
              data: [
                61.380000000000024,
                67.16999999999999,
                63.56999999999999,
                54.43000000000001,
                40.96000000000001,
                42.56,
                46.21000000000001
              ]
            }
          ]
        ],
        chartSeriesKeys: [
          'Flat Fee',
          'Net Energy',
          'Energy Delivered',
          'Rate',
          'Increase'
        ],
        data: []
      },
      availableRates: [
        'Time-of-Use (Peak Pricing 4-9 p.m. Every Day) E-TOU-C',
        'Home Charging EV2-A',
        'Tiered Rate Plan E-1',
        'Time-of-Use (Peak Pricing 5-8 p.m. Weekdays) E-TOU-D',
        'Electric Home (E-ELEC)'
      ],
      months: [
        {
          key: 1,
          firstColumnAttr: 6,
          compareRate: [
            {
              dif: 0,
              total: 231.84,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.4,
                'Net Energy': 0,
                'Energy Delivered': 229.44
              }
            },
            {
              dif: -43.129999999999995,
              total: 188.71,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 1.98,
                'Net Energy': 0,
                'Energy Delivered': 186.73
              }
            },
            {
              dif: -6.740000000000009,
              total: 225.1,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.3400000000000003,
                'Net Energy': 0,
                'Energy Delivered': 222.7616896458
              }
            },
            {
              dif: -16.189999999999998,
              total: 215.65,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.24,
                'Net Energy': 0,
                'Energy Delivered': 213.41000000000003
              }
            },
            {
              dif: -7.930000000000007,
              total: 223.91,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 17.6,
                'Net Energy': 0,
                'Energy Delivered': 293.22
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -23.24,
                percentage: -10
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.26,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 234.21,
                percentage: 101
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 18.47,
                percentage: 8
              }
            ],
            [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 43.58,
                percentage: 23
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.84,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 123.5,
                percentage: 65
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 19.65,
                percentage: 10
              }
            ],
            [
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.2,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 1',
                totalAmount: 92.46180899999999,
                percentage: 41
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2',
                totalAmount: 130.2998806458,
                percentage: 58
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.1,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 206.46,
                percentage: 96
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 6.95,
                percentage: 3
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 86.91,
                percentage: 39
              },
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 37.78,
                percentage: 17
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.18,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 15.28,
                percentage: 7
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 149.52,
                percentage: 67
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 19.01,
                percentage: 8
              }
            ]
          ]
        },
        {
          key: 2,
          firstColumnAttr: 7,
          compareRate: [
            {
              dif: 0,
              total: 254.08,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.63,
                'Net Energy': 0,
                'Energy Delivered': 251.45
              }
            },
            {
              dif: -45.650000000000006,
              total: 208.43,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.1799999999999997,
                'Net Energy': 0,
                'Energy Delivered': 206.25
              }
            },
            {
              dif: -8.5,
              total: 245.58,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.55,
                'Net Energy': 0,
                'Energy Delivered': 243.0297578022
              }
            },
            {
              dif: -20.120000000000005,
              total: 233.96,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.4299999999999997,
                'Net Energy': 0,
                'Energy Delivered': 231.53
              }
            },
            {
              dif: -11.830000000000013,
              total: 242.25,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 16.799999999999997,
                'Net Energy': 0,
                'Energy Delivered': 321.25
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -21.74,
                percentage: -9
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.48,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 244.26,
                percentage: 96
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 28.93,
                percentage: 11
              }
            ],
            [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 48.82,
                percentage: 23
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.03,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 126.68,
                percentage: 61
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 30.75,
                percentage: 15
              }
            ],
            [
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.4,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 1',
                totalAmount: 86.496531,
                percentage: 35
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2',
                totalAmount: 156.5332268022,
                percentage: 64
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.28,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 216.07,
                percentage: 92
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 15.46,
                percentage: 7
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 95.8,
                percentage: 40
              },
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 42.32,
                percentage: 17
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.36,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 14.29,
                percentage: 6
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 153.37,
                percentage: 63
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 29.76,
                percentage: 12
              }
            ]
          ]
        },
        {
          key: 3,
          firstColumnAttr: 8,
          compareRate: [
            {
              dif: 0,
              total: 236.61,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.45,
                'Net Energy': 0,
                'Energy Delivered': 234.16
              }
            },
            {
              dif: -42.870000000000005,
              total: 193.74,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.03,
                'Net Energy': 0,
                'Energy Delivered': 191.71
              }
            },
            {
              dif: -7.650000000000006,
              total: 228.96,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.37,
                'Net Energy': 0,
                'Energy Delivered': 226.5876828222
              }
            },
            {
              dif: -17.200000000000017,
              total: 219.41,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.2800000000000002,
                'Net Energy': 0,
                'Energy Delivered': 217.13
              }
            },
            {
              dif: -8.580000000000013,
              total: 228.03,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 17.15,
                'Net Energy': 0,
                'Energy Delivered': 300.18
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -22.48,
                percentage: -10
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.31,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 232.02,
                percentage: 98
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 24.619999999999997,
                percentage: 10
              }
            ],
            [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 43.2,
                percentage: 22
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.89,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 122.33,
                percentage: 63
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 26.18,
                percentage: 14
              }
            ],
            [
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.23,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 1',
                totalAmount: 89.47917000000001,
                percentage: 39
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2',
                totalAmount: 137.1085128222,
                percentage: 60
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.14,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 205.51,
                percentage: 94
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 11.620000000000001,
                percentage: 5
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 89.30000000000001,
                percentage: 39
              },
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 37.45,
                percentage: 16
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.23,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 14.78,
                percentage: 6
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 148.1,
                percentage: 65
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 25.33,
                percentage: 11
              }
            ]
          ]
        },
        {
          key: 4,
          firstColumnAttr: 9,
          compareRate: [
            {
              dif: 0,
              total: 231.95,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.42,
                'Net Energy': 0,
                'Energy Delivered': 229.53
              }
            },
            {
              dif: -27.769999999999982,
              total: 204.18,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.15,
                'Net Energy': 0,
                'Energy Delivered': 202.03
              }
            },
            {
              dif: 5.550000000000011,
              total: 237.5,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.4699999999999998,
                'Net Energy': 0,
                'Energy Delivered': 235.0321879962
              }
            },
            {
              dif: 4.920000000000016,
              total: 236.87,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.47,
                'Net Energy': 0,
                'Energy Delivered': 234.4
              }
            },
            {
              dif: -6.060000000000002,
              total: 225.89,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 18.13,
                'Net Energy': 0,
                'Energy Delivered': 286.38
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -33.17,
                percentage: -14
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 111.95,
                percentage: 48
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.26,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 14.34,
                percentage: 6
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 126.05,
                percentage: 54
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 10.36,
                percentage: 4
              }
            ],
            [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 20.74,
                percentage: 10
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 62.59,
                percentage: 31
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.99,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 14.74,
                percentage: 7
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 68.18,
                percentage: 33
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 24.77,
                percentage: 12
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 11.01,
                percentage: 5
              }
            ],
            [
              {
                billLineItemName: 'Winter Energy Charge Tier 1',
                totalAmount: 81.287415,
                percentage: 34
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2',
                totalAmount: 33.7464626082,
                percentage: 14
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.32,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 1',
                totalAmount: 50.704863,
                percentage: 21
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2',
                totalAmount: 69.293447388,
                percentage: 29
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 111.58,
                percentage: 47
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.31,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 7.37,
                percentage: 3
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 110.41,
                percentage: 47
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 5.04,
                percentage: 2
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 78.62,
                percentage: 35
              },
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 17.98,
                percentage: 8
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 66.9,
                percentage: 30
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.2,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 15.77,
                percentage: 7
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 11.25,
                percentage: 5
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 82.54,
                percentage: 37
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 18.44,
                percentage: 8
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 10.65,
                percentage: 5
              }
            ]
          ]
        },
        {
          key: 5,
          firstColumnAttr: 10,
          compareRate: [
            {
              dif: 0,
              total: 191.85,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.0100000000000002,
                'Net Energy': 0,
                'Energy Delivered': 189.84
              }
            },
            {
              dif: -6.680000000000007,
              total: 185.17,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 1.9500000000000002,
                'Net Energy': 0,
                'Energy Delivered': 183.22
              }
            },
            {
              dif: 17.039999999999992,
              total: 208.89,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.18,
                'Net Energy': 0,
                'Energy Delivered': 206.7103047882
              }
            },
            {
              dif: 26.379999999999995,
              total: 218.23,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.27,
                'Net Energy': 0,
                'Energy Delivered': 215.95999999999998
              }
            },
            {
              dif: -0.3400000000000034,
              total: 191.51,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 16.299999999999997,
                'Net Energy': 0,
                'Energy Delivered': 232.81
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -39.49,
                percentage: -21
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 207.06,
                percentage: 108
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.87,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 22.27,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 118.34,
                percentage: 64
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.81,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 22.9,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 41.98,
                percentage: 23
              }
            ],
            [
              {
                billLineItemName: 'Winter Energy Charge Tier 1',
                totalAmount: 157.155669,
                percentage: 75
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2',
                totalAmount: 49.5546357882,
                percentage: 24
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.04,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 205.54,
                percentage: 94
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.13,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 10.42,
                percentage: 5
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 57.6,
                percentage: 30
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 126.48,
                percentage: 66
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.87,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 14.29,
                percentage: 7
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 17.47,
                percentage: 9
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 31.26,
                percentage: 16
              }
            ]
          ]
        },
        {
          key: 6,
          firstColumnAttr: 11,
          compareRate: [
            {
              dif: 0,
              total: 219.51,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.3000000000000003,
                'Net Energy': 0,
                'Energy Delivered': 217.21
              }
            },
            {
              dif: -10.629999999999995,
              total: 208.88,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.2,
                'Net Energy': 0,
                'Energy Delivered': 206.68
              }
            },
            {
              dif: 19.150000000000006,
              total: 238.66,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.49,
                'Net Energy': 0,
                'Energy Delivered': 236.1741529422
              }
            },
            {
              dif: 25.900000000000006,
              total: 245.41,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.5500000000000003,
                'Net Energy': 0,
                'Energy Delivered': 242.86
              }
            },
            {
              dif: -5.269999999999982,
              total: 214.24,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 17.03,
                'Net Energy': 0,
                'Energy Delivered': 262.07
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -40.85,
                percentage: -19
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 232.57,
                percentage: 106
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.14,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 25.49,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 132.09,
                percentage: 63
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.04,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 26.21,
                percentage: 13
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 48.38,
                percentage: 23
              }
            ],
            [
              {
                billLineItemName: 'Winter Energy Charge Tier 1',
                totalAmount: 162.57483,
                percentage: 68
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2',
                totalAmount: 73.5993229422,
                percentage: 31
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.33,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 232.97,
                percentage: 95
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.39,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 9.89,
                percentage: 4
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 64.86,
                percentage: 30
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 141.19,
                percentage: 66
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.09,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 14.78,
                percentage: 7
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 20,
                percentage: 9
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 36.02,
                percentage: 17
              }
            ]
          ]
        },
        {
          key: 7,
          firstColumnAttr: 12,
          compareRate: [
            {
              dif: 0,
              total: 203.42,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.13,
                'Net Energy': 0,
                'Energy Delivered': 201.29
              }
            },
            {
              dif: -6.469999999999999,
              total: 196.95,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.07,
                'Net Energy': 0,
                'Energy Delivered': 194.88000000000002
              }
            },
            {
              dif: 18.350000000000023,
              total: 221.77,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.31,
                'Net Energy': 0,
                'Energy Delivered': 219.4613313504
              }
            },
            {
              dif: 30.78,
              total: 234.2,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.44,
                'Net Energy': 0,
                'Energy Delivered': 231.76
              }
            },
            {
              dif: 2.960000000000008,
              total: 206.38,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 18.42,
                'Net Energy': 0,
                'Energy Delivered': 249.63
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -44.93,
                percentage: -22
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 223.35,
                percentage: 110
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.98,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 22.87,
                percentage: 11
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 130.86,
                percentage: 66
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.92,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 23.520000000000003,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15000000000000002,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 40.5,
                percentage: 21
              }
            ],
            [
              {
                billLineItemName: 'Winter Energy Charge Tier 1',
                totalAmount: 178.832313,
                percentage: 81
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2',
                totalAmount: 40.6290183504,
                percentage: 18
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.16,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15000000000000002,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 222.74,
                percentage: 95
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.29,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 9.02,
                percentage: 4
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15000000000000002,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 61.67,
                percentage: 30
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 139.87,
                percentage: 68
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.01,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 16.26,
                percentage: 8
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 17.94,
                percentage: 9
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 30.15,
                percentage: 15
              }
            ]
          ]
        },
        {
          key: 8,
          firstColumnAttr: 'Total',
          compareRate: [
            {
              dif: 0,
              total: 1570,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 16.34,
                'Net Energy': 0,
                'Energy Delivered': 1552.9199999999998
              }
            },
            {
              dif: -185,
              total: 1385,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 14.559999999999999,
                'Net Energy': 0,
                'Energy Delivered': 1371.5000000000002
              }
            },
            {
              dif: 35,
              total: 1605,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 16.71,
                'Net Energy': 0,
                'Energy Delivered': 1589.7571073472002
              }
            },
            {
              dif: 35,
              total: 1605,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 16.68,
                'Net Energy': 0,
                'Energy Delivered': 1587.05
              }
            },
            {
              dif: -40,
              total: 1530,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 121.42999999999999,
                'Net Energy': 0,
                'Energy Delivered': 1945.54
              }
            }
          ]
        }
      ],
      rateDetails: [
        {
          ratePlanCode: 'ETOUC',
          rateMarketingName: 'Time-of-Use (Peak Pricing 4-9 p.m. Every Day) E-TOU-C',
          totalAmt: 1570,
          totalSavings: 0,
          bestRate: false,
          currentRate: true
        },
        {
          ratePlanCode: 'EV2A',
          rateMarketingName: 'Home Charging EV2-A',
          totalAmt: 1385,
          totalSavings: 185,
          bestRate: true,
          currentRate: false
        },
        {
          ratePlanCode: 'E1',
          rateMarketingName: 'Tiered Rate Plan E-1',
          totalAmt: 1605,
          totalSavings: -35,
          bestRate: false,
          currentRate: false
        },
        {
          ratePlanCode: 'ETOUD',
          rateMarketingName: 'Time-of-Use (Peak Pricing 5-8 p.m. Weekdays) E-TOU-D',
          totalAmt: 1605,
          totalSavings: -35,
          bestRate: false,
          currentRate: false
        },
        {
          ratePlanCode: 'EELEC',
          rateMarketingName: 'Electric Home (E-ELEC)',
          totalAmt: 1530,
          totalSavings: 40,
          bestRate: false,
          currentRate: false
        }
      ],
      bestRates: [
        {
          month: 6,
          total: 188.71
        },
        {
          month: 7,
          total: 208.43
        },
        {
          month: 8,
          total: 193.74
        },
        {
          month: 9,
          total: 204.18
        },
        {
          month: 10,
          total: 185.17
        },
        {
          month: 11,
          total: 208.88
        },
        {
          month: 12,
          total: 196.95
        }
      ],
      tagAttributesList: [
        'Flat Fee',
        'Net Energy',
        'Energy Delivered'
      ]
    }
  }
};

export const projectFord = {
  id: 255,
  contractId: '8511445809',
  name: 'Ford Mach E',
  rateCode: 'TOU',
  resources: [
    {
      id: 113,
      name: 'ev',
      attributes: {
        weekendCharging: 'Unpredictable',
        savingsPerYear: '-400',
        weekdayDistance: '82',
        year: '2023',
        chargingPower: '1.6',
        model: 'Model Y Long Range',
        make: 'Tesla',
        weekendDistance: '18',
        weekdayCharging: '3'
      }
    }
  ],
  savingsPerYear: 480,
  createdTimestamp: '2023-11-30T07:29:29.000+00:00',
  updatedTimestamp: '2023-12-12T13:14:19.000+00:00',
  compareInfo: {
    contractId: '8511445809',
    analysisStartDate: '2023-06-16',
    analysisEndDate: '2024-01-15',
    rateEffectiveDate: '2024-01-01',
    analysisDate: '2024-01-18',
    billCycleCount: 7,
    currentRateName: 'TOU',
    currentProfileIndicator: '',
    currentAmount: 1570,
    savingsCurrentRate: 185,
    meteraccountId: '6310042005',
    entityId: '8511609883',
    premiseId: '6310042076',
    servicepointId: '6310042005',
    personId: '8615220624',
    message: 'D6F, U',
    rateDetails: [
      {
        rateMarketingDescription: 'This rate plan is best for you if you can reduce some of your electricity usage during the higher priced  peak time of 4 p.m. to 9 p.m. every day (including weekends) and can stay below or close to your Baseline Allowance.',
        scenarioIndex: 'alt2',
        scenarioName: 'TOU|TOUFS',
        scenarioTags: [
          'base'
        ],
        totalSavings: 0,
        rateMarketingName: 'Time-of-Use (Peak Pricing 4-9 p.m. Every Day) E-TOU-C',
        totalAmt: 1570,
        ratePlanCode: 'TOU',
        profileIndicator: '',
        monthlyDetails: [
          {
            billMonth: 6,
            seasonCode: 'To be implemented',
            billStartDate: '2023-06-16',
            monthlyKwh: 467.978633,
            billEndDate: '2023-07-16',
            monthlyCost: 231.84,
            tagAttributes: {
              'Flat Fee': 2.4,
              'Net Energy': 0,
              'Energy Delivered': 229.44
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -23.24,
                percentage: -10
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.26,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 234.21,
                percentage: 101
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 18.47,
                percentage: 8
              }
            ],
            monthlySavings: 0
          },
          {
            billMonth: 7,
            seasonCode: 'To be implemented',
            billStartDate: '2023-07-17',
            monthlyKwh: 503.684167,
            billEndDate: '2023-08-14',
            monthlyCost: 254.08,
            tagAttributes: {
              'Flat Fee': 2.63,
              'Net Energy': 0,
              'Energy Delivered': 251.45
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -21.74,
                percentage: -9
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.48,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 244.26,
                percentage: 96
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 28.93,
                percentage: 11
              }
            ],
            monthlySavings: 0
          },
          {
            billMonth: 8,
            seasonCode: 'To be implemented',
            billStartDate: '2023-08-15',
            monthlyKwh: 473.831167,
            billEndDate: '2023-09-13',
            monthlyCost: 236.61,
            tagAttributes: {
              'Flat Fee': 2.45,
              'Net Energy': 0,
              'Energy Delivered': 234.16
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -22.48,
                percentage: -10
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.31,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 232.02,
                percentage: 98
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 24.619999999999997,
                percentage: 10
              }
            ],
            monthlySavings: 0
          },
          {
            billMonth: 9,
            seasonCode: 'To be implemented',
            billStartDate: '2023-09-14',
            monthlyKwh: 510.220067,
            billEndDate: '2023-10-15',
            monthlyCost: 231.95,
            tagAttributes: {
              'Flat Fee': 2.42,
              'Net Energy': 0,
              'Energy Delivered': 229.53
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -33.17,
                percentage: -14
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 111.95,
                percentage: 48
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.26,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 14.34,
                percentage: 6
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 126.05,
                percentage: 54
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 10.36,
                percentage: 4
              }
            ],
            monthlySavings: 0
          },
          {
            billMonth: 10,
            seasonCode: 'To be implemented',
            billStartDate: '2023-10-16',
            monthlyKwh: 468.371267,
            billEndDate: '2023-11-13',
            monthlyCost: 191.85,
            tagAttributes: {
              'Flat Fee': 2.0100000000000002,
              'Net Energy': 0,
              'Energy Delivered': 189.84
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -39.49,
                percentage: -21
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 207.06,
                percentage: 108
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.87,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 22.27,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              }
            ],
            monthlySavings: 0
          },
          {
            billMonth: 11,
            seasonCode: 'To be implemented',
            billStartDate: '2023-11-14',
            monthlyKwh: 527.013167,
            billEndDate: '2023-12-13',
            monthlyCost: 219.51,
            tagAttributes: {
              'Flat Fee': 2.3000000000000003,
              'Net Energy': 0,
              'Energy Delivered': 217.21
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -40.85,
                percentage: -19
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 232.57,
                percentage: 106
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.14,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 25.49,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              }
            ],
            monthlySavings: 0
          },
          {
            billMonth: 12,
            seasonCode: 'To be implemented',
            billStartDate: '2023-12-14',
            monthlyKwh: 502.991433,
            billEndDate: '2024-01-15',
            monthlyCost: 203.42,
            tagAttributes: {
              'Flat Fee': 2.13,
              'Net Energy': 0,
              'Energy Delivered': 201.29
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -44.93,
                percentage: -22
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 223.35,
                percentage: 110
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.98,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 22.87,
                percentage: 11
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              }
            ],
            monthlySavings: 0
          }
        ]
      },
      {
        rateMarketingDescription: 'This rate plan is for customers with an Electric Vehicle (EV) battery storage  and/or electric heat pump that can charge during off-peak hours and shift other electricity usage to off-peak hours. Customers using over 800% of Baseline Allowance in the last 12 months are ineligible.',
        scenarioIndex: 'alt4',
        scenarioName: 'SPP|SSPFS',
        scenarioTags: [
          'best'
        ],
        totalSavings: 185,
        rateMarketingName: 'Home Charging SPP',
        totalAmt: 1385,
        ratePlanCode: 'SPP',
        profileIndicator: '',
        monthlyDetails: [
          {
            billMonth: 6,
            seasonCode: 'To be implemented',
            billStartDate: '2023-06-16',
            monthlyKwh: 467.978633,
            billEndDate: '2023-07-16',
            monthlyCost: 188.71,
            tagAttributes: {
              'Flat Fee': 1.98,
              'Net Energy': 0,
              'Energy Delivered': 186.73
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 43.58,
                percentage: 23
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.84,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 123.5,
                percentage: 65
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 19.65,
                percentage: 10
              }
            ],
            monthlySavings: 43.129999999999995
          },
          {
            billMonth: 7,
            seasonCode: 'To be implemented',
            billStartDate: '2023-07-17',
            monthlyKwh: 503.684167,
            billEndDate: '2023-08-14',
            monthlyCost: 208.43,
            tagAttributes: {
              'Flat Fee': 2.1799999999999997,
              'Net Energy': 0,
              'Energy Delivered': 206.25
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 48.82,
                percentage: 23
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.03,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 126.68,
                percentage: 61
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 30.75,
                percentage: 15
              }
            ],
            monthlySavings: 45.650000000000006
          },
          {
            billMonth: 8,
            seasonCode: 'To be implemented',
            billStartDate: '2023-08-15',
            monthlyKwh: 473.831167,
            billEndDate: '2023-09-13',
            monthlyCost: 193.74,
            tagAttributes: {
              'Flat Fee': 2.03,
              'Net Energy': 0,
              'Energy Delivered': 191.71
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 43.2,
                percentage: 22
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.89,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 122.33,
                percentage: 63
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 26.18,
                percentage: 14
              }
            ],
            monthlySavings: 42.870000000000005
          },
          {
            billMonth: 9,
            seasonCode: 'To be implemented',
            billStartDate: '2023-09-14',
            monthlyKwh: 510.220067,
            billEndDate: '2023-10-15',
            monthlyCost: 204.18,
            tagAttributes: {
              'Flat Fee': 2.15,
              'Net Energy': 0,
              'Energy Delivered': 202.03
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 20.74,
                percentage: 10
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 62.59,
                percentage: 31
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.99,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 14.74,
                percentage: 7
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 68.18,
                percentage: 33
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 24.77,
                percentage: 12
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 11.01,
                percentage: 5
              }
            ],
            monthlySavings: 27.769999999999982
          },
          {
            billMonth: 10,
            seasonCode: 'To be implemented',
            billStartDate: '2023-10-16',
            monthlyKwh: 468.371267,
            billEndDate: '2023-11-13',
            monthlyCost: 185.17,
            tagAttributes: {
              'Flat Fee': 1.9500000000000002,
              'Net Energy': 0,
              'Energy Delivered': 183.22
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 118.34,
                percentage: 64
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.81,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 22.9,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 41.98,
                percentage: 23
              }
            ],
            monthlySavings: 6.680000000000007
          },
          {
            billMonth: 11,
            seasonCode: 'To be implemented',
            billStartDate: '2023-11-14',
            monthlyKwh: 527.013167,
            billEndDate: '2023-12-13',
            monthlyCost: 208.88,
            tagAttributes: {
              'Flat Fee': 2.2,
              'Net Energy': 0,
              'Energy Delivered': 206.68
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 132.09,
                percentage: 63
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.04,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 26.21,
                percentage: 13
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 48.38,
                percentage: 23
              }
            ],
            monthlySavings: 10.629999999999995
          },
          {
            billMonth: 12,
            seasonCode: 'To be implemented',
            billStartDate: '2023-12-14',
            monthlyKwh: 502.991433,
            billEndDate: '2024-01-15',
            monthlyCost: 196.95,
            tagAttributes: {
              'Flat Fee': 2.07,
              'Net Energy': 0,
              'Energy Delivered': 194.88000000000002
            },
            accountMonthlyLineItemDetailsList: [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 130.86,
                percentage: 66
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.92,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 23.520000000000003,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15000000000000002,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 40.5,
                percentage: 21
              }
            ],
            monthlySavings: 6.469999999999999
          }
        ]
      }
    ],
    statusCode: '',
    statusDescription: '',
    compareRatesDetails: {
      currency: 'USD',
      charts: {
        key: 0,
        seriesData: [
          [
            {
              name: 'Flat Fee',
              data: [
                2.4,
                2.63,
                2.45,
                2.42,
                2.0100000000000002,
                2.3000000000000003,
                2.13
              ]
            },
            {
              name: 'Net Energy',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            },
            {
              name: 'Energy Delivered',
              data: [
                229.44,
                251.45,
                234.16,
                229.53,
                189.84,
                217.21,
                201.29
              ]
            },
            {
              name: 'Rate',
              data: [
                231.84,
                254.08,
                236.61,
                231.95,
                191.85,
                219.51,
                203.42
              ]
            },
            {
              name: 'Increase',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            }
          ],
          [
            {
              name: 'Flat Fee',
              data: [
                1.98,
                2.1799999999999997,
                2.03,
                2.15,
                1.9500000000000002,
                2.2,
                2.07
              ]
            },
            {
              name: 'Net Energy',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            },
            {
              name: 'Energy Delivered',
              data: [
                186.73,
                206.25,
                191.71,
                202.03,
                183.22,
                206.68,
                194.88000000000002
              ]
            },
            {
              name: 'Rate',
              data: [
                231.84,
                254.08,
                236.61,
                231.95,
                191.85,
                219.51,
                203.42
              ]
            },
            {
              name: 'Increase',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            }
          ],
          [
            {
              name: 'Flat Fee',
              data: [
                2.3400000000000003,
                2.55,
                2.37,
                2.4699999999999998,
                2.18,
                2.49,
                2.31
              ]
            },
            {
              name: 'Net Energy',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            },
            {
              name: 'Energy Delivered',
              data: [
                222.7616896458,
                243.0297578022,
                226.5876828222,
                235.0321879962,
                206.7103047882,
                236.1741529422,
                219.4613313504
              ]
            },
            {
              name: 'Rate',
              data: [
                231.84,
                254.08,
                236.61,
                231.95,
                191.85,
                219.51,
                203.42
              ]
            },
            {
              name: 'Increase',
              data: [
                0,
                0,
                0,
                3.0821879962000196,
                14.860304788200011,
                16.66415294220002,
                16.041331350400014
              ]
            }
          ],
          [
            {
              name: 'Flat Fee',
              data: [
                2.24,
                2.4299999999999997,
                2.2800000000000002,
                2.47,
                2.27,
                2.5500000000000003,
                2.44
              ]
            },
            {
              name: 'Net Energy',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            },
            {
              name: 'Energy Delivered',
              data: [
                213.41000000000003,
                231.53,
                217.13,
                234.4,
                215.95999999999998,
                242.86,
                231.76
              ]
            },
            {
              name: 'Rate',
              data: [
                231.84,
                254.08,
                236.61,
                231.95,
                191.85,
                219.51,
                203.42
              ]
            },
            {
              name: 'Increase',
              data: [
                0,
                0,
                0,
                2.450000000000017,
                24.109999999999985,
                23.350000000000023,
                28.340000000000003
              ]
            }
          ],
          [
            {
              name: 'Flat Fee',
              data: [
                17.6,
                16.799999999999997,
                17.15,
                18.13,
                16.299999999999997,
                17.03,
                18.42
              ]
            },
            {
              name: 'Net Energy',
              data: [
                0,
                0,
                0,
                0,
                0,
                0,
                0
              ]
            },
            {
              name: 'Energy Delivered',
              data: [
                293.22,
                321.25,
                300.18,
                286.38,
                232.81,
                262.07,
                249.63
              ]
            },
            {
              name: 'Rate',
              data: [
                231.84,
                254.08,
                236.61,
                231.95,
                191.85,
                219.51,
                203.42
              ]
            },
            {
              name: 'Increase',
              data: [
                61.380000000000024,
                67.16999999999999,
                63.56999999999999,
                54.43000000000001,
                40.96000000000001,
                42.56,
                46.21000000000001
              ]
            }
          ]
        ],
        chartSeriesKeys: [
          'Flat Fee',
          'Net Energy',
          'Energy Delivered',
          'Rate',
          'Increase'
        ],
        data: []
      },
      availableRates: [
        'Time-of-Use (Peak Pricing 4-9 p.m. Every Day) E-TOU-C',
        'Home Charging EV2-A',
        'Tiered Rate Plan E-1',
        'Time-of-Use (Peak Pricing 5-8 p.m. Weekdays) E-TOU-D',
        'Electric Home (E-ELEC)'
      ],
      months: [
        {
          key: 1,
          firstColumnAttr: 6,
          compareRate: [
            {
              dif: 0,
              total: 231.84,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.4,
                'Net Energy': 0,
                'Energy Delivered': 229.44
              }
            },
            {
              dif: -43.129999999999995,
              total: 188.71,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 1.98,
                'Net Energy': 0,
                'Energy Delivered': 186.73
              }
            },
            {
              dif: -6.740000000000009,
              total: 225.1,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.3400000000000003,
                'Net Energy': 0,
                'Energy Delivered': 222.7616896458
              }
            },
            {
              dif: -16.189999999999998,
              total: 215.65,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.24,
                'Net Energy': 0,
                'Energy Delivered': 213.41000000000003
              }
            },
            {
              dif: -7.930000000000007,
              total: 223.91,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 17.6,
                'Net Energy': 0,
                'Energy Delivered': 293.22
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -23.24,
                percentage: -10
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.26,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 234.21,
                percentage: 101
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 18.47,
                percentage: 8
              }
            ],
            [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 43.58,
                percentage: 23
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.84,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 123.5,
                percentage: 65
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 19.65,
                percentage: 10
              }
            ],
            [
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.2,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 1',
                totalAmount: 92.46180899999999,
                percentage: 41
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2',
                totalAmount: 130.2998806458,
                percentage: 58
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.1,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 206.46,
                percentage: 96
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 6.95,
                percentage: 3
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 86.91,
                percentage: 39
              },
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 37.78,
                percentage: 17
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.18,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 15.28,
                percentage: 7
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 149.52,
                percentage: 67
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 19.01,
                percentage: 8
              }
            ]
          ]
        },
        {
          key: 2,
          firstColumnAttr: 7,
          compareRate: [
            {
              dif: 0,
              total: 254.08,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.63,
                'Net Energy': 0,
                'Energy Delivered': 251.45
              }
            },
            {
              dif: -45.650000000000006,
              total: 208.43,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.1799999999999997,
                'Net Energy': 0,
                'Energy Delivered': 206.25
              }
            },
            {
              dif: -8.5,
              total: 245.58,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.55,
                'Net Energy': 0,
                'Energy Delivered': 243.0297578022
              }
            },
            {
              dif: -20.120000000000005,
              total: 233.96,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.4299999999999997,
                'Net Energy': 0,
                'Energy Delivered': 231.53
              }
            },
            {
              dif: -11.830000000000013,
              total: 242.25,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 16.799999999999997,
                'Net Energy': 0,
                'Energy Delivered': 321.25
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -21.74,
                percentage: -9
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.48,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 244.26,
                percentage: 96
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 28.93,
                percentage: 11
              }
            ],
            [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 48.82,
                percentage: 23
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.03,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 126.68,
                percentage: 61
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 30.75,
                percentage: 15
              }
            ],
            [
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.4,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 1',
                totalAmount: 86.496531,
                percentage: 35
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2',
                totalAmount: 156.5332268022,
                percentage: 64
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.28,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 216.07,
                percentage: 92
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 15.46,
                percentage: 7
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 95.8,
                percentage: 40
              },
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 42.32,
                percentage: 17
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.36,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 14.29,
                percentage: 6
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 153.37,
                percentage: 63
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 29.76,
                percentage: 12
              }
            ]
          ]
        },
        {
          key: 3,
          firstColumnAttr: 8,
          compareRate: [
            {
              dif: 0,
              total: 236.61,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.45,
                'Net Energy': 0,
                'Energy Delivered': 234.16
              }
            },
            {
              dif: -42.870000000000005,
              total: 193.74,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.03,
                'Net Energy': 0,
                'Energy Delivered': 191.71
              }
            },
            {
              dif: -7.650000000000006,
              total: 228.96,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.37,
                'Net Energy': 0,
                'Energy Delivered': 226.5876828222
              }
            },
            {
              dif: -17.200000000000017,
              total: 219.41,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.2800000000000002,
                'Net Energy': 0,
                'Energy Delivered': 217.13
              }
            },
            {
              dif: -8.580000000000013,
              total: 228.03,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 17.15,
                'Net Energy': 0,
                'Energy Delivered': 300.18
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -22.48,
                percentage: -10
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.31,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 232.02,
                percentage: 98
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 24.619999999999997,
                percentage: 10
              }
            ],
            [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 43.2,
                percentage: 22
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.89,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 122.33,
                percentage: 63
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 26.18,
                percentage: 14
              }
            ],
            [
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.23,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 1',
                totalAmount: 89.47917000000001,
                percentage: 39
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2',
                totalAmount: 137.1085128222,
                percentage: 60
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.14,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 205.51,
                percentage: 94
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 11.620000000000001,
                percentage: 5
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 89.30000000000001,
                percentage: 39
              },
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 37.45,
                percentage: 16
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.23,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 14.78,
                percentage: 6
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 148.1,
                percentage: 65
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 25.33,
                percentage: 11
              }
            ]
          ]
        },
        {
          key: 4,
          firstColumnAttr: 9,
          compareRate: [
            {
              dif: 0,
              total: 231.95,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.42,
                'Net Energy': 0,
                'Energy Delivered': 229.53
              }
            },
            {
              dif: -27.769999999999982,
              total: 204.18,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.15,
                'Net Energy': 0,
                'Energy Delivered': 202.03
              }
            },
            {
              dif: 5.550000000000011,
              total: 237.5,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.4699999999999998,
                'Net Energy': 0,
                'Energy Delivered': 235.0321879962
              }
            },
            {
              dif: 4.920000000000016,
              total: 236.87,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.47,
                'Net Energy': 0,
                'Energy Delivered': 234.4
              }
            },
            {
              dif: -6.060000000000002,
              total: 225.89,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 18.13,
                'Net Energy': 0,
                'Energy Delivered': 286.38
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -33.17,
                percentage: -14
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 111.95,
                percentage: 48
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.26,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 14.34,
                percentage: 6
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 126.05,
                percentage: 54
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 10.36,
                percentage: 4
              }
            ],
            [
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 20.74,
                percentage: 10
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 62.59,
                percentage: 31
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.99,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 14.74,
                percentage: 7
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 68.18,
                percentage: 33
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 24.77,
                percentage: 12
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 11.01,
                percentage: 5
              }
            ],
            [
              {
                billLineItemName: 'Winter Energy Charge Tier 1',
                totalAmount: 81.287415,
                percentage: 34
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2',
                totalAmount: 33.7464626082,
                percentage: 14
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.32,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 1',
                totalAmount: 50.704863,
                percentage: 21
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2',
                totalAmount: 69.293447388,
                percentage: 29
              },
              {
                billLineItemName: 'Summer Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 111.58,
                percentage: 47
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.31,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 7.37,
                percentage: 3
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 110.41,
                percentage: 47
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 5.04,
                percentage: 2
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 78.62,
                percentage: 35
              },
              {
                billLineItemName: 'Summer Part-peak Energy_Charges',
                totalAmount: 17.98,
                percentage: 8
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 66.9,
                percentage: 30
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.2,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 15.77,
                percentage: 7
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 11.25,
                percentage: 5
              },
              {
                billLineItemName: 'Summer Off-peak Energy_Charges',
                totalAmount: 82.54,
                percentage: 37
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 18.44,
                percentage: 8
              },
              {
                billLineItemName: 'Summer Peak Energy_Charges',
                totalAmount: 10.65,
                percentage: 5
              }
            ]
          ]
        },
        {
          key: 5,
          firstColumnAttr: 10,
          compareRate: [
            {
              dif: 0,
              total: 191.85,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.0100000000000002,
                'Net Energy': 0,
                'Energy Delivered': 189.84
              }
            },
            {
              dif: -6.680000000000007,
              total: 185.17,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 1.9500000000000002,
                'Net Energy': 0,
                'Energy Delivered': 183.22
              }
            },
            {
              dif: 17.039999999999992,
              total: 208.89,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.18,
                'Net Energy': 0,
                'Energy Delivered': 206.7103047882
              }
            },
            {
              dif: 26.379999999999995,
              total: 218.23,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.27,
                'Net Energy': 0,
                'Energy Delivered': 215.95999999999998
              }
            },
            {
              dif: -0.3400000000000034,
              total: 191.51,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 16.299999999999997,
                'Net Energy': 0,
                'Energy Delivered': 232.81
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -39.49,
                percentage: -21
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 207.06,
                percentage: 108
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.87,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 22.27,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 118.34,
                percentage: 64
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.81,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 22.9,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 41.98,
                percentage: 23
              }
            ],
            [
              {
                billLineItemName: 'Winter Energy Charge Tier 1',
                totalAmount: 157.155669,
                percentage: 75
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2',
                totalAmount: 49.5546357882,
                percentage: 24
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.04,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 205.54,
                percentage: 94
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.13,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 10.42,
                percentage: 5
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 57.6,
                percentage: 30
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 126.48,
                percentage: 66
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.87,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 14.29,
                percentage: 7
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 17.47,
                percentage: 9
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.14,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 31.26,
                percentage: 16
              }
            ]
          ]
        },
        {
          key: 6,
          firstColumnAttr: 11,
          compareRate: [
            {
              dif: 0,
              total: 219.51,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.3000000000000003,
                'Net Energy': 0,
                'Energy Delivered': 217.21
              }
            },
            {
              dif: -10.629999999999995,
              total: 208.88,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.2,
                'Net Energy': 0,
                'Energy Delivered': 206.68
              }
            },
            {
              dif: 19.150000000000006,
              total: 238.66,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.49,
                'Net Energy': 0,
                'Energy Delivered': 236.1741529422
              }
            },
            {
              dif: 25.900000000000006,
              total: 245.41,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.5500000000000003,
                'Net Energy': 0,
                'Energy Delivered': 242.86
              }
            },
            {
              dif: -5.269999999999982,
              total: 214.24,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 17.03,
                'Net Energy': 0,
                'Energy Delivered': 262.07
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -40.85,
                percentage: -19
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 232.57,
                percentage: 106
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.14,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 25.49,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 132.09,
                percentage: 63
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.04,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 26.21,
                percentage: 13
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 48.38,
                percentage: 23
              }
            ],
            [
              {
                billLineItemName: 'Winter Energy Charge Tier 1',
                totalAmount: 162.57483,
                percentage: 68
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2',
                totalAmount: 73.5993229422,
                percentage: 31
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.33,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 232.97,
                percentage: 95
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.39,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 9.89,
                percentage: 4
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 64.86,
                percentage: 30
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 141.19,
                percentage: 66
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.09,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 14.78,
                percentage: 7
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 20,
                percentage: 9
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.16,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 36.02,
                percentage: 17
              }
            ]
          ]
        },
        {
          key: 7,
          firstColumnAttr: 12,
          compareRate: [
            {
              dif: 0,
              total: 203.42,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 2.13,
                'Net Energy': 0,
                'Energy Delivered': 201.29
              }
            },
            {
              dif: -6.469999999999999,
              total: 196.95,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.07,
                'Net Energy': 0,
                'Energy Delivered': 194.88000000000002
              }
            },
            {
              dif: 18.350000000000023,
              total: 221.77,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.31,
                'Net Energy': 0,
                'Energy Delivered': 219.4613313504
              }
            },
            {
              dif: 30.78,
              total: 234.2,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 2.44,
                'Net Energy': 0,
                'Energy Delivered': 231.76
              }
            },
            {
              dif: 2.960000000000008,
              total: 206.38,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 18.42,
                'Net Energy': 0,
                'Energy Delivered': 249.63
              }
            }
          ],
          lineItems: [
            [
              {
                billLineItemName: 'Baseline_Credit',
                totalAmount: -44.93,
                percentage: -22
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 223.35,
                percentage: 110
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.98,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 22.87,
                percentage: 11
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 130.86,
                percentage: 66
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 1.92,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 23.520000000000003,
                percentage: 12
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15000000000000002,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 40.5,
                percentage: 21
              }
            ],
            [
              {
                billLineItemName: 'Winter Energy Charge Tier 1',
                totalAmount: 178.832313,
                percentage: 81
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2',
                totalAmount: 40.6290183504,
                percentage: 18
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.16,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Energy Charge Tier 2 Continued',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15000000000000002,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 222.74,
                percentage: 95
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.29,
                percentage: 1
              },
              {
                billLineItemName: 'Minimum_Charge',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 9.02,
                percentage: 4
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15000000000000002,
                percentage: 0
              }
            ],
            [
              {
                billLineItemName: 'Generation',
                totalAmount: 61.67,
                percentage: 30
              },
              {
                billLineItemName: 'Winter Off-peak Energy_Charges',
                totalAmount: 139.87,
                percentage: 68
              },
              {
                billLineItemName: 'User_Utility_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'NEM_Charges_Before_Tax',
                totalAmount: 0,
                percentage: 0
              },
              {
                billLineItemName: 'SF_Prop_C_Tax_Surcharge',
                totalAmount: 2.01,
                percentage: 1
              },
              {
                billLineItemName: 'Base_Services_Charge',
                totalAmount: 16.26,
                percentage: 8
              },
              {
                billLineItemName: 'Winter Peak Energy_Charges',
                totalAmount: 17.94,
                percentage: 9
              },
              {
                billLineItemName: 'Energy_Commission_Tax',
                totalAmount: 0.15,
                percentage: 0
              },
              {
                billLineItemName: 'Winter Part-peak Energy_Charges',
                totalAmount: 30.15,
                percentage: 15
              }
            ]
          ]
        },
        {
          key: 8,
          firstColumnAttr: 'Total',
          compareRate: [
            {
              dif: 0,
              total: 1570,
              currentRate: true,
              tagAttributes: {
                'Flat Fee': 16.34,
                'Net Energy': 0,
                'Energy Delivered': 1552.9199999999998
              }
            },
            {
              dif: -185,
              total: 1385,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 14.559999999999999,
                'Net Energy': 0,
                'Energy Delivered': 1371.5000000000002
              }
            },
            {
              dif: 35,
              total: 1605,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 16.71,
                'Net Energy': 0,
                'Energy Delivered': 1589.7571073472002
              }
            },
            {
              dif: 35,
              total: 1605,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 16.68,
                'Net Energy': 0,
                'Energy Delivered': 1587.05
              }
            },
            {
              dif: -40,
              total: 1530,
              currentRate: false,
              tagAttributes: {
                'Flat Fee': 121.42999999999999,
                'Net Energy': 0,
                'Energy Delivered': 1945.54
              }
            }
          ]
        }
      ],
      rateDetails: [
        {
          ratePlanCode: 'ETOUC',
          rateMarketingName: 'Time-of-Use (Peak Pricing 4-9 p.m. Every Day) E-TOU-C',
          totalAmt: 1570,
          totalSavings: 0,
          bestRate: false,
          currentRate: true
        },
        {
          ratePlanCode: 'EV2A',
          rateMarketingName: 'Home Charging EV2-A',
          totalAmt: 1385,
          totalSavings: 185,
          bestRate: true,
          currentRate: false
        },
        {
          ratePlanCode: 'E1',
          rateMarketingName: 'Tiered Rate Plan E-1',
          totalAmt: 1605,
          totalSavings: -35,
          bestRate: false,
          currentRate: false
        },
        {
          ratePlanCode: 'ETOUD',
          rateMarketingName: 'Time-of-Use (Peak Pricing 5-8 p.m. Weekdays) E-TOU-D',
          totalAmt: 1605,
          totalSavings: -35,
          bestRate: false,
          currentRate: false
        },
        {
          ratePlanCode: 'EELEC',
          rateMarketingName: 'Electric Home (E-ELEC)',
          totalAmt: 1530,
          totalSavings: 40,
          bestRate: false,
          currentRate: false
        }
      ],
      bestRates: [
        {
          month: 6,
          total: 188.71
        },
        {
          month: 7,
          total: 208.43
        },
        {
          month: 8,
          total: 193.74
        },
        {
          month: 9,
          total: 204.18
        },
        {
          month: 10,
          total: 185.17
        },
        {
          month: 11,
          total: 208.88
        },
        {
          month: 12,
          total: 196.95
        }
      ],
      tagAttributesList: [
        'Flat Fee',
        'Net Energy',
        'Energy Delivered'
      ]
    }
  }
};
