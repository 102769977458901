import React, { useEffect, useRef, useState } from 'react';
import Avatar from '../Avatar/Avatar';
import Link from '../Link/Link';
import { useNavigate } from 'react-router-dom';
import './user-menu.scss';

interface UserMenuProps {
  logoutCallback: () => void;
  showSettings?: boolean;
  settingsUrl?: string
  user: string
}

const UserMenu: React.FC<UserMenuProps> = ({
  logoutCallback,
  showSettings,
  settingsUrl,
  user
}: UserMenuProps) => {
  const navigate = useNavigate();
  const [showOptions, setShowOptions] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const currentRef: any = ref.current;
    const handleClickOutside = (event: any) => {
      if (currentRef && !currentRef.contains(event.target)) {
        showOptions && setShowOptions(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [showOptions]);

  const menuItemsArray = [
    {
      displayName: 'Logout',
      name: 'logout',
      url: '#logout',
      active: true
    },
    {
      displayName: 'Settings',
      name: 'settings',
      url: `/${settingsUrl}`,
      active: showSettings
    }
  ];

  const onClickAction = (event: any, displayName: string) => {
    event.preventDefault();
    event.stopPropagation();
    if (displayName === 'Logout') {
      localStorage.clear();
      logoutCallback();
    } else if (displayName === 'Settings') {
      setShowOptions(false);
      navigate(`/${settingsUrl}`);
    }
  };
  const renderMenuItems = menuItemsArray.map((item) => {
    const {
      displayName,
      url,
      active
    } = item;

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
      active && <li
        className='flex flex--align-center bor--b-1 pad--b-12 pad--l-12 pad--t-12'
        key={displayName}
        onClick={(event) => {
          onClickAction(event, displayName);
        }}
      >
        <Link
          className='icon'
          color='stone'
          copy={displayName}
          href={url}
          icon={false}
        />
      </li>
    );
  });

  return (
    <nav
      className='menu--user'
      aria-label="User Navigation"
      ref={ref}
    >
      {user &&
        <div
          role='button'
          onClick={() => setShowOptions(!showOptions)}
          onKeyDown={() => setShowOptions(!showOptions)}
          tabIndex={0}>
          <Avatar
            className='mar--t-20'
            alt={user}
            size='sm'
          />
        </div>
      }
      {showOptions &&
      <ul className='sub-nav pad--l-0 bor--1 shadow'>
        {renderMenuItems}
      </ul>}
    </nav>
  );
};

export default UserMenu;
