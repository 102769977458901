import { createSlice } from '@reduxjs/toolkit';

// TYPINGS
import { IEvent } from 'api/events/events.typings';
import { FilterItem, SortItem } from 'store/helpers/store.typings';

// HELPERS
import setFilter from 'store/helpers/setFilter';

const initialEvents = {
  layout: {
    isEventHandlerOpen: false,
    isFilterOpen: false
  },
  eventsFilters: [{} as FilterItem<IEvent>],
  eventsSortBy: {} as SortItem<IEvent>,
  eventsSearchString: '',
  eventHandlerId: null
};

const eventsSlice = createSlice({
  name: 'EVENTS',
  initialState: initialEvents,
  reducers: {
    setEventHandlerOpen: (state, { payload }) => {
      state.layout.isEventHandlerOpen = payload.open;
      if (payload.id) {
        state.eventHandlerId = payload.id;
      } else {
        state.eventHandlerId = null;
      }
    },
    // ACCOUNTS
    setEventsSearchString: (state, { payload }) => {
      state.eventsSearchString = payload;
    },
    setSortedEvents: (state, { payload }) => {
      state.eventsSortBy.key = payload.sortKey;
      if (payload.sortKey === state.eventsSortBy.key) {
        state.eventsSortBy.ascending = !state.eventsSortBy.ascending;
      }
    },
    setEventsFilterOpen: (state, { payload }) => {
      state.layout.isFilterOpen = payload;
    },
    toogleFilter: (state) => {
      state.layout.isFilterOpen = !state.layout.isFilterOpen;
    },
    setEventsFilter: (state, { payload }) => {
      setFilter<IEvent>(state.eventsFilters, payload);
    },
    removeEventsFilter: (state, { payload }) => {
      const selectedFilter = state.eventsFilters.find(filter => filter.key === payload.key);
      if (selectedFilter) {
        if (selectedFilter.value.includes(payload.value)) {
          selectedFilter.value = selectedFilter.value.filter((value: any) => value !== payload.value);
        }
      }
    },
    clearEventsFilter: (state) => {
      state.eventsFilters = [];
    }
  }
});

export const {
  setEventHandlerOpen,
  setEventsFilterOpen,
  setSortedEvents,
  setEventsSearchString,
  setEventsFilter,
  removeEventsFilter,
  clearEventsFilter,
  toogleFilter
} = eventsSlice.actions;

export default eventsSlice.reducer;
