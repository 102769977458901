import { gridxTheme, pgeTheme, sceTheme } from 'hooks/Theme/Theme.config';

export const getCMSJsonUrl = (theme: string) => {
  switch (theme) {
  case 'pge':
    return 'https://explore-frontend-dev.s3.us-west-2.amazonaws.com/cms/pge/cms-pge-data.json';
  case 'sce':
    return 'https://explore-frontend-dev.s3.us-west-2.amazonaws.com/cms/sce/cms-sce-data.json';
  default:
    return 'https://explore-frontend-dev.s3.us-west-2.amazonaws.com/cms/energyx/cms-data.json';
  }
};

export const getTheme = (theme: string) => {
  switch (theme) {
  case 'pge':
    return pgeTheme;
  case 'sce':
    return sceTheme;
  default:
    return gridxTheme;
  }
};
