import React, { useContext, useState } from 'react';

// COMPONENTS
import CostResultChart from '../CostResultChart/CostResultChart';
import CostResultTable from '../CostResultTable/CostResultTable';
import { Icon, Tabs } from 'components';

// ASSETS
import styles from './EletricCostResult.module.scss';

// HELPERS
import { dataTemp } from './EletricCostResult.helpers';
import { CompareRateDetails } from '../CostResultTable/types';
import { ExploreContext } from 'widget/ExploreWidget/helpers/ExploreContext';

const EletricCostResult = () => {
  const { state } = useContext(ExploreContext);
  const [activeTab, setActiveTab] = useState(0);

  // EVENTS
  const handleTabChange = (event: any, index: number) => {
    setActiveTab(index);
  };

  // RENDERS
  const costTabs = [
    {
      label: 'Chart'
    },
    {
      label: 'Table'
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.title}>12-month electric cost</p>
        <div>
          <Tabs
            tabs={costTabs}
            size="sm"
            value={activeTab}
            onChange={handleTabChange}
          />
        </div>
      </div>
      <div>
        {activeTab === 0 && (<CostResultChart/>)}
        {activeTab === 1 && (<CostResultTable data={dataTemp as CompareRateDetails}/>)}
      </div>
    </div>
  );
};

export default EletricCostResult;
