import React, { ReactNode } from 'react';

// COMPONENTS
import Box from '../../components/Box/Box';
import Text from '../../components/Text/Text';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

// ASSETS
import './alert-inline.scss';

export type AlertInlineButtons = {
  primary: {
    label: string,
    onClick: () => void
  },
  secondary?: {
    label: string,
    onClick: () => void
  }
}

interface AlertInlineProps {
    className?: string,
    alertMessage?: string | ReactNode,
    alertTitle?: string,
    type?: 'display' | 'error' | 'success' | 'info' | 'gray' | 'warning',
    variant?: 'filled' | 'outlined',
    icon?: boolean,
    action?: ReactNode,
    /**
     * @deprecated This is going to be removed in favor of "action" prop which accepts a ReactNode
     */
    actionName?: string | ReactNode | null,
    closeButton?: boolean | ReactNode,
    buttons?: AlertInlineButtons
};

/**
* Represents a AlertInline.
* @constructor
* @param {string} className
* @param {string} alertMessage
* @param {string} alertTitle
* @param {string} type
* @param {boolean} icon
* @param {boolean} action
* @param {string} actionName
* @param {boolean} closeButton
* Usage :
* ````js
* <AlertInline className='my-alert-inline' alertMessage='Alert Message' alertTitle='Alert Title' type='info' icon=true action=true actionName='Action 1' closeButton=true />
* ````
* @augments {Component<Props, State>}
*/
/** */
const AlertInline: React.FC<AlertInlineProps> = ({
  className,
  alertMessage,
  actionName,
  alertTitle,
  type = 'gray',
  variant = 'filled',
  icon,
  action,
  closeButton,
  buttons
}) => {
  if (actionName) {
    console.warn('actionName is deprecated. Please use action prop instead');
  }

  // RENDERS
  const renderIcon = () => {
    if (type === 'info') {
      return <Icon.Info size={24}/>;
    } else if (type === 'success') {
      return <Icon.CheckCircle size={24}/>;
    }
    return <Icon.Info size={24}/>;
  };

  const buildAlertMessage = () => {
    if (!alertMessage) return null;

    if (typeof alertMessage === 'string') {
      return (
        <Text className='body--lg'>
          {alertMessage}
        </Text>
      );
    } else {
      return (<div>{alertMessage}</div>);
    }
  };

  return (
    <div
      className={`${className} alert alert--${type} alert--${variant} pad--b-24 pad--l-24 pad--r-24 pad--t-24`}
      aria-hidden
      role='presentation'
    >
      <div className='alert-container'>
        <div className='alert-container__left'>
          {icon
            ? (
              <Box mr={2} className='icon' display="flex">
                {renderIcon()}
              </Box>
            )
            : null}
          <div className='alert-message'>
            {alertTitle && (
              <p className='alert-message--title'>
                {alertTitle}
              </p>)}
            <p className='alert-message--description'>
              {buildAlertMessage()}
            </p>

            {action && (
              <div className='action'>
                { action }
              </div>
            )}

            {
              buttons && (
                <div className='buttons'>
                  <Button color='secondary' variant="outlined" copy={buttons.primary.label} onClick={buttons.primary.onClick} />
                  {
                    buttons.secondary && (
                      <Button color='secondary' variant="outlined" copy={buttons.secondary.label} onClick={buttons.secondary.onClick} />
                    )
                  }
                </div>
              )
            }
          </div>
        </div>
        <div className='alert-container__right'>
          {closeButton && <Button copy='' icon={<Icon.X/>} iconOnly variant="ghost" color="secondary" />}
        </div>
      </div>
    </div>
  );
};

export default AlertInline;
