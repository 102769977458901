import React from 'react';
import classnames from 'classnames';
import { Tabs as MuiTabs, Tab } from '@mui/material';

// COMPONENTS
import TabPanel from './TabPanel';

// ASSETS
import styles from './Tabs.module.scss';

export interface TabsProps {
  tabs: Array<any>;
  className?: any;
  onChange?: (event: Record<string, any>, value: any) => void;
  classes?: any;
  value?: any;
  color?: 'default' | 'depth';
  size?: 'sm' | 'md' | 'lg';
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  className,
  onChange,
  classes = {},
  value: valueProp = undefined,
  color = 'default',
  size = 'md',
  ...props
}) => {
  const [value, setValue] = React.useState(valueProp || 0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (onChange) {
      onChange(event, newValue);
    }
    setValue(newValue);
  };

  if (!tabs || tabs.length === 0) {
    return null;
  }

  // STYLES
  const cssTabs = classnames(
    className
  );

  const cssRoot = classnames(styles['root-container'], styles[`root-container__color-${color}`], styles[`root-container__size-${size}`]);
  const cssTab = classnames(styles['root-tab'], styles[`root-tab__size-${size}`]);

  return (
    <>
      <MuiTabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs"
        className={cssTabs}
        classes={{
          root: cssRoot,
          indicator: styles.indicator,
          ...classes
        }}
        {...props}
      >
        {tabs.map(({ label, ...props }) => (
          <Tab
            classes={{
              root: cssTab,
              selected: styles.selected
            }}
            label={label}
            key={label}
            {...props}
          />
        ))}
      </MuiTabs>
      {tabs.map((tab, index) => (
        <TabPanel key={tab.label} value={value} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </>
  );
};

export default Tabs;
