import { useState, useEffect } from 'react';

// same breakpoints from the scss (_layout.scss)
export const breakpoints = {
  mobileExtraSmall: '(max-width: 374px)',
  mobileSmall: '(max-width: 413px)',
  mobileMedium: '(max-width: 767px)',
  tabletSmall: '(max-width: 980px)',
  tabletMedium: '(max-width: 1024px)',
  desktopSmall: '(min-width: 1280px)',
  desktopMedium: '(min-width: 1366px)',
  desktopLarge: '(min-width: 1440px)',
  desktopExtraLarge: '(min-width: 1920px)'
};

const useMediaQuery = (query: string) => {
  // State and setter for match status
  const [isMatching, setIsMatching] = useState(false);

  useEffect(() => {
    // Initialize media query
    const mediaQuery = window.matchMedia(query);

    // Function to toggle isMatching
    const handleMediaChange = () => setIsMatching(mediaQuery.matches);

    // Initial check
    handleMediaChange();

    // Attach listener for changes
    mediaQuery.addListener(handleMediaChange);

    // Clean up listener on unmount
    return () => mediaQuery.removeListener(handleMediaChange);
  }, [query]);

  return isMatching;
};

export default useMediaQuery;
