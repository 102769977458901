import { decodeJwt } from 'jose';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';

import { TENANT_KEY } from '../../components/constants';
import { AuthenticationError } from './AuthenticationError';
import { TenantProductsPermissions, User } from './User';

export const AUTHENTICATION_INITIALIZATION_DOMAIN = 'authEmailDomain';
export const USER_TENANCY = 'UAS_TENANCY';
export const USER_AUTH_TOKEN_KEY = 'userToken';
export const USER_EMAIL_KEY = 'user';
export const PERMISSIONS_SET_TIME = 'PERMISSIONS_SET_TIME';
export const PERMISSIONS_SET = 'PERMISSIONS_SET';
export const EXPIRED_TOKEN_MESSAGE = 'AuthorizationClient - instantiateFromLocalStorage - token expired.';
export let ROOT_AUTH_API_URL = 'https://api-citadel-dev.devel.gridx.com';

export const REFRESH_API_TIMEOUT_MS = 1000 * 60 * 30; // 30 minutes

export function getRefreshApiTimeoutMs () {
  const configRefreshApi = localStorage.getItem('REFRESH_API_TIMEOUT_MS');
  if (!configRefreshApi) {
    return REFRESH_API_TIMEOUT_MS;
  } else {
    try {
      return parseInt(configRefreshApi);
    } catch (ex) {
      return REFRESH_API_TIMEOUT_MS;
    }
  }
}

export interface AuthorizationClientInterface {
  getUser(): User | undefined;
  isAuthorized(productName: string, permission: string): boolean;
  isAuthorizedForAction(user: User, action: string): boolean;
  isLoggedIn(): boolean;
  permissionsLoadedCallback?: Function;
}

export interface AuthorizationClientOptions {
  permissionsLoadedCallback?: Function;
  rootApiUrl: string;
  tokenExpirationTimeoutInSecs?: number;
}

export class AuthorizationClient implements AuthorizationClientInterface {
  user?: User;
  permissionsLoadedCallback?: Function;
  rootApiUrl: string;
  refreshTimer: ReturnType<typeof setInterval> | undefined;

  constructor (user: User | undefined, permissionsLoadedCallback?: Function, rootApiUrl: string = ROOT_AUTH_API_URL) {
    this.user = user;
    if (user) {
      this.refreshTimer = setInterval(() => {
        this.refreshToken();
      }, getRefreshApiTimeoutMs());
    }
    this.permissionsLoadedCallback = permissionsLoadedCallback;
    this.rootApiUrl = rootApiUrl;
    // Incase of using a static method to set the root api url, we need to set it to the global variable.
    ROOT_AUTH_API_URL = rootApiUrl;
  }

  getUser (): User | undefined {
    return this.user;
  }

  async refreshToken () {
    if (!this.user) {
      return;
    }

    const refreshToken = this.user.refreshToken;
    const refreshTokenUrl = `${this.rootApiUrl}/token`;
    const requestOptions = {
      body: JSON.stringify({
        refreshToken
      }),
      headers: {
        Authorization: this.user.idToken,
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      mode: 'cors' as RequestMode
    };

    return fetch(refreshTokenUrl, requestOptions)
      .then(response => response.json())
      .then(data => {
        const claims = decodeJwt(data.id_token);
        const userData = {
          accessToken: data.access_token,
          idToken: data.id_token,
          expiresIn: data.expires_in,
          refreshToken: this.user?.refreshToken, // We keep the existing refresh token
          tokenType: data.token_type,
          username: claims.email
        };
        this.user!.idToken = data.id_token;
        this.user!.accessToken = data.access_token;
        localStorage.setItem(USER_AUTH_TOKEN_KEY, JSON.stringify(userData));
      });
  }

  /**
   * @param product The name of the GridX product. Example: Analyze, Citadel, Design, etc.
   * @param permission The name of the permission to check. Example: GetProducts, GetUsers, etc.
   * @returns boolean
   */
  isAuthorized (productName: string, permission: string): boolean {
    if (!this.user) {
      return false;
    }

    if (!this.user.productPermissions) {
      return false;
    }

    // Product permissions are separated from tenant selections.
    // Thus, we need to see if they have any application in the tenant```
    const tenant = localStorage.getItem(TENANT_KEY) || 'gridx';
    const tenantProductPermissions = this.user.productPermissions.find(prodPerm => prodPerm.tenant?.tenantName?.toLowerCase() === tenant.toLowerCase());
    const productNamePermissions = tenantProductPermissions ? tenantProductPermissions.products.find(prodPerm => prodPerm.productName === productName) : null;
    // debugger;
    if (!productNamePermissions) {
      return false;
    }

    // By here, we know they have roles for the product name the permission is being checked for.
    // Now find any role with the permission.
    const permissionInRoles = productNamePermissions.roles.find(role => role.permissions.find(rolePerm => rolePerm.permissionName === permission));

    if (!permissionInRoles) {
      return false;
    }
    return true;
  }

  isAuthorizedForAction (): boolean {
    throw new Error('Method not implemented.');
  }

  isLoggedIn (): boolean {
    return this.user !== undefined;
  }

  /**
   * Attempt to get the product permissions from the API.
   * Permissions will be cached for 5 minutes.
   * @returns Array of the useres product roles and permissions.
   */
  async getUsersProductPermission (): Promise<Array<TenantProductsPermissions>> {
    // get users id token
    const userInfo = this.getUser();
    const user = userInfo || (localStorage?.getItem('userToken') ? JSON.parse(localStorage?.getItem('userToken') ?? '') : {});
    if (Object.keys(user).length) {
      const idToken = user.idToken;
      const email = user.username;
      const getProductPermissionsUrl = `${this.rootApiUrl}/users/permissions/${email}`;
      const tenant = localStorage.getItem(USER_TENANCY) || 'gridx';
      const tenantHeaderValue = tenant.toLowerCase();
      const getProductPermissionsResponse = await fetch(getProductPermissionsUrl, {
        headers: {
          Authorization: idToken,
          'X-Gridx-UAS-Tenant': tenantHeaderValue,
          'X-Gridx-UAS-AccessTenant': tenantHeaderValue
        }
      });
      if (getProductPermissionsResponse && getProductPermissionsResponse.status !== 200) {
        console.error('No Product Permissions returned');
        return [];
      }
      const productPermissions = await getProductPermissionsResponse.json();
      AuthorizationClient.setUserPermissionsToLocalStorage(productPermissions);
      if (this.permissionsLoadedCallback) {
        this.permissionsLoadedCallback(productPermissions);
      }
      return productPermissions;
    } else {
      return [];
    }
  }

  static setRootAuthApiUrl (rootAuthApiUrl: string): void {
    console.log('Setting root Auth API URL', rootAuthApiUrl);
    ROOT_AUTH_API_URL = rootAuthApiUrl;
  }

  static setUserPermissionsToLocalStorage (productPermissions: TenantProductsPermissions[]) {
    localStorage.setItem(PERMISSIONS_SET_TIME, `${new Date().getTime()}`);
    localStorage.setItem(PERMISSIONS_SET, JSON.stringify(productPermissions));
  }

  static getUserPermissionsFromLocalStorage (currentTimestamp: number) {
    let permissions;
    const previousPermissionsTime = localStorage.getItem(PERMISSIONS_SET_TIME);
    const previousPermissions = localStorage.getItem(PERMISSIONS_SET);
    if (previousPermissionsTime) {
      const prevPermssionTime = Number(previousPermissionsTime);
      const TWENTY_MINUTES_IN_MS = 1000 * 20 * 60;
      const TWENTY_MINUTES_AGO_IN_MS = (currentTimestamp - TWENTY_MINUTES_IN_MS);
      if (prevPermssionTime && prevPermssionTime >= TWENTY_MINUTES_AGO_IN_MS) {
        try {
          permissions = previousPermissions ? JSON.parse(previousPermissions) : [];
        } catch (ex) {
          console.error('Error reading previous permissions:', ex);
          permissions = undefined;
        }
      }
    }

    return permissions;
  }

  static instantiateFromLocalStorage (options: AuthorizationClientOptions): AuthorizationClient | undefined {
    const localStorageUser = localStorage.getItem(USER_AUTH_TOKEN_KEY);
    if (!localStorageUser) {
      return undefined;
    }

    // Have user provide expirey time, or default to now in seconds:
    const nowInSeconds = options.tokenExpirationTimeoutInSecs || (new Date().getTime() + 1) / 1000;
    const hydratedUser = JSON.parse(localStorageUser);

    const rootApiUrl = options.rootApiUrl || ROOT_AUTH_API_URL;

    const payload = decodeJwt(hydratedUser.accessToken);
    if (!payload || !payload.exp || payload.exp < nowInSeconds) {
      throw new AuthenticationError('TOKEN_EXPIRED', EXPIRED_TOKEN_MESSAGE, payload);
    }

    const currentTimestamp = new Date().getTime();
    const permissions = AuthorizationClient.getUserPermissionsFromLocalStorage(currentTimestamp);

    const user: User = new User(hydratedUser.username, hydratedUser.idToken, hydratedUser.accessToken, hydratedUser.refreshToken, permissions);

    const client = new AuthorizationClient(user, options.permissionsLoadedCallback, rootApiUrl);

    client.getUsersProductPermission().then((productPermissions: Array<TenantProductsPermissions>) => {
      if (client.user) {
        client.user.productPermissions = productPermissions;
      }
    });

    return client;
  }

  /**
   * Request the authentication redirection to happen. This function will store a
   * domain token to tell the client how to request auth codes if the customer is a
   * 'bring your idp' customer, and we authenticate with Google/Okta/etc.
   * A request is sent to the authenticate API - which either redirects the browser
   * to the IdP login. Otherwise, the API returns a 200 request, in which
   * this function returns to the caller and initiate the password prompt
   * for login via email/password.
   *
   * @param userEmailDomainKey The domain without the TLD. Example: gridx or energyx
   * @returns Object containing the password prompt - or no return via HTTP redirect.
   */
  static async startAuthenticationRedirection (userEmailDomainKey: string, forceRedirection = true, redirectionUrl: null | undefined | string) {
    localStorage.setItem(USER_TENANCY, userEmailDomainKey);

    // Any other gridx application will pass in a redirection url to end up at the correct
    // application after authentication. If this call receives a redirection url, we will
    // need to pass the users authentication domain for auth code exchange.
    // If no redirection url is passed in, we will default
    // to the current window location.
    const base64encodedState = btoa(JSON.stringify({ domain: userEmailDomainKey }));
    const redirectUri = redirectionUrl || `${window.location.origin}/authcode`;

    // Here we'd want to hit the Authentication service to verify the domain.
    // If we get a good response, we know we've got a domain user that is accepted.
    // For now, we'll assume it's a good user and redirect.
    localStorage.setItem(AUTHENTICATION_INITIALIZATION_DOMAIN, userEmailDomainKey);

    const requestOptions = {
      method: 'GET',
      mode: 'cors' as RequestMode,
      redirect: 'follow'
    } as RequestInit;

    // We will issue a request to the authenticate handler.
    // If the client is redirected via a 301 to a location, we will take that via the
    // `redirect: follow` attribute in fetch.
    // Otherwise, we will prompt user for a password if receiving a 200
    // request with a payload stating to prompt for password.
    const authUrl = `${ROOT_AUTH_API_URL}/authenticate?domain=${userEmailDomainKey}&t=${new Date().getTime()}&redirectUri=${redirectUri}`;
    const response = await fetch(authUrl, requestOptions);
    if (response.status === 200) {
      // Here, we got a 200, meaning we dont redirect to customers IdP.
      // Instead we want to prompt user for password, as this is a gridx provided
      // idp customer.
      const resultBody = await response.json();
      if (resultBody.promptForPassword) {
        return { prompForPassword: true };
      }
    } else if (response.status === 202) {
      // Here we signal from the auth API - redirect to cognito to handle the flow
      // through IdP via 202, this is so we dont hit a redirect limit
      // through fetch, resulting in a "Fetch failed" exception.
      const resultBody = await response.json();

      if (forceRedirection) {
        window.location.href = `${resultBody.redirectUri}&state=${base64encodedState}`;
      } else {
        return resultBody;
      }
    } else {
      console.log('Uncertain of Authenticate response: ', response);
      throw new AuthenticationError('AUTHENTICATION_FAILED', 'Uncertain of Authentication repsonse', response);
    }
  }

  static getAuthenticationEmailDomain (): string {
    const maybeEmailDomain = localStorage.getItem(AUTHENTICATION_INITIALIZATION_DOMAIN);
    return maybeEmailDomain || '';
  }

  /**
   * Attempt to exchange the authentication code in for a set of JWT access tokens.
   * The auth code comes from Cognito and is exchanged with Cognito via the
   * specific user pool `/oauth2/token` endpoints.
   * We need a way to look up the auth token endpoints by user email domains.
   * @param authCode The Auth code as given from Cognito
   */
  static async getAuthTokenFromAuthCode (authCode: string, requestedUserEmailDomainKey?: string): Promise<boolean> {
    // Ensure the domain is set for UAS Tenancy:
    if (requestedUserEmailDomainKey) {
      localStorage.setItem(USER_TENANCY, requestedUserEmailDomainKey);
    }
    const userEmailDomainKey = requestedUserEmailDomainKey || localStorage.getItem(AUTHENTICATION_INITIALIZATION_DOMAIN);

    if (!userEmailDomainKey) {
      throw new Error('No User Email Domain has been set to start the authentication process.');
    }

    const requestOptions = {
      body: JSON.stringify({
        code: authCode,
        domain: userEmailDomainKey,
        redirectUri: `${window.location.origin}/authcode`
      }),
      headers: {
        'X-Gridx-Correlation-ID': uuidv4(),
        'Content-Type': 'application/json',
        'X-Gridx-uas-accesstenant': userEmailDomainKey,
        'X-Gridx-uas-tenant': userEmailDomainKey
      },
      method: 'POST',
      mode: 'cors' as RequestMode
    };

    const tokenExchangeUrl = `${ROOT_AUTH_API_URL}/token`;

    const response = await fetch(tokenExchangeUrl, requestOptions);
    const data = await response.json();

    if (response.status === 200) {
      const claims = decodeJwt(data.id_token);
      const userData = {
        accessToken: data.access_token,
        idToken: data.id_token,
        expiresIn: data.expires_in,
        refreshToken: data.refresh_token,
        tokenType: data.token_type,
        username: claims.email
      };
      // @ts-ignore Argument of type 'unknown' is not assignable to parameter of type 'string'
      localStorage.setItem(USER_EMAIL_KEY, claims.email);
      localStorage.setItem(USER_AUTH_TOKEN_KEY, JSON.stringify(userData));

      // Now get permissions from token payload
      const userPermissions = data.userPermissions;
      if (userPermissions) {
        AuthorizationClient.setUserPermissionsToLocalStorage(userPermissions);
      }

      return true;
    } else {
      return false;
    }
  }
}
