import React from 'react';
import { Route } from 'react-router-dom';

// COMPONENTS
import { AnalyzeList, FullPopulation, RateDetails, Rates } from 'pages/Analyze/sections';

const AnalyzeRoutes = [
  <Route key="fullpopulation" path='/analyze/fullpopulation' element={<FullPopulation />} />,
  <Route key="rates" path='/analyze/rates' element={<Rates />} />,
  <Route key="single-rate" path='/analyze/rates/:id' element={<RateDetails />} />,
  <Route key="list" path='/analyze/list' element={<AnalyzeList />} />
];

export default AnalyzeRoutes;
