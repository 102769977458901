import React, { useState } from 'react';
import classnames from 'classnames';
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import TextField, { TextFieldProps } from '@mui/material/TextField';

// COMPONENTS
import InputLabel from '../InputLabel/InputLabel';
import Icon from '../Icon/Icon';
import Tag from '../Tag/Tag';

// ASSETS
import styles from './TextInput.module.scss';

type Props = Omit<TextFieldProps, 'size'> & {
  autocompleteAttribute?: string,
  className?: string,
  endAdornment?: React.ReactNode,
  errorMessage?: string,
  infoMessage?: string,
  labelDescription?: string,
  suffix?: string | React.ReactNode | React.ReactElement;
  prefix?: string | React.ReactNode | React.ReactElement;
  multiple?: boolean,
  onOptionsChange?: (event: React.SyntheticEvent, value: any | Array<any>, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails) => void,
  optional?: boolean,
  options?: any[],
  startAdornment?: React.ReactNode,
  onFocus?: React.FocusEventHandler<HTMLInputElement>,
  onBlur?: React.FocusEventHandler<HTMLInputElement>,
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
  inputRef?: any
};

/**
* Represents a TextInput.
*
* @constructor
* @param {string} autocompleteAttribute
* @param {string} className
* @param {node} endAdornment
* @param {string} errorMessage
* @param {string} infoMessage
* @param {boolean} multiple
* @param {boolean} optional
* @param {array} options
* @param {node} startAdornment
*
* Usage:

* ````js
* <TextInput
*   errorMessage="Validation message text"
*   id="my-text-input"
*   infoMessage="This is an assistive text for the user"
*   label="Label"
*   placeholder="Placeholder" />
* ````

* @augments {Component<Props, State>}
*/

const TextInput: React.FC<Props> = ({
  autocompleteAttribute,
  className,
  disabled,
  endAdornment,
  error,
  errorMessage,
  id,
  infoMessage,
  labelDescription,
  label,
  multiple,
  onOptionsChange,
  optional,
  options,
  size = 'sm' as const,
  startAdornment,
  suffix,
  prefix,
  sx,
  onFocus,
  onBlur,
  inputRef,
  ...forwardProps
}) => {
  const [isFocused, setIsFocused] = useState(false);
  if (endAdornment) {
    forwardProps.InputProps = { endAdornment, ...forwardProps.InputProps };
  }

  if (startAdornment) {
    forwardProps.InputProps = { startAdornment, ...forwardProps.InputProps };
  }

  // EVENTS
  const handleFocus: React.FocusEventHandler<HTMLInputElement> = (event) => {
    setIsFocused(true);
    if (onFocus) {
      onFocus(event);
    }
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
    setIsFocused(false);
    if (onBlur) {
      onBlur(event);
    }
  };

  // STYLES
  const cssInputContainer = classnames(styles['input-container'], styles[`input-container__${size}`], {
    [styles['input-container__focused']]: isFocused
  });

  const cssTextInput = classnames(styles['text-input'], className, {
    [styles['text-input__fullwidth']]: forwardProps.fullWidth,
    [styles['text-input__with-suffix']]: suffix,
    [styles['text-input__with-prefix']]: prefix
  });

  const cssSuffix = classnames(styles.suffix, styles[`suffix__${size}`]);

  const cssPrefix = classnames(styles.prefix, styles[`prefix__${size}`]);

  console.log('forwardProps', forwardProps);

  return (
    <Stack className={cssTextInput} gap={0} sx={sx}>
      <Stack className={styles['helper--container']} alignItems="center" direction="row" gap={0.5}>
        {label &&
          <InputLabel
            infoMessage={infoMessage}
            isOptional={optional}
            description={labelDescription}
          >
            {label}
          </InputLabel>
        }
      </Stack>
      <div className={cssInputContainer}>
        {prefix && <div className={cssPrefix}>{prefix}</div>}
        {options
          ? (
            <Autocomplete
              disabled={disabled}
              id={id}
              multiple={multiple}
              onChange={onOptionsChange}
              options={options}
              renderInput={(params) => {
                const mergedProps = { error, ...forwardProps, ...params };
                return <TextField {...mergedProps} />;
              }}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => {
                  const tagProps = getTagProps({ index });

                  return (
                  // `key` is included in result of `getTagProps`
                  // eslint-disable-next-line react/jsx-key
                    <Tag
                      copy={option}
                      icon={<Icon.X onClick={tagProps.onDelete} />}
                      iconPos="right"
                      {...tagProps}
                    />
                  );
                })
              }
            />
          )
          : (
            <TextField
              autoComplete={autocompleteAttribute}
              disabled={disabled}
              error={error}
              id={id}
              onFocus={handleFocus}
              onBlur={handleBlur}
              {...forwardProps}
              ref={inputRef}
              rows={forwardProps.rows ? forwardProps.rows : forwardProps.multiline ? 4.3334 : undefined}
            />
          )
        }
        {suffix && <div className={cssSuffix}>{suffix}</div>}
      </div>
      <div
        className={styles['error--message--container']}
      >
        {error && errorMessage
          ? <FormHelperText className={styles['error-message']}>{errorMessage}</FormHelperText>
          : null}
      </div>
    </Stack>
  );
};

export default TextInput;
