import React from 'react';

// COMPONENTS
import DataTable from 'components/DataTable/DataTable';
import { Box, Button, Dropdown, ExpandableSection, Icon } from 'components';
import { ShareButton } from 'blocks/components';
import ChartBillingDetails from '../ChartBillingDetails/ChartBillingDetails';
import BillingDetailsInfo from '../BillingDetailsInfo/BillingDetailsInfo';
import BillingApprovalDrawer from '../BillingApprovalDrawer/BillingApprovalDrawer';

// ASSETS
import styles from './BillingSingleView.module.scss';

// HELPERS
import { useDispatch } from 'react-redux';
import { setApproveBillingOpen } from 'store/modules/billing/billing.slice';
import { columnsFuelEnergy, columnsMember, columnsNonFuel, columnsProduction, columnsTransmission, dataFuelEnergy, dataMember, dataNonFuel, dataProduction, dataTransmission } from './BillingSingleView.helpers';

// TYPINGS
import { IBilling } from 'api/billing/billing.typings';

interface BillingSingleViewProps {
  bill: IBilling
}

const BillingSingleView:React.FC<BillingSingleViewProps> = ({ bill }) => {
  const dispatch = useDispatch();

  // EVENTS
  const handleApproval = () => {
    dispatch(setApproveBillingOpen(true));
  };

  // RENDERS
  const actionList = [
    {
      startIcon: <Icon.Upload size={18}/>,
      content: 'Update',
      onClick: () => {}
    },
    {
      startIcon: <Icon.Users size={18} />,
      content: 'Assign',
      onClick: () => {}
    },
    {
      startIcon: <Icon.Download size={18} />,
      content: 'Download',
      onClick: () => {}
    }
  ];

  return (
    <>
      <div className={styles.header}>
        <div className={styles.title}>
          <p>Bill ID: {bill.billId}</p>
        </div>
        <div className={styles.actions}>
          <Dropdown label="Actions" options={actionList} color='depth' />
          <ShareButton/>
          {(bill.status === 'Pre-Billing' || bill.status === 'Pending approval') && (
            <Button size="md" copy='Submit for Approval' type="primary" icon={<Icon.CheckCircle />} iconPosition="left" onClick={handleApproval} />
          )}
          {(bill.status === 'Approved' || bill.status === 'Pending Approval') && (
            <Button size="md" copy='Upload to Sharepoint' type="primary" icon={<Icon.Upload />} iconPosition="left" />
          )}
        </div>
      </div>
      <BillingDetailsInfo bill={bill}/>
      <Box mb={10}>
        <ExpandableSection title='Bill details' expanded>
          <div className={styles.overview}>
            <div className={styles['overview--header']}>
              <span className={styles['overview--title']}>January</span>
              <div className={styles['overview--total']}>
                <span>Total Amount</span>
                <span>$4,284,254.04</span>
              </div>
            </div>
            <ChartBillingDetails data={bill.details} />
            <DataTable columns={columnsProduction as any} data={dataProduction} />
            <DataTable columns={columnsTransmission as any} data={dataTransmission} />
            <DataTable columns={columnsNonFuel as any} data={dataNonFuel} />
            <DataTable columns={columnsFuelEnergy as any} data={dataFuelEnergy} />
            <DataTable columns={columnsMember as any} data={dataMember} />
          </div>
        </ExpandableSection>
      </Box>
      <BillingApprovalDrawer bill={bill} />
    </>
  );
};

export default BillingSingleView;
