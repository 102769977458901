import React from 'react';
import { useParams } from 'react-router-dom';

// COMPONENTS
import { Breadcrumb, Tag } from 'components';

// ASSETS
import styles from './Project.module.scss';

// HELPERS
import { useSingleSystem } from 'api/hooks';
import { useTheme } from 'hooks/Theme/Theme.context';
import { AllocateesTable, DataUpdateDrawer, MoreActions, ProjectDetails, UpdateSystemDrawer } from './components';

const Project = () => {
  const { id } = useParams();
  const { slug } = useTheme();
  const { data: projectData, isLoading } = useSingleSystem(id || '');

  return (
    <div className={`${styles['project-page']} admin--page pad--t-128 pad--l-128 pad--r-36`} role="main">
      {
        (!isLoading && projectData) && (
          <>
            <div className={styles['project-header']}>
              <Breadcrumb
                withRouter={true}
                items={[
                  {
                    title: 'Systems',
                    bold: true,
                    arrow: true,
                    href: `/${slug}/data/`
                  }
                ]}
              />
              <MoreActions/>
            </div>
            <div className={styles['project-title']}>
              <span>Project ID</span>
              <p>{projectData.projectID}</p>
            </div>
            <div className={styles['project-info']}>
              <div>
                <p className={styles['info-title']}>Status</p>
                <Tag copy={projectData.status} color='stem' size="sm" />
              </div>
              <div>
                <p className={styles['info-title']}>Allocation</p>
                <p className={styles['info-description']}>{projectData.allocation}</p>
              </div>
            </div>
            <ProjectDetails project={projectData}/>
            <AllocateesTable allocatees={projectData.allocatees}/>
            {/* Drawers below */}
            <UpdateSystemDrawer/>
            <DataUpdateDrawer project={projectData}/>
          </>
        )
      }
    </div>
  );
};

export default Project;
