import React, { Fragment } from 'react';

import Header from '../../components/Header/Header';
import Link from '../../components/Link/Link';
import Text from '../../components/Text/Text';

const TypographyPage: React.FC = () => {
  return (
    <Fragment>
      <Header
        className='design-studio pad--b-100 pad--l-128 pad--t-100'
        color='stone'
        text='Typography'
      >
        <Text
          className='max-width--900'
          color='white'
          variant='body1'
        >
          Use typography to present your design and content as clearly and efficiently as possible.
        </Text>
        <Link
          className='underline dis--block mar--t-60 body--xl'
          color='white'
          copy='See Our Guidelines'
          href='/'
        />
      </Header>
      <div
        className='mar--t-80 pad--b-80 pad--l-128 max-width--lg temp--min-width--md'
      >
        <div className='bor--b-1--stone'>
          <div className='mar--b-80'>
            <Text
              className='body--lead'
              color='stone'
              variant='body1'
            >
              Typefaces
            </Text>
            <Text
              className='body--lg'
              color='stone'
              variant='body1'
            >
              Use typography to present your design and content as clearly and efficiently as possible.
            </Text>
            <Link
              className='underline dis--block mar--t-60 body--xl'
              color='stone'
              copy='https://degarism.com/Aktifo'
              href='https://degarism.com/Aktifo'
              icon={false}
            />
          </div>
          <div className='bor--b-1--stone pad--b-80 row'>
            <div className='column'>
              <Text className='headline--md'>Aktifo-A</Text>
              <div className='row'>
                <div className='column'>
                  <Text className='display-heading--xxl'>Aa</Text>
                </div>
                <div className='column'>
                  <Text className='headline--md'>Book</Text>
                  <Text className='headline--md'>Medium</Text>
                  <Text className='headline--md'>Semibold</Text>
                </div>
              </div>
              <Text className='body--xl'>Used exclusively with the exception of numbers (e.g. power stats) + all caps mini-headers</Text>
            </div>
            <div className='column'>
              <Text className='headline--md'>Aktifo-B</Text>
              <div className='row'>
                <div className='column'>
                  <Text className='display-heading--xxl'>Aa</Text>
                </div>
                <div className='column'>
                  <Text className='headline--md'>Semibold</Text>
                  <Text className='headline--md'>Bold</Text>
                </div>
                <Text className='body--xl'>Used exclusively with the exception of numbers (e.g. power stats) + all caps mini-headers</Text>
              </div>
            </div>
          </div>
        </div>

        <div className='bor--b-1--stone pad--b-80 pad--t-80'>
          <Text className='headline--md pad--b-80'>Display</Text>
          <Text className='pad--b-60' color='magma'>Display Extra Large Outlined</Text>
          <Text
            className='display-heading--xl pad--b-80'
            variant='h2'
          >
            Display Heading
          </Text>

          <Text className='pad--b-60' color='magma'>Display Large</Text>
          <Text
            className='pad--b-80 display-heading--lg'
            variant='h2'
          >
            The five boxing wizards jump quickly.
          </Text>

          <Text className='pad--b-60' color='magma'>Display Medium</Text>
          <Text
            className='pad--b-80 display-heading--md'
            variant='h3'
          >
            Pack my box with five dozen liquor jugs.
          </Text>
          <Text className='pad--b-60' color='magma'>Display Small</Text>
          <Text
            className='pad--b-80  display-heading--sm'
            variant='h3'
          >
            Go, lazy fat vixen; be shrewd, jump quick.
          </Text>
        </div>

        <div className='bor--b-1--stone pad--b-80 pad--t-80'>
          <Text className='pad--b-80 headline--md'>Headline</Text>
          <Text className='pad--b-60' color='magma'>
            Headline Large
          </Text>
          <Text
            className='pad--b-80 headline--lg'
            variant='body1'
          >
            When zombies arrive, quickly fax Judge Pat.
          </Text>

          <Text className='pad--b-60' color='magma'>
            Headline Medium
          </Text>
          <Text
            className='pad--b-80 headline--md'
            variant='body1'
          >
            Amazingly few discotheques provide jukeboxes.
          </Text>

          <Text className='pad--b-60' color='magma'>
            Headline Small
          </Text>
          <Text
            className='pad--b-80 headline--sm'
            variant='body1'
          >
            The quick onyx goblin jumps over the lazy dwarf.
          </Text>
        </div>
        <div className='bor--b-1--stone pad--b-80 pad--t-80'>
          <Text className='pad--b-80 headline--md'>Body</Text>
          <Text className='pad--b-60' color='magma'>Body Lead</Text>
          <Text
            className='pad--b-80 body--lead'
            variant='body1'
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Text>
        </div>
        <div className='bor--b-1--stone pad--b-80 pad--t-80'>
          <Text className='pad--b-60' color='magma'>Body Extra Large</Text>
          <Text
            className='pad--b-80 body--xl'
            variant='body1'
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Text>
        </div>

        <div className='bor--b-1--stone pad--b-80 pad--t-80'>
          <Text className='pad--b-60' color='magma'>Body Medium</Text>
          <Text
            className='pad--b-80 body--md'
            variant='body1'
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Text>
        </div>
        <div className='bor--b-1--stone pad--b-80 pad--t-80'>
          <Text className='pad--b-60' color='magma'>Body Small</Text>
          <Text
            className='pad--b-80 body--sm'
            variant='body1'
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Text>
        </div>
        <div className='bor--b-1--stone pad--b-80 pad--t-80'>
          <Text className='pad--b-60' color='magma'>Body Extra Small</Text>
          <Text
            className='pad--b-80 body--xs'
            variant='body1'
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Text>
        </div>
      </div>
    </Fragment>
  );
};

export default TypographyPage;
