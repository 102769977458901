import * as React from 'react';

import colors from '../../../style/colors';

const Citadel = (props: any) => {
  const { color, showBrandName, showGridX } = props;

  let renderColor;
  switch (color) {
  case 'deep':
    renderColor = colors.deep;
    break;
  case 'energy':
    renderColor = colors.energy;
    break;
  case 'white':
    renderColor = colors.white;
    break;
  }

  if (color === 'deep' || color === 'energy' || color === 'white') {
    if (showGridX) {
      return (
        <svg
          width={424}
          height={134}
          viewBox="0 0 424 134"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#clip0_11025_6825)">
            <path
              d="M29.4784 81.7358L52.107 104.321L29.4784 126.906L6.84981 104.321L29.4784 81.7358ZM29.4784 74.9082C28.0262 74.9082 26.5523 75.4718 25.4469 76.5772L1.6695 100.29C-0.563012 102.5 -0.563012 106.098 1.6695 108.331L25.4469 132.043C26.5523 133.149 28.0262 133.712 29.4784 133.712C30.9306 133.712 32.4045 133.149 33.5099 132.043L57.2873 108.331C59.5198 106.12 59.5198 102.5 57.2873 100.29L33.5099 76.5772C32.4045 75.4718 30.9306 74.9082 29.4784 74.9082Z"
              fill={renderColor}
            />
            <path
              d="M29.4784 6.8276L52.107 29.4128L29.4784 51.9981L6.84981 29.4128L29.4784 6.8276ZM29.4784 0C28.0045 0 26.5523 0.563548 25.4469 1.66897L1.6695 25.3813C-0.563012 27.5922 -0.563012 31.2119 1.6695 33.4227L25.4469 57.1351C26.5523 58.2405 28.0262 58.804 29.4784 58.804C30.9306 58.804 32.4045 58.2405 33.5099 57.1351L57.2873 33.4227C59.5198 31.2119 59.5198 27.5922 57.2873 25.3813L33.5099 1.66897C32.3828 0.563548 30.9306 0 29.4784 0Z"
              fill={renderColor}
            />
            <path
              d="M132.326 25.3812L108.549 1.66887C106.316 -0.541968 102.718 -0.541968 100.485 1.66887L76.7081 25.3812C74.4756 27.5921 74.4756 31.2118 76.7081 33.4226L100.485 57.135C102.718 59.3458 106.316 59.3458 108.549 57.135L132.326 33.4226C134.558 31.2118 134.558 27.6137 132.326 25.3812Z"
              fill={renderColor}
            />
            <path
              d="M132.326 100.289L108.549 76.5771C106.316 74.3662 102.718 74.3662 100.485 76.5771L76.7081 100.289C74.4756 102.5 74.4756 106.098 76.7081 108.331L100.485 132.043C102.718 134.254 106.316 134.254 108.549 132.043L132.326 108.331C134.558 106.12 134.558 102.522 132.326 100.289Z"
              fill={renderColor}
            />
          </g>
          <path
            d="M165.456 87.8806C165.456 71.8246 175.968 61.0966 191.592 61.0966C204.408 61.0966 214.056 68.2966 216.144 79.2406H205.992C204.192 73.3366 198.648 69.6646 191.592 69.6646C181.656 69.6646 175.464 77.0086 175.464 87.8806C175.464 99.1846 181.944 106.097 191.52 106.097C198.72 106.097 204.264 102.209 205.992 96.5206H216.144C214.056 107.537 204.408 114.737 191.592 114.737C175.968 114.737 165.456 103.937 165.456 87.8806Z"
            fill={renderColor}
          />
          <path
            d="M229.09 72.0406C225.058 72.0406 222.826 69.5926 222.826 66.4966C222.826 63.5446 225.058 61.1686 229.09 61.1686C233.05 61.1686 235.282 63.5446 235.282 66.4966C235.282 69.5926 233.05 72.0406 229.09 72.0406ZM224.554 114.017V76.3606H233.626V114.017H224.554Z"
            fill={renderColor}
          />
          <path
            d="M250.161 114.017V83.8486H239.577V76.3606H250.161V66.7126L259.233 66.2086V76.3606H269.889V83.8486H259.233V114.017H250.161Z"
            fill={renderColor}
          />
          <path
            d="M274.064 103.721C274.064 97.1686 278.384 93.1366 286.088 92.2726L299.192 90.8326V89.1766C299.192 84.8566 295.88 82.8406 291.2 82.8406C286.088 82.8406 283.28 85.3606 283.424 89.4646H275.072C274.856 81.0406 281.12 75.6406 291.992 75.6406C302 75.6406 308.264 80.2486 308.264 89.4646V114.017H300.344L299.84 107.321C297.896 111.929 292.568 114.737 286.376 114.737C278.96 114.737 274.064 110.417 274.064 103.721ZM283.064 102.497C283.064 105.665 285.152 107.681 289.184 107.681C294.8 107.681 299.192 103.937 299.192 98.3926V95.9446L288.608 97.3126C284.648 97.8166 283.064 99.7606 283.064 102.497Z"
            fill={renderColor}
          />
          <path
            d="M348.142 60.0166H357.214V114.017H349.222L348.502 108.401C345.91 112.289 340.582 114.737 334.246 114.737C322.87 114.737 315.526 106.817 315.526 95.2966C315.526 83.4166 323.446 75.6406 334.966 75.6406C340.438 75.6406 345.262 77.5126 348.142 80.8246V60.0166ZM336.334 107.105C343.246 107.105 348.142 102.857 348.142 95.7286V94.8646C348.142 87.8086 343.39 83.2726 336.406 83.2726C329.206 83.2726 324.742 88.0246 324.742 95.1526C324.742 102.569 329.422 107.105 336.334 107.105Z"
            fill={renderColor}
          />
          <path
            d="M393.973 102.497H402.973C401.029 110.201 393.829 114.737 384.613 114.737C372.445 114.737 364.525 106.889 364.525 95.1526C364.525 83.4886 372.445 75.6406 384.109 75.6406C395.053 75.6406 402.541 82.6246 402.541 92.9206C402.541 94.2166 402.397 95.0806 402.181 96.3046H373.669V97.0246C373.669 103.721 378.061 107.465 384.541 107.465C389.149 107.465 392.605 105.521 393.973 102.497ZM383.893 82.8406C378.709 82.8406 374.749 85.8646 373.885 90.5446H393.253C393.181 86.0086 389.293 82.8406 383.893 82.8406Z"
            fill={renderColor}
          />
          <path d="M410.768 114.017V60.0166H419.84V114.017H410.768Z" fill={renderColor} />
          <g clipPath="url(#clip1_11025_6825)">
            <path
              d="M208.414 9.94043H203.654V29.35H208.414V9.94043Z"
              fill={renderColor}
            />
            <path
              d="M208.414 3.42676H203.654V7.55292H208.414V3.42676Z"
              fill={renderColor}
            />
            <path
              d="M233.159 1.5166V29.35H228.957L228.578 26.4555C227.219 28.4614 224.424 29.7216 221.1 29.7216C215.131 29.7216 211.278 25.6396 211.278 19.7024C211.278 13.5781 215.432 9.56886 221.478 9.56886C224.347 9.56886 226.878 10.5328 228.391 12.2399V1.5166H233.159ZM228.399 19.9258V19.4789C228.399 15.8412 225.905 13.5027 222.239 13.5027C218.463 13.5027 216.123 15.9504 216.123 19.6244C216.123 23.4466 218.577 25.7851 222.205 25.7851C225.833 25.7851 228.399 23.5999 228.399 19.9258Z"
              fill={renderColor}
            />
            <path
              d="M241.637 19.1486L234.465 9.94008H240.376L244.94 15.95C245.02 16.0539 245.124 16.1382 245.243 16.1962C245.362 16.2542 245.493 16.2844 245.625 16.2844C245.758 16.2844 245.889 16.2542 246.008 16.1962C246.126 16.1382 246.23 16.0539 246.31 15.95L251.028 9.92969H256.772L249.486 19.133C249.368 19.2813 249.304 19.4642 249.304 19.6527C249.304 19.8411 249.368 20.0241 249.486 20.1723L256.809 29.3367H250.991L246.26 23.4151C246.18 23.3131 246.077 23.2306 245.959 23.1738C245.841 23.117 245.712 23.0875 245.58 23.0875C245.449 23.0875 245.32 23.117 245.202 23.1738C245.084 23.2306 244.981 23.3131 244.901 23.4151L240.249 29.3419H234.465L241.637 20.1697C241.751 20.0231 241.813 19.8437 241.813 19.6592C241.813 19.4747 241.751 19.2953 241.637 19.1486V19.1486Z"
              fill={renderColor}
            />
            <path
              d="M198.883 9.94042L202.059 14.1545H195.696C195.212 14.1545 194.748 14.3434 194.406 14.6796C194.064 15.0158 193.872 15.6594 193.872 16.1349V29.35H189.204V15.8387C189.204 11.5384 191.661 9.93262 194.731 9.93262L198.883 9.94042Z"
              fill={renderColor}
            />
            <path
              d="M181.395 9.94026L181.093 12.6503C179.771 10.7951 177.127 9.57129 173.916 9.57129C168.098 9.57129 164.285 13.5779 164.285 19.1851C164.285 24.7508 168.101 28.76 173.916 28.76C176.939 28.76 179.62 27.5726 180.829 25.7174V28.6847C180.829 31.8027 178.488 33.7333 174.974 33.7333C173.022 33.7333 171.488 33.0915 170.441 32.1743C170.275 32.0261 169.939 31.7455 169.939 31.7455L166.895 34.7856C167.158 35.0508 167.441 35.2966 167.741 35.5209C169.449 36.7967 171.859 37.5216 174.9 37.5216C181.395 37.5216 185.589 34.1074 185.589 28.5028V9.94026H181.395ZM180.829 19.3307C180.829 22.7449 178.224 24.9353 174.86 24.9353C171.234 24.9353 169.119 22.5214 169.119 19.1462C169.119 15.6566 171.385 13.3934 174.9 13.3934C178.261 13.3934 180.829 15.4721 180.829 18.8863V19.3307Z"
              fill={renderColor}
            />
          </g>
          <defs>
            <clipPath id="clip0_11025_6825">
              <rect width={134} height={134} fill="white" />
            </clipPath>
            <clipPath id="clip1_11025_6825">
              <rect
                width={99}
                height={39}
                fill="white"
                transform="translate(162 0.0166016)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    } else if (showBrandName) {
      return (
        <svg
          width={417}
          height={134}
          viewBox="0 0 417 134"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#clip0_11025_9487)">
            <path
              d="M29.4784 81.7363L52.107 104.322L29.4784 126.907L6.84981 104.322L29.4784 81.7363ZM29.4784 74.9087C28.0262 74.9087 26.5523 75.4722 25.4469 76.5777L1.6695 100.29C-0.563012 102.501 -0.563012 106.099 1.6695 108.331L25.4469 132.044C26.5523 133.149 28.0262 133.713 29.4784 133.713C30.9306 133.713 32.4045 133.149 33.5099 132.044L57.2873 108.331C59.5198 106.121 59.5198 102.501 57.2873 100.29L33.5099 76.5777C32.4045 75.4722 30.9306 74.9087 29.4784 74.9087Z"
              fill={renderColor}
            />
            <path
              d="M29.4784 6.8276L52.107 29.4128L29.4784 51.9981L6.84981 29.4128L29.4784 6.8276ZM29.4784 0C28.0045 0 26.5523 0.563548 25.4469 1.66897L1.6695 25.3813C-0.563012 27.5922 -0.563012 31.2119 1.6695 33.4227L25.4469 57.1351C26.5523 58.2405 28.0262 58.804 29.4784 58.804C30.9306 58.804 32.4045 58.2405 33.5099 57.1351L57.2873 33.4227C59.5198 31.2119 59.5198 27.5922 57.2873 25.3813L33.5099 1.66897C32.3828 0.563548 30.9306 0 29.4784 0Z"
              fill={renderColor}
            />
            <path
              d="M132.326 25.3812L108.549 1.66887C106.316 -0.541968 102.718 -0.541968 100.485 1.66887L76.7081 25.3812C74.4756 27.5921 74.4756 31.2118 76.7081 33.4226L100.485 57.135C102.718 59.3458 106.316 59.3458 108.549 57.135L132.326 33.4226C134.558 31.2118 134.558 27.6137 132.326 25.3812Z"
              fill={renderColor}
            />
            <path
              d="M132.326 100.289L108.549 76.5771C106.316 74.3662 102.718 74.3662 100.485 76.5771L76.7081 100.289C74.4756 102.5 74.4756 106.098 76.7081 108.331L100.485 132.043C102.718 134.254 106.316 134.254 108.549 132.043L132.326 108.331C134.558 106.12 134.558 102.522 132.326 100.289Z"
              fill={renderColor}
            />
          </g>
          <path
            d="M162 67.5041C162 51.4481 172.512 40.7201 188.136 40.7201C200.952 40.7201 210.6 47.9201 212.688 58.8641H202.536C200.736 52.9601 195.192 49.2881 188.136 49.2881C178.2 49.2881 172.008 56.6321 172.008 67.5041C172.008 78.8081 178.488 85.7201 188.064 85.7201C195.264 85.7201 200.808 81.8321 202.536 76.1441H212.688C210.6 87.1601 200.952 94.3601 188.136 94.3601C172.512 94.3601 162 83.5601 162 67.5041Z"
            fill={renderColor}
          />
          <path
            d="M225.634 51.6641C221.602 51.6641 219.37 49.2161 219.37 46.1201C219.37 43.1681 221.602 40.7921 225.634 40.7921C229.594 40.7921 231.826 43.1681 231.826 46.1201C231.826 49.2161 229.594 51.6641 225.634 51.6641ZM221.098 93.6401V55.9841H230.17V93.6401H221.098Z"
            fill={renderColor}
          />
          <path
            d="M246.705 93.6401V63.4721H236.121V55.9841H246.705V46.3361L255.777 45.8321V55.9841H266.433V63.4721H255.777V93.6401H246.705Z"
            fill={renderColor}
          />
          <path
            d="M270.607 83.3441C270.607 76.7921 274.928 72.7601 282.632 71.8961L295.736 70.4561V68.8001C295.736 64.4801 292.423 62.4641 287.743 62.4641C282.631 62.4641 279.823 64.9841 279.967 69.0881H271.616C271.4 60.6641 277.663 55.2641 288.535 55.2641C298.543 55.2641 304.807 59.8721 304.807 69.0881V93.6401H296.887L296.383 86.9441C294.439 91.5521 289.112 94.3601 282.92 94.3601C275.504 94.3601 270.607 90.0401 270.607 83.3441ZM279.607 82.1201C279.607 85.2881 281.696 87.3041 285.728 87.3041C291.344 87.3041 295.736 83.5601 295.736 78.0161V75.5681L285.151 76.9361C281.191 77.4401 279.607 79.3841 279.607 82.1201Z"
            fill={renderColor}
          />
          <path
            d="M344.686 39.6401H353.758V93.6401H345.766L345.046 88.0241C342.454 91.9121 337.126 94.3601 330.79 94.3601C319.414 94.3601 312.07 86.4401 312.07 74.9201C312.07 63.0401 319.99 55.2641 331.51 55.2641C336.982 55.2641 341.806 57.1361 344.686 60.4481V39.6401ZM332.878 86.7281C339.79 86.7281 344.686 82.4801 344.686 75.3521V74.4881C344.686 67.4321 339.934 62.8961 332.95 62.8961C325.75 62.8961 321.286 67.6481 321.286 74.7761C321.286 82.1921 325.966 86.7281 332.878 86.7281Z"
            fill={renderColor}
          />
          <path
            d="M390.517 82.1201H399.517C397.573 89.8241 390.373 94.3601 381.157 94.3601C368.989 94.3601 361.069 86.5121 361.069 74.7761C361.069 63.1121 368.989 55.2641 380.653 55.2641C391.597 55.2641 399.085 62.2481 399.085 72.5441C399.085 73.8401 398.941 74.7041 398.725 75.9281H370.213V76.6481C370.213 83.3441 374.605 87.0881 381.085 87.0881C385.693 87.0881 389.149 85.1441 390.517 82.1201ZM380.437 62.4641C375.253 62.4641 371.293 65.4881 370.429 70.1681H389.797C389.725 65.6321 385.837 62.4641 380.437 62.4641Z"
            fill={renderColor}
          />
          <path
            d="M407.312 93.6401V39.6401H416.384V93.6401H407.312Z"
            fill={renderColor}
          />
          <defs>
            <clipPath id="clip0_11025_9487">
              <rect width={134} height={134} fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    } else {
      return (
        <svg
          width="1em"
          height="1em"
          fill="none"
          viewBox="0 0 64 64"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#clip0_9611_8432)">
            <path
              d="M14.0791 39.0383L24.8868 49.8253L14.0791 60.6123L3.27144 49.8253L14.0791 39.0383ZM14.0791 35.7773C13.3855 35.7773 12.6816 36.0465 12.1536 36.5745L0.797266 47.8998C-0.26901 48.9557 -0.26901 50.6742 0.797266 51.7404L12.1536 63.0657C12.6816 63.5937 13.3855 63.8628 14.0791 63.8628C14.7727 63.8628 15.4767 63.5937 16.0046 63.0657L27.361 51.7404C28.4273 50.6845 28.4273 48.9557 27.361 47.8998L16.0046 36.5745C15.4767 36.0465 14.7727 35.7773 14.0791 35.7773Z"
              fill={renderColor}
            />
            <path
              d="M14.0791 3.26094L24.8868 14.0479L14.0791 24.8349L3.27144 14.0479L14.0791 3.26094ZM14.0791 0C13.3752 0 12.6816 0.269157 12.1536 0.797119L0.797266 12.1224C-0.26901 13.1783 -0.26901 14.9072 0.797266 15.9631L12.1536 27.2884C12.6816 27.8163 13.3855 28.0855 14.0791 28.0855C14.7727 28.0855 15.4767 27.8163 16.0046 27.2884L27.361 15.9631C28.4273 14.9072 28.4273 13.1783 27.361 12.1224L16.0046 0.797119C15.4663 0.269157 14.7727 0 14.0791 0Z"
              fill={renderColor}
            />
            <path
              d="M63.2003 12.1224L51.844 0.79707C50.7777 -0.258854 49.0593 -0.258854 47.993 0.79707L36.6366 12.1224C35.5703 13.1783 35.5703 14.9071 36.6366 15.963L47.993 27.2883C49.0593 28.3443 50.7777 28.3443 51.844 27.2883L63.2003 15.963C64.2666 14.9071 64.2666 13.1886 63.2003 12.1224Z"
              fill={renderColor}
            />
            <path
              d="M63.2003 47.8995L51.844 36.5742C50.7777 35.5182 49.0593 35.5182 47.993 36.5742L36.6366 47.8995C35.5703 48.9554 35.5703 50.6739 36.6366 51.7401L47.993 63.0654C49.0593 64.1214 50.7777 64.1214 51.844 63.0654L63.2003 51.7401C64.2666 50.6842 64.2666 48.9657 63.2003 47.8995Z"
              fill={renderColor}
            />
          </g>
          <defs>
            <clipPath id="clip0_9611_8432">
              <rect width={64} height={64} fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    }
  }

  if (showGridX) {
    return (
      <svg
        width={424}
        height={134}
        viewBox="0 0 424 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M57.2771 100.504L33.5022 76.7327C31.277 74.5078 27.6691 74.5078 25.4438 76.7327L1.66895 100.504C-0.556315 102.729 -0.556317 106.336 1.66895 108.561L25.4438 132.332C27.6691 134.557 31.277 134.557 33.5022 132.332L57.2771 108.561C59.5024 106.336 59.5024 102.729 57.2771 100.504Z"
          fill="#344145"
        />
        <path
          d="M57.2771 25.4398L33.5022 1.66869C31.277 -0.556227 27.6691 -0.556229 25.4438 1.66868L1.66895 25.4398C-0.556315 27.6647 -0.556317 31.272 1.66895 33.4969L25.4438 57.268C27.6691 59.4929 31.277 59.4929 33.5022 57.268L57.2771 33.4969C59.5024 31.272 59.5024 27.6647 57.2771 25.4398Z"
          fill="#D4E9EC"
        />
        <path
          d="M132.328 25.4398L108.553 1.66869C106.328 -0.556227 102.72 -0.556229 100.495 1.66868L76.7201 25.4398C74.4948 27.6647 74.4948 31.272 76.7201 33.4969L100.495 57.268C102.72 59.4929 106.328 59.4929 108.553 57.268L132.328 33.4969C134.554 31.272 134.554 27.6647 132.328 25.4398Z"
          fill="#FFA44F"
        />
        <path
          d="M132.331 100.504L108.556 76.7327C106.331 74.5078 102.723 74.5078 100.498 76.7327L76.7229 100.504C74.4976 102.729 74.4976 106.336 76.7229 108.561L100.498 132.332C102.723 134.557 106.331 134.557 108.556 132.332L132.331 108.561C134.556 106.336 134.556 102.729 132.331 100.504Z"
          fill="#FFA44F"
        />
        <path
          d="M165.456 87.8806C165.456 71.8246 175.968 61.0966 191.592 61.0966C204.408 61.0966 214.056 68.2966 216.144 79.2406H205.992C204.192 73.3366 198.648 69.6646 191.592 69.6646C181.656 69.6646 175.464 77.0086 175.464 87.8806C175.464 99.1846 181.944 106.097 191.52 106.097C198.72 106.097 204.264 102.209 205.992 96.5206H216.144C214.056 107.537 204.408 114.737 191.592 114.737C175.968 114.737 165.456 103.937 165.456 87.8806Z"
          fill="#344145"
        />
        <path
          d="M229.09 72.0406C225.058 72.0406 222.826 69.5926 222.826 66.4966C222.826 63.5446 225.058 61.1686 229.09 61.1686C233.05 61.1686 235.282 63.5446 235.282 66.4966C235.282 69.5926 233.05 72.0406 229.09 72.0406ZM224.554 114.017V76.3606H233.626V114.017H224.554Z"
          fill="#344145"
        />
        <path
          d="M250.161 114.017V83.8486H239.577V76.3606H250.161V66.7126L259.233 66.2086V76.3606H269.889V83.8486H259.233V114.017H250.161Z"
          fill="#344145"
        />
        <path
          d="M274.064 103.721C274.064 97.1686 278.384 93.1366 286.088 92.2726L299.192 90.8326V89.1766C299.192 84.8566 295.88 82.8406 291.2 82.8406C286.088 82.8406 283.28 85.3606 283.424 89.4646H275.072C274.856 81.0406 281.12 75.6406 291.992 75.6406C302 75.6406 308.264 80.2486 308.264 89.4646V114.017H300.344L299.84 107.321C297.896 111.929 292.568 114.737 286.376 114.737C278.96 114.737 274.064 110.417 274.064 103.721ZM283.064 102.497C283.064 105.665 285.152 107.681 289.184 107.681C294.8 107.681 299.192 103.937 299.192 98.3926V95.9446L288.608 97.3126C284.648 97.8166 283.064 99.7606 283.064 102.497Z"
          fill="#344145"
        />
        <path
          d="M348.142 60.0166H357.214V114.017H349.222L348.502 108.401C345.91 112.289 340.582 114.737 334.246 114.737C322.87 114.737 315.526 106.817 315.526 95.2966C315.526 83.4166 323.446 75.6406 334.966 75.6406C340.438 75.6406 345.262 77.5126 348.142 80.8246V60.0166ZM336.334 107.105C343.246 107.105 348.142 102.857 348.142 95.7286V94.8646C348.142 87.8086 343.39 83.2726 336.406 83.2726C329.206 83.2726 324.742 88.0246 324.742 95.1526C324.742 102.569 329.422 107.105 336.334 107.105Z"
          fill="#344145"
        />
        <path
          d="M393.973 102.497H402.973C401.029 110.201 393.829 114.737 384.613 114.737C372.445 114.737 364.525 106.889 364.525 95.1526C364.525 83.4886 372.445 75.6406 384.109 75.6406C395.053 75.6406 402.541 82.6246 402.541 92.9206C402.541 94.2166 402.397 95.0806 402.181 96.3046H373.669V97.0246C373.669 103.721 378.061 107.465 384.541 107.465C389.149 107.465 392.605 105.521 393.973 102.497ZM383.893 82.8406C378.709 82.8406 374.749 85.8646 373.885 90.5446H393.253C393.181 86.0086 389.293 82.8406 383.893 82.8406Z"
          fill="#344145"
        />
        <path d="M410.768 114.017V60.0166H419.84V114.017H410.768Z" fill="#344145" />
        <g clipPath="url(#clip0_11025_6811)">
          <path
            d="M208.414 9.94043H203.654V29.35H208.414V9.94043Z"
            fill="#344145"
          />
          <path
            d="M208.414 3.42676H203.654V7.55292H208.414V3.42676Z"
            fill="#344145"
          />
          <path
            d="M233.159 1.5166V29.35H228.957L228.578 26.4555C227.219 28.4614 224.424 29.7216 221.1 29.7216C215.131 29.7216 211.278 25.6396 211.278 19.7024C211.278 13.5781 215.432 9.56886 221.478 9.56886C224.347 9.56886 226.878 10.5328 228.391 12.2399V1.5166H233.159ZM228.399 19.9258V19.4789C228.399 15.8412 225.905 13.5027 222.239 13.5027C218.463 13.5027 216.123 15.9504 216.123 19.6244C216.123 23.4466 218.577 25.7851 222.205 25.7851C225.833 25.7851 228.399 23.5999 228.399 19.9258Z"
            fill="#344145"
          />
          <path
            d="M241.637 19.1486L234.465 9.94008H240.376L244.94 15.95C245.02 16.0539 245.124 16.1382 245.243 16.1962C245.362 16.2542 245.493 16.2844 245.625 16.2844C245.758 16.2844 245.889 16.2542 246.008 16.1962C246.126 16.1382 246.23 16.0539 246.31 15.95L251.028 9.92969H256.772L249.486 19.133C249.368 19.2813 249.304 19.4642 249.304 19.6527C249.304 19.8411 249.368 20.0241 249.486 20.1723L256.809 29.3367H250.991L246.26 23.4151C246.18 23.3131 246.077 23.2306 245.959 23.1738C245.841 23.117 245.712 23.0875 245.58 23.0875C245.449 23.0875 245.32 23.117 245.202 23.1738C245.084 23.2306 244.981 23.3131 244.901 23.4151L240.249 29.3419H234.465L241.637 20.1697C241.751 20.0231 241.813 19.8437 241.813 19.6592C241.813 19.4747 241.751 19.2953 241.637 19.1486V19.1486Z"
            fill="#344145"
          />
          <path
            d="M198.883 9.94042L202.059 14.1545H195.696C195.212 14.1545 194.748 14.3434 194.406 14.6796C194.064 15.0158 193.872 15.6594 193.872 16.1349V29.35H189.204V15.8387C189.204 11.5384 191.661 9.93262 194.731 9.93262L198.883 9.94042Z"
            fill="#344145"
          />
          <path
            d="M181.395 9.94026L181.093 12.6503C179.771 10.7951 177.127 9.57129 173.916 9.57129C168.098 9.57129 164.285 13.5779 164.285 19.1851C164.285 24.7508 168.101 28.76 173.916 28.76C176.939 28.76 179.62 27.5726 180.829 25.7174V28.6847C180.829 31.8027 178.488 33.7333 174.974 33.7333C173.022 33.7333 171.488 33.0915 170.441 32.1743C170.275 32.0261 169.939 31.7455 169.939 31.7455L166.895 34.7856C167.158 35.0508 167.441 35.2966 167.741 35.5209C169.449 36.7967 171.859 37.5216 174.9 37.5216C181.395 37.5216 185.589 34.1074 185.589 28.5028V9.94026H181.395ZM180.829 19.3307C180.829 22.7449 178.224 24.9353 174.86 24.9353C171.234 24.9353 169.119 22.5214 169.119 19.1462C169.119 15.6566 171.385 13.3934 174.9 13.3934C178.261 13.3934 180.829 15.4721 180.829 18.8863V19.3307Z"
            fill="#344145"
          />
        </g>
        <defs>
          <clipPath id="clip0_11025_6811">
            <rect
              width={99}
              height={39}
              fill="white"
              transform="translate(162 0.0166016)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (showBrandName) {
    return (
      <svg
        width={417}
        height={134}
        viewBox="0 0 417 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M57.2771 100.504L33.5022 76.7327C31.277 74.5078 27.6691 74.5078 25.4438 76.7327L1.66895 100.504C-0.556315 102.729 -0.556317 106.336 1.66895 108.561L25.4438 132.332C27.6691 134.557 31.277 134.557 33.5022 132.332L57.2771 108.561C59.5024 106.336 59.5024 102.729 57.2771 100.504Z"
          fill="#344145"
        />
        <path
          d="M57.2771 25.4398L33.5022 1.66869C31.277 -0.556227 27.6691 -0.556229 25.4438 1.66868L1.66895 25.4398C-0.556315 27.6647 -0.556317 31.272 1.66895 33.4969L25.4438 57.268C27.6691 59.4929 31.277 59.4929 33.5022 57.268L57.2771 33.4969C59.5024 31.272 59.5024 27.6647 57.2771 25.4398Z"
          fill="#D4E9EC"
        />
        <path
          d="M132.328 25.4398L108.553 1.66869C106.328 -0.556227 102.72 -0.556229 100.495 1.66868L76.7201 25.4398C74.4948 27.6647 74.4948 31.272 76.7201 33.4969L100.495 57.268C102.72 59.4929 106.328 59.4929 108.553 57.268L132.328 33.4969C134.554 31.272 134.554 27.6647 132.328 25.4398Z"
          fill="#FFA44F"
        />
        <path
          d="M132.331 100.504L108.556 76.7327C106.331 74.5078 102.723 74.5078 100.498 76.7327L76.7229 100.504C74.4976 102.729 74.4976 106.336 76.7229 108.561L100.498 132.332C102.723 134.557 106.331 134.557 108.556 132.332L132.331 108.561C134.556 106.336 134.556 102.729 132.331 100.504Z"
          fill="#FFA44F"
        />
        <path
          d="M162 67.5041C162 51.4481 172.512 40.7201 188.136 40.7201C200.952 40.7201 210.6 47.9201 212.688 58.8641H202.536C200.736 52.9601 195.192 49.2881 188.136 49.2881C178.2 49.2881 172.008 56.6321 172.008 67.5041C172.008 78.8081 178.488 85.7201 188.064 85.7201C195.264 85.7201 200.808 81.8321 202.536 76.1441H212.688C210.6 87.1601 200.952 94.3601 188.136 94.3601C172.512 94.3601 162 83.5601 162 67.5041Z"
          fill="#344145"
        />
        <path
          d="M225.634 51.6641C221.602 51.6641 219.37 49.2161 219.37 46.1201C219.37 43.1681 221.602 40.7921 225.634 40.7921C229.594 40.7921 231.826 43.1681 231.826 46.1201C231.826 49.2161 229.594 51.6641 225.634 51.6641ZM221.098 93.6401V55.9841H230.17V93.6401H221.098Z"
          fill="#344145"
        />
        <path
          d="M246.705 93.6401V63.4721H236.121V55.9841H246.705V46.3361L255.777 45.8321V55.9841H266.433V63.4721H255.777V93.6401H246.705Z"
          fill="#344145"
        />
        <path
          d="M270.607 83.3441C270.607 76.7921 274.928 72.7601 282.632 71.8961L295.736 70.4561V68.8001C295.736 64.4801 292.423 62.4641 287.743 62.4641C282.631 62.4641 279.823 64.9841 279.967 69.0881H271.616C271.4 60.6641 277.663 55.2641 288.535 55.2641C298.543 55.2641 304.807 59.8721 304.807 69.0881V93.6401H296.887L296.383 86.9441C294.439 91.5521 289.112 94.3601 282.92 94.3601C275.504 94.3601 270.607 90.0401 270.607 83.3441ZM279.607 82.1201C279.607 85.2881 281.696 87.3041 285.728 87.3041C291.344 87.3041 295.736 83.5601 295.736 78.0161V75.5681L285.151 76.9361C281.191 77.4401 279.607 79.3841 279.607 82.1201Z"
          fill="#344145"
        />
        <path
          d="M344.686 39.6401H353.758V93.6401H345.766L345.046 88.0241C342.454 91.9121 337.126 94.3601 330.79 94.3601C319.414 94.3601 312.07 86.4401 312.07 74.9201C312.07 63.0401 319.99 55.2641 331.51 55.2641C336.982 55.2641 341.806 57.1361 344.686 60.4481V39.6401ZM332.878 86.7281C339.79 86.7281 344.686 82.4801 344.686 75.3521V74.4881C344.686 67.4321 339.934 62.8961 332.95 62.8961C325.75 62.8961 321.286 67.6481 321.286 74.7761C321.286 82.1921 325.966 86.7281 332.878 86.7281Z"
          fill="#344145"
        />
        <path
          d="M390.517 82.1201H399.517C397.573 89.8241 390.373 94.3601 381.157 94.3601C368.989 94.3601 361.069 86.5121 361.069 74.7761C361.069 63.1121 368.989 55.2641 380.653 55.2641C391.597 55.2641 399.085 62.2481 399.085 72.5441C399.085 73.8401 398.941 74.7041 398.725 75.9281H370.213V76.6481C370.213 83.3441 374.605 87.0881 381.085 87.0881C385.693 87.0881 389.149 85.1441 390.517 82.1201ZM380.437 62.4641C375.253 62.4641 371.293 65.4881 370.429 70.1681H389.797C389.725 65.6321 385.837 62.4641 380.437 62.4641Z"
          fill="#344145"
        />
        <path
          d="M407.312 93.6401V39.6401H416.384V93.6401H407.312Z"
          fill="#344145"
        />
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 65 65"
        {...props}
      >
        <path
          fill={renderColor || '#344145'}
          d="M27.356 48.002 16.001 36.648a2.722 2.722 0 0 0-3.849 0L.797 48.002a2.72 2.72 0 0 0 0 3.848l11.355 11.353a2.722 2.722 0 0 0 3.85 0L27.355 51.85a2.72 2.72 0 0 0 0-3.848Z"
        />
        <path
          fill={renderColor || '#D4E9EC'}
          d="M27.356 12.15 16.001.797a2.722 2.722 0 0 0-3.849 0L.797 12.15a2.72 2.72 0 0 0 0 3.848l11.355 11.354a2.722 2.722 0 0 0 3.85 0l11.354-11.354a2.72 2.72 0 0 0 0-3.848Z"
        />
        <path
          fill={renderColor || '#FFA44F'}
          d="M63.202 12.15 51.846.797a2.722 2.722 0 0 0-3.848 0L36.642 12.15a2.72 2.72 0 0 0 0 3.848l11.356 11.354a2.722 2.722 0 0 0 3.848 0l11.356-11.354a2.72 2.72 0 0 0 0-3.848ZM63.203 48.002 51.848 36.648a2.722 2.722 0 0 0-3.85 0L36.645 48.002a2.72 2.72 0 0 0 0 3.848l11.355 11.353a2.722 2.722 0 0 0 3.849 0L63.203 51.85a2.72 2.72 0 0 0 0-3.848Z"
        />
      </svg>
    );
  }
};
export default Citadel;
