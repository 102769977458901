import React, { Fragment } from 'react';

import Header from '../../components/Header/Header';
import Link from '../../components/Link/Link';
import Text from '../../components/Text/Text';

const GridPage: React.FC = () => {
  return (
    <Fragment>
      <Header
        className='design-studio pad--b-100 pad--l-128 pad--t-100'
        color='stone'
        text='Grid'
      >
        <Text
          className='max-width--900'
          color='white'
        >
            The grid is based on a fluid 12-column grid. Across screen sizes, grid spacing and grid gutters remain fixed to the defined values. If there is a sidebar, it is fixed and excluded from the 12 columns in the grid.
        </Text>
        <Link
          className='underline dis--block mar--t-60 body--xl'
          color='white'
          copy='See Our Guidelines'
          href='/'
        />
      </Header>
      <div
        className='mar--t-80 pad--l-128'
      >
        <section
          className='pad--b-80 pad--r-80 max-width--lg'
        >
          <div className='media--lg'>
            <span className='heading-3'>Desktop - Grid (4px) + 12 Columns (auto)</span>
            <br />
                        32px Margin, 32px Gutter
          </div>
          <div className='media--md'>
            <span className='heading-3'>Tablet Landscape - Grid (4px) + 12 Columns (auto)</span>
            <br />
                        32px Margin, 32px Gutter
          </div>
          <div className='media--sm'>
            <span className='heading-3'>Tablet Portrait - Grid (4px) + 6 Columns (auto)</span>
            <br />
                        32px Margin, 32px Gutter
          </div>
          <div className='media--xs'>
            <span className='heading-3'>Mobile - Grid (4px) + 4 Columns (auto)</span>
            <br />
                        16px Margin, 16px Gutter
          </div>
          <div className='nav mar--t-20'>
            <span className='avatar' />
          </div>
          <div className='flex pad--t-400 pad--b-400 text--center width--100-p'>
            <div className='lg width--100-p'>
              <Text
                className='heading-1 text--center'
                color='stone'
              >
                Desktop 1440px
              </Text>
            </div>
            <div className='md width--100-p'>
              <Text
                className='heading-1'
                color='stone'
              >
                Tablet 1194px
              </Text>
            </div>
            <div className='media--sm width--100-p'>
              <Text
                className='heading-1'
                color='stone'
              >
                Tablet 834px
              </Text>
            </div>
            <div className='media--xs width--100-p'>
              <Text
                className='heading-1'
                color='stone'
              >
                Mobile 375px
              </Text>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default GridPage;
