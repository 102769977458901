export const getCustomerTypeColor = (customerType: string) => {
  switch (customerType) {
  case 'residential':
    return 'altitude';
  case 'comercial':
    return 'clean';
  case 'industrial':
    return 'wind';
  case 'agricultural':
    return 'cloud';
  default:
    return 'primary';
  }
};
