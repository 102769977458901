import React, { useState } from 'react';
import { Column } from '@tanstack/react-table';

// COMPONENTS
import Popover from '../../../Popover/Popover';

// ASSETS
import styles from './ColumnSearch.module.scss';
import { Button } from 'components';
import classnames from 'classnames';

interface ColumnSearchProps<TData, TValue> {
  column?: Column<TData, TValue>
  title?: string
}

const ColumnSearch = <TData, TValue>({ column }: ColumnSearchProps<TData, TValue>) => {
  const [active, setActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const togglePopover = (e: React.KeyboardEvent | React.MouseEvent) => {
    if (e.type !== 'keydown' || (e as React.KeyboardEvent).key === 'Enter') {
      setActive(!active);
    }
  };

  // EVENTS
  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const handleSubmitFilter = () => {
    column?.setFilterValue(searchValue);
    setActive(false);
  };

  const handleResetFilter = () => {
    column?.setFilterValue(undefined);
    setSearchValue('');
    setActive(false);
  };

  // STYLES
  const cssColumnFilter = classnames(styles['column-filter'], {
    [styles['column-filter--active']]: column?.getIsFiltered()
  });

  // RENDERS
  const activator = (
    <span
      role="button"
      tabIndex={0}
      className={cssColumnFilter}
      onClick={togglePopover}
      onKeyDown={togglePopover}
    >
      <span role="img" aria-label="filter" className={styles['icon-filter']}>
        <svg viewBox="64 64 896 896" focusable="false" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true">
          <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
        </svg>
      </span>
    </span>
  );

  return (
    <Popover
      isPortal
      p={0}
      placement='bottom-end'
      zIndex={999}
      isOpen={active}
      renderReference = { activator }
      onOutsideClickClose={() => setActive(false)}
    >
      <div className={styles.searchContainer}>
        <input
          type="text"
          placeholder='Search'
          onChange={(e) => handleSearchChange(e.target.value)}
        />
      </div>
      <div className={styles.footer}>
        <Button copy="Reset" size="xs" type='secondary' onClick={handleResetFilter} />
        <Button copy="Filter" size="xs" onClick={handleSubmitFilter} />
      </div>
    </Popover>
  );
};

export default ColumnSearch;
