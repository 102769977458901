import React, { useState } from 'react';

// COMPONENTS
import BrandIcon from 'components/BrandIcon/BrandIcon';
import Button from 'components/Button/Button';
import Checkbox from 'components/CustomCheckbox/CustomCheckbox';
import Icon from 'components/Icon/Icon';
import Text from 'components/Text/Text';
import TextInput from 'components/TextInput/TextInput';
import Upload from 'components/Upload/Upload';
import Logo from 'components/Logo/Logo';

interface Props {
    name: string;
}

const PlatformConfiguration: React.FC<Props> = ({ name }) => {
  const [logoUploaded, setLogoUploaded] = useState(false);

  return (
    <div>
      <div
        className='mar--b-48'
      >
        <Text appearance='title-sm' color='stone'>{name}</Text>
      </div>
      <div className='flex'>
        <div className='width--20-p'>
          {logoUploaded &&
            (
              <Logo
                className='dis--inline-block client--logo-hz'
                name='energyx'
                size='lg'
              />
            )
          }
          <Upload
            label="Logo"
            button={<Button copy="Choose file" type="primary" size="md" icon={<Icon.Upload size={16} />} iconPosition="left" />}
            onUpload={() => setLogoUploaded(true)}
          />
          <Upload
            label="Theme"
            button={<Button copy="Choose file" type="primary" size="md" icon={<Icon.Upload size={16} />} iconPosition="left" />}
            onUpload={() => {}}
          />
        </div>
        <div className='pad--l-24 width--80-p'>
          <div className='width--100-p'>
            <BrandIcon showBrandName color='full' icon='analyze' height={50} width={150} />
            <TextInput
              className='mar--b-24 mar--t-24'
              errorMessage=""
              id="input"
              infoMessage=""
              label="Synthectic Rates Data"
              placeholder="https://gridx-inc.awsapps.com/analyse/synthectic-data"
            />
            <TextInput
              className='mar--b-24'
              errorMessage=""
              id="input"
              infoMessage=""
              label="Template Models"
              placeholder="https://gridx-inc.awsapps.com/analyse/template-models"
            />
            <div className='flex'>
              <Checkbox className='mar--r-8' id='Frontend' />
              <Text className='mar--r-24' appearance='body-lg' color='stone'>Frontend</Text>

              <Checkbox className='mar--l-48 mar--r-8' id='Backend' />
              <Text className='mar--r-24' appearance='body-lg' color='stone'>Backend</Text>

              <Checkbox className='mar--l-48 mar--r-8' id='Database' />
              <Text className='mar--r-24' appearance='body-lg' color='stone'>Database</Text>
            </div>
            <Button className='mar--b-24 mar--t-24' copy='Build Analyze Staging' type='secondary' size='sm' />
            <div
              className='flex'
            >
              <Icon.AlertCircle className='mar--r-8' />
              <Text
                appearance='body-md'
              >
            Verify with GridX&apos;s OPS that AWS instances are available for deployment
              </Text>
            </div>

          </div>
          <div className='width--100-p mar--t-48'>
            <BrandIcon showBrandName color='full' icon='kam' height={50} width={150} />
            <TextInput
              className='mar--b-24 mar--t-24'
              errorMessage=""
              id="input"
              infoMessage=""
              label="Synthectic Rates Data"
              placeholder="https://gridx-inc.awsapps.com/kam/synthectic-data"
            />
            <TextInput
              className='mar--b-24'
              errorMessage=""
              id="input"
              infoMessage=""
              label="Template Models"
              placeholder="https://gridx-inc.awsapps.com/kam/template-models"
            />
            <div className='flex'>
              <Checkbox className='mar--r-8' id='Frontend' />
              <Text className='mar--r-24' appearance='body-lg' color='stone'>Frontend</Text>

              <Checkbox className='mar--l-48 mar--r-8' id='Backend' />
              <Text className='mar--r-24' appearance='body-lg' color='stone'>Backend</Text>

              <Checkbox className='mar--l-48 mar--r-8' id='Database' />
              <Text className='mar--r-24' appearance='body-lg' color='stone'>Database</Text>
            </div>
            <Button className='mar--b-24 mar--t-24' copy='Build KAM Staging' type='secondary' size='sm' />
            <div
              className='flex'
            >
              <Icon.AlertCircle className='mar--r-8' />
              <Text
                appearance='body-md'
              >
            Verify with GridX&apos;s OPS that AWS instances are available for deployment
              </Text>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default PlatformConfiguration;
