import React from 'react';
import Grid from '@mui/system/Unstable_Grid';
import { useNavigate } from 'react-router-dom';

// COMPONENTS
import { Puzzle, HomeGas, HomePower, Store, Municipality } from '../../../../../../icons';
import Icon from '../../../../../../components/Icon/Icon';
import { StatusTag } from '../../../../components';

// ASSETS
import styles from './ListRatesItem.module.scss';

// TYPINGS
import { IRate } from '../../../../../../api/analyze/analyze.typings';

interface ListRatesItemProps {
  rate: IRate
}

const ListRatesItem:React.FC<ListRatesItemProps> = ({ rate }) => {
  const navigate = useNavigate();

  // EVENTS
  const handleRateClick = () => {
    navigate(`/analyze/rates/${rate.id}`);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleRateClick();
    }
  };

  // RENDERS
  const renderIcon = () => {
    switch (rate.rateType) {
    case 'home-power':
      return <HomePower />;
    case 'home-gas':
      return <HomeGas />;
    case 'store':
      return <Store />;
    case 'municipality':
      return <Municipality />;
    default:
      return <Puzzle />;
    }
  };

  return (
    <div
      className={styles['list-item']}
      onClick={handleRateClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      role="button"
    >
      <div className={styles['list-icon']}>
        {renderIcon()}
      </div>
      <div className={styles['list-body']}>
        <Grid container spacing={2}>
          <Grid xs={3}>
            <div className={styles.title}>
              <p>{rate.name}</p>
              <div className={styles.chevron}>
                <Icon.ChevronRight size={18} />
              </div>
            </div>
          </Grid>
          <Grid xs={7}>
            <p>{rate.description}</p>
          </Grid>
          <Grid xs={2}>
            <div className={styles.status}><StatusTag status={rate.status} /></div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ListRatesItem;
