import React from 'react';

// COMPONENTS
import { Icon } from 'components';

// ASSETS
import styles from './DownloadLink.module.scss';

interface DownloadLinkProps {
  text: string;
  onClick?: () => void;
}

const DownloadLink:React.FC<DownloadLinkProps> = ({ text, onClick }) => {
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick && onClick();
    }
  };

  return (
    <div
      className={styles['download-link']}
      onClick={onClick}
      role="button"
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <Icon.Download size={24} />
      <span>{text}</span>
    </div>
  );
};

export default DownloadLink;
