import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';

// COMPONENTS
import ListRatesItem from '../ListRatesItem/ListRatesItem';
import ListShowMore from 'components/ListShowMore/ListShowMore';

// TYPINGS
import { IRate } from '../../../../../../api/analyze/analyze.typings';

// ASSETS
import styles from './ListRates.module.scss';

interface ListRatesProps {
  rates: Array<IRate>
}

const ListRates:React.FC<ListRatesProps> = ({ rates }) => {
  const [limitShow, setLimitShow] = useState(10);

  // EVENTS
  const handleShowMore = () => {
    setLimitShow(limitShow + 10);
  };

  // RENDERS
  const filteredRates = rates.slice(0, limitShow);

  return (
    <>
      <AnimatePresence>
        <motion.ul
          className={styles['list-container']}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {
            filteredRates.map((rate) => {
              return (
                <motion.li
                  key={rate.id}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.5 }}
                >
                  <ListRatesItem key={rate.id} rate={rate} />
                </motion.li>
              );
            })
          }
        </motion.ul>
      </AnimatePresence>
      <div className={styles['list-footer']}>
        <ListShowMore total={rates.length} current={limitShow} onShowMore={handleShowMore} />
      </div>
    </>
  );
};

export default ListRates;
