import React, { Dispatch, useState, SetStateAction } from 'react';

import Button from '../Button/Button';
import Drawer from '../Drawer/Drawer';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';
import TextInput from '../TextInput/TextInput';
import { RenderFiltersList } from '../../helpers/UserFiltersList';

interface Props {
    className?: string,
    isOpen?: boolean,
    setIsOpen?: Dispatch<SetStateAction<boolean>>,
    setNewUsers?: Dispatch<SetStateAction<any>>
};

const ManageUsers: React.FC<Props> = ({
  className,
  isOpen = true,
  setIsOpen = () => {},
  setNewUsers = () => {}
}: Props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const handleSave = () => {
    const tempObject = {
      picture: 'http://placehold.it/32x32',
      registered: new Date(),
      role: 'admin'
    };

    setNewUsers([{ firstName, lastName, email, ...tempObject }]);
    setIsOpen(false);
  };

  return (
    <div className={className}>
      <Drawer
        anchor='right'
        open={isOpen}
        size='md'
        onClose={() => setIsOpen(false)}
        footer={
          <Button copy='Save Changes' type='primary' onClick={() => handleSave()}/>
        }
      >
        <div className='pad--r-28 pad--t-28'>
          <div className='flex'>
            <div className='manage-user--header mar--b-24 width--100-p'>
              <div className='flex flex--align-center jus--space-between manage-user--title-container width--100-p'>
                <div className='flex flex--align-center'>
                  <Icon.Edit3 className='manage-user--logo-icon' />
                  <Text className='body--xl bold manage-user--title'>New User</Text>
                </div>
                <div
                  className=''
                  onClick={() => setIsOpen(false)}
                  onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                    if (e.key === 'Enter') {
                      setIsOpen(false);
                    }
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <Icon.X className='manage-user--logo-close-icon' />
                </div>
              </div>
            </div>
          </div>
          <TextInput
            className='width--100-p'
            label='First name'
            data-testid='firstName'
            onChange={(e: { target: { value: string; }; }) => setFirstName(e.target.value)}
            size='lg'
          />
          <TextInput
            className='mar--t-24 width--100-p'
            label='Last name'
            data-testid='LastName'
            onChange={(e: { target: { value: string; }; }) => setLastName(e.target.value)}
            size='lg'
          />
          <TextInput
            className='mar--t-24 width--100-p'
            label='Email'
            data-testid='Email'
            onChange={(e: { target: { value: string; }; }) => setEmail(e.target.value)}
            size='lg'
          />
          <div className='mar--t-24'>
            <Text
              className=' title--sm'
              variant='body1'
            >
            Applications access
            </Text>
            <div className='mar--t-16'>
              {RenderFiltersList(() => {})}
            </div>
          </div>
        </div>
      </Drawer>
    </div>

  );
};

export default ManageUsers;
