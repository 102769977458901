import WebFont from 'webfontloader';
import { Theme } from './Theme.model';

export function applyThemeToRoot (theme: Theme) {
  // Function to handle font loading
  const loadFonts = (theme: Theme) => {
    if (theme.font && Array.isArray(theme.font.families) && theme.font.families.length > 0) {
      WebFont.load({
        google: {
          families: theme.font.families
        },
        active: function () {
          console.log('Fonts loaded successfully');
        },
        inactive: function () {
          console.error('Failed to load fonts');
        }
      });
    }
  };

  // Recursive function to traverse the theme object and set CSS variables
  const applyProperties = (obj: Theme, prefix = '') => {
    for (const [key, value] of Object.entries(obj)) {
      const newPrefix = prefix ? `${prefix}-${key}` : key;

      if (typeof value === 'object' && value !== null) {
        applyProperties(value, newPrefix);
      } else {
        document.documentElement.style.setProperty(`--${newPrefix}`, value);
      }
    }
  };

  // Load fonts
  loadFonts(theme);

  // Apply theme properties
  applyProperties(theme);
}

export const removeBodyCSSVariables = () => {
  // Get all style properties of the document
  const styles = document.documentElement.style;

  for (let i = styles.length - 1; i >= 0; i--) {
    const propName = styles[i];
    if (propName.startsWith('--')) {
      // Remove the CSS variable
      document.documentElement.style.removeProperty(propName);
    }
  }
};
