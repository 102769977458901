import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

// COMPONENTS
import DataTable from 'components/DataTable/DataTable';
import { Box, Button, Drawer, Icon, Select, Tag, TextInput } from 'components';
import AlertInline from 'components/AlertInline/AlertInline';
import { InfoItem } from 'blocks/components';
import { columns, data } from './EventHandlerDrawer.helpers';

// ACTIONS
import { setEventHandlerOpen } from 'store/modules/events/events.slice';

// SELECTORS
import { getEventHandlerId, getEventsFilters, getEventsSearchString, getEventsSortBy, getIsEventHandlerOpen } from 'store/modules/events/events.selector';

// ASSETS
import styles from './EventHandlerDrawer.module.scss';
import colors from 'style/colors';
import { IEvent } from 'api/events/events.typings';
import { useEvents } from 'api/events/events.api';

const EventHandlerDrawer = () => {
  const queryClient = useQueryClient();
  const [simulation, setSimulation] = useState({ loading: false, completed: false });
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsEventHandlerOpen);
  const eventHandlerId = useSelector(getEventHandlerId);
  const searchString = useSelector(getEventsSearchString);
  const filters = useSelector(getEventsFilters);
  const { key: sortKey, ascending } = useSelector(getEventsSortBy);

  const { data: eventsData, isLoading } = useEvents({ filters, searchString, sortKey, orderAscending: ascending });

  if (isLoading || !eventsData) return null;

  const event = eventsData.filter(event => event.id === eventHandlerId)[0];

  if (!event) return null;

  // EVENTS
  const handleClose = () => {
    dispatch(setEventHandlerOpen({ open: false }));
    setSimulation({ loading: false, completed: false });
  };

  const handleSimulation = () => {
    if (simulation.completed) {
      dispatch(setEventHandlerOpen({ open: false }));
      setSimulation({ loading: false, completed: false });
      queryClient.setQueryData(['events'], (oldData: IEvent[] | undefined) => {
        if (!oldData) {
          return [];
        }

        return oldData.map(data =>
          data.id === event.id ? { ...data, status: 'Resolved' } : data
        );
      });
    } else {
      setSimulation({ loading: true, completed: false });
      setTimeout(() => {
        setSimulation({ loading: false, completed: true });
      }, 2000);
    }
  };

  // RENDERS
  const handlerOptions = [
    { value: 'modifyAttributes', label: 'Modify Attributes' },
    { value: 'value1', label: 'Value 1' },
    { value: 'value2', label: 'Value 2' },
    { value: 'value3', label: 'Value 3' },
    { value: 'value4', label: 'Value 4' },
    { value: 'value5', label: 'Value 5' },
    { value: 'value6', label: 'Value 6' },
    { value: 'value7', label: 'Value 7' },
    { value: 'value8', label: 'Value 8' }
  ];

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      width={770}
      onClose={handleClose}
      title="Event Handler"
    >
      <Box mb={3}>
        <AlertInline
          alertMessage={
            <div className={styles['alert-container']}>
              <Tag color={event.status === 'Exception' ? 'heat' : 'stem'} copy={event.status} size="sm" />
              <h5>Pre Billing</h5>
              <p>
                {event.status === 'Exception' ? 'Ratecode mismatch, where bill rate code = &apos;T-1&apos; and attribute_id = 2 is &apos;T-2&apos;' : 'Handler applied to attribute'}
              </p>
            </div>
          }
          type={event.status === 'Exception' ? 'error' : 'success'}
        />
      </Box>
      <div className={styles['details-container']}>
        <div className={styles.details}>
          <div>
            <div className={styles['info-box']}>
              <InfoItem title="Status" description={event.status === 'Exception' ? 'Unresolved' : 'Accepted'} />
            </div>
            <div className={styles['info-box']}>
              <InfoItem title="Type" description="Pre Billing" />
            </div>
            <div className={styles['info-box']}>
              <InfoItem title="Code" description={event.code} />
            </div>
          </div>
          <div>
            <div className={styles['info-box']}>
              <InfoItem title="Service Agreement ID" description="2608237351137217" />
            </div>
            <div className={styles['info-box']}>
              <InfoItem title="Bill Cycle" description="2023-03-01 - 2023-05-01" />
            </div>
            <div className={styles['info-box']}>
              <InfoItem title="Rate Code" description="T-2" />
            </div>
          </div>
          <div>
            <div className={styles['info-box']}>
              <InfoItem title="Event ID" description={event.eventId}/>
            </div>
            <div className={styles['info-box']}>
              <InfoItem title="Ticket" description={event.ticket} />
            </div>
            <div className={styles['info-box']}>
              <InfoItem
                title="Assigned"
                description={
                  <Box display="flex" alignItems="center">
                    <Box mr={0.5}>
                      <span>-</span>
                    </Box>
                    <Icon.Edit3 color={colors.sky}/>
                  </Box>
                }
              />
            </div>
          </div>
        </div>
        <div className={styles['details-footer']}>
          <Button
            copy="More details"
            iconPosition="right"
            icon={<Icon.ChevronRight />}
            type='secondary'
            color='depth'
            size="md"
          />
        </div>
      </div>
      <div className={styles.resolve}>
        <h5>Resolve</h5>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus tempus velit vitae arcu tincidunt viverra. Duis ultricies nisi eu ornare maximus.</p>
        <div className={styles['resolve-form']}>
          <Select label="Handler" options={handlerOptions} defaultValue="modifyAttributes" />
          <TextInput label="Attributes" size="md" />
        </div>
        <DataTable columns={columns as any} data={data} />
      </div>
      <div className={styles.footer}>
        {
          simulation.completed && (
            <Box mb={2} width="100%">
              <AlertInline
                alertMessage="Succeeded, no exceptions during simulation"
                icon
                type="success"
              />
            </Box>
          )
        }
        <Button
          type="primary"
          color='depth'
          copy={simulation.completed ? 'Apply' : 'Simulate'}
          size="md"
          onClick={handleSimulation}
          loading={simulation.loading}
        />
      </div>
    </Drawer>
  );
};

export default EventHandlerDrawer;
