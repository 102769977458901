import React from 'react';

// COMPONENTS
import DataTable from 'components/DataTable/DataTable';

// HELPERS
import { columnsDemand, columnsPower, columnsRegister, columnsTou, demandData, powerData, registerData, touData } from './UsageDetailsInfo.helpers';

// ASSETS
import styles from './UsageDetailsInfo.module.scss';

const UsageDetailsInfo = () => {
  return (
    <div className={styles.container}>
      <div>
        <div className={styles['table-info']}>
          <p>Total kWh</p>
          <DataTable columns={columnsTou as any} data={touData}/>
        </div>
        <div className={styles['table-info']}>
          <p>Register Reads</p>
          <DataTable columns={columnsRegister as any} data={registerData}/>
        </div>
      </div>
      <div>
        <div className={styles['table-info']}>
          <p>Demand KW</p>
          <DataTable columns={columnsDemand as any} data={demandData}/>
        </div>
        <div className={styles['table-info']}>
          <p>Demand Kva</p>
          <DataTable columns={columnsDemand as any} data={demandData}/>
        </div>
        <div className={styles['table-info']}>
          <p>Power Factor</p>
          <DataTable columns={columnsPower as any} data={powerData}/>
        </div>
      </div>
    </div>
  );
};

export default UsageDetailsInfo;
