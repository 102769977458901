import React from 'react';

// COMPONENTS
import Icon from '../../../components/Icon/Icon';

// ASSETS
import styles from './FilterPanel.module.scss';
import { Button } from 'components';

interface FilterPanelProps {
  children: React.ReactNode;
  onClose: () => void;
  onClear?: () => void;
}

const FilterPanel:React.FC<FilterPanelProps> = ({ children, onClose, onClear }) => {
  // EVENTS
  const handleClose = () => {
    onClose();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClose();
    }
  };

  const handleClearAll = () => {
    if (onClear) {
      onClear();
    }
  };

  return (
    <div className={styles['filter-container']}>
      <div className={styles['filter-header']}>
        <span className={styles['filter-header__title']}>Filters</span>
        <span
          className={styles['filter-close']}
          onClick={handleClose}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex={0}
        >
          <Icon.X/>
        </span>
      </div>
      <div>
        {children}
      </div>
      <div className={styles.footer}>
        {onClear && (<Button copy="Clear all" type="secondary" color='depth' size="sm" onClick={handleClearAll} />)}
      </div>
    </div>
  );
};

export default FilterPanel;
