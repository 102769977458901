import accountsReducer from './modules/accounts/accounts.slice';
import analyzeReducer from './modules/analyze/analyze.slice';
import projectReducer from './modules/project/project.slice';
import billingReducer from './modules/billing/billing.slice';
import eventsReducer from './modules/events/events.slice';

export const reducers = {
  accounts: accountsReducer,
  billing: billingReducer,
  events: eventsReducer,
  analyze: analyzeReducer,
  project: projectReducer
};

export interface IAppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => any): void;
}
