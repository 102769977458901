import React from 'react';

// COMPONENTS
import { AccountsFilterPanel, FilterSearchAccounts, ListAccounts } from './components';

// ASSETS
import styles from './AccountsList.module.scss';

// HELPERS
import { useAccounts } from 'api/accounts/accounts.api';
import { useSelector } from 'react-redux';
import { getAccountsFilterOpen, getAccountsFilters, getAccountsSearchString, getAccountsSortBy } from 'store/modules/accounts/accounts.selector';
import { Box } from 'components';
import { useListWidth } from 'hooks/useListWidth';

const AccountsList = () => {
  const searchString = useSelector(getAccountsSearchString);
  const filters = useSelector(getAccountsFilters);
  const isFilterOpen = useSelector(getAccountsFilterOpen);
  const { key: sortKey, ascending } = useSelector(getAccountsSortBy);

  const { data: dataAccounts, isLoading } = useAccounts({ filters, searchString, sortKey, orderAscending: ascending });

  const listWidth = useListWidth(isFilterOpen);

  return (
    <div className={`${styles['account-page']} admin--page pad--t-128 pad--l-128 pad--r-36`} role="main">
      <div><h2>Accounts</h2></div>
      <FilterSearchAccounts/>
      {(!isLoading && dataAccounts) && (
        <div id="mainListArea" className={styles.main}>
          <AccountsFilterPanel/>
          <Box width={listWidth}>
            <ListAccounts accounts={dataAccounts} aria-label="List of Accounts" />
          </Box>
        </div>
      )}
    </div>
  );
};

export default AccountsList;
