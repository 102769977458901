import React from 'react';
import { get, useFormContext } from 'react-hook-form';

// COMPONENTS
import TextInput from 'components/TextInput/TextInput';
import Select from 'components/CustomSelect/CustomSelect';
import Box from 'components/Box/Box';

const FormHeatPump = () => {
  const { register, getValues } = useFormContext();

  // RENDERS
  const homeYearField = register('homeYearField');
  const homeSizeField = register('homeSizeField');
  const heatingType = register('heatingType');

  return (
    <div>
      <Box mb={3}>
        <Select
          label="Year home was built"
          options={[
            { value: '<1940', label: '<1940' },
            { value: '1950s', label: '1950s' },
            { value: '1960s', label: '1960s' },
            { value: '1970s', label: '1970s' },
            { value: '1980s', label: '1980s' },
            { value: '1990s', label: '1990s' },
            { value: '2000s', label: '2000s' },
            { value: '2010s', label: '2010s' }
          ]}
          defaultValue={getValues().homeYearField}
          inputRef={homeYearField.ref}
          onChange={(_, value: any) => homeYearField.onChange({ target: { value, name: homeYearField.name } })}
        />
      </Box>
      <Box mb={3}>
        <TextInput
          label="Home size"
          size="md"
          suffix="sq ft"
          defaultValue={getValues().homeSizeField}
          inputRef={register('homeSizeField') as any}
          onChange={(event: any) => homeSizeField.onChange({ target: { value: event.target.value, name: homeSizeField.name } })}
        />
      </Box>
      <Select
        label="Heating & cooling type"
        options={[
          { value: 'Gas Heating and Electric A/C', label: 'Gas Heating and Electric A/C' },
          { value: 'Gas Heat and no A/C', label: 'Gas Heat and no A/C' },
          { value: 'Electric Heating and Electric A/C', label: 'Electric Heating and Electric A/C' },
          { value: 'Electric Heating and no A/C', label: 'Electric Heating and no A/C' }
        ]}
        defaultValue={getValues().heatingType}
        inputRef={heatingType.ref}
        onChange={(_, value: any) => heatingType.onChange({ target: { value, name: heatingType.name } })}
      />
    </div>
  );
};

export default FormHeatPump;
