import React from 'react';

// COMPONENTS
import ImplementationItem from 'widget/ExploreWidget/components/ImplementationItem/ImplementationItem';
import Button from 'components/Button/Button';

// ASSETS
import styles from '../../ImplementationPage.module.scss';
import { Resource } from 'widget/ExploreWidget/api/projects.typings';

// HELPERS
import { getModifierTags } from 'widget/ExploreWidget/helpers/utils/getModifierIcon';

interface EvImplementationProps {
  modifier: Resource;
}

const EvImplementation:React.FC<EvImplementationProps> = ({ modifier }) => {
  return (
    <ImplementationItem
      title="Electric vehicle"
      type='modifier'
      modifier="ev"
      tags={getModifierTags(modifier)}
      footer={
        <div className={styles['content-footer']}>
          <p>Once you’ve found your electric vehicle, enter the date you expect to start charging at home so we can help with the final connection.</p>
          <Button
            copy="Enter your date"
            variant="outlined"
            color="secondary"
          />
        </div>
      }
    >
      <div className={styles['content-quote']}>
        <div className={styles['content-text']}>
          <p className={styles['content-title']}>Lean about getting started with an electric vehicle.</p>
          <p className={styles['content-description']}>
              Browse our resources and frequently asked questions about owning an EV and charging it from your home. <a href="#.">View fundamentals of electric vehicles.</a>.
          </p>
        </div>
        <div className={styles['content-text']}>
          <p className={styles['content-title']}>Review rebates and Incentives </p>
          <p className={styles['content-description']}>As an EV owner and PG&E customer, you may be eligible to receive a rebate for your use of electricity as a clean transportation fuel or access High Occupancy Vehicle (HOV) lanes. <a href="#.">Learn more about available rebates and incentives.</a></p>
        </div>
      </div>
    </ImplementationItem>
  );
};

export default EvImplementation;
