import * as React from 'react';
import classnames from 'classnames';
import { Drawer as MUIDrawer } from '@mui/material/';

// COMPONENTS
import Icon from '../Icon/Icon';

// ASSETS
import styles from './Drawer.module.scss';

// HELPERS
import { getModalSize } from './Drawer.helpers';

export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'full' | 'viewport';

export interface IDrawerProps {
  id?: string;
  /** Side from which the drawer will appear. */
  anchor: 'bottom' | 'left' | 'right' | 'top';
  /** Whether the drawer is open or not */
  open: boolean;
  /** The content for the title of the drawer */
  title?: string | React.ReactNode;
  /** The content to display inside drawer */
  children?: React.ReactNode;
  /** Inner content of the footer */
  footer?: React.ReactNode;
  /** Replaces drawer content with a spinner while a background action is being performed */
  loading?: boolean;
  /** Controls the size of the drawer */
  size?: Size;
  /** Primary action of the drawer */
  primaryAction?: React.ReactNode;
  /** Secondary action of the drawer */
  secondaryAction?: React.ReactNode;
  /** Increases the modal width */
  large?: boolean;
  /** Limits modal height on large sceens with scrolling */
  limitHeight?: boolean;
  /** Styles for the body container */
  bodyStyle?: React.CSSProperties;
  /** Styles for the footer container */
  footerStyle?: React.CSSProperties;
  /** Callback when the modal is closed */
  onClose(): void;
  /** Callback when modal transition animation has ended */
  onTransitionEnd?(): void;
  transitionDuration?: number | { appear?: number; enter?: number; exit?: number };
  width?: number | string;
  BackdropProps?: Record<string, any>;
}

const Drawer: React.FC<IDrawerProps> = ({
  anchor = 'left',
  open,
  children,
  title,
  footer,
  onClose,
  size = 'sm',
  transitionDuration,
  width,
  id,
  bodyStyle,
  footerStyle,
  BackdropProps = { invisible: true }
}) => {
  // STYLES
  const cssRoot = classnames(styles.root, {
    [styles.rootFull]: size === 'full',
    [styles.rootViewPort]: size === 'viewport'
  });

  const cssModalDrawer = {
    root: styles.modalRoot
  };

  const cssPaperDrawer = {
    root: cssRoot
  };

  const cssDrawerBody = classnames(styles.drawerBody, {
    [styles.drawerBodyNoFooter]: !footer
  });

  return (
    <MUIDrawer
      anchor={anchor}
      BackdropProps={BackdropProps}
      transitionDuration={transitionDuration || 400}
      open={open}
      onClose={onClose}
      classes={cssModalDrawer}
      PaperProps={{ classes: cssPaperDrawer }}
    >
      <div id={id} className={styles.paper} style={{ width: width || getModalSize(size) }}>
        <div className={styles.container}>
          {title && (
            <div className={styles.drawerHeader} data-element="drawer-header">
              <p className={styles.drawerTitle}>{title}</p>
              <div>
                <Icon.X onClick={onClose} className={styles.drawerCloseIcon} fontSize="inherit" />
              </div>
            </div>
          )}
          <div className={cssDrawerBody} style={bodyStyle} data-element="drawer-body">
            {children}
          </div>
          {footer && (
            <div className={styles.drawerFooter} style={footerStyle} data-element="drawer-footer">
              {footer}
            </div>
          )}
        </div>
      </div>
    </MUIDrawer>
  );
};

export default Drawer;
