import * as React from 'react';
const Municipality = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 36 36"
    {...props}
  >
    <path
      fill="#FFA44F"
      stroke="#FFA44F"
      strokeWidth={0.75}
      d="M33 32.25h-6.585l.6-3H31.5a.75.75 0 1 0 0-1.5h-4.185l.9-4.5H31.5a.75.75 0 1 0 0-1.5h-2.985l.72-3.603a.75.75 0 0 0-1.47-.294l-.78 3.897h-4.47l.72-3.603a.75.75 0 0 0-1.47-.294l-.78 3.897H16.5a.75.75 0 1 0 0 1.5h4.185l-.9 4.5H16.5a.75.75 0 1 0 0 1.5h2.985l-.6 3H9.75v-9.076A5.243 5.243 0 0 0 14.25 18V7.5a5.25 5.25 0 1 0-10.5 0V18a5.243 5.243 0 0 0 4.5 5.174v9.076H3a.75.75 0 1 0 0 1.5h30a.75.75 0 1 0 0-1.5ZM5.25 18V7.5a3.75 3.75 0 0 1 7.5 0V18a3.75 3.75 0 0 1-7.5 0Zm16.965 5.25h4.47l-.9 4.5h-4.47l.9-4.5Zm-1.2 6h4.47l-.6 3h-4.47l.6-3Z"
    />
  </svg>
);
export default Municipality;
