import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// import './chart.scss';

interface Props {
    className?: string,
    options?: object,
};

/**
* Represents a Chart.
* @constructor
* @param {string} className
* @param {string} type
* @param {object} options
* Usage :
* ````js
* <Chart className='my-chart' options={options} />
* ````
* @augments {Component<Props, State>}
*/
/** */
const Chart: React.FC<Props> = ({
  className,
  options
}: Props) => {
  return (
    <div
      className={
        `chart ${className || ''}`
      }
    >
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default Chart;
