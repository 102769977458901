import { useEffect, useState } from 'react';

export const useListWidth = (isFilterOpen: boolean) => {
  const [listWidth, setListWidth] = useState('100%');

  const updateListWidth = () => {
    const accountsListMain = document.querySelector('#mainListArea');
    const filterPanel = document.querySelector('#mainListArea > div:first-child');

    const mainWidth = accountsListMain?.getBoundingClientRect().width;
    const filterPanelWidth = isFilterOpen ? filterPanel?.getBoundingClientRect().width : 0;

    setListWidth(`calc(${mainWidth}px - ${filterPanelWidth}px)`);
  };

  useEffect(() => {
    setTimeout(() => {
      updateListWidth();
    }, 1000);
    window.addEventListener('resize', updateListWidth);

    const accountsListMain = document.querySelector('#mainListArea');

    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (
          mutation.type === 'attributes' && mutation.attributeName === 'style'
        ) {
          updateListWidth();
        }
      }
    });

    if (accountsListMain) {
      observer.observe(accountsListMain, {
        attributes: true,
        childList: true,
        subtree: true
      });
    }

    return () => {
      window.removeEventListener('resize', updateListWidth);
      observer.disconnect();
    };
  }, [isFilterOpen]);

  return listWidth;
};
