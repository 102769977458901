import React from 'react';

// COMPONENTS
import { DatePicker } from '../../../../../../components';
import InputLabel from '../../../../../../components/InputLabel/InputLabel';

const FilterDateRange = () => {
  return (
    <div>
      <InputLabel>Date range</InputLabel>
      <DatePicker/>
    </div>
  );
};

export default FilterDateRange;
