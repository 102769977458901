import React from 'react';

import colors from '../../../../style/colors';
import { colorsIndex, constColors } from './charts.helpers';
import { ConstColors } from './types';

// ASSETS
import styles from './TableRateCompare.module.scss';

type RateComparisonLegendsProps = {
  chartSeriesKeys: Array<string>;
  inactiveLegends?: Array<string>;
  onlegendsClick: (key: string) => void;
};

const TableRateCompareLegends = ({ chartSeriesKeys, inactiveLegends, onlegendsClick }: RateComparisonLegendsProps) => (
  <ul className={styles['legends-root']}>
    {chartSeriesKeys.map((seriesKey: string, index: number) => {
      const color = inactiveLegends?.includes(seriesKey) ? colors.greyMedium : colors.stone;

      return (
        <button
          className={styles['legends-button']}
          key={seriesKey}
          style={{ color }}
          onClick={() => {
            onlegendsClick(seriesKey);
          }}
        >
          <span
            className={styles['legends-bullet']}
            style={{
              opacity: inactiveLegends?.includes(seriesKey) ? 0.3 : 1,
              backgroundColor: constColors[seriesKey as keyof ConstColors] || colorsIndex[index]
            }}
          ></span>
          <span>{seriesKey}</span>
        </button>
      );
    })}
  </ul>
);

export default TableRateCompareLegends;
