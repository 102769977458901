import { useQuery } from '@tanstack/react-query';
import { useAxios } from './useAxios';

interface ResourceListQueryArgs {
  rateCode: string;
  enabled?: boolean;
}

export const useResourceList = ({ rateCode, enabled }: ResourceListQueryArgs) => {
  const axios = useAxios();

  return useQuery({
    queryKey: ['resource-list'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryFn: () => {
      return axios.get(`/api/resources/${rateCode}`).then((res) => res.data);
    },
    enabled
  });
};
