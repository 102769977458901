import React, { Fragment } from 'react';

import Header from '../../components/Header/Header';
import AlertInline from '../../components/AlertInline/AlertInline';
import Link from '../../components/Link/Link';
import Text from '../../components/Text/Text';

interface Props {
  type?: 'error' | 'success' | 'info' | 'gray' | 'warning',
  alertMessage?: string
  alertTitle?: string
  icon?: boolean
  action?: boolean
  actionName?: string
  closeButton?: boolean
};

const AlertInlinePage: React.FC = () => {
  const renderAlertInline: React.FC<Props> = ({
    type = 'error',
    alertMessage = 'Alert',
    alertTitle = undefined,
    icon = false,
    action = false,
    actionName = null,
    closeButton = false
  }) => {
    return (
      <AlertInline
        type={type}
        alertMessage={alertMessage}
        alertTitle={alertTitle}
        icon={icon}
        action={actionName}
        closeButton={closeButton}
      />
    );
  };
  const alertMessage = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam22.';
  return (
    <Fragment>
      <Header
        className='design-studio pad--b-100 pad--l-128 pad--t-100'
        color='stone'
        text='Alert Inline'
      >
        <Text
          className='max-width--900 pad--t-40 title--sm'
          color='white'
          variant='body1'
        >
           Inline notifications display both task-generated and system-generated messages and persist until they are dismissed by the user or the notification is resolved.
        </Text>
        <Link
          className='underline dis--block mar--t-60 body--xl'
          color='white'
          copy='See Our Guidelines'
          href='/'
        />
      </Header>
      <div
        className='pad--b-156 mar--l-96 mar--t-80 max-width--lg temp--min-width--sm'
      >
        <div className='mar--b-20'>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'error', alertMessage, icon: false, action: false, closeButton: false })}
            {renderAlertInline({ type: 'error', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: false })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'info', alertMessage, icon: false, action: false, closeButton: false })}
            {renderAlertInline({ type: 'info', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: false })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'success', alertMessage, icon: false, action: false, closeButton: false })}
            {renderAlertInline({ type: 'success', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: false })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'warning', alertMessage, icon: false, action: false, closeButton: false })}
            {renderAlertInline({ type: 'warning', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: false })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'gray', alertMessage, icon: false, action: false, closeButton: false })}
            {renderAlertInline({ type: 'gray', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: false })}
          </section>
        </div>

        <div className='mar--b-20 mar--t-100'>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'error', alertMessage, icon: true, action: false, closeButton: false })}
            {renderAlertInline({ type: 'error', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: false })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'info', alertMessage, icon: true, action: false, closeButton: false })}
            {renderAlertInline({ type: 'info', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: false })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'success', alertMessage, icon: true, action: false, closeButton: false })}
            {renderAlertInline({ type: 'success', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: false })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'warning', alertMessage, icon: true, action: false, closeButton: false })}
            {renderAlertInline({ type: 'warning', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: false })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'gray', alertMessage, icon: true, action: false, closeButton: false })}
            {renderAlertInline({ type: 'gray', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: false })}
          </section>
        </div>

        <div className='mar--b-20 mar--t-100'>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'error', alertMessage, icon: false, action: false, closeButton: true })}
            {renderAlertInline({ type: 'error', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: true })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'info', alertMessage, icon: false, action: false, closeButton: true })}
            {renderAlertInline({ type: 'info', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: true })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'success', alertMessage, icon: false, action: false, closeButton: true })}
            {renderAlertInline({ type: 'success', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: true })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'warning', alertMessage, icon: false, action: false, closeButton: true })}
            {renderAlertInline({ type: 'warning', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: true })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'gray', alertMessage, icon: false, action: false, closeButton: true })}
            {renderAlertInline({ type: 'gray', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: true })}
          </section>
        </div>

        <div className='mar--b-20 mar--t-100'>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'error', alertMessage, icon: true, action: true, closeButton: true })}
            {renderAlertInline({ type: 'error', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: true })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'info', alertMessage, icon: true, action: true, closeButton: true })}
            {renderAlertInline({ type: 'info', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: true })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'success', alertMessage, icon: true, action: true, closeButton: true })}
            {renderAlertInline({ type: 'success', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: true })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'warning', alertMessage, icon: true, action: true, closeButton: true })}
            {renderAlertInline({ type: 'warning', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: true })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'gray', alertMessage, icon: true, action: true, closeButton: true })}
            {renderAlertInline({ type: 'gray', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: true })}
          </section>
        </div>

        <div className='mar--b-20 mar--t-100'>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'error', alertMessage, icon: false, action: false, closeButton: false, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'error', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: false, alertTitle: 'Alert Title' })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'info', alertMessage, icon: false, action: false, closeButton: false, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'info', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: false, alertTitle: 'Alert Title' })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'success', alertMessage, icon: false, action: false, closeButton: false, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'success', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: false, alertTitle: 'Alert Title' })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'warning', alertMessage, icon: false, action: false, closeButton: false, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'warning', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: false, alertTitle: 'Alert Title' })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'gray', alertMessage, icon: false, action: false, closeButton: false, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'gray', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: false, alertTitle: 'Alert Title' })}
          </section>
        </div>

        <div className='mar--b-20 mar--t-100'>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'error', alertMessage, icon: true, action: false, closeButton: false, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'error', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: false, alertTitle: 'Alert Title' })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'info', alertMessage, icon: true, action: false, closeButton: false, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'info', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: false, alertTitle: 'Alert Title' })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'success', alertMessage, icon: true, action: false, closeButton: false, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'success', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: false, alertTitle: 'Alert Title' })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'warning', alertMessage, icon: true, action: false, closeButton: false, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'warning', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: false, alertTitle: 'Alert Title' })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'gray', alertMessage, icon: true, action: false, closeButton: false, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'gray', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: false, alertTitle: 'Alert Title' })}
          </section>
        </div>

        <div className='mar--b-20 mar--t-100'>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'error', alertMessage, icon: false, action: false, closeButton: true, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'error', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: true, alertTitle: 'Alert Title' })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'info', alertMessage, icon: false, action: false, closeButton: true, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'info', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: true, alertTitle: 'Alert Title' })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'success', alertMessage, icon: false, action: false, closeButton: true, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'success', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: true, alertTitle: 'Alert Title' })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'warning', alertMessage, icon: false, action: false, closeButton: true, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'warning', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: true, alertTitle: 'Alert Title' })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'gray', alertMessage, icon: false, action: false, closeButton: true, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'gray', alertMessage, icon: false, action: true, actionName: 'Action1', closeButton: true, alertTitle: 'Alert Title' })}
          </section>
        </div>

        <div className='mar--b-20 mar--t-100'>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'error', alertMessage, icon: true, action: true, closeButton: true, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'error', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: true, alertTitle: 'Alert Title' })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'info', alertMessage, icon: true, action: true, closeButton: true, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'info', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: true, alertTitle: 'Alert Title' })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'success', alertMessage, icon: true, action: true, closeButton: true, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'success', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: true, alertTitle: 'Alert Title' })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'warning', alertMessage, icon: true, action: true, closeButton: true, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'warning', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: true, alertTitle: 'Alert Title' })}
          </section>
          <section className='flex mar--r-20 mar--l-20 mar--b-20'>
            {renderAlertInline({ type: 'gray', alertMessage, icon: true, action: true, closeButton: true, alertTitle: 'Alert Title' })}
            {renderAlertInline({ type: 'gray', alertMessage, icon: true, action: true, actionName: 'Action1', closeButton: true, alertTitle: 'Alert Title' })}
          </section>
        </div>
      </div>
    </Fragment>
  );
};

export default AlertInlinePage;
