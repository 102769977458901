import React, { ReactNode } from 'react';

import './shadow.scss';

interface Props {
    className?: string,
    children?: ReactNode,
    size?: string
};

/**
* Represents a Shadow.
* @constructor
* @param {string} className
* @param {string} size
* Usage :
* ````js
* <Shadow className='my-shadow' size='sm' />
* ````
* @augments {Component<Props, State>}
*/
/** */
const Shadow: React.FC<Props> = ({ className, children, size, ...props }: Props) => {
  return (
    <div
      className={
        `shadow 
        ${className || ''}
        shadow--${size ? `${size}` : ''}`
      }
      {...props}
    >
      {children}
    </div>
  );
};

export default Shadow;
