import React from 'react';
import { Typography, Box } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel:React.FC<TabPanelProps> = ({ children, value, index, ...props }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...props}
  >
    <Box>{children}</Box>
  </Typography>
);

export default TabPanel;
