import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import { FilterSearchBar } from 'blocks/components';

// ACTIONS

// SELECTORS
import { getEventsSearchString } from 'store/modules/events/events.selector';
import { setEventsSearchString, setSortedEvents, toogleFilter } from 'store/modules/events/events.slice';

const FilterSearchEvents = () => {
  const dispatch = useDispatch();
  const searchString = useSelector(getEventsSearchString);

  // EVENTS
  const handleOpenFilter = () => {
    dispatch(toogleFilter());
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEventsSearchString(event.target.value));
  };

  // RENDERS
  const actionList = [
    {
      content: 'Event ID',
      onClick: () => {
        dispatch(setSortedEvents({ sortKey: 'eventId' }));
      }
    },
    {
      content: 'ID',
      onClick: () => {
        dispatch(setSortedEvents({ sortKey: 'id' }));
      }
    },
    {
      content: 'Batch ID',
      onClick: () => {
        dispatch(setSortedEvents({ sortKey: 'batchId' }));
      }
    },
    {
      content: 'Code',
      onClick: () => {
        dispatch(setSortedEvents({ sortKey: 'code' }));
      }
    },
    {
      content: 'Ticket',
      onClick: () => {
        dispatch(setSortedEvents({ sortKey: 'ticket' }));
      }
    },
    {
      content: 'Created',
      onClick: () => {
        dispatch(setSortedEvents({ sortKey: 'created' }));
      }
    },
    {
      content: 'Assigned',
      onClick: () => {
        dispatch(setSortedEvents({ sortKey: 'assigned' }));
      }
    },
    {
      content: 'Status',
      onClick: () => {
        dispatch(setSortedEvents({ sortKey: 'status' }));
      }
    }
  ];

  return (
    <FilterSearchBar
      searchPlaceholder='Search exceptions'
      sortOptions={actionList}
      onSearch={handleSearch}
      searchString={searchString}
      onOpenFilter={handleOpenFilter}
    />
  );
};

export default FilterSearchEvents;
