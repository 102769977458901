import React, { Fragment, ReactNode } from 'react';
import classnames from 'classnames';
import { Typography } from '@mui/material';

// ASSETS
import './text.scss';
import { MetronomeColor } from '../constants';

type TextAppearance = 'display-lg' | 'display-md' | 'display-sm' | 'headline-lg' | 'headline-md' | 'headline-sm' | 'title-lg' | 'title-md' | 'title-sm' | 'body-lead' | 'body-xl' | 'body-lg' | 'body-md' | 'body-sm' | 'body-xs';

interface TextProps {
  align?: 'center'
  | 'inherit'
  | 'justify'
  | 'left'
  | 'right',
  children?: ReactNode,
  className?: string,
  color?: MetronomeColor | 'default',
  gutterBottom?: boolean,
  noWrap?: boolean,
  paragraph?: boolean,
  appearance?: TextAppearance,
  variant?: 'body1'
  | 'body2'
  | 'button'
  | 'caption'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'inherit'
  | 'overline'
  | 'subtitle1'
  | 'subtitle2',
  variantMapping?: object,
};
/**
* Represents a Text.
* @constructor
* @param {string} align
* @param {string} children
* @param {string} className
* @param {string} color
* @param {boolean} gutterBottom
* @param {boolean} noWrap
* @param {boolean} paragraph
* @param {string} variant
* @param {string} variantMapping
* Usage :
* ````js
* <Text align='center' className='my-text' copy='My Text' ect.. />
* ````
* @augments {Component<Props, State>}
*/
/** */
const Text: React.FC<TextProps> = ({
  align,
  children,
  className = '',
  color = 'default',
  gutterBottom = false,
  noWrap = false,
  paragraph = false,
  appearance,
  variant = 'body1',
  variantMapping
}: TextProps) => {
  const cssText = classnames(className, `text--${color}`, {
    'display-heading--sm': appearance === 'display-sm',
    'display-heading--md': appearance === 'display-md',
    'display-heading--lg': appearance === 'display-lg',
    'headline--lg': appearance === 'headline-lg',
    'headline--md': appearance === 'headline-md',
    'headline--sm': appearance === 'headline-sm',
    'title--lg': appearance === 'title-lg',
    'title--md': appearance === 'title-md',
    'title--sm': appearance === 'title-sm',
    'body--lead': appearance === 'body-lead',
    'body--lg': appearance === 'body-lg',
    'body--xl': appearance === 'body-xl',
    'body--md': appearance === 'body-md',
    'body--sm': appearance === 'body-sm',
    'body--xs': appearance === 'body-xs'
  });

  return (
    <Fragment>
      <Typography
        align={align}
        className={cssText}
        gutterBottom={gutterBottom}
        noWrap={noWrap}
        paragraph={paragraph}
        variant={variant}
        variantMapping={variantMapping}
      >
        {children}
      </Typography>
    </Fragment>
  );
};

export default Text;
