import React from 'react';
import Highcharts from 'highcharts';
import HighchartsXRange from 'highcharts/modules/xrange';
import HighchartsReact from 'highcharts-react-official';
import colors from '../../style/colors';

HighchartsXRange(Highcharts);

interface OverlapTimeChartProps {
  series: any;
  width?: number | string | null;
  height?: number | string | null;
  yAxis?: Highcharts.YAxisOptions;
  xAxis?: Highcharts.XAxisOptions;
  chart?: Highcharts.ChartOptions;
  legend?: Highcharts.LegendOptions;
  title?: Highcharts.TitleOptions;
  tooltip?: Highcharts.TooltipOptions;
}

const OverlapTimeChart:React.FC<OverlapTimeChartProps> = ({ series, height, width, yAxis, xAxis, chart, legend, title, tooltip }) => {
  const options = {
    chart: {
      type: 'xrange',
      backgroundColor: colors.aspen,
      plotBackgroundColor: colors.aspen,
      marginLeft: 24,
      marginRight: 24,
      // marginTop: 0,
      marginBottom: 0,
      spacingTop: 24,
      height,
      width,
      ...chart
    },
    title: {
      text: '',
      align: 'center',
      ...title
    },
    accessibility: {
      enabled: false
    },
    subtitle: {
      text: ''
    },
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false,
      ...legend
    },
    xAxis: {
      opposite: true,
      lineWidth: 0,
      tickWidth: 0,
      title: {
        text: null
      },
      type: 'datetime',
      min: Date.UTC(2023, 5, 7, 0, 0, 0),
      max: Date.UTC(2023, 5, 7, 23, 59, 59),
      tickInterval: 3 * 3600 * 1000,
      labels: {
        staggerLines: 1,
        style: {
          color: colors.stone,
          fontSize: '14px',
          fontFamily: 'Aktifo-B, sans-serif'
        },
        formatter: function (this: Highcharts.AxisLabelsFormatterContextObject): string {
          return Highcharts.dateFormat('%k', Number(this.value));
        }
      },
      dateTimeLabelFormats: {
        hour: '%k'
      },
      ...xAxis
    },
    yAxis: {
      title: {
        text: null
      },
      visible: false,
      ...yAxis
    },
    tooltip: {
      ...tooltip
    },
    plotOptions: {
      xrange: {
        borderRadius: 8
      }
    },
    series
  };

  return (
    <HighchartsReact highcharts={Highcharts} options={options} />
  );
};

export default OverlapTimeChart;
