import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthorizationClient } from '../components/Auth/AuthorizationClient';

import Link from '../components/Link/Link';
import Login from '../components/Login/Login';
import ClientQuote from '../components/ClientQuote/ClientQuote';
import VideoBackground from '../components/VideoBackground/VideoBackground';

import './login-page.scss';
import mockUsers from '../json/mock_users.json';
import loginPageCopy from '../json/login_page_text.json';

export interface LoginProperties {
  metronomeDemoMode?: boolean;
};

const LoginPage: React.FC = (props: LoginProperties) => {
  const {
    metronomeDemoMode
  } = props;
  const navigate = useNavigate();
  const [pageState, setPageState] = useState('username');
  const [loginState, setLoginState] = useState('email');
  const [userEmail, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailInputState, setEmailInputState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Invalid Email');

  const [profile, setProfile] = useState({
    username: '',
    role: '',
    profileImage: '',
    landing: '',
    product: ''
  });
  const {
    byLine,
    copy,
    logoImg,
    logoAlt,
    jobTitle
  } = loginPageCopy.quote;
  const errorCopy = {
    userErrorMessage: 'User does not exist',
    emailErrorMessage: 'Invalid email'
  };
  const handlePwSubmit = () => {
    setPageState('login');
    setLoginState('submit');
    setTimeout(() => navigate(`${profile.landing}?email=${profile.username}`), 800);
    localStorage.setItem('userData', JSON.stringify(profile));
  };
  const mp4Video = 'https://gridx-video-sharing.s3.us-west-2.amazonaws.com/seafoam_sparkle_2000x2000.mp4';
  const webm = 'https://gridx-video-sharing.s3.us-west-2.amazonaws.com/seafoam_sparkle_2000x2000.webm';
  const checkIfUserExist = async () => {
    // TODO - this code should really be in the authentication service to do a lookup:
    // A) To see if the user exists or not (to start auth process).
    // B) To see if the users domain (sce.com) is an authenticatable party.
    // For simplicity and demo sake, the code is here.
    const userEmailSplit = userEmail.split('@');
    const userEmailDomain = userEmailSplit[1];
    let userEmailDomainKey = userEmailDomain.split('.')[0];
    // this only check users email domain
    const filterPersonas = mockUsers.filter((persona: { username: string; }) => persona.username.includes(userEmailDomain));

    const filterIfUserExist = mockUsers.filter((persona: { username: string; }) => persona.username.includes(userEmail));

    // CHECK IF USER EXIST AND IN METRONOME DEMO MODE
    if (metronomeDemoMode && filterIfUserExist[0]) {
      const filterUser = filterPersonas.filter((user: {username: string;}) => user.username.includes(userEmailSplit[0]));
      setProfile(filterUser[0]);
      // If we return, it's because the client was not redirected. Thus we will prompt for user
      // password:
      setLoginState('pw');
    }

    // CHECK IF EMAIL DOMAIN IS ALLOWED AND NOT IN METRONOME MODE
    if (filterPersonas[0] && !metronomeDemoMode) {
      // Here we'd want to hit the Authentication service to verify the domain.
      // If we get a good response, we know we've got a domain user that is accepted.
      // For now, we'll assume it's a good user and redirect.
      if (userEmailDomainKey === 'gridx') {
        // Currently the authenticator has 'headway' google identity provider set up for OAuth.
        // Thus, we replace with headway.
        // Once proof of concept is accepted, we can enable GridX to have a proper authenticator.
        userEmailDomainKey = 'headway';
      }

      if (!metronomeDemoMode) {
        await AuthorizationClient.startAuthenticationRedirection(userEmailDomainKey, false, null);
      }
    } else {
      setEmailInputState(true);
      setErrorMessage(errorCopy.userErrorMessage);
    }
  };
  const handleEmailSubmit = () => {
    checkIfUserExist();
  };

  return (
    <div
      className={`login--page state--${pageState}`}
    >
      <Login
        additionalLink={<Link
          className='underline'
          color='sky'
          copy='Create account'
          icon={false}
          href='/'
        />}
        handlePwSubmit={() => handlePwSubmit()}
        loginState={loginState}
        handleEmailSubmit={() => handleEmailSubmit()}
        setErrorMessage={setErrorMessage}
        emailInputState={emailInputState}
        errorCopy={errorMessage}
        profile={profile}
        password={password}
        setPassword={setPassword}
        setLoginState={setLoginState}
        setUserEmail={setUserEmail}
        setEmailInputState={setEmailInputState}
        userEmail={userEmail}
      />
      <section
        className='login--container right--side graphic--lines-animate'
      >
        <VideoBackground
          poster='./images/login/seafoam_sparkle.png'
          mp4={mp4Video}
          webm={webm}
        />
        <ClientQuote
          byLine={byLine}
          loginState={pageState}
          logoAlt={logoAlt}
          logoImage={logoImg}
          jobTitle={jobTitle}
          quote={copy}
        />
      </section>
    </div>
  );
};

export default LoginPage;
