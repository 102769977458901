import { useQuery, useMutation, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
// HELPERS
import { useAxios } from './useAxios';
import { useContext } from 'react';
import { ActionTypes, ExploreContext } from '../helpers/ExploreContext';
import { Rate } from './projects.typings';
import { projectFord, projectHyundai, projectTesla } from './projectDetails.api.helpers';

interface QueryHooksArgs {
  projectId?: number;
}

export const useGetProjectDetail = ({ projectId }: QueryHooksArgs) => {
  const axiosInstance = useAxios();
  const { state, dispatch } = useContext(ExploreContext);

  return useQuery({
    queryKey: ['project-detail', projectId],
    refetchOnMount: false,
    queryFn: () => {
      return axiosInstance.get(`/api/projects/getProject/${projectId}`).then((res) => {
        // Data for DEMO purpose
        if (projectId === 256) {
          return projectHyundai;
        }

        if (projectId === 259) {
          return projectTesla;
        }

        if (projectId === 255) {
          return projectFord;
        }

        return res.data;
      });
    },
    onSuccess: (data) => {
      const bestRateCode = data.compareInfo?.compareRatesDetails.rateDetails.find((rate: any) => rate.bestRate);

      if (data.compareInfo) {
        const selectedRate = data.compareInfo.rateDetails.find((rate: Rate) => rate.ratePlanCode === data.rateCode);
        const appliedResource = state.appliedResource || [];

        dispatch({
          type: ActionTypes.SET_SELECTED_RATE,
          payload: selectedRate
        });

        dispatch({
          type: ActionTypes.SET_APPLIED_RATE_CODE,
          payload: selectedRate?.ratePlanCode
        });

        dispatch({
          type: ActionTypes.SET_BEST_RATE_CODE,
          payload: bestRateCode.ratePlanCode
        });

        dispatch({ type: ActionTypes.SET_APPLIED_RESOURCE, payload: [...appliedResource, ...data.resources] });

        dispatch({
          type: ActionTypes.UPDATE_APP_CACHE,
          payload: {
            rateDetails: data.compareInfo.rateDetails,
            compareRatesDetails: data.compareInfo.compareRatesDetails,
            projectResources: appliedResource || data.resources,
            costSavings: state.cache.costSavings || data.savingsPerYear
          }
        });
      }
    },
    enabled: !!projectId,
    refetchOnWindowFocus: false
  });
};

export const useSaveProject = () => {
  const axiosInstance = useAxios();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { dispatch } = useContext(ExploreContext);
  const { mutate: addProject } = useMutation({
    mutationFn: (projectData) => {
      return axiosInstance.post('api/projects/saveProject', projectData).then((res) => res.data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['project-detail', data.id]);
      queryClient.invalidateQueries(['create-project']);
      queryClient.refetchQueries({ queryKey: ['project-list'], exact: false });
      navigate(`/explore?project&projectID=${data.id}`);
      dispatch({ type: 'SET_PROJECT_MODE', payload: 'EDIT' });
    }
  });
  return { addProject };
};

export interface EvVehicle {
  atvType: string;
  make: string;
  model: string;
  year: string;
  mpge: string;
  pluginRange: string;
}

export interface ChargingPower {
  id: number;
  name: string;
  value: string;
  active: boolean;
}

export type EvMetadata = {
  chargingPowerList: ChargingPower[];
  evVehicles: EvVehicle[];
};

interface EvMetadataQueryArgs {
  enabled?: boolean;
}

export const useEvMetadata = ({ enabled = true }: EvMetadataQueryArgs): UseQueryResult<EvMetadata, unknown> => {
  const axiosInstance = useAxios();

  return useQuery({
    queryKey: ['ev-metadata'],
    queryFn: () => {
      return axiosInstance.get('/api/resources/evMetadata').then((res) => {
        return res.data;
      });
    },
    enabled,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
};

export const useDeleteProject = ({ projectId }: QueryHooksArgs) => {
  const axiosInstance = useAxios();
  const { dispatch } = useContext(ExploreContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: deleteProject, isLoading: isDeletingProject } = useMutation({
    mutationFn: () => {
      return axiosInstance.delete(`/api/projects/${projectId}`, {}).then((res) => res.data);
    },
    onSuccess: () => {
      navigate('/explore');
      dispatch({ type: ActionTypes.TOGGLE_PROJECT_DELETE, payload: '' });
      queryClient.refetchQueries({ queryKey: ['project-list'], exact: false });
    }
  });

  return { deleteProject, isDeletingProject };
};
