import React from 'react';

import Avatar from '../components/Avatar/Avatar';
import Tag from '../components/Tag/Tag';
import Text from '../components/Text/Text';
import { TagColor } from 'components/Tag/Tag.helpers';

export const RenderUsersList = (users: any) => {
  const usersList = users.map((user:any) => {
    const {
      createDate,
      firstName,
      lastName,
      email,
      role
    } = user;
      //   need to define final colors for roles
    const tagColorByRole = (): any => {
      let color = 'sheen';

      if (role === 'customer-admin') {
        color = 'pollen';
      }

      return color as TagColor;
    };

    return (
      <div
        className='
          bor--1-mist
          bor--r-6
          shadow--sm
          flex flex--align-center
          pad--l-24
          pad--r-24
          pad--t-16
          pad--b-16
          mar--b-8'
        key={`${createDate}${email}${firstName}`} // TODO switch out to user ID from API
      >
        <div
          className='flex flex--align-center width--30-p'
        >
          <Avatar
            className='mar--r-24'
            alt={`${firstName} ${lastName}`}
            size='xs'
          />
          <Text
            className='body--lg text--bold ellipsis'
            variant='body1'
          >
            {`${firstName} ${lastName}`}
          </Text>
        </div>
        <div
          className='width--30-p'
        >
          <Text
            className='body--lg ellipsis'
            variant='body1'
          >
            {email}
          </Text>
        </div>
        <div
          className='flex jus--end width--40-p'
        >
          {role
            ? (
              <Tag
                color={tagColorByRole()}
                copy={role}
                size='sm'
              />
            )
            : (
          // NEED TO ADD ROLE TO API
              <Tag
                color={tagColorByRole()}
                copy='admin'
                size='sm'
              />
            )}
        </div>
      </div>
    );
  });

  return usersList;
};
