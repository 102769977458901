import React from 'react';

// ASSETS
import styles from './VirtualMeterInfo.module.scss';
import { InfoItem } from 'blocks/components';
import { Tag } from 'components';
import { IMeterAccount } from 'api/accounts/accounts.typings';

interface VirtualMeterInfoProps {
  meterAccount: IMeterAccount
}

const VirtualMeterInfo:React.FC<VirtualMeterInfoProps> = ({ meterAccount }) => {
  return (
    <div className={styles['info-details']}>
      <div className={styles['info-box']}>
        <InfoItem title="Status" description={<Tag copy={meterAccount.status} size="sm" color="stem" />} />
      </div>
      <div className={styles['info-box']}>
        <InfoItem title="Meter ID" description={meterAccount.meterId} />
      </div>
      <div className={styles['info-box']}>
        <InfoItem title="Service Agreement" description={meterAccount.serviceAgreement} />
      </div>
      <div className={styles['info-box']}>
        <InfoItem title="Billing Cycle" description={meterAccount.billCycle} />
      </div>
    </div>
  );
};

export default VirtualMeterInfo;
