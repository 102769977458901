import React from 'react';
import {
  Column,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFacetedUniqueValues,
  SortingState,
  useReactTable,
  getFilteredRowModel,
  ColumnPinningState
} from '@tanstack/react-table';

// COMPONENTS
import { ColumnHeader, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './components';

interface DataTableProps<TData, TValue> {
  columns: Column<TData, TValue>[];
  data: TData[];
  columnPinning?: ColumnPinningState;
}

const DataTable = <TData, TValue>({ columns, data, columnPinning = {} }: DataTableProps<TData, TValue>) => {
  const [rowSelection, setRowSelection] = React.useState({});
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable<TData>({
    data,
    columns,
    state: {
      sorting,
      columnPinning,
      rowSelection
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues()
    // debugTable: true
  });

  return (
    <Table>
      <TableHeader>
        {table.getHeaderGroups().map(headerGroup => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              return (
                <TableHead key={header.id} colSpan={header.colSpan} column={header.column}>
                  {header.isPlaceholder
                    ? null
                    : (
                      <ColumnHeader column={header.column}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </ColumnHeader>
                    )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table
          .getRowModel()
          .rows.slice(0, 10)
          .map(row => {
            return (
              <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <TableCell key={cell.id} column={cell.column}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

export default DataTable;
