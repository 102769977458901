import React from 'react';
import Highcharts from 'highcharts';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import HighchartsReact from 'highcharts-react-official';

import colors from '../../style/colors';

// HELPERS
import { useTheme } from '../../hooks/Theme/Theme.context';
import { Color, ColorCode } from 'hooks/Theme/Theme.model';

HighchartsHeatmap(Highcharts);

interface HeatMapChartProps {
  accessibility?: Highcharts.AccessibilityOptions;
  backgroundColor?: string | null;
  series: any;
  width?: number | string | null;
  height?: number | string | null;
  yAxis?: Highcharts.YAxisOptions;
  xAxis?: Highcharts.XAxisOptions;
  chart?: Highcharts.ChartOptions;
  legend?: Highcharts.LegendOptions;
  plotBackgroundColor?: string | null;
  plotOptions?: object | null;
  title?: Highcharts.TitleOptions;
  tooltip?: Highcharts.TooltipOptions;
  responsive?: Highcharts.ResponsiveOptions;
}

const HeatMapChart:React.FC<HeatMapChartProps> = ({
  accessibility,
  backgroundColor,
  series,
  chart,
  title,
  tooltip,
  xAxis,
  yAxis,
  responsive
}) => {
  const { getThemeColor } = useTheme();

  const options = {
    chart: {
      backgroundColor: backgroundColor || getThemeColor(Color.LightGray, ColorCode.C100, colors.white),
      type: 'heatmap',
      style: {
        fontFamily: 'Aktifo-B-Medium',
        color: colors.black
      },
      ...chart
    },

    title: {
      text: '',
      ...title
    },

    xAxis: {
      lineWidth: 0,
      gridLineColor: colors.white,
      labels: {
        style: {
          borderColor: colors.white,
          color: colors.stone,
          fontSize: '12px',
          fontFamily: 'Aktifo-B-Medium'
        }
      },
      ...xAxis
    },

    yAxis: {
      gridLineColor: colors.white,
      title: null,
      reversed: false,
      lineColor: colors.white,
      borderColor: colors.white,
      labels: {
        style: {
          color: colors.stone,
          fontSize: '12px',
          fontFamily: 'Aktifo-B-Medium'
        }
      },
      ...yAxis
    },

    accessibility: {
      ...accessibility
    },

    credits: {
      enabled: false
    },

    colorAxis: {
      min: 0,
      minColor: colors.white,
      maxColor: colors.magma
    },

    legend: {
      enabled: false
    },

    tooltip: {
      enabled: false,
      ...tooltip
    },

    series: [{
      borderWidth: 8,
      borderRadius: 25,
      borderColor: colors.white,
      colorByPoint: true,
      ...series
    }],

    responsive: {
      rules: [{
        condition: {
          maxWidth: 500
        }
      }],
      ...responsive
    }
  };
  return (
    <HighchartsReact highcharts={Highcharts} options={options} />
  );
};

export default HeatMapChart;
