import { useContext } from 'react';
import { ExploreContext } from 'widget/ExploreWidget/helpers/ExploreContext';
import { useGetProjectDetail } from 'widget/ExploreWidget/api/projectDetail.api';
import { useCreateProject } from 'widget/ExploreWidget/api/projects.api';

const useProjectData = () => {
  const { state } = useContext(ExploreContext);
  const projectID = Number(new URLSearchParams(location.search).get('projectID')) || undefined;

  return state.projectMode === 'NEW'
    ? useCreateProject({ contractId: state.contractId })
    : useGetProjectDetail({ projectId: projectID });
};

export default useProjectData;
