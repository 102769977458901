import React, { Fragment } from 'react';

import Image from '../../components/Image/Image';
import Text from '../../components/Text/Text';

import './client-quote.scss';

interface Props {
    byLine?: string,
    className?: string,
    loginState?: string,
    logoAlt?: string,
    logoImage?: string,
    jobTitle?: string,
    quote?: string,
};

/**
* Represents a ClientQuote.
* @constructor
* @param {string} byLine
* @param {string} className
* @param {string} loginState
* @param {string} logoAlt
* @param {string} logoImage
* @param {string} jobTitle
* @param {string} quote
* Usage :
* ````js
* <ClientQuote className='my--client-quote' />
* ````
* @augments {Component<Props, State>}
*/
/** */
const ClientQuote: React.FC<Props> = ({
  byLine,
  className,
  loginState,
  logoAlt,
  logoImage,
  jobTitle,
  quote
}: Props) => {
  const login = 'login';

  return (
    <>
      <div
        className={`
        ${className}
        quote--container
        flex
        flex--align-center
        jus--center
        `}
      >
        <div>
          <Text
            className='body--xl text--left'
            color='white'
            variant='body1'
          >
            {quote}
          </Text>
          <div
            className='flex mar--t-24'
          >
            <div className='text--left'>
              <Text
                className='headline--sm'
                color='white'
                variant='body1'
              >
                {byLine}
              </Text>
              {jobTitle
                ? (
                  <Text
                    className='body--lg'
                    color='white'
                    variant='body1'
                  >
                    {jobTitle}
                  </Text>
                )
                : ''}
            </div>
            <Image
              className='mar--t-24'
              alt={logoAlt}
              src={logoImage}
            />
          </div>
        </div>
      </div>
      {loginState === login
        ? (
          <div
            aria-hidden='true'
            className='graphic--container'
          >
            <div>
              <div className='graphic--lines-container'>
                <Image
                  className='graphic--lines lines--top'
                  alt='graphic-2'
                  src='../../images/login/circuit.svg'
                />
                <Image
                  className='graphic--lines lines--right'
                  alt='graphic-3'
                  src='../../images/login/circuit.svg'
                />
                <Image
                  className='graphic--lines lines--bottom'
                  alt='graphic-4'
                  src='../../images/login/circuit.svg'
                />
                <Image
                  className='graphic--lines lines--left'
                  alt='graphic-5'
                  src='../../images/login/circuit.svg'
                />
              </div>
            </div>
            <div>
              <div className="graphic--logo-container">
                <Image
                  className='graphic--logo r--top'
                  alt='graphic-6'
                  src='../../images/login/gridx_white_logo.svg'
                />
              </div>
              <div className="graphic--logo-container logo-container-right">
                <Image
                  className='graphic--logo r--right'
                  alt='graphic-6'
                  src='../../images/login/gridx_white_logo.svg'
                />
              </div>
              <div className="graphic--logo-container logo-container-bottom">
                <Image
                  className='graphic--logo r--bottom'
                  alt='graphic-6'
                  src='../../images/login/gridx_white_logo.svg'
                />
              </div>
              <div className="graphic--logo-container logo-container-left">
                <Image
                  className='graphic--logo r--left'
                  alt='graphic-6'
                  src='../../images/login/gridx_white_logo.svg'
                />
              </div>
            </div>
          </div>
        )
        : null}
    </>
  );
};

export default ClientQuote;
