/* eslint-disable no-unused-vars */
export enum Color {
  Quinary = 'quinary',
  Quaternary = 'quaternary',
  Tertiary = 'tertiary',
  Secondary = 'secondary',
  Primary = 'primary',
  LightGray = 'lightGray',
  Gray = 'gray',
  // Add other color variants as needed
}

export enum ColorCode {
  Main = 'main',
  C100 = 'C100',
  C200 = 'C200',
  C300 = 'C100',
  // Add other code variants as needed
}
/* eslint-enable no-unused-vars */

interface ColorSchema {
  main: string,
  C100?: string,
  C200?: string,
  C300?: string,
  [key: string]: string | undefined
}

export interface Theme {
  text?: {
    display?: string,
    displaySubtle?: string,
    brand?: string,
    info?: string,
    success?: string,
    error?: string,
    invert?: string,
    link?: string
  },
  background?: {
    display?: string,
    displaySubtle?: string,
    brand?: string,
    info?: string,
    success?: string,
    warning?: string,
    error?: string,
    invert?: {
      display?: string,
      brand?: string,
      info?: string,
      success?: string,
      warning?: string,
      error?: string,
    },
    button?: {
      primary?: string,
      primaryHover?: string,
      secondary?: string,
      secondaryHover?: string,
    },
  },
  button?: {
    radius?: {
      sm?: string | number,
      md?: string | number
    },
    color?: {
      primary?: {
        filled?: {
          background?: string,
          'background-hover'?: string,
          text?: string,
          'text-disabled'?: string
        },
        outlined?: {
          border?: string,
          'border-hover'?: string,
          'border-disabled'?: string,
          text?: string,
          'text-disabled'?: string
        },
        ghost?: {
          text?: string,
          'text-hover'?: string,
          'text-disabled'?: string
        }
      },
      secondary?: {
        filled?: {
          background?: string,
          'background-hover'?: string,
          'background-disabled'?: string,
          text?: string,
          'text-disabled'?: string
        },
        outlined?: {
          border?: string,
          'border-disabled'?: string,
          text?: string
        },
        ghost?: {
          text?: string,
          'text-hover'?: string,
          'text-disabled'?: string
        }
      },
      tertiary?: {
        filled?: {
          background?: string,
          'background-hover'?: string,
          'background-disabled'?: string,
          text?: string,
          'text-disabled'?: string
        },
        outlined?: {
          border?: string,
          'border-disabled'?: string,
          text?: string
        },
        ghost?: {
          text?: string,
          'text-hover'?: string,
          'text-disabled'?: string
        }
      }
    }
  },
  border?: {
    brand?: string,
    display?: string,
    info?: string,
    success?: string,
    warning?: string,
    error?: string,
    input?: string,
    inputHover?: string,
  },
  color?: {
    text?: {
      display?: string,
      'display-variant'?: string,
      invert?: string,
      brand?: string,
      info?: string,
      success?: string,
      warning?: string,
      error?: string
    },
    background?: {
      display?: string,
      'display-bright'?: string,
      'display-dim'?: string,
      'display-dark'?: string,
      'display-neutral'?: string,
      brand?: string,
      'brand-variant'?: string,
      info?: string,
      success?: string,
      warning?: string,
      error?: string,
      invert?: {
        display?: string,
        brand?: string,
        info?: string,
        success?: string,
        warning?: string,
        error?: string
      },
      accents?: {
        energy?: string,
        ocean?: string,
        clean?: string,
        mangrove?: string,
        reaction?: string,
        bright?: string
      }
    },
    border?: {
      display?: string,
      'display-variant'?: string,
      dark?: string,
      brand?: string,
      'brand-variant'?: string,
      info?: string,
      success?: string,
      warning?: string,
      error?: string,
      input?: string,
      'input-hover'?: string
    },
    link?: {
      default?: string
    },
    icon?: {
      display?: string,
      'display-variant'?: string,
      invert?: string,
      brand?: string,
      'brand-variant'?: string,
      info?: string,
      success?: string,
      warning?: string,
      error?: string
    },
    chart?: {
      background?: string,
      'background-bright'?: string,
      'background-dim'?: string,
      border?: string,
      brand?: string,
      'brand-variant'?: string,
      categorical?: {
        soft?: {
          '20'?: {
            '00'?: string,
            '01'?: string,
            '02'?: string,
            '03'?: string,
            '04'?: string,
            '05'?: string,
            '06'?: string,
            '07'?: string,
            '08'?: string,
            '09'?: string,
            '10'?: string,
            '11'?: string,
            '12'?: string,
            '13'?: string,
            '14'?: string,
            '15'?: string,
            '16'?: string,
            '17'?: string,
            '18'?: string,
            '19'?: string,
            '20'?: string
          }
        }
      },
      progression?: {
        soft?: {
          '20'?: {
            '00'?: string,
            '01'?: string,
            '02'?: string,
            '03'?: string,
            '04'?: string,
            '05'?: string,
            '06'?: string,
            '07'?: string,
            '08'?: string,
            '09'?: string,
            '10'?: string,
            '11'?: string,
            '12'?: string,
            '13'?: string,
            '14'?: string,
            '15'?: string,
            '16'?: string,
            '17'?: string,
            '18'?: string,
            '19'?: string,
            '20'?: string
          }
        },
        sharp?: {
          '10'?: {
            '00'?: string,
            '01'?: string,
            '02'?: string,
            '03'?: string,
            '04'?: string,
            '05'?: string,
            '06'?: string,
            '07'?: string,
            '08'?: string,
            '09'?: string,
            '10'?: string
          }
        },
        sheer?: {
          '10'?: {
            '00'?: string,
            '01'?: string,
            '02'?: string,
            '03'?: string,
            '04'?: string,
            '05'?: string,
            '06'?: string,
            '07'?: string,
            '08'?: string,
            '09'?: string,
            '10'?: string
          }
        }
      },
      accents?: {
        bright?: {
          softer?: string,
          soft?: string,
          sharp?: string,
          sheer?: string
        },
        clean?: {
          soft?: string,
          sharp?: string,
          sharper?: string,
          sheer?: string
        },
        energy?: {
          softer?: string,
          soft?: string,
          sharp?: string,
          sheer?: string
        },
        mangrove?: {
          soft?: string,
          sharp?: string,
          sharper?: string,
          sheer?: string
        },
        reaction?: {
          softer?: string,
          soft?: string,
          sharp?: string,
          sheer?: string
        }
      }
    }
  },
  radius?: {
    xsm?: string | number,
    sm?: string | number,
    md?: string | number,
    lg?: string | number,
  },
  app?: {
    background?: {
      color?: string
    }
  },
  font?: {
    sans?: string,
    sansBold?: string,
    sansSemi?: string
    families?: string[],
  },
  palette?: {
    [key: string]: ColorSchema | undefined
    primary?: ColorSchema,
    secondary?: ColorSchema,
    tertiary?: ColorSchema,
    quaternary?: ColorSchema,
    quinary?: ColorSchema,
    senary?: ColorSchema,
    septenary?: ColorSchema
    lightGray?: ColorSchema,
    gray?: ColorSchema,
  },
  typography?: {
    fontFamily?: string,
  },
  brand?: {
    brandName: string,
    brandLogo: string,
    logoWidth: number,
    slug?: string,
  },
  shadows?: {
    0: string,
    1: string,
  },
  explore?: {
    app?: {
      background?: string
    }
  }
}
