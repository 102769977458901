import * as React from 'react';

import colors from '../../../style/colors';

const KAM = (props:any) => {
  const { color, showBrandName, showGridX } = props;

  let renderColor;
  switch (color) {
  case 'deep':
    renderColor = colors.deep;
    break;
  case 'energy':
    renderColor = colors.energy;
    break;
  case 'white':
    renderColor = colors.white;
    break;
  }

  if (showGridX) {
    return (
      <svg
        width={338}
        height={134}
        viewBox="0 0 338 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M57.9585 133.999C50.3466 134.022 42.8059 132.534 35.7734 129.621C28.7409 126.708 22.3565 122.428 16.9902 117.03L30.7716 103.249C34.365 106.85 38.639 109.7 43.3448 111.634C48.0505 113.567 53.0939 114.545 58.1812 114.51L58.2787 133.999H57.9585Z"
          fill={renderColor || '#D4E9EC'}
        />
        <path
          d="M102.864 30.7647C99.3005 27.1803 95.0617 24.3381 90.3927 22.4027C85.7238 20.4672 80.7173 19.4769 75.6631 19.489V0.000212631C83.2779 -0.0204841 90.8212 1.46993 97.856 4.38514C104.891 7.30036 111.277 11.5824 116.645 16.9833L102.864 30.7647Z"
          fill={renderColor || '#D4E9EC'}
        />
        <path
          d="M27.9185 23.7911L34.489 30.988L41.0595 38.171C47.3711 32.4168 55.428 28.9421 63.9449 28.3013V8.8125C50.5601 9.46604 37.8212 14.7625 27.9185 23.7911Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M27.9174 23.7905C27.2632 24.3752 26.6228 24.9877 25.9825 25.628C15.1322 36.5072 9.03906 51.2452 9.03906 66.6102C9.03906 81.9752 15.1322 96.7132 25.9825 107.592L39.7639 93.811C32.5621 86.5904 28.5178 76.8084 28.5178 66.6102C28.5178 56.412 32.5621 46.6301 39.7639 39.4094C40.1815 38.9918 40.613 38.5742 41.0585 38.1844L34.488 30.9874L27.9174 23.7905Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M107.989 25.6282L94.2074 39.4096C99.3236 44.5297 102.892 50.9891 104.502 58.0458C106.112 65.1025 105.7 72.4703 103.311 79.303C100.923 86.1357 96.6552 92.1561 90.9992 96.6726C85.3431 101.189 78.5277 104.019 71.3359 104.836V124.325C82.3981 123.497 92.9901 119.511 101.854 112.842C110.718 106.172 117.481 97.0979 121.341 86.6979C125.2 76.2979 125.994 65.0087 123.627 54.171C121.26 43.3334 115.833 33.4028 107.989 25.5586V25.6282Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M50.9693 67.1118L67.145 50.9361L72.4627 56.2816L86.2162 42.528C80.8598 38.3333 74.2547 36.0499 67.4512 36.041H67.3677C61.6364 36.0374 56.0196 37.6459 51.1589 40.6826C46.2982 43.7193 42.3896 48.0619 39.8794 53.2143C37.3693 58.3667 36.3588 64.1211 36.9635 69.8205C37.5682 75.5198 39.7636 80.9342 43.299 85.4452L56.2869 72.4573L50.9693 67.1118Z"
          fill={renderColor || '#FFA44F'}
        />
        <path
          d="M91.5072 47.7065L77.712 61.5018L83.2802 67.0701L67.1462 83.2875L61.578 77.7193L48.5483 90.7351C54.4382 95.3489 61.8134 97.647 69.2816 97.1956C76.7498 96.7442 83.7945 93.5746 89.0858 88.2849C94.3771 82.9953 97.5491 75.9517 98.0029 68.4836C98.4567 61.0155 96.1609 53.6397 91.549 47.7483L91.5072 47.7065Z"
          fill={renderColor || '#FFA44F'}
        />
        <path
          d="M211.752 113.944H199.224L177.264 89.9684V114.016H167.688V61.8164H177.264V85.0004L198.936 61.8164H211.032L186.696 86.9444L211.752 113.944Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M253.75 114.016L249.79 103.288H227.11L223.15 114.016H212.782L233.302 61.8164H243.31L264.478 114.016H253.75ZM229.918 95.8004H246.982L238.414 72.7604L229.918 95.8004Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M333.892 114.016H324.172L319.132 71.8964L306.388 114.016H296.02L283.276 71.0324L278.02 114.016H268.516L275.212 61.8164H289.252L301.42 103.936L314.164 61.8164H327.484L333.892 114.016Z"
          fill={renderColor || '#344145'}
        />
        <g clipPath="url(#clip0_9122_8335)">
          <path
            d="M208.414 9.94043H203.654V29.35H208.414V9.94043Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M208.414 3.42627H203.654V7.55243H208.414V3.42627Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M233.159 1.5166V29.35H228.957L228.578 26.4555C227.219 28.4614 224.424 29.7216 221.1 29.7216C215.131 29.7216 211.278 25.6396 211.278 19.7024C211.278 13.5781 215.432 9.56886 221.478 9.56886C224.347 9.56886 226.878 10.5328 228.391 12.2399V1.5166H233.159ZM228.399 19.9258V19.4789C228.399 15.8412 225.905 13.5027 222.239 13.5027C218.463 13.5027 216.123 15.9504 216.123 19.6244C216.123 23.4466 218.577 25.7851 222.205 25.7851C225.833 25.7851 228.399 23.5999 228.399 19.9258Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M241.637 19.1491L234.465 9.94057H240.376L244.94 15.9505C245.02 16.0544 245.124 16.1387 245.243 16.1967C245.362 16.2547 245.493 16.2849 245.625 16.2849C245.758 16.2849 245.889 16.2547 246.008 16.1967C246.126 16.1387 246.23 16.0544 246.31 15.9505L251.028 9.93018H256.772L249.486 19.1335C249.368 19.2817 249.304 19.4647 249.304 19.6532C249.304 19.8416 249.368 20.0246 249.486 20.1728L256.809 29.3372H250.991L246.26 23.4156C246.18 23.3136 246.077 23.2311 245.959 23.1743C245.841 23.1175 245.712 23.088 245.58 23.088C245.449 23.088 245.32 23.1175 245.202 23.1743C245.084 23.2311 244.981 23.3136 244.901 23.4156L240.249 29.3424H234.465L241.637 20.1702C241.751 20.0235 241.813 19.8441 241.813 19.6597C241.813 19.4752 241.751 19.2958 241.637 19.1491Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M198.883 9.94042L202.059 14.1545H195.696C195.212 14.1545 194.748 14.3434 194.406 14.6796C194.064 15.0158 193.872 15.6594 193.872 16.1349V29.35H189.204V15.8387C189.204 11.5384 191.661 9.93262 194.731 9.93262L198.883 9.94042Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M181.395 9.94026L181.093 12.6503C179.771 10.7951 177.127 9.57129 173.916 9.57129C168.098 9.57129 164.285 13.5779 164.285 19.1851C164.285 24.7508 168.101 28.76 173.916 28.76C176.939 28.76 179.62 27.5726 180.829 25.7174V28.6847C180.829 31.8027 178.488 33.7333 174.974 33.7333C173.022 33.7333 171.488 33.0915 170.441 32.1743C170.275 32.0261 169.939 31.7455 169.939 31.7455L166.895 34.7856C167.158 35.0508 167.441 35.2966 167.741 35.5209C169.449 36.7967 171.859 37.5216 174.9 37.5216C181.395 37.5216 185.589 34.1074 185.589 28.5028V9.94026H181.395ZM180.829 19.3307C180.829 22.7449 178.224 24.9353 174.86 24.9353C171.234 24.9353 169.119 22.5214 169.119 19.1462C169.119 15.6566 171.385 13.3934 174.9 13.3934C178.261 13.3934 180.829 15.4721 180.829 18.8863V19.3307Z"
            fill={renderColor || '#344145'}
          />
        </g>
        <defs>
          <clipPath id="clip0_9122_8335">
            <rect
              width={99}
              height={39}
              fill="white"
              transform="translate(162 0.0166016)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (showBrandName) {
    return (
      <svg
        width={338}
        height={134}
        viewBox="0 0 338 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M57.9585 133.999C50.3466 134.022 42.8059 132.534 35.7734 129.621C28.7409 126.708 22.3565 122.428 16.9902 117.03L30.7716 103.249C34.365 106.85 38.639 109.7 43.3448 111.634C48.0505 113.567 53.0939 114.545 58.1812 114.51L58.2787 133.999H57.9585Z"
          fill={renderColor || '#D4E9EC'}
        />
        <path
          d="M102.864 30.7647C99.3005 27.1803 95.0617 24.3381 90.3927 22.4027C85.7238 20.4672 80.7173 19.4769 75.6631 19.489V0.000212631C83.2779 -0.0204841 90.8212 1.46993 97.856 4.38514C104.891 7.30036 111.277 11.5824 116.645 16.9833L102.864 30.7647Z"
          fill={renderColor || '#D4E9EC'}
        />
        <path
          d="M27.9185 23.7911L34.489 30.988L41.0595 38.171C47.3711 32.4168 55.428 28.9421 63.9449 28.3013V8.8125C50.5601 9.46604 37.8212 14.7625 27.9185 23.7911Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M27.9174 23.7905C27.2632 24.3752 26.6228 24.9877 25.9825 25.628C15.1322 36.5072 9.03906 51.2452 9.03906 66.6102C9.03906 81.9752 15.1322 96.7132 25.9825 107.592L39.7639 93.811C32.5621 86.5904 28.5178 76.8084 28.5178 66.6102C28.5178 56.412 32.5621 46.6301 39.7639 39.4094C40.1815 38.9918 40.613 38.5742 41.0585 38.1844L34.488 30.9874L27.9174 23.7905Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M107.989 25.6282L94.2074 39.4096C99.3236 44.5297 102.892 50.9891 104.502 58.0458C106.112 65.1025 105.7 72.4703 103.311 79.303C100.923 86.1357 96.6552 92.1561 90.9992 96.6726C85.3431 101.189 78.5277 104.019 71.3359 104.836V124.325C82.3981 123.497 92.9901 119.511 101.854 112.842C110.718 106.172 117.481 97.0979 121.341 86.6979C125.2 76.2979 125.994 65.0087 123.627 54.171C121.26 43.3334 115.833 33.4028 107.989 25.5586V25.6282Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M50.9693 67.1118L67.145 50.9361L72.4627 56.2816L86.2162 42.528C80.8598 38.3333 74.2547 36.0499 67.4512 36.041H67.3677C61.6364 36.0374 56.0196 37.6459 51.1589 40.6826C46.2982 43.7193 42.3896 48.0619 39.8794 53.2143C37.3693 58.3667 36.3588 64.1211 36.9635 69.8205C37.5682 75.5198 39.7636 80.9342 43.299 85.4452L56.2869 72.4573L50.9693 67.1118Z"
          fill={renderColor || '#FFA44F'}
        />
        <path
          d="M91.5072 47.7065L77.712 61.5018L83.2802 67.0701L67.1462 83.2875L61.578 77.7193L48.5483 90.7351C54.4382 95.3489 61.8134 97.647 69.2816 97.1956C76.7498 96.7442 83.7945 93.5746 89.0858 88.2849C94.3771 82.9953 97.5491 75.9517 98.0029 68.4836C98.4567 61.0155 96.1609 53.6397 91.549 47.7483L91.5072 47.7065Z"
          fill={renderColor || '#FFA44F'}
        />
        <path
          d="M211.752 88.9278H199.224L177.264 64.9518V88.9998H167.688V36.7998H177.264V59.9838L198.936 36.7998H211.032L186.696 61.9278L211.752 88.9278Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M253.75 88.9998L249.79 78.2718H227.11L223.15 88.9998H212.782L233.302 36.7998H243.31L264.478 88.9998H253.75ZM229.918 70.7838H246.982L238.414 47.7438L229.918 70.7838Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M333.892 88.9998H324.172L319.132 46.8798L306.388 88.9998H296.02L283.276 46.0158L278.02 88.9998H268.516L275.212 36.7998H289.252L301.42 78.9198L314.164 36.7998H327.484L333.892 88.9998Z"
          fill={renderColor || '#344145'}
        />
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 64 64"
        {...props}
      >
        <path
          fill={renderColor || '#D4E9EC'}
          d="M23.682 64a27.473 27.473 0 0 1-19.567-8.105l6.582-6.582a18.318 18.318 0 0 0 13.091 5.378L23.835 64h-.153ZM45.13 14.694a18.257 18.257 0 0 0-12.992-5.386V0A27.492 27.492 0 0 1 51.71 8.111l-6.582 6.583Z"
        />
        <path
          fill={renderColor || '#344145'}
          d="m9.334 11.363 3.138 3.437 3.139 3.431a18.257 18.257 0 0 1 10.93-4.714V4.21a27.532 27.532 0 0 0-17.207 7.154Z"
        />
        <path
          fill={renderColor || '#344145'}
          d="M9.334 11.363c-.313.279-.619.571-.924.877a27.718 27.718 0 0 0 0 39.147l6.582-6.582a18.397 18.397 0 0 1 0-25.983c.2-.2.405-.399.618-.585L12.472 14.8l-3.138-3.438ZM47.577 12.24l-6.582 6.582A18.378 18.378 0 0 1 30.07 50.071v9.308a27.678 27.678 0 0 0 17.506-47.172v.033Z"
        />
        <path
          fill={renderColor || '#FFA44F'}
          d="m20.343 32.053 7.726-7.725 2.54 2.553 6.569-6.57a14.568 14.568 0 0 0-8.962-3.097h-.04A14.594 14.594 0 0 0 16.68 40.81l6.203-6.204-2.54-2.553ZM39.705 22.785l-6.589 6.589 2.66 2.66-7.706 7.745-2.66-2.66-6.223 6.217a14.627 14.627 0 0 0 20.538-20.53l-.02-.02Z"
        />
      </svg>
    );
  }
};
export default KAM;
