import React from 'react';

// COMPONENTS
import StackedChart from '../../../../../../../../components/StackedChart/StackedChart';
import { costSampleData1, costSampleData2, costSampleData3 } from './RatePriceCharts.helpers';

interface RatePriceChartsProps {
  tdWidth: number
}

const RatePriceCharts:React.FC<RatePriceChartsProps> = ({ tdWidth }) => {
  return (
    <>
      <StackedChart
        width={tdWidth * 2}
        height={288}
        xAxis={{ categories: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'] }}
        series={costSampleData1}
        legend={{ margin: tdWidth - 260 }}
        yAxis={{ min: 0, tickInterval: 0.15, max: 0.5 }}
      />
      <StackedChart
        width={tdWidth}
        height={288}
        xAxis={{ categories: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'] }}
        yAxis={{ visible: false, min: 0, tickInterval: 0.15 }}
        chart={{ marginLeft: 0 }}
        legend={{ enabled: false }}
        series={costSampleData2}
      />
      <StackedChart
        width={tdWidth}
        height={288}
        xAxis={{ categories: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'] }}
        yAxis={{ visible: false, min: 0, tickInterval: 0.15 }}
        chart={{ marginLeft: 0 }}
        legend={{ enabled: false }}
        series={costSampleData3}
      /></>
  );
};

export default RatePriceCharts;
