import moment from 'moment';

export const generateTouData = (dateStart: moment.Moment, dateEnd: moment.Moment) => {
  const periodTouData = {
    peak: [] as number[],
    offPeak: [] as number[],
    generationOffPeak: [] as number[],
    generationPeak: [] as number[]
  };

  const difference = dateEnd.diff(dateStart, 'days');
  for (let i = 0; i < difference; i++) {
    periodTouData.peak.push(Number((Math.random() * 30).toFixed(2)));
    periodTouData.offPeak.push(Number((Math.random() * 100).toFixed(2)));
    periodTouData.generationOffPeak.push(Number((-Math.random() * 20).toFixed(2)));
    periodTouData.generationPeak.push(Number((-Math.random() * 10).toFixed(2)));
  }

  return periodTouData;
};

export const getPeriodArray = (billSelectForm: any) => {
  const periodArray = [];
  const dateStart = moment(billSelectForm.billRange[0]);
  const dateEnd = moment(billSelectForm.billRange[1]);
  while (dateEnd.diff(dateStart, 'days') >= 0) {
    periodArray.push(dateStart.format('YYYY-MM-DD'));
    dateStart.add(1, 'days');
  }
  return periodArray;
};
