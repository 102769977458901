import React from 'react';

// COMPONENTS
import Skeleton from 'components/Skeleton/Skeleton';

// ASSETS
import styles from '../../ProjectList.module.scss';
import Box from 'components/Box/Box';

const ProjectListPageLoading = () => {
  return (
    <div className={styles.container}>
      <Box mb={4.5}>
        <Skeleton width="100%" height={495}/>
      </Box>
      <div className={styles.startAction}>
        <Box mb={3}>
          <Skeleton width={220} height={42}/>
        </Box>
        <Skeleton width={461} height={24}/>
      </div>
      <div className={styles.calculations}>
        <Box mb={2}>
          <Skeleton width="100%" height={293}/>
        </Box>
        <Skeleton width="100%" height={293}/>
      </div>
    </div>
  );
};

export default ProjectListPageLoading;
