import React, { Fragment } from 'react';

import Header from '../../components/Header/Header';
import Divider from '../../components/Divider/Divider';
import Link from '../../components/Link/Link';
import Text from '../../components/Text/Text';

interface Props {
  color?: string
};

const DividerPage: React.FC = () => {
  const renderDividers: React.FC<Props> = ({
    color = 'stone'
  }) => {
    return (
      <Divider
        className='mar--r-8 mar--b-20'
        color={color as any}
      />
    );
  };

  return (
    <Fragment>
      <Header
        className='design-studio pad--b-100 pad--l-128 pad--t-100'
        color='stone'
        text='Divider'
      >
        <Text
          className='max-width--900'
          color='white'
        >
        </Text>
        <Link
          className='underline dis--block mar--t-60 body--xl'
          color='white'
          copy='See Our Guidelines'
          href='/'
        />

      </Header>
      <div
        className='mar--t-80 pad--l-128 max-width--lg  temp--min-width--sm'
      >
        <section
          className='pad--r-20 pad--l-20 max-width--sm'
        >
          {renderDividers({ color: 'stone' })}
          {renderDividers({ color: 'ocean' })}
          {renderDividers({ color: 'wave' })}
        </section>
        <section
          className='pad--t-20 pad--r-20 pad--b-20 pad--l-20 max-width--sm section-dark'
        >
          {renderDividers({ color: 'white' })}
          {renderDividers({ color: 'surf' })}
          {renderDividers({ color: 'deep' })}
        </section>
      </div>
    </Fragment>
  );
};

export default DividerPage;
