import React from 'react';

// COMPONENTS
import { Icon, Tag, ExpandableSection, Avatar } from '../../../../../../components';

// TYPINGS
import { IRate } from '../../../../../../api/analyze/analyze.typings';

// ASSETS
import styles from './RateDetailsInfo.module.scss';
import StatusTag from '../../../../components/StatusTag/StatusTag';

interface RateDetailsInfoProps {
  rate: IRate
}

const RateDetailsInfo:React.FC<RateDetailsInfoProps> = ({ rate }) => {
  return (
    <div>
      <ExpandableSection title="Rate details" icon={<Icon.Info size={18} />} expanded>
        <div className={styles.container}>
          <div>
            <div className={styles['info-item']}>
              <span className={styles['info-item__title']}>ID</span>
              <span className={styles['info-item__value']}>{rate.id}</span>
            </div>
            <div className={styles['info-item']}>
              <span className={styles['info-item__title']}>Name</span>
              <span className={styles['info-item__value']}>{rate.name}</span>
            </div>
            <div className={`${styles['info-item']} ${styles['info-item__status']}`}>
              <div className={styles['info-item']}>
                <span className={styles['info-item__title']}>Status</span>
                <span className={styles['info-item__value']}><StatusTag status={rate.status} /></span>
              </div>
              <div className={styles['info-item']}>
                <span className={styles['info-item__title']}>Type</span>
                <span className={styles['info-item__value']}><Tag copy="Source" color="sky" size='sm' /></span>
              </div>
            </div>
            <div className={styles['info-item']}>
              <span className={styles['info-item__title']}>Description</span>
              <span className={styles['info-item__description']}>{rate.description}</span>
            </div>
          </div>
          <div>
            <div className={styles['info-item']}>
              <span className={styles['info-item__title']}>Rate codes</span>
              <span className={styles['info-item__value']}>{rate.rateCodes}</span>
            </div>
            <div className={styles['info-item']}>
              <span className={styles['info-item__title']}>Contract model</span>
              <span className={styles['info-item__value']}>{rate.contractModel}</span>
            </div>
            <div className={styles['info-item']}>
              <span className={styles['info-item__title']}>Created</span>
              <div className={styles['info-item__user']}>
                <span className={styles['info-item__person']}><Avatar alt="ryan greenway" size='sm' src="/images/login/ryan-greenway.png" />Ryan Greenway</span>
                <span className={styles['info-item__value']}>March 7, 2023</span>
              </div>
            </div>
            <div className={styles['info-item']}>
              <span className={styles['info-item__title']}>Updated</span>
              <div className={styles['info-item__user']}>
                <span className={styles['info-item__person']}><Avatar alt="ryan greenway" size='sm' src="/images/login/ryan-greenway.png" />Ryan Greenway</span>
                <span className={styles['info-item__value']}>March 7, 2023</span>
              </div>
            </div>
          </div>
        </div>
      </ExpandableSection>
    </div>
  );
};

export default RateDetailsInfo;
