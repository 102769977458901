import React, { Fragment } from 'react';

import Header from '../../components/Header/Header';
import Image from '../../components/Image/Image';
import Link from '../../components/Link/Link';
import Text from '../../components/Text/Text';

interface Props {
    className?: string,
};

const SpacingPage: React.FC<Props> = () => {
  return (
    <Fragment>
      <Header
        className='design-studio pad--b-100 pad--l-128 pad--t-100'
        color='stone'
        text='Spacing'
      >
        <Text
          className='max-width--900 pad--t-40 title--sm'
          color='white'
          variant='body1'
        >
            Use principles of 4 point: 4px/ 8px / 16px / 24px / 32px / 40px / 48px / 56px and so on. The spacing scale at its root is 4px based scale. This is chosen because 4 is a highly composable number.
        </Text>
        <Link
          className='underline dis--block mar--t-60 body--xl'
          color='white'
          copy='See Our Guidelines'
          href='/'
        />
      </Header>
      <div
        className='mar--l-96 mar--t-80 max-width--lg temp--min-width--md'
      >
        <div
          className='pad--b-80'
        >
          <Image
            alt='Spacing Guide'
            src='../../images/styleguide/spacing.svg'
          />
        </div>
      </div>
    </Fragment>
  );
};

export default SpacingPage;
