import React, { useEffect, useRef, useState } from 'react';

// TYPINGS
import { IRate } from '../../../../../../api/analyze/analyze.typings';
import { ExpandableSection, Icon, Table } from '../../../../../../components';
import { TimeOfUseCharts, TimeOfUseSelects } from './components';

// ASSETS
import styles from './TimeOfUse.module.scss';
import { data, getPeakColor } from './TimeOfUse.helpers';

interface TimeOfUseProps {
  rate: IRate
}

const TimeOfUse:React.FC<TimeOfUseProps> = () => {
  const [tdWidth, setTdWidth] = useState({ first: 0, second: 0 });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const firstTd = containerRef.current.querySelector<HTMLDivElement>('tbody td');
        const secondTd = containerRef.current.querySelector<HTMLDivElement>('tbody td:nth-child(2)');
        if (firstTd && secondTd) {
          setTdWidth({ first: firstTd.offsetWidth, second: secondTd.offsetWidth });
        }

        const divs = Array.from(containerRef.current.querySelectorAll('#chartContainer > div:not(:first-child)')) as HTMLElement[];
        divs.forEach((element: HTMLElement) => {
          if (secondTd) {
            element.style.width = `${secondTd.offsetWidth}px`;
          }
        });
      }
    };

    handleResize(); // Call once initially

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // RENDERS
  const columns = [
    {
      title: '',
      dataIndex: 'peak',
      key: 'peak',
      width: 150,
      render: (text: string) => (
        <div className={styles['peak-cell']}>
          <div className={styles['peak-color']} style={{ backgroundColor: getPeakColor(text) }} />
          <strong>{text}</strong>
        </div>
      )
    },
    { title: 'Winter', dataIndex: 'winter', key: 'winter', width: 288 },
    { title: 'Spring', dataIndex: 'spring', key: 'spring', width: 288 },
    { title: 'Summer', dataIndex: 'summer', key: 'summer', width: 288 },
    { title: 'Fall', dataIndex: 'fall', key: 'fall', width: 288 }
  ];

  return (
    <ExpandableSection title="Time of use" icon={<Icon.Clock size={18} />} expanded>
      <div className={styles['select-area']}>
        <TimeOfUseSelects />
      </div>
      <div ref={containerRef} className={styles['info-graphic']}>
        <div id="chartContainer" className={styles['chart-container']}>
          <TimeOfUseCharts tdWidth={tdWidth} />
        </div>
        <Table columns={columns} dataSource={data} />
      </div>
    </ExpandableSection>
  );
};

export default TimeOfUse;
