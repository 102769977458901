import React, { useState } from 'react';
import classnames from 'classnames';

// ASSETS
import styles from './ExpandableSection.module.scss';
import Icon from '../Icon/Icon';

interface ExpandableSectionProps {
  title?: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  expanded?: boolean;
}

const ExpandableSection: React.FC<ExpandableSectionProps> = ({ title, children, icon, expanded = false }) => {
  const [isOpen, setIsOpen] = useState(expanded);

  const toggleOpen = (e: React.KeyboardEvent | React.MouseEvent) => {
    if (e.type !== 'keydown' || (e as React.KeyboardEvent).key === 'Enter') {
      setIsOpen(!isOpen);
    }
  };

  // STYLES
  const cssBody = classnames(styles.body, {
    [styles['body--noheader']]: !title
  });

  return (
    <div className={styles.container}>
      {
        title && (
          <div
            className={styles.header}
            onClick={toggleOpen}
            onKeyDown={toggleOpen}
            role="button"
            tabIndex={0}
          >
            <div className={styles.icon}>{icon}</div>
            <span className={styles.title}>{title}</span>
            <div className={styles['icon-chevron']}>
              {isOpen ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
            </div>
          </div>
        )
      }
      {
        isOpen && (
          <div className={cssBody}>
            {children}
          </div>
        )
      }
    </div>
  );
};

export default ExpandableSection;
