import colors from '../../../../../../style/colors';

export const data = [
  {
    key: 1,
    peak: 'Off Peak',
    winter: '00:00 – 14:00, 23:00 – 00:00',
    spring: '00:00 – 15:00, 23:00 – 00:00',
    summer: '00:00 – 14:00, 23:00 – 00:00',
    fall: '00:00 – 15:00, 23:00 – 00:00'
  },
  {
    key: 2,
    peak: 'Partial peak',
    winter: '14:00 – 16:00, 21:00 – 23:00',
    spring: '21:00 – 23:00',
    summer: '14:00 – 16:00, 21:00 – 23:00',
    fall: '21:00 – 23:00'
  },
  {
    key: 3,
    peak: 'Peak',
    winter: '16:00 – 21:00',
    spring: '15:00 – 21:00',
    summer: '16:00 – 21:00',
    fall: '15:00 – 21:00'
  }
];

export const getPeakColor = (peak: string): string => {
  switch (peak) {
  case 'Off Peak':
    return colors.watt;
  case 'Partial peak':
    return colors.glow;
  case 'Peak':
    return colors.lumen;
  default:
    return '#000000';
  }
};
