import React from 'react';

// COMPONENTS
import { Button, Dropdown } from '../../../../../../components';
import { ShareButton } from 'blocks/components';
import { HomeGas, HomePower, Municipality, Puzzle, Store } from '../../../../../../icons';

// ASSETS
import styles from './RateDetailsHeader.module.scss';
import { IRate } from '../../../../../../api/analyze/analyze.typings';
import { simpleListWithIcon } from '../../../../../../components/ActionList/stories/ActionList.stories.helpers';

interface RateDetailsHeaderProps {
  rate: IRate
}

const RateDetailsHeader:React.FC<RateDetailsHeaderProps> = ({ rate }) => {
  // RENDERS
  const renderIcon = () => {
    switch (rate.rateType) {
    case 'home-power':
      return <HomePower />;
    case 'home-gas':
      return <HomeGas />;
    case 'store':
      return <Store />;
    case 'municipality':
      return <Municipality />;
    default:
      return <Puzzle />;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <div className={styles.icon}>{renderIcon()}</div>
        <h1>{rate.name}</h1>
      </div>
      <div className={styles.actions}>
        <Dropdown label="Actions" options={simpleListWithIcon} color='depth' />
        <ShareButton/>
        <Button size="md" copy='Analyze' />
      </div>
    </div>
  );
};

export default RateDetailsHeader;
