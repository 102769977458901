import * as React from 'react';
import classnames from 'classnames';

// COMPONENTS
import { Section } from './components';

// ASSETS
import styles from './ActionList.module.scss';
import { ActionListSection } from './ActionList.helpers';

export interface IActionListProps {
  items?: any;
  sections?: any;
  actionRole?: string;
  onActionAnyItem?: any;
  isLoading?: boolean;
  size?: 'md' | 'lg';
  color?: 'light' | 'dark';
}

const ActionList: React.FC<IActionListProps> = ({
  items,
  sections = [],
  actionRole,
  onActionAnyItem,
  size = 'md',
  color = 'light'
}) => {
  let finalSections: any = [];

  if (items) {
    finalSections = [{ items }, ...sections];
  } else if (sections) {
    finalSections = sections;
  }

  const hasMultipleSections = finalSections.length > 1;

  const sectionMarkup = finalSections.map((section: ActionListSection, index: number) => {
    return (
      <Section
        key={section.title || index}
        section={section}
        onActionAnyItem={onActionAnyItem}
        hasMultipleSections={hasMultipleSections}
        actionRole={actionRole}
      />
    );
  });

  // STYLES
  const cssActionList = classnames(styles.actionList, {
    [styles.actionListMd]: size === 'md',
    [styles.actionListLg]: size === 'lg',
    [styles.actionListLight]: color === 'light',
    [styles.actionListDark]: color === 'dark'
  });

  return (
    <div className={cssActionList}>
      {sectionMarkup}
    </div>
  );
};

export default ActionList;
