export const LayoutActionTypes = {
  TOGGLE_PROJECT_SETTINGS: 'TOGGLE_PROJECT_SETTINGS',
  TOGGLE_PROJECT_SAVE: 'TOGGLE_PROJECT_SAVE',
  TOGGLE_COST_ESTIMATES: 'TOGGLE_COST_ESTIMATES',
  TOGGLE_ENERGY_RESOURCES: 'TOGGLE_ENERGY_RESOURCES',
  TOGGLE_SOLAR_PANELS: 'TOGGLE_SOLAR_PANELS',
  TOGGLE_ELECTRIC_VEHICLE: 'TOGGLE_ELECTRIC_VEHICLE',
  TOGGLE_HEAT_PUMP: 'TOGGLE_HEAT_PUMP',
  TOGGLE_RATES: 'TOGGLE_RATES',
  TOGGLE_RATE_DETAILS: 'TOGGLE_RATE_DETAILS',
  TOGGLE_PROJECT_DELETE: 'TOGGLE_PROJECT_DELETE'
};
