import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// COMPONENTS
import { InfoItem, ListItem, ListToolbar } from 'blocks/components';
import { Dropdown, Icon, Tag } from 'components';
import ListShowMore from 'components/ListShowMore/ListShowMore';

// ASSETS
import styles from './ListBilling.module.scss';

// ACTIONS
import { setIsUpdateSystemOpen } from 'store/modules/accounts/accounts.slice';

// HELPERS
import { getStatusCustomColor, getStatusTextColor } from './ListBilling.helpers';
import { useTheme } from 'hooks/Theme/Theme.context';

// TYPINGS
import { IBilling } from 'api/billing/billing.typings';

interface ListBillingProps {
  billings: IBilling[]
}

const ListBilling:React.FC<ListBillingProps> = ({ billings }) => {
  const navigate = useNavigate();
  const { getThemeColor, hasThemePalette, slug } = useTheme();
  const [selectedItems, setSelectedItems] = useState<{ [key: string]: boolean }>({});
  const [limitShow, setLimitShow] = useState(10);
  const dispatch = useDispatch();

  // EVENTS
  const handleShowMore = () => {
    setLimitShow(limitShow + 10);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const newSelectedItems: { [key: string]: boolean } = {};

    billings.forEach((bill) => {
      newSelectedItems[bill.billId] = isChecked;
    });

    setSelectedItems(newSelectedItems);
  };

  const handleSelectItem = (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [id]: isChecked
    }));
  };

  const handleAccountClick = (billId: string | number) => {
    navigate(`/${slug}/billing/${billId}/`);
  };

  // RENDERS
  const filteredBillings = billings.slice(0, limitShow);

  const actionList = [
    {
      startIcon: <Icon.Upload size={18}/>,
      content: 'Update',
      onClick: () => {
        dispatch(setIsUpdateSystemOpen(true));
      }
    },
    {
      startIcon: <Icon.Users size={18} />,
      content: 'Assign',
      onClick: () => {}
    },
    {
      startIcon: <Icon.Download size={18} />,
      content: 'Download',
      onClick: () => {}
    }
  ];

  return (
    <>
      <ListToolbar
        selectedCount={Object.values(selectedItems).filter(Boolean).length}
        onSelect={handleSelectAll}
        rightSlot={<Dropdown label="Actions" options={actionList} color='depth' />}
      />
      <AnimatePresence>
        <motion.ul
          className={styles['list-container']}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {
            filteredBillings.map((bill) => {
              return (
                <motion.li
                  key={bill.billId}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className={styles['list-item']}>
                    <ListItem
                      selectable
                      isSelected={selectedItems[bill.billId]}
                      onSelect={handleSelectItem(bill.billId)}
                      onBodyClick={() => handleAccountClick(bill.billId)}
                      columns={[
                        { body: <InfoItem title="Bill ID" description={bill.billId} />, col: 2 },
                        { body: <InfoItem title="Service Agreement" description={bill.serviceAgreement} />, col: 2.5 },
                        { body: <InfoItem title="Bill Cycle Start" description={bill.startDate} />, col: 1.5 },
                        { body: <InfoItem title="Bill Cycle End" description={bill.endDate} />, col: 1.5 },
                        { body: <InfoItem title="File ID" description={bill.saId} />, col: 1.5 },
                        {
                          body: (
                            <div className={styles['customer-type']}>
                              <Tag
                                copy={bill.status}
                                size="sm"
                                backgroundColor={getStatusCustomColor(bill.status, getThemeColor)}
                                textColor={getStatusTextColor(bill.status, hasThemePalette)}
                              />
                            </div>
                          ),
                          col: 3
                        }
                      ]}
                    />
                  </div>
                </motion.li>
              );
            })}
        </motion.ul>
      </AnimatePresence>
      <div className={styles['list-footer']}>
        <ListShowMore total={40} current={limitShow} onShowMore={handleShowMore} />
      </div>
    </>
  );
};

export default ListBilling;
