import React, { Fragment } from 'react';

import Header from '../../components/Header/Header';
import Shadow from '../../components/Shadow/Shadow';
import Link from '../../components/Link/Link';
import Text from '../../components/Text/Text';

import './button-page.scss';

interface Props {
    size?: string,
    color?: string
};

const ShadowPage: React.FC = () => {
  const renderShadows: React.FC<Props> = ({
    size = 'xs'
  }) => {
    return (
      <Fragment>
        <Shadow
          size={size}
        />
      </Fragment>
    );
  };
  const renderSize: React.FC<Props> = ({
    color = 'white'
  }) => {
    return (
      <Fragment>
        <div className='flex jus--space-around'>
          <Text
            className='body--sm'
            // @ts-ignore
            color={color}
            variant='body1'
          >
            xs
          </Text>
          <Text
            className='body--sm'
            // @ts-ignore
            color={color}
            variant='body1'
          >
            sm
          </Text>
          <Text
            className='body--sm'
            // @ts-ignore
            color={color}
            variant='body1'
          >
            md
          </Text>
          <Text
            className='body--sm'
            // @ts-ignore
            color={color}
            variant='body1'
          >
            lg
          </Text>
          <Text
            className='body--sm'
            // @ts-ignore
            color={color}
            variant='body1'
          >
            xl
          </Text>
          <Text
            className='body--sm'
            // @ts-ignore
            color={color}
            variant='body1'
          >
            2xl
          </Text>
          <Text
            className='body--sm'
            // @ts-ignore
            color={color}
            variant='body1'
          >
            3xl
          </Text>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Header
        className='design-studio pad--b-100 pad--l-128 pad--t-100'
        color='stone'
        text='Shadow/Elevation'
      >
        <Link
          className='underline dis--block mar--t-60 body--xl'
          color='white'
          copy='See Our Guidelines'
          href='/'
        />
      </Header>
      <div
        className='shadow--page mar--l-96 mar--t-80 max-width--lg temp--min-width--md'
      >
        <div
          className='pad--b-80'
        >
          <section className='section mar--b-80 max-width--sm'>
            {renderSize({ color: 'stone' })}
            <div className='flex mar--t-20 jus--space-between'>
              {renderShadows({ size: 'xs' })}
              {renderShadows({ size: 'sm' })}
              {renderShadows({ size: 'md' })}
              {renderShadows({ size: 'lg' })}
              {renderShadows({ size: 'xl' })}
              {renderShadows({ size: 'xxl' })}
              {renderShadows({ size: 'xxxl' })}
            </div>
          </section>
          <section className='section mar--b-80 max-width--sm stone'>
            {renderSize({ color: 'white' })}
            <div className='flex mar--t-20 jus--space-between'>
              {renderShadows({ size: 'xs' })}
              {renderShadows({ size: 'sm' })}
              {renderShadows({ size: 'md' })}
              {renderShadows({ size: 'lg' })}
              {renderShadows({ size: 'xl' })}
              {renderShadows({ size: 'xxl' })}
              {renderShadows({ size: 'xxxl' })}
            </div>
          </section>
          <section className='section mar--b-80 max-width--sm deep'>
            {renderSize({ color: 'white' })}
            <div className='flex mar--t-20 jus--space-between'>
              {renderShadows({ size: 'xs' })}
              {renderShadows({ size: 'sm' })}
              {renderShadows({ size: 'md' })}
              {renderShadows({ size: 'lg' })}
              {renderShadows({ size: 'xl' })}
              {renderShadows({ size: 'xxl' })}
              {renderShadows({ size: 'xxxl' })}
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  );
};

export default ShadowPage;
