import React from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';

// COMPONENTS
import Icon from './components/Icon/Icon';
import Layout from './components/Layout/Layout';
import RequireAuth from './components/RequireAuth/RequireAuth';

// PAGES
import CitadelHomepage from './pages/CitadelHomepage';
import CitadelUsers from './pages/CitadelUsers';
import FourOFourPage from './pages/FourOFourPage';
import ImpactAnalyze from './pages/ImpactAnalysis';
import LoginPage from './pages/LoginPage';
import MetronomeHome from './pages/MetronomeHome';
import TempAnalyzePage from './pages/TempAnalyzePage';
import ExplorePage from './pages/Explore/sections/ExplorePage/ExplorePage';
import ExplorePrototype from './pages/Explore/ExplorePrototype';
import RealTimeHourlyPricing from './pages/RealTimeHourlyPricing/RealTimeHourlyPricing';
import MyAccountWidget from 'pages/RateAnalysisWidgetDemo/sections/MyAccount/MyAccount';
import RatesPrograms from 'pages/RateAnalysisWidgetDemo/sections/RatesPrograms/RatesPrograms';
import RatesProgramsCompare from 'pages/RateAnalysisWidgetDemo/sections/RatesProgramsCompare/RatesProgramsCompare';
import Usage from 'pages/Usage/Usage';
import Platform from 'pages/Platform/Platform';
import MyAccount from 'pages/MyAccount/MyAccount';

// HELPERS
import { MetronomeRoutes, FigmaIframeRoutes, AnalyzeRoutes, SeminoleRoutes, EnergyxRoutes } from './routes/index';

// ASSETS
import './app.scss';

const pages = [
  {
    active: true,
    color: 'stone',
    displayName: 'Home',
    href: '/energyx/admin',
    icon: <Icon.Home />,
    permissionRequired: 'admin'
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Insights',
    href: '/analytics',
    icon: <Icon.Activity />,
    permissionRequired: 'admin'
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Users',
    href: '/energyx/users',
    icon: <Icon.Users />,
    permissionRequired: 'admin'
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Settings',
    href: '/settings',
    icon: <Icon.Settings />,
    permissionRequired: 'admin'
  }
];

const impactAnalysisPages = [
  {
    active: true,
    color: 'stone',
    displayName: 'Impact Analysis',
    href: '/design/impact-analysis',
    icon: <Icon.Activity />,
    permissionRequired: 'admin'
  }
];

const seminolePages = [
  {
    active: true,
    color: 'stone',
    displayName: 'Home',
    href: '/seminole/home',
    icon: <Icon.Home />,
    permissionRequired: 'admin',
    withRouter: true
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Accounts',
    href: '/seminole/accounts',
    icon: <Icon.UserCheck />,
    permissionRequired: 'admin',
    withRouter: true
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Billing',
    href: '/seminole/billing',
    icon: <Icon.Calendar />,
    permissionRequired: 'admin',
    withRouter: true
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Events',
    href: '/seminole/events',
    icon: <Icon.Flag />,
    permissionRequired: 'admin',
    withRouter: true
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Insights',
    href: '/seminole/insights',
    icon: <Icon.Activity />,
    permissionRequired: 'admin',
    withRouter: true
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Users',
    href: '/seminole/users',
    icon: <Icon.Users />,
    permissionRequired: 'admin',
    withRouter: true
  }
];

const energyxPages = [
  {
    active: true,
    color: 'stone',
    displayName: 'Home',
    href: '/energyx/home',
    icon: <Icon.Home />,
    authorizationRequired: 'admin',
    withRouter: true
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Accounts',
    href: '/energyx/accounts',
    icon: <Icon.UserCheck />,
    authorizationRequired: 'admin',
    withRouter: true
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Billing',
    href: '/energyx/billing',
    icon: <Icon.Calendar />,
    authorizationRequired: 'admin',
    withRouter: true
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Events',
    href: '/energyx/events',
    icon: <Icon.Flag />,
    authorizationRequired: 'admin',
    withRouter: true
  }
];

const App = () => {
  return (
    <div className='App'>
      <Routes>
        <Route exact path='/' element={<LoginPage metronomeDemoMode={true} />} />
        <Route exact path='/login' element={<LoginPage metronomeDemoMode={true} />} />
        <Route exact path='/metronome/home' element={<MetronomeHome />} />
        <Route path='/' element={<Layout pages={pages} metronomeDemoMode={false} />}>
          {/* Public Routes */}
          <Route exact path='/energyx/admin' element={<CitadelHomepage />} />
          <Route exact path='/energyx/users' element={<CitadelUsers />} />
        </Route>
        <Route path='/' element={<Layout pages={seminolePages} metronomeDemoMode={false} />}>
          {MetronomeRoutes}
          {AnalyzeRoutes}
          {SeminoleRoutes}
        </Route>
        <Route path='/' element={<Layout pages={energyxPages} metronomeDemoMode={false} />}>
          {EnergyxRoutes}
        </Route>
        <Route path='/' element={<Layout pages={impactAnalysisPages} metronomeDemoMode={false} />}>
          <Route exact path='/design/impact-analysis' element={<ImpactAnalyze />} />
        </Route>
        {FigmaIframeRoutes}
        <Route path='/real-time-hourly-pricing' element={<RealTimeHourlyPricing />} />
        <Route
          path='/rthp-iframe' element={
            <iframe src='/real-time-hourly-pricing'
              title='Real Time Hourly Pricing' height='100%' width='100%'></iframe>
          } />
        {/* Private Routes */}
        <Route path='/apps' element={<RequireAuth allowedRoles='analyze admin' />}>
          <Route exact path='analyze' element={<TempAnalyzePage />} />
        </Route>
        <Route path='/explore' element={<ExplorePage theme="all" />} />
        <Route path='/der-calculator' element={<ExplorePage theme="pge" />} />
        <Route path='/explore-demo' element={<ExplorePage theme="all" />} />
        <Route path='/explore/rates' element={<ExplorePrototype />} />

        {/* My Account Demo */}
        <Route path='/:theme/rates-design' element={<MyAccount />} />
        <Route path='/:theme/explore' element={<ExplorePage />} />
        <Route path='/:theme/rates-comparison' element={<RatesProgramsCompare />} />
        <Route path='/:theme/usage' element={<Usage />} />

        {/* Rate Analysis Widget Demo */}
        <Route path='/rate-analysis' element={<MyAccountWidget theme='all' />} />
        <Route path='/rate-analysis/my-account' element={<MyAccountWidget theme='all' />} />
        <Route path='/rate-analysis/rates-programs' element={<RatesPrograms theme='all' />} />
        <Route path='/rate-analysis/rates-programs-details' element={<RatesProgramsCompare theme='all' />} />
        {/* Prime Nav Prototype */}
        <Route path='/prototype/platform-navigation' element={<Platform />} />
        {/* Catch All */}
        <Route path='/*' element={<FourOFourPage />} />
        {/* 404 Page */}
      </Routes>
    </div>
  );
};

export default App;
