import * as React from 'react';

import colors from '../../../style/colors';

const CSR = (props:any) => {
  const { color, showBrandName, showGridX } = props;

  let renderColor;
  switch (color) {
  case 'deep':
    renderColor = colors.deep;
    break;
  case 'energy':
    renderColor = colors.energy;
    break;
  case 'white':
    renderColor = colors.white;
    break;
  }

  if (showGridX) {
    return (
      <svg
        width={319}
        height={134}
        viewBox="0 0 319 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_9122_7897)">
          <path
            d="M20.5084 113.933H2.90625C5.53737 120.517 10.2805 126.037 16.3879 129.622C22.4954 133.207 29.6199 134.654 36.6385 133.734C43.6571 132.814 50.1707 129.581 55.1526 124.542C60.1345 119.504 63.3014 112.948 64.1542 105.907H20.5084V113.933Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M20.5084 98.2559H64.1542C63.3107 91.2082 60.1476 84.6431 55.1646 79.5977C50.1816 74.5524 43.6628 71.3145 36.6382 70.3957C29.6136 69.4768 22.4837 70.9294 16.3751 74.5239C10.2664 78.1184 5.52717 83.6499 2.90625 90.2444H20.5084V98.2559Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M89.909 113.285V89.6107H97.9047V69.7837C90.171 70.7199 83.0475 74.462 77.8801 80.3028C72.7127 86.1436 69.8594 93.6786 69.8594 101.484C69.8594 109.289 72.7127 116.824 77.8801 122.665C83.0475 128.506 90.171 132.248 97.9047 133.184V113.285H89.909Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M105.526 69.7837V89.6539H113.522V113.328H105.526V133.184C113.26 132.248 120.384 128.506 125.551 122.665C130.718 116.824 133.572 109.289 133.572 101.484C133.572 93.6786 130.718 86.1436 125.551 80.3028C120.384 74.462 113.26 70.7199 105.526 69.7837Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M91.117 37.5929L85.451 31.9301L102.162 15.1866L107.828 20.8494L121.835 6.81489C115.708 1.99638 108.032 -0.406224 100.258 0.0612629C92.4835 0.528749 85.1494 3.83396 79.6422 9.35202C74.135 14.8701 70.8363 22.2185 70.3697 30.0082C69.9032 37.7979 72.3011 45.489 77.1101 51.6274L91.117 37.5929Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M118.872 31.9305L102.161 48.6739L96.5098 42.9967L82.5029 57.0312C88.6292 61.8497 96.3052 64.2524 104.08 63.7849C111.854 63.3174 119.188 60.0122 124.695 54.4941C130.202 48.9761 133.501 41.6276 133.968 33.8379C134.434 26.0482 132.036 18.3571 127.227 12.2188L113.22 26.2533L118.872 31.9305Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M43.6881 19.8988V43.573H35.6924V63.4432C43.4261 62.507 50.5497 58.7649 55.717 52.9241C60.8844 47.0833 63.7377 39.5484 63.7377 31.7431C63.7377 23.9378 60.8844 16.4029 55.717 10.5621C50.5497 4.72128 43.4261 0.97922 35.6924 0.0429688V19.9132L43.6881 19.8988Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M28.07 63.4435V43.5733H20.0742V19.899H28.07V0.0288086C20.3363 0.96506 13.2128 4.70716 8.04536 10.548C2.87797 16.3888 0.0246582 23.9237 0.0246582 31.7289C0.0246582 39.5342 2.87797 47.0692 8.04536 52.91C13.2128 58.7508 20.3363 62.4928 28.07 63.4291V63.4435Z"
            fill={renderColor || '#344145'}
          />
        </g>
        <path
          d="M165.456 87.8807C165.456 71.8247 175.968 61.0967 191.592 61.0967C204.408 61.0967 214.056 68.2967 216.144 79.2407H205.992C204.192 73.3367 198.648 69.6647 191.592 69.6647C181.656 69.6647 175.464 77.0087 175.464 87.8807C175.464 99.1847 181.944 106.097 191.52 106.097C198.72 106.097 204.264 102.209 205.992 96.5207H216.144C214.056 107.537 204.408 114.737 191.592 114.737C175.968 114.737 165.456 103.937 165.456 87.8807Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M243.706 114.737C231.394 114.737 223.474 108.401 222.754 98.6807H232.474C232.906 103.649 237.298 106.745 243.922 106.745C250.402 106.745 254.074 103.793 254.074 99.3287C254.074 94.0727 248.962 93.2087 246.802 92.8487L237.802 91.3367C227.29 89.5367 223.042 84.1367 223.042 77.0807C223.042 67.5047 231.034 61.0967 242.914 61.0967C254.722 61.0967 262.642 67.4327 262.786 77.0807H253.066C252.778 72.1847 248.746 68.9447 242.482 68.9447C236.866 68.9447 233.05 71.5367 233.05 75.9287C233.05 79.9607 236.29 81.6887 240.754 82.4087L249.826 83.9207C259.906 85.5767 264.082 91.4807 264.082 98.6087C264.082 108.329 256.018 114.737 243.706 114.737Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M317.499 114.017H305.403L282.435 92.5607V114.017H272.859V61.8167H297.123C308.715 61.8167 315.843 68.0087 315.843 77.9447C315.843 87.8087 309.147 93.8567 299.067 93.8567H295.755L317.499 114.017ZM282.435 70.3127V87.0887H296.691C302.811 87.0887 305.979 83.7047 305.979 78.7367C305.979 73.1927 302.307 70.3127 296.835 70.3127H282.435Z"
          fill={renderColor || '#344145'}
        />
        <g clipPath="url(#clip1_9122_7897)">
          <path
            d="M208.414 9.94043H203.654V29.35H208.414V9.94043Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M208.414 3.42627H203.654V7.55243H208.414V3.42627Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M233.159 1.5166V29.35H228.957L228.578 26.4555C227.219 28.4614 224.424 29.7216 221.1 29.7216C215.131 29.7216 211.278 25.6396 211.278 19.7024C211.278 13.5781 215.432 9.56886 221.478 9.56886C224.347 9.56886 226.878 10.5328 228.391 12.2399V1.5166H233.159ZM228.399 19.9258V19.4789C228.399 15.8412 225.905 13.5027 222.239 13.5027C218.463 13.5027 216.123 15.9504 216.123 19.6244C216.123 23.4466 218.577 25.7851 222.205 25.7851C225.833 25.7851 228.399 23.5999 228.399 19.9258Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M241.637 19.1491L234.465 9.94057H240.376L244.94 15.9505C245.02 16.0544 245.124 16.1387 245.243 16.1967C245.362 16.2547 245.493 16.2849 245.625 16.2849C245.758 16.2849 245.889 16.2547 246.008 16.1967C246.126 16.1387 246.23 16.0544 246.31 15.9505L251.028 9.93018H256.772L249.486 19.1335C249.368 19.2817 249.304 19.4647 249.304 19.6532C249.304 19.8416 249.368 20.0246 249.486 20.1728L256.809 29.3372H250.991L246.26 23.4156C246.18 23.3136 246.077 23.2311 245.959 23.1743C245.841 23.1175 245.712 23.088 245.58 23.088C245.449 23.088 245.32 23.1175 245.202 23.1743C245.084 23.2311 244.981 23.3136 244.901 23.4156L240.249 29.3424H234.465L241.637 20.1702C241.751 20.0235 241.813 19.8441 241.813 19.6597C241.813 19.4752 241.751 19.2958 241.637 19.1491Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M198.883 9.94042L202.059 14.1545H195.696C195.212 14.1545 194.748 14.3434 194.406 14.6796C194.064 15.0158 193.872 15.6594 193.872 16.1349V29.35H189.204V15.8387C189.204 11.5384 191.661 9.93262 194.731 9.93262L198.883 9.94042Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M181.395 9.94026L181.093 12.6503C179.771 10.7951 177.127 9.57129 173.916 9.57129C168.098 9.57129 164.285 13.5779 164.285 19.1851C164.285 24.7508 168.101 28.76 173.916 28.76C176.939 28.76 179.62 27.5726 180.829 25.7174V28.6847C180.829 31.8027 178.488 33.7333 174.974 33.7333C173.022 33.7333 171.488 33.0915 170.441 32.1743C170.275 32.0261 169.939 31.7455 169.939 31.7455L166.895 34.7856C167.158 35.0508 167.441 35.2966 167.741 35.5209C169.449 36.7967 171.859 37.5216 174.9 37.5216C181.395 37.5216 185.589 34.1074 185.589 28.5028V9.94026H181.395ZM180.829 19.3307C180.829 22.7449 178.224 24.9353 174.86 24.9353C171.234 24.9353 169.119 22.5214 169.119 19.1462C169.119 15.6566 171.385 13.3934 174.9 13.3934C178.261 13.3934 180.829 15.4721 180.829 18.8863V19.3307Z"
            fill={renderColor || '#344145'}
          />
        </g>
        <defs>
          <clipPath id="clip0_9122_7897">
            <rect width={134} height={134} fill="white" />
          </clipPath>
          <clipPath id="clip1_9122_7897">
            <rect
              width={99}
              height={39}
              fill="white"
              transform="translate(162 0.0166016)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (showBrandName) {
    return (
      <svg
        width={319}
        height={134}
        viewBox="0 0 319 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_9087_4305)">
          <path
            d="M20.5088 113.933H2.90674C5.53786 120.518 10.2809 126.037 16.3884 129.622C22.4959 133.207 29.6204 134.654 36.639 133.734C43.6576 132.815 50.1712 129.581 55.1531 124.543C60.135 119.504 63.3019 112.948 64.1546 105.907H20.5088V113.933Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M20.5088 98.2561H64.1546C63.3112 91.2085 60.148 84.6433 55.165 79.598C50.182 74.5526 43.6633 71.3148 36.6387 70.3959C29.6141 69.4771 22.4842 70.9296 16.3755 74.5241C10.2669 78.1186 5.52766 83.6501 2.90674 90.2446H20.5088V98.2561Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M89.909 113.285V89.6109H97.9047V69.7839C90.171 70.7202 83.0475 74.4623 77.8801 80.3031C72.7127 86.1439 69.8594 93.6788 69.8594 101.484C69.8594 109.289 72.7127 116.824 77.8801 122.665C83.0475 128.506 90.171 132.248 97.9047 133.184V113.285H89.909Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M105.527 69.7839V89.6542H113.523V113.328H105.527V133.184C113.261 132.248 120.384 128.506 125.552 122.665C130.719 116.824 133.572 109.289 133.572 101.484C133.572 93.6788 130.719 86.1439 125.552 80.3031C120.384 74.4623 113.261 70.7202 105.527 69.7839Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M91.117 37.5931L85.451 31.9303L102.162 15.1869L107.828 20.8497L121.835 6.81514C115.708 1.99663 108.032 -0.40598 100.258 0.061507C92.4835 0.528994 85.1494 3.83421 79.6422 9.35226C74.135 14.8703 70.8363 22.2188 70.3697 30.0085C69.9032 37.7982 72.3011 45.4892 77.1101 51.6276L91.117 37.5931Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M118.872 31.931L102.161 48.6744L96.5098 42.9972L82.5029 57.0317C88.6292 61.8502 96.3052 64.2529 104.08 63.7854C111.854 63.3179 119.188 60.0127 124.695 54.4946C130.202 48.9766 133.501 41.6281 133.968 33.8384C134.434 26.0487 132.036 18.3576 127.227 12.2192L113.22 26.2538L118.872 31.931Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M43.6881 19.899V43.5732H35.6924V63.4435C43.4261 62.5072 50.5497 58.7651 55.717 52.9243C60.8844 47.0835 63.7377 39.5486 63.7377 31.7433C63.7377 23.9381 60.8844 16.4031 55.717 10.5623C50.5497 4.72152 43.4261 0.979464 35.6924 0.0432129V19.9134L43.6881 19.899Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M28.0702 63.4435V43.5733H20.0745V19.899H28.0702V0.0288086C20.3366 0.96506 13.213 4.70716 8.04561 10.548C2.87822 16.3888 0.0249023 23.9237 0.0249023 31.7289C0.0249023 39.5342 2.87822 47.0692 8.04561 52.91C13.213 58.7508 20.3366 62.4928 28.0702 63.4291V63.4435Z"
            fill={renderColor || '#344145'}
          />
        </g>
        <path
          d="M165.456 62.8641C165.456 46.8081 175.968 36.0801 191.592 36.0801C204.408 36.0801 214.056 43.2801 216.144 54.2241H205.992C204.192 48.3201 198.648 44.6481 191.592 44.6481C181.656 44.6481 175.464 51.9921 175.464 62.8641C175.464 74.1681 181.944 81.0801 191.52 81.0801C198.72 81.0801 204.264 77.1921 205.992 71.5041H216.144C214.056 82.5201 204.408 89.7201 191.592 89.7201C175.968 89.7201 165.456 78.9201 165.456 62.8641Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M243.706 89.7201C231.394 89.7201 223.474 83.3841 222.754 73.6641H232.474C232.906 78.6321 237.298 81.7281 243.922 81.7281C250.402 81.7281 254.074 78.7761 254.074 74.3121C254.074 69.0561 248.962 68.1921 246.802 67.8321L237.802 66.3201C227.29 64.5201 223.042 59.1201 223.042 52.0641C223.042 42.4881 231.034 36.0801 242.914 36.0801C254.722 36.0801 262.642 42.4161 262.786 52.0641H253.066C252.778 47.1681 248.746 43.9281 242.482 43.9281C236.866 43.9281 233.05 46.5201 233.05 50.9121C233.05 54.9441 236.29 56.6721 240.754 57.3921L249.826 58.9041C259.906 60.5601 264.082 66.4641 264.082 73.5921C264.082 83.3121 256.018 89.7201 243.706 89.7201Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M317.499 89.0001H305.403L282.435 67.5441V89.0001H272.859V36.8001H297.123C308.715 36.8001 315.843 42.9921 315.843 52.9281C315.843 62.7921 309.147 68.8401 299.067 68.8401H295.755L317.499 89.0001ZM282.435 45.2961V62.0721H296.691C302.811 62.0721 305.979 58.6881 305.979 53.7201C305.979 48.1761 302.307 45.2961 296.835 45.2961H282.435Z"
          fill={renderColor || '#344145'}
        />
        <defs>
          <clipPath id="clip0_9087_4305">
            <rect width={134} height={134} fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 64 65"
        {...props}
      >
        <path
          fill={renderColor || '#D4E9EC'}
          d="M9.795 54.594H1.388a15.25 15.25 0 0 0 6.439 7.493 15.203 15.203 0 0 0 18.514-2.426 15.27 15.27 0 0 0 4.3-8.9H9.795v3.833ZM9.795 47.106H30.64a15.263 15.263 0 0 0-4.294-8.911 15.196 15.196 0 0 0-18.526-2.423 15.242 15.242 0 0 0-6.433 7.508h8.407v3.826Z"
        />
        <path
          fill={renderColor || '#344145'}
          d="M42.941 54.284V42.977h3.82v-9.47a15.211 15.211 0 0 0-9.565 5.024 15.268 15.268 0 0 0-3.83 10.117c0 3.728 1.362 7.326 3.83 10.116a15.211 15.211 0 0 0 9.564 5.024v-9.504h-3.819ZM50.4 33.507v9.49h3.82v11.308H50.4v9.483a15.212 15.212 0 0 0 9.565-5.024 15.268 15.268 0 0 0 3.83-10.116c0-3.728-1.362-7.327-3.83-10.117a15.212 15.212 0 0 0-9.564-5.024Z"
        />
        <path
          fill={renderColor || '#FFA44F'}
          d="m43.519 18.133-2.707-2.705 7.982-7.997 2.706 2.705 6.69-6.703a15.193 15.193 0 0 0-20.152 1.211 15.264 15.264 0 0 0-1.21 20.191l6.69-6.702ZM56.775 15.428l-7.981 7.997-2.7-2.711-6.69 6.703a15.193 15.193 0 0 0 20.152-1.212 15.265 15.265 0 0 0 1.21-20.191l-6.69 6.703 2.699 2.711Z"
        />
        <path
          fill={renderColor || '#344145'}
          d="M20.866 9.682v11.307h-3.82v9.49a15.21 15.21 0 0 0 9.565-5.024 15.268 15.268 0 0 0 0-20.233A15.211 15.211 0 0 0 17.047.199v9.49l3.819-.006ZM13.406 30.479v-9.49H9.588V9.682h3.819V.192a15.211 15.211 0 0 0-9.565 5.023 15.268 15.268 0 0 0-3.83 10.117c0 3.728 1.363 7.326 3.83 10.116a15.21 15.21 0 0 0 9.564 5.024v.007Z"
        />
      </svg>
    );
  }
};
export default CSR;
