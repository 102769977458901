import React from 'react';

// ASSETS
import styles from './LegendGraphItem.module.scss';

interface LegendGraphItemProps {
  name: string;
  color: string;
}

const LegendGraphItem: React.FC<LegendGraphItemProps> = ({ name, color }) => {
  return (
    <div className={styles.container}>
      <div className={styles.circle} style={{ backgroundColor: color }} />
      <div className={styles.name}>{name}</div>
    </div>
  );
};

export default LegendGraphItem;
