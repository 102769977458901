import classnames from 'classnames';
import * as React from 'react';

// ASSETS
import styles from '../../ActionList.module.scss';

interface IPropsItem {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  selected?: boolean;
  content?: any;
  onClick?: any;
}

const Item: React.FC<IPropsItem> = ({ startIcon, content, onClick, endIcon, selected = false }) => {
  const contentElement = <div className={styles.content}>{content}</div>;

  const classNames = classnames(styles.item, {
    [styles.itemSelected]: selected
  });

  return (
    <li className={classNames}>
      <button type="button" onClick={onClick}>
        <div>
          {startIcon && <div className={styles.startIcon}>{startIcon}</div>}
          {contentElement}
        </div>
        <div>{endIcon && <div className={styles.endIcon}>{endIcon}</div>}</div>
      </button>
    </li>
  );
};

export default Item;
