import * as React from 'react';

import colors from '../../../style/colors';

const Explore = (props:any) => {
  const { color, showBrandName, showGridX } = props;

  let renderColor;
  switch (color) {
  case 'deep':
    renderColor = colors.deep;
    break;
  case 'energy':
    renderColor = colors.energy;
    break;
  case 'white':
    renderColor = colors.white;
    break;
  }

  if (color === 'deep' || color === 'energy' || color === 'white') {
    if (showGridX) {
      return (
        <svg
          width={435}
          height={134}
          viewBox="0 0 435 134"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#clip0_9122_8267)">
            <path
              d="M71.9364 110.484C70.5845 111.835 68.7532 112.594 66.8439 112.594C64.9347 112.594 63.1033 111.835 61.7514 110.484L42.3267 91.0061L23.4356 72.0931C22.0874 70.7385 21.3304 68.9035 21.3304 66.9905C21.3304 65.0775 22.0874 63.2425 23.4356 61.888L42.6622 42.6082L61.7514 23.4813C63.1033 22.1304 64.9347 21.3719 66.8439 21.3719C68.7532 21.3719 70.5845 22.1304 71.9364 23.4813L88.571 40.1486L103.666 25.0243L87.0463 8.35694C81.6846 3.00114 74.4225 -0.00634766 66.8516 -0.00634766C59.2806 -0.00634766 52.0185 3.00114 46.6569 8.35694L27.5676 27.4992L8.34109 46.7789C2.99676 52.1485 -0.00439453 59.4224 -0.00439453 67.0058C-0.00439453 74.5891 2.99676 81.8631 8.34109 87.2327L27.2322 106.146L46.6569 125.624C52.0166 130.984 59.2794 133.994 66.8516 133.994C74.4237 133.994 81.6866 130.984 87.0463 125.624L103.239 109.4L88.144 94.2754L71.9364 110.484Z"
              fill={renderColor}
            />
            <path
              d="M110.543 61.8872C111.214 62.5559 111.747 63.3509 112.11 64.2266C112.474 65.1023 112.661 66.0413 112.661 66.9897C112.661 67.9381 112.474 68.8772 112.11 69.7529C111.747 70.6285 111.214 71.4236 110.543 72.0923L93.3442 89.3249L108.439 104.449L125.637 87.2166C130.989 81.851 133.995 74.5756 133.995 66.9897C133.995 59.4039 130.989 52.1285 125.637 46.7628L108.866 29.958L93.7711 45.0824L110.543 61.8872Z"
              fill={renderColor}
            />
            <path
              d="M82.1262 56.44L77.9805 52.2946L66.9956 41.3105V94.9027L93.7938 68.1066L82.1262 56.44Z"
              fill={renderColor}
            />
            <path
              d="M91.1359 68.1066L66.9842 92.7702L42.3187 68.1066L66.9842 43.443L91.1359 68.1066Z"
              stroke={renderColor}
              strokeWidth={3}
            />
          </g>
          <path
            d="M167.688 114.017V61.8166H204.768V70.2406H177.264V83.9926H202.608V91.9126H177.264V105.593H204.768V114.017H167.688Z"
            fill={renderColor}
          />
          <path
            d="M209.474 114.017L222.578 95.2246L209.474 76.3606H219.698L228.77 89.7526L238.13 76.3606H248.066L234.746 95.2246L248.138 114.017H238.058L228.698 100.841L219.482 114.017H209.474Z"
            fill={renderColor}
          />
          <path
            d="M277.043 75.6406C288.131 75.6406 295.763 83.4166 295.763 95.0086C295.763 106.889 287.915 114.737 276.323 114.737C270.851 114.737 266.027 112.793 263.147 109.553V128.417H254.075V76.3606H262.139L262.787 81.8326C265.523 78.0166 270.779 75.6406 277.043 75.6406ZM274.955 107.105C281.939 107.105 286.547 102.641 286.547 95.2246C286.547 88.0246 282.083 83.2726 274.955 83.2726C268.043 83.2726 263.147 87.5206 263.147 94.6486V95.5126C263.147 102.497 267.827 107.105 274.955 107.105Z"
            fill={renderColor}
          />
          <path
            d="M303.567 114.017V60.0166H312.639V114.017H303.567Z"
            fill={renderColor}
          />
          <path
            d="M320.237 95.1526C320.237 83.4886 328.661 75.6406 340.829 75.6406C352.997 75.6406 361.421 83.4886 361.421 95.1526C361.421 106.889 352.997 114.737 340.829 114.737C328.661 114.737 320.237 106.889 320.237 95.1526ZM329.525 95.2246C329.525 102.497 334.061 106.889 340.901 106.889C347.885 106.889 352.205 102.209 352.205 95.1526C352.205 87.8806 347.669 83.4166 340.973 83.4166C333.917 83.4166 329.525 88.0966 329.525 95.2246Z"
            fill={renderColor}
          />
          <path
            d="M387.734 75.7846C388.742 75.7846 389.75 75.9286 390.326 76.0726V84.5686C389.822 84.4246 388.886 84.3526 388.094 84.3526C380.102 84.3526 377.942 90.7606 377.942 96.5926V114.017H368.87V76.3606H376.862L377.726 82.4086C379.454 78.0166 383.342 75.7846 387.734 75.7846Z"
            fill={renderColor}
          />
          <path
            d="M423.215 102.497H432.215C430.271 110.201 423.071 114.737 413.855 114.737C401.687 114.737 393.767 106.889 393.767 95.1526C393.767 83.4886 401.687 75.6406 413.351 75.6406C424.295 75.6406 431.783 82.6246 431.783 92.9206C431.783 94.2166 431.639 95.0806 431.423 96.3046H402.911V97.0246C402.911 103.721 407.303 107.465 413.783 107.465C418.391 107.465 421.847 105.521 423.215 102.497ZM413.135 82.8406C407.951 82.8406 403.991 85.8646 403.127 90.5446H422.495C422.423 86.0086 418.535 82.8406 413.135 82.8406Z"
            fill={renderColor}
          />
          <g clipPath="url(#clip1_9122_8267)">
            <path
              d="M208.414 9.94043H203.654V29.35H208.414V9.94043Z"
              fill={renderColor}
            />
            <path
              d="M208.414 3.42627H203.654V7.55243H208.414V3.42627Z"
              fill={renderColor}
            />
            <path
              d="M233.159 1.5166V29.35H228.957L228.578 26.4555C227.219 28.4614 224.424 29.7216 221.1 29.7216C215.131 29.7216 211.278 25.6396 211.278 19.7024C211.278 13.5781 215.432 9.56886 221.478 9.56886C224.347 9.56886 226.878 10.5328 228.391 12.2399V1.5166H233.159ZM228.399 19.9258V19.4789C228.399 15.8412 225.905 13.5027 222.239 13.5027C218.463 13.5027 216.123 15.9504 216.123 19.6244C216.123 23.4466 218.577 25.7851 222.205 25.7851C225.833 25.7851 228.399 23.5999 228.399 19.9258Z"
              fill={renderColor}
            />
            <path
              d="M241.637 19.1491L234.465 9.94057H240.376L244.94 15.9505C245.02 16.0544 245.124 16.1387 245.243 16.1967C245.362 16.2547 245.493 16.2849 245.625 16.2849C245.758 16.2849 245.889 16.2547 246.008 16.1967C246.126 16.1387 246.23 16.0544 246.31 15.9505L251.028 9.93018H256.772L249.486 19.1335C249.368 19.2817 249.304 19.4647 249.304 19.6532C249.304 19.8416 249.368 20.0246 249.486 20.1728L256.809 29.3372H250.991L246.26 23.4156C246.18 23.3136 246.077 23.2311 245.959 23.1743C245.841 23.1175 245.712 23.088 245.58 23.088C245.449 23.088 245.32 23.1175 245.202 23.1743C245.084 23.2311 244.981 23.3136 244.901 23.4156L240.249 29.3424H234.465L241.637 20.1702C241.751 20.0235 241.813 19.8441 241.813 19.6597C241.813 19.4752 241.751 19.2958 241.637 19.1491V19.1491Z"
              fill={renderColor}
            />
            <path
              d="M198.883 9.94042L202.059 14.1545H195.696C195.212 14.1545 194.748 14.3434 194.406 14.6796C194.064 15.0158 193.872 15.6594 193.872 16.1349V29.35H189.204V15.8387C189.204 11.5384 191.661 9.93262 194.731 9.93262L198.883 9.94042Z"
              fill={renderColor}
            />
            <path
              d="M181.395 9.94026L181.093 12.6503C179.771 10.7951 177.127 9.57129 173.916 9.57129C168.098 9.57129 164.285 13.5779 164.285 19.1851C164.285 24.7508 168.101 28.76 173.916 28.76C176.939 28.76 179.62 27.5726 180.829 25.7174V28.6847C180.829 31.8027 178.488 33.7333 174.974 33.7333C173.022 33.7333 171.488 33.0915 170.441 32.1743C170.275 32.0261 169.939 31.7455 169.939 31.7455L166.895 34.7856C167.158 35.0508 167.441 35.2966 167.741 35.5209C169.449 36.7967 171.859 37.5216 174.9 37.5216C181.395 37.5216 185.589 34.1074 185.589 28.5028V9.94026H181.395ZM180.829 19.3307C180.829 22.7449 178.224 24.9353 174.86 24.9353C171.234 24.9353 169.119 22.5214 169.119 19.1462C169.119 15.6566 171.385 13.3934 174.9 13.3934C178.261 13.3934 180.829 15.4721 180.829 18.8863V19.3307Z"
              fill={renderColor}
            />
          </g>
          <defs>
            <clipPath id="clip0_9122_8267">
              <rect width={134} height={134} fill="white" />
            </clipPath>
            <clipPath id="clip1_9122_8267">
              <rect
                width={99}
                height={39}
                fill="white"
                transform="translate(162 0.0166016)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    } else if (showBrandName) {
      return (
        <svg
          width={436}
          height={135}
          viewBox="0 0 436 135"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#clip0_9094_5741)">
            <path
              d="M71.9364 110.984C70.5845 112.335 68.7532 113.094 66.8439 113.094C64.9347 113.094 63.1033 112.335 61.7514 110.984L42.3267 91.5061L23.4356 72.5931C22.0874 71.2385 21.3304 69.4035 21.3304 67.4905C21.3304 65.5775 22.0874 63.7425 23.4356 62.388L42.6622 43.1082L61.7514 23.9813C63.1033 22.6304 64.9347 21.8719 66.8439 21.8719C68.7532 21.8719 70.5845 22.6304 71.9364 23.9813L88.571 40.6486L103.666 25.5243L87.0463 8.85694C81.6846 3.50114 74.4225 0.493652 66.8516 0.493652C59.2806 0.493652 52.0185 3.50114 46.6569 8.85694L27.5676 27.9992L8.34109 47.2789C2.99676 52.6485 -0.00439453 59.9224 -0.00439453 67.5058C-0.00439453 75.0891 2.99676 82.3631 8.34109 87.7327L27.2322 106.646L46.6569 126.124C52.0166 131.484 59.2794 134.494 66.8516 134.494C74.4237 134.494 81.6866 131.484 87.0463 126.124L103.239 109.9L88.144 94.7754L71.9364 110.984Z"
              fill={renderColor}
            />
            <path
              d="M110.543 62.3872C111.214 63.0559 111.747 63.8509 112.11 64.7266C112.474 65.6023 112.661 66.5413 112.661 67.4897C112.661 68.4381 112.474 69.3772 112.11 70.2529C111.747 71.1285 111.214 71.9236 110.543 72.5923L93.3442 89.8249L108.439 104.949L125.637 87.7166C130.989 82.351 133.995 75.0756 133.995 67.4897C133.995 59.9039 130.989 52.6285 125.637 47.2628L108.866 30.458L93.7711 45.5824L110.543 62.3872Z"
              fill={renderColor}
            />
            <path
              d="M82.1262 56.94L77.9805 52.7946L66.9956 41.8105V95.4027L93.7938 68.6066L82.1262 56.94Z"
              fill={renderColor}
            />
            <path
              d="M91.1359 68.6066L66.9842 93.2702L42.3187 68.6066L66.9842 43.943L91.1359 68.6066Z"
              stroke={renderColor}
              strokeWidth={3}
            />
          </g>
          <path
            d="M167.688 89.5V37.3H204.768V45.724H177.264V59.476H202.608V67.396H177.264V81.076H204.768V89.5H167.688Z"
            fill={renderColor}
          />
          <path
            d="M209.474 89.5L222.578 70.708L209.474 51.844H219.698L228.77 65.236L238.13 51.844H248.066L234.746 70.708L248.138 89.5H238.058L228.698 76.324L219.482 89.5H209.474Z"
            fill={renderColor}
          />
          <path
            d="M277.043 51.124C288.131 51.124 295.763 58.9 295.763 70.492C295.763 82.372 287.915 90.22 276.323 90.22C270.851 90.22 266.027 88.276 263.147 85.036V103.9H254.075V51.844H262.139L262.787 57.316C265.523 53.5 270.779 51.124 277.043 51.124ZM274.955 82.588C281.939 82.588 286.547 78.124 286.547 70.708C286.547 63.508 282.083 58.756 274.955 58.756C268.043 58.756 263.147 63.004 263.147 70.132V70.996C263.147 77.98 267.827 82.588 274.955 82.588Z"
            fill={renderColor}
          />
          <path d="M303.567 89.5V35.5H312.639V89.5H303.567Z" fill={renderColor} />
          <path
            d="M320.237 70.636C320.237 58.972 328.661 51.124 340.829 51.124C352.997 51.124 361.421 58.972 361.421 70.636C361.421 82.372 352.997 90.22 340.829 90.22C328.661 90.22 320.237 82.372 320.237 70.636ZM329.525 70.708C329.525 77.98 334.061 82.372 340.901 82.372C347.885 82.372 352.205 77.692 352.205 70.636C352.205 63.364 347.669 58.9 340.973 58.9C333.917 58.9 329.525 63.58 329.525 70.708Z"
            fill={renderColor}
          />
          <path
            d="M387.734 51.268C388.742 51.268 389.75 51.412 390.326 51.556V60.052C389.822 59.908 388.886 59.836 388.094 59.836C380.102 59.836 377.942 66.244 377.942 72.076V89.5H368.87V51.844H376.862L377.726 57.892C379.454 53.5 383.342 51.268 387.734 51.268Z"
            fill={renderColor}
          />
          <path
            d="M423.215 77.98H432.215C430.271 85.684 423.071 90.22 413.855 90.22C401.687 90.22 393.767 82.372 393.767 70.636C393.767 58.972 401.687 51.124 413.351 51.124C424.295 51.124 431.783 58.108 431.783 68.404C431.783 69.7 431.639 70.564 431.423 71.788H402.911V72.508C402.911 79.204 407.303 82.948 413.783 82.948C418.391 82.948 421.847 81.004 423.215 77.98ZM413.135 58.324C407.951 58.324 403.991 61.348 403.127 66.028H422.495C422.423 61.492 418.535 58.324 413.135 58.324Z"
            fill={renderColor}
          />
          <defs>
            <clipPath id="clip0_9094_5741">
              <rect
                width={134}
                height={134}
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    } else {
      return (
        <svg
          width={64}
          height={64}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#clip0_8564_4237)">
            <path
              d="M34.3576 52.7686C33.7119 53.4137 32.8372 53.776 31.9254 53.776C31.0135 53.776 30.1388 53.4137 29.4931 52.7686L20.2156 43.4655L11.193 34.4324C10.5491 33.7854 10.1875 32.909 10.1875 31.9953C10.1875 31.0816 10.5491 30.2052 11.193 29.5583L20.3759 20.3501L29.4931 11.2148C30.1388 10.5696 31.0135 10.2073 31.9254 10.2073C32.8372 10.2073 33.7119 10.5696 34.3576 11.2148L42.3025 19.1753L49.5118 11.9517L41.5742 3.99123C39.0135 1.43324 35.545 -0.00317383 31.929 -0.00317383C28.313 -0.00317383 24.8446 1.43324 22.2838 3.99123L13.1665 13.1338L3.9837 22.342C1.43119 24.9066 -0.00219727 28.3807 -0.00219727 32.0026C-0.00219727 35.6245 1.43119 39.0986 3.9837 41.6632L13.0063 50.6963L22.2838 59.9994C24.8436 62.5592 28.3125 63.9968 31.929 63.9968C35.5456 63.9968 39.0144 62.5592 41.5742 59.9994L49.3079 52.2505L42.0985 45.0269L34.3576 52.7686Z"
              fill={renderColor}
            />
            <path
              d="M52.7965 29.5581C53.1171 29.8775 53.3716 30.2572 53.5452 30.6754C53.7188 31.0937 53.8081 31.5422 53.8081 31.9951C53.8081 32.4481 53.7188 32.8966 53.5452 33.3148C53.3716 33.7331 53.1171 34.1128 52.7965 34.4322L44.5823 42.6627L51.7916 49.8862L60.0059 41.6558C62.562 39.093 63.9978 35.6182 63.9978 31.9951C63.9978 28.3721 62.562 24.8972 60.0059 22.3345L51.9955 14.3083L44.7862 21.5319L52.7965 29.5581Z"
              fill={renderColor}
            />
            <path
              d="M39.2244 26.9565L37.2443 24.9766L31.9978 19.7305V45.3267L44.7969 32.5286L39.2244 26.9565Z"
              fill={renderColor}
            />
            <path
              d="M42.4307 32.5286L31.9864 43.1942L21.3199 32.5286L31.9864 21.8629L42.4307 32.5286Z"
              stroke={renderColor}
              strokeWidth={3}
            />
          </g>
          <defs>
            <clipPath id="clip0_8564_4237">
              <rect width={64} height={64} fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    }
  }

  if (showGridX) {
    return (
      <svg
        width={435}
        height={134}
        viewBox="0 0 435 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_9122_8268)">
          <path
            d="M71.9364 110.484C70.5845 111.835 68.7532 112.594 66.8439 112.594C64.9347 112.594 63.1033 111.835 61.7514 110.484L42.3267 91.0061L23.4356 72.0931C22.0874 70.7385 21.3304 68.9035 21.3304 66.9905C21.3304 65.0775 22.0874 63.2425 23.4356 61.888L42.6622 42.6082L61.7514 23.4813C63.1033 22.1304 64.9347 21.3719 66.8439 21.3719C68.7532 21.3719 70.5845 22.1304 71.9364 23.4813L88.571 40.1486L103.666 25.0243L87.0463 8.35694C81.6846 3.00114 74.4225 -0.00634766 66.8516 -0.00634766C59.2806 -0.00634766 52.0185 3.00114 46.6569 8.35694L27.5676 27.4992L8.34109 46.7789C2.99676 52.1485 -0.00439453 59.4224 -0.00439453 67.0058C-0.00439453 74.5891 2.99676 81.8631 8.34109 87.2327L27.2322 106.146L46.6569 125.624C52.0166 130.984 59.2794 133.994 66.8516 133.994C74.4237 133.994 81.6866 130.984 87.0463 125.624L103.239 109.4L88.144 94.2754L71.9364 110.484Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M110.543 61.8872C111.214 62.5559 111.747 63.3509 112.11 64.2266C112.474 65.1023 112.661 66.0413 112.661 66.9897C112.661 67.9381 112.474 68.8772 112.11 69.7529C111.747 70.6285 111.214 71.4236 110.543 72.0923L93.3442 89.3249L108.439 104.449L125.637 87.2166C130.989 81.851 133.995 74.5756 133.995 66.9897C133.995 59.4039 130.989 52.1285 125.637 46.7628L108.866 29.958L93.7711 45.0824L110.543 61.8872Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M82.1262 56.44L77.9805 52.2946L66.9956 41.3105V94.9027L93.7938 68.1066L82.1262 56.44Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M40.1973 68.1066L66.9954 94.9027V41.3105L40.1973 68.1066Z"
            fill={renderColor || '#D4E9EC'}
          />
        </g>
        <path
          d="M167.688 114.017V61.8166H204.768V70.2406H177.264V83.9926H202.608V91.9126H177.264V105.593H204.768V114.017H167.688Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M209.474 114.017L222.578 95.2246L209.474 76.3606H219.698L228.77 89.7526L238.13 76.3606H248.066L234.746 95.2246L248.138 114.017H238.058L228.698 100.841L219.482 114.017H209.474Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M277.043 75.6406C288.131 75.6406 295.763 83.4166 295.763 95.0086C295.763 106.889 287.915 114.737 276.323 114.737C270.851 114.737 266.027 112.793 263.147 109.553V128.417H254.075V76.3606H262.139L262.787 81.8326C265.523 78.0166 270.779 75.6406 277.043 75.6406ZM274.955 107.105C281.939 107.105 286.547 102.641 286.547 95.2246C286.547 88.0246 282.083 83.2726 274.955 83.2726C268.043 83.2726 263.147 87.5206 263.147 94.6486V95.5126C263.147 102.497 267.827 107.105 274.955 107.105Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M303.567 114.017V60.0166H312.639V114.017H303.567Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M320.237 95.1526C320.237 83.4886 328.661 75.6406 340.829 75.6406C352.997 75.6406 361.421 83.4886 361.421 95.1526C361.421 106.889 352.997 114.737 340.829 114.737C328.661 114.737 320.237 106.889 320.237 95.1526ZM329.525 95.2246C329.525 102.497 334.061 106.889 340.901 106.889C347.885 106.889 352.205 102.209 352.205 95.1526C352.205 87.8806 347.669 83.4166 340.973 83.4166C333.917 83.4166 329.525 88.0966 329.525 95.2246Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M387.734 75.7846C388.742 75.7846 389.75 75.9286 390.326 76.0726V84.5686C389.822 84.4246 388.886 84.3526 388.094 84.3526C380.102 84.3526 377.942 90.7606 377.942 96.5926V114.017H368.87V76.3606H376.862L377.726 82.4086C379.454 78.0166 383.342 75.7846 387.734 75.7846Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M423.215 102.497H432.215C430.271 110.201 423.071 114.737 413.855 114.737C401.687 114.737 393.767 106.889 393.767 95.1526C393.767 83.4886 401.687 75.6406 413.351 75.6406C424.295 75.6406 431.783 82.6246 431.783 92.9206C431.783 94.2166 431.639 95.0806 431.423 96.3046H402.911V97.0246C402.911 103.721 407.303 107.465 413.783 107.465C418.391 107.465 421.847 105.521 423.215 102.497ZM413.135 82.8406C407.951 82.8406 403.991 85.8646 403.127 90.5446H422.495C422.423 86.0086 418.535 82.8406 413.135 82.8406Z"
          fill={renderColor || '#344145'}
        />
        <g clipPath="url(#clip1_9122_8268)">
          <path
            d="M208.414 9.94043H203.654V29.35H208.414V9.94043Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M208.414 3.42627H203.654V7.55243H208.414V3.42627Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M233.159 1.5166V29.35H228.957L228.578 26.4555C227.219 28.4614 224.424 29.7216 221.1 29.7216C215.131 29.7216 211.278 25.6396 211.278 19.7024C211.278 13.5781 215.432 9.56886 221.478 9.56886C224.347 9.56886 226.878 10.5328 228.391 12.2399V1.5166H233.159ZM228.399 19.9258V19.4789C228.399 15.8412 225.905 13.5027 222.239 13.5027C218.463 13.5027 216.123 15.9504 216.123 19.6244C216.123 23.4466 218.577 25.7851 222.205 25.7851C225.833 25.7851 228.399 23.5999 228.399 19.9258Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M241.637 19.1491L234.465 9.94057H240.376L244.94 15.9505C245.02 16.0544 245.124 16.1387 245.243 16.1967C245.362 16.2547 245.493 16.2849 245.625 16.2849C245.758 16.2849 245.889 16.2547 246.008 16.1967C246.126 16.1387 246.23 16.0544 246.31 15.9505L251.028 9.93018H256.772L249.486 19.1335C249.368 19.2817 249.304 19.4647 249.304 19.6532C249.304 19.8416 249.368 20.0246 249.486 20.1728L256.809 29.3372H250.991L246.26 23.4156C246.18 23.3136 246.077 23.2311 245.959 23.1743C245.841 23.1175 245.712 23.088 245.58 23.088C245.449 23.088 245.32 23.1175 245.202 23.1743C245.084 23.2311 244.981 23.3136 244.901 23.4156L240.249 29.3424H234.465L241.637 20.1702C241.751 20.0235 241.813 19.8441 241.813 19.6597C241.813 19.4752 241.751 19.2958 241.637 19.1491Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M198.883 9.94042L202.059 14.1545H195.696C195.212 14.1545 194.748 14.3434 194.406 14.6796C194.064 15.0158 193.872 15.6594 193.872 16.1349V29.35H189.204V15.8387C189.204 11.5384 191.661 9.93262 194.731 9.93262L198.883 9.94042Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M181.395 9.94026L181.093 12.6503C179.771 10.7951 177.127 9.57129 173.916 9.57129C168.098 9.57129 164.285 13.5779 164.285 19.1851C164.285 24.7508 168.101 28.76 173.916 28.76C176.939 28.76 179.62 27.5726 180.829 25.7174V28.6847C180.829 31.8027 178.488 33.7333 174.974 33.7333C173.022 33.7333 171.488 33.0915 170.441 32.1743C170.275 32.0261 169.939 31.7455 169.939 31.7455L166.895 34.7856C167.158 35.0508 167.441 35.2966 167.741 35.5209C169.449 36.7967 171.859 37.5216 174.9 37.5216C181.395 37.5216 185.589 34.1074 185.589 28.5028V9.94026H181.395ZM180.829 19.3307C180.829 22.7449 178.224 24.9353 174.86 24.9353C171.234 24.9353 169.119 22.5214 169.119 19.1462C169.119 15.6566 171.385 13.3934 174.9 13.3934C178.261 13.3934 180.829 15.4721 180.829 18.8863V19.3307Z"
            fill={renderColor || '#344145'}
          />
        </g>
        <defs>
          <clipPath id="clip0_9122_8268">
            <rect width={134} height={134} fill="white" />
          </clipPath>
          <clipPath id="clip1_9122_8268">
            <rect
              width={99}
              height={39}
              fill="white"
              transform="translate(162 0.0166016)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (showBrandName) {
    return (
      <svg
        width={436}
        height={134}
        viewBox="0 0 436 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_9087_4575)">
          <path
            d="M71.9364 110.484C70.5845 111.835 68.7532 112.594 66.8439 112.594C64.9347 112.594 63.1033 111.835 61.7514 110.484L42.3267 91.0061L23.4356 72.0931C22.0874 70.7385 21.3304 68.9035 21.3304 66.9905C21.3304 65.0775 22.0874 63.2425 23.4356 61.888L42.6622 42.6082L61.7514 23.4813C63.1033 22.1304 64.9347 21.3719 66.8439 21.3719C68.7532 21.3719 70.5845 22.1304 71.9364 23.4813L88.571 40.1486L103.666 25.0243L87.0463 8.35694C81.6846 3.00114 74.4225 -0.00634766 66.8516 -0.00634766C59.2806 -0.00634766 52.0185 3.00114 46.6569 8.35694L27.5676 27.4992L8.34109 46.7789C2.99676 52.1485 -0.00439453 59.4224 -0.00439453 67.0058C-0.00439453 74.5891 2.99676 81.8631 8.34109 87.2327L27.2322 106.146L46.6569 125.624C52.0166 130.984 59.2794 133.994 66.8516 133.994C74.4237 133.994 81.6866 130.984 87.0463 125.624L103.239 109.4L88.144 94.2754L71.9364 110.484Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M110.543 61.8872C111.214 62.5559 111.747 63.3509 112.11 64.2266C112.474 65.1023 112.661 66.0413 112.661 66.9897C112.661 67.9381 112.474 68.8772 112.11 69.7529C111.747 70.6285 111.214 71.4236 110.543 72.0923L93.3442 89.3249L108.439 104.449L125.637 87.2166C130.989 81.851 133.995 74.5756 133.995 66.9897C133.995 59.4039 130.989 52.1285 125.637 46.7628L108.866 29.958L93.7711 45.0824L110.543 61.8872Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M82.1262 56.44L77.9805 52.2946L66.9956 41.3105V94.9027L93.7938 68.1066L82.1262 56.44Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M40.1973 68.1066L66.9954 94.9027V41.3105L40.1973 68.1066Z"
            fill={renderColor || '#D4E9EC'}
          />
        </g>
        <path
          d="M167.688 89V36.8H204.768V45.224H177.264V58.976H202.608V66.896H177.264V80.576H204.768V89H167.688Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M209.474 89L222.578 70.208L209.474 51.344H219.698L228.77 64.736L238.13 51.344H248.066L234.746 70.208L248.138 89H238.058L228.698 75.824L219.482 89H209.474Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M277.043 50.624C288.131 50.624 295.763 58.4 295.763 69.992C295.763 81.872 287.915 89.72 276.323 89.72C270.851 89.72 266.027 87.776 263.147 84.536V103.4H254.075V51.344H262.139L262.787 56.816C265.523 53 270.779 50.624 277.043 50.624ZM274.955 82.088C281.939 82.088 286.547 77.624 286.547 70.208C286.547 63.008 282.083 58.256 274.955 58.256C268.043 58.256 263.147 62.504 263.147 69.632V70.496C263.147 77.48 267.827 82.088 274.955 82.088Z"
          fill={renderColor || '#344145'}
        />
        <path d="M303.567 89V35H312.639V89H303.567Z" fill={renderColor || '#344145'} />
        <path
          d="M320.237 70.136C320.237 58.472 328.661 50.624 340.829 50.624C352.997 50.624 361.421 58.472 361.421 70.136C361.421 81.872 352.997 89.72 340.829 89.72C328.661 89.72 320.237 81.872 320.237 70.136ZM329.525 70.208C329.525 77.48 334.061 81.872 340.901 81.872C347.885 81.872 352.205 77.192 352.205 70.136C352.205 62.864 347.669 58.4 340.973 58.4C333.917 58.4 329.525 63.08 329.525 70.208Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M387.734 50.768C388.742 50.768 389.75 50.912 390.326 51.056V59.552C389.822 59.408 388.886 59.336 388.094 59.336C380.102 59.336 377.942 65.744 377.942 71.576V89H368.87V51.344H376.862L377.726 57.392C379.454 53 383.342 50.768 387.734 50.768Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M423.215 77.48H432.215C430.271 85.184 423.071 89.72 413.855 89.72C401.687 89.72 393.767 81.872 393.767 70.136C393.767 58.472 401.687 50.624 413.351 50.624C424.295 50.624 431.783 57.608 431.783 67.904C431.783 69.2 431.639 70.064 431.423 71.288H402.911V72.008C402.911 78.704 407.303 82.448 413.783 82.448C418.391 82.448 421.847 80.504 423.215 77.48ZM413.135 57.824C407.951 57.824 403.991 60.848 403.127 65.528H422.495C422.423 60.992 418.535 57.824 413.135 57.824Z"
          fill={renderColor || '#344145'}
        />
        <defs>
          <clipPath id="clip0_9087_4575">
            <rect width={134} height={134} fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 64 64"
        {...props}
      >
        <path
          fill={renderColor || '#344145'}
          d="M34.358 52.769a3.441 3.441 0 0 1-4.865 0l-9.277-9.303-9.023-9.034a3.455 3.455 0 0 1 0-4.874l9.183-9.208 9.117-9.135a3.441 3.441 0 0 1 4.865 0l7.945 7.96 7.209-7.223-7.938-7.96A13.647 13.647 0 0 0 31.93-.004a13.648 13.648 0 0 0-9.645 3.994l-9.117 9.143-9.183 9.208a13.695 13.695 0 0 0-3.986 9.66c0 3.622 1.433 7.097 3.986 9.661l9.022 9.033L22.284 60a13.64 13.64 0 0 0 9.645 3.998 13.64 13.64 0 0 0 9.645-3.998l7.734-7.748-7.21-7.224-7.74 7.742Z"
        />
        <path
          fill={renderColor || '#FFA44F'}
          d="M52.797 29.558a3.438 3.438 0 0 1 .748 3.757 3.437 3.437 0 0 1-.748 1.117l-8.215 8.23 7.21 7.224 8.214-8.23a13.68 13.68 0 0 0 3.992-9.66 13.68 13.68 0 0 0-3.992-9.662l-8.01-8.026-7.21 7.224 8.01 8.026Z"
        />
        <path
          fill={renderColor || '#FFA44F'}
          d="m39.224 26.956-1.98-1.98-5.246-5.245v25.596l12.799-12.798-5.573-5.573Z"
        />
        <path fill={renderColor || '#D4E9EC'} d="m19.198 32.529 12.8 12.798V19.73l-12.8 12.798Z" />
      </svg>
    );
  }
};
export default Explore;
