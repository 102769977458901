import React from 'react';
import classnames from 'classnames';

// HELPERS
import { TableProps, getColumnStyles } from './Table.helpers';

// ASSETS
import styles from './Table.module.scss';

const Table:React.FC<TableProps<any>> = ({
  columns,
  dataSource,
  tableLayout = 'auto',
  showHeader = true,
  columnPinning = { left: [], right: [] }
}) => {
  const getStickyStyle = (key: string) => {
    let leftWidth = 0;
    let rightWidth = 0;

    if (columnPinning?.left?.includes(key)) {
      columns.forEach((col) => {
        if (col.key === key) return;
        if (columnPinning?.left?.includes(col.key)) {
          leftWidth += Number(col.width) || 100;
        }
      });
      return { left: `${leftWidth}px` };
    }

    if (columnPinning.right?.includes(key)) {
      columns.slice().reverse().forEach((col) => {
        if (col.key === key) return;
        if (columnPinning?.right?.includes(col.key)) {
          rightWidth += Number(col.width) || 100;
        }
      });
      return { right: `${rightWidth}px` };
    }

    return {};
  };

  return (
    <div className={styles.table}>
      <div className={styles.container}>
        <table className='sb-unstyled' style={{ tableLayout }}>
          {
            showHeader && (
              <thead className={styles['table-head']}>
                <tr>
                  {
                    columns.map((column) => {
                      const cssTableCell = classnames(styles['table-cell'], column.className, {
                        [styles['align-right']]: column.align === 'right',
                        [styles.sticky]: columnPinning.left?.includes(column.key) || columnPinning.right?.includes(column.key)
                      });

                      const stickyStyle = getStickyStyle(column.key);

                      return (
                        <th key={column.key} className={cssTableCell} scope="col" style={{ ...getColumnStyles(column), ...stickyStyle }}>
                          {column.title}
                        </th>
                      );
                    })
                  }
                </tr>
              </thead>
            )
          }
          <tbody className={styles['table-body']}>
            {
              dataSource.map((data) => {
                return (
                  <tr key={data.key} data-row-key="1">
                    {
                      columns.map((column) => {
                        const cssTableCell = classnames(styles['table-cell'], column.className, {
                          [styles['align-right']]: column.align === 'right',
                          [styles.sticky]: columnPinning.left?.includes(column.key) || columnPinning.right?.includes(column.key)
                        });

                        const stickyStyle = getStickyStyle(column.key);

                        return (
                          <td key={column.key} className={cssTableCell} style={{ ...getColumnStyles(column), ...stickyStyle }}>
                            {column.render ? column.render(column.dataIndex ? data[column.dataIndex] : data) : data[column.dataIndex]}
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
