import React from 'react';

// COMPONENTS
import { Tabs } from 'components';
import { useDispatch } from 'react-redux';

// ACTIONS
import { setBillingFilter } from 'store/modules/billing/billing.slice';

const BillingTabsFilter = () => {
  const dispatch = useDispatch();

  const tabs = [
    {
      content: '',
      label: 'Pre-Billing'
    },
    {
      content: '',
      label: 'Pending Approval'
    },
    {
      content: '',
      label: 'Approved'
    }
  ];

  // EVENTS
  const handleTabChange = (event: Record<string, any>) => {
    if (event.target.innerText === 'Pre-Billing') {
      dispatch(setBillingFilter({ key: 'status', type: 'radio', value: 'Pre-Billing' }));
    } else if (event.target.innerText === 'Pending Approval') {
      dispatch(setBillingFilter({ key: 'status', type: 'radio', value: 'Pending approval' }));
    } else if (event.target.innerText === 'Approved') {
      dispatch(setBillingFilter({ key: 'status', type: 'radio', value: 'Approved' }));
    } else if (event.target.innerText === 'History') {
      dispatch(setBillingFilter({ key: 'status', type: 'radio', value: 'History' }));
    }
  };

  return (
    <Tabs tabs={tabs} onChange={handleTabChange}/>
  );
};

export default BillingTabsFilter;
