import React, { useContext } from 'react';

// COMPONENTS
import FooterNavigation from 'widget/ExploreWidget/components/FooterNavigation/FooterNavigation';
import ImplementationItem from 'widget/ExploreWidget/components/ImplementationItem/ImplementationItem';
import Button from 'components/Button/Button';
import BatteryImplementation from './components/BatteryImplementation/BatteryImplementation';
import HeatPumpImplementation from './components/HeatPumpImplementation/HeatPumpImplementation';
import WaterHeaterImplementation from './components/WaterHeaterImplementation/WaterHeaterImplementation';
import ElectricDryerImplementation from './components/ElectricDryerImplementation/ElectricDryerImplementation';
import RangeImplementation from './components/RangeImplementation/RangeImplementation';
import SolarImplementation from './components/SolarImplementation/SolarImplementation';
import EvImplementation from './components/EvImplementation/EvImplementation';
import Box from 'components/Box/Box';

// ASSETS
import styles from './ImplementationPage.module.scss';

// HELPERS
import { ExploreContext } from 'widget/ExploreWidget/helpers/ExploreContext';
import { getModifierKey } from 'widget/ExploreWidget/helpers/utils/getModifierIcon';

const ImplementationPage = () => {
  const { state } = useContext(ExploreContext);

  return (
    <div className={styles.container}>
      <p className={styles.title}>Ready to get Started? Prepare for your clean energy technologies.</p>
      <div className={styles.implementations}>
        {
          state.appliedResource?.map((resource: any) => {
            switch (getModifierKey(resource.name)) {
            case 'solar':
              return (
                <SolarImplementation key={resource.id} modifier={resource}/>
              );
            case 'ev':
              return (
                <EvImplementation key={resource.id} modifier={resource}/>
              );
            case 'battery':
              return (
                <BatteryImplementation key={resource.id} modifier={resource}/>
              );
            case 'heatPump':
              return (
                <HeatPumpImplementation key={resource.id} modifier={resource}/>
              );
            case 'waterHeater':
              return (
                <WaterHeaterImplementation key={resource.id} modifier={resource}/>
              );
            case 'dryer':
              return (
                <ElectricDryerImplementation key={resource.id} modifier={resource}/>
              );
            case 'range':
              return (
                <RangeImplementation key={resource.id} modifier={resource}/>
              );
            }

            return null;
          })
        }
      </div>
      <p className={styles.title}>Enroll in the optimal rate plan for your electrification journey.</p>
      <div className={styles.implementations}>
        <ImplementationItem
          title="The Select Pricing Plan is the best rate for your clean energy scenario"
          type='rate'
          footer={
            <div className={styles['content-footer']}>
              <Button
                copy="Enroll in Select Pricing Plan"
                variant="outlined"
                color="secondary"
              />
            </div>
          }
        >
          <div className={styles['content-quote']}>
            <div className={styles['content-text']}>
              <p className={styles['content-title']}>Learn about the Select Pricing Plan</p>
              <p className={styles['content-description']}>
                The Select Pricing Plan (SPP) is a billing option that offers new ways to manage your energy use and costs. Here’s how the plan differs from our traditional electric rates.
              </p>
              <br/>
              <p className={styles['content-description']}>
              In the past, the only way to reduce your energy costs was to cut back on your overall energy use—something that we know can be difficult. Now, with the Select Pricing Plan you’ll save if:
              </p>
              <ul>
                <li>Your energy use is consistent over the course of the day, avoiding large spikes that come from running major appliances and devices all at once</li>
                <li>You reduce your energy use when possible during peak hours (Monday through Friday, noon to 8 p.m.)</li>
              </ul>
              <p className={styles['content-description']}>
                <a href="https://www.coned.com/en/accounts-billing/select-pricing-plan" target='_blank' rel="noreferrer">Learn more about the Select Pricing Plan</a>
              </p>
            </div>
          </div>
        </ImplementationItem>
      </div>
      <Box mt={4.5}>
        <FooterNavigation
          description='Click “Download summary” to ... ipsum dolor sit amet, consectetur adipiscing elit.'
        />
      </Box>
    </div>
  );
};

export default ImplementationPage;
