import React from 'react';
import { Route } from 'react-router-dom';

// COMPONENTS
import AccordionPage from 'pages/Metronome/AccordionPage';
import AlertInlinePage from 'pages/Metronome/AlertInlinePage';
import AlertStickyPage from 'pages/Metronome/AlertStickyPage';
import ButtonPage from 'pages/Metronome/ButtonPage';
import TilePage from 'pages/Metronome/TilePage';
import ColorPage from 'pages/Metronome/ColorPage';
import DatePickerPage from 'pages/Metronome/DatePickerPage';
import DividerPage from 'pages/Metronome/DividerPage';
import FiltersPage from 'pages/Metronome/FiltersPage';
import GridPage from 'pages/Metronome/GridPage';
import InputPage from 'pages/Metronome/InputPage';
import KeyValuePairPage from 'pages/KeyValuePairPage';
import ShadowPage from 'pages/Metronome/ShadowPage';
import SideSheetPage from 'pages/Metronome/SideSheetPage';
import SpacingPage from 'pages/Metronome/SpacingPage';
import SpacingUsagePage from 'pages/Metronome/SpacingUsagePage';
import TagPage from 'pages/Metronome/TagPage';
import Tooltip from 'pages/Metronome/TooltipPage';
import TypographyPage from 'pages/Metronome/TypographyPage';
import FourOFourPage from 'pages/FourOFourPage';

const MetronomeRoutes = [
  <Route key="accordion" path='/metronome/accordion' element={<AccordionPage />} />,
  <Route key="alert-inline" path='/metronome/alert-inline' element={<AlertInlinePage />} />,
  <Route key="alert-sticky" path='/metronome/alert-sticky' element={<AlertStickyPage />} />,
  <Route key="button" path='/metronome/button' element={<ButtonPage />} />,
  <Route key="tile" path='/metronome/tile' element={<TilePage />} />,
  <Route key="color" path='/metronome/color' element={<ColorPage />} />,
  <Route key="datepicker" path='/metronome/datepicker' element={<DatePickerPage />} />,
  <Route key="divider" path='/metronome/divider' element={<DividerPage />} />,
  <Route key="filters" path='/metronome/filters' element={<FiltersPage />} />,
  <Route key="grid" path='/metronome/grid' element={<GridPage />} />,
  <Route key="input" path='/metronome/input' element={<InputPage />} />,
  <Route key="key-value-pairs" path='/metronome/key-value-pairs' element={<KeyValuePairPage />} />,
  <Route key="shadow" path='/metronome/shadow' element={<ShadowPage />} />,
  <Route key="side-sheet" path='/metronome/side-sheet' element={<SideSheetPage />} />,
  <Route key="spacing" path='/metronome/spacing' element={<SpacingPage />} />,
  <Route key="spacing-usage" path='/metronome/spacing-usage' element={<SpacingUsagePage />} />,
  <Route key="tag" path='/metronome/tag' element={<TagPage />} />,
  <Route key="tooltip" path='/metronome/tooltip' element={<Tooltip />} />,
  <Route key="typography" path='/metronome/typography' element={<TypographyPage />} />,
  <Route key="up" path='/up' element={<FourOFourPage />} />
];

export default MetronomeRoutes;
