import React, { useEffect, useState } from 'react';
import { Placement } from 'popper.js';

// COMPONENTS
import Popover from 'components/Popover/Popover';

// ASSETS
import './tooltip.scss';
import Box from 'components/Box/Box';

interface Props {
  className?: string,
  copy?: string,
  tip?: string,
  type?: string,
  offset?: number | string;
  children: React.ReactElement,
  placement?: Placement;
  isOpen?: boolean,
  trigger?: 'click' | 'hover' | 'focus',
};

/**
* Represents a Tooltip.
* @constructor
* @param {string} className
* @param {string} copy
* @param {string} tip
* @param {string} type
* Usage :
* ````js
* <Tooltip className='my-tooltip' tip='none' type='light' />
* ````
* @augments {Component<Props, State>}
*/
/** */
const Tooltip: React.FC<Props> = ({
  className,
  copy,
  tip = 'bottom-left',
  placement = 'top-start',
  type = 'light',
  isOpen,
  offset = -30,
  children,
  trigger = 'click',
  ...props
}: Props) => {
  const [active, setActive] = useState(Boolean(isOpen));

  useEffect(() => {
    setActive(Boolean(isOpen));
  }, [isOpen]);

  // EVENTS
  const handleAnchorTrigger = () => {
    if (isOpen === undefined) {
      setActive(!active);
    }
  };

  const handleOutsideClick = () => {
    if (isOpen === undefined) {
      setActive(false);
    }
  };

  // STYLES
  let boxMargin;

  if (tip.includes('top')) {
    boxMargin = {
      mt: 1
    };
  } else if (tip.includes('bottom')) {
    boxMargin = {
      mb: 1
    };
  }

  return (
    <Popover
      isOpen={active}
      renderReference={
        <div
          role="presentation"
          onClick={trigger === 'click' ? handleAnchorTrigger : undefined}
          onMouseEnter={trigger === 'hover' ? handleAnchorTrigger : undefined}
          onFocus={trigger === 'focus' ? handleAnchorTrigger : undefined}
          style={{ display: 'flex' }}
        >
          {children}
        </div>
      }
      onOutsideClickClose={handleOutsideClick}
      p={0}
      placement={placement}
      offset={offset}
      zIndex={999}
      noCard
      isPortal
      fullWidth
    >
      <Box {...boxMargin} display="flex">
        <div className={`tooltip ${className || ''} ${tip} ${type}`} {...props}>{copy}</div>
      </Box>
    </Popover>
  );
};

export default Tooltip;
