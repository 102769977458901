import React from 'react';

import './logo.scss';

interface Props {
    align?: 'left' | 'center' | 'right' | undefined,
    ratio?: 'square' | 'rectangle' | undefined,
    className?: string,
    name?: string,
    size?: string,
    backgroundImage?: string,
    width?: string | number,
    backgroundSize?: 'auto' | 'cover' | 'contain' | string;
};

/**
* Represents a Navigation.
* @constructor
* @param {string} className
* @param {string} name
* @param {string} size
* Usage :
* ````js
* <Logo className="my-navigation" />
* ````
* @augments {Component<Props, State>}
*/
/** */
const Logo: React.FC<Props> = ({ align, className, name, ratio, size, backgroundImage = '', width = '', backgroundSize = '' }: Props) => {
  return (
    <div
      className={
        `${className || ''} 
        logo 
        ${!align || `logo--${align}`}
        ${!name || `logo--${name}`}
        ${!ratio || `logo--${ratio}`}
        ${!size || `logo--${size}`}`
      }
      aria-hidden
      role='presentation'
      style={{
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : '',
        backgroundSize: backgroundSize || '',
        width: width ? `${width}px` : ''
      }}
    >
      <span className='visually-hidden'>{name}</span>
    </div>
  );
};

export default React.memo(Logo);
