import React from 'react';

// ASSETS
import styles from './AdditionalBlock.module.scss';

const AdditionalBlock = () => {
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.body__left}></div>
        <div className={styles.body__right}>
          <div className={styles['body__right-item']}></div>
          <div className={styles['body__right-item']}></div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalBlock;
