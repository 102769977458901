import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';
import { useNavigate } from 'react-router-dom';

// TYPINGS
import { Project } from 'api/systems/systems.typings';

// COMPONENTS
import { ListItem } from 'blocks/components';
import CustomerDataItem from '../../../../../Analyze/components/CustomerDataItem/CustomerDataItem';
import Tag from 'components/Tag/Tag';
import ListShowMore from 'components/ListShowMore/ListShowMore';

// ASSETS
import styles from './ListProjects.module.scss';

// HELPERS
import { getCustomerTypeColor, getCustomerTypeCustomColor, getCustomerTypeTextColor } from './ListProjects.helpers';
import { useTheme } from 'hooks/Theme/Theme.context';

interface ListCustomersProps {
  projects: Array<Project>
}

const ListProjects:React.FC<ListCustomersProps> = ({ projects }) => {
  const { getThemeColor, hasThemePalette, slug } = useTheme();
  const [limitShow, setLimitShow] = useState(10);
  const navigate = useNavigate();

  // EVENTS
  const handleShowMore = () => {
    setLimitShow(limitShow + 10);
  };

  const handleProjectClick = (projectID: string | number) => {
    navigate(`/${slug}/data/${projectID}`);
  };

  // RENDERS
  const filteredProjects = projects.slice(0, limitShow);

  return (
    <>
      <AnimatePresence>
        <motion.ul
          className={styles['list-container']}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {
            filteredProjects.map((project) => {
              return (
                <motion.li
                  key={project.id}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.5 }}
                >
                  <ListItem
                    onBodyClick={() => handleProjectClick(project.projectID)}
                    selectable
                    columns={[
                      { body: <CustomerDataItem title="Project ID" value={project.projectID} />, col: 2 },
                      { body: <CustomerDataItem title="Allocation" value={project.allocation} />, col: 2 },
                      { body: <CustomerDataItem title="Updated" value={project.updated} />, col: 2 },
                      { body: <CustomerDataItem title="Address" value={project.address} />, col: 3 },
                      {
                        body: (
                          <div className={styles['customer-type']}>
                            <Tag
                              copy={project.status}
                              color={getCustomerTypeColor(project.status)}
                              backgroundColor={getCustomerTypeCustomColor(project.status, getThemeColor)}
                              textColor={getCustomerTypeTextColor(project.status, hasThemePalette)}
                            />
                          </div>
                        ),
                        col: 3
                      }
                    ]}
                  />
                </motion.li>
              );
            })
          }
        </motion.ul>
      </AnimatePresence>
      <div className={styles['list-footer']}>
        <ListShowMore total={projects.length} current={limitShow} onShowMore={handleShowMore} />
      </div>
    </>
  );
};

export default ListProjects;
