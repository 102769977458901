import React from 'react';

// COMPONENTS
import { GraphCard, LegendGraphItem } from '../../../../../../blocks/components';
import Icon from '../../../../../../components/Icon/Icon';
import DonutChart from '../../../../../../components/DonutChart/DonutChart';

// ASSETS
import styles from '../../FullPopulation.module.scss';

// HELPERS
import { transformData } from './GraphQuality.helpers';
import { useTheme } from '../../../../../../hooks/Theme/Theme.context';

// TYPINGS
import { ICustomerData } from '../../../../../../api/analyze/analyze.typings';

interface GraphQualityProps {
  customers: Array<ICustomerData>;
}

const GraphQuality:React.FC<GraphQualityProps> = ({ customers }) => {
  const { getThemeColor } = useTheme();

  // RENDERS
  const graphData = transformData(customers, getThemeColor);
  return (
    <GraphCard
      title="Analysis quality"
      icon={<Icon.CheckCircle />}
    >
      <div className={styles['graph-container']}>
        <div className={styles['legend-container']}>
          {
            graphData.map((item) => {
              return (
                <LegendGraphItem key={item.name} name={item.name} color={item.color as string} />
              );
            })
          }
        </div>
        <div>
          <DonutChart
            data={graphData}
            height={240}
          />
        </div>
      </div>
    </GraphCard>
  );
};

export default GraphQuality;
