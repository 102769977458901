import React from 'react';

// COMPONENTS
import { FilterSearchBar } from 'blocks/components';
import ListMeterAccounts from '../ListMeterAccounts/ListMeterAccounts';
import { Box, Button, Icon } from 'components';

// ASSETS
import styles from './MeterAccounts.module.scss';

// HELPERS
import { useSingleAccount } from 'api/accounts/accounts.api';
import { useParams } from 'react-router-dom';
import { useListWidth } from 'hooks/useListWidth';

const MeterAccounts = () => {
  // const { data: meterAccountsData, isLoading } = useMeterAccounts();
  const { accountId } = useParams();
  // const { slug } = useTheme();
  const { data: accountData, isLoading } = useSingleAccount(accountId || '');
  const listWidth = useListWidth(false);

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <h3>Delivery Point</h3>
        <div className={styles.actions}>
          <Button size="md" copy='Delivery Point' type="primary" color="depth" icon={<Icon.Plus />} iconPosition="left" />
          <Button size="md" copy='Upload' type="secondary" color="depth" icon={<Icon.Upload />} iconPosition="left" />
        </div>
      </div>
      <div className={styles.filters}>
        <FilterSearchBar searchPlaceholder='Search networks'/>
      </div>
      {(!isLoading && accountData?.meterAccounts) && (
        <div id="mainListArea" className={styles['main-list-area']}>
          <div></div>
          <Box width={listWidth}>
            <ListMeterAccounts accounts={accountData.meterAccounts} aria-label="List of Virtual Meters" />
          </Box>
        </div>
      )}
    </div>
  );
};

export default MeterAccounts;
