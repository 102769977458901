export const columnsTou = [
  {
    accessorKey: 'tou',
    header: 'TOU',
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    accessorKey: 'delivered',
    id: 'delivered',
    header: 'Delivered',
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    accessorKey: 'received',
    header: 'Received',
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    accessorKey: 'net',
    header: 'Net',
    enableColumnFilter: false,
    enableSorting: false
  }
];

export const touData = [
  {
    tou: 'Off Peak',
    delivered: '34.038.67 kWh',
    received: '5.761.75 kWh',
    net: '28.276.92 kWh'
  },
  {
    tou: 'Peak',
    delivered: '34.038.67 kWh',
    received: '7.044.25 kWh',
    net: '7.609.25 kWh'
  }
];

export const columnsRegister = [
  {
    accessorKey: 'register',
    header: 'Register',
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    accessorKey: 'start',
    id: 'start',
    header: 'Start',
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    accessorKey: 'stop',
    header: 'Stop',
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    accessorKey: 'constant',
    header: 'Constant',
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    accessorKey: 'use',
    header: 'Use',
    enableColumnFilter: false,
    enableSorting: false
  }
];

export const registerData = [
  {
    register: 'Delivered KWH',
    start: '2.933.5 kWh',
    stop: '3.187 kWh',
    constant: '200 kWh',
    use: '48.700 kWh'
  },
  {
    register: 'Delivered KVARH',
    start: '2.043.1 kWh',
    stop: '2.225.9 kWh',
    constant: '200 kWh',
    use: '36.560 kWh'
  },
  {
    register: 'Received KWH',
    start: '62.4 kWh',
    stop: '126.4 kWh',
    constant: '200 kWh',
    use: '12.800 kWh'
  },
  {
    register: 'Received KVARH',
    start: '0 kWh',
    stop: '0 kWh',
    constant: '200 kWh',
    use: '0 kWh'
  }
];

export const columnsDemand = [
  {
    accessorKey: 'tou',
    header: 'TOU',
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    accessorKey: 'maxDemandKW',
    id: 'maxDemandKW',
    header: 'Max Demand KW',
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    accessorKey: 'time',
    header: 'Time',
    enableColumnFilter: false,
    enableSorting: false
  }
];

export const demandData = [
  {
    tou: 'Peak',
    maxDemandKW: '154.33 KW',
    time: '2022-04-28 08:15 pm'
  },
  {
    tou: 'Off Peak',
    maxDemandKW: '143.33 KW',
    time: '2022-05-29 09:10 pm'
  }
];

export const columnsPower = [
  {
    accessorKey: 'tou',
    header: 'TOU',
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    accessorKey: 'powerFactor',
    id: 'powerFactor',
    header: 'Power Factor',
    enableColumnFilter: false,
    enableSorting: false
  }
];

export const powerData = [
  {
    tou: 'Peak',
    powerFactor: '88.4%'
  },
  {
    tou: 'Off Peak',
    powerFactor: '87.1%'
  }
];
