import React from 'react';

// COMPONENTS
import Text from 'components/Text/Text';
import Box from 'components/Box/Box';

// ASSETS
import styles from './MainPage.module.scss';

interface MainPageProps {
  children?: React.ReactNode;
  title: string;
}

const MainPage:React.FC<MainPageProps> = ({ children, title }) => {
  return (
    <div className={styles.container}>
      <div className={styles.mainPage}>
        <Box mb={3}>
          <Text appearance='headline-sm'>{title}</Text>
        </Box>
        {children}
      </div>
    </div>
  );
};

export default MainPage;
