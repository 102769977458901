import { useQuery } from '@tanstack/react-query';

// DATA
import dataEvents from './mocks/events.json';

// TYPINGS
import { IEvent } from './events.typings';
import { DataQueryParam } from 'api/helpers/api.typings';

// HELPERS
import { filterQueryData } from 'api/helpers/filterQueryData';

export const useEvents = (queryParams: DataQueryParam<IEvent>) => {
  return useQuery({
    queryKey: ['events'],
    queryFn: () => {
      return dataEvents;
    },
    select: filterQueryData(queryParams, 'ticket'),
    refetchOnWindowFocus: false
  });
};
