import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { PricingData, downloadFile, fetchRealTimeDataByRange, formatPrice } from './RealTimeData.helpers';
import { Button, DatePicker, Icon, Select, Text } from '@gridx/metronome-components';

interface PricingExportProps {
  chartData: PricingData[] | undefined
  pgeChartColors: string[]
  selectDate: string
  todayDate: string
  apiUrl: string
  utility: string
  market: string
  rate: string
  maxDate: Date
}

export const PricingExport = (props: PricingExportProps) => {
  const [firstTime, setFirstTime] = useState(true);
  const dateRangeStart = moment().subtract(1, 'd').format('MMM DD, YYYY');
  const [dateRange, setDateRange] = useState([dateRangeStart, props.todayDate]);
  const [average, setAverage] = useState('day');
  // This is when the data started, no data before this date
  const minDate = new Date('2023-08-01');
  const startDate = moment(!firstTime ? dateRange[0] : props.selectDate).format('YYYYMMDD');
  const endDate = moment(dateRange ? dateRange[1] : props.selectDate).format('YYYYMMDD');

  useEffect(() => {
    if (props.chartData && props.chartData.length) {
      setFirstTime(false);
    }
  }, [props.chartData]);

  const {
    isLoading: downloadIsLoading,
    isError: downloadIsError,
    data: downloadData
  } = fetchRealTimeDataByRange(props.apiUrl, props.utility, props.market, props.rate, startDate, endDate);

  const exportToCsv = () => {
    if (downloadData && downloadData.length) {
      const data = formatPrice(downloadData[0].priceDetails);
      const unwrappedDownloadedData = downloadData.map((data: any) => formatPrice(data.priceDetails));
      const hours: any[] = [];
      downloadData.map((data: any) => data.priceDetails.map((p: any) => hours.push(moment(p.startIntervalTimeStamp).tz('America/Los_Angeles').format('h A'))));
      const hourlyPrices: any[] = [];
      const dayData: any[] = [];
      const averageUpperCase = average.toUpperCase();
      const numberOfDays = moment(endDate).diff(moment(startDate), 'days');
      let dataRange = 31;
      const roundPrices = (prices: number[]) => {
        return prices.map((price: number) => {
          return `${Math.round(price * 100) / 100}`;
        });
      };

      if (average === 'week') {
        dataRange = 7;
      } else {
        dataRange = numberOfDays === 0 ? 1 : numberOfDays;
      }
      const returnDate = data.splice(0, dataRange).map((price: number, index: number) => {
        if (average === 'week' || average === 'month') {
          const weekDates = `${moment(startDate).add(index, 'd').tz('America/Los_Angeles').format('MM/DD/YYYY')}`;
          dayData.push(weekDates);
          return dayData;
        } else {
          unwrappedDownloadedData.map((prices: number[], index: number) => {
            const date = downloadData[index].priceDetails[0].startIntervalTimeStamp;
            const formattedDate = moment(date).tz('America/Los_Angeles').format('MM/DD/YYYY');

            const pricesByHour = prices.map((price: number) => {
              const formattedPrice = Math.round(price * 100) / 100;
              dayData.push(formattedDate);
              hourlyPrices.push(formattedPrice);

              return formattedDate;
            });

            return pricesByHour;
          });

          return dayData;
        }
      });

      const returnTime = hours.map((hour: string) => {
        if (average === 'day') {
          return hour;
        } else {
          return '12:00 AM';
        }
      });

      const toColumns = (data: object) => {
        const arrays = Object.values(data);
        const csvString = [Object.keys(data).join(','), ...arrays[0].map((row: object, i: number) =>
          arrays.map(column => column[i]).join(',')
        )].join('\n').substring(5);

        return csvString;
      };

      const timeLength = returnTime.length + 1;

      let dates = ['Date', ...returnDate];
      const times = ['Time (PT) ', ...returnTime];
      const averageCopy = average === 'week' ? 'Average ' : '';
      const generationPrice = [`${averageCopy}Generation Price (Cents per kwh)`, ...roundPrices(average === 'week' ? data : hourlyPrices)];

      if (returnDate[0]) {
        dates = ['Date', ...returnDate[0]];
      }

      const columns = toColumns([dates.splice(0, timeLength), times.splice(0, timeLength), generationPrice.splice(0, timeLength)]);
      const fileName = `HourlyFlexPricing_${props.utility}_${props.rate}_${moment(startDate).format('YYYY-MM-DD')}.csv`;

      downloadFile([`${averageUpperCase} DOWNLOAD`, columns], fileName.replace(/\s/g, ''), 'text/csv');
    }
  };

  const averagePriceOptions = [
    { label: 'Hourly', value: 'day' }
  ];

  averagePriceOptions.push({ label: 'Day Average', value: 'week' });

  return (
    <div className='flex--right bor--l-1 pad--l-24'>
      <Text
        appearance='title-sm'
        className='pad--b-4'
      >
        Download prices
      </Text>
      {/* DATE PICKER FOR DOWNLOAD */}
      <DatePicker
        className='mar--b-24'
        disabled={downloadIsLoading}
        size='md'
        format='MM-dd-y'
        dayPlaceholder='Datepicker'
        calendarIcon={<Icon.Calendar color={props.pgeChartColors[2]} />}
        isRange
        clearIcon={null}
        onChange={(value) => setDateRange(value)}
        maxDate={props.maxDate}
        minDate={minDate}
        value={dateRange}
      />
      <div className='mar--b-24 width--100-p'>
        <Select
          label={'Price Details'}
          options={averagePriceOptions}
          size='sm'
          onChange={(e: any, value: any) => setAverage(value)}
          value={average}
        />
      </div>
      {downloadIsError && (
        <div className='flex'>
          <Icon.AlertCircle />
          <Text>
            Error fetching data
          </Text>
        </div>
      )}
      <Button
        color='depth'
        className={`flex--right ${downloadIsLoading && 'icon--loader'}`}
        disabled={downloadIsLoading}
        copy='Download CSV'
        iconRight={downloadIsLoading ? <Icon.Loader /> : <Icon.Download size={16} className='mar--l-8' />}
        size='sm'
        type='secondary'
        onClick={() => exportToCsv()}
      />
    </div>
  );
};
