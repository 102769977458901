import React, { ReactNode } from 'react';

import './card.scss';

interface Props {
    children?: ReactNode,
    className?: string,
    size?: 'xs' | 'sm' | 'md' | 'lg',
    style?: object,
};

/**
* Represents a Card.
* @constructor
* @param {node} children
* @param {string} className
* @param {string} size
* @param {object} style
* Usage :
* ````js
* <Card className='my-card' children={<MyComponent />} />
* ````
* @augments {Component<Props, State>}
*/
/** */

const Card: React.FC<Props> = ({ className, children, size, style }: Props) => {
  return (
    <div className={`
        ${className || ''} 
        ${size ? ` card--${size} ` : ''} 
        card
        pad--b-20 pad--l-20 pad--r-20 pad--t-20
    `}
    style={style}
    >
      {children}
    </div>
  );
};

export default Card;
