import React from 'react';

// import { useTheme } from 'hooks/Theme/Theme.context';

// COMPONENTS
import Icon from 'components/Icon/Icon';
import Logo from 'components/Logo/Logo';
import Avatar from 'components/Avatar/Avatar';

import './header-nav.scss';

interface Props {}

const HeaderNav: React.FC<Props> = () => {
  return (
    <div className='desktop--main-nav-wrapper'>
      <div className='desktop--main-nav-container flex jus--space-between shadow--sm'>
        <div className='flex'>
          <div className='bor--r-1 flex flex--align-center jus--center gridx--logo-container'>
            <Logo
              className='dis--inline-block'
              name='gridx'
              size='sm'
            />
          </div>
          <Logo
            className='dis--inline-block mar--l-32 client--logo-hz'
            name='energyx'
            size='lg'
          />
        </div>
        <div className='flex flex--align-center'>
          <div className='notification message'>
            <Icon.Bell />
          </div>
          <Avatar className='mar--r-32 mar--l-40' alt="R" size='sm' />
        </div>
      </div>
    </div>
  );
};

export default HeaderNav;
