import colors from '../../../../../../style/colors';

export const data = [
  {
    key: 1,
    season: 'Winter',
    date: 'October 1 - April 30'
  },
  {
    key: 2,
    season: 'Spring',
    date: 'October 1 - April 30'
  },
  {
    key: 3,
    season: 'Summer',
    date: 'May 1 - September 30'
  },
  {
    key: 4,
    season: 'Fall',
    date: 'May 1 - September 30'
  }
];

export const getPeakColor = (peak: string): string => {
  switch (peak) {
  case 'Winter':
    return colors.air;
  case 'Spring':
    return colors.clean;
  case 'Summer':
    return colors.temper;
  case 'Fall':
    return colors.mauvelous;
  default:
    return '#000000';
  }
};
