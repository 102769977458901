import React from 'react';
import { Route } from 'react-router-dom';

// COMPONENTS
import { BillingDetails, BillingList } from 'pages/Billing/sections';
import { EventsList } from 'pages/Events/sections';

import { Account, AccountsList, MeterAccountDetails } from 'pages/Accounts/sections';
import { Project, Systems } from 'pages/DataIngestion/sections';

import CitadelHomepage from 'pages/CitadelHomepage';

import CitadelUsers from 'pages/CitadelUsers';
import { FullPopulation } from 'pages/Analyze/sections';
import ImpactAnalyze from 'pages/ImpactAnalysis';

const SeminoleRoutes = [
  <Route key="home" path='/seminole/home' element={<CitadelHomepage />} />,
  <Route key="accounts" path='/seminole/data' element={<Systems/>} />,
  <Route key="data-id" path='/seminole/data/:id' element={<Project/>} />,

  // Accounts
  <Route key="singleAccount" path='/seminole/accounts/:accountId' element={<Account/>} />,
  <Route key="accounts" path='/seminole/accounts' element={<AccountsList/>} />,

  // Billing
  <Route key="events" path='/seminole/billing' element={<BillingList />} />,
  <Route key="events" path='/seminole/billing/:id' element={<BillingDetails />} />,

  // Events
  <Route key="events" path='/seminole/events' element={<EventsList />} />,
  <Route key="invoicing" path='/seminole/invoicing' element={<div/>} />,

  <Route key="singleVirtualMeter" path='/seminole/accounts/:accountId/meteraccount/:meterAccountId' element={<MeterAccountDetails/>} />,
  <Route key="events" path='/seminole/events' element={<div />} />,
  <Route key="billing" path='/seminole/billing' element={<div/>} />,

  <Route key="insights" path='/seminole/insights' element={<FullPopulation />} />,
  <Route key="users" path='/seminole/users' element={<CitadelUsers />} />,
  <Route key="impact-analysis" path='/seminole/impact-analysis' element={<ImpactAnalyze />} />
];

export default SeminoleRoutes;
