import React from 'react';
import Highcharts from 'highcharts';
import Accessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';
import colors from '../../style/colors';

// HELPERS
import { useTheme } from '../../hooks/Theme/Theme.context';
import { Color, ColorCode } from 'hooks/Theme/Theme.model';

Accessibility(Highcharts);

interface ColumnStackedChartProps {
  series: any;
  width?: number | string | null;
  height?: number | string | null;
  yAxis?: Highcharts.YAxisOptions;
  xAxis?: Highcharts.XAxisOptions;
  chart?: Highcharts.ChartOptions;
  legend?: Highcharts.LegendOptions;
  title?: Highcharts.TitleOptions;
  tooltip?: Highcharts.TooltipOptions;
  accessibility?: Highcharts.AccessibilityOptions
}

const ColumnStackedChart:React.FC<ColumnStackedChartProps> = ({ series, height, width, yAxis, xAxis, chart, legend, title, tooltip, accessibility }) => {
  const { theme, getThemeColor } = useTheme();

  const options = {
    chart: {
      type: 'column',
      backgroundColor: getThemeColor(Color.LightGray, ColorCode.C100, colors.white),
      plotBackgroundColor: getThemeColor(Color.LightGray, ColorCode.C100, colors.white),
      height,
      width,
      ...chart
    },
    accessibility: {
      enabled: false,
      ...accessibility
    },
    title: {
      text: '',
      align: 'center',
      style: {
        color: colors.stone,
        fontSize: '12px',
        fontWeight: 600,
        fontFamily: Object.keys(theme).length > 0 ? theme.typography?.fontFamily : 'Aktifo-B-Medium'
      },
      ...title
    },
    subtitle: {
      text: ''
    },
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false,
      ...legend
    },
    xAxis: {
      visible: false,
      lineWidth: 0,
      tickWidth: 0,
      labels: {
        staggerLines: 1,
        style: {
          color: colors.stone,
          fontSize: '14px',
          fontWeight: 600,
          fontFamily: 'Aktifo-B, sans-serif'
        }
      },
      ...xAxis
    },
    yAxis: {
      title: '',
      gridLineWidth: 0,
      labels: {
        staggerLines: 1,
        style: {
          color: colors.stone,
          fontSize: '12px',
          fontFamily: 'Aktifo-B-Medium'
        }
      },
      ...yAxis
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      padding: 0,
      borderRadius: 0,
      borderWidth: 0,
      shadow: false,
      style: {
        backgroundColor: '#fff',
        opacity: 1
      },
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        return `
            <span class="donutTooltip">
              <span>${this.y}</span>
              <span>${this.series.name}</span>
            </span>
          `;
      },
      ...tooltip
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        pointPadding: 0.1,
        borderWidth: 0,
        groupPadding: 0
      }
    },
    series
  };
  return (
    <HighchartsReact highcharts={Highcharts} options={options} />
  );
};

export default ColumnStackedChart;
