import * as React from 'react';

const HomeGas = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 36 36"
    {...props}
  >
    <path
      fill="#FFA44F"
      stroke="#FFA44F"
      strokeWidth={0.75}
      d="m30.504 18.914-6.169-3.085a.75.75 0 0 0-1.006 1.006l1.774 3.547a3.053 3.053 0 0 1-.573 3.527.751.751 0 0 1-1.28-.53V21a.75.75 0 0 0-1.28-.53l-1.5 1.5a5.831 5.831 0 0 0-1.72 4.151v.258a5.872 5.872 0 0 0 5.871 5.871h1.52a7.943 7.943 0 0 0 6.623-3.544 5.862 5.862 0 0 0 .986-3.257v-1.284a5.839 5.839 0 0 0-3.246-5.25Zm1.746 6.535a4.36 4.36 0 0 1-.734 2.425 6.448 6.448 0 0 1-5.374 2.876h-1.52a4.37 4.37 0 0 1-4.372-4.371v-.258a4.343 4.343 0 0 1 1.28-3.09l.22-.22v.568a2.25 2.25 0 0 0 3.84 1.59 4.552 4.552 0 0 0 .855-5.258l-.768-1.534 4.156 2.079a4.344 4.344 0 0 1 2.417 3.91v1.283ZM15.098 5.694 3.44 14.108a.75.75 0 0 1-.878-1.216l11.658-8.414a3.94 3.94 0 0 1 4.562 0l11.659 8.414a.75.75 0 0 1-.88 1.216L17.903 5.694a2.426 2.426 0 0 0-2.804 0ZM18.75 31.5a.748.748 0 0 1-.75.75H9a3.753 3.753 0 0 1-3.75-3.75V15.577l1.5-1.083V28.5A2.253 2.253 0 0 0 9 30.75h9a.748.748 0 0 1 .75.75Z"
    />
  </svg>
);
export default HomeGas;
