import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import { Avatar, Box, Button, Drawer, Icon, Tag, TextInput } from 'components';
import AlertInline from 'components/AlertInline/AlertInline';
import { InfoItem } from 'blocks/components';

// ACTIONS
import { setApproveBilling, setApproveBillingOpen, setFirstApproveBilling } from 'store/modules/billing/billing.slice';

// SELECTORS
import { getIsApprovalBillingOpen } from 'store/modules/billing/billing.selector';

// ASSETS
import styles from './BillingApprovalDrawer.module.scss';

// HELPERS
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { getStatusCustomColor, getStatusTextColor } from 'pages/Billing/sections/BillingList/components/ListBilling/ListBilling.helpers';
import { useTheme } from 'hooks/Theme/Theme.context';

// TYPINGS
import { IBilling } from 'api/billing/billing.typings';

interface BillingApprovalDrawerProps {
  bill: IBilling
}

const BillingApprovalDrawer:React.FC<BillingApprovalDrawerProps> = ({ bill }) => {
  const { getThemeColor, hasThemePalette } = useTheme();
  const [firstApprove, setFirstApprove] = useState(false);
  const [loadingFirstApprove, setLoadingFirstApprove] = useState(false);
  const [secondApprove, setSecondApprove] = useState(false);
  const [loadingSecondApprove, setLoadingSecondApprove] = useState(false);
  const [uploadApprove, setUploadApprove] = useState(false);
  const [loadingUploadApprove, setLoadingUploadApprove] = useState(false);
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsApprovalBillingOpen);

  // EVENTS
  const handleClose = () => {
    dispatch(setApproveBillingOpen(false));
  };

  const handleFirstApprove = () => {
    setLoadingFirstApprove(true);
    setTimeout(() => {
      setLoadingFirstApprove(false);
      setFirstApprove(true);
      dispatch(setFirstApproveBilling());
    }, 2000);
  };

  const handleSecondApprove = () => {
    setLoadingSecondApprove(true);
    setTimeout(() => {
      setLoadingSecondApprove(false);
      setSecondApprove(true);
      dispatch(setApproveBilling());
    }, 2000);
  };

  const handleUpload = () => {
    setLoadingUploadApprove(true);
    setTimeout(() => {
      setLoadingUploadApprove(false);
      setUploadApprove(true);
    }, 2000);
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      width={770}
      onClose={handleClose}
      title="Approval"
    >
      <div className={styles['details-container']}>
        <div>
          <div className={styles['info-box']}>
            <InfoItem
              title="Status"
              description={
                <Tag
                  copy={bill.status}
                  size="sm"
                  backgroundColor={getStatusCustomColor(bill.status, getThemeColor)}
                  textColor={getStatusTextColor(bill.status, hasThemePalette)}
                />
              }
            />
          </div>
          <div className={styles['info-box']}>
            <InfoItem title="Bill ID" description={bill.billId} />
          </div>
        </div>
        <div>
          <div className={styles['info-box']}>
            <InfoItem title="Service Agreement ID" description={bill.saId} />
          </div>
          <div className={styles['info-box']}>
            <InfoItem title="Bill Cycle" description={`${bill.startDate} - ${bill.endDate}`} />
          </div>
        </div>
        <div>
          <div className={styles['info-box']}>
            <InfoItem title="Due by" description={bill.transactionDate} />
          </div>
        </div>
      </div>
      <div className={styles.steps}>
        <div className={styles.step}>
          <div className={styles['step-check']}>
            <CustomCheckbox checked id="here" />
          </div>
          <div>
            <p className={styles['step-title']}>
              <span>Pre-Billing</span>
              <Icon.Info size={16} />
            </p>
            <div>
              <p>New usage data uploaded and events have been managed in the system</p>
            </div>
          </div>
        </div>
        <div className={styles.step} data-enabled="true">
          <div className={styles['step-check']}>
            <CustomCheckbox checked={firstApprove} id="here" />
          </div>
          <div>
            <p className={styles['step-title']}>
              <span>First Approval</span>
              <Icon.Info size={16} />
            </p>
            <div>
              <div className={styles['approver-container']}>
                <p>Approver</p>
                <div className={styles['user-item']}>
                  <div className={styles['user-item__info']}>
                    <Avatar alt="parker-anderson" src='/images/login/parker-anderson.png' size="xs" />
                    <span>Parker Anderson</span>
                  </div>
                  <div>
                    <Icon.X size={20}/>
                  </div>
                </div>
                <Box mt={1} mb={1}>
                  <TextInput label="Comments" multiline rows={3} />
                </Box>
                <div className={styles['approver-footer']}>
                  <Box mb={2}>
                    {firstApprove && (<AlertInline alertMessage="Approved by Parker Anderson July 10th, 2023 @ 8:32AM" icon type="success" />)}
                  </Box>
                  <Box display="flex" justifyContent="flex-end">
                    {
                      !firstApprove && (
                        <Box mr={2}>
                          <Button
                            copy='Return for Review'
                            color='depth'
                            type='secondary'
                            size="md"
                          />
                        </Box>
                      )
                    }
                    <Button
                      copy={firstApprove ? 'Approved' : 'Approve'}
                      color='depth'
                      size="md"
                      onClick={handleFirstApprove}
                      disabled={firstApprove}
                      loading={loadingFirstApprove}
                    />
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.step} data-enabled={firstApprove ? 'true' : 'false'}>
          <div className={styles['step-check']}>
            <CustomCheckbox checked={secondApprove} id="here" />
          </div>
          <div>
            <p className={styles['step-title']}>
              <span>Second Approval</span>
              <Icon.Info size={16} />
            </p>
            <div>
              {
                firstApprove && (
                  <div className={styles['approver-container']}>
                    <p>Approver</p>
                    <div className={styles['user-item']}>
                      <div className={styles['user-item__info']}>
                        <Avatar alt="louis sims" src='/images/login/louis-simms.png' size="xs" />
                        <span>Louis Simms</span>
                      </div>
                      <div>
                        <Icon.X size={20}/>
                      </div>
                    </div>
                    <Box mt={1} mb={1}>
                      <TextInput label="Comments" multiline rows={3} />
                    </Box>
                    <div className={styles['approver-footer']}>
                      <Box mb={2}>
                        {
                          secondApprove && (
                            <AlertInline alertMessage="Approved by Parker Anderson July 10th, 2023 @ 8:32AM" icon type="success" />
                          )
                        }
                      </Box>
                      <Box display="flex" justifyContent="flex-end">
                        {
                          !secondApprove && (
                            <Box mr={2}>
                              <Button
                                copy='Return for Review'
                                color='depth'
                                type='secondary'
                                size="md"
                              />
                            </Box>
                          )
                        }
                        <Button
                          copy={secondApprove ? 'Approved' : 'Approve'}
                          color='depth'
                          size="md"
                          onClick={handleSecondApprove}
                          disabled={secondApprove}
                          loading={loadingSecondApprove}
                        />
                      </Box>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
        <div className={styles.step} data-enabled={secondApprove ? 'true' : 'false'}>
          <div className={styles['step-check']}>
            <CustomCheckbox checked={uploadApprove} id="here" />
          </div>
          <div>
            <p className={styles['step-title']}>
              <span>Uploaded to Sharepoint</span>
              <Icon.Info size={16} />
            </p>
            <div>
              <p>Once the bill is approved by the first and second approvers, the bill can be uploaded to share point</p>
            </div>
            <Box display="flex" justifyContent="flex-end" mb={6}>
              {
                secondApprove && (
                  <Button
                    disabled={uploadApprove}
                    copy="Upload to Sharepoint"
                    color="depth"
                    size="md"
                    onClick={handleUpload}
                    loading={loadingUploadApprove}
                  />
                )
              }
            </Box>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default BillingApprovalDrawer;
