import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import { FilterSearchBar } from 'blocks/components';

// ACTIONS

// SELECTORS
import { getBillingSearchString } from 'store/modules/billing/billing.selector';
import { setBillingSearchString, setSortedBilling, toogleFilter } from 'store/modules/billing/billing.slice';

const FilterSearchBilling = () => {
  const dispatch = useDispatch();
  const searchString = useSelector(getBillingSearchString);

  // EVENTS
  const handleOpenFilter = () => {
    dispatch(toogleFilter());
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setBillingSearchString(event.target.value));
  };

  // RENDERS
  const actionList = [
    {
      content: 'Bill ID',
      onClick: () => {
        dispatch(setSortedBilling({ sortKey: 'billId' }));
      }
    },
    {
      content: 'Service Agreement',
      onClick: () => {
        dispatch(setSortedBilling({ sortKey: 'serviceAgreement' }));
      }
    },
    {
      content: 'Bill Cycle Start',
      onClick: () => {
        dispatch(setSortedBilling({ sortKey: 'startDate' }));
      }
    },
    {
      content: 'Bill Cycle End',
      onClick: () => {
        dispatch(setSortedBilling({ sortKey: 'endDate' }));
      }
    }
  ];

  return (
    <FilterSearchBar
      searchPlaceholder='Search Billing'
      sortOptions={actionList}
      onSearch={handleSearch}
      searchString={searchString}
      onOpenFilter={handleOpenFilter}
    />
  );
};

export default FilterSearchBilling;
