import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// COMPONENTS
import RateSummaryLoading from '../RateSummaryLoading/RateSummaryLoading';
import TableRateCompare from '../TableRateCompare/TableRateCompare';

// ASSETS
import styles from './RatesComparisonTable.module.scss';

// HELPERS
import { useGetRateSummary } from '../../api/rateAnalysis.api';

const queryClient = new QueryClient();

interface RatesComparisonTableProps {
  contractId?: string;
  apiUrl?: string;
  content?: Object;
}

const RatesContainer:React.FC<RatesComparisonTableProps> = ({ contractId, apiUrl }) => {
  const [tableData, setTableData] = useState<any>();
  const { data, isLoading } = useGetRateSummary({
    contractId,
    apiUrl
  });

  useEffect(() => {
    if (data) {
      setTableData(data.data);
    }
  }, [isLoading]);

  if (isLoading) {
    return (
      <RateSummaryLoading/>
    );
  }

  return (
    <div className={styles.container}>
      <TableRateCompare data={tableData}/>
    </div>
  );
};

const RatesComparisonTable:React.FC<RatesComparisonTableProps> = ({ contractId, content, apiUrl }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {/* {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />} */}
      <RatesContainer
        contractId={contractId}
        content={content}
        apiUrl={apiUrl}
      />
    </QueryClientProvider>
  );
};

export default RatesComparisonTable;
