import React from 'react';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import FilterStatus from '../FilterStatus/FilterStatus';
import { FilterPanel } from 'blocks/components';
import FilterEffectiveDate from '../FilterEffectiveDate/FilterEffectiveDate';

// SELECTORS
import { getAccountsFilterOpen, getAccountsFilters } from 'store/modules/accounts/accounts.selector';

// ACTIONS
import { clearAccountFilter, toogleFilter } from 'store/modules/accounts/accounts.slice';

const AccountsFilterPanel = () => {
  const dispatch = useDispatch();
  const isFilterOpen = useSelector(getAccountsFilterOpen);
  const filters = useSelector(getAccountsFilters);

  return (
    <AnimatePresence>
      {isFilterOpen && (
        <motion.div
          initial={{ x: '-100%' }}
          animate={{ x: 0 }}
          exit={{ x: '-100%' }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          style={{ zIndex: 10 }}
        >
          <FilterPanel
            aria-label="Filter Accounts"
            onClose={() => dispatch(toogleFilter())}
            onClear={() => dispatch(clearAccountFilter())}
          >
            <FilterStatus selectedFilters={filters}/>
            <FilterEffectiveDate selectedFilters={filters} />
          </FilterPanel>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AccountsFilterPanel;
