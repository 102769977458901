import colors from 'style/colors';

export const sampleData = [
  {
    name: 'Business customers',
    y: 50,
    color: colors.clean
  },
  {
    name: 'Residential customers',
    y: 50,
    color: colors.wind
  }
];
