import React, { Fragment, useState } from 'react';

import DatePicker from '../../components/DatePicker/DatePicker';
import Header from '../../components/Header/Header';
import Link from '../../components/Link/Link';
import Icon from '../../components/Icon/Icon';
import Text from '../../components/Text/Text';

import './datepicker-page.scss';

const DatePickerPage: React.FC = () => {
  const [value, onChange] = useState([new Date(), new Date()]);

  const renderWeek = (numDays: number, range: boolean) => {
    const items = [];
    for (let i = 0; i < numDays; i++) {
      items.push(
        <Text
          className={`body--sm react-calendar__tile ${range ? 'react-calendar__tile--active' : ''}`}
          color='stone'
          key={i}
          variant='body1'
        >
          01
        </Text>
      );
    }

    return items;
  };

  const renderLabels = (labels: any[]) => {
    const labelsMap = labels.map((label, index) => {
      return (
        <Text
          className='body--sm'
          color='stone'
          key={index}
          variant='body1'
        >
          {label}
        </Text>
      );
    });

    return labelsMap;
  };

  const renderTiles = (tiles: { state: any; }[]) => {
    const tilesMap = tiles.map((tile: { state: string; }, index: number) => {
      const {
        state
      } = tile;

      return (
        <Text
          className='body--sm'
          color='stone'
          key={index}
          variant='body1'
        >
          <span className={`dis--block tile-sample tile-sample--${state}`}>
            01
          </span>
        </Text>
      );
    });

    return tilesMap;
  };

  return (
    <Fragment>
      <Header
        className='design-studio pad--b-100 pad--l-128 pad--t-100'
        color='stone'
        text='Datepicker'
      >
        <Text
          className='max-width--900 pad--t-40 title--sm'
          color='white'
          variant='body1'
        >
            ...
        </Text>
        <Link
          className='underline dis--block mar--t-60 body--xl'
          color='white'
          copy='See Our Guidelines'
          href='/'
        />
      </Header>
      <div className='mar--l-96 mar--t-80 pad--b-80 max-width--lg temp--min-width--md'>
        <div
          className='pad--b-80'
        >
          <aside className='button-page--aside pad--t-40'>
            <Text
              className='max-width--900 headline--lg'
              color='stone'
              variant='h1'
            >
              Datepicker
            </Text>
          </aside>
        </div>
        <section className='datepicker-page--section'>
          <div className='flex jus--space-between mar--b-36'>
            {renderLabels(['Default', 'Hover', 'Click', 'Focus', 'Range', 'Disabled'])}
          </div>
          <div className='flex jus--space-between'>
            {renderTiles([{
              state: 'default'
            },
            {
              state: 'hover'
            },
            {
              state: 'click'
            },
            {
              state: 'focus'
            },
            {
              state: 'range'
            },
            {
              state: 'disabled'
            }])}
          </div>
          <div className=''>
            <div className='flex mar--b-20 mar--t-80'>
              <Text
                className='body--sm dis--block week--label'
                color='stone'
                variant='body1'
              >
                Default
              </Text>
              <Text
                className='body--sm dis--block pad--l-28 week--label'
                color='stone'
                variant='body1'
              >
                Range
              </Text>
            </div>
            <div className='flex'>
              <div className='datepicker--week flex jus--space-between mar--l-8'>
                {renderWeek(7, false)}
              </div>
              <div className='datepicker--week flex jus--space-between mar--l-20'>
                {renderWeek(7, true)}
              </div>
            </div>
          </div>

        </section>
        <div
          className='mar--t-80'
        >
          {/* @ts-ignore */}
          <DatePicker dayPlaceholder='Datepicker' calendarIcon={<Icon.Calendar />} minDate={new Date()} onChange={onChange} value={value} />
        </div>
      </div>
    </Fragment>
  );
};

export default DatePickerPage;
