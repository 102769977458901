import React from 'react';

// COMPONENTS
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';

// ASSETS
import styles from './ListToolbar.module.scss';

interface ListToolbarProps {
  selectedCount: number;
  onSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rightSlot?: React.ReactNode;
}

const ListToolbar:React.FC<ListToolbarProps> = ({ selectedCount, onSelect, rightSlot }) => {
  return (
    <div className={styles['list-toolbar']}>
      <div className={styles['select-all']}>
        <div className={styles['list-selection']}>
          <CustomCheckbox id="listSelectAll" onChange={onSelect} />
        </div>
        <p>{selectedCount} selected</p>
      </div>
      <div>
        {rightSlot}
      </div>
    </div>
  );
};

export default ListToolbar;
