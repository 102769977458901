import * as React from 'react';

import colors from '../../../style/colors';

const Analyze = (props:any) => {
  const { color, showBrandName, showGridX } = props;

  let renderColor;
  switch (color) {
  case 'deep':
    renderColor = colors.deep;
    break;
  case 'energy':
    renderColor = colors.energy;
    break;
  case 'white':
    renderColor = colors.white;
    break;
  }

  if (showGridX) {
    return (
      <svg
        width={448}
        height={134}
        viewBox="0 0 448 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-brandrender="with-gridx"
        {...props}
      >
        <g clipPath="url(#clip0_9120_7207)">
          <path
            d="M40.2151 70.8883L51.034 81.6928V53.3472L40.2151 64.1661C39.7728 64.607 39.4219 65.1308 39.1825 65.7076C38.9431 66.2843 38.8198 66.9027 38.8198 67.5272C38.8198 68.1517 38.9431 68.77 39.1825 69.3468C39.4219 69.9235 39.7728 70.4474 40.2151 70.8883Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M63.5684 40.0051L52.7495 50.8096H81.1095L70.3051 40.0051C69.8639 39.5606 69.3391 39.2079 68.7611 38.9672C68.183 38.7265 67.563 38.6025 66.9368 38.6025C66.3106 38.6025 65.6905 38.7265 65.1124 38.9672C64.5343 39.2079 64.0096 39.5606 63.5684 40.0051Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M30.2783 36.6303V59.956L60.4848 29.7495H37.1303C35.3104 29.7571 33.5676 30.4855 32.2835 31.775C30.9993 33.0646 30.2783 34.8104 30.2783 36.6303Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M96.8349 29.7495H73.5093L103.716 59.956V36.6303C103.716 35.7267 103.538 34.832 103.192 33.9972C102.846 33.1624 102.339 32.4038 101.7 31.7649C101.061 31.1259 100.303 30.6191 99.4681 30.2733C98.6332 29.9275 97.7385 29.7495 96.8349 29.7495Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M103.716 97.4489V74.1089L73.5093 104.315H96.8349C97.7378 104.317 98.6322 104.141 99.467 103.797C100.302 103.453 101.06 102.947 101.7 102.309C102.339 101.672 102.846 100.914 103.192 100.08C103.538 99.2459 103.716 98.3519 103.716 97.4489Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M36.8689 104.587H60.2089L30.0024 74.3809V97.7209C30.0024 99.542 30.7259 101.288 32.0136 102.576C33.3013 103.864 35.0478 104.587 36.8689 104.587Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M51.3822 6.72641L36.3511 21.7142H98.798L83.7668 6.72641C81.6409 4.59922 79.1166 2.91178 76.3383 1.76048C73.5599 0.609186 70.582 0.0166016 67.5745 0.0166016C64.5671 0.0166016 61.5891 0.609186 58.8108 1.76048C56.0324 2.91178 53.5082 4.59922 51.3822 6.72641Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M127.303 50.2621L112.271 35.231V97.6778L127.303 82.6467C129.43 80.5208 131.117 77.9965 132.269 75.2181C133.42 72.4398 134.012 69.4618 134.012 66.4544C134.012 63.447 133.42 60.469 132.269 57.6906C131.117 54.9123 129.43 52.388 127.303 50.2621Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M82.5975 127.336L97.7152 112.305H35.2539L50.2994 127.336C54.5876 131.609 60.3947 134.009 66.4485 134.009C72.5023 134.009 78.3093 131.609 82.5975 127.336Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M6.69218 83.7414L21.7233 98.7869V36.3833L6.69218 51.4288C2.41461 55.7176 0.0124512 61.5278 0.0124512 67.5851C0.0124512 73.6425 2.41461 79.4526 6.69218 83.7414Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M70.305 94.5052L81.124 83.7007H52.7495L63.5684 94.5052C64.0096 94.9496 64.5343 95.3024 65.1124 95.5431C65.6905 95.7838 66.3106 95.9077 66.9368 95.9077C67.563 95.9077 68.183 95.7838 68.7611 95.5431C69.3391 95.3024 69.8639 94.9496 70.305 94.5052Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M93.1993 63.8941L82.3804 53.0752V81.4352L93.1993 70.6163C94.0855 69.7221 94.5827 68.5141 94.5827 67.2552C94.5827 65.9963 94.0855 64.7883 93.1993 63.8941Z"
            fill={renderColor || '#D4E9EC'}
          />
        </g>
        <path
          d="M204.048 114.017L200.088 103.289H177.408L173.448 114.017H163.08L183.6 61.8166H193.608L214.776 114.017H204.048ZM180.216 95.8006H197.28L188.712 72.7606L180.216 95.8006Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M220.474 114.017V76.3606H228.826L229.618 81.8326C231.85 78.0166 236.458 75.6406 241.786 75.6406C250.858 75.6406 255.538 81.2566 255.538 90.3286V114.017H246.466V91.6246C246.466 85.9366 243.442 83.2726 238.834 83.2726C233.29 83.2726 229.546 87.2326 229.546 93.7846V114.017H220.474Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M263.666 103.721C263.666 97.1686 267.986 93.1366 275.69 92.2726L288.794 90.8326V89.1766C288.794 84.8566 285.482 82.8406 280.802 82.8406C275.69 82.8406 272.882 85.3606 273.026 89.4646H264.674C264.458 81.0406 270.722 75.6406 281.594 75.6406C291.602 75.6406 297.866 80.2486 297.866 89.4646V114.017H289.946L289.442 107.321C287.498 111.929 282.17 114.737 275.978 114.737C268.562 114.737 263.666 110.417 263.666 103.721ZM272.666 102.497C272.666 105.665 274.754 107.681 278.786 107.681C284.402 107.681 288.794 103.937 288.794 98.3926V95.9446L278.21 97.3126C274.25 97.8166 272.666 99.7606 272.666 102.497Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M307.504 114.017V60.0166H316.576V114.017H307.504Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M322.297 76.3606H332.737L344.329 98.6806L355.777 76.3606H366.145L337.777 128.417H327.553L339.289 107.177L322.297 76.3606Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M382.911 106.529H401.415V114.017H370.095V112.073L387.879 83.8486H370.527V76.3606H400.911V78.1606L382.911 106.529Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M435.52 102.497H444.52C442.576 110.201 435.376 114.737 426.16 114.737C413.992 114.737 406.072 106.889 406.072 95.1526C406.072 83.4886 413.992 75.6406 425.656 75.6406C436.6 75.6406 444.088 82.6246 444.088 92.9206C444.088 94.2166 443.944 95.0806 443.728 96.3046H415.216V97.0246C415.216 103.721 419.608 107.465 426.088 107.465C430.696 107.465 434.152 105.521 435.52 102.497ZM425.44 82.8406C420.256 82.8406 416.296 85.8646 415.432 90.5446H434.8C434.728 86.0086 430.84 82.8406 425.44 82.8406Z"
          fill={renderColor || '#344145'}
        />
        <g clipPath="url(#clip1_9120_7207)">
          <path
            d="M208.414 9.94043H203.654V29.35H208.414V9.94043Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M208.414 3.42627H203.654V7.55243H208.414V3.42627Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M233.159 1.5166V29.35H228.957L228.578 26.4555C227.219 28.4614 224.424 29.7216 221.1 29.7216C215.131 29.7216 211.278 25.6396 211.278 19.7024C211.278 13.5781 215.432 9.56886 221.478 9.56886C224.347 9.56886 226.878 10.5328 228.391 12.2399V1.5166H233.159ZM228.399 19.9258V19.4789C228.399 15.8412 225.905 13.5027 222.239 13.5027C218.463 13.5027 216.123 15.9504 216.123 19.6244C216.123 23.4466 218.577 25.7851 222.205 25.7851C225.833 25.7851 228.399 23.5999 228.399 19.9258Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M241.637 19.1491L234.465 9.94057H240.376L244.94 15.9505C245.02 16.0544 245.124 16.1387 245.243 16.1967C245.362 16.2547 245.493 16.2849 245.625 16.2849C245.758 16.2849 245.889 16.2547 246.008 16.1967C246.126 16.1387 246.23 16.0544 246.31 15.9505L251.028 9.93018H256.772L249.486 19.1335C249.368 19.2817 249.304 19.4647 249.304 19.6532C249.304 19.8416 249.368 20.0246 249.486 20.1728L256.809 29.3372H250.991L246.26 23.4156C246.18 23.3136 246.077 23.2311 245.959 23.1743C245.841 23.1175 245.712 23.088 245.58 23.088C245.449 23.088 245.32 23.1175 245.202 23.1743C245.084 23.2311 244.981 23.3136 244.901 23.4156L240.249 29.3424H234.465L241.637 20.1702C241.751 20.0235 241.813 19.8441 241.813 19.6597C241.813 19.4752 241.751 19.2958 241.637 19.1491Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M198.883 9.94042L202.059 14.1545H195.696C195.212 14.1545 194.748 14.3434 194.406 14.6796C194.064 15.0158 193.872 15.6594 193.872 16.1349V29.35H189.204V15.8387C189.204 11.5384 191.661 9.93262 194.731 9.93262L198.883 9.94042Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M181.395 9.94026L181.093 12.6503C179.771 10.7951 177.127 9.57129 173.916 9.57129C168.098 9.57129 164.285 13.5779 164.285 19.1851C164.285 24.7508 168.101 28.76 173.916 28.76C176.939 28.76 179.62 27.5726 180.829 25.7174V28.6847C180.829 31.8027 178.488 33.7333 174.974 33.7333C173.022 33.7333 171.488 33.0915 170.441 32.1743C170.275 32.0261 169.939 31.7455 169.939 31.7455L166.895 34.7856C167.158 35.0508 167.441 35.2966 167.741 35.5209C169.449 36.7967 171.859 37.5216 174.9 37.5216C181.395 37.5216 185.589 34.1074 185.589 28.5028V9.94026H181.395ZM180.829 19.3307C180.829 22.7449 178.224 24.9353 174.86 24.9353C171.234 24.9353 169.119 22.5214 169.119 19.1462C169.119 15.6566 171.385 13.3934 174.9 13.3934C178.261 13.3934 180.829 15.4721 180.829 18.8863V19.3307Z"
            fill={renderColor || '#344145'}
          />
        </g>
        <defs>
          <clipPath id="clip0_9120_7207">
            <rect width={134} height={134} fill="white" />
          </clipPath>
          <clipPath id="clip1_9120_7207">
            <rect
              width={99}
              height={39}
              fill="white"
              transform="translate(162 0.0166016)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (showBrandName) {
    return (
      <svg
        width={212}
        height={64}
        viewBox="0 0 212 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-brandrender="with-name"
        {...props}
      >
        <g clipPath="url(#clip0_9148_7144)">
          <path
            d="M19.2072 33.8573L24.3744 39.0177V25.4795L19.2072 30.6467C18.9959 30.8573 18.8283 31.1075 18.714 31.383C18.5996 31.6584 18.5408 31.9538 18.5408 32.252C18.5408 32.5503 18.5996 32.8456 18.714 33.1211C18.8283 33.3966 18.9959 33.6468 19.2072 33.8573V33.8573Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M30.3609 19.1064L25.1936 24.2667H38.7387L33.5783 19.1064C33.3676 18.8941 33.117 18.7256 32.8409 18.6107C32.5648 18.4957 32.2687 18.4365 31.9696 18.4365C31.6705 18.4365 31.3744 18.4957 31.0983 18.6107C30.8222 18.7256 30.5716 18.8941 30.3609 19.1064V19.1064Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M14.4612 17.4954V28.6359L28.8882 14.209H17.7338C16.8646 14.2126 16.0322 14.5605 15.4189 15.1764C14.8055 15.7923 14.4612 16.6261 14.4612 17.4954Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M46.2495 14.209H35.1089L49.5359 28.6359V17.4954C49.5359 17.0638 49.4508 16.6364 49.2857 16.2377C49.1205 15.839 48.8785 15.4767 48.5733 15.1715C48.2681 14.8664 47.9058 14.6243 47.5071 14.4591C47.1084 14.294 46.6811 14.209 46.2495 14.209V14.209Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M49.5359 46.543V35.3955L35.1089 49.8225H46.2495C46.6807 49.8234 47.1079 49.7392 47.5066 49.5748C47.9053 49.4104 48.2676 49.169 48.5729 48.8644C48.8781 48.5598 49.1203 48.1979 49.2856 47.7996C49.4508 47.4013 49.5359 46.9742 49.5359 46.543V46.543Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M17.6091 49.9524H28.7566L14.3296 35.5254V46.6729C14.3296 47.5426 14.6751 48.3768 15.2901 48.9918C15.9051 49.6068 16.7393 49.9524 17.6091 49.9524V49.9524Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M24.5406 3.2125L17.3616 10.3709H47.187L40.0079 3.2125C38.9925 2.19653 37.7869 1.39058 36.4599 0.84071C35.133 0.290838 33.7107 0.0078125 32.2743 0.0078125C30.8379 0.0078125 29.4156 0.290838 28.0886 0.84071C26.7616 1.39058 25.556 2.19653 24.5406 3.2125V3.2125Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M60.8011 24.0052L53.6221 16.8262V46.6515L60.8011 39.4725C61.8171 38.4571 62.623 37.2515 63.1729 35.9245C63.7228 34.5976 64.0058 33.1752 64.0058 31.7389C64.0058 30.3025 63.7228 28.8802 63.1729 27.5532C62.623 26.2262 61.8171 25.0206 60.8011 24.0052Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M39.4495 60.8177L46.6699 53.6387H16.8376L24.0236 60.8177C26.0717 62.8586 28.8452 64.0046 31.7366 64.0046C34.6279 64.0046 37.4014 62.8586 39.4495 60.8177V60.8177Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M3.19618 39.9957L10.3752 47.1817V17.377L3.19618 24.5629C1.15316 26.6113 0.00585938 29.3862 0.00585938 32.2793C0.00585938 35.1724 1.15316 37.9474 3.19618 39.9957Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M33.5783 45.1369L38.7456 39.9766H25.1936L30.3609 45.1369C30.5716 45.3492 30.8222 45.5177 31.0983 45.6326C31.3744 45.7476 31.6705 45.8068 31.9696 45.8068C32.2687 45.8068 32.5648 45.7476 32.8409 45.6326C33.117 45.5177 33.3676 45.3492 33.5783 45.1369Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M44.5132 30.5169L39.3459 25.3496V38.8947L44.5132 33.7274C44.9365 33.3004 45.1739 32.7234 45.1739 32.1222C45.1739 31.5209 44.9365 30.9439 44.5132 30.5169V30.5169Z"
            fill={renderColor || '#D4E9EC'}
          />
        </g>
        <path
          d="M96.9399 44.8955L95.0485 39.7717H84.2163L82.3249 44.8955H77.373L87.1736 19.9642H91.9536L102.064 44.8955H96.9399ZM85.5574 36.1954H93.7074L89.6152 25.1912L85.5574 36.1954Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M104.785 44.8955V26.9106H108.774L109.152 29.5241C110.218 27.7015 112.419 26.5667 114.964 26.5667C119.297 26.5667 121.532 29.249 121.532 33.5819V44.8955H117.199V34.2009C117.199 31.4842 115.755 30.2118 113.554 30.2118C110.906 30.2118 109.118 32.1032 109.118 35.2325V44.8955H104.785Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M125.414 39.978C125.414 36.8487 127.477 34.923 131.157 34.5103L137.415 33.8226V33.0317C137.415 30.9684 135.834 30.0055 133.598 30.0055C131.157 30.0055 129.816 31.2091 129.884 33.1692H125.895C125.792 29.1458 128.784 26.5667 133.977 26.5667C138.757 26.5667 141.748 28.7675 141.748 33.1692V44.8955H137.966L137.725 41.6975C136.796 43.8983 134.252 45.2394 131.294 45.2394C127.752 45.2394 125.414 43.1761 125.414 39.978ZM129.712 39.3934C129.712 40.9065 130.71 41.8694 132.635 41.8694C135.318 41.8694 137.415 40.0812 137.415 37.4333V36.2641L132.36 36.9175C130.469 37.1582 129.712 38.0867 129.712 39.3934Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M146.352 44.8955V19.1045H150.685V44.8955H146.352Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M153.417 26.9106H158.403L163.94 37.5709L169.408 26.9106H174.359L160.811 51.7732H155.927L161.533 41.6287L153.417 26.9106Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M182.367 41.3192H191.205V44.8955H176.246V43.9671L184.74 30.4869H176.452V26.9106H190.964V27.7703L182.367 41.3192Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M207.493 39.3934H211.792C210.863 43.073 207.425 45.2394 203.023 45.2394C197.211 45.2394 193.429 41.4911 193.429 35.8859C193.429 30.315 197.211 26.5667 202.782 26.5667C208.009 26.5667 211.586 29.9023 211.586 34.8198C211.586 35.4388 211.517 35.8515 211.414 36.4361H197.796V36.78C197.796 39.978 199.894 41.7662 202.989 41.7662C205.189 41.7662 206.84 40.8377 207.493 39.3934ZM202.679 30.0055C200.203 30.0055 198.312 31.4498 197.899 33.685H207.15C207.115 31.5186 205.258 30.0055 202.679 30.0055Z"
          fill={renderColor || '#344145'}
        />
        <defs>
          <clipPath id="clip0_9148_7144">
            <rect width={64} height={64} fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 65 65"
        {...props}
      >
        <path
          fill={renderColor || '#344145'}
          d="m19.736 34.374 5.168 5.16V25.998l-5.168 5.167a2.268 2.268 0 0 0 0 3.21ZM30.89 19.624l-5.167 5.16h13.545l-5.16-5.16a2.268 2.268 0 0 0-3.218 0Z"
        />
        <path
          fill={renderColor || '#FFA44F'}
          d="M14.99 18.012v11.141l14.427-14.427H18.263a3.286 3.286 0 0 0-3.273 3.286Z"
        />
        <path
          fill={renderColor || '#344145'}
          d="M46.778 14.726h-11.14l14.427 14.427v-11.14a3.286 3.286 0 0 0-3.287-3.287ZM50.065 47.06V35.913L35.638 50.34h11.14a3.28 3.28 0 0 0 3.287-3.28Z"
        />
        <path
          fill={renderColor || '#D4E9EC'}
          d="M18.138 50.47h11.147L14.858 36.041V47.19a3.28 3.28 0 0 0 3.28 3.28ZM25.07 3.73l-7.18 7.158h29.826l-7.18-7.158a10.934 10.934 0 0 0-15.467 0Z"
        />
        <path
          fill={renderColor || '#FFA44F'}
          d="m61.33 24.523-7.179-7.179V47.17l7.18-7.18a10.934 10.934 0 0 0 0-15.467Z"
        />
        <path
          fill={renderColor || '#344145'}
          d="m39.979 61.335 7.22-7.18H17.367l7.186 7.18a10.927 10.927 0 0 0 15.426 0ZM3.725 40.513l7.18 7.186V17.895l-7.18 7.186a10.927 10.927 0 0 0 0 15.432Z"
        />
        <path
          fill={renderColor || '#FFA44F'}
          d="m34.107 45.654 5.168-5.16H25.723l5.167 5.16a2.268 2.268 0 0 0 3.217 0Z"
        />
        <path
          fill={renderColor || '#D4E9EC'}
          d="m45.042 31.034-5.167-5.167v13.545l5.167-5.167a2.28 2.28 0 0 0 0-3.21Z"
        />
      </svg>
    );
  }
};

export default Analyze;
