import React, { ReactNode } from 'react';
import classnames from 'classnames';

import './tag.scss';

export type TagColors = 'primary' | 'secondary' | 'success' | 'warning' | 'alert' |
  'info-primary' | 'info-secondary' | 'info-tertiary' | 'cloud' | 'beacon' | 'bright' |
  'blush' | 'algae' | 'stem' | 'mist' | 'sky' | 'wave' | 'sheen' | 'pollen' | 'stone' |
  'relax' | 'heat' | 'altitude' | 'clean' | 'wind' | 'pale' | 'ocean' | 'invert-success';

interface TagProps {
  type?: 'default' | 'colorful' | 'outlined',
  className?: string,
  color?: TagColors
  copy?: string | React.ReactNode,
  icon?: ReactNode,
  isIconOnly?: boolean,
  iconPos?: 'left' | 'right' | null,
  size?: 'sm' | 'md' | 'lg',
  backgroundColor?: string,
  textColor?: string,
};

/**
* Represents a tag.
* @constructor
* @param {string} className
* @param {string} color
* @param {string} copy
* @param {object} icon
* @param {string} iconPos
* @param {string} size
* Usage :
* ````js
* <Tag className='my-tag' color='stone' size='sm' />
* ````
* @augments {Component<Props, State>}
*/
/** */
const Tag: React.FC<TagProps> = ({
  className,
  color,
  copy,
  icon,
  iconPos = 'left',
  size = 'sm',
  backgroundColor = '',
  textColor = '',
  type = 'default'
}) => {
  // STYLES
  const cssTag = classnames('tag', `tag--icon-${iconPos}`, {
    [`tag--${color}`]: color,
    [`tag--${size}`]: size,
    'tag--default': type === 'default',
    'tag--colorful': type === 'colorful',
    'tag--outlined': type === 'outlined'
  });

  return (
    <div data-testid="tag" className={`tag-container ${className || ''} dis--inline-block`}>
      <span
        className={cssTag}
        aria-hidden
        role='presentation'
        style={{ backgroundColor, color: textColor }}
      >
        {iconPos === 'left'
          ? (
            icon
          )
          : null}
        <span className="tag--label">{copy}</span>
        {iconPos === 'right'
          ? (
            icon
          )
          : null}
      </span>
    </div>

  );
};

export default Tag;
