import React from 'react';

// ASSETS
import styles from './ListBox.module.scss';

interface OwnerState {
  maxheightlist: string | number;
}

interface ListBoxProps {
  children: React.ReactNode;
  ownerState: OwnerState;
}

const ListBox = React.forwardRef<HTMLUListElement, ListBoxProps>(({ ownerState, children, ...props }, ref) => {
  return (
    <ul
      {...props}
      ref={ref}
      className={styles['list-box']}
      style={{
        maxHeight: ownerState.maxheightlist
      }}
    >
      {children}
    </ul>
  );
});

ListBox.displayName = 'ListBox';

export default ListBox;
