import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// COMPONENTS
import Button from 'components/Button/Button';
import BannerExplore from './components/BannerExplore/BannerExplore';

// ASSETS
import styles from './ProjectList.module.scss';

// HELPERS
import { ActionTypes, ExploreContext } from 'widget/ExploreWidget/helpers/ExploreContext';
import Icon from 'components/Icon/Icon';
import { useCreateNewProject, useGetProjectList } from 'widget/ExploreWidget/api/projects.api';
import { getTemplateType } from './ProjectList.helpers';
import { useQueryClient } from '@tanstack/react-query';
import Calculations from './components/Calculations/Calculations';
import Link from 'components/Link/Link';
import ProjectListPageLoading from './components/ProjectListPageLoading/ProjectListPageLoading';

const ProjectList = () => {
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(true);
  const { state, dispatch } = useContext(ExploreContext);
  const { projectTemplates } = state.content.projectList;
  const { isFetched, data } = useGetProjectList({
    contractId: state.contractId,
    mock: state.mock
  });
  const createNewProject = useCreateNewProject();

  const queryClient = useQueryClient();

  // ? EFFECTS
  useEffect(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 2500);
  }, []);

  // EVENTS
  const handleTemplateProject = (template: any) => {
    createNewProject(getTemplateType(template));
    dispatch({ type: ActionTypes.SET_PROJECT_MODE, payload: 'EDIT' });
  };

  const handleCreateCalculation = () => {
    dispatch({ type: ActionTypes.SET_PROJECT_MODE, payload: 'NEW' });
    navigate('/explore?project');
  };

  const resetCache = () => {
    queryClient.removeQueries({ queryKey: ['project-detail'], exact: false });
    queryClient.removeQueries(['create-project']);
    queryClient.removeQueries(['compare-calculate']);
    queryClient.removeQueries(['resource-list']);

    dispatch({ type: ActionTypes.RESET_APP_CACHE });
  };

  useEffect(() => {
    resetCache();
  }, []);

  return (
    <>
      {showLoading && <ProjectListPageLoading/>}
      {!showLoading && (
        <div className={styles.container}>
          <BannerExplore/>
          <div className={styles.startAction}>
            <Button
              copy='Start my calculation'
              size='md'
              icon={<Icon.Plus />}
              iconPosition="left"
              onClick={handleCreateCalculation}
            />
            <span>
          Click &rdquo;Start my calculation&rdquo; to create a new clean energy calculation.
            </span>
          </div>
          <div className={styles.calculations}>
            <div className={styles.sectionTitle}>
              <Icon.Sun size={24}/>
              <h3>My calculations</h3>
            </div>
            <Calculations/>
          </div>
          <div className={styles.footer}>
            <div className={styles.sectionTitle}>
              <Icon.FileMinus size={24}/>
              <h3>Find your best rate plan</h3>
            </div>
            <p>Run your personalized electric rate plan comparison. Ensuring you are on best rate plan in line with your energy needs can help you save money.</p>
            <Link href="www" copy='Compare your options' size="sm" />
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectList;
