import { ProductPermission } from 'components/Auth/User';
import Logo from 'components/Logo/Logo';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '../../hooks/Theme/Theme.context';
import Select from '../CustomSelect/CustomSelect';
import ProductMenu from '../ProductMenu/ProductMenu';
import UserMenu from '../UserMenu/UserMenu';
import { GridxProduct, TENANT_KEY } from '../constants';
import './navigation.scss';
interface Props {
  assumedTenant?: string;
  citadelApiUrl?: string;
  className?: string;
  currentTenant?: string;
  logoutCallback: () => void;
  productPermissions?: Array<ProductPermission>;
  productName?: GridxProduct;
  isClientUser?: boolean;
  tenantOptions?: Array<any>;
  selectedTenant?:string
  setAssumedTenant?: any
  showSettings?: boolean
  settingsUrl?: string,
  user: string,
  logo?: Function
}

/**
 * Represents a Navigation.
 * @constructor
 * @param {string} className
 * Usage :
 * ````js
 * <Navigation className='my-navigation' />
 * ````
 * @augments {Component<Props, State>}
 */
/** */
const Navigation: React.FC<Props> = ({
  assumedTenant,
  citadelApiUrl,
  className,
  currentTenant,
  isClientUser,
  logoutCallback,
  productName,
  productPermissions,
  setAssumedTenant,
  tenantOptions,
  showSettings,
  settingsUrl,
  user,
  logo
}: Props) => {
  const { theme } = useTheme(); //eslint-disable-line
  const pathname = useLocation().pathname.split('/');
  const [latestTenant, setLatestTenant] = useState('');
  let shownProductName: string;

  if (productName) {
    shownProductName = GridxProduct[productName];
  } else {
    shownProductName = pathname[1]; //eslint-disable-line
  }

  const tenantChange = (event: any) => {
    // event?.target?.innerText - not recommended
    localStorage.setItem(TENANT_KEY, event?.target?.innerText);
    setAssumedTenant(event?.target?.innerText);
    setLatestTenant(event?.target?.innerText);
  };

  useEffect(() => {
    setLatestTenant(assumedTenant ?? '');
  }, [assumedTenant]);

  return (
    <div className={`${className} bor--b-1 flex flex--align-center`}>
      <div
        id="navHeader"
        className="nav--header flex flex--align-center jus--space-between"
      >
        <div className="dis--inline-block flex flex--align-center">
          {(!isClientUser && tenantOptions && tenantOptions?.length > 1)
            ? (
              <div className="dis--inline-block mar--l-128">
                <Select
                  options={tenantOptions ?? []}
                  size="sm"
                  value={latestTenant}
                  onChange={e => tenantChange(e)}
                />
              </div>
            )
            : (isClientUser
              ? <Logo
                className='dis--inline-block'
                name={latestTenant}
                size='xl'
              />
              : ''
            )
          }
          {logo ? logo() : ''}
        </div>
        <div className="flex flex--align-center">
          { currentTenant && <ProductMenu
            assumedTenant={assumedTenant}
            citadelApiUrl={citadelApiUrl}
            currentTenant={currentTenant}
            /* @ts-ignore */
            productPermissions={productPermissions}
          />}
          <UserMenu logoutCallback={logoutCallback} showSettings={showSettings} settingsUrl={settingsUrl} user={user}/>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
