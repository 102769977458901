import React from 'react';
import colors from 'style/colors';

// ASSETS
import styles from './BillingSingleView.module.scss';
import { Box } from 'components';

export const columnsProduction = [
  {
    accessorKey: 'billKey',
    header: 'Production Charges',
    enableColumnFilter: false,
    cell: (info: any) => {
      return (
        <Box display="flex">
          <div className={styles.dotColor} style={{ backgroundColor: info.row.original.color }} />
          <span>{info.getValue()}</span>
        </Box>
      );
    }
  },
  {
    accessorKey: 'billAmount',
    header: 'Amount',
    enableColumnFilter: false,
    enableSorting: false,
    meta: {
      customWidth: 150
    }
  }
];

export const dataProduction = [
  {
    billKey: 'Production Metered Demand 147,570 kW @ $8.50',
    billAmount: '$1,254,345.00',
    color: colors.blue
  },
  {
    billKey: 'Customer Load Member Distributed Generation (1,997) kW @ 8.50',
    billAmount: '($16,974.50)',
    color: colors.clean
  },
  {
    billKey: 'Production Fixed Energy Charge',
    billAmount: '$461,580.00',
    color: colors.wind
  }
];

export const columnsTransmission = [
  {
    accessorKey: 'billKey',
    header: 'Transmission Demand Charges',
    enableColumnFilter: false,
    cell: (info: any) => {
      return (
        <Box display="flex">
          <div className={styles.dotColor} style={{ backgroundColor: info.row.original.color }} />
          <span>{info.getValue()}</span>
        </Box>
      );
    }
  },
  {
    accessorKey: 'billAmount',
    header: 'Amount',
    enableColumnFilter: false,
    enableSorting: false,
    meta: {
      customWidth: 150
    }
  }
];

export const dataTransmission = [
  {
    billKey: 'Transmission Metered Demand —> 147,570 kW @ $ 4.33100',
    billAmount: '$639,125.67',
    color: colors.algae
  }
];

export const columnsNonFuel = [
  {
    accessorKey: 'billKey',
    header: 'Non-Fuel Energy Charges',
    enableColumnFilter: false,
    cell: (info: any) => {
      return (
        <Box display="flex">
          <div className={styles.dotColor} style={{ backgroundColor: info.row.original.color }} />
          <span>{info.getValue()}</span>
        </Box>
      );
    }
  },
  {
    accessorKey: 'billAmount',
    header: 'Amount',
    enableColumnFilter: false,
    enableSorting: false,
    meta: {
      customWidth: 150
    }
  }
];

export const dataNonFuel = [
  {
    billKey: 'Non-Fuel Metered Energy 49,272,551 @ $ 0.00422',
    billAmount: '$207,930.17',
    color: colors.coral
  }
];

export const columnsFuelEnergy = [
  {
    accessorKey: 'billKey',
    header: 'Fuel Energy Charges',
    enableColumnFilter: false,
    cell: (info: any) => {
      return (
        <Box display="flex">
          <div className={styles.dotColor} style={{ backgroundColor: info.row.original.color }} />
          <span>{info.getValue()}</span>
        </Box>
      );
    }
  },
  {
    accessorKey: 'billAmount',
    header: 'Amount',
    enableColumnFilter: false,
    enableSorting: false,
    meta: {
      customWidth: 150
    }
  }
];

export const dataFuelEnergy = [
  {
    billKey: 'On-Peak Metered Energy 12,456,978 kWH @ 0.04212',
    billAmount: '$524,687.91',
    color: colors.pine
  },
  {
    billKey: 'Off-Peak Metered Energy 25,129,039 kWH @ 0.0353',
    billAmount: '$889,316.69',
    color: colors.vine
  },
  {
    billKey: 'Super Off-Peak Metered Energy 11,686,534 kWh @ $ 0.02862 ',
    billAmount: '$334,468.60',
    color: colors.moss
  }
];

export const columnsMember = [
  {
    accessorKey: 'billKey',
    header: 'Member Distributed Generation Adjustments',
    enableColumnFilter: false,
    cell: (info: any) => {
      return (
        <Box display="flex">
          <div className={styles.dotColor} style={{ backgroundColor: info.row.original.color }} />
          <span>{info.getValue()}</span>
        </Box>
      );
    }
  },
  {
    accessorKey: 'billAmount',
    header: 'Amount',
    enableColumnFilter: false,
    enableSorting: false,
    meta: {
      customWidth: 150
    }
  }
];

export const dataMember = [
  {
    billKey: 'Excess Capacity Agreement (1,203) kW @ $ 8.50000',
    billAmount: '($10,225.50)',
    color: colors.bright
  }
];
