import React, { useState } from 'react';

// COMPONENTS
import Button, { ButtonProps } from '../Button/Button';
import Popover from '../Popover/Popover';
import ActionList from '../ActionList/ActionList';
import Icon from '../Icon/Icon';
import { ActionListItem } from '../ActionList/ActionList.helpers';

interface IDropdownProps extends Pick<ButtonProps, 'size' | 'variant' | 'color' | 'disabled'> {
  label: string;
  disabled?: boolean,
  options: ActionListItem[]
  variant?: 'contained' | 'outlined'
  placement?: 'bottom-start' | 'bottom-end',
  /**
   * @deprecated type is deprecated, use color instead
   */
  type?: 'primary' | 'secondary',
  listColor?: 'light' | 'dark'
  listSize?: 'md' | 'lg'
}

const Dropdown:React.FC<IDropdownProps> = ({
  label,
  disabled = false,
  size = 'md',
  variant = 'contained',
  color,
  type,
  options,
  placement = 'bottom-end',
  listColor = 'light',
  listSize = 'md'
}) => {
  if (type) {
    console.warn('Dropdown: type is deprecated, use color instead');
  }

  const [active, setActive] = useState(false);

  // EVENTS
  const togglePopover = () => {
    setActive(!active);
  };

  // RENDERS
  const activator = (
    <Button
      copy={label}
      disabled={disabled}
      size={size}
      color={color}
      variant={variant}
      onClick={togglePopover}
      icon={<Icon.ChevronDown/>}
      iconPosition="right"
    />
  );

  return (
    <div>
      <Popover
        isOpen={active}
        renderReference = { activator }
        onOutsideClickClose={() => setActive(false)}
        p={0}
        placement={placement}
        zIndex={999}
      >
        <ActionList items={options} color={listColor} size={listSize} />
      </Popover>
    </div>
  );
};

export default Dropdown;
