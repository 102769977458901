import React from 'react';
import { useFormContext } from 'react-hook-form';

// COMPONENTS
import Select from 'components/CustomSelect/CustomSelect';
import Box from 'components/Box/Box';

const FormWaterHeater = () => {
  const { register, getValues } = useFormContext();

  // RENDERS
  const occupantsField = register('occupantsField');
  const waterHeaterField = register('waterHeaterField');

  return (
    <div>
      <Box mb={3}>
        <Select
          label="Occupants"
          options={[
            { value: 1, label: '1' },
            { value: 2, label: '2' },
            { value: 3, label: '3' },
            { value: 4, label: '4' },
            { value: 5, label: '5' },
            { value: 6, label: '6' },
            { value: 7, label: '7' },
            { value: 8, label: '8' },
            { value: 9, label: '9' },
            { value: 10, label: '10+' }
          ]}
          defaultValue={getValues().occupantsField}
          inputRef={occupantsField.ref}
          onChange={(_, value: any) => occupantsField.onChange({ target: { value, name: occupantsField.name } })}
        />
      </Box>
      <Select
        label="Water heater fuel"
        options={[
          { value: 'Natural gas', label: 'Natural gas' },
          { value: 'Electricity', label: 'Electricity' },
          { value: 'Propane', label: 'Propane' },
          { value: 'Fuel Oil', label: 'Fuel Oil' },
          { value: 'Other Fuel', label: 'Other Fuel' }
        ]}
        defaultValue={getValues().waterHeaterField}
        inputRef={waterHeaterField.ref}
        onChange={(_, value: any) => waterHeaterField.onChange({ target: { value, name: waterHeaterField.name } })}
      />
    </div>
  );
};

export default FormWaterHeater;
