import React from 'react';

// COMPONENTS
import Icon from 'components/Icon/Icon';
import Chip from 'components/Chip/Chip';

// HELPERS
interface ResourceChipProps {
  resource: string
}

const ResourceChip:React.FC<ResourceChipProps> = ({ resource }) => {
  // RENDERS
  const renderChipDetails = () => {
    // Using a switch statement here because we anticipate adding more resource types in the future.
    switch (resource) {
    case 'solarChannel':
      return {
        icon: <Icon.Sun/>,
        label: 'Solar panels'
      };
    case 'solar':
      return {
        icon: <Icon.Sun/>,
        label: 'Solar panels'
      };
    case 'ev':
      return {
        icon: <Icon.Zap/>,
        label: 'Electric vehicle'
      };
    case 'battery':
      return {
        icon: <Icon.Zap/>,
        label: 'Battery storage'
      };
    case 'climate':
      return {
        icon: <Icon.Zap/>,
        label: 'Climate control'
      };
    case 'heater':
      return {
        icon: <Icon.Zap/>,
        label: 'Water heater'
      };
    };
  };

  const details = renderChipDetails();

  return (
    <Chip
      icon={details?.icon}
      label={details?.label || ''}
      type="info"
      variant="outlined"
    />
  );
};

export default ResourceChip;
