import { createSlice } from '@reduxjs/toolkit';

// TYPINGS
import { FilterItem } from 'store/helpers/store.typings';

const initialAnalyze = {
  layout: {
    isFilterOpen: false
  },
  searchString: '',
  filters: [{} as FilterItem<any>],
  recentSearches: ['Time of Use Rate: 2-Period', 'Nebula', 'Green Rate', 'Residential', 'Standard Rate'] as string[]
};

const analyzeSlice = createSlice({
  name: 'ANALYZE',
  initialState: initialAnalyze,
  reducers: {
    setFilter: (state, { payload }) => {
      const selectedFilter = state.filters.find(filter => filter.key === payload.key);
      if (selectedFilter) {
        if (selectedFilter.value.includes(payload.value)) return;
        selectedFilter?.value.push(payload.value);
      } else {
        state.filters.push({
          key: payload.key,
          value: [payload.value]
        });
      }
    },
    removeFilter: (state, { payload }) => {
      const selectedFilter = state.filters.find(filter => filter.key === payload.key);
      if (selectedFilter) {
        if (selectedFilter.value.includes(payload.value)) {
          selectedFilter.value = selectedFilter.value.filter((value: any) => value !== payload.value);
        }
      }
    },
    toogleFilter: (state) => {
      state.layout.isFilterOpen = !state.layout.isFilterOpen;
    },
    setSearchString: (state, { payload }) => {
      state.searchString = payload;
    }
  }
});

export const {
  setFilter,
  removeFilter,
  toogleFilter,
  setSearchString
} = analyzeSlice.actions;

export default analyzeSlice.reducer;
