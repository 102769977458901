import React from 'react';

import CustomCheckbox from '../components/CustomCheckbox/CustomCheckbox';
import Icon from '../components/Icon/Icon';
import Logo from '../components/Logo/Logo';
import Tag from '../components/Tag/Tag';
import Text from '../components/Text/Text';

import mockUsersFiltersList from '../json/mock_users_filters_list.json';
import { TagColor } from 'components/Tag/Tag.helpers';

export const RenderFiltersList = (handleFilters: { (e: any): void; (arg0: React.ChangeEvent<HTMLInputElement>): void; }) => {
  const list = mockUsersFiltersList.apps.map((app) => {
    const {
      name
    } = app;
    const tempRole = [{ color: 'stone', role: 'admin' }];

    const renderRoles = tempRole.map((item) => {
      const {
        color,
        role
      } = item;

      return (
        <div
          key={role}
          className='flex mar--b-20'
        >
          <CustomCheckbox
            className='mar--l-36 mar--r-16'
            color='magma' id={`${name}-${role}-checkbox`}
            value={`${name}-${role}`}
            onChange={(e) => handleFilters(e)}
          />
          <Tag
            color={color as any}
            copy= {role}
            size='sm'
          />
        </div>
      );
    });

    return (
      <div key={name}>
        <div
          className='flex'
        >
          <CustomCheckbox
            className='mar--r-16'
            color='magma'
            id={`${name.toLowerCase()}-checkbox`}
            value={name}
            onChange={(e) => handleFilters(e)}
          />
          <Logo
            className='mar--r-16 dis--inline-block'
            name={name.toLowerCase()}
            size='xs'
          />
          <Text
            className='body--lg pad--b-20 text--bold'
            variant='body1'
          >
            {name}
          </Text>
        </div>
        <div>
          {renderRoles}
        </div>
      </div>
    );
  });

  return (
    <>
      {list}
      <div
        className='visually-hidden flex jus--end flex--align-center cursor--pointer link'
      >
        <Icon.X />
        <Text
          className='body--md link'
          variant='body1'
        >
              Clear filter
        </Text>
      </div>
    </>
  );
};
