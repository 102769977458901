export type ErrorName = 'TOKEN_EXPIRED'
  | 'TOKEN_INVALID'
  | 'AUTHENTICATION_FAILED';

export class AuthenticationError extends Error {
  name: ErrorName;
  message: string;
  cause: any;

  constructor (name: ErrorName, message: string, cause: any) {
    super();
    this.name = name;
    this.message = message;
    this.cause = cause;
  }
}
