// TYPINGS
import { DataQueryParam } from 'api/helpers/api.typings';
import { FilterItem } from 'store/helpers/store.typings';

// HELPERS
import { sortBy } from 'utils/sortBy';

export const filterQueryData = <T>(queryParams: DataQueryParam<T>, searchKey?: keyof T) => (data: T[]) => {
  const { filters, searchString, sortKey, orderAscending } = queryParams;
  let filteredData = data;

  // Apply Filters
  if (filters) {
    if (filters.length === 0) return filteredData;

    filters.forEach((filter: FilterItem<T>) => {
      if (filter.value) {
        if (filter.type === 'daterange') {
          filteredData = filteredData.filter((item: T) => {
            const date = new Date(item[filter.key as keyof T] as unknown as string);
            return date >= filter.value[0] && date <= filter.value[1];
          });
        } else {
          if (filter.value.length) {
            filteredData = filteredData.filter((item) => filter.value.includes(item[filter.key as keyof T]));
          }
        }
      }
    });
  }

  // Apply the search filter
  if (searchString && searchString.length > 0 && searchKey) {
    filteredData = filteredData.filter((item) => String(item[searchKey]).toLowerCase().includes(searchString.toLowerCase()));
  }

  // Sort By
  filteredData = sortBy({
    attribute: sortKey as keyof T,
    direction: orderAscending ? 'desc' : 'asc',
    data: filteredData as T[]
  }) as T[];

  return filteredData;
};
