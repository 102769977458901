import React from 'react';

// COMPONENTS
import Tag from 'components/Tag/Tag';
import Button from 'components/Button/Button';

// ASSETS
import styles from './ModifierItem.module.scss';
import Box from 'components/Box/Box';
import Icon from 'components/Icon/Icon';

export type IconResource = {
  src: string;
  alt: string;
}

interface ModifierItemProps {
  title: string;
  description?: string;
  icon: IconResource;
  image: IconResource;
  tags?: Array<string>;
  width?: Number;
  onSelected?: () => void;
  resourceItem?: any;
  onRemoveItem?: (resource: any) => void;
  selected?: boolean;
}

const ModifierItem:React.FC<ModifierItemProps> = ({
  icon,
  image,
  title,
  description,
  tags,
  width,
  onSelected,
  resourceItem,
  onRemoveItem = () => {},
  selected = false
}) => {
  // EVENTS
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onRemoveItem && onRemoveItem(resourceItem);
    }
  };

  return (
    <div className={styles.container} style={{ width: `${width}px` }}>
      <div>
        <div className={styles.header}>
          <div className={styles.icon}>
            <div className={styles['icon-item']} data-alt={icon.alt}>
              <img src={icon.src} alt={icon.alt} />
            </div>
          </div>
          <p className={styles.title}>{title}</p>
        </div>
        <div className={styles.modifierImage} style={{ backgroundImage: `url(${image.src})` }} />
        {description && <p className={styles.description}>{description}</p>}
        {
          tags && tags.length > 0 && (
            <div className={styles.tags}>
              {
                tags.map((item) => {
                  return (
                    <Tag key={item} copy={item} color='cloud' />
                  );
                })
              }
            </div>
          )
        }
      </div>
      <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
        {
          selected && (
            <>
              <Button copy="Edit" color='secondary' variant="outlined" onClick={onSelected} />
              <div
                className={styles['link-remove']}
                role="button"
                tabIndex={0}
                onClick={() => onRemoveItem(resourceItem)}
                onKeyDown={handleKeyDown}
              >
                <Icon.X size={16}/>
                <span>Remove</span>
              </div>
            </>
          )
        }
        {
          !selected && (
            <Button copy="Select" color='secondary' variant="outlined" onClick={onSelected} />
          )
        }
      </Box>
    </div>
  );
};

export default ModifierItem;
