import React, { useContext } from 'react';

// COMPONENTS
import Drawer from 'components/Drawer/Drawer';
import Box from 'components/Box/Box';
import BackLink from 'widget/ExploreWidget/components/BackLink/BackLink';
import Button from 'components/Button/Button';

// ASSETS
import styles from './DrawerSaveCalculation.module.scss';

// HELPERS
import { ActionTypes, ExploreContext } from 'widget/ExploreWidget/helpers/ExploreContext';
import useMediaQuery, { breakpoints } from 'hooks/useMediaQuery';
import { useResourceList } from 'widget/ExploreWidget/api/resources.api';
import Icon from 'components/Icon/Icon';
import ModifierItem from '../ModifierItem/ModifierItem';
import { getModifierIcon, getModifierImage } from 'widget/ExploreWidget/helpers/utils/getModifierIcon';
import { Modifier } from 'widget/ExploreWidget/helpers/types';
import { TextInput } from 'components';
import colors from 'style/colors';
import { useNavigate } from 'react-router-dom';
// import EnergyResourceItemLoading from '../EnergyResourceItem/EnergyResourceItemLoading';

const DrawerSaveCalculation = () => {
  const { state, dispatch } = useContext(ExploreContext);
  const isMobile = useMediaQuery(breakpoints.mobileMedium);
  const navigate = useNavigate();

  // ? EVENTS
  const handleClose = () => {
    dispatch({
      type: ActionTypes.SET_SAVE_CALCULATION,
      payload: {
        isOpen: false
      }
    });
  };

  const handleSave = () => {
    if (state.saveCalculation.afterSaveAction === 'quit') {
      navigate('/explore');
      handleClose();
      dispatch({ type: ActionTypes.SET_CALCULATION_STEP, payload: 0 });
    } else if (state.saveCalculation.afterSaveAction === 'continue') {
      handleClose();
      dispatch({ type: ActionTypes.SET_CALCULATION_STEP, payload: state.activeCalculationStep + 1 });
    }
  };

  return (
    <Drawer
      open={state.saveCalculation.isOpen}
      onClose={handleClose}
      anchor="right"
      width={isMobile ? '100vw' : 400}
      BackdropProps={{ invisible: false }}
      footer={
        <Box display="flex" justifyContent="end" gap={1.5}>
          <Button copy="Close" color="secondary" variant="outlined" size='md' onClick={handleClose} />
          <Button copy="Save" color="primary" variant="contained" size='md' onClick={handleSave} />
        </Box>
      }
      bodyStyle={{
        backgroundColor: colors.colorBackgroundDisplayNeutral
      }}
      footerStyle={{
        backgroundColor: colors.white,
        borderTop: `1px solid ${colors.wave}`
      }}
    >
      <Box mb={3} my={3}>
        <div className={styles.titleContainer}>
          <p className={styles.title}>Save calculation</p>
        </div>
        <p className={styles.description}>Name and save your clean energy calculation so you can return to it at any time.</p>
      </Box>
      <div className={styles.form}>
        <TextInput
          label="Calculation name"
          placeholder='Type your calculation name'
          size="xl"
        />
      </div>
    </Drawer>
  );
};

export default DrawerSaveCalculation;
