import * as React from 'react';

// COMPONENTS
import Item from '../Item/Item';

// ASSETS
import styles from '../../ActionList.module.scss';

// HELPERS
import { ActionListSection } from '../../ActionList.helpers';

export interface ISectionProps {
  section: ActionListSection;
  onActionAnyItem?: any;
  hasMultipleSections?: any;
  actionRole: any;
}

const Section: React.FC<ISectionProps> = ({ section }) => {
  const actionMarkup = section.items.map(({ content, onClick, ...item }, index) => {
    return <Item key={`${content}-${index}`} content={content} onClick={onClick} {...item} />;
  });

  return (
    <>
      <ul>{actionMarkup}</ul>
      {section.divider && <div className={styles.divider} />}
    </>
  );
};

export default Section;
