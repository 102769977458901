import React from 'react';

// COMPONENTS
import ListMappings from '../ListMappings/ListMappings';
import { FilterSearchBar } from 'blocks/components';

// ASSETS
import styles from '../Mapping/Mapping.module.scss';

// HELPERS
import { useMapping } from 'api/accounts/accounts.api';

const Devices = () => {
  const { data: mappingData, isLoading } = useMapping();
  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <h3>Devices</h3>
      </div>
      <div className={styles.filters}>
        <FilterSearchBar searchPlaceholder='Search networks'/>
      </div>
      {(!isLoading && mappingData) && (
        <ListMappings title='Device' mappings={mappingData} aria-label="List of Devices" />
      )}
    </div>
  );
};

export default Devices;
