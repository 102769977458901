export const data = [
  {
    key: 1,
    date: 'January 1, 2023',
    offpeak: '$ 0.17',
    partialpeak: '$ 0.27',
    peak: '$ 0.37'
  },
  {
    key: 2,
    date: 'February 1, 2023',
    offpeak: '$ 0.16',
    partialpeak: '$ 0.26',
    peak: '$ 0.36'
  },
  {
    key: 3,
    date: 'March 1, 2023',
    offpeak: '$ 0.16',
    partialpeak: '$ 0.26',
    peak: '$ 0.36'
  },
  {
    key: 4,
    date: 'April 1, 2023',
    offpeak: '$ 0.16',
    partialpeak: '$ 0.26',
    peak: '$ 0.36'
  },
  {
    key: 5,
    date: 'May 1, 2023',
    offpeak: '$ 0.16',
    partialpeak: '$ 0.26',
    peak: '$ 0.36'
  },
  {
    key: 6,
    date: 'June 1, 2023',
    offpeak: '$ 0.16',
    partialpeak: '$ 0.26',
    peak: '$ 0.36'
  },
  {
    key: 7,
    date: 'July 1, 2023',
    offpeak: '$ 0.16',
    partialpeak: '$ 0.26',
    peak: '$ 0.36'
  },
  {
    key: 8,
    date: 'August 1, 2023',
    offpeak: '$ 0.16',
    partialpeak: '$ 0.26',
    peak: '$ 0.36'
  }
];
