import React from 'react';

// COMPONENTS
import Accounts from './components/Accounts';
import Analyze from './components/Analyze';
import Balance from './components/Balance';
import Calculate from './components/Calculate';
import Citadel from './components/Citadel';
import CSR from './components/CSR';
import Design from './components/Design';
import Empower from './components/Empower';
import Explore from './components/Explore';
import KAM from './components/KAM';
import Rates from './components/Rates';

export type BrandIconOptions = 'accounts' | 'analyze' | 'balance' | 'calculate' | 'citadel' | 'csr' | 'design' | 'empower' | 'explore' | 'kam' | 'rates';

interface BrandIconProps {
  icon: BrandIconOptions;
  color?: 'full' | 'energy' | 'white' | 'deep';
  fontSize?: number;
  showBrandName?: boolean;
  showGridX?: boolean;
  width?: number;
  height?: number;
}

const BrandIcon:React.FC<BrandIconProps> = ({ icon, color = 'full', ...props }) => {
  switch (icon) {
  case 'accounts':
    return <Accounts color={color} {...props} />;
  case 'analyze':
    return <Analyze color={color} {...props} />;
  case 'balance':
    return <Balance color={color} {...props} />;
  case 'calculate':
    return <Calculate color={color} {...props} />;
  case 'citadel':
    return <Citadel color={color} {...props} />;
  case 'csr':
    return <CSR color={color} {...props} />;
  case 'design':
    return <Design color={color} {...props} />;
  case 'empower':
    return <Empower color={color} {...props} />;
  case 'explore':
    return <Explore color={color} {...props} />;
  case 'kam':
    return <KAM color={color} {...props} />;
  case 'rates':
    return <Rates color={color} {...props} />;
  }
};

export default BrandIcon;
