import React from 'react';

// COMPONENTS
import DataTable from 'components/DataTable/DataTable';

// HELPERS
import { columns, data } from './Changelog.helpers';

// ASSETS
import styles from './Changelog.module.scss';

const Changelog = () => {
  return (
    <div className={styles.container}>
      <DataTable columns={columns as any} data={data} />
    </div>
  );
};

export default Changelog;
