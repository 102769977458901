import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

// TYPINGS
import { IAccount } from 'api/accounts/accounts.typings';
import { FilterItem, SortItem } from 'store/helpers/store.typings';
import { IBilling } from 'api/billing/billing.typings';

// HELPERS
import setFilter from 'store/helpers/setFilter';

const initialAccounts = {
  layout: {
    isUpdateSystemOpen: false,
    isDataUpdateOpen: false,
    isViewDeviceOpen: false,
    isConfigureMappingOpen: false,
    isAccountSettingsOpen: false,
    isFilterOpen: false
  },
  accountsFilters: [{} as FilterItem<IAccount>],
  accountsSortBy: {} as SortItem<IAccount>,
  accountsSearchString: '',
  updateSystemFile: {} as File,
  singleBilling: {} as IBilling,
  usageSelectedYear: moment().year()
};

const accountsSlice = createSlice({
  name: 'ACCOUNTS',
  initialState: initialAccounts,
  reducers: {
    setViewDeviceOpen: (state, { payload }) => {
      state.layout.isViewDeviceOpen = payload;
    },
    setIsUpdateSystemOpen: (state, { payload }) => {
      state.layout.isUpdateSystemOpen = payload;
    },
    setIsDataUpdateOpen: (state, { payload }) => {
      state.layout.isDataUpdateOpen = payload;
    },
    setIsConfigureMappingOpen: (state, { payload }) => {
      state.layout.isConfigureMappingOpen = payload;
    },
    setIsAccountSettingsOpen: (state, { payload }) => {
      state.layout.isAccountSettingsOpen = payload;
    },
    setUpdateSystemFile: (state, action) => {
      state.updateSystemFile = action.payload;
    },
    setUsageSelectedYear: (state, { payload }) => {
      state.usageSelectedYear = payload;
    },
    setSingleBilling: (state, { payload }) => {
      state.singleBilling = payload;
    },
    // ACCOUNTS
    setAccountSearchString: (state, { payload }) => {
      state.accountsSearchString = payload;
    },
    setSortedAccounts: (state, { payload }) => {
      state.accountsSortBy.key = payload.sortKey;
      if (payload.sortKey === state.accountsSortBy.key) {
        state.accountsSortBy.ascending = !state.accountsSortBy.ascending;
      }
    },
    setAccountsFilterOpen: (state, { payload }) => {
      state.layout.isFilterOpen = payload;
    },
    toogleFilter: (state) => {
      state.layout.isFilterOpen = !state.layout.isFilterOpen;
    },
    setAccountFilter: (state, { payload }) => {
      setFilter<IAccount>(state.accountsFilters, payload);
    },
    removeAccountFilter: (state, { payload }) => {
      const selectedFilter = state.accountsFilters.find(filter => filter.key === payload.key);
      if (selectedFilter) {
        if (selectedFilter.value.includes(payload.value)) {
          selectedFilter.value = selectedFilter.value.filter((value: any) => value !== payload.value);
        }
      }
    },
    clearAccountFilter: (state) => {
      state.accountsFilters = [];
    },
    setFirstApproveBilling: (state) => {
      const selectedBilling = state.singleBilling;
      if (selectedBilling) {
        selectedBilling.status = 'Pending approval';
      }
    },
    setApproveBilling: (state) => {
      const selectedBilling = state.singleBilling;
      if (selectedBilling) {
        selectedBilling.status = 'Approved';
      }
    }
  }
});

export const {
  setViewDeviceOpen,
  setUsageSelectedYear,
  setIsUpdateSystemOpen,
  setUpdateSystemFile,
  setIsDataUpdateOpen,
  setIsConfigureMappingOpen,
  setIsAccountSettingsOpen,
  setSingleBilling,
  setApproveBilling,
  setFirstApproveBilling,
  // ACCOUNTS
  setAccountSearchString,
  setSortedAccounts,
  setAccountFilter,
  removeAccountFilter,
  clearAccountFilter,
  toogleFilter
} = accountsSlice.actions;

export default accountsSlice.reducer;
