import React from 'react';
import Highcharts from 'highcharts';

// COMPONENTS
import { GraphCard, LegendGraphItem } from '../../../../../../blocks/components';
import Icon from '../../../../../../components/Icon/Icon';
import ColumnChart from '../../../../../../components/ColumnChart/ColumnChart';

// ASSETS
import styles from '../../FullPopulation.module.scss';

// HELPERS
import { transformData } from './GraphSavingPotential.helpers';
import { useTheme } from '../../../../../../hooks/Theme/Theme.context';
import colors from '../../../../../../style/colors';

// TYPINGS
import formatToCurrency from '../../../../../../utils/formatToCurrency';
import { ICustomerData } from '../../../../../../api/analyze/analyze.typings';
import { Color, ColorCode } from 'hooks/Theme/Theme.model';

interface GraphSavingPotentialProps {
  customers: Array<ICustomerData>;
}

const GraphSavingPotential:React.FC<GraphSavingPotentialProps> = ({ customers }) => {
  const { getThemeColor } = useTheme();

  // RENDERS
  const graphData = transformData(customers, getThemeColor);

  return (
    <GraphCard
      title="480k Accounts with savings potential"
      icon={<Icon.Paperclip />}
    >
      <div className={styles['graph-container']}>
        <div className={styles['legend-container']}>
          <LegendGraphItem name="25% +" color={getThemeColor(Color.Tertiary, ColorCode.C100, colors.stone)} />
          <LegendGraphItem name="20-25%" color={getThemeColor(Color.Tertiary, ColorCode.C200, colors.storm)} />
          <LegendGraphItem name="10-15%" color={getThemeColor(Color.Tertiary, ColorCode.C300, colors.surf)} />
          <LegendGraphItem name="5-10%" color={getThemeColor(Color.Secondary, ColorCode.C100, colors.mist)} />
          <LegendGraphItem name="2.5-5%" color={getThemeColor(Color.Secondary, ColorCode.C200, colors.altitude)} />
          <LegendGraphItem name="0-2.5% +" color={getThemeColor(Color.Secondary, ColorCode.C300, colors.clean)} />
          {/* {
            graphData.map((item, index) => {
              return (
                <LegendGraphItem key={item.name} name={item.name} color={item.color} />
              );
            })
          } */}
        </div>
        <div>
          <ColumnChart
            title={{
              text: 'Savings potential distribution'
            }}
            series={graphData}
            height={240}
            tooltip={{
              formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
                return `
                    <div class="donutTooltip">
                      <strong>${formatToCurrency(this.y as number)}</strong>
                      <span>${this.series.name}</span>
                    </div>
                  `;
              }
            }}
          />
        </div>
      </div>
    </GraphCard>
  );
};

export default GraphSavingPotential;
