import { Resource } from 'widget/ExploreWidget/api/projects.typings';
import { Modifier } from 'widget/ExploreWidget/helpers/types';

export const getModifierAttribute = (modifier: Resource): { modifier: Modifier, label: string } => {
  switch (modifier.name) {
  case 'solarChannel':
    return {
      modifier: 'solar',
      label: 'Solar panels'
    };
  case 'ev':
    return {
      modifier: 'ev',
      label: 'Electric vehicles'
    };
  case 'battery':
    return {
      modifier: 'battery',
      label: 'Battery storage'
    };
  case 'heatPump':
    return {
      modifier: 'heatPump',
      label: 'HVAC heat pump'
    };
  case 'waterHeater':
    return {
      modifier: 'waterHeater',
      label: 'Heat pump water heater'
    };
  case 'dryer':
    return {
      modifier: 'dryer',
      label: 'Electric dryer'
    };
  case 'range':
    return {
      modifier: 'range',
      label: 'Electric range'
    };
  default:
    return {
      modifier: 'solar',
      label: 'Solar panels'
    };
  }
};
