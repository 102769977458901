import { Role } from './Role';

export type Tenant = {
    tenantId: string;
    tenantName: string;
};

export type TenantProductsPermissions ={
  products: Array<ProductPermission>,
  tenant: Tenant
}

export type ProductPermission = {
  productId: string;
  productName: string;
  productUrl: string;
  roles: Array<Role>;
  tenant?: Tenant;
}

/**
 * Define a user in the GridX system.
 */
export class User {
  accessToken: string;
  idToken: string;
  refreshToken: string;
  username: string;
  productPermissions?: Array<TenantProductsPermissions>;

  constructor (username: string, idToken: string, accessToken: string, refreshToken: string, productPermissions: Array<TenantProductsPermissions> | undefined) {
    this.accessToken = accessToken;
    this.idToken = idToken;
    this.refreshToken = refreshToken;
    this.username = username;
    this.productPermissions = productPermissions;
  }
}

export default User;
