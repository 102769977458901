import React from 'react';
import Option from '@mui/base/Option';

// ASSETS
import styles from './Option.module.scss';

interface CustomOptionProps {
  value: string | number,
  children: React.ReactNode,
}

const CustomOption:React.FC<CustomOptionProps> = ({ value, children, ...props }) => {
  return (
    <Option {...props} className={styles.option} value={value}>{children}</Option>
  );
};

export default CustomOption;
