import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Text } from '@gridx/metronome-components';
import HeatMapChart from '../../components/HeatMapChart/HeatMapChart';
import colors from '../../style/colors';
import { fetchRealTimeDataByRange, formatPrice } from './RealTimeData.helpers';

interface PricingHeatmapProps {
  apiUrl: string
  utility: string
  market: string
  rate: string
  scrollBar: number
  chartWidth: number
  categories: () => string[]
  selectDate: string
  pgeChartColors: string[]
}

export const PricingHeatMap = (props: PricingHeatmapProps) => {
  const [prices, setPrices] = useState<number[][]>([[], [], []]);

  const yesterday = moment().tz('America/Los_Angeles').subtract(1, 'day');
  const oneDaysBefore = moment().tz('America/Los_Angeles').subtract(2, 'day');
  const twoDaysBefore = moment().tz('America/Los_Angeles').subtract(3, 'day');

  const {
    data
  } = fetchRealTimeDataByRange(props.apiUrl, props.utility, props.market, props.rate, twoDaysBefore.format('YYYYMMDD'), yesterday.format('YYYYMMDD'));

  useEffect(() => {
    if (data && data.length) {
      setPrices(data.map(p => formatPrice(p.priceDetails)));
    }
  }, [data]);

  const xHeatmapAxis = {
    lineWidth: 0,
    visible: true,
    opposite: false,
    labels: {
      style: {
        color: colors.stone,
        fontSize: '12px',
        fontFamily: 'Aktifo-B-Medium'
      }
    },
    categories: props.categories()
  };

  const plotHeatmapData = (prices: any, yPos: number) => {
    const getColorRange = (price: number) => {
      const low = 10;
      const medium = 15;
      const high = 300;
      if (price < low) {
        return props.pgeChartColors[0];
      } else if (price >= low && price < medium) {
        return props.pgeChartColors[1];
      } else if (price >= medium && price < high) {
        return props.pgeChartColors[2];
      } else {
        return props.pgeChartColors[0];
      }
    };
    const heatmapData = prices.map((price: number, index: number) => {
      return {
        price: Math.round(price),
        rawPrice: price,
        color: getColorRange(price),
        x: index,
        y: yPos
      };
    });

    return heatmapData;
  };

  const yesterdayData = plotHeatmapData(prices[0], 0);
  const oneDaysBeforeData = plotHeatmapData(prices[1], 1);
  const twoDaysBeforeData = plotHeatmapData(prices[2], 2);

  const previousData = [...yesterdayData, ...oneDaysBeforeData, ...twoDaysBeforeData];

  const yHeatmapAxis = {
    categories: [twoDaysBefore.format('ddd'), oneDaysBefore.format('ddd'), yesterday.format('ddd')]
  };

  const heatMapChart = {
    marginTop: 0,
    marginBottom: 20,
    paddingBottom: 0,
    plotBorderWidth: 0,
    border: 0,
    height: 200,
    scrollablePlotArea: {
      minWidth: props.scrollBar,
      scrollPositionX: 1
    },
    width: props.chartWidth
  };

  const heatMapSeries = {
    data: previousData,
    dataLabels: {
      enabled: true,
      format: '{point.price} ¢',
      style: {
        textOutline: 0,
        fontColor: colors.stone,
        fontSize: '12px',
        fontFamily: 'Aktifo-B-Medium'
      }
    }
  };

  const heatMapResponsive = {
    rules: [{
      condition: {
        maxWidth: 500
      },
      chartOptions: {
        yAxis: {
          labels: {
            format: '{point.price} ¢'
          }
        }
      }
    }]
  };

  const heatmapAccessibility = {
    point: {
      descriptionFormatter: function (point: any) {
        const xName = point.series.xAxis.categories[point.x];
        const yName = point.series.yAxis.categories[point.y];
        const val = point.price;
        return `${yName} ${val}¢ per kwh at ${xName}`;
      }
    }
  };

  const heatMapTooltip = {
    enabled: true,
    formatter: function (this: any): string {
      return `${Number(this.point.options.rawPrice.toFixed(3))}¢`;
    }
  };
  return (
    <>
      <div className='mar--t-32'>
        <Text
          appearance='body-md'
        >
          New Day-Ahead prices available at 4PM PST
        </Text>
      </div>
      <div
        className='mar--b-32 mar--t-32'
      >
        <Text
          className='pad--b-32'
          appearance='title-md'>
          Previous 3 Days
        </Text>
        <HeatMapChart
          accessibility={heatmapAccessibility}
          backgroundColor={colors.white}
          chart={heatMapChart}
          series={heatMapSeries}
          tooltip={heatMapTooltip}
          width={props.chartWidth}
          xAxis={xHeatmapAxis}
          yAxis={yHeatmapAxis}
          responsive={heatMapResponsive}
        />
      </div>
    </>
  );
};
