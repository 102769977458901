import React from 'react';

// ASSETS
import styles from './InfoItem.module.scss';

interface InfoItemProps {
  title: string;
  description: string | number | React.ReactNode;
  error?: string;
}

const InfoItem:React.FC<InfoItemProps> = ({ title, description, error }) => {
  return (
    <div className={styles['info-box']}>
      <p className={styles['info-title']}>{title}</p>
      <div className={styles['info-description']}>{description || '-'}</div>
      {error && <p className={styles['info-error']}>{error}</p>}
    </div>
  );
};

export default InfoItem;
