import { GraphCard, LegendGraphItem } from 'blocks/components';
import { Icon } from 'components';
import React from 'react';
import colors from 'style/colors';

// ASSETS
import styles from './GraphInvoicing.module.scss';
import ColumnStackedChart from 'components/ColumnStackedChart/ColumnStackedChart';

const GraphInvoicing = () => {
  const seriesData = [
    {
      name: 'Production Charges',
      data: [17000000, 17000000, 19000000, 24000000, 29000000, 29000000, 34000000, 30000000, 19000000, 16000000, 19000000, 19000000],
      color: colors.clean

    }, {
      name: 'Transmission Demand Charges',
      data: [640000, 640000, 640000, 640000, 640000, 640000, 640000, 640000, 640000, 640000, 640000, 640000],
      color: colors.wind

    }, {
      name: 'Non-Fuel Energy Charges',
      data: [21000, 21000, 21000, 21000, 21000, 21000, 21000, 21000, 21000, 21000, 21000, 21000],
      color: colors.cloud

    }, {
      name: 'Fuel Energy Charges',
      data: [1840000, 1440000, 1980000, 1980000, 1980000, 2980000, 3400000, 3000000, 1980000, 1540000, 1950000, 1650000],
      color: colors.air
    }];
  return (
    <GraphCard
      title="Invoicing"
      icon={<Icon.BarChart />}
    >
      <div className={styles['graph-container']}>
        <div className={styles['legend-container']}>
          <LegendGraphItem name="Production Charges" color={colors.clean} />
          <LegendGraphItem name="Transmission Demand Charges" color={colors.wind} />
          <LegendGraphItem name="Non-Fuel Energy Charges" color={colors.cloud} />
          <LegendGraphItem name="Fuel Energy Charges" color={colors.air} />
        </div>
        <div>
          <ColumnStackedChart
            series={seriesData}
            height={240}
            xAxis={{
              categories: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
              visible: true
            }}
          />
        </div>
      </div>
    </GraphCard>
  );
};

export default GraphInvoicing;
