import React from 'react';

// COMPONENTS
import ImplementationItem from 'widget/ExploreWidget/components/ImplementationItem/ImplementationItem';
import Button from 'components/Button/Button';

// ASSETS
import styles from '../../ImplementationPage.module.scss';
import { Resource } from 'widget/ExploreWidget/api/projects.typings';

// HELPERS
import { getModifierTags } from 'widget/ExploreWidget/helpers/utils/getModifierIcon';

interface SolarImplementationProps {
  modifier: Resource;
}

const SolarImplementation:React.FC<SolarImplementationProps> = ({ modifier }) => {
  return (
    <ImplementationItem
      title="Solar panels"
      type='modifier'
      modifier="solar"
      tags={getModifierTags(modifier)}
      footer={
        <div className={styles['content-footer']}>
          <p>Once you’ve found a contractor and scheduled an installation, enter your installation date below so we can help with the final connection.</p>
          <Button
            copy="Enter your installation date"
            variant="outlined"
            color="secondary"
          />
        </div>
      }
    >
      <div className={styles['content-quote']}>
        <div className={styles['content-text']}>
          <p className={styles['content-title']}>Prepare your home for solar</p>
          <p className={styles['content-description']}>
              Make your home energy efficient before installing a renewable energy source to maximize your investment. Complete a Home Energy Checkup and receive customized recommendations. <a href="https://gridx.com/explore/" target='_blank' rel="noreferrer">Take a free Home Energy Checkup</a>.
          </p>
        </div>
        <div className={styles['content-text']}>
          <p className={styles['content-title']}>Find the right solar contractor</p>
          <p className={styles['content-description']}>EnergyX can recommend resources and questions to ask when choosing a contractor. <a href="https://gridx.com/explore/" target='_blank' rel="noreferrer">Find a contractor</a>.</p>
        </div>
        <div className={styles['content-text']}>
          <p className={styles['content-title']}>Lean more about getting started with solar</p>
          <p className={styles['content-description']}>Use our solar guide to learn more about about installing, maintaining and benefiting from your solar system. <a href="https://gridx.com/explore/" target='_blank' rel="noreferrer">View solar guide</a>.</p>
        </div>
      </div>
    </ImplementationItem>
  );
};

export default SolarImplementation;
