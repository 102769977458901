import { curry, when, splitAt, gt, length } from 'ramda';

export const truncate = curry((str: string, limit: number) => {
  const isTooLong = gt(length(str), limit);

  const splitAndAppend = (s: string) => {
    const [head] = splitAt(limit, s);
    return head + '...';
  };

  return when(
    () => isTooLong,
    splitAndAppend
  )(str);
});
