import React from 'react';
import { useDispatch } from 'react-redux';

// COMPONENTS
import { FilterControl } from 'blocks/components';

// ASSETS
import { setAccountFilter } from 'store/modules/accounts/accounts.slice';
import { Box, DatePicker, Icon } from 'components';

// TYPINGS
import { IAccount } from 'api/accounts/accounts.typings';
import { FilterItem } from 'store/helpers/store.typings';

interface FilterEffectiveDateProps {
  selectedFilters: FilterItem<IAccount>[];
}

const FilterEffectiveDate:React.FC<FilterEffectiveDateProps> = ({ selectedFilters }) => {
  const dispatch = useDispatch();

  // EVENTS
  const handleDateChange = (dates: Date[]) => {
    dispatch(setAccountFilter({ key: 'effectiveDate', type: 'daterange', value: dates }));
  };

  // RENDER
  const filterValue = selectedFilters.find((filter) => filter.key === 'effectiveDate')?.value as Date[];

  return (
    <FilterControl title="Effective date" expanded>
      <Box zIndex={9999}>
        <DatePicker
          size='md'
          format='y-MM-dd'
          dayPlaceholder='Datepicker'
          calendarIcon={<Icon.Calendar />}
          isRange={true}
          onChange={handleDateChange}
          value={filterValue}
          clearIcon={null}
        />
      </Box>
    </FilterControl>
  );
};

export default FilterEffectiveDate;
