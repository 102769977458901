import { Color, ColorCode } from 'hooks/Theme/Theme.model';
import colors from 'style/colors';

export const getStatusCustomColor = (customerType: string, getThemeColor: Function) => {
  switch (customerType) {
  case 'Pre-Billing':
    return getThemeColor(Color.Primary, ColorCode.C200, colors.storm);
  case 'Approved':
    return getThemeColor(Color.Primary, ColorCode.C200, colors.stem);
  case 'Pending approval':
    return getThemeColor(Color.Primary, ColorCode.C200, colors.watt);
  case 'Exception':
    return getThemeColor(Color.Primary, ColorCode.C200, colors.heat);
  default:
    return '#333';
  }
};

export const getStatusTextColor = (customerType: string, hasThemePalette: boolean) => {
  switch (customerType) {
  case 'Pre-Billing':
    return hasThemePalette ? '#fff' : '';
  default:
    return '#fff';
  }
};
