import React, { useState } from 'react';
import classnames from 'classnames';

// COMPONENTS
import Button from '../../../components/Button/Button';
import TextInput from '../../../components/TextInput/TextInput';
import Icon from '../../../components/Icon/Icon';

// ASSETS
import styles from './FilterSearchBar.module.scss';
import { ActionList, Popover } from 'components';
import { ActionListItem } from 'components/ActionList/ActionList.helpers';

interface FilterSearchBarProps {
  onSearch?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onOpenFilter?: () => void;
  searchPlaceholder?: string;
  recentSearches?: string[];
  searchString?: string;
  sortOptions?: ActionListItem[];
  variant?: 'contained' | 'outlined'
  color?: 'primary' | 'secondary';
  hideFilter?: boolean;
  hideSortBy?: boolean;
  filteredCount?: number;
}

const FilterSearchBar:React.FC<FilterSearchBarProps> = ({
  onSearch,
  onOpenFilter,
  searchPlaceholder = '',
  recentSearches = [],
  searchString = '',
  sortOptions,
  variant = 'contained',
  color = 'primary',
  hideFilter = false,
  hideSortBy = false,
  filteredCount = 0
}) => {
  const [active, setActive] = useState(false);
  const [sortActive, setSortActive] = useState(false);

  // EVENTS
  const openRecentSearch = () => {
    if (recentSearches.length > 0) {
      setActive(true);
    }
  };

  const toggleSort = () => {
    setSortActive(!sortActive);
  };

  // RENDERS
  const activator = (
    <TextInput
      endAdornment={<Icon.Search/>}
      errorMessage="Validation message text"
      id="my-text-input"
      infoMessage=""
      label=""
      placeholder={searchPlaceholder}
      size="md"
      onChange={onSearch}
      onFocus={openRecentSearch}
      value={searchString}
      fullWidth
    />
  );

  const buildRecentSearches = () => {
    return recentSearches.map((search) => {
      return {
        content: search,
        onClick: () => {
          if (onSearch) {
            const syntheticEvent = {
              target: {
                value: search
              },
              preventDefault: () => {},
              stopPropagation: () => {},
              currentTarget: {
                value: search
              }
            } as React.ChangeEvent<HTMLInputElement>;

            setActive(false);
            onSearch(syntheticEvent);
          }
        }
      };
    });
  };

  const sortButton = (
    <Button
      color={color}
      copy="Sort by"
      icon={<Icon.Minimize2 />}
      iconPosition="left"
      size='md'
      variant={variant}
      onClick={toggleSort}
    />
  );

  // STYLES
  const cssSearchContainer = classnames(styles['search-container'], {
    [styles['search-container--filter-hidden']]: hideFilter,
    [styles['search-container--sort-hidden']]: hideSortBy
  });

  return (
    <div className={styles['filter-search-bar']}>
      {
        !hideFilter && (
          <Button
            color={color}
            copy="Filter"
            icon={<Icon.Filter />}
            iconPosition="left"
            iconRight={filteredCount && <div className={styles.filteredCount}>{filteredCount}</div>}
            size='md'
            variant={filteredCount ? 'contained' : variant}
            onClick={onOpenFilter}
          />
        )
      }
      <div className={cssSearchContainer}>
        <Popover
          isOpen={active}
          renderReference = { activator }
          onOutsideClickClose={() => setActive(false)}
          p={0}
          placement='bottom-start'
          zIndex={999}
          fullWidth
        >
          <div className={styles['recent-searches']}>
            <span>Recent searches</span>
            <ActionList items={buildRecentSearches()} />
          </div>
        </Popover>
      </div>
      {
        !hideSortBy && (
          <Popover
            isOpen={sortActive}
            renderReference={sortButton}
            onOutsideClickClose={() => setSortActive(false)}
            p={0}
            placement='bottom-end'
            zIndex={999}
          >
            <div onClick={() => setSortActive(false)} role="presentation">
              <ActionList items={sortOptions} />
            </div>
          </Popover>
        )
      }
    </div>
  );
};

export default FilterSearchBar;
