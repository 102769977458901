/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { truncate } from 'utils';

// COMPONENTS
import Tag from 'components/Tag/Tag';

// ASSETS
import styles from './TableRateCompare.module.scss';
import { ColCaptions, CompareRate, CompareRateDetails, MapMonthsDisplay, Month, MonthCaption, MonthDisplay } from './types';
import { Resource } from 'widget/ExploreWidget/api/projects.typings';
import Box from 'components/Box/Box';
import Icon from 'components/Icon/Icon';

const getCurrencyFormat = (val: string) => {
  if (!val) return '';

  const formatDecimal = Math.abs(Number.parseFloat(val)).toFixed(2);

  return Number(formatDecimal).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
};

const mapMonthsDisplay: MapMonthsDisplay = {
  1: 'January ',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
};

export const prepareTableData = (data: any, resourseList: Array<Resource> = []) => {
  const { tagAttributesList, months, rateDetails = [] } = data;
  const rateCodes = rateDetails.map((rate: any) => rate.ratePlanCode);

  const colCaptions: ColCaptions = {
    key: 2,
    date: ''
  };

  const avlRatesDisplay: ColCaptions = {
    key: 1,
    date: ''
  };

  rateDetails.forEach((rate: any, index: number) => {
    avlRatesDisplay[`${rate.ratePlanCode}-${index}` as keyof object] = (
      <div className={styles['rate-cell']}>
        {/* @ts-ignore */}
        <p>{truncate(rateDetails[index]?.rateMarketingName, 25)}</p>
        {rate.currentRate && (<Tag copy='Current rate' color="mist" size="sm" />)}
        {rate.bestRate && (
          <Box display="flex" gap={1}>
            <Tag copy='Lowest cost' color="success" size="sm" />
            <Tag copy='SmartCharge' icon={<Icon.Plus/>} color="sheen" size="sm" />
          </Box>
        )}
      </div>
    );

    colCaptions[`${rate.ratePlanCode}-${index}` as keyof object] = (
      <div className={styles['cost-value']}>
        <div>
          <p className={styles['cost-value__label']}>{rate.currentRate ? '' : 'Savings estimate'}</p>
        </div>
        <div>
          <p className={styles['cost-value__label']}>Cost estimate</p>
        </div>
      </div>
    );
  });

  const monthsDisplay: Array<MonthDisplay> = [];

  months.forEach((month: Month, index: number) => {
    const plotObj: MonthCaption = {
      key: `month-${index}`,
      date: mapMonthsDisplay[month.firstColumnAttr as keyof MapMonthsDisplay] || month.firstColumnAttr + '',
      isExpandable: true
    };

    month.compareRate.forEach((ele: CompareRate, index: number) => {
      const { dif, tagAttributes = [] } = ele;
      const styleName = dif < 0 ? 'cost-value__saving' : 'cost-value__increase';
      plotObj[`${rateCodes[index]}-${index}`] = (isExpanded: boolean) => (
        <>
          <div className={styles['cost-value']}>
            <div>
              <p className={styles[styleName]}>{dif !== 0 ? getCurrencyFormat(dif + '') : ''}</p>
            </div>
            <div>
              <p className={styles['cost-value__value']}>{getCurrencyFormat(ele.total + '')}</p>
            </div>
          </div>

          {isExpanded && (
            <div className={styles['cost-value__expanded']}>
              {tagAttributesList?.map((tag: string) => (
                <div key={`tag-${tag}`}>
                  <p className={styles['tag-attributes']}>{getCurrencyFormat(tagAttributes[tag as keyof object] + '')}</p>
                </div>
              ))}
            </div>
          )}
        </>
      );
    });

    monthsDisplay.push(plotObj as MonthDisplay);
  });

  return [avlRatesDisplay, colCaptions, ...monthsDisplay];
};
