import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Theme } from './Theme.model';
import { applyThemeToRoot } from './Theme.helpers';
import { defaultTheme } from './Theme.config';

interface ThemeContextProps {
  theme: Theme,
  setTheme: Dispatch<SetStateAction<Theme>>
  getThemeColor: (color: string, code: string, fallback: string) => string
  hasThemePalette: boolean,
  slug: string
}

export const ThemeContext = React.createContext<ThemeContextProps>({
  theme: defaultTheme
} as ThemeContextProps);

export interface ThemeProviderProps {
  children: React.ReactNode,
  theme?: Theme
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children, theme = defaultTheme }) => {
  const [currentTheme, setCurrentTheme]: [Theme, Dispatch<SetStateAction<Theme>>] = React.useState<Theme>(theme);

  useEffect(() => {
    applyThemeToRoot(currentTheme);
  }, [currentTheme]);

  const getThemeColor = (color: string, code: string, fallback: string): string => {
    if (Object.keys(currentTheme).length > 0 && currentTheme.palette) {
      const colorSchema = currentTheme.palette[color];
      if (colorSchema) {
        return colorSchema[code] || fallback;
      }
    }
    return fallback;
  };

  const getSlug = (): string => {
    if (Object.keys(currentTheme).length > 0 && currentTheme.brand) {
      if (currentTheme.brand.slug) {
        return currentTheme.brand.slug;
      }
    }

    return 'app';
  };

  return (
    <ThemeContext.Provider value={{
      theme: currentTheme,
      setTheme: setCurrentTheme,
      getThemeColor,
      hasThemePalette: Boolean(Object.keys(currentTheme).length > 0 && currentTheme.palette),
      slug: getSlug()
    }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => React.useContext(ThemeContext);
