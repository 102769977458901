import React from 'react';

// COMPONENTS
import Icon from '../../../components/Icon/Icon';

// ASSETS
import styles from './GraphCard.module.scss';

interface GraphCardCardProps {
  title: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
}

const GraphCard: React.FC<GraphCardCardProps> = ({ title, children, icon }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles['header-left']}>
          {icon && (<div className={styles.icon}>{icon}</div>)}
          <span className={styles.title}>{title}</span>
        </div>
        <div>
          <div className={styles['icon-more']}><Icon.MoreVertical /></div>
        </div>
      </div>
      <div className={styles.body}>
        {children}
      </div>
    </div>
  );
};

export default GraphCard;
