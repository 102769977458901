import React from 'react';

// COMPONENTS
import { Text } from '../../../../components';
import { FilterSearchBar } from '../../../../blocks/components';

// HELPERS
import { useAnalyseList } from '../../../../api/analyze/analyze.api';

// ASSETS
import styles from './AnalyzeList.module.scss';
import { ListAnalyses } from './components';
import { IAnalyse } from '../../../../api/analyze/analyze.typings';

const AnalyzeList = () => {
  const { data: analyses, isLoading } = useAnalyseList({ rateId: 1 });
  return (
    <div className={`${styles['list-page']} admin--page pad--t-128 pad--l-128 pad--r-36`} role="main">
      <div className="pad--b-24">
        <Text className='title--md' variant='h2'>
          Analyses
        </Text>
      </div>
      <FilterSearchBar />
      {
        (!isLoading && analyses) && (
          <ListAnalyses analyses={analyses as IAnalyse[]} />
        )
      }
    </div>
  );
};

export default AnalyzeList;
