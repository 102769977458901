import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// ASSETS
import './DonutChart.scss';

// HELPERS
import colors, { chartColorsSchema1 } from '../../style/colors';
import { useTheme } from '../../hooks/Theme/Theme.context';
import { Color, ColorCode } from 'hooks/Theme/Theme.model';

type DataDonut = {
  name?: string;
  y: number;
  color?: string;
}

interface DonutChartProps {
  data: Array<DataDonut>;
  width?: number | string | null;
  height?: number | string | null;
  innerSize?: number | string;
  startAngle?: number;
  title?: Highcharts.TitleOptions
}

const DonutChart:React.FC<DonutChartProps> = ({ data, title = {}, width = null, height = null, innerSize = '60%', startAngle = 180 }) => {
  const { theme, getThemeColor } = useTheme();

  const options = {
    title: {
      text: '',
      align: 'center',
      verticalAlign: 'middle',
      style: {
        color: '#333333',
        fontSize: '22px',
        fontWeight: 500,
        fontFamily: Object.keys(theme).length > 0 ? theme.typography?.fontFamily : 'Aktifo-B-Medium'
      },
      ...title
    },
    accessibility: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      padding: 0,
      borderRadius: 0,
      borderWidth: 0,
      shadow: false,
      style: {
        backgroundColor: '#fff',
        opacity: 1
      },
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        return `
            <div class="donutTooltip">
              <strong>${this.percentage.toFixed(1)}%</strong>
              <span>${this.y}</span>
              <span>${this.point.name}</span>
            </div>
          `;
      }
    },
    chart: {
      height,
      width,
      backgroundColor: getThemeColor(Color.LightGray, ColorCode.C100, colors.white),
      plotBackgroundColor: getThemeColor(Color.LightGray, ColorCode.C100, colors.white),
      plotBorderWidth: 0,
      plotShadow: false,
      events: {
        load: function (this: Highcharts.Chart) {
          // Check if the chart's container and its SVG element exist.
          if (this.container && this.container.getElementsByTagName('svg').length > 0) {
            const svg = this.container.getElementsByTagName('svg')[0];

            // Create a title element.
            const title = document.createElementNS('http://www.w3.org/2000/svg', 'title');
            title.textContent = 'Donut Chart';

            // Prepend the title to the SVG element. This ensures that the title is the first child of the SVG.
            svg.insertBefore(title, svg.firstChild);

            // Set aria-label and role attributes.
            svg.setAttribute('aria-label', 'Donut Chart');
            svg.setAttribute('role', 'img');
          }
        }
      }
    },
    plotOptions: {
      pie: {
        startAngle,
        borderWidth: 0,
        dataLabels: {
          enabled: false
        }
      }
    },
    series: [
      {
        type: 'pie',
        innerSize,
        data: data.map((item: any, index: number) => {
          return {
            name: item.name || '',
            y: item.y,
            color: item.color || chartColorsSchema1[index]
          };
        })
      }
    ]
  };
  return (
    <HighchartsReact highcharts={Highcharts} options={options} />
  );
};

export default DonutChart;
