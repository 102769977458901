import * as Icon from 'react-feather';

/**
* Represents a Icon.
* @constructor
* @param {string} color
* @param {string} size
* Usage :
* ````js
* <Icon color='currentColor' size='24px' />
* ````
* @augments {Component<Props, State>}
*/
/** */

export default Icon;
