import React from 'react';

import Button from '../components/Button/Button';
import Card from '../components/Card/Card';
import Icon from '../components/Icon/Icon';
import Logo from '../components/Logo/Logo';
import Text from '../components/Text/Text';

import appLaunchMockLists from '../json/apps_launcher_mock.json';

/**
* Represents a CitadelHomepage.
* @constructor
* Usage :
* ````js
* <CitadelHomepage  />
* ````
* @augments {CitadelHomepage<Props, State>}
*/
/** */

const CitadelHomepage: React.FC = () => {
  const renderCards = (cardList:Array<object>) => {
    const cards = cardList.map((item:any) => {
      const {
        access,
        cta,
        description,
        icon,
        heading,
        link,
        owner
      } = item;

      return (
        <Card
          className={`
            bor--1-mist
            mar--r-8
            mar--t-8
            shadow--sm
        `}
          key={heading}
          size='sm'
        >
          {access && link
            ? (
              <a
                href={link}
                target="_blank"
                rel="noreferrer"
              >
                <div
                  className={`
                ${!description || 'mar--b-24'}
                flex--align-center 
                flex`
                  }
                >
                  <Logo
                    className='dis--inline-block'
                    name={icon}
                    size='sm'
                  />
                  <div className='mar--l-8'>
                    {!owner || (
                      <Text
                        className='body--md bold'
                      >
                        {owner}
                      </Text>
                    )}
                    <div
                      className='flex'
                    >
                      <Text
                        className='body--xl bold'
                      >
                        {heading}
                      </Text>
                      {!access || <Icon.ChevronRight />}
                    </div>
                  </div>
                </div>
              </a>
            )
            : (
              <div
                className={`
                ${!description || 'mar--b-24'}
                flex--align-center 
                flex`
                }
              >
                <Logo
                  className='dis--inline-block'
                  name={icon}
                  size='sm'
                />
                <div className='mar--l-8'>
                  {!owner || (
                    <Text
                      className='body--md bold'
                    >
                      {owner}
                    </Text>
                  )}
                  <div
                    className='flex'
                  >
                    <Text
                      className='body--xl bold'
                    >
                      {heading}
                    </Text>
                    {!access || <Icon.ChevronRight />}
                  </div>
                </div>
              </div>
            )}

          {!description || (
            <Text
              className='body--md bor--t-1 pad--t-24'
              variant='body1'
            >
              {description}
            </Text>
          )}
          {!cta || (
            <Button
              className='mar--t-32'
              color='depth'
              copy={cta.text}
              size='md'
              type='secondary'
            />
          )}
        </Card>
      );
    });

    return (
      <div
        className='flex--column'
      >
        {cards}
      </div>
    );
  };
  const handleListClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.currentTarget;
    target.classList.toggle('close--next-card');
  };

  const handleListKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      const target = e.currentTarget;
      target.classList.toggle('close--next-card');
    }
  };

  return (
    <div className='admin--page pad--t-128 pad--l-128'>
      <Text
        className='title--md pad--b-16'
        variant='h2'
      >
        Home
      </Text>
      {/* <Card
        className={`
          bor--1-mist
          card--bg-sheen
          mar--b-16
        `}
      >
        <div
          className='flex cursor--pointer'
          onClick={(e) => handleListClick(e)}
          onKeyDown={handleListKeyDown}
          role='button'
          tabIndex={0}
        >
          <Icon.Database />
          <Text
            className='title--md pad--l-8 pad--r-8'
            variant='h2'
          >
            Data
          </Text>
          <Icon.ChevronDown className='indicator' />
        </div>
        <div className='card--container mar--t-8'>
          {renderCards(appLaunchMockLists[0].data_list!)}
        </div>
      </Card> */}

      <Card
        className={`
          bor--1-mist
          card--bg-sheen
          mar--b-16
        `}
      >
        <div
          className='flex cursor--pointer'
          onClick={(e) => handleListClick(e)}
          onKeyDown={handleListKeyDown}
          role='button'
          tabIndex={0}
        >
          <Icon.Grid />
          <Text
            className='title--md pad--l-8 pad--r-8'
            variant='h2'
          >
            Applications
          </Text>
          <Icon.ChevronDown className='indicator' />
        </div>
        <div className='card--container mar--t-8'>
          {renderCards(appLaunchMockLists[1].available_apps!)}
        </div>
      </Card>
      <Card
        className={`
          bor--1-mist
          card--bg-sheen
          mar--b-16
        `}
      >
        <div
          className='flex cursor--pointer'
          onClick={(e) => handleListClick(e)}
          onKeyDown={handleListKeyDown}
          role='button'
          tabIndex={0}
        >
          <Icon.Compass />
          <Text
            className='title--md pad--l-8 pad--r-8'
            variant='h2'
          >
            Explore more
          </Text>
          <Icon.ChevronDown className='indicator' />
        </div>
        <div className='card--container mar--t-8'>
          {renderCards(appLaunchMockLists[2].unavailable_apps!)}
        </div>
      </Card>
    </div>
  );
};

export default CitadelHomepage;
