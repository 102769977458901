import React, { useState } from 'react';
import classnames from 'classnames';

// COMPONENTS
import InputLabel from 'components/InputLabel/InputLabel';
import { Box, Icon } from 'components';
import AlertInline from 'components/AlertInline/AlertInline';

// ASSETS
import styles from './Upload.module.scss';

// HELPERS
import { idGenerator } from 'utils';

export interface UploadProps {
  /** Label for the input */
  label: string;
  /** button text */
  buttonLabel?: string;
  /** drop area text */
  dropAreaLabel?: string;
  /** Disable the input */
  disabled?: boolean;
  /** Error to display beneath the label */
  error?: Error | boolean | string;
  /** Name of the input */
  name?: string;
  /** ID for the input */
  id?: string;
  /** Callback when value is changed */
  onChange?(e: React.ChangeEvent<any> | string, id?: string): void;
  /** hide/disable drag & drop option  */
  dragDropHidden?: boolean;
  button: React.ReactNode;
  onUpload?: (file: File) => void;
}

const generatedId = idGenerator('Upload');

const Upload: React.FC<UploadProps> = ({
  label,
  disabled = false,
  button,
  dropAreaLabel,
  onUpload,
  onChange,
  name,
  id = generatedId(),
  dragDropHidden = false,
  error
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [isDragHover, setIsDragHover] = React.useState(false);

  const refFileInput: React.RefObject<HTMLInputElement> = React.useRef(null);

  // EVENTS
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const reader = new FileReader();

    const eventFile = e.target.files ? e.target.files[0] : undefined;

    if (eventFile) {
      reader.onloadend = () => {
        setFile(eventFile);
        if (onUpload) {
          onUpload(eventFile);
        }
      };
      reader.readAsDataURL(eventFile);
    } else {
      setFile(null);
    }

    if (onChange) {
      onChange(e, e.target.name);
    }
  };

  const handleClick = () => {
    if (refFileInput && refFileInput.current) {
      refFileInput.current.click();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick();
    }
  };

  const handleFileDrop = (file: File) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setFile(file);
    };

    reader.readAsDataURL(file);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!disabled) {
      const droppedFile = e.dataTransfer.items
        ? e.dataTransfer.items[0].getAsFile()
        : e.dataTransfer.files[0];
      if (droppedFile) {
        handleFileDrop(droppedFile);
      }
      setIsDragHover(false);
    }
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!disabled) {
      setIsDragHover(false);
    }
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!disabled) {
      setIsDragHover(false);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!disabled) {
      setIsDragHover(true);
    }
  };

  const handleDeleteFile = () => {
    setFile(null);
    if (onUpload) {
      onUpload({} as File);
    }
  };

  // STYLES
  const cssDropZone = classnames(styles['form-drop-zone'], { [styles.hovered]: isDragHover });

  return (
    <div className={styles['input-file']}>
      {label && <InputLabel>{label}</InputLabel>}
      <div className={styles['input-file__body']}>
        {!dragDropHidden && (
          <div
            className={styles['form-drop-zone-container']}
            onDrop={handleDrop}
            onDrag={handleDrag}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
          >
            <div className={cssDropZone} id="form-drop-zone">
              <span>{dropAreaLabel}</span>
            </div>
          </div>
        )}
        <div
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex={0}
        >
          {button}
        </div>
        <input ref={refFileInput} name={name} id={id} type="file" onChange={handleChange} disabled={disabled} />
      </div>
      {
        error && (
          <div className={styles['error-container']}>
            <AlertInline
              action
              alertMessage={
                <div className={styles['error-body']}>
                  {typeof error === 'string' && (
                    <Box mb={2}>
                      <span><b>{error}</b></span>
                    </Box>
                  )}
                  <span>Please check the file and try again.</span>
                </div>
              }
              alertTitle="Import failed"
              icon
              type="error"
            />
          </div>
        )
      }
      <div className={styles['file-list']}>
        {file && (
          <div className={styles['file-item']}>
            <Box mr={1} display="flex">
              <Icon.File />
            </Box>
            <span>{file.name}</span>
            <Box ml={3} display="flex">
              <Icon.Trash onClick={handleDeleteFile} />
            </Box>
          </div>
        )}
      </div>
    </div>
  );
};

export default Upload;
