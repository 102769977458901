import React from 'react';
import Highcharts from 'highcharts';

// COMPONENTS
import { GraphCard, LegendGraphItem } from '../../../../../../blocks/components';
import Icon from '../../../../../../components/Icon/Icon';
import ColumnChart from '../../../../../../components/ColumnChart/ColumnChart';

// ASSETS
import styles from '../../FullPopulation.module.scss';

// HELPERS
import { transformData } from './GraphPrograms.helpers';
import formatToCurrency from '../../../../../../utils/formatToCurrency';

// TYPINGS
import { ICustomerData } from '../../../../../../api/analyze/analyze.typings';
import { useTheme } from '../../../../../../hooks/Theme/Theme.context';

interface GraphProgramsProps {
  customers: Array<ICustomerData>;
}

const GraphPrograms:React.FC<GraphProgramsProps> = ({ customers }) => {
  const { getThemeColor } = useTheme();

  // RENDERS
  const graphData = transformData(customers, getThemeColor);

  return (
    <GraphCard
      title={`${graphData.length} Programs`}
      icon={<Icon.Codesandbox />}
    >
      <div className={styles['graph-container']}>
        <div className={styles['legend-container']}>
          {
            graphData.map((item) => {
              return (
                <LegendGraphItem key={item.name} name={item.name} color={item.color as string} />
              );
            })
          }
        </div>
        <div>
          <ColumnChart
            title={{
              text: 'Program enrollment'
            }}
            series={graphData}
            height={240}
            tooltip={{
              formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
                return `
                    <div class="donutTooltip">
                      <strong>${formatToCurrency(this.y as number)}</strong>
                      <span>${this.series.name}</span>
                    </div>
                  `;
              }
            }}
          />
        </div>
      </div>
    </GraphCard>
  );
};

export default GraphPrograms;
