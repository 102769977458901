import React from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';

// COMPONENTS
import DataTable from 'components/DataTable/DataTable';
import { Button, Dropdown, Select } from 'components';

// ASSETS
import styles from './Attributes.module.scss';

// HELPERS
import { useAttributes } from 'api/accounts/accounts.api';
import { IAttribute } from 'api/accounts/accounts.typings';
import { simpleListWithIcon } from 'components/ActionList/stories/ActionList.stories.helpers';
import { valueOptions } from './Attributes.helpers';

const Attributes = () => {
  const { data: attributesData, isLoading } = useAttributes();
  const [editRows, setEditRows] = React.useState<string[]>([]);

  // EVENTS
  const handleEditRow = (info: CellContext<IAttribute, unknown>) => {
    if (!editRows.includes(info.row.id)) {
      setEditRows(prevEditRows => [...prevEditRows, info.row.id]);
    }
  };

  const handleSaveRow = (info: CellContext<IAttribute, unknown>) => {
    if (editRows.includes(info.row.id)) {
      setEditRows(prevEditRows => prevEditRows.filter((rowId: any) => rowId !== info.row.id));
    }
  };

  const columns: any[] = React.useMemo<ColumnDef<IAttribute>[]>(
    () => [
      {
        accessorKey: 'attributeId',
        header: 'Id',
        enableSorting: false
      },
      {
        accessorKey: 'attributeName',
        id: 'attributeName',
        header: 'Name',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        }
      },
      {
        accessorKey: 'value',
        header: 'Value',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        },
        cell: (info) => {
          if (editRows.includes(info.row.id)) {
            return <Select options={valueOptions} size="sm" />;
          } else {
            return info.getValue();
          }
        }
      },
      {
        accessorKey: 'customerIdType',
        header: 'Type',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        }
      },
      {
        accessorKey: 'start',
        header: 'Start',
        enableColumnFilter: false
      },
      {
        accessorKey: 'end',
        header: 'End',
        enableColumnFilter: false
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        enableColumnFilter: false,
        enableSorting: false,
        maxSize: 50,
        cell: (info) => {
          if (editRows.includes(info.row.id)) {
            return (
              <div className={styles.actionButton}>
                <Button copy='Save' type='secondary' size="xs" onClick={() => handleSaveRow(info)} />
              </div>
            );
          } else {
            return (
              <div className={styles.actionButton}>
                <Button copy='Edit' type='secondary' size="xs" onClick={() => handleEditRow(info)} />
              </div>
            );
          }
        }
      }
    ],
    [editRows]
  );

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <h3>Attributes</h3>
        <div className={styles.actions}>
          <Dropdown label="Actions" options={simpleListWithIcon} color='depth' />
        </div>
      </div>
      {(!isLoading && attributesData) && (
        <DataTable columns={columns} data={attributesData as IAttribute[]} columnPinning={{ right: ['actions'] }}/>
      )}
    </div>
  );
};

export default Attributes;
