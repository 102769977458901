import React from 'react';
import { Route } from 'react-router-dom';

// COMPONENTS
import FigmaIframe from 'pages/FigmaIframe';

const northStarDemo = 'https://www.figma.com/proto/k0q5oAE2oMZaBqQG0pwRb9/%F0%9F%9F%A2-NorthStar---Demo-v2-(Q1-2023)?embed_host=share&kind=&node-id=1533-165088&page-id=1525%3A188275&scaling=min-zoom&starting-point-node-id=1533%3A165088&viewport=-1595%2C66%2C0.59&hotspot-hints=0&hide-ui=1';

const citadelFigmaDemo = 'https://www.figma.com/proto/r3oLbVEXPYPQs2yOlM3S5a/%F0%9F%9F%A2-Citadel---Demo-(Q2-2023)?embed_host=share&kind=&node-id=1598-151995&page-id=1525%3A188275&scaling=min-zoom&starting-point-node-id=1598%3A151995&viewport=63%2C132%2C0.02&hotspot-hints=0&hide-ui=1';

const kamFigmaDemo = 'https://www.figma.com/proto/Iur0YyPrXwtm3uQXMurgwB/%F0%9F%9F%A2-KAM---Demo-(Q2-2023)?embed_host=share&kind=&node-id=1716-162492&page-id=1525%3A188275&scaling=min-zoom&starting-point-node-id=1716%3A162489&viewport=65%2C147%2C0.06&hotspot-hints=0&hide-ui=1';

const designFigmaDemo = 'https://www.figma.com/proto/sloaJjae3rb2C5DbAWznRb/%F0%9F%9F%A2-Design---Demo-(Q2-2023)?embed_host=share&hotspot-hints=0&kind=&node-id=1609-152949&page-id=1525%3A188275&scaling=min-zoom&starting-point-node-id=1609%3A151230&viewport=22%2C178%2C0.1&hide-ui=1';

const analyzeFigmaDemo = 'https://www.figma.com/proto/pyC9MefXUC6yRaw6aNxua3/%F0%9F%9F%A2-Analyze---Demo-(Q2-2023)?embed_host=share&kind=&node-id=1716-121718&page-id=1525%3A188275&scaling=min-zoom&starting-point-node-id=1716%3A121715&viewport=77%2C148%2C0.07&hotspot-hints=0&hide-ui=1';

const exploreFigmaDemo = 'https://www.figma.com/proto/FjMH0tzboAtbSqgwy9sVkv/GridX-Explore---Utility-X---Energy-Scenario-Builder?node-id=397-11450&scaling=min-zoom&page-id=397%3A10843&hotspot-hints=0&hide-ui=1';

const FigmaIframeRoutes = [
  <Route key="demo" path='/demo' element={<FigmaIframe figmaEmbedLink={northStarDemo} />} />,
  <Route key="design" path='/demo/design' element={<FigmaIframe figmaEmbedLink={designFigmaDemo} />} />,
  <Route key="analyze" path='/demo/analyze' element={<FigmaIframe figmaEmbedLink={analyzeFigmaDemo} />} />,
  <Route key="kam" path='/demo/kam' element={<FigmaIframe figmaEmbedLink={kamFigmaDemo} />} />,
  <Route key="explore" path='/demo/explore' element={<FigmaIframe figmaEmbedLink={exploreFigmaDemo} />} />,
  <Route key="citadel" path='/demo/citadel' element={<FigmaIframe figmaEmbedLink={citadelFigmaDemo} />} />
];

export default FigmaIframeRoutes;
