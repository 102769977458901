import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// SELECTORS
import { getIsUpdateSystemOpen, getUpdateSystemFile } from 'store/modules/project/project.selector';

// ACTIONS
import { closeUpdateSystem, openDataUpdate, setUpdateSystemFile } from 'store/modules/project/project.slice';

// COMPONENTS
import Upload from 'components/Upload/Upload';
import { Button, Drawer, Icon } from 'components';
import { DownloadLink } from 'blocks/components';

// ASSETS
import styles from './UpdateSystemDrawer.module.scss';
import { isEmpty } from 'utils';

const UpdateSystemDrawer = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsUpdateSystemOpen);
  const updateSystemFile = useSelector(getUpdateSystemFile);

  // EVENTS
  const handleClose = () => {
    dispatch(closeUpdateSystem());
  };

  const handleUpload = (file: File) => {
    dispatch(setUpdateSystemFile(file));
  };

  const handleSubmitData = () => {
    dispatch(closeUpdateSystem());
    dispatch(openDataUpdate());
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      size="sm"
      onClose={handleClose}
      title="Update system"
    >
      <p>
        Porem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
      </p>
      <div className={styles.templates}>
        <DownloadLink text='Download bill template' />
        <DownloadLink text='Download invoice template' />
      </div>
      <Upload
        label="Upload file"
        button={<Button copy="Choose file" type="primary" size="md" icon={<Icon.Upload size={16} />} iconPosition="left" />}
        onUpload={handleUpload}
      />
      <div className={styles.footer}>
        <Button copy="Cancel" type="secondary" size="md" fullWidth />
        <Button copy="Continue" type="primary" color="magma" size="md" fullWidth disabled={isEmpty(updateSystemFile)} onClick={handleSubmitData} />
      </div>
    </Drawer>
  );
};

export default UpdateSystemDrawer;
