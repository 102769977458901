export const series1 = [{
  name: '',
  borderColor: 'rgba(251, 226, 136, 0)',
  pointWidth: 72,
  dataLabels: {
    enabled: true
  },
  data: [
    {
      x: Date.UTC(2023, 5, 7, 0, 0, 0),
      x2: Date.UTC(2023, 5, 7, 23, 59, 59),
      y: 0,
      color: 'rgba(251, 226, 136, 0.5)'
    },
    {
      x: Date.UTC(2023, 5, 7, 12, 0, 0),
      x2: Date.UTC(2023, 5, 7, 21, 0, 0),
      y: 0,
      color: 'rgba(255, 208, 39, 0.5)'
    },
    {
      x: Date.UTC(2023, 5, 7, 15, 0, 0),
      x2: Date.UTC(2023, 5, 7, 18, 0, 0),
      y: 0,
      color: 'rgba(241, 189, 5, 0.5)'
    }
  ]
}];

export const series2 = [{
  name: '',
  borderColor: 'rgba(251, 226, 136, 0)',
  pointWidth: 72,
  dataLabels: {
    enabled: true
  },
  data: [
    {
      x: Date.UTC(2023, 5, 7, 0, 0, 0),
      x2: Date.UTC(2023, 5, 7, 23, 59, 59),
      y: 0,
      color: 'rgba(251, 226, 136, 0.5)'
    },
    {
      x: Date.UTC(2023, 5, 7, 12, 0, 0),
      x2: Date.UTC(2023, 5, 7, 21, 0, 0),
      y: 0,
      color: 'rgba(255, 208, 39, 0.5)'
    },
    {
      x: Date.UTC(2023, 5, 7, 15, 0, 0),
      x2: Date.UTC(2023, 5, 7, 18, 0, 0),
      y: 0,
      color: 'rgba(241, 189, 5, 0.5)'
    }
  ]
}];

export const series3 = [{
  name: '',
  borderColor: 'rgba(251, 226, 136, 0)',
  pointWidth: 72,
  dataLabels: {
    enabled: true
  },
  data: [
    {
      x: Date.UTC(2023, 5, 7, 0, 0, 0),
      x2: Date.UTC(2023, 5, 7, 23, 59, 59),
      y: 0,
      color: 'rgba(251, 226, 136, 0.5)'
    },
    {
      x: Date.UTC(2023, 5, 7, 12, 0, 0),
      x2: Date.UTC(2023, 5, 7, 21, 0, 0),
      y: 0,
      color: 'rgba(255, 208, 39, 0.5)'
    },
    {
      x: Date.UTC(2023, 5, 7, 15, 0, 0),
      x2: Date.UTC(2023, 5, 7, 18, 0, 0),
      y: 0,
      color: 'rgba(241, 189, 5, 0.5)'
    }
  ]
}];
