import React from 'react';

// ASSETS
import styles from './BannerBlock.module.scss';

const BannerBlock = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}></div>
      <div className={styles.body}>
        <div className={styles.body__left}>
          <div className={styles['body__left-item']}></div>
          <div className={styles['body__left-item']}></div>
        </div>
        <div className={styles.body__right}></div>
      </div>
    </div>
  );
};

export default BannerBlock;
