export const getModalSize = (size: string) => {
  let finalSize;

  switch (size) {
  case 'xs':
    finalSize = 360;
    break;
  case 'sm':
    finalSize = 480;
    break;
  case 'md':
    finalSize = 600;
    break;
  case 'lg':
    finalSize = 1280;
    break;
  case 'full':
  case 'viewport':
    finalSize = '100%';
    break;
  default:
    finalSize = 1024;
  }

  return finalSize;
};
