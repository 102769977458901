import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { Button as MUIButton } from '@mui/base/';

// COMPONENTS
import Icon from '../Icon/Icon';

// ASSETS
import styles from './Button.module.scss';

export interface ButtonProps {
    children?: ReactNode,
    className?: string,
    color?: 'default' | 'depth' | 'magma' | 'eversource-blue' | 'primary' | 'secondary' | 'tertiary',
    copy: string,
    disabled?: boolean,
    icon?: ReactNode,
    iconRight?: ReactNode,
    iconOnly?: boolean,
    iconPosition?: string | null,
    loading?: boolean,
    name?: string,
    fullWidth?: boolean,
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full',
    type?: 'primary' | 'secondary' | 'submit',
    variant?: 'contained' | 'outlined' | 'ghost',
    onClick?: () => void
};

/**
* Represents a Button.
* @constructor
* @param {string} children
* @param {string} className
* @param {string} copy
* @param {string} color
* @param {boolean} disabled
* @param {node} icon
* @param {node} iconRight
* @param {boolean} iconOnly
* @param {string} iconPosition
* @param {boolean} loading
* @param {string} name
* @param {string} size
* @param {string} type
* @param {function} onClick
* Usage :
* ````js
* <Button className='my-button' copy='My Button' color='stone' icon={<Icon.X />} iconOnly='false' iconPosition='left' size='sm' type='primary' />
* ````
* @augments {Component<Props, State>}
*/
/** */
const Button: React.FC<ButtonProps> = ({
  className,
  copy = 'Button',
  color = 'primary',
  disabled,
  fullWidth = false,
  icon,
  iconRight,
  iconOnly = false,
  iconPosition,
  loading = false,
  name,
  onClick = () => {},
  size = 'sm',
  variant = 'contained',
  type = 'primary',
  ...props
}: ButtonProps) => {
  const cssButton = classnames(
    className,
    styles.button,
    'flex jus--center',
    styles[`color-${color}`],
    {
      [styles['full-width']]: fullWidth,
      [styles.disable]: disabled,
      [styles[`icon--${iconPosition}`]]: iconPosition && !iconOnly,
      [styles['button--loading']]: loading,
      [styles['icon-only']]: iconOnly,
      [styles[size]]: size,
      [styles[type]]: type,
      [styles.contained]: variant === 'contained',
      [styles.outlined]: variant === 'outlined',
      [styles.ghost]: variant === 'ghost'
    }
  );

  return (
    <MUIButton
      onClick={onClick}
      className={cssButton}
      {...props}
      name={name || ''}
      disabled={disabled}
    >
      {loading
        ? <Icon.Loader />
        : (
          <>
            {iconPosition === 'left' && !iconOnly ? icon : null}
            {iconOnly ? icon : <span>{copy}</span>}
            {iconPosition === 'right' && !iconOnly ? icon : null}
            {!iconRight || iconRight}
          </>
        )
      }
    </MUIButton>
  );
};

export default Button;
