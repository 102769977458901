import React, { Dispatch, SetStateAction } from 'react';

import Button from '../Button/Button';
import Drawer from '../Drawer/Drawer';
import Card from '../Card/Card';
import Icon from '../Icon/Icon';
import Tag from '../Tag/Tag';
import Text from '../Text/Text';
import TextInput from '../TextInput/TextInput';

import './roles-sidesheet.scss';

import mockRoles from '../../json/mock_roles.json';
import Divider from '../Divider/Divider';
import Logo from '../Logo/Logo';
import { TagColor } from 'components/Tag/Tag.helpers';

interface Props {
    className?: string,
    isOpen?: boolean,
    setIsOpen?: Dispatch<SetStateAction<boolean>>
};

const RolesSidesheet: React.FC<Props> = ({
  className,
  isOpen = false,
  setIsOpen = () => {}
}: Props) => {
  // Write inital load and then updated state
  const handleListClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.currentTarget;
    target.classList.toggle('close--next-card');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      const target = e.currentTarget;
      target.classList.toggle('close--next-card');
    }
  };

  const renderRoleCards = () => {
    const colorArray = ['sheen', 'pollen', 'wave'];
    const roleCards = mockRoles.roles.map((role, index) => {
      const {
        id,
        name,
        products
      } = role;
      const renderProducts = (productsList: { id: number; name: string; }[]) => {
        const productCards = productsList.map((product: any) => {
          const {
            // admin,
            access,
            available,
            // read,
            id,
            name
            // write
          } = product;

          const getRole = () => available ? ['sheen', 'Admin'] : () => access ? ['bright', 'Admin'] : [];

          const [color, label]:any = getRole();
          const getIcon = (product:string) => {
            // BREAK THIS OUT TO HELPER
            const productIcon:any = {
              platform: 'gridx',
              design: 'design',
              analyze: 'analyze',
              kam: 'kam',
              csr: 'csr',
              calculate: 'calculate'
            };
            return productIcon[product.toLowerCase()];
          };

          return (
            <div
              className='flex bor--b-1'
              key={id}
            >
              <div className='flex jus--center mar--t-32 width--10-p'>
                <Logo
                  name={getIcon(name)}
                  size='xs'
                />
              </div>
              <div className='bor--l-1 width--90-p'>
                <div
                  className='flex pad--l-16 jus--space-between flex--align-center pad--b-16'
                  onClick={(e) => handleListClick(e)}
                  onKeyDown={handleKeyDown}
                  role="button"
                  tabIndex={0}
                >
                  <div className='column'>
                    <Text
                      color='stone'
                      className='body--lg pad--t-16'
                      variant='body1'
                    >
                      {name}
                    </Text>
                    <div>
                      <Tag
                        color={color}
                        copy={label}
                        size='sm'
                      />
                    </div>
                  </div>
                  <div
                    className='cursor--pointer'
                  >
                    <Icon.ChevronDown className='indicator' />
                  </div>
                </div>
                <div className='column card--container'>
                  <Text
                    color='stone'
                    className='bor--b-1 bor--t-1 body--lg pad--b-16 pad--l-16 pad--t-16'
                    variant='body1'
                  >
                    Change Roles
                  </Text>
                  <div className='bor--b-1'>
                    <Tag
                      className='bor--b-1 pad--b-16 pad--l-16 pad--t-16'
                      color='stem'
                      copy='Admin'
                      size='sm'
                    />
                  </div>
                  <div className='bor--b-1'>
                    <Tag
                      className='bor--b-1 pad--b-16 pad--l-16 pad--t-16'
                      color='sky'
                      copy='Editor'
                      size='sm'
                    />
                  </div>
                  <div>
                    <Tag
                      className='pad--b-16 pad--l-16 pad--t-16'
                      color='wave'
                      copy='No access'
                      size='sm'
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        });

        return (
          <div
            className='mar--t-24'
          >
            {productCards}
          </div>
        );
      };

      return (
        <Card
          className={
            `bor--1-mist
            mar--b-8
            shadow--sm`
          }
          key={id}
        >
          <div
            className='flex flex--align-center close--next-card'
            onClick={(e) => handleListClick(e)}
            role="button"
            tabIndex={0}
            onKeyDown={handleKeyDown}
          >
            <Tag
              className='mar--r-16'
              color={colorArray[index] as any}
              copy={name}
            />
            <Icon.ChevronDown className='indicator cursor--pointer' />
          </div>
          <div className='card--container card--full-height'>
            <TextInput
              className='mar--t-28'
              errorMessage=''
              label='Name'
              id='role-name'
              placeholder={name}
              size="md"
            />
            <div className='flex flex--align-center mar--t-24'>
              <span className='mar--r-8 swatch swatch--sm swatch--stem'></span>
              <Text
                className='body--md'
                variant='body1'
              >
                Change color
              </Text>
              <Icon.Edit3 />
            </div>
            <Divider
              className='mar--b-20 mar--t-20'
              color='stone'
            />
            <Text
              className=' title--sm'
              variant='body1'
            >
            Applications access
            </Text>
            <div className='mar--b-28'>
              {renderProducts(products)}
            </div>
            <Text
              className='pad--b-32 title--sm'
              variant='body1'
            >
            Data access
            </Text>
            <Text className='italic'>
            No data restrictions
            </Text>
            <Button
              className='mar--b-32 mar--l-4 mar--t-32'
              color='depth'
              copy='Add restrictions'
              icon={<Icon.Plus />}
              iconPosition='left'
              size='sm'
              type='secondary'
            />
            <Divider />
            <Button
              className='mar--b-32 mar--t-32'
              color='magma'
              copy='Remove role'
              icon={<Icon.Trash />}
              iconPosition='left'
              size='md'
              type='secondary'
            />
          </div>
        </Card>
      );
    });

    return roleCards;
  };

  return (
    <div className={className}>
      <Drawer
        anchor="right"
        open={isOpen}
        size="md"
        onClose={() => setIsOpen(false)}
        footer={
          <Button copy="Save Changes" type="primary" onClick={() => setIsOpen(false)}/>
        }
      >
        <div className='flex jus--space-between pad--r-28 pad--t-28'>
          <div className='flex'>
            <Icon.Shield className='mar--r-12' />
            <Text
              className='title--md text--bold'
              variant='body1'
            >
            Roles
            </Text>
          </div>
          <Icon.X onClick={() => setIsOpen?.(false)} className='cursor--pointer' />
        </div>
        <div
          className='pad--l-28 pad--r-28 pad--t-28'
        >
          {renderRoleCards()}
        </div>
      </Drawer>
    </div>

  );
};

export default RolesSidesheet;
