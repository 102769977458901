import React, { useContext, useState } from 'react';

// COMPONENTS
import CalculationItem from '../CalculationItem/CalculationItem';

// ASSETS
import styles from './Calculations.module.scss';

// HELPERS
import { useGetProjectList } from 'widget/ExploreWidget/api/projects.api';
import { ExploreContext } from 'widget/ExploreWidget/helpers/ExploreContext';
import { Project } from 'widget/ExploreWidget/api/projects.typings';
import { resourcesTemp } from './Calculations.helpers';
import ListShowMore from 'components/ListShowMore/ListShowMore';

const Calculations = () => {
  const { state, dispatch } = useContext(ExploreContext);
  const { isFetched, data } = useGetProjectList({
    contractId: state.contractId,
    mock: state.mock
  });
  const [limitShow, setLimitShow] = useState(5);

  // EVENTS
  const handleShowMore = () => {
    setLimitShow(limitShow + 10);
  };

  // RENDERS
  let filteredData: any = [];
  if (data) {
    filteredData = data.slice(0, limitShow);
  }

  return (
    <div className={styles.container}>
      {filteredData?.map((project: Project) => {
        return (
          <CalculationItem
            key={project.id}
            id={project.id}
            title={project.name}
            rate={project.rateCode}
            modifiers={project.resources}
            costSaving={project.savingsPerYear}
            costPerYear={project.totalCostPerYear || 0}
          />
        );
      })}
      <ListShowMore
        total={data?.length || 0}
        current={limitShow}
        onShowMore={handleShowMore}
        buttonProps={{
          color: 'secondary',
          variant: 'outlined',
          fullWidth: false
        }}
      />
    </div>
  );
};

export default Calculations;
