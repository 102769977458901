import React from 'react';
import Image from '../components/Image/Image';
import Button from '../components/Button/Button';

import './temp--analyze-page.scss';

const TempAnalyzePage: React.FC = () => {
  return (
    <div className='analyze--page'>
      <Image
        className='mar--l-80 mar--t-96'
        alt='Analyze'
        src='../../images/products/analyze.jpg'
      />
      <Button
        className='button--temp-analyze'
        copy='Lookup'
        color='magma'
        size='md'
      />
      <Button
        className='button--temp-analyze apply--button'
        copy='Apply'
        color='magma'
        size='sm'
      />
    </div>
  );
};

export default TempAnalyzePage;
