
export const costSampleData1 = [
  {
    name: 'Energy delivered',
    color: '#00B88C',
    data:
        [
          0.05,
          0.06,
          0.07,
          0.08,
          0.09,
          0.12,
          0.10,
          0.09,
          0.08,
          0.07,
          0.06,
          0.05
        ]
  },
  {
    name: 'Actual demand',
    color: '#51E7BA',
    data:
          [
            0.05,
            0.06,
            0.07,
            0.08,
            0.09,
            0.12,
            0.10,
            0.09,
            0.08,
            0.07,
            0.06,
            0.05
          ]
  }, {
    name: 'Flat fee',
    color: '#90F5D1',
    data:
        [
          0.02,
          0.02,
          0.02,
          0.02,
          0.02,
          0.02,
          0.02,
          0.02,
          0.02,
          0.02,
          0.02,
          0.02
        ]
  }];

export const costSampleData2 = [
  {
    name: 'Energy delivered',
    color: '#00B88C',
    data:
        [
          0.10,
          0.11,
          0.12,
          0.14,
          0.16,
          0.20,
          0.18,
          0.16,
          0.14,
          0.12,
          0.11,
          0.10
        ]
  },
  {
    name: 'Actual demand',
    color: '#51E7BA',
    data:
          [
            0.05,
            0.06,
            0.07,
            0.08,
            0.09,
            0.12,
            0.10,
            0.09,
            0.08,
            0.07,
            0.06,
            0.05
          ]
  }, {
    name: 'Flat fee',
    color: '#90F5D1',
    data:
        [
          0.02,
          0.02,
          0.02,
          0.02,
          0.02,
          0.02,
          0.02,
          0.02,
          0.02,
          0.02,
          0.02,
          0.02
        ]
  }];

export const costSampleData3 = [
  {
    name: 'Energy delivered',
    color: '#00B88C',
    data:
          [
            0.10,
            0.11,
            0.12,
            0.14,
            0.16,
            0.20,
            0.18,
            0.16,
            0.14,
            0.12,
            0.11,
            0.10
          ]
  },
  {
    name: 'Actual demand',
    color: '#51E7BA',
    data:
            [
              0.10,
              0.11,
              0.12,
              0.14,
              0.16,
              0.20,
              0.18,
              0.16,
              0.14,
              0.12,
              0.11,
              0.10
            ]
  }, {
    name: 'Flat fee',
    color: '#90F5D1',
    data:
          [
            0.02,
            0.02,
            0.02,
            0.02,
            0.02,
            0.02,
            0.02,
            0.02,
            0.02,
            0.02,
            0.02,
            0.02
          ]
  }];
