import React, { ReactNode } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import DatePicker from 'react-date-picker';
import classnames from 'classnames';

// COMPONENTS
import Icon from '../Icon/Icon';
import InputLabel from 'components/InputLabel/InputLabel';

// ASSETS
import './date-picker.scss';

interface Props {
  autoFocus?: boolean,
  className?: string,
  calendarIcon?: ReactNode,
  dayPlaceholder?: string,
  disabled?: boolean,
  disableCalendar?: boolean,
  format?: string,
  isOpen?: boolean,
  isRange?: boolean,
  maxDate?: Date,
  maxDetail?: 'month' | 'year' | 'decade' | 'century',
  minDate?: Date,
  onChange?: (value: any) => void,
  onCalendarClose?: () => void,
  onCalendarOpen?: () => void,
  onFocus?: () => void,
  label?: string,
  openCalendarOnFocus?: boolean,
  viewOnlyDays?: boolean,
  clearIcon?: string | React.ComponentType | React.ReactElement | null,
  value?: any,
  size?: 'sm' | 'md' | 'lg' | 'xl'
};

/**
* Represents a datepicker
* @constructor
* @param {string} className
* @param {string}  className,
* @param {ReactNode}  calendarIcon,
* @param {boolean}  disabled,
* @param {boolean}  disableCalendar,
* @param {string}  format,
* @param {boolean}  isOpen,
* @param {Date}  maxDate,
* @param {string}  maxDetail,
* @param {Date}  minDate,
* @param {function} onChange,
* @param {function} onCalendarClose,
* @param {function} onCalendarOpen,
* @param {function} onFocus,
* @param {boolean} openCalendarOnFocus,
* @param {object} value
* Usage :
* ````js
* <CustomDatePicker className="my-datepicker" />
* ````
* @augments {Component<Props, State>}
*/
/** */

const CustomDatePicker: React.FC<Props> = ({
  className,
  disabled,
  calendarIcon,
  disableCalendar,
  format,
  isOpen,
  isRange = true,
  maxDate = undefined,
  maxDetail,
  label,
  viewOnlyDays = false,
  size = 'md',
  minDate = undefined,
  onChange,
  onCalendarClose,
  onCalendarOpen,
  onFocus,
  openCalendarOnFocus,
  value,
  // clearIcon = <Icon.X size={18}/>
  clearIcon = null
}: Props) => {
  // RENDERS
  const getIconSize = () => {
    switch (size) {
    case 'sm':
      return 16;
    case 'md':
      return 20;
    case 'lg':
      return 24;
    case 'xl':
      return 28;
    default:
      return 20;
    }
  };

  // STYLES
  const cssContainer = classnames('custom--date-picker', className, `custom--date-picker--${size}`, {
    'view-only-days': viewOnlyDays
  });

  return (
    <div className={cssContainer}>
      {label && <InputLabel>{label}</InputLabel>}
      {
        isRange && (
          <DateRangePicker
            calendarIcon={<>{calendarIcon}</> || <Icon.Calendar size={getIconSize()}/>}
            disabled={disabled}
            disableCalendar={disableCalendar}
            format={format}
            isOpen={isOpen}
            maxDate={maxDate}
            maxDetail={maxDetail}
            minDate={minDate}
            onChange={onChange}
            onCalendarClose={onCalendarClose}
            onCalendarOpen={onCalendarOpen}
            onFocus={onFocus}
            openCalendarOnFocus={openCalendarOnFocus}
            value={value}
            clearIcon={clearIcon}
            clearAriaLabel="Clear"
            calendarAriaLabel="Calendar"
            dayAriaLabel="Day"
            yearAriaLabel="Year"
            monthAriaLabel="Month"
            nativeInputAriaLabel="Input"
          />
        )
      }
      {
        !isRange && (
          <DatePicker
            calendarIcon={<>{calendarIcon}</> || <Icon.Calendar size={getIconSize()}/>}
            disabled={disabled}
            disableCalendar={disableCalendar}
            format={format}
            isOpen={isOpen}
            maxDate={maxDate}
            maxDetail={maxDetail}
            minDate={minDate}
            onChange={onChange}
            onCalendarClose={onCalendarClose}
            onCalendarOpen={onCalendarOpen}
            onFocus={onFocus}
            openCalendarOnFocus={openCalendarOnFocus}
            value={value}
            clearIcon={clearIcon}
            clearAriaLabel="Clear"
            calendarAriaLabel="Calendar"
            dayAriaLabel="Day"
            yearAriaLabel="Year"
            monthAriaLabel="Month"
            nativeInputAriaLabel="Input"
          />
        )
      }
    </div>
  );
};

export default CustomDatePicker;
