/* eslint-disable @typescript-eslint/no-unused-vars  */
/* eslint-disable multiline-ternary */

import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExploreContext } from 'widget/ExploreWidget/helpers/ExploreContext';
import _ from 'lodash';

// COMPONENTS
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import Box from 'components/Box/Box';
import Tabs from 'components/Tabs/Tabs';
import Icon from 'components/Icon/Icon';
import LoadingLongCostEstimates from '../ProjectDetails/components/LoadingLongCostEstimates/LoadingLongCostEstimates';

// ASSETS
import styles from './RateComparison.module.scss';

import { CompareRateDetails } from './types';
import RateComparisonTable from './RateCompareTable';
import useProjectData from '../../hooks/useProjectData';
import { useCompareCalculate } from 'widget/ExploreWidget/api/projects.api';

const RateComparison = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const projectID = new URLSearchParams(location.search).get('projectID') || '';
  const [tableData, setTableData] = useState<CompareRateDetails>();

  // const isMobile = useMediaQuery(breakpoints.mobileMedium);
  const { state } = useContext(ExploreContext);

  const { projectResources = [] } = state.cache;
  const { appliedResource } = state;

  const { data: project } = appliedResource?.length ? useCompareCalculate() : useProjectData();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (project) {
      const { compareRatesDetails } = project.compareInfo ? project.compareInfo : project;
      setTableData(compareRatesDetails);
    }
  }, [project]);

  // EVENTS
  const handleTabChange = (event: Record<string, any>, value: any) => {
    setTabIndex(value);
  };

  const handleBackToProject = () => {
    navigate(`/explore?project&projectID=${projectID}`);
  };

  // RENDERS
  const tabsWithIcons = [
    {
      label: 'Cost',
      icon: <Icon.CreditCard />,
      iconPosition: 'start'
    }
    // {
    //   label: 'Usage',
    //   icon: <Icon.Zap/>,
    //   iconPosition: 'start'
    // }
  ];

  return (
    <div>
      <div className={styles.header}>
        <Breadcrumb type="backlink" items={[{ title: 'Back to project' }]} onBacklinkClick={handleBackToProject} />
      </div>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>Rate comparison</h1>
      </div>
      <Box mb={6}>
        {tableData ? (
          <div className={styles['rates-table__container']}>
            <div className={styles['rates-table__header']}>
              <Tabs tabs={tabsWithIcons} onChange={handleTabChange} value={tabIndex} color="depth" />
            </div>
            <div>
              <div className={styles['rates-table']}>
                <RateComparisonTable data={tableData} resourseList={projectResources} />
              </div>
            </div>
          </div>
        ) : (
          <LoadingLongCostEstimates />
        )}
      </Box>
    </div>
  );
};

export default RateComparison;
