import React, { useContext } from 'react';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

// ASSETS
import styles from './CalculationItem.module.scss';
import { Icon } from 'components';
import AttributeTag from 'widget/ExploreWidget/components/AttributeTag/AttributeTag';

// HELPERS
import { Resource } from 'widget/ExploreWidget/api/projects.typings';
import { getModifierAttribute } from './CalculationItem.helpers';
import { ActionTypes, ExploreContext } from 'widget/ExploreWidget/helpers/ExploreContext';

interface CalculationItemProps {
  id: number;
  title: string;
  tags?: Array<string>;
  modifiers?: Resource[];
  costPerYear?: number;
  costSaving?: number;
  rate: string;
}

const CalculationItem:React.FC<CalculationItemProps> = ({ id, title, tags, costPerYear, costSaving, rate, modifiers }) => {
  const { state, dispatch } = useContext(ExploreContext);
  const navigate = useNavigate();

  // EVENTS
  const handleEditProject = () => {
    dispatch({ type: ActionTypes.SET_PROJECT_MODE, payload: 'EDIT' });
    navigate(`/explore?project&projectID=${id}`);
  };

  return (
    <div className={styles.container} onClick={handleEditProject} role="presentation">
      <div className={styles.title}>
        <p>{title}</p>
        <Icon.ChevronRight/>
      </div>
      <div className={styles.tags}>
        <AttributeTag
          type="rate"
          label={rate}
        />
        {
          modifiers && modifiers.length > 0 && modifiers.map((modifier, index) => {
            const modifierAttribute = getModifierAttribute(modifier);
            return (
              <AttributeTag
                key={modifier.id}
                type="modifier"
                modifier={modifierAttribute.modifier}
                label={modifierAttribute.label}
              />
            );
          })
        }
      </div>
      <div className={styles.footer}>
        <div className={styles.costItem}>
          <p>12-month total electric savings</p>
          <p className={classnames(styles.costValue, styles.costSaving)}>${costSaving}</p>
        </div>
        <div className={styles.costItem}>
          <p>12-month total electric cost</p>
          <p className={styles.costValue}>${costPerYear}</p>
        </div>
      </div>
    </div>
  );
};

export default CalculationItem;
