import { useQuery } from '@tanstack/react-query';

// DATA
import systemsData from 'json/data-system.json';
import invalidSystemData from 'json/data-system-invalid.json';

// TYPINGS
import { Project } from './systems.typings';

export const useSystems = () => {
  return useQuery({
    queryKey: ['systems'],
    queryFn: () => {
      return systemsData as Project[];
    }
  });
};

export const useSingleSystem = (projectId: string | number) => {
  return useQuery({
    queryKey: ['single-system'],
    queryFn: () => {
      return systemsData.filter((project) => project.projectID === Number(projectId))[0];
    }
  });
};

export const useTemporaryInvalidSystem = () => {
  return useQuery({
    queryKey: ['invalid-system'],
    queryFn: () => {
      return invalidSystemData;
    }
  });
};
