import React, { useState } from 'react';

import Button from '../components/Button/Button';
import Checkbox from '../components/CustomCheckbox/CustomCheckbox';
import { Sort } from '../icons';
import Icon from '../components/Icon/Icon';
import Text from '../components/Text/Text';
import TextInput from '../components/TextInput/TextInput';
import ListShowMore from '../components/ListShowMore/ListShowMore';

import { RenderUsersList } from '../helpers/UsersList';
import { RenderFiltersList } from '../helpers/UserFiltersList';
import RolesSidesheet from '../components/RolesSidesheet/RolesSidesheet';
import ManageUsers from '../components/ManageUsers/ManageUsers';

// API
import { useUsers } from '../api/hooks';

// import mockUsersList from '../json/mock_users_list.json';
import mockUsersList from '../json/seminole_mock_users_list.json';

/**
* Represents a CitadelUsers.
* @constructor
* Usage :
* ````js
* <CitadelUsers  />
* ````
* @augments {CitadelUsers<Props, State>}
*/
/** */

const CitadelUsers: React.FC = () => {
  const [limitShow, setLimitShow] = useState(10);
  const [expandFilter, setExpandFilter] = useState(false);
  const [permissionIsOpen, setPermissionIsOpen] = useState(false);
  const [userMgntIsOpen, setUserMgntIsOpen] = useState(false);

  const [order, setOrder] = useState('descending');
  const [search, setSearch] = useState('');
  const [filtersParams, setFilterParams] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const {
    data: users,
    isLoading,
    isError,
    error
  } = useUsers(
    mockUsersList,
    appliedFilters,
    search,
    order,
    limitShow
  );

  // for demo purpose
  const [newUser, setNewUsers] = useState([]);

  const handleShowMore = () => {
    setLimitShow(limitShow + 10);
  };
  const handleApplyFilter = () => {
    setAppliedFilters([...filtersParams]);
  };
  const handleFilters = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      checked,
      value
    } = e.target;
    if (checked) {
      // add param
      // @ts-ignore
      setFilterParams(filtersParams => [...filtersParams, value.toLowerCase()]);
    } else {
      // remove param
      setFilterParams(filtersParams.filter(e => e !== value.toLowerCase()));
    }
  };

  const handleSearcUsers = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleSort = () => {
    setOrder(order === 'descending' ? 'ascending' : 'descending');
  };

  const renderFiltersControls = () => {
    return (
      <div
        className='mar--t-28 mar--l-28 mar--r-28'
      >
        <div
          className='bor--b-1 mar--b-20 flex jus--space-between'
        >
          <Text
            className='body--lg pad--b-20 text--bold'
            variant='body1'
          >
            Filters
          </Text>
          <div
            onClick={() => setExpandFilter(false)}
            onKeyDown={() => setExpandFilter(false)}
            role="button"
            tabIndex={0}
          >
            <Icon.X />
          </div>
        </div>
        <div
          className='bor--b-1 mar--b-20'
        >
          <Text
            className='body--lg pad--b-20 text--bold'
            variant='body1'
          >
            Profiles
          </Text>
          <div className='flex'>
            <Checkbox
              className='mar--r-16'
              color='magma'
              id='admin-checkbox'
              onChange={(e) => handleFilters(e)}
              value='admin'
            />
            <Text
              className='body--lg pad--b-20 text--bold'
              variant='body1'
            >
              Admin
            </Text>
          </div>
          <div className='flex'>
            <Checkbox
              className='mar--r-16'
              color='magma'
              id='admin-checkbox'
              onChange={(e) => handleFilters(e)}
              value='tariff-modeler'
            />
            <Text
              className='body--lg pad--b-20 text--bold'
              variant='body1'
            >
          Tariff Modeler
            </Text>
          </div>
        </div>
        <div
          className='bor--b-1 mar--b-20'
        >
          <Text
            className='body--lg pad--b-20 text--bold'
            variant='body1'
          >
            Applications Access
          </Text>
          <div
            className='mar--b-20'
          >
            {RenderFiltersList((e) => handleFilters(e))}
          </div>
        </div>
        <div
          className='mar--b-20'
        >
          <Button
            className='mar--r-8'
            color='depth'
            copy='Cancel'
            onClick={() => setExpandFilter(false)}
            size='sm'
            type='secondary'
          />
          <Button
            className=''
            color='magma'
            copy='Apply'
            onClick={() => handleApplyFilter()}
            size='sm'
            type='primary'
          />

        </div>
      </div>
    );
  };

  return (
    <div className='users--page pad--t-128 pad--l-128 pad--r-36'>
      <div
        className='flex jus--space-between mar--b-28'
      >
        <Button
          className=''
          color='magma'
          copy='New User'
          icon={<Icon.Plus />}
          iconPosition='left'
          size='md'
          type='primary'
          onClick={() => setUserMgntIsOpen(true)}
        />
        <div>
          <Button
            color='depth'
            copy='Permissions'
            icon={<Icon.Key />}
            iconPosition='left'
            size='md'
            type='secondary'
            onClick={() => setPermissionIsOpen(true)}
          />
        </div>
      </div>
      <Text
        className='title--md pad--b-20'
        variant='h2'
      >
        Users
      </Text>
      <div>
        <div className='flex'>
          <div className='bor--r-1 pad--r-16'>
            <Button
              color='magma'
              copy='Filter'
              icon={<Icon.Filter />}
              iconPosition='left'
              size='md'
              type='secondary'
              onClick={() => setExpandFilter(true)}
            />
          </div>
          <TextInput
            className='no-label mar--l-16 mar--r-16 width--40-p'
            endAdornment={<Icon.Search />}
            errorMessage='No results'
            id='user-search-input'
            placeholder='Search users'
            size="md"
            onChange={(e) => handleSearcUsers(e)}
          />
          <div
            className='bor--l-1 pad--l-16'
          >
            <Button
              color='magma'
              copy='Sort'
              icon={<Sort />}
              iconPosition='left'
              onClick={() => handleSort()}
              size='md'
              type='secondary'
            />
          </div>
        </div>

        <div
          className='flex mar--t-24'
        >
          <div
            className={
              `${expandFilter ? 'width--20-p' : 'width--0-p'}
            transition--width
            overflow--x-hidden
            white-space--no-wrap`
            }
          >
            {renderFiltersControls()}
          </div>
          <div className={
            `transition--width
            ${expandFilter ? 'width--80-p' : 'width--100-p'}`
          }>
            {!isLoading && users
              ? (
                RenderUsersList([...newUser, ...users])
              )
              : ''}
            {isError && error
              ? (
                <div>
                  Unable to load users.
                </div>
              )
              : ''}
            {users
              ? (
                <>
                  <div className='flex jus--center mar--t-32 mar--b-60'>
                    <div className='width--30-p'>
                      <ListShowMore
                        total={mockUsersList.length + newUser.length}
                        current={users.length + newUser.length}
                        onShowMore={handleShowMore}
                      />
                    </div>
                  </div>
                </>
              )
              : ''}
          </div>
        </div>
      </div>
      <RolesSidesheet isOpen={permissionIsOpen} setIsOpen={setPermissionIsOpen} />
      <ManageUsers setNewUsers={setNewUsers} isOpen={userMgntIsOpen} setIsOpen={setUserMgntIsOpen} />
    </div>
  );
};

export default CitadelUsers;
