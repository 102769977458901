import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import { FilterSearchBar } from '../../../../blocks/components';

// ACTIONS
import { setSearchString, toogleFilter } from '../../../../store/modules/analyze/analyze.slice';

// SELECTORS
import { getFiltersSearch, getRecentSearches } from 'store/modules/analyze/analyze.selector';

const FilterSearchAnalyze = () => {
  const dispatch = useDispatch();
  const recentSearches = useSelector(getRecentSearches);
  const { searchString } = useSelector(getFiltersSearch);

  // EVENTS
  const handleOpenFilter = () => {
    dispatch(toogleFilter());
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchString(event.target.value));
  };

  return (
    <FilterSearchBar
      onSearch={handleSearch}
      onOpenFilter={handleOpenFilter}
      searchPlaceholder="Search accounts"
      recentSearches={recentSearches}
      searchString={searchString}
    />
  );
};

export default FilterSearchAnalyze;
