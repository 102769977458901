import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';
import { useNavigate, useParams } from 'react-router-dom';

// COMPONENTS
import { Dropdown, Icon, Tag } from 'components';
import ListShowMore from 'components/ListShowMore/ListShowMore';
import { InfoItem, ListItem, ListToolbar } from 'blocks/components';

// ASSETS
import styles from './ListMeterAccounts.module.scss';

// HELPERS
import { IMeterAccount } from 'api/accounts/accounts.typings';
import { useTheme } from 'hooks/Theme/Theme.context';

interface ListMeterAccountsProps {
  accounts: Array<IMeterAccount>
}

const ListMeterAccounts:React.FC<ListMeterAccountsProps> = ({ accounts }) => {
  const { slug } = useTheme();
  const [selectedItems, setSelectedItems] = useState<{ [key: string]: boolean }>({});
  const [limitShow, setLimitShow] = useState(10);
  const { accountId } = useParams();
  const navigate = useNavigate();

  // EVENTS
  const handleShowMore = () => {
    setLimitShow(limitShow + 10);
  };

  const handleAccountClick = (meterAccountId: string | number) => {
    navigate(`/${slug}/accounts/${accountId}/meteraccount/${meterAccountId}`);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const newSelectedItems: { [key: string]: boolean } = {};

    accounts.forEach((account) => {
      newSelectedItems[account.id] = isChecked;
    });

    setSelectedItems(newSelectedItems);
  };

  const handleSelectItem = (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [id]: isChecked
    }));
  };

  // RENDERS
  const filteredAccounts = accounts.slice(0, limitShow);

  // RENDERS
  const actionList = [
    {
      startIcon: <Icon.Upload size={18}/>,
      content: 'Update',
      onClick: () => {}
    },
    {
      startIcon: <Icon.Link size={18} />,
      content: 'Map',
      onClick: () => {}
    },
    {
      startIcon: <Icon.Users size={18} />,
      content: 'Assign',
      onClick: () => {}
    }
  ];

  return (
    <>
      <ListToolbar
        selectedCount={Object.values(selectedItems).filter(Boolean).length}
        onSelect={handleSelectAll}
        rightSlot={<Dropdown label="Actions" options={actionList} color='depth' />}
      />
      <AnimatePresence>
        <motion.ul
          className={styles['list-container']}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {
            filteredAccounts.map((account) => {
              return (
                <motion.li
                  key={account.id}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className={styles['list-item']}>
                    <ListItem
                      selectable
                      isSelected={selectedItems[account.id]}
                      onSelect={handleSelectItem(account.id)}
                      onBodyClick={() => handleAccountClick(account.id)}
                      columns={[
                        { body: <InfoItem title="Meter Account" description={account.meterAccount} />, col: 2 },
                        { body: <InfoItem title="SA ID" description={account.saId} />, col: 2 },
                        { body: <InfoItem title="Rate" description={account.rate} />, col: 1 },
                        { body: <InfoItem title="Bill Cycle" description={account.billCycle} />, col: 1.5 },
                        { body: <InfoItem title="Start Date" description={account.startDate} />, col: 2 },
                        { body: <InfoItem title="End Date" description={account.endDate} />, col: 2 },
                        {
                          body: (
                            <div className={styles['customer-type']}>
                              <Tag
                                copy={account.status}
                                size="sm"
                                color="stem"
                              />
                            </div>
                          ),
                          col: 1.5
                        }
                      ]}
                    />
                  </div>
                </motion.li>
              );
            })}
        </motion.ul>
      </AnimatePresence>
      <div className={styles['list-footer']}>
        <ListShowMore total={40} current={limitShow} onShowMore={handleShowMore} />
      </div>
    </>
  );
};

export default ListMeterAccounts;
