import React, { useMemo, useState } from 'react';
import Text from '../Text/Text';
import Icon from '../Icon/Icon';
import './accordion.scss';

interface Props {
  color?: 'sheen' | 'white' | undefined;
  className?: string;
  icon?: React.ReactNode;
  shadowColor?: 'mangrove' | 'lumen' | 'wind' | 'temper' | string | null;
  titleLeft?: React.ReactNode;
  titleRight?: React.ReactNode;
  expanded: boolean;
  onChange: (expanded: boolean) => void;
  inputId?: string | undefined;
  children: React.ReactNode;
}

export const getInputId = () => {
  return `accordion-${(Math.random() + 1).toString(36).substring(7)}`;
};

/**
 * Represents an Accordion.
 * @constructor
 * @param {string} color
 * @param {string} className
 * @param {ReactNode|undefined} icon
 * @param {string|undefined} shadowColor
 * @param {ReactNode|undefined} titleLeft
 * @param {ReactNode} titleRight
 * @param {boolean} expanded
 * @param {(expanded: boolean) => {}} onChange
 * @param {string|undefined} inputId
 * Usage :
 * ````js
 * <Accordion titleLeft='AccordionHeader' onChange={setExpanded}>
 *   Accordion Content
 * </Accordion>
 * ````
 * @augments {Component<Props, State>}
 */
/** */

const Accordion: React.FC<Props> = ({
  className,
  color = 'sheen',
  icon,
  shadowColor,
  titleLeft,
  titleRight,
  expanded,
  onChange,
  inputId,
  children
}) => {
  const [checked, setChecked] = useState(expanded);
  inputId = inputId !== undefined ? inputId : useMemo(() => getInputId(), []);

  return (
    <div
      className={`
      accordion-box
      shadow--sm
      pad--b-16 pad--l-16 pad--t-16 pad--r-16
      ${className || ''}
      ${color ? `accordion--bg-${color}` : ''}
      ${shadowColor ? `accordion--${shadowColor}` : ''}
      `}
    >
      <input type='checkbox' defaultChecked={checked} id={inputId}
        onChange={(event) => {
          onChange(event.target.checked);
          setChecked(event.target.checked);
        }}
        className='accordion-checkbox'
      />
      <div className='flex flex--align-center'>
        <div>
          <label className='accordion-summary' htmlFor={inputId}>
            {!icon || <span className='mar--r-16'>{icon}</span>}
            <div className='accordion-title-left'>
              <Text
                className='title--sm'
                variant='body2'
              >
                {titleLeft}
              </Text>
            </div>
            {checked
              ? <Icon.ChevronDown />
              : <Icon.ChevronRight />}
          </label>
        </div>

        <div className='accordion-title-right'>
          {titleRight}
        </div>
      </div>
      {checked
        ? <div className='accordion-details mar--t-16'>
          {children}
        </div>
        : <></>}
    </div>
  );
};

export default Accordion;
