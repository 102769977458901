import React from 'react';
import classnames from 'classnames';

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(
  // eslint-disable-next-line react/prop-types
  ({ className, ...props }, ref) => (
    <thead ref={ref} className={classnames('[&_tr]:border-b', className)} {...props} />
  ));

TableHeader.displayName = 'TableHeader';

export default TableHeader;
