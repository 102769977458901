import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

// COMPONENTS
import Icon from '../Icon/Icon';

// ASSETS
import styles from './Breadcrumb.module.scss';

type BreadcrumbItem = {
  title: React.ReactNode | string;
  href?: string;
  bold?: boolean;
  arrow?: boolean;
}

interface BreadcrumbProps {
  type?: 'default' | 'backlink';
  items: Array<BreadcrumbItem>;
  withRouter?: boolean;
  onBacklinkClick?: () => void;
}

const Breadcrumb:React.FC<BreadcrumbProps> = ({ items, withRouter, type = 'default', onBacklinkClick = null }) => {
  // EVENTS
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      if (onBacklinkClick) {
        onBacklinkClick();
      }
    }
  };

  const handleBackLinkClick = () => {
    if (onBacklinkClick) {
      onBacklinkClick();
    }
  };

  // RENDERS
  const renderBackLink = () => {
    if (onBacklinkClick) {
      return (
        <div className={styles['backlink--custom-event']}>
          <Icon.ArrowLeft/>
          <span>{items[0].title}</span>
        </div>
      );
    }

    if (withRouter && items[0].href) {
      return (
        <Link to={items[0].href}>
          <Icon.ArrowLeft/>
          <span>{items[0].title}</span>
        </Link>
      );
    } else if (items[0].href) {
      return (
        <a href={items[0].href}>
          <Icon.ArrowLeft/>
          <span>{items[0].title}</span>
        </a>
      );
    } else {
      return items[0].title;
    }
  };

  return (
    <nav className={styles.breadcrumb}>
      {
        type === 'backlink' && (
          <div
            className={styles.backlink}
            onClick={handleBackLinkClick}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
          >
            {renderBackLink()}
          </div>
        )
      }
      {
        type === 'default' && (
          <ol>
            {
              items.map((item, index) => {
                const cssBreadcrumbItem = classnames(styles['breadcrumb-item'], {
                  [styles['breadcrumb-bold']]: item.bold
                });

                return (
                  <React.Fragment key={item.title?.toString()}>
                    <li className={cssBreadcrumbItem}>
                      {item.arrow && (<Icon.ArrowLeft size={24}/>)}
                      <span>
                        {
                          withRouter && item.href
                            ? (
                              <Link to={item.href}>{item.title}</Link>
                            )
                            : (
                              item.href ? <a href={item.href}>{item.title}</a> : item.title
                            )
                        }
                      </span>
                    </li>
                    {index < items.length - 1 && <li className={styles.separator} aria-hidden="true"><Icon.ChevronRight size={16}/></li>}
                  </React.Fragment>
                );
              })
            }
          </ol>
        )
      }
    </nav>
  );
};

export default Breadcrumb;
