import React from 'react';
import { useDispatch } from 'react-redux';

// COMPONENTS
import { FilterControl } from '../../../../../../blocks/components';
import Checkbox from '../../../../../../components/CustomCheckbox/CustomCheckbox';
import Tag from '../../../../../../components/Tag/Tag';

// ASSETS
import styles from './FilterAccountType.module.scss';
import { setFilter, removeFilter } from '../../../../../../store/modules/analyze/analyze.slice';
import { useTheme } from '../../../../../../hooks/Theme/Theme.context';
import { Color, ColorCode } from 'hooks/Theme/Theme.model';

// TYPINGS
import { FilterItem } from 'store/helpers/store.typings';
import { TagColor } from 'components/Tag/Tag.helpers';

interface FilterAccountTypeProps {
  selectedFilters: FilterItem<any>[];
}

const FilterAccountType:React.FC<FilterAccountTypeProps> = ({ selectedFilters }) => {
  const { getThemeColor, hasThemePalette } = useTheme();
  const dispatch = useDispatch();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = event.target;
    if (checked) {
      dispatch(setFilter({ key: 'accountType', value: id }));
    } else {
      dispatch(removeFilter({ key: 'accountType', value: id }));
    }
  };

  // RENDERS
  const options = [
    {
      id: 'residential',
      copy: 'Residential',
      color: 'altitude',
      backgroundColor: getThemeColor(Color.Primary, ColorCode.C200, '')
    },
    {
      id: 'comercial',
      copy: 'Comercial',
      color: 'clean',
      backgroundColor: getThemeColor(Color.Tertiary, ColorCode.C200, '')
    },
    {
      id: 'industrial',
      copy: 'Industrial',
      color: 'wind',
      backgroundColor: getThemeColor(Color.Secondary, ColorCode.C200, ''),
      textColor: hasThemePalette ? '#fff' : ''
    },
    {
      id: 'agricultural',
      copy: 'Agricultural',
      color: 'cloud',
      backgroundColor: getThemeColor(Color.Quaternary, ColorCode.C200, ''),
      textColor: hasThemePalette ? '#fff' : ''
    }
  ];

  return (
    <FilterControl title="Account type" expanded>
      {options.map((option) => (
        <div key={option.id} className={styles['control-item']}>
          <div>
            <Checkbox
              id={option.id}
              onChange={handleCheckboxChange}
              checked={selectedFilters.filter((item) => item.key === 'accountType' && item.value.includes(option.id)).length > 0}
            />
          </div>
          <div><Tag copy={option.copy} color={option.color as any} backgroundColor={option.backgroundColor} textColor={option.textColor} /></div>
        </div>
      ))}
    </FilterControl>
  );
};

export default FilterAccountType;
