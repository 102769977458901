import React, { ReactNode } from 'react';

import Text from '../Text/Text';

import './tile.scss';

interface Props {
    background?: string,
    bottomControl?: ReactNode,
    className?: string,
    icon?: string,
    onClick?: (event: React.MouseEvent) => void,
    owner?: string,
    size?: 'xs' | 'sm' | 'md' | 'lg',
    ribbon?: boolean,
    subTitle?: string,
    title?: string,
    topControl?: ReactNode,
    type?: string,
};

/**
* Represents a Tile.
* @constructor
* @param {string} background
* @param {node} bottomControl
* @param {string} className
* @param {string} icon
* @param {boolean} ribbon
* @param {function} onClick
* @param {string} owner
* @param {string} size
* @param {string} subTitle
* @param {string} title
* @param {node} topControl
* @param {string} type
* Usage :
* ````js
* <Tile className='my-tile' />
* ````
* @augments {Component<Props, State>}
*/
/** */
const Tile: React.FC<Props> = ({
  background,
  bottomControl,
  className,
  icon,
  onClick,
  ribbon,
  owner,
  size,
  subTitle,
  title,
  topControl
}: Props) => {
  let onClickFn = (event: any) => {}; // eslint-disable-line 
  if (onClick) {
    onClickFn = onClick;
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClickFn(event);
    }
  };

  return (
    <div
      className={
        `tile
        ${className || ''}
        ${size ? `tile--${size}` : 'tile--sm'}`
      }
      onClick={onClickFn}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      aria-label="Tile"
    >
      <div className={
        `tile--top
        ${background ? `tile--bg-${background}` : ''}
        ${ribbon ? 'tile--ribbon' : ''}
        ${icon ? `tile--${icon}` : ''}`
      }>
        {topControl
          ? (
            <div
              className='controls--top-right'
            >
              {topControl}
            </div>
          )
          : ''}
        {bottomControl
          ? (
            <div
              className='controls--bottom-right'
            >
              {bottomControl}
            </div>
          )
          : ''}
      </div>
      {title
        ? (
          <Text
            className='body--md text--bold'
            variant='body1'
          >
            {title}
          </Text>
        )
        : ''}
      {subTitle
        ? (
          <Text
            className='body--sm text--bold'
            variant='body1'
          >
            {subTitle}
          </Text>
        )
        : ''}
      {owner
        ? (
          <Text
            className='body--sm'
            color='ocean'
            variant='body1'
          >
            {owner}
          </Text>
        )
        : ''}
    </div>
  );
};

export default Tile;
