import React, { useContext } from 'react';

// COMPONENTS
import TabNav from 'components/TabNav/TabNav';
import Button from 'components/Button/Button';
import ConfigurationPage from '../ConfigurationPage/ConfigurationPage';
import ResultsPage from '../ResultsPage/ResultsPage';
import ImplementationPage from '../ImplementationPage/ImplementationPage';
import DrawerSingleModifier from 'widget/ExploreWidget/components/DrawerSingleModifier/DrawerSingleModifier';

// ASSETS
import styles from './CalculationSteps.module.scss';

// HELPERS
import useMediaQuery, { breakpoints } from 'hooks/useMediaQuery';
import { ActionTypes, ExploreContext } from 'widget/ExploreWidget/helpers/ExploreContext';
import { useCostSavingDetails } from 'widget/ExploreWidget/api/projects.api';
import useProjectData from 'widget/ExploreWidget/hooks/useProjectData';
import Skeleton from 'components/Skeleton/Skeleton';
import DrawerSaveCalculation from 'widget/ExploreWidget/components/DrawerSaveCalculation/DrawerSaveCalculation';

const CalculationSteps = () => {
  const { state, dispatch } = useContext(ExploreContext);
  const isMobile = useMediaQuery(breakpoints.mobileMedium);

  // QUERIES
  const {
    isLoading: isLoadingProject,
    data: projectData
  } = useProjectData();
  const { mutate: fetchConstSavingDetails, isLoading } = useCostSavingDetails({ contractId: state.contractId });

  // MISC
  const projectMode = state.projectMode;

  // EVENTS
  const handleTabChange = (event: any, index: number) => {
    dispatch({ type: ActionTypes.SET_CALCULATION_STEP, payload: index });

    if (index === 1) {
      fetchConstSavingDetails({
        resources: state.appliedResource || state.cache.projectResources || [],
        selectedRatePlanCode: state.bestRate
      });
    }
  };

  // RENDERS
  const stepsTabs = [
    {
      label: '1. Clean energy technologies',
      disabled: isLoadingProject
    },
    {
      label: '2. Cost estimates',
      disabled: isLoadingProject
    },
    {
      label: '3. Next steps',
      disabled: isLoadingProject
    }
  ];

  const renderTitle = () => {
    if (isLoadingProject) {
      return <Skeleton width={300} height={40}/>;
    }

    if (projectMode === 'NEW') {
      return 'New calculation';
    }

    if (projectMode === 'EDIT') {
      if (projectData?.name) {
        return projectData.name;
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>{renderTitle()}</h1>
        <Button
          copy='Settings'
          size="sm"
          variant="outlined"
          color="secondary"
        />
      </div>
      <TabNav
        tabs={stepsTabs}
        onChange={handleTabChange}
        value={state.activeCalculationStep}
        orientation={isMobile ? 'vertical' : 'horizontal'}
      />
      {state.activeCalculationStep === 0 && (<ConfigurationPage/>)}
      {state.activeCalculationStep === 1 && (<ResultsPage isLoading={isLoading}/>)}
      {state.activeCalculationStep === 2 && (<ImplementationPage/>)}
      <DrawerSingleModifier />
      <DrawerSaveCalculation />
    </div>
  );
};

export default CalculationSteps;
