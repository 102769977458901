import React, { useEffect, useState } from 'react';
import { Card, DatePicker, Icon, Text } from '@gridx/metronome-components';
import moment from 'moment-timezone';

interface HourlyRatesProps {
  data: any // FIXME
  isLoading: boolean
  maxDate: Date
  pgeChartColors: string[]
  selectDate: string
  setSelectDate: (date: string) => void
}

export const HourlyRates = (props: HourlyRatesProps) => {
  const [morningPrice, setMorningPrice] = useState(0);
  const [afternoonPrice, setAfternoonPrice] = useState(0);
  const [lateAfternoonPrice, setLateAfternoonPrice] = useState(0);
  const [peakPrice, setPeakPrice] = useState(0);
  useEffect(() => {
    if (props.data && props.data.length) {
      setMorningPrice(Math.round(Number(props.data[0].priceDetails[12].intervalPrice) * 100));
      setAfternoonPrice(Math.round(Number(props.data[0].priceDetails[15].intervalPrice) * 100));
      setLateAfternoonPrice(Math.round(Number(props.data[0].priceDetails[17].intervalPrice) * 100));
      setPeakPrice(Math.round(Number(props.data[0].priceDetails[19].intervalPrice) * 100));
    }
  }, [props.data]);

  const hourlyRates = [
    {
      hour: '9am - 12pm',
      averagePrice: `${morningPrice}¢`,
      price: morningPrice,
      unit: '/kWh'
    },
    {
      hour: '12pm - 3pm',
      averagePrice: `${afternoonPrice}¢`,
      price: afternoonPrice,
      unit: '/kWh'
    },
    {
      hour: '3pm - 6pm',
      averagePrice: `${lateAfternoonPrice}¢`,
      price: lateAfternoonPrice,
      unit: '/kWh'
    },
    {
      hour: '6pm - 9pm',
      averagePrice: `${peakPrice}¢`,
      price: peakPrice,
      unit: '/kWh'
    }
  ];

  return (
    <div>
      <Text
        appearance='title-md'
      >
        Hourly pricing
      </Text>
      <div className='flex mar--b-32'>
        <div
          className='bor--1-storm bor--r-4 flex--align-center jus--center flex mar--t-32'
          style={{ color: props.pgeChartColors[3] }}
        >
          {/* CHART DATE PICKER */}
          <DatePicker
            disabled={props.isLoading}
            size='md'
            format='MMM d y'
            dayPlaceholder='Datepicker'
            isRange={false}
            calendarIcon={<Icon.Calendar color={props.pgeChartColors[2]} />}
            clearIcon={null}
            onChange={(value) => props.setSelectDate(moment(value).format('MMM DD, YYYY'))}
            value={props.selectDate}
            maxDate={props.maxDate}
          />
        </div>
      </div>
      <div className='flex pad--b-32'>
        {
          hourlyRates.map((rate) => {
            const {
              averagePrice,
              hour,
              price,
              unit
            } = rate;
            return (
              <Card
                className='bor--2-energy flex flex-md-qt-column'
                key={hour}
                style={{ borderColor: props.pgeChartColors[0] }}
              >
                <div className='bar--container width--10-p'>
                  <span className='bar' style={{ backgroundColor: props.pgeChartColors[3] }} />
                  <span className='bar measure' style={{ height: `${price >= 100 ? 100 : price + 10}%`, backgroundColor: props.pgeChartColors[2] }} />
                </div>
                <div className='width--90-p'>
                  <div
                    className='flex jus--space-between mar--b-20'
                  >
                    <Text
                      appearance='body-lg'
                    >
                      {hour}
                    </Text>
                    <Text
                      appearance='body-md'
                      color='ocean'
                    >
                      avg price
                    </Text>
                  </div>
                  <div
                    className='flex jus--space-between'
                  >
                    <Text
                      appearance='headline-md'
                    >
                      {averagePrice}
                    </Text>
                    <Text
                      appearance='body-md'
                      color='ocean'
                    >
                      {unit}
                    </Text>
                  </div>
                </div>
              </Card>
            );
          })
        }
      </div>
    </div>
  );
};
