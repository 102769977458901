import { ICustomerData } from '../../../../../../api/analyze/analyze.typings';
import { Color, ColorCode } from '../../../../../../hooks/Theme/Theme.model';
import colors from '../../../../../../style/colors';

export const countCustomerTypes = (customers: Array<ICustomerData>) => {
  return customers.reduce((accumulator: any, currentValue) => {
    if (currentValue.customerType in accumulator) {
      accumulator[currentValue.customerType] += 1;
    } else {
      accumulator[currentValue.customerType] = 1;
    }
    return accumulator;
  }, {});
};

export const getCustomColors = (key: string, getThemeColor: Function) => {
  switch (key) {
  case 'residential':
    return getThemeColor(Color.Primary, ColorCode.C100, colors.altitude);
  case 'comercial':
    return getThemeColor(Color.Tertiary, ColorCode.C100, colors.clean);
  case 'industrial':
    return getThemeColor(Color.Quaternary, ColorCode.C100, colors.wind);
  case 'agricultural':
    return getThemeColor(Color.Secondary, ColorCode.C100, colors.cloud);
  default:
    return '#333';
  }
};

export const transformData = (customers: Array<ICustomerData>, getThemeColor: Function) => {
  return Object.entries(countCustomerTypes(customers)).map(([key, value]) => {
    return {
      name: key,
      y: value as number,
      color: getCustomColors(key, getThemeColor)
    };
  });
};
