export const dataTemp = {
  currency: 'USD',
  charts: {
    key: 0,
    seriesData: [
      [
        {
          name: 'Flat Fee',
          data: [
            0.6000000000000001,
            0.8799999999999999,
            0.67,
            0.6900000000000001,
            0.61,
            0.8,
            0.5800000000000001
          ]
        },
        {
          name: 'Net Energy',
          data: [
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        },
        {
          name: 'Energy Delivered',
          data: [
            56.629999999999995,
            83.57,
            63.79,
            64.85000000000001,
            57.97,
            75.44,
            54.81999999999999
          ]
        },
        {
          name: 'Rate',
          data: [
            57.23,
            84.45,
            64.46,
            65.54,
            58.58,
            76.24,
            55.4
          ]
        },
        {
          name: 'Increase',
          data: [
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        }
      ],
      [
        {
          name: 'Flat Fee',
          data: [
            0.56,
            0.8300000000000001,
            0.62,
            0.7000000000000001,
            0.66,
            0.8600000000000001,
            0.62
          ]
        },
        {
          name: 'Net Energy',
          data: [
            0,
            0,
            0,
            0,
            0,
            0,
            0
          ]
        },
        {
          name: 'Energy Delivered',
          data: [
            53.000780877000004,
            77.992859175,
            59.200973205,
            65.749714206,
            62.486119014,
            81.542199585,
            58.971436029
          ]
        },
        {
          name: 'Rate',
          data: [
            57.23,
            84.45,
            64.46,
            65.54,
            58.58,
            76.24,
            55.4
          ]
        },
        {
          name: 'Increase',
          data: [
            0,
            0,
            0,
            0.2097142059999868,
            3.906119014000005,
            5.302199585000011,
            3.5714360290000045
          ]
        }
      ]
    ],
    chartSeriesKeys: [
      'Flat Fee',
      'Net Energy',
      'Energy Delivered',
      'Rate',
      'Increase'
    ],
    data: []
  },
  availableRates: [
    'Time-of-Use (Peak Pricing 4-9 p.m. Every Day) E-TOU-C',
    'Tiered Rate Plan E-1'
  ],
  months: [
    {
      key: 1,
      firstColumnAttr: 1,
      compareRate: [
        {
          dif: 0,
          total: 254,
          currentRate: true,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 253
          }
        },
        {
          dif: -71, // 254 - 183 = 71
          total: 183,
          currentRate: false,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 183
          }
        }
      ],
      lineItems: [
        [
          {
            billLineItemName: 'Baseline_Credit',
            totalAmount: -13.31,
            percentage: -23
          },
          {
            billLineItemName: 'User_Utility_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'NEM_Charges_Before_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'SF_Prop_C_Tax_Surcharge',
            totalAmount: 0.56,
            percentage: 1
          },
          {
            billLineItemName: 'Minimum_Charge',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Summer Off-peak Energy_Charges',
            totalAmount: 51.47,
            percentage: 90
          },
          {
            billLineItemName: 'Energy_Commission_Tax',
            totalAmount: 0.04,
            percentage: 0
          },
          {
            billLineItemName: 'Summer Peak Energy_Charges',
            totalAmount: 18.47,
            percentage: 32
          }
        ],
        [
          {
            billLineItemName: 'User_Utility_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'NEM_Charges_Before_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'SF_Prop_C_Tax_Surcharge',
            totalAmount: 0.52,
            percentage: 1
          },
          {
            billLineItemName: 'Minimum_Charge',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Summer Energy Charge Tier 1',
            totalAmount: 53.000780877000004,
            percentage: 99
          },
          {
            billLineItemName: 'Summer Energy Charge Tier 2',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Summer Energy Charge Tier 2 Continued',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Energy_Commission_Tax',
            totalAmount: 0.04,
            percentage: 0
          }
        ]
      ]
    },
    {
      key: 2,
      firstColumnAttr: 2,
      compareRate: [
        {
          dif: 0,
          total: 292,
          currentRate: true,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 291
          }
        },
        {
          dif: -82,
          total: 210,
          currentRate: false,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 209
          }
        }
      ],
      lineItems: [
        [
          {
            billLineItemName: 'Baseline_Credit',
            totalAmount: -19.6,
            percentage: -23
          },
          {
            billLineItemName: 'User_Utility_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'NEM_Charges_Before_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'SF_Prop_C_Tax_Surcharge',
            totalAmount: 0.82,
            percentage: 1
          },
          {
            billLineItemName: 'Minimum_Charge',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Summer Off-peak Energy_Charges',
            totalAmount: 74.24,
            percentage: 88
          },
          {
            billLineItemName: 'Energy_Commission_Tax',
            totalAmount: 0.06,
            percentage: 0
          },
          {
            billLineItemName: 'Summer Peak Energy_Charges',
            totalAmount: 28.93,
            percentage: 34
          }
        ],
        [
          {
            billLineItemName: 'User_Utility_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'NEM_Charges_Before_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'SF_Prop_C_Tax_Surcharge',
            totalAmount: 0.77,
            percentage: 1
          },
          {
            billLineItemName: 'Minimum_Charge',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Summer Energy Charge Tier 1',
            totalAmount: 77.992859175,
            percentage: 99
          },
          {
            billLineItemName: 'Summer Energy Charge Tier 2',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Summer Energy Charge Tier 2 Continued',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Energy_Commission_Tax',
            totalAmount: 0.06,
            percentage: 0
          }
        ]
      ]
    },
    {
      key: 3,
      firstColumnAttr: 3,
      compareRate: [
        {
          dif: 0,
          total: 240,
          currentRate: true,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 239
          }
        },
        {
          dif: -67,
          total: 173,
          currentRate: false,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 172
          }
        }
      ],
      lineItems: [
        [
          {
            billLineItemName: 'Baseline_Credit',
            totalAmount: -14.87,
            percentage: -23
          },
          {
            billLineItemName: 'User_Utility_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'NEM_Charges_Before_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'SF_Prop_C_Tax_Surcharge',
            totalAmount: 0.63,
            percentage: 1
          },
          {
            billLineItemName: 'Minimum_Charge',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Summer Off-peak Energy_Charges',
            totalAmount: 54.04,
            percentage: 84
          },
          {
            billLineItemName: 'Energy_Commission_Tax',
            totalAmount: 0.04,
            percentage: 0
          },
          {
            billLineItemName: 'Summer Peak Energy_Charges',
            totalAmount: 24.619999999999997,
            percentage: 38
          }
        ],
        [
          {
            billLineItemName: 'User_Utility_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'NEM_Charges_Before_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'SF_Prop_C_Tax_Surcharge',
            totalAmount: 0.58,
            percentage: 1
          },
          {
            billLineItemName: 'Minimum_Charge',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Summer Energy Charge Tier 1',
            totalAmount: 59.200973205,
            percentage: 99
          },
          {
            billLineItemName: 'Summer Energy Charge Tier 2',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Summer Energy Charge Tier 2 Continued',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Energy_Commission_Tax',
            totalAmount: 0.04,
            percentage: 0
          }
        ]
      ]
    },
    {
      key: 4,
      firstColumnAttr: 4,
      compareRate: [
        {
          dif: 0,
          total: 203,
          currentRate: true,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 202
          }
        },
        {
          dif: -57,
          total: 146,
          currentRate: false,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 145
          }
        }
      ],
      lineItems: [
        [
          {
            billLineItemName: 'Baseline_Credit',
            totalAmount: -16.53,
            percentage: -25
          },
          {
            billLineItemName: 'Winter Off-peak Energy_Charges',
            totalAmount: 32.34,
            percentage: 49
          },
          {
            billLineItemName: 'User_Utility_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'NEM_Charges_Before_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'SF_Prop_C_Tax_Surcharge',
            totalAmount: 0.64,
            percentage: 1
          },
          {
            billLineItemName: 'Minimum_Charge',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Winter Peak Energy_Charges',
            totalAmount: 14.34,
            percentage: 22
          },
          {
            billLineItemName: 'Summer Off-peak Energy_Charges',
            totalAmount: 24.34,
            percentage: 37
          },
          {
            billLineItemName: 'Energy_Commission_Tax',
            totalAmount: 0.05,
            percentage: 0
          },
          {
            billLineItemName: 'Summer Peak Energy_Charges',
            totalAmount: 10.36,
            percentage: 16
          }
        ],
        [
          {
            billLineItemName: 'Winter Energy Charge Tier 1',
            totalAmount: 39.585752844,
            percentage: 60
          },
          {
            billLineItemName: 'Winter Energy Charge Tier 2',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'User_Utility_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'NEM_Charges_Before_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'SF_Prop_C_Tax_Surcharge',
            totalAmount: 0.65,
            percentage: 1
          },
          {
            billLineItemName: 'Minimum_Charge',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Summer Energy Charge Tier 1',
            totalAmount: 26.163961362,
            percentage: 39
          },
          {
            billLineItemName: 'Summer Energy Charge Tier 2',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Summer Energy Charge Tier 2 Continued',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Winter Energy Charge Tier 2 Continued',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Energy_Commission_Tax',
            totalAmount: 0.05,
            percentage: 0
          }
        ]
      ]
    },
    {
      key: 5,
      firstColumnAttr: 5,
      compareRate: [
        {
          dif: 0,
          total: 178,
          currentRate: true,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 177
          }
        },
        {
          dif: -50,
          total: 128,
          currentRate: false,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 127
          }
        }
      ],
      lineItems: [
        [
          {
            billLineItemName: 'Baseline_Credit',
            totalAmount: -15.7,
            percentage: -27
          },
          {
            billLineItemName: 'Winter Off-peak Energy_Charges',
            totalAmount: 51.4,
            percentage: 88
          },
          {
            billLineItemName: 'User_Utility_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'NEM_Charges_Before_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'SF_Prop_C_Tax_Surcharge',
            totalAmount: 0.57,
            percentage: 1
          },
          {
            billLineItemName: 'Minimum_Charge',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Winter Peak Energy_Charges',
            totalAmount: 22.27,
            percentage: 38
          },
          {
            billLineItemName: 'Energy_Commission_Tax',
            totalAmount: 0.04,
            percentage: 0
          }
        ],
        [
          {
            billLineItemName: 'Winter Energy Charge Tier 1',
            totalAmount: 62.486119014,
            percentage: 99
          },
          {
            billLineItemName: 'Winter Energy Charge Tier 2',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'User_Utility_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'NEM_Charges_Before_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'SF_Prop_C_Tax_Surcharge',
            totalAmount: 0.62,
            percentage: 1
          },
          {
            billLineItemName: 'Minimum_Charge',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Winter Energy Charge Tier 2 Continued',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Energy_Commission_Tax',
            totalAmount: 0.04,
            percentage: 0
          }
        ]
      ]
    },
    {
      key: 6,
      firstColumnAttr: 6,
      compareRate: [
        {
          dif: 0,
          total: 279,
          currentRate: true,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 278
          }
        },
        {
          dif: -78,
          total: 201,
          currentRate: false,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 200
          }
        }
      ],
      lineItems: [
        [
          {
            billLineItemName: 'Baseline_Credit',
            totalAmount: -20.49,
            percentage: -27
          },
          {
            billLineItemName: 'Winter Off-peak Energy_Charges',
            totalAmount: 70.44,
            percentage: 92
          },
          {
            billLineItemName: 'User_Utility_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'NEM_Charges_Before_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'SF_Prop_C_Tax_Surcharge',
            totalAmount: 0.74,
            percentage: 1
          },
          {
            billLineItemName: 'Minimum_Charge',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Winter Peak Energy_Charges',
            totalAmount: 25.49,
            percentage: 33
          },
          {
            billLineItemName: 'Energy_Commission_Tax',
            totalAmount: 0.06,
            percentage: 0
          }
        ],
        [
          {
            billLineItemName: 'Winter Energy Charge Tier 1',
            totalAmount: 81.542199585,
            percentage: 99
          },
          {
            billLineItemName: 'Winter Energy Charge Tier 2',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'User_Utility_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'NEM_Charges_Before_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'SF_Prop_C_Tax_Surcharge',
            totalAmount: 0.8,
            percentage: 1
          },
          {
            billLineItemName: 'Minimum_Charge',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Winter Energy Charge Tier 2 Continued',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Energy_Commission_Tax',
            totalAmount: 0.06,
            percentage: 0
          }
        ]
      ]
    },
    {
      key: 7,
      firstColumnAttr: 7,
      compareRate: [
        {
          dif: 0,
          total: 305,
          currentRate: true,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 304
          }
        },
        {
          dif: -86,
          total: 219,
          currentRate: false,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 218
          }
        }
      ],
      lineItems: [
        [
          {
            billLineItemName: 'Baseline_Credit',
            totalAmount: -14.81,
            percentage: -27
          },
          {
            billLineItemName: 'Winter Off-peak Energy_Charges',
            totalAmount: 46.76,
            percentage: 84
          },
          {
            billLineItemName: 'User_Utility_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'NEM_Charges_Before_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'SF_Prop_C_Tax_Surcharge',
            totalAmount: 0.54,
            percentage: 1
          },
          {
            billLineItemName: 'Minimum_Charge',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Winter Peak Energy_Charges',
            totalAmount: 22.87,
            percentage: 41
          },
          {
            billLineItemName: 'Energy_Commission_Tax',
            totalAmount: 0.04,
            percentage: 0
          }
        ],
        [
          {
            billLineItemName: 'Winter Energy Charge Tier 1',
            totalAmount: 58.971436029,
            percentage: 99
          },
          {
            billLineItemName: 'Winter Energy Charge Tier 2',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'User_Utility_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'NEM_Charges_Before_Tax',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'SF_Prop_C_Tax_Surcharge',
            totalAmount: 0.58,
            percentage: 1
          },
          {
            billLineItemName: 'Minimum_Charge',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Winter Energy Charge Tier 2 Continued',
            totalAmount: 0,
            percentage: 0
          },
          {
            billLineItemName: 'Energy_Commission_Tax',
            totalAmount: 0.04,
            percentage: 0
          }
        ]
      ]
    },
    {
      key: 8,
      firstColumnAttr: 8,
      compareRate: [
        {
          dif: 0,
          total: 330,
          currentRate: true,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 329
          }
        },
        {
          dif: -93,
          total: 237,
          currentRate: false,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 236
          }
        }
      ]
    },
    {
      key: 9,
      firstColumnAttr: 9,
      compareRate: [
        {
          dif: 0,
          total: 317,
          currentRate: true,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 316
          }
        },
        {
          dif: -89,
          total: 228,
          currentRate: false,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 227
          }
        }
      ]
    },
    {
      key: 10,
      firstColumnAttr: 10,
      compareRate: [
        {
          dif: 0,
          total: 178,
          currentRate: true,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 177
          }
        },
        {
          dif: -50,
          total: 128,
          currentRate: false,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 127
          }
        }
      ]
    },
    {
      key: 11,
      firstColumnAttr: 11,
      compareRate: [
        {
          dif: 0,
          total: 228,
          currentRate: true,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 227
          }
        },
        {
          dif: -64,
          total: 164,
          currentRate: false,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 163
          }
        }
      ]
    },
    {
      key: 12,
      firstColumnAttr: 12,
      compareRate: [
        {
          dif: 0,
          total: 241,
          currentRate: true,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 240
          }
        },
        {
          dif: -68,
          total: 173,
          currentRate: false,
          tagAttributes: {
            'Flat Fee': 1,
            'Net Energy': 0,
            'Energy Delivered': 172
          }
        }
      ]
    },
    {
      key: 13,
      firstColumnAttr: 'Total',
      compareRate: [
        {
          dif: 0,
          total: 3045,
          currentRate: true,
          tagAttributes: {
            'Flat Fee': 12,
            'Net Energy': 0,
            'Energy Delivered': 3033
          }
        },
        {
          dif: -855,
          total: 2190,
          currentRate: false,
          tagAttributes: {
            'Flat Fee': 12,
            'Net Energy': 0,
            'Energy Delivered': 2178
          }
        }
      ]
    }
  ],
  rateDetails: [
    {
      ratePlanCode: 'ETOUC',
      rateMarketingName: 'Time-of-Use (Peak Pricing 4-9 p.m. Every Day) E-TOU-C',
      totalAmt: 460,
      totalSavings: 0,
      bestRate: true,
      currentRate: true
    },
    {
      ratePlanCode: 'E1',
      rateMarketingName: 'Tiered Rate Plan E-1',
      totalAmt: 465,
      totalSavings: -5,
      bestRate: false,
      currentRate: false
    }
  ],
  bestRates: [
    {
      month: 6,
      total: 57.23
    },
    {
      month: 7,
      total: 84.45
    },
    {
      month: 8,
      total: 64.46
    },
    {
      month: 9,
      total: 65.54
    },
    {
      month: 10,
      total: 58.58
    },
    {
      month: 11,
      total: 76.24
    },
    {
      month: 12,
      total: 55.4
    }
  ],
  tagAttributesList: [
    'Flat Fee',
    'Net Energy',
    'Energy Delivered'
  ]
};
