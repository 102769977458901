import * as React from 'react';

import colors from '../../../style/colors';

const Accounts = (props:any) => {
  const { color, showBrandName, showGridX } = props;

  let renderColor;
  switch (color) {
  case 'deep':
    renderColor = colors.deep;
    break;
  case 'energy':
    renderColor = colors.energy;
    break;
  case 'white':
    renderColor = colors.white;
    break;
  }

  if (showGridX) {
    return (
      <svg
        width={510}
        height={134}
        viewBox="0 0 510 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_11025_6619)">
          <path
            d="M90.6506 96.11L74.2374 112.523C72.3059 114.458 69.7338 115.521 66.9999 115.521C64.266 115.521 61.6972 114.458 59.7624 112.523L42.8803 95.6411L29.8052 108.716L46.6872 125.598C52.2871 131.198 59.6468 133.998 66.9999 133.998C74.353 133.998 81.7127 131.198 87.3125 125.598L103.726 109.185L90.6506 96.11Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M21.4749 74.2358C17.483 70.244 17.483 63.7493 21.4749 59.7608L37.8881 43.3476L24.813 30.2725L8.39976 46.6857C-2.79992 57.8854 -2.79992 76.1113 8.39976 87.3109L23.9677 102.879L37.0428 89.8038L21.4749 74.2358Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M125.6 46.6858L108.718 29.8037L95.6431 42.8788L112.525 59.7609C116.517 63.7495 116.517 70.2441 112.525 74.236L96.4883 90.2761L109.563 103.351L125.6 87.3111C136.8 76.1114 136.8 57.8855 125.6 46.6858Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M87.3124 8.39807C76.1127 -2.80161 57.8868 -2.80491 46.6871 8.39807L30.647 24.4382L43.7221 37.5133L59.7622 21.4732C61.7565 19.4756 64.3781 18.4784 66.9997 18.4784C69.6213 18.4784 72.243 19.4756 74.2372 21.4732L89.8052 37.0411L102.88 23.966L87.3124 8.39807Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M78.4114 46.3158C78.4114 46.9827 78.1505 47.6134 77.6784 48.0888L68.7734 56.9905C68.3013 57.4659 67.6706 57.7268 67.0004 57.7268C66.3301 57.7268 65.7028 57.4659 65.2273 56.9905L56.3257 48.0888C55.8502 47.6134 55.5894 46.9827 55.5894 46.3158C55.5894 45.6488 55.8502 45.0149 56.3257 44.5427L65.2273 35.6378C65.7028 35.1656 66.3301 34.9048 67.0004 34.9048C67.6706 34.9048 68.3013 35.1656 68.7734 35.6378L77.6784 44.5427C78.1505 45.0149 78.4114 45.6455 78.4114 46.3158Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M99.094 66.9984C99.094 67.6687 98.8331 68.296 98.361 68.7715L89.456 77.6731C88.9839 78.1486 88.3532 78.4094 87.683 78.4094C87.0127 78.4094 86.3854 78.1486 85.9099 77.6731L77.0083 68.7715C76.5328 68.296 76.272 67.6687 76.272 66.9984C76.272 66.3281 76.5328 65.6975 77.0083 65.2253L85.9099 56.3204C86.3854 55.8482 87.016 55.5874 87.683 55.5874C88.3499 55.5874 88.9839 55.8482 89.456 56.3204L98.361 65.2253C98.8331 65.6975 99.094 66.3281 99.094 66.9984Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M57.7287 66.9984C57.7287 67.6687 57.4679 68.296 56.9924 68.7715L48.0908 77.6731C47.6153 78.1486 46.988 78.4094 46.3177 78.4094C45.6475 78.4094 45.0168 78.1486 44.5447 77.6731L35.6397 68.7715C35.1676 68.296 34.9067 67.6687 34.9067 66.9984C34.9067 66.3281 35.1676 65.6975 35.6397 65.2253L44.5447 56.3204C45.0168 55.8482 45.6475 55.5874 46.3177 55.5874C46.988 55.5874 47.6153 55.8482 48.0908 56.3204L56.9924 65.2253C57.4679 65.6975 57.7287 66.3281 57.7287 66.9984Z"
            fill={renderColor || '#344145'}
          />
        </g>
        <path
          d="M204.048 114.016L200.088 103.288H177.408L173.448 114.016H163.08L183.6 61.8164H193.608L214.776 114.016H204.048ZM180.216 95.8004H197.28L188.712 72.7604L180.216 95.8004Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M217.747 95.1524C217.747 83.4164 225.955 75.6404 237.619 75.6404C247.699 75.6404 255.259 81.2564 256.555 89.6084H247.339C246.115 85.7204 242.227 83.4884 237.619 83.4884C230.995 83.4884 227.035 88.1684 227.035 95.2244C227.035 102.352 231.211 106.888 237.691 106.888C242.587 106.888 246.115 104.512 247.339 100.768H256.555C255.187 109.264 247.699 114.736 237.763 114.736C225.955 114.736 217.747 106.888 217.747 95.1524Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M262.176 95.1524C262.176 83.4164 270.384 75.6404 282.048 75.6404C292.128 75.6404 299.688 81.2564 300.984 89.6084H291.768C290.544 85.7204 286.656 83.4884 282.048 83.4884C275.424 83.4884 271.464 88.1684 271.464 95.2244C271.464 102.352 275.64 106.888 282.12 106.888C287.016 106.888 290.544 104.512 291.768 100.768H300.984C299.616 109.264 292.128 114.736 282.192 114.736C270.384 114.736 262.176 106.888 262.176 95.1524Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M306.605 95.1524C306.605 83.4884 315.029 75.6404 327.197 75.6404C339.365 75.6404 347.789 83.4884 347.789 95.1524C347.789 106.888 339.365 114.736 327.197 114.736C315.029 114.736 306.605 106.888 306.605 95.1524ZM315.893 95.2244C315.893 102.496 320.429 106.888 327.269 106.888C334.253 106.888 338.573 102.208 338.573 95.1524C338.573 87.8804 334.037 83.4164 327.341 83.4164C320.285 83.4164 315.893 88.0964 315.893 95.2244Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M355.519 99.0404V76.3604H364.591V99.0404C364.591 104.296 367.687 106.96 372.655 106.96C378.055 106.96 380.719 103.792 380.719 99.0404V76.3604H389.791V99.0404C389.791 109.48 383.023 114.736 372.583 114.736C361.135 114.736 355.519 108.472 355.519 99.0404Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M399.667 114.016V76.3604H408.019L408.811 81.8324C411.043 78.0164 415.651 75.6404 420.979 75.6404C430.051 75.6404 434.731 81.2564 434.731 90.3284V114.016H425.659V91.6244C425.659 85.9364 422.635 83.2724 418.027 83.2724C412.483 83.2724 408.739 87.2324 408.739 93.7844V114.016H399.667Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M450.518 114.016V83.8484H439.934V76.3604H450.518V66.7124L459.59 66.2084V76.3604H470.246V83.8484H459.59V114.016H450.518Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M491.524 114.736C481.804 114.736 475.54 109.84 475.468 102.496H483.892C484.036 105.952 487.06 108.04 491.524 108.04C495.916 108.04 498.22 106.168 498.22 103.36C498.22 100.912 496.492 99.6164 493.756 99.1124L486.844 97.8884C481.156 96.8084 476.116 94.0724 476.116 87.4484C476.116 80.3204 482.236 75.6404 491.308 75.6404C500.38 75.6404 506.572 80.0324 506.644 87.3764H498.508C498.364 84.0644 495.556 82.2644 491.452 82.2644C487.564 82.2644 485.116 83.9924 485.116 86.7284C485.116 89.6084 487.996 90.2564 489.94 90.6164L496.852 91.8404C504.412 93.1364 507.292 97.3124 507.292 102.712C507.292 109.84 501.388 114.736 491.524 114.736Z"
          fill={renderColor || '#344145'}
        />
        <g clipPath="url(#clip1_11025_6619)">
          <path
            d="M208.414 9.94043H203.654V29.35H208.414V9.94043Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M208.414 3.42627H203.654V7.55243H208.414V3.42627Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M233.159 1.5166V29.35H228.957L228.578 26.4555C227.219 28.4614 224.424 29.7216 221.1 29.7216C215.131 29.7216 211.278 25.6396 211.278 19.7024C211.278 13.5781 215.432 9.56886 221.478 9.56886C224.347 9.56886 226.878 10.5328 228.391 12.2399V1.5166H233.159ZM228.399 19.9258V19.4789C228.399 15.8412 225.905 13.5027 222.239 13.5027C218.463 13.5027 216.123 15.9504 216.123 19.6244C216.123 23.4466 218.577 25.7851 222.205 25.7851C225.833 25.7851 228.399 23.5999 228.399 19.9258Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M241.637 19.1491L234.465 9.94057H240.376L244.94 15.9505C245.02 16.0544 245.124 16.1387 245.243 16.1967C245.362 16.2547 245.493 16.2849 245.625 16.2849C245.758 16.2849 245.889 16.2547 246.008 16.1967C246.126 16.1387 246.23 16.0544 246.31 15.9505L251.028 9.93018H256.772L249.486 19.1335C249.368 19.2817 249.304 19.4647 249.304 19.6532C249.304 19.8416 249.368 20.0246 249.486 20.1728L256.809 29.3372H250.991L246.26 23.4156C246.18 23.3136 246.077 23.2311 245.959 23.1743C245.841 23.1175 245.712 23.088 245.58 23.088C245.449 23.088 245.32 23.1175 245.202 23.1743C245.084 23.2311 244.981 23.3136 244.901 23.4156L240.249 29.3424H234.465L241.637 20.1702C241.751 20.0235 241.813 19.8441 241.813 19.6597C241.813 19.4752 241.751 19.2958 241.637 19.1491Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M198.883 9.94042L202.059 14.1545H195.696C195.212 14.1545 194.748 14.3434 194.406 14.6796C194.064 15.0158 193.872 15.6594 193.872 16.1349V29.35H189.204V15.8387C189.204 11.5384 191.661 9.93262 194.731 9.93262L198.883 9.94042Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M181.395 9.94026L181.093 12.6503C179.771 10.7951 177.127 9.57129 173.916 9.57129C168.098 9.57129 164.285 13.5779 164.285 19.1851C164.285 24.7508 168.101 28.76 173.916 28.76C176.939 28.76 179.62 27.5726 180.829 25.7174V28.6847C180.829 31.8027 178.488 33.7333 174.974 33.7333C173.022 33.7333 171.488 33.0915 170.441 32.1743C170.275 32.0261 169.939 31.7455 169.939 31.7455L166.895 34.7856C167.158 35.0508 167.441 35.2966 167.741 35.5209C169.449 36.7967 171.859 37.5216 174.9 37.5216C181.395 37.5216 185.589 34.1074 185.589 28.5028V9.94026H181.395ZM180.829 19.3307C180.829 22.7449 178.224 24.9353 174.86 24.9353C171.234 24.9353 169.119 22.5214 169.119 19.1462C169.119 15.6566 171.385 13.3934 174.9 13.3934C178.261 13.3934 180.829 15.4721 180.829 18.8863V19.3307Z"
            fill={renderColor || '#344145'}
          />
        </g>
        <defs>
          <clipPath id="clip0_11025_6619">
            <rect width={134} height={134} fill="white" />
          </clipPath>
          <clipPath id="clip1_11025_6619">
            <rect
              width={99}
              height={39}
              fill="white"
              transform="translate(162 0.0166016)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (showBrandName) {
    return (
      <svg
        width={507}
        height={134}
        viewBox="0 0 507 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_11025_6482)">
          <path
            d="M90.6506 96.11L74.2374 112.523C72.3059 114.458 69.7338 115.521 66.9999 115.521C64.266 115.521 61.6972 114.458 59.7624 112.523L42.8803 95.6411L29.8052 108.716L46.6872 125.598C52.2871 131.198 59.6468 133.998 66.9999 133.998C74.353 133.998 81.7127 131.198 87.3125 125.598L103.726 109.185L90.6506 96.11Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M21.4749 74.2358C17.483 70.244 17.483 63.7493 21.4749 59.7608L37.8881 43.3476L24.813 30.2725L8.39976 46.6857C-2.79992 57.8854 -2.79992 76.1113 8.39976 87.3109L23.9677 102.879L37.0428 89.8038L21.4749 74.2358Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M125.6 46.6858L108.718 29.8037L95.6431 42.8788L112.525 59.7609C116.517 63.7495 116.517 70.2441 112.525 74.236L96.4883 90.2761L109.563 103.351L125.6 87.3111C136.8 76.1114 136.8 57.8855 125.6 46.6858Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M87.3124 8.39807C76.1127 -2.80161 57.8868 -2.80491 46.6871 8.39807L30.647 24.4382L43.7221 37.5133L59.7622 21.4732C61.7565 19.4756 64.3781 18.4784 66.9997 18.4784C69.6213 18.4784 72.243 19.4756 74.2372 21.4732L89.8052 37.0411L102.88 23.966L87.3124 8.39807Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M78.4114 46.3158C78.4114 46.9827 78.1505 47.6134 77.6784 48.0888L68.7734 56.9905C68.3013 57.4659 67.6706 57.7268 67.0004 57.7268C66.3301 57.7268 65.7028 57.4659 65.2273 56.9905L56.3257 48.0888C55.8502 47.6134 55.5894 46.9827 55.5894 46.3158C55.5894 45.6488 55.8502 45.0149 56.3257 44.5427L65.2273 35.6378C65.7028 35.1656 66.3301 34.9048 67.0004 34.9048C67.6706 34.9048 68.3013 35.1656 68.7734 35.6378L77.6784 44.5427C78.1505 45.0149 78.4114 45.6455 78.4114 46.3158Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M99.094 66.9984C99.094 67.6687 98.8331 68.296 98.361 68.7715L89.456 77.6731C88.9839 78.1486 88.3532 78.4094 87.683 78.4094C87.0127 78.4094 86.3854 78.1486 85.9099 77.6731L77.0083 68.7715C76.5328 68.296 76.272 67.6687 76.272 66.9984C76.272 66.3281 76.5328 65.6975 77.0083 65.2253L85.9099 56.3204C86.3854 55.8482 87.016 55.5874 87.683 55.5874C88.3499 55.5874 88.9839 55.8482 89.456 56.3204L98.361 65.2253C98.8331 65.6975 99.094 66.3281 99.094 66.9984Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M57.7287 66.9984C57.7287 67.6687 57.4679 68.296 56.9924 68.7715L48.0908 77.6731C47.6153 78.1486 46.988 78.4094 46.3177 78.4094C45.6475 78.4094 45.0168 78.1486 44.5447 77.6731L35.6397 68.7715C35.1676 68.296 34.9067 67.6687 34.9067 66.9984C34.9067 66.3281 35.1676 65.6975 35.6397 65.2253L44.5447 56.3204C45.0168 55.8482 45.6475 55.5874 46.3177 55.5874C46.988 55.5874 47.6153 55.8482 48.0908 56.3204L56.9924 65.2253C57.4679 65.6975 57.7287 66.3281 57.7287 66.9984Z"
            fill={renderColor || '#344145'}
          />
        </g>
        <path
          d="M202.968 92.74L199.008 82.012H176.328L172.368 92.74H162L182.52 40.54H192.528L213.696 92.74H202.968ZM179.136 74.524H196.2L187.632 51.484L179.136 74.524Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M216.667 73.876C216.667 62.14 224.875 54.364 236.539 54.364C246.619 54.364 254.179 59.98 255.475 68.332H246.259C245.035 64.444 241.147 62.212 236.539 62.212C229.915 62.212 225.955 66.892 225.955 73.948C225.955 81.076 230.131 85.612 236.611 85.612C241.507 85.612 245.035 83.236 246.259 79.492H255.475C254.107 87.988 246.619 93.46 236.683 93.46C224.875 93.46 216.667 85.612 216.667 73.876Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M261.096 73.876C261.096 62.14 269.304 54.364 280.968 54.364C291.048 54.364 298.608 59.98 299.904 68.332H290.688C289.464 64.444 285.576 62.212 280.968 62.212C274.344 62.212 270.384 66.892 270.384 73.948C270.384 81.076 274.56 85.612 281.04 85.612C285.936 85.612 289.464 83.236 290.688 79.492H299.904C298.536 87.988 291.048 93.46 281.112 93.46C269.304 93.46 261.096 85.612 261.096 73.876Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M305.525 73.876C305.525 62.212 313.949 54.364 326.117 54.364C338.285 54.364 346.709 62.212 346.709 73.876C346.709 85.612 338.285 93.46 326.117 93.46C313.949 93.46 305.525 85.612 305.525 73.876ZM314.813 73.948C314.813 81.22 319.349 85.612 326.189 85.612C333.173 85.612 337.493 80.932 337.493 73.876C337.493 66.604 332.957 62.14 326.261 62.14C319.205 62.14 314.813 66.82 314.813 73.948Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M354.439 77.764V55.084H363.511V77.764C363.511 83.02 366.607 85.684 371.575 85.684C376.975 85.684 379.639 82.516 379.639 77.764V55.084H388.711V77.764C388.711 88.204 381.943 93.46 371.503 93.46C360.055 93.46 354.439 87.196 354.439 77.764Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M398.587 92.74V55.084H406.939L407.731 60.556C409.963 56.74 414.571 54.364 419.899 54.364C428.971 54.364 433.651 59.98 433.651 69.052V92.74H424.579V70.348C424.579 64.66 421.555 61.996 416.947 61.996C411.403 61.996 407.659 65.956 407.659 72.508V92.74H398.587Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M449.438 92.74V62.572H438.853V55.084H449.438V45.436L458.509 44.932V55.084H469.165V62.572H458.509V92.74H449.438Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M490.444 93.46C480.724 93.46 474.46 88.564 474.388 81.22H482.812C482.956 84.676 485.98 86.764 490.444 86.764C494.836 86.764 497.14 84.892 497.14 82.084C497.14 79.636 495.412 78.34 492.676 77.836L485.764 76.612C480.076 75.532 475.036 72.796 475.036 66.172C475.036 59.044 481.156 54.364 490.228 54.364C499.3 54.364 505.492 58.756 505.564 66.1H497.428C497.284 62.788 494.476 60.988 490.372 60.988C486.484 60.988 484.036 62.716 484.036 65.452C484.036 68.332 486.916 68.98 488.86 69.34L495.772 70.564C503.332 71.86 506.212 76.036 506.212 81.436C506.212 88.564 500.308 93.46 490.444 93.46Z"
          fill={renderColor || '#344145'}
        />
        <defs>
          <clipPath id="clip0_11025_6482">
            <rect width={134} height={134} fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 64 64"
        {...props}
      >
        <path
          fill={renderColor || '#FFA44F'}
          d="m43.296 45.903-7.84 7.84A4.852 4.852 0 0 1 32 55.173a4.855 4.855 0 0 1-3.457-1.431l-8.063-8.064-6.245 6.245 8.063 8.063A13.68 13.68 0 0 0 32 64a13.68 13.68 0 0 0 9.702-4.012l7.839-7.839-6.245-6.245Z"
        />
        <path
          fill={renderColor || '#344145'}
          d="M10.257 35.456a4.893 4.893 0 0 1 0-6.914l7.839-7.839-6.245-6.245-7.84 7.84c-5.348 5.349-5.348 14.053 0 19.402l7.436 7.436 6.245-6.245-7.435-7.435Z"
        />
        <path
          fill={renderColor || '#FFA44F'}
          d="m59.988 22.298-8.063-8.063-6.245 6.244 8.063 8.064a4.893 4.893 0 0 1 0 6.913l-7.66 7.66 6.246 6.246 7.66-7.661c5.348-5.35 5.348-14.054 0-19.403Z"
        />
        <path
          fill={renderColor || '#D4E9EC'}
          d="M41.702 4.011c-5.35-5.35-14.054-5.35-19.404 0l-7.66 7.66 6.244 6.246 7.661-7.661A4.868 4.868 0 0 1 32 8.826c1.252 0 2.504.476 3.457 1.43l7.435 7.435 6.245-6.245-7.435-7.435Z"
        />
        <path
          fill={renderColor || '#D4E9EC'}
          d="M37.45 22.12c0 .32-.125.62-.35.848l-4.253 4.251a1.185 1.185 0 0 1-.847.352c-.32 0-.62-.125-.847-.352l-4.251-4.251a1.19 1.19 0 0 1-.352-.847c0-.319.125-.621.352-.847l4.251-4.253c.227-.226.527-.35.847-.35.32 0 .621.125.847.35l4.253 4.253c.225.226.35.527.35.847Z"
        />
        <path
          fill={renderColor || '#FFA44F'}
          d="M47.328 32c0 .32-.124.619-.35.846l-4.253 4.252a1.185 1.185 0 0 1-.847.351c-.32 0-.62-.124-.847-.352l-4.251-4.251a1.188 1.188 0 0 1-.352-.847c0-.32.125-.621.352-.847L41.03 26.9a1.196 1.196 0 0 1 1.694 0l4.253 4.253c.226.226.35.527.35.847Z"
        />
        <path
          fill={renderColor || '#344145'}
          d="M27.572 32c0 .32-.125.619-.352.846l-4.251 4.252a1.188 1.188 0 0 1-.847.351c-.32 0-.621-.124-.847-.352l-4.253-4.251a1.193 1.193 0 0 1-.35-.847c0-.32.125-.621.35-.847l4.253-4.253a1.19 1.19 0 0 1 .847-.35c.32 0 .62.125.847.35l4.251 4.253c.227.226.352.527.352.847Z"
        />
      </svg>
    );
  }
};
export default Accounts;
