import React, { Fragment } from 'react';

import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import Header from '../../components/Header/Header';
import Link from '../../components/Link/Link';
import Text from '../../components/Text/Text';

import './button-page.scss';

interface Props {
    color?: 'default' | 'depth' | 'magma' | 'eversource-blue',
    icon?: string | null,
    iconPos?: string | null,
    iconOnly?: boolean,
    type?: 'primary' | 'secondary' | 'submit',
};

const ButtonPage: React.FC = () => {
  const renderButtons: React.FC<Props> = ({
    color = 'depth',
    icon = null,
    iconPos = null,
    iconOnly = false,
    type = 'primary'
  }) => {
    const renderIcons = () => {
      let TempIcon = <Icon.ArrowRight />;

      switch (icon) {
      case 'arrow-down-circle':
        TempIcon = <Icon.ArrowDownCircle />;
        break;
      case 'arrow-down-left':
        TempIcon = <Icon.ArrowDownLeft />;
        break;
      case 'arrow-down-right':
        TempIcon = <Icon.ArrowDownRight />;
        break;
      case 'arrow-down':
        TempIcon = <Icon.ArrowDown />;
        break;
      case 'arrow-left-circle':
        TempIcon = <Icon.ArrowLeftCircle />;
        break;
      case 'arrow-left':
        TempIcon = <Icon.ArrowLeft />;
        break;
      case 'arrow-right-circle':
        TempIcon = <Icon.ArrowRightCircle />;
        break;
      case 'arrow-right':
        TempIcon = <Icon.ArrowRight />;
        break;
      case 'arrow-up-circle':
        TempIcon = <Icon.ArrowUpCircle />;
        break;
      case 'arrow-up-left':
        TempIcon = <Icon.ArrowUpLeft />;
        break;
      case 'arrow-up-right':
        TempIcon = <Icon.ArrowUpRight />;
        break;
      case 'arrow-up':
        TempIcon = <Icon.ArrowUp />;
        break;
      case 'plus':
        TempIcon = <Icon.Plus />;
        break;
      default:
        TempIcon = <Icon.Grid />;
      }

      return (
        TempIcon
      );
    };

    return (
      <Fragment>
        <div
          className='column--five'
        >
          <Button
            copy='Button'
            color={color}
            size='sm'
            icon={renderIcons()}
            iconPosition={iconPos}
            iconOnly={iconOnly}
            type={type}
          />
        </div>
        <div
          className='column--five'
        >
          <Button
            copy='Button'
            color={color}
            size='md'
            icon={renderIcons()}
            iconPosition={iconPos}
            iconOnly={iconOnly}
            type={type}
          />
        </div>
        <div
          className='column--five'
        >
          <Button
            copy='Button'
            color={color}
            size='lg'
            icon={renderIcons()}
            iconPosition={iconPos}
            iconOnly={iconOnly}
            type={type}
          />
        </div>
        <div
          className='column--five'
        >
          <Button
            className='column--five'
            copy='Button'
            color={color}
            size='xl'
            icon={renderIcons()}
            iconPosition={iconPos}
            iconOnly={iconOnly}
            type={type}
          />
        </div>
        <div
          className='column--five'
        >
          <Button
            copy='Button'
            color={color}
            disabled
            size='xl'
            icon={renderIcons()}
            iconPosition={iconPos}
            iconOnly={iconOnly}
            type={type}
          />
        </div>

      </Fragment>
    );
  };

  return (
    <Fragment>
      <Header
        className='design-studio pad--b-100 pad--l-128 pad--t-100'
        color='stone'
        text='Button'
      >
        <Text
          className='max-width--900 pad--t-40 title--sm'
          color='white'
          variant='body1'
        >
            Multiple button types: Magnetize Primary, Magnetize Secondary, Depth Primary, Depth Secondary, Flow Primary, and Flow Secondary. Primary buttons should be used for the primary desired user action.
        </Text>
        <Link
          className='underline dis--block mar--t-60 body--xl'
          color='white'
          copy='See Our Guidelines'
          href='/'
        />
      </Header>
      <div
        className='mar--l-96 mar--t-80 max-width--lg temp--min-width--md'
      >
        <div
          className='pad--b-80'
        >
          <aside className='button-page--aside pad--t-40'>
            <Text
              className='max-width--900 pad--t-40 title--sm'
              color='stone'
              variant='body1'
            >
              Magnetize Primary
            </Text>
          </aside>
          <section className='button-page--section'>
            <div className='flex flex--column'>
              <Text
                className='body--sm column--five'
                color='stone'
                variant='body1'
              >
                Small
              </Text>
              <Text
                className='body--sm column--five'
                color='stone'
                variant='body1'
              >
                Medium
              </Text>
              <Text
                className='body--sm column--five'
                color='stone'
                variant='body1'
              >
                Large
              </Text>
              <Text
                className='body--sm column--five'
                color='stone'
                variant='body1'
              >
                Extra Large
              </Text>
              <Text
                className='body--sm column--five'
                color='stone'
                variant='body1'
              >
                Disabled
              </Text>
            </div>
            <div className='flex mar--t-40'>
              {renderButtons({ color: 'magma' })}
            </div>
            {/* ICONS */}
            <div className='flex mar--t-40 flex--column'>
              {renderButtons({ color: 'magma', icon: 'plus', iconPos: 'left', iconOnly: false })}
            </div>
            <div className='flex mar--t-40 jus--space-between'>
              {renderButtons({ color: 'magma', icon: 'plus', iconPos: 'right', iconOnly: false })}
            </div>
            <div className='flex mar--t-40 jus--space-between'>
              {renderButtons({ color: 'magma', icon: 'arrow-down-circle', iconPos: null, iconOnly: true })}
            </div>
          </section>
        </div>
        <div
          className='pad--b-80'
        >
          <aside className='button-page--aside'>
            <Text
              className='max-width--900 pad--t-40 title--sm'
              color='stone'
              variant='body1'
            >
              Magnetize Secondary
            </Text>
          </aside>
          <section className='button-page--section'>
            <div className='flex jus--space-between'>
              {renderButtons({ color: 'magma', icon: null, iconPos: null, iconOnly: false, type: 'secondary' })}
            </div>
            {/* ICONS */}
            <div className='flex mar--t-40 jus--space-between'>
              {renderButtons({ color: 'magma', icon: 'plus', iconPos: 'left', iconOnly: false, type: 'secondary' })}
            </div>
            <div className='flex mar--t-40 jus--space-between'>
              {renderButtons({ color: 'magma', icon: 'plus', iconPos: 'right', iconOnly: false, type: 'secondary' })}
            </div>
            <div className='flex mar--t-40 jus--space-between'>
              {renderButtons({ color: 'magma', icon: 'arrow-down-circle', iconPos: null, iconOnly: true, type: 'secondary' })}
            </div>
          </section>
        </div>
        <div>
          <aside className='button-page--aside'>
            <Text
              className='max-width--900 pad--t-40 title--sm'
              color='stone'
              variant='body1'
            >
              Depth Primary
            </Text>
          </aside>
          <section className='button-page--section pad--b-80'>
            <div className='flex jus--space-between'>
              {renderButtons({ color: 'depth', icon: 'arrow-down-circle' })}
            </div>
            {/* ICONS */}
            <div className='flex mar--t-40 jus--space-between'>
              {renderButtons({ color: 'depth', icon: 'plus', iconPos: 'left' })}
            </div>
            <div className='flex mar--t-40 jus--space-between'>
              {renderButtons({ color: 'depth', icon: 'arrow-right', iconPos: 'right' })}
            </div>
            <div className='flex mar--t-40 jus--space-between'>
              {renderButtons({ color: 'depth', icon: 'arrow-down-circle', iconPos: null, iconOnly: true })}
            </div>
          </section>
        </div>

        <div>
          <aside className='button-page--aside'>
            <Text
              className='max-width--900 pad--t-40 title--sm'
              color='stone'
              variant='body1'
            >
              Depth Secondary
            </Text>
          </aside>
          <section className='button-page--section pad--b-80'>
            <div className='flex jus--space-between'>
              {renderButtons({ color: 'depth', icon: 'arrow-down-circle', iconPos: null, type: 'secondary' })}
            </div>
            {/* ICONS */}
            <div className='flex mar--t-40 jus--space-between'>
              {renderButtons({ color: 'depth', icon: 'plus', iconPos: 'left', type: 'secondary' })}
            </div>
            <div className='flex mar--t-40 jus--space-between'>
              {renderButtons({ color: 'depth', icon: 'arrow-right', iconPos: 'right', type: 'secondary' })}
            </div>
            <div className='flex mar--t-40 jus--space-between'>
              {renderButtons({ color: 'depth', icon: 'arrow-down-circle', iconPos: null, iconOnly: true, type: 'secondary' })}
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  );
};

export default ButtonPage;
