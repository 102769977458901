import React from 'react';
import { useParams } from 'react-router-dom';

// COMPONENTS
import ExploreNav from 'pages/Explore/sections/ExplorePage/components/ExploreNav/ExploreNav';
import MainPage from '../../components/MainPage/MainPage';
import RatesComparisonTable from 'widget/RateAnalysis/components/RatesComparisonTable/RatesComparisonTable';

// ASSETS
import { ThemeProvider } from 'hooks/Theme/Theme.context';

// HELPES
import { conedTheme } from 'hooks/Theme/Theme.config';

interface RatesProgramsCompareProps {
  theme?: string;
}

const RatesProgramsCompare:React.FC<RatesProgramsCompareProps> = ({ theme = '' }) => {
  const { theme: themeRoute } = useParams();
  const currentTheme = themeRoute || theme;

  return (
    <div>
      <ExploreNav
        onCmsChange={() => {}}
        company={currentTheme}
        menu={[
          {
            label: 'My Account',
            selected: false,
            href: `/${currentTheme}/rates-design`
          },
          {
            label: 'Billing',
            selected: false
          },
          {
            label: 'Usage',
            selected: false,
            href: `/${currentTheme}/usage`
          },
          {
            label: 'Rates & Programs',
            selected: true,
            href: `/${currentTheme}/rates-comparison`
          },
          {
            label: 'Explore',
            selected: false,
            href: `/${currentTheme}/explore`
          }
        ]}
      />
      <MainPage title="Rates & Programs">
        <ThemeProvider theme={conedTheme}>
          <RatesComparisonTable
            contractId="4"
            apiUrl='https://312458b5-df28-4b21-ba38-1a4e2a44a667.mock.pstmn.io/'
          />
        </ThemeProvider>
      </MainPage>
    </div>
  );
};

export default RatesProgramsCompare;
