import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

// COMPONENTS
import Select from 'components/CustomSelect/CustomSelect';
import Box from 'components/Box/Box';
import Skeleton from 'components/Skeleton/Skeleton';

// ASSETS
import styles from '../../DrawerSingleModifier.module.scss';

// HELPERS
import { useEvMetadata } from 'widget/ExploreWidget/api/projectDetail.api';
import { ExploreContext } from 'widget/ExploreWidget/helpers/ExploreContext';
import { buildChargingPowerOptions, buildMakeOptions, buildModelOptions, buildYearOptions } from './FormModifierEv.helpers';
import InputLabel from 'components/InputLabel/InputLabel';
import Slider from 'components/Slider/Slider';
import { buildTimeOptions } from 'utils';

const FormModifierEv = () => {
  const { register, formState: { errors }, watch } = useFormContext();
  const { state, dispatch } = useContext(ExploreContext);

  const { data: evMetadata, isLoading: isLoadingEvMetadata } = useEvMetadata({
    enabled: state.openSingleModifier === 'ev'
  });

  // RENDERS
  const makeField = register('make');
  const modelField = register('model');
  const yearField = register('year');
  const weekdayDistanceField = register('weekdayDistance');
  const weekendDistanceField = register('weekendDistance');
  const chargingTimeField = register('chargingStartTime');
  const chargingPowerField = register('chargingPower');

  const renderFieldLoading = () => {
    return (
      <div>
        <Box mb={1}>
          <Skeleton width={100} height={18} />
        </Box>
        <Skeleton width="100%" height={42} />
      </div>
    );
  };

  return (
    <div>
      <Box mb={3}>
        {!isLoadingEvMetadata
          ? (
            <Select
              label="Make"
              options={evMetadata ? buildMakeOptions(evMetadata.evVehicles) : []}
              maxHeightList={300}
              inputRef={makeField.ref}
              error={errors.make ? Boolean(errors.make.message) : undefined}
              errorMessage={errors.make?.message as string}
              value={watch('make')}
              onChange={(_, value: any) => makeField.onChange({ target: { value, name: makeField.name } })}
            />
          )
          : (
            renderFieldLoading()
          )}
      </Box>
      <Box mb={3}>
        {!isLoadingEvMetadata
          ? (
            <Select
              label="Model"
              disabled={!watch('make')}
              error={errors.model ? Boolean(errors.model.message) : undefined}
              errorMessage={errors.model?.message as string}
              options={
                evMetadata && watch('make') ? buildModelOptions(evMetadata.evVehicles, watch('make')) : []
              }
              maxHeightList={300}
              value={watch('model')}
              inputRef={modelField.ref}
              onChange={(_, value: any) => modelField.onChange({ target: { value, name: modelField.name } })}
            />
          )
          : (
            renderFieldLoading()
          )}
      </Box>
      <Box mb={3}>
        {!isLoadingEvMetadata
          ? (
            <Select
              label="Year"
              disabled={!watch('model')}
              error={errors.year ? Boolean(errors.year.message) : undefined}
              errorMessage={errors.year?.message as string}
              options={
                evMetadata && watch('make') && watch('model')
                  ? buildYearOptions(evMetadata.evVehicles, watch('make'), watch('model'))
                  : []
              }
              value={watch('year')}
              inputRef={yearField.ref}
              onChange={(_, value: any) => yearField.onChange({ target: { value, name: yearField.name } })}
            />
          )
          : (
            renderFieldLoading()
          )}
      </Box>
      <Box mb={3}>
        {!isLoadingEvMetadata
          ? (
            <>
              <Select
                label="Charging power"
                options={evMetadata ? buildChargingPowerOptions(evMetadata.chargingPowerList) : []}
                value={watch('chargingPower')}
                error={errors.chargingPower ? Boolean(errors.chargingPower.message) : undefined}
                errorMessage={errors.chargingPower?.message as string}
                inputRef={chargingPowerField.ref}
                onChange={(_, value: any) => chargingPowerField.onChange({ target: { value, name: chargingPowerField.name } })}
              />
              <p className={styles['form-description']}>
              Level 1: Plug your EV into a standard 110-volt wall outlet. Level 2: Charge your EV 4 times faster than a Level 1. Requires 240-volt wall outlet professionally installed on a dedicated circuit.
              </p>
            </>
          )
          : (
            renderFieldLoading()
          )}
      </Box>
      <Box mb={3} width="100%">
        <Box mb={1.5}>
          <InputLabel>Miles per weekday</InputLabel>
        </Box>
        <div className={styles['slider-value']}>
          <span>{watch('weekdayDistance')} miles</span>
        </div>
        <Slider
          inputRef={weekdayDistanceField.ref}
          value={Number(watch('weekdayDistance'))}
          {...weekdayDistanceField}
          min={0}
          max={200}
          color="depth"
          ariaLabel="Weekday mileage slider up to 200"
        />
        <p className={styles['form-description']}>
          Miles driven on average on a weekday.
        </p>
      </Box>
      <Box mb={3} width="100%">
        <Box mb={1.5}>
          <InputLabel>Miles per weekend day</InputLabel>
        </Box>
        <div className={styles['slider-value']}>
          <span>{watch('weekendDistance')} miles</span>
        </div>
        <Slider
          inputRef={weekendDistanceField.ref}
          value={Number(watch('weekendDistance'))}
          {...weekendDistanceField}
          min={0}
          max={40}
          color="depth"
          ariaLabel="Weekend mileage slider up to 40"
        />
        <p className={styles['form-description']}>
          Miles driven on average on a weekend day.
        </p>
      </Box>
      <Box mb={3}>
        {!isLoadingEvMetadata
          ? (
            <>
              <Select
                label="Charging time"
                options={buildTimeOptions()}
                maxHeightList={200}
                value={watch('chargingStartTime')}
                error={errors.chargingStartTime ? Boolean(errors.chargingStartTime.message) : undefined}
                errorMessage={errors.chargingStartTime?.message as string}
                inputRef={chargingTimeField.ref}
                onChange={(_, value: any) => chargingTimeField.onChange({ target: { value, name: chargingTimeField.name } })}
              />
              <p className={styles['form-description']}>
                The time it takes to charge an electric car can be as little as 30 minutes or more than 12 hours. This depends on the size of the battery and the speed of the charging point.
              </p>
            </>
          )
          : (
            renderFieldLoading()
          )}
      </Box>
    </div>
  );
};

export default FormModifierEv;
