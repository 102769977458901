import { ICustomerData } from '../../../../../../api/analyze/analyze.typings';
import { Color, ColorCode } from '../../../../../../hooks/Theme/Theme.model';

import colors from '../../../../../../style/colors';

export const calculateRates = (customers: Array<ICustomerData>) => {
  return customers.reduce((accumulator: any, currentValue) => {
    if (currentValue.programDistribution in accumulator) {
      accumulator[currentValue.programDistribution] += currentValue.annualCost;
    } else {
      accumulator[currentValue.programDistribution] = currentValue.annualCost;
    }
    return accumulator;
  }, {});
};

export const getCustomColors = (key: string, getThemeColor: Function) => {
  switch (key) {
  case 'Program 1':
    return getThemeColor(Color.Quaternary, ColorCode.C100, colors.ember);
  case 'Program 2':
    return getThemeColor(Color.Quaternary, ColorCode.C200, colors.energy);
  case 'Program 3':
    return getThemeColor(Color.Quaternary, ColorCode.C300, colors.amp);
  case 'Program 4':
    return getThemeColor(Color.Quinary, ColorCode.C100, colors.ray);
  case 'Program 5':
    return getThemeColor(Color.Tertiary, ColorCode.C200, colors.fruit);
  case 'Program 6':
    return getThemeColor(Color.Tertiary, ColorCode.C100, colors.temper);
  case 'Program 7':
    return getThemeColor(Color.Quinary, ColorCode.C200, colors.relax);
  case 'Program 8':
    return getThemeColor(Color.Quinary, ColorCode.C300, colors.pale);
  default:
    return '#333';
  }
};

export const transformData = (customers: Array<ICustomerData>, getThemeColor: Function) => {
  return Object.entries(calculateRates(customers)).map(([key, value]) => {
    return {
      name: key,
      data: [value as number],
      color: getCustomColors(key, getThemeColor)
    };
  });
};
