import { SelectRootSlotProps } from '@mui/base/Select';
import React from 'react';

// COMPONENTS
import Icon from '../../../Icon/Icon';

// ASSETS
import styles from './BaseRoot.module.scss';

interface CustomSelectRootSlotProps<TValue extends {}, Multiple extends boolean>
  extends SelectRootSlotProps<TValue, Multiple> {
  placeholder?: string;
}

const BaseRoot = React.forwardRef(function Button<
  TValue extends {},
  Multiple extends boolean,
> (
  props: CustomSelectRootSlotProps<TValue, Multiple>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const { ownerState, className, placeholder, ...other } = props;

  return (
    <button className={`${styles['base-root']} ${className}`} type="button" {...other} ref={ref}>
      {(placeholder && !ownerState.value) && <span>{placeholder}</span>}
      {other.children}
      <Icon.ChevronDown size={18} />
    </button>
  );
});

export default BaseRoot;
