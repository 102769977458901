import { FilterItem } from './store.typings';

function setFilter<T> (stateFilters: FilterItem<T>[], payload: any) {
  const selectedFilter = stateFilters.find(filter => filter.key === payload.key);

  if (payload.type === 'daterange' || payload.type === 'radio') {
    if (selectedFilter) {
      stateFilters = stateFilters.map(filter => {
        if (filter.key === payload.key) {
          return {
            ...filter,
            value: payload.value
          };
        }
        return filter;
      });
    } else {
      stateFilters.push({
        key: payload.key,
        type: payload.type,
        value: payload.value
      });
    }
  } else {
    if (selectedFilter) {
      if (selectedFilter.value.includes(payload.value)) return;
      selectedFilter?.value.push(payload.value);
    } else {
      stateFilters.push({
        key: payload.key,
        value: [payload.value]
      });
    }
  }
}

export default setFilter;
