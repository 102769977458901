import React, { useState, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import moment from 'moment-timezone';

import useMediaQuery, { breakpoints } from 'hooks/useMediaQuery';

import { ThemeProvider } from 'hooks/Theme/Theme.context';
import { pgeTheme } from '../../hooks/Theme/Theme.config';

import Text from '../../components/Text/Text';
import Select from 'components/CustomSelect/CustomSelect';

import './real-time-hourly-pricing.scss';

import {
  fetchRealTimeDataByRange,
  rates
} from './RealTimeData.helpers';
import { HourlyRates } from './HourlyRates';
import { PricingBarChart } from './PricingBarChart';
import { PricingHeatMap } from './PricingHeatmap';
import { PricingExport } from './PricingExport';
import { PricingCurrentPrice } from './PricingCurrentPrice';

const queryClient = new QueryClient();

const HourlyPricing: React.FC = () => {
  const [utility, setUtility] = useState('PG&E');
  const isMobile = useMediaQuery(breakpoints.mobileMedium);
  const market = 'DAM';
  const todayDate = moment().tz('America/Los_Angeles').format('MMM DD, YYYY');
  const [selectDate, setSelectDate] = useState(todayDate);
  const dateForGraph = moment(selectDate).tz('America/Los_Angeles').format('YYYYMMDD');

  const [rate, setRate] = useState('B20P');
  const [currentTime, setCurrentTime] = useState(moment().tz('America/Los_Angeles').format('MMM Do, YYYY - h:mm'));
  const [currentHour, setCurrentHour] = useState(moment().tz('America/Los_Angeles').format('H'));
  const [meridiemTime, setMeridiemTime] = useState(moment().tz('America/Los_Angeles').format('a'));
  const headerCopy = 'Hourly flex pricing';
  const maxDate = moment(todayDate).tz('America/Los_Angeles').add(1, 'days').toDate();
  const pgeChartColors = ['#719eb1', '#5d7e8c', '#4a636d', '#aac5d0']; // PG&E BLUE TODO - replace with theme color once chart have been themed
  const chartWidth = 1440;
  let scrollBar = chartWidth;

  useEffect(() => {
    const interval = setInterval(() => {
      setMeridiemTime(moment().tz('America/Los_Angeles').format('a'));
      setCurrentTime(moment().tz('America/Los_Angeles').format('MMM Do, YYYY - h:mm'));
      setCurrentHour(moment().tz('America/Los_Angeles').format('H'));
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [meridiemTime]);

  if (isMobile) {
    scrollBar = chartWidth + 100;
  }

  const apiUrl = 'https://pge-pe-api.gridx.com/uat/v1/getPricing';

  const {
    isLoading,
    isFetched,
    isError,
    data
  } = fetchRealTimeDataByRange(apiUrl, utility, market, rate, dateForGraph, dateForGraph);

  const categories = () => {
    const hours: string[] = [];

    for (let i = 0; i < 24; i++) {
      hours.push(moment({ hour: i }).tz('America/Los_Angeles').format('h A'));
    }
    return hours;
  };

  return (
    <div className='pad--l-8 pad--r-8 pad--t-48 rthp--wrapper'>
      <div className='flex flex--align-center mar--b-40 jus--space-between width--100-p'>
        <Text
          appearance='headline-sm'
        >
          {headerCopy}
        </Text>
        <Text
          appearance='title-md'
          color='ocean'
        >
          {currentTime} <span className='uppercase'>{meridiemTime}</span>
        </Text>
      </div>
      <div className='flex pad--b-32'>
        {isMobile && (
          <div className='flex mar--t-32 width--100-p'>
            <PricingExport chartData={data} pgeChartColors={pgeChartColors} selectDate={selectDate}
              todayDate={todayDate} apiUrl={apiUrl} utility={utility} market={market} rate={rate} maxDate={maxDate} />
          </div>
        )}
        <div
          className='flex-md-qt-column'
          key='select-provider'
        >
          <Select
            label='Choose your provider'
            options={[
              { label: 'PG&E', value: 'PG&E' },
              { label: 'Peninsula Clean Energy', value: 'PCE' },
              { label: 'Ava Community Energy (EBCE)', value: 'EBCE' },
              { label: 'Silicon Valley Clean Energy', value: 'SVCE' },
              { label: 'MCE', value: 'MCE' },
              { label: 'Valley Clean Energy', value: 'VCE' },
              { label: 'San Jose Clean Energy', value: 'SJCE' },
              { label: 'Central Coast Community Energy', value: '3CE' }
            ]}
            size='sm'
            onChange={(e: any, value: any) => {
              setUtility(value);
              // set rates to utility first rate in list
              setRate(rates(value)[0].value);
            }}
            value={utility}
          />
          {!isMobile && <PricingCurrentPrice pgeChartColors={pgeChartColors} currentHour={currentHour}
            isMobile={isMobile} data={data} />}
        </div>
        <div
          className='flex-md-qt-column'
          key='select-rate'
        >
          <Select
            label='Choose your rate plan'
            options={rates(utility)}
            size='sm'
            onChange={(e: any, value: any) => setRate(value)}
            value={rate}
          />
        </div>
        {isMobile && <PricingCurrentPrice pgeChartColors={pgeChartColors} currentHour={currentHour}
          isMobile={isMobile} data={data} />}
        <div className='flex flex-md-qt-column mar--t-32'></div>

        {!isMobile && (
          <div className='flex flex-md-qt-column mar--t-32'>
            <PricingExport chartData={data} pgeChartColors={pgeChartColors} selectDate={selectDate}
              todayDate={todayDate} apiUrl={apiUrl} utility={utility} market={market} rate={rate} maxDate={maxDate} />
          </div>
        )}
      </div>
      <div className='bor--t-1-wave pad--b-32 pad--t-32'>
        <HourlyRates pgeChartColors={pgeChartColors} data={data} selectDate={selectDate}
          setSelectDate={setSelectDate} maxDate={maxDate} isLoading={isLoading} />
        <PricingBarChart data={data} pgeChartColors={pgeChartColors} isError={isError} isFetched={isFetched}
          chartWidth={chartWidth} todayDate={todayDate} selectDate={selectDate} currentHour={currentHour}
          categories={categories} scrollBar={scrollBar} />
        <PricingHeatMap apiUrl={apiUrl} utility={utility} market={market} rate={rate} scrollBar={scrollBar}
          chartWidth={chartWidth} categories={categories} selectDate={selectDate} pgeChartColors={pgeChartColors} />
      </div>
    </div>
  );
};

const RealTimeHourlyPricing = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={pgeTheme}>
        <HourlyPricing />
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default RealTimeHourlyPricing;
