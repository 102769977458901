import React from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';

// COMPONENTS
import { ExpandableSection, Button, Icon } from '../../../../components';
import Text from '../../../../components/Text/Text';
import { FilterControl, FilterPanel } from '../../../../blocks/components';
import { FilterAccountType, GraphAccounts, GraphBestRates, GraphPrograms, GraphQuality, GraphRates, GraphSavingPotential } from './components';
import { ListCustomers, FilterSearchAnalyze } from '../../components';

// SELECTORS
import { getFiltersSearch, getIsFilterOpen } from '../../../../store/modules/analyze/analyze.selector';

// ASSETS
import styles from './FullPopulation.module.scss';

// API
import { usePopulation } from '../../../../api/hooks';
import { toogleFilter } from 'store/modules/analyze/analyze.slice';

const FullPopulation: React.FC = () => {
  const dispatch = useDispatch();
  const isFilterOpen = useSelector(getIsFilterOpen);
  const { filters, searchString } = useSelector(getFiltersSearch);
  const { data: customerData, isLoading } = usePopulation(filters, searchString);

  // STYLES
  const cssInsightsGraphs = classnames(styles['insights-graphs'], {
    [styles.narrow]: isFilterOpen
  });

  return (
    <div className={`${styles['analyze-page']} admin--page pad--t-128 pad--l-128 pad--r-36`} role="main">
      <div className="pad--b-24">
        <Text className='title--md' variant='h2'>
          Full population
        </Text>
      </div>
      <div className={styles['actions-bar']}>
        <FilterSearchAnalyze/>
        <div>
          <Button
            copy="Export"
            icon={<Icon.Download />}
            iconPosition="left"
            size='md'
            type='secondary'
            aria-label="Export Data"
          />
        </div>
      </div>
      <div className={styles.main}>
        <AnimatePresence>
          {isFilterOpen && (
            <motion.div
              initial={{ x: '-100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            >
              <FilterPanel aria-label="Filter Population" onClose={() => dispatch(toogleFilter())}>
                <FilterAccountType selectedFilters={filters} />
                <FilterControl title="Rate" />
                <FilterControl title="Program" />
                <FilterControl title="Savings potential" />
                <FilterControl title="Best rate" />
              </FilterPanel>
            </motion.div>
          )}
        </AnimatePresence>
        <div className={styles.content}>
          {
            (!isLoading && customerData) && (
              <>
                <div>
                  <ExpandableSection title="Insights" expanded icon={<Icon.BarChart size={18} />}>
                    <div className={cssInsightsGraphs}>
                      <GraphAccounts customers={customerData} aria-label="Graph of Accounts" />
                      <GraphRates customers={customerData} aria-label="Graph of Rates" />
                      <GraphPrograms customers={customerData} aria-label="Graph of Programs" />
                      <GraphSavingPotential customers={customerData} aria-label="Graph of Saving Potential" />
                      <GraphBestRates customers={customerData} aria-label="Graph of Best Rates" />
                      <GraphQuality customers={customerData} aria-label="Graph of Quality" />
                    </div>
                  </ExpandableSection>
                </div>
                <ListCustomers customers={customerData} aria-label="List of Customers" />
              </>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default FullPopulation;
