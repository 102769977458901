import React from 'react';
import classnames from 'classnames';

// ASSETS
import styles from './Table.module.scss';

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(
  // eslint-disable-next-line react/prop-types
  ({ className, ...props }, ref) => (
    <div className={styles['table-container']}>
      <table
        ref={ref}
        className={classnames(styles.table, className)}
        {...props}
      />
    </div>
  ));

Table.displayName = 'Table';

export default Table;
