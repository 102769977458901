import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import { Drawer, Box, Button, DatePicker, Icon } from 'components';
import TextInput from 'components/TextInput/TextInput';

// SELECTORS
import { getIsAccountSettingsOpen } from 'store/modules/accounts/accounts.selector';

// ACTIONS
import { setIsAccountSettingsOpen } from 'store/modules/accounts/accounts.slice';

// ASSETS
import styles from './AccountSettingsDrawer.module.scss';

// HELPERS
import { IAccount } from 'api/accounts/accounts.typings';

interface AccountSettingsDrawerProps {
  account: IAccount;
}

const AccountSettingsDrawer:React.FC<AccountSettingsDrawerProps> = ({ account }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsAccountSettingsOpen);

  // EVENTS
  const handleClose = () => {
    dispatch(setIsAccountSettingsOpen(false));
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      size="md"
      onClose={handleClose}
      title="Account Settings"
    >
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" columnGap={1.5}>
        <TextInput label="Service Agreement" defaultValue={account.serviceAgreement} size="md" />
      </Box>
      <div className={styles.horizontalDivider} />
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" columnGap={1.5} rowGap={3}>
        <Box zIndex={12}>
          <DatePicker
            label="Service Start"
            size='md'
            format='y-MM-dd'
            dayPlaceholder='Datepicker'
            calendarIcon={<Icon.Calendar />}
            isRange={false}
            value={new Date('2023-03-02')}
            clearIcon={null}
          />
        </Box>
        <Box zIndex={12}>
          <DatePicker
            label="Service End"
            size='md'
            format='y-MM-dd'
            dayPlaceholder='Datepicker'
            calendarIcon={<Icon.Calendar />}
            isRange={false}
            clearIcon={null}
          />
        </Box>
        <DatePicker
          label="Bill Cycle Start"
          size='md'
          format='y-MM-dd'
          dayPlaceholder='Datepicker'
          calendarIcon={<Icon.Calendar />}
          isRange={false}
          clearIcon={null}
          value={new Date('2023-03-02')}
        />
        <DatePicker
          label="Bill Cycle End"
          size='md'
          format='y-MM-dd'
          dayPlaceholder='Datepicker'
          calendarIcon={<Icon.Calendar />}
          isRange={false}
          clearIcon={null}
          value={new Date('2023-05-02')}
        />
      </Box>
      <div className={styles.horizontalDivider} />
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" columnGap={1.5} rowGap={3} mb={3}>
        <TextInput label="Meter #" defaultValue="123456789" size="md" />
        <TextInput label="Site Premise ID" defaultValue="123456789" size="md" />
      </Box>
      <TextInput label="Billing Account" defaultValue="123456789" size="md" />
      <div className={styles.horizontalDivider} />
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" columnGap={1.5} rowGap={3}>
        <TextInput label="Contact Name" defaultValue="Joseph Chang" size="md" />
        <TextInput label="Contact Phone" defaultValue="(709) 234-2345" size="md" />
        <TextInput label="Contact Email" defaultValue="joe.chang@centralfloridaelectric.com" size="md" />
        <TextInput label="Contact ID" defaultValue="1284893094" size="md" />
      </Box>
      <div className={styles.horizontalDivider} />
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" columnGap={1.5} rowGap={3}>
        <TextInput label="Billing Address" defaultValue="1000 South Road" size="md" />
        <TextInput label="Billing Address 2" defaultValue="" size="md" />
        <TextInput label="City" defaultValue="Westport" size="md" />
        <TextInput label="State" defaultValue="MA" size="md" />
        <TextInput label="Zip Code" defaultValue="55544" size="md" />
      </Box>
      <div className={styles.horizontalDivider} />
      <TextInput label="Comment" defaultValue="55544" size="md" multiline rows={3} />
      <Box textAlign="right" mb={6} mt={3} display="flex" justifyContent="space-between">
        <div>
          <Button copy="Archive" type="secondary" size="md" onClick={handleClose} />
        </div>
        <Box display="flex">
          <Box mr={1.5}>
            <Button copy="Cancel" type="secondary" size="md" onClick={handleClose} />
          </Box>
          <Button copy="Save changes" color="depth" type="primary" size="md" onClick={handleClose} />
        </Box>
      </Box>
    </Drawer>
  );
};

export default AccountSettingsDrawer;
