import React from 'react';
import classnames from 'classnames';

// ASSETS
import styles from './TableRow.module.scss';

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(
  // eslint-disable-next-line react/prop-types
  ({ className, ...props }, ref) => (
    <tr
      ref={ref}
      className={classnames(
        styles['table-row'],
        className
      )}
      {...props}
    />
  ));

TableRow.displayName = 'TableRow';

export default TableRow;
