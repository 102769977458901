import { ICustomerData } from '../../../../../../api/analyze/analyze.typings';
import { Color, ColorCode } from '../../../../../../hooks/Theme/Theme.model';

import colors from '../../../../../../style/colors';

export const calculateRates = (customers: Array<ICustomerData>) => {
  return customers.reduce((accumulator: any, currentValue) => {
    if (currentValue.bestRate in accumulator) {
      accumulator[currentValue.bestRate] += currentValue.annualCost;
    } else {
      accumulator[currentValue.bestRate] = currentValue.annualCost;
    }
    return accumulator;
  }, {});
};

export const getCustomColors = (key: string, getThemeColor: Function) => {
  switch (key) {
  case 'A-Rate':
    return getThemeColor(Color.Quinary, ColorCode.C100, colors.pine);
  case 'B-Rate':
    return getThemeColor(Color.Quaternary, ColorCode.C100, colors.mangrove);
  case 'C-Rate':
    return getThemeColor(Color.Tertiary, ColorCode.C100, colors.moss);
  case 'D-Rate':
    return getThemeColor(Color.Secondary, ColorCode.C100, colors.pollen);
  case 'E-Rate':
    return getThemeColor(Color.Primary, ColorCode.C100, colors.brightLow);
  case 'F-Rate':
    return getThemeColor(Color.Tertiary, ColorCode.C200, colors.watt);
  case 'G-Rate':
    return getThemeColor(Color.Quaternary, ColorCode.C200, colors.lumen);
  case 'H-Rate':
    return getThemeColor(Color.Secondary, ColorCode.C200, colors.beacon);
  default:
    return '#333';
  }
};

export const transformData = (customers: Array<ICustomerData>, getThemeColor: Function) => {
  return Object.entries(calculateRates(customers)).map(([key, value]) => {
    return {
      name: key,
      data: [value as number],
      color: getCustomColors(key, getThemeColor)
    };
  });
};
