import React from 'react';

import Text from '../Text/Text';

import './header.scss';

type Props = {
  className?: string,
  children?: React.ReactNode,
  color: string,
  text: string,
};

const Header: React.FC<Props> = ({ className, children, color, text }: Props) => (
  <header
    className={`${className} header ${color} flex flex--align-items-center`}
  >
    <div
      className='dis--block mar--t-40'
    >
      <Text
        className='display-heading--sm'
        color='white'
        variant='h1'
      >
        {text}
      </Text>
      {children}
    </div>
  </header>
);

export default Header;
