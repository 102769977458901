import React from 'react';
import classnames from 'classnames';
import MuiSkeleton from '@mui/material/Skeleton';

// ASSETS
import styles from './Skeleton.module.scss';

interface SkeletonProps {
  width?: number | string;
  height?: number | string;
  variant?: 'text' | 'rectangular' | 'circular';
  classes?: string;
  strong?: boolean;
  children?: React.ReactNode;
  animation?: 'pulse' | 'wave' | false;
}

const Skeleton:React.FC<SkeletonProps> = ({
  children,
  width,
  height,
  classes,
  variant = 'rectangular',
  strong = false,
  animation = 'wave'
}) => {
  const cssRoot = classnames(styles.root, classes, {
    [styles.strong]: strong
  });

  return (
    <MuiSkeleton
      classes={{
        root: cssRoot
      }}
      variant={variant}
      animation={animation}
      width={width}
      height={height}
    >
      {children}
    </MuiSkeleton>
  );
};

export default Skeleton;
