import React from 'react';

// COMPONENTS
import { Tabs } from 'components';
import Mapping from '../Mapping/Mapping';
import Devices from '../Devices/Devices';
import Usage from '../Usage/Usage';
import Events from '../Events/Events';

const MeterAccountTabs = () => {
  const tabs = [
    {
      content: (
        <Mapping/>
      ),
      label: 'Mapping'
    },
    {
      content: (
        <Devices />
      ),
      label: 'Devices'
    },
    {
      content: (
        <Usage />
      ),
      label: 'Usage'
    },
    {
      content: (
        <Events />
      ),
      label: 'Events'
    }
  ];

  return (
    <Tabs tabs={tabs} />
  );
};

export default MeterAccountTabs;
