import React, { Fragment, useState } from 'react';

import Button from '../../components/Button/Button';
import Header from '../../components/Header/Header';
import Link from '../../components/Link/Link';
import SideSheet from '../../components/SideSheet/SideSheet';
import Text from '../../components/Text/Text';

// import './side-sheet-page.scss';

interface Props {
    className?: string,
};

const SideSheetPage: React.FC<Props> = () => {
  const [sheetSize, updateSheeSide] = useState('');

  const handleOpenSheet = (size: string) => {
    updateSheeSide(size);
  };
  return (
    <Fragment>
      <Header
        className='design-studio pad--b-100 pad--l-128 pad--t-100'
        color='stone'
        text='Side Sheet'
      >
        <Text
          className='max-width--900 pad--t-40 title--sm'
          color='white'
          variant='body1'
        >
            A sheet is a temporary workspace that allows users to complete tasks without navigating to a new page. Side sheets are anchored to the left or right edge of the screen. They are a fixed width and typically span the height of the screen. Their dimensions depend on how the app’s layout is subdivided into UI regions.
        </Text>
        <Link
          className='underline dis--block mar--t-60 body--xl'
          color='white'
          copy='See Our Guidelines'
          href='/'
        />
      </Header>
      <div
        className='mar--l-96 mar--t-80 max-width--lg temp--min-width--md'
      >
        <div
          className='pad--b-80'
        >
            Sizes

          <Button
            copy='Open sheet xs'
            color="depth"
            size='lg'
            onClick={() => handleOpenSheet('xs')}
          />
          <Button
            copy='Open sheet xs'
            color="depth"
            size='lg'
            onClick={() => handleOpenSheet('sm')}
          />
          <Button
            copy='Open sheet xs'
            color="depth"
            size='lg'
            onClick={() => handleOpenSheet('md')}
          />
          <Button
            copy='Open sheet lg'
            color="depth"
            size='lg'
            onClick={() => handleOpenSheet('lg')}
          />
          <Button
            copy='Open sheet full'
            color="depth"
            size='lg'
            onClick={() => handleOpenSheet('full')}
          />
          <SideSheet
            className=''
            size={sheetSize}
          >
            My side sheet
          </SideSheet>
        </div>
      </div>
    </Fragment>
  );
};

export default SideSheetPage;
