import React, { useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';

// COMPONENTS
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import ModifierItem from 'widget/ExploreWidget/components/ModifierItem/ModifierItem';
import FooterNavigation from 'widget/ExploreWidget/components/FooterNavigation/FooterNavigation';
import DrawerModifiers from 'widget/ExploreWidget/components/DrawerModifiers/DrawerModifiers';
import ModifiersIcons from './components/ModifiersIcons/ModifiersIcons';
import LoadingLongCostEstimates from '../ProjectDetails/components/LoadingLongCostEstimates/LoadingLongCostEstimates';

// ASSETS
import styles from './ConfigurationPage.module.scss';

// HELPERS
import { ActionTypes, ExploreContext } from 'widget/ExploreWidget/helpers/ExploreContext';
import useProjectData from 'widget/ExploreWidget/hooks/useProjectData';
import { getModifierIcon, getModifierImage, getModifierKey, getModifierTags, getModifierTitle } from 'widget/ExploreWidget/helpers/utils/getModifierIcon';
import { Resource } from 'widget/ExploreWidget/api/projects.typings';

const ConfigurationPage = () => {
  const { state, dispatch } = useContext(ExploreContext);
  const queryClient = useQueryClient();
  // QUERIES
  const {
    isFetching,
    isLoading,
    isError,
    data: project
  } = useProjectData();

  // MISC
  const projectID = Number(new URLSearchParams(location.search).get('projectID')) || undefined;
  const projectMode = state.projectMode;
  const appliedResource = state.appliedResource || [];

  // EVENTS
  const handleAddModifiers = () => {
    dispatch({ type: 'TOGGLE_ENERGY_RESOURCES', payload: '' });
  };

  const handleRemoveResource = (resource: any) => {
    const updatedResource = appliedResource.filter(item => item !== resource);
    dispatch({ type: ActionTypes.SET_APPLIED_RESOURCE, payload: [...updatedResource] });
    dispatch({ type: ActionTypes.REFRESH_COST_SAVINGS, payload: true });
    queryClient.removeQueries(['compare-calculate']);
  };

  // RENDERS
  const renderModifiers = () => {
    if (projectMode === 'NEW' && appliedResource.length === 0) {
      if (isLoading || isError) return <LoadingLongCostEstimates/>;
      return <ModifiersIcons/>;
    }

    if (projectMode === 'EDIT' && (isLoading || isFetching || !projectID)) { return <LoadingLongCostEstimates/>; }

    return (
      <div className={styles.modifiers}>
        {
          appliedResource.map((resource: Resource, index) => {
            return (
              <ModifierItem
                key={resource.id || index}
                title={getModifierTitle(getModifierKey(resource.name))}
                icon={{ src: getModifierIcon(getModifierKey(resource.name)), alt: `${resource.name} modifier icon` }}
                image={{ src: getModifierImage(getModifierKey(resource.name)), alt: `${resource.name} modifier image` }}
                onRemoveItem={() => handleRemoveResource(resource)}
                tags={getModifierTags(resource)}
                selected
              />
            );
          })
        }
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <p className={styles.description}>Click “Add clean energy technologies” and select the clean energy technologies you are considering.</p>
      {renderModifiers()}
      <div className={styles.addModifier}>
        <Button
          copy='Add clean energy technologies'
          icon={<Icon.Plus />}
          iconPosition="left"
          onClick={handleAddModifiers}
          size="md"
          color="secondary"
        />
      </div>
      <FooterNavigation
        description="Click “Save & continue” to name your calculation and get electric cost estimates based on your previous 12-months of usage"
      />
      <DrawerModifiers rateCode="asdsad" resourceItem="" onRemoveItem={() => {}}/>
    </div>
  );
};

export default ConfigurationPage;
