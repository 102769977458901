import React from 'react';

// COMPONENTS
import { Select } from 'components';
import UsageYearSelection from '../UsageYearSelection/UsageYearSelection';

// ASSETS
import styles from './UsagePeriodSelection.module.scss';

interface UsagePeriodSelectionProps {
  data: any
}

const UsagePeriodSelection:React.FC<UsagePeriodSelectionProps> = ({ data }) => {
  const option = data.list.map((item: any) => {
    return {
      value: item.id,
      label: `${item.start + ' - ' + item.end + ' - Bill ID ' + item.id}`
    };
  });

  return (
    <div className={styles.container}>
      <UsageYearSelection data={data}/>
      <Select placeholder='Select a billing period' options={option} />
    </div>
  );
};

export default UsagePeriodSelection;
