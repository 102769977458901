import React from 'react';

// COMPONENTS
import ImplementationItem from 'widget/ExploreWidget/components/ImplementationItem/ImplementationItem';
import Button from 'components/Button/Button';

// ASSETS
import styles from '../../ImplementationPage.module.scss';
import { Resource } from 'widget/ExploreWidget/api/projects.typings';

// HELPERS
import { getModifierTags } from 'widget/ExploreWidget/helpers/utils/getModifierIcon';

interface RangeImplementationProps {
  modifier: Resource;
}

const RangeImplementation:React.FC<RangeImplementationProps> = ({ modifier }) => {
  return (
    <ImplementationItem
      title="Electric range"
      type='modifier'
      modifier="range"
      tags={getModifierTags(modifier)}
      footer={
        <div className={styles['content-footer']}>
          <Button
            copy="Button"
            variant="outlined"
            color="secondary"
          />
        </div>
      }
    >
      <div className={styles['content-quote']}>
        <div className={styles['content-text']}>
          <p className={styles['content-title']}>Norem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <p className={styles['content-description']}>
                Yorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus.
          </p>
        </div>
        <div className={styles['content-text']}>
          <p className={styles['content-title']}>Norem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <p className={styles['content-description']}>
                Yorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus.
          </p>
        </div>
      </div>
    </ImplementationItem>
  );
};

export default RangeImplementation;
