import { useContext } from 'react';

// COMPONENTS
import FormModifierSolar from './components/FormModifierSolar/FormModifierSolar';
import FormBatteryStorage from './components/FormBatteryStorage/FormBatteryStorage';
import FormHeatPump from './components/FormHeatPump/FormHeatPump';
import FormWaterHeater from './components/FormWaterHeater/FormWaterHeater';

// HELPERS
import { ExploreContext } from 'widget/ExploreWidget/helpers/ExploreContext';
import { getModifierIcon, getModifierImage } from 'widget/ExploreWidget/helpers/utils/getModifierIcon';
import FormElectricDryer from './components/FormElectricDryer/FormElectricDryer';
import FormElectricRange from './components/FormElectricRange/FormElectricRange';
import FormModifierEv from './components/FormModifierEv/FormModifierEv';

export const useSingleModifier = () => {
  const { state, dispatch } = useContext(ExploreContext);

  let ModifierForm;
  let title = '';
  let description = '';

  switch (state.openSingleModifier) {
  case 'solar':
    ModifierForm = FormModifierSolar;
    title = 'Solar panels';
    description = 'Power your home with rooftop solar. Receive a bill credit for the electricity you don’t use.';
    break;
  case 'ev':
    ModifierForm = FormModifierEv;
    title = 'Electric vehicle';
    description = 'Save on driving costs with an electric vehicle. Charge at home when prices are lowest.';
    break;
  case 'battery':
    ModifierForm = FormBatteryStorage;
    title = 'Battery storage';
    description = 'When combined with a renewable energy source, battery storage can power your home when needed. You can also save money by using stored energy when rates are higher during peak hours of the day.';
    break;
  case 'heatPump':
    ModifierForm = FormHeatPump;
    title = 'HVAC heat pump';
    description = 'Electric heat pumps are 4 times more efficient at heating your home and can be a 2-for-1 replacement for your heating and AC units.';
    break;
  case 'waterHeater':
    ModifierForm = FormWaterHeater;
    title = 'Heat pump water header';
    description = 'This technology is 3 to 4 times more efficient than gas water heaters.';
    break;
  case 'dryer':
    ModifierForm = FormElectricDryer;
    title = 'Electric dryer';
    description = 'Energy Star® dryers use about 20% less energy than conventional dryer models.';
    break;
  case 'range':
    ModifierForm = FormElectricRange;
    title = 'Electric range';
    description = 'Electric resistance stoves are more energy-efficient than natural gas ranges. Electric resistance stoves are 75% efficient while gas stoves are 40% efficient.';
  }

  return {
    isOpen: Boolean(state.openSingleModifier),
    title,
    description,
    icon: state.openSingleModifier && getModifierIcon(state.openSingleModifier),
    image: state.openSingleModifier && getModifierImage(state.openSingleModifier),
    ModifierForm
  };
};

export const getDefaultValues = (modifier: string | null) => {
  switch (modifier) {
  case 'solar':
    return {
      zipcode: '10011',
      capacityField: 0,
      module_type: '0',
      array_type: '1',
      losses: '14.00',
      tilt: '38.00',
      azimuth: '180',
      orientationField: 'South'
    };
  case 'ev':
    return {};
  case 'battery':
    return {
      capacityField: 0,
      resiliencyField: 4
    };
  case 'heatPump':
    return {
      homeYearField: '1970s',
      homeSizeField: 1800,
      heatingType: 'Gas Heating and Electric A/C'
    };
  case 'waterHeater':
    return {
      occupantsField: 4,
      waterHeaterField: 'Natural gas'
    };
  case 'dryer':
    return {
      dryerTypeField: 'Electric',
      occupantsField: 4,
      compareToField: 'Natural Gas'
    };
  case 'range':
    return {
      rangeTypeField: 'Electric',
      occupantsField: 2,
      compareToField: 'Natural Gas'
    };
  }
};
