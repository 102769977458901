import React, { Fragment } from 'react';

import Header from '../../components/Header/Header';
import Color from '../../components/Color/Color';
import Link from '../../components/Link/Link';
import Text from '../../components/Text/Text';

interface Props {
    color?: string,
    copy?: string | null
    hex?: string | null
    textColor?: string | null
};

const ColorPage: React.FC = () => {
  const renderColor: React.FC<Props> = ({
    color = 'magma',
    copy = null,
    hex = null,
    textColor = null
  }) => {
    return (
      <Color
        color={color}
        copy={copy}
        hex={hex}
        textColor={textColor}
      />
    );
  };

  return (
    <Fragment>
      <Header
        className='design-studio pad--b-156 pad--l-128 pad--t-156'
        color='stone'
        text='Colors'
      >
        <Text
          className='max-width--900 pad--t-40 title--sm'
          color='white'
          variant='body1'
        >
            Unified color matching improves brand recognition and also achieves the purpose of information transmission, interactive feedback and so on.
        </Text>
        <Link
          className='underline dis--block mar--t-60 body--xl'
          color='white'
          copy='See Our Guidelines'
          href='/'
        />
      </Header>
      <div
        className='mar--l-96 mar--t-80 max-width--xl temp--min-width--md'
      >
        <div className='mar--b-80'>
          <Text
            className='body--lead'
          >
            <b>Brand + Product Palette Extension</b>
          </Text>
        </div>
        <div className='pad--b-80'>
          <section className='mar--b-156'>
            <div className='flex mar--b-20'>Energy</div>
            <div className='flex'>
              {renderColor({ color: 'magma', copy: 'Magma', hex: '#F18A08' })}
              {renderColor({ color: 'spark', copy: '*New* Spark', hex: '#FF9716' })}
              {renderColor({ color: 'energy', copy: 'Energy', hex: '#FFA44F' })}
              {renderColor({ color: 'amp', copy: 'Amp', hex: '#FFC085' })}
              {renderColor({ color: 'burst', copy: 'Burst', hex: '#FFD5AD' })}
              {renderColor({ color: 'ray', copy: 'Ray', hex: '#FEE8CD' })}
              {renderColor({ color: 'silt', copy: 'Silt', hex: '#FBF5E9' })}
            </div>
          </section>
          <section className='mar--b-156'>
            <div className='flex mar--b-20'>Ocean</div>
            <div className='flex'>
              {renderColor({ color: 'stone', copy: 'Stone', hex: '#344145', textColor: 'white' })}
              {renderColor({ color: 'deep', copy: 'Deep', hex: '#4A636D', textColor: 'white' })}
              {renderColor({ color: 'ocean', copy: 'Ocean', hex: '#5E7E8B', textColor: 'white' })}
              {renderColor({ color: 'storm', copy: 'Storm', hex: '#7998A4', textColor: 'white' })}
              {renderColor({ color: 'surf', copy: 'Surf', hex: '#A7C6CD' })}
              {renderColor({ color: 'wave', copy: 'Wave', hex: '#D4E9EC' })}
              {renderColor({ color: 'mist', copy: 'Mist', hex: '#E4F0F2' })}
            </div>
          </section>
          <section className='mar--b-156'>
            <div className='flex mar--b-20'>Earth</div>
            <div className='flex'>
              {renderColor({ color: 'earth', copy: 'Earth', hex: '#928877', textColor: 'white' })}
              {renderColor({ color: 'root-color', copy: 'Root', hex: '#575348', textColor: 'white' })}
              {renderColor({ color: 'aspen', copy: 'Aspen', hex: '#EFECE5' })}
            </div>
          </section>
          <section className='mar--b-156'>
            <div className='flex mar--b-20'>Algae</div>
            <div className='flex'>
              {renderColor({ color: 'algae', copy: 'Algae', hex: '#662940', textColor: 'white' })}
              {renderColor({ color: 'anemone', copy: 'Anemone', hex: '#410F2F', textColor: 'white' })}
              {renderColor({ color: 'coral', copy: 'Coral', hex: '#D1C2C3' })}
            </div>
          </section>
          <section className='mar--b-156'>
            <div className='flex mar--b-20'>Clean</div>
            <div className='flex'>
              {renderColor({ color: 'altitude', copy: 'Altitude', hex: '#15448E', textColor: 'white' })}
              {renderColor({ color: 'blue', copy: 'Blue', hex: '#1D5DC3', textColor: 'white' })}
              {renderColor({ color: 'sky', copy: 'Sky', hex: '#3979E2', textColor: 'white' })}
              {renderColor({ color: 'clean', copy: 'Clean', hex: '#5293FF', textColor: 'white' })}
              {renderColor({ color: 'wind', copy: 'Wind', hex: '#85B4FF' })}
              {renderColor({ color: 'air', copy: 'Air', hex: '#ADCDFF' })}
              {renderColor({ color: 'cloud', copy: 'Cloud', hex: '#E6F0FF' })}
            </div>
          </section>
          <section className='mar--b-156'>
            <div className='flex mar--b-20'>Mangrove</div>
            <div className='flex'>
              {renderColor({ color: 'pine', copy: 'Pine', hex: '#007156', textColor: 'white' })}
              {renderColor({ color: 'stem', copy: 'Stem', hex: '#009C77', textColor: 'white' })}
              {renderColor({ color: 'vine', copy: 'Vine', hex: '#00B88C' })}
              {renderColor({ color: 'mangrove', copy: 'Mangrove', hex: '#23CF9F' })}
              {renderColor({ color: 'moss', copy: 'Moss', hex: '#51E7BA' })}
              {renderColor({ color: 'spore', copy: 'Spore', hex: '#90F5D1' })}
              {renderColor({ color: 'pollen', copy: 'Pollen', hex: '#CCFFED' })}
            </div>
          </section>
          <section className='mar--b-156'>
            <div className='flex mar--b-20'>Bright</div>
            <div className='flex'>
              {renderColor({ color: 'low-vis', copy: 'Low Vis', hex: '#A38000', textColor: 'white' })}
              {renderColor({ color: 'dwindle', copy: 'Dwindle', hex: '#D3A60' })}
              {renderColor({ color: 'watt', copy: 'Watt', hex: '#F1BD05' })}
              {renderColor({ color: 'glow', copy: 'Glow', hex: '#FFD027' })}
              {renderColor({ color: 'bright', copy: 'Bright', hex: '#FFD027' })}
              {renderColor({ color: 'lumen', copy: 'Lumen', hex: '#FBE288' })}
              {renderColor({ color: 'beacon', copy: 'Beacon', hex: '#CCFFED' })}
            </div>
          </section>
          <section className='mar--b-156'>
            <div className='flex'>
              {renderColor({ color: 'reaction', copy: 'Reaction', hex: '#E84855', textColor: 'white' })}
              {renderColor({ color: 'gray', copy: 'Gray', hex: '#888888' })}
              {renderColor({ color: 'white', copy: 'White', hex: '#FFFFFF' })}
            </div>
          </section>
          <section className='mar--b-156'>
            <div className='flex mar--b-20'>Reaction</div>
            <div className='flex'>
              {renderColor({ color: 'fruit', copy: 'Fruit', hex: '#007156', textColor: 'white' })}
              {renderColor({ color: 'heat', copy: 'Heat', hex: '#009C77', textColor: 'white' })}
              {renderColor({ color: 'reaction', copy: 'Reaction', hex: '#E84855', textColor: 'white' })}
              {renderColor({ color: 'temper', copy: 'Temper', hex: '#23CF9F', textColor: 'white' })}
              {renderColor({ color: 'mauvelous', copy: 'Mauvelous', hex: '#51E7BA' })}
              {renderColor({ color: 'relax', copy: 'Relax', hex: '#90F5D1' })}
              {renderColor({ color: 'pale', copy: 'Pale', hex: '#CCFFED' })}
            </div>
          </section>
          <section className='pad--b-156 bor--b-1-ocean'>
            <div className='flex'>
              {renderColor({ color: 'disabled-grey-light', copy: 'Disabled Grey Light', hex: '#DBDBDB' })}
              {renderColor({ color: 'disabled-grey-medium', copy: 'Disabled Grey Medium', hex: '#696969', textColor: 'white' })}
              {renderColor({ color: 'disabled-grey-dark', copy: 'Disabled Grey Dark', hex: '#4A4A4A', textColor: 'white' })}
            </div>
          </section>
          <div
            className='max-width--lg'
          >
            <section className='pad--t-100'>
              <Text
                className='body--lead pad--b-32'
              >
                <b>Primary Web/UI Palette</b>
              </Text>
              <div className='flex '>
                {renderColor({ color: 'energy', copy: 'Energy', hex: '#FFA44F' })}
                {renderColor({ color: 'spark', copy: '"New Spark"', hex: '#FF9716' })}
                {renderColor({ color: 'amp', copy: 'Amp', hex: '#FFC085' })}
                {renderColor({ color: 'deep', copy: 'Deep', hex: '#4A636D', textColor: 'white' })}
                {renderColor({ color: 'stone', copy: 'Stone', hex: '#344145', textColor: 'white' })}
                {renderColor({ color: 'ocean', copy: 'Ocean', hex: '#5E7E8B', textColor: 'white' })}
              </div>
            </section>
            <section className='pad--t-100'>
              <Text
                className='body--lead pad--b-32'
              >
                <b>Primary Web/UI Palette</b>
              </Text>
              <div className='flex '>
                {renderColor({ color: 'wave', copy: 'wave', hex: '#FFA44F' })}
                {renderColor({ color: 'surf', copy: 'surf', hex: '#FF9716' })}
                {renderColor({ color: 'mist', copy: 'mist', hex: '#FFC085' })}
                {renderColor({ color: 'white', copy: 'white', hex: '#4A636D', textColor: 'white' })}
                {renderColor({ color: 'sky', copy: 'sky', hex: '#3979E2', textColor: 'white' })}
                {renderColor({ color: 'wind', copy: 'wind', hex: '#5E7E8B', textColor: 'white' })}
              </div>
            </section>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ColorPage;
