import React from 'react';
import { useFormContext } from 'react-hook-form';

// COMPONENTS
import TextInput from 'components/TextInput/TextInput';
import Box from 'components/Box/Box';

// ASSETS
import styles from '../../DrawerSingleModifier.module.scss';
import Slider from 'components/Slider/Slider';
import InputLabel from 'components/InputLabel/InputLabel';

const FormBatteryStorage = () => {
  const { register, watch } = useFormContext();

  // RENDERS
  const capacityField = register('capacityField');
  const resiliencyField = register('resiliencyField');

  return (
    <div>
      <Box mb={3}>
        <TextInput
          label="Rated capacity"
          size="md"
          suffix="kW"
          inputRef={register('capacityField') as any}
          onChange={(event: any) => capacityField.onChange({ target: { value: event.target.value, name: capacityField.name } })}
        />
        <p className={styles['form-description']}>
          Storage capacity (also known as energy capacity) measures the total amount of electricity a battery can store. This spec indicates how much electricity a battery can deliver over time before needing to be recharged. This metric is usually provided in watt-hours (wH) or kilowatt-hours (kWh) for larger batteries. Rated capacity is what the manufacturer tested the design of the battery and found it to be consistently.
        </p>
      </Box>
      <Box mb={3} width="100%">
        <Box mb={1.5}>
          <InputLabel>Resiliency</InputLabel>
        </Box>
        <div className={styles['slider-value']}>
          <span>{watch('resiliencyField')} %</span>
        </div>
        <Slider
          inputRef={resiliencyField.ref}
          {...resiliencyField}
          min={0}
          max={100}
          color="depth"
          ariaLabel="Weekday mileage slider up to 200"
          defaultValue={4}
        />
      </Box>
    </div>
  );
};

export default FormBatteryStorage;
