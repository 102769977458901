import React from 'react';

// COMPONENTS
import { Box, TextInput } from 'components';

export const buildColumns = (setDataSource: any) => {
  return [
    {
      accessorKey: 'numberRow',
      header: '',
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info: any) => {
        const handleNewRow = (rowNumber: string) => {
          if (rowNumber === '+') {
            setDataSource((prev: any) => {
              const data = prev.filter((item: any) => item.numberRow !== '+');
              return [
                ...data,
                {
                  numberRow: data.length + 1,
                  sourceMeterId: Math.floor(Math.random() * 1000000),
                  sourceMeterChannel: '1: kWh Delivered (interval)',
                  ruleText: 'Interval(1)'
                },
                {
                  numberRow: '+',
                  sourceMeterId: '',
                  sourceMeterChannel: '',
                  ruleText: ''
                }
              ];
            });
          }
        };

        return (
          <Box width={25} textAlign="center" onClick={() => handleNewRow(info.getValue())}>
            <button>{info.getValue()}</button>
          </Box>
        );
      }
    },
    {
      accessorKey: 'sourceMeterId',
      id: 'sourceMeterId',
      header: 'Source Meter ID',
      enableSorting: false
    },
    {
      accessorKey: 'sourceMeterChannel',
      header: 'Source Meter Channel',
      enableColumnFilter: false,
      enableSorting: false
    },
    {
      accessorKey: 'ruleText',
      header: 'Rule Text',
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info: any) => {
        return (
          <TextInput defaultValue={info.getValue()} />
        );
      }
    }
  ];
};

export const data = [
  {
    numberRow: '1',
    sourceMeterId: '654432',
    sourceMeterChannel: '1: kWh Delivered (interval)',
    ruleText: 'Interval(1)'
  },
  {
    numberRow: '2',
    sourceMeterId: '544321',
    sourceMeterChannel: '1: kWh Delivered (interval)',
    ruleText: 'Interval(1)'
  },
  {
    numberRow: '+',
    sourceMeterId: '',
    sourceMeterChannel: '',
    ruleText: ''
  }
];
