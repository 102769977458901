import React, { useState } from 'react';
import classnames from 'classnames';
import { Column } from '@tanstack/react-table';

// COMPONENTS
import Popover from '../../../Popover/Popover';

// ASSETS
import styles from './ColumnFilter.module.scss';
import { Button } from 'components';

interface ColumnFilterProps<TData, TValue> {
  column?: Column<TData, TValue>
  title?: string
  options: {
    label: string
    value: string
    icon?: React.ReactNode
  }[]
}

const ColumnFilter = <TData, TValue>({ column, options }: ColumnFilterProps<TData, TValue>) => {
  const [active, setActive] = useState(false);
  const [selectedValues, setSelectedValues] = useState(new Set<string>());
  // const selectedValues = new Set(column?.getFilterValue() as string[]);

  const togglePopover = (e: React.KeyboardEvent | React.MouseEvent) => {
    if (e.type !== 'keydown' || (e as React.KeyboardEvent).key === 'Enter') {
      setActive(!active);
    }
  };

  // EVENTS
  const handleSelectFilterOption = (value: string, isSelected: boolean) => {
    setSelectedValues((prevValues) => {
      const newValues = new Set(prevValues);
      if (isSelected) {
        newValues.delete(value);
      } else {
        newValues.add(value);
      }
      return newValues;
    });
  };

  const handleSubmitFilter = () => {
    const filterValues = Array.from(selectedValues);
    column?.setFilterValue(
      filterValues.length ? filterValues : undefined
    );
    setActive(false);
  };

  const handleResetFilter = () => {
    column?.setFilterValue(undefined);
    setSelectedValues(new Set());
    setActive(false);
  };

  // STYLES
  const cssColumnFilter = classnames(styles['column-filter'], {
    [styles['column-filter--active']]: column?.getIsFiltered()
  });

  // RENDERS
  const activator = (
    <span
      role="button"
      tabIndex={0}
      className={cssColumnFilter}
      onClick={togglePopover}
      onKeyDown={togglePopover}
    >
      <span role="img" aria-label="filter" className={styles['icon-filter']}>
        <svg viewBox="64 64 896 896" focusable="false" data-icon="filter" width="1em" height="1em" fill="currentColor" aria-hidden="true">
          <path d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"></path>
        </svg>
      </span>
    </span>
  );

  return (
    <Popover
      isPortal
      p={0}
      placement='bottom-end'
      zIndex={999}
      isOpen={active}
      renderReference = { activator }
      onOutsideClickClose={() => setActive(false)}
    >
      <div className={styles.optionsContainer}>
        {options && options.map((option) => {
          const isSelected = selectedValues.has(option.value);

          return (
            <div key={option.label}>
              <input
                type="checkbox"
                id={option.value}
                name={option.value}
                value={option.value}
                onChange={() => handleSelectFilterOption(option.value, isSelected)}
                checked={isSelected}
              />
              <label htmlFor={option.value}>{option.label}</label>
            </div>
          );
        })}
      </div>
      <div className={styles.footer}>
        <Button copy="Reset" size="xs" type='secondary' onClick={handleResetFilter} />
        <Button copy="Filter" size="xs" onClick={handleSubmitFilter} />
      </div>
    </Popover>
  );
};

export default ColumnFilter;
