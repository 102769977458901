import { ChargingPower, EvVehicle } from 'widget/ExploreWidget/api/projectDetail.api';
import * as Yup from 'yup';

export const gasPerYearByModel = (model?: string) => {
  switch (model) {
  case 'equinox':
    return 1607.04;
  case 'blazer':
    return 1875;
  case 'silverado':
    return 2500;
  case 'bolt':
    return 0;
  default:
    return 0;
  }
};

export const calculateEvCost = (weekdayMileage: number, weekendMileage: number, weekdayCharging: number) => {
  return (weekdayMileage * weekdayCharging) + (weekendMileage * weekdayCharging);
};

export const buildMakeOptions = (evVehicles: EvVehicle[]) => {
  const makeOptions = new Set(evVehicles.map(vehicle => vehicle.make));
  return Array.from(makeOptions).map(make => ({
    value: make,
    label: make
  }));
};

export const buildModelOptions = (evVehicles: EvVehicle[], make: string) => {
  const modelOptions = new Set(evVehicles.filter(vehicle => vehicle.make === make).map(vehicle => vehicle.model));
  return Array.from(modelOptions).map(model => ({
    value: model,
    label: model
  }));
};

export const buildYearOptions = (evVehicles: EvVehicle[], make: string, model: string) => {
  const yearOptions = new Set(evVehicles.filter(vehicle => vehicle.make === make && vehicle.model === model).map(vehicle => vehicle.year));
  return Array.from(yearOptions).map(year => ({
    value: year,
    label: year
  }));
};

export const buildChargingPowerOptions = (chargingPowers: ChargingPower[]) => {
  return chargingPowers.map(chargingPower => ({
    value: chargingPower.value,
    label: chargingPower.name
  }));
};

export const defaultAttribueValues = {
  make: 'Tesla',
  model: 'Model Y Long Range AWD',
  year: '2023',
  weekdayDistance: 40,
  weekendDistance: 20,
  chargingStartTime: '21:00',
  chargingPower: '3.3'
};

export const validationSchema = Yup.object({
  make: Yup.string().required(),
  model: Yup.string().required(),
  year: Yup.string().required(),
  chargingPower: Yup.string().required(),
  chargingStartTime: Yup.string().required()
});
