import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import { FilterSearchBar } from 'blocks/components';

// ACTIONS

// SELECTORS
import { getAccountsSearchString } from 'store/modules/accounts/accounts.selector';
import { setAccountSearchString, setSortedAccounts, toogleFilter } from 'store/modules/accounts/accounts.slice';

const FilterSearchAccounts = () => {
  const dispatch = useDispatch();
  const searchString = useSelector(getAccountsSearchString);

  // EVENTS
  const handleOpenFilter = () => {
    dispatch(toogleFilter());
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setAccountSearchString(event.target.value));
  };

  // RENDERS
  const actionList = [
    {
      content: 'Service Agreement',
      onClick: () => {
        dispatch(setSortedAccounts({ sortKey: 'serviceAgreement' }));
      }
    },
    {
      content: 'Rate',
      onClick: () => {
        dispatch(setSortedAccounts({ sortKey: 'rate' }));
      }
    },
    {
      content: 'Bill Cycle',
      onClick: () => {
        dispatch(setSortedAccounts({ sortKey: 'billCycle' }));
      }
    },
    {
      content: 'Effective Date',
      onClick: () => {
        dispatch(setSortedAccounts({ sortKey: 'effectiveDate' }));
      }
    }
  ];

  return (
    <FilterSearchBar
      searchPlaceholder='Search customers'
      sortOptions={actionList}
      onSearch={handleSearch}
      searchString={searchString}
      onOpenFilter={handleOpenFilter}
    />
  );
};

export default FilterSearchAccounts;
