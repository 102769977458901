import React, { Dispatch, SetStateAction, Fragment, useRef, useState, ReactNode } from 'react';

import './login.scss';

import {
  SwitchTransition,
  CSSTransition
} from 'react-transition-group';

import Avatar from '../../components/Avatar/Avatar';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import Logo from '../../components/Logo/Logo';
import Link from '../../components/Link/Link';
import Text from '../../components/Text/Text';
import TextInput from '../../components/TextInput/TextInput';

interface Props {
    className?: string,
    additionalLink?: ReactNode,
    handleEmailSubmit?: () => void,
    handlePwSubmit?: () => void,
    loginState?: string,
    emailInputState?: boolean,
    errorCopy?: string,
    password?: string,
    profile?: any,
    errorMessage?: string,
    setErrorMessage?: Dispatch<SetStateAction<string>>,
    setEmailInputState?: Dispatch<SetStateAction<boolean>>,
    setLoginState?: Dispatch<SetStateAction<string>>,
    setPassword?: Dispatch<SetStateAction<string>>,
    setUserEmail?: Dispatch<SetStateAction<string>>,
    userEmail?: string,
};

/**
* Represents a Login.
* @constructor
* @param {string} className
* Usage :
* ````js
* <Login
    className='my-login',
  />
* ````
* @augments {Component<Props, State>}
*/
/** */
const Login: React.FC<Props> = ({
  className,
  additionalLink,
  emailInputState,
  errorCopy = '',
  handleEmailSubmit = () => {},
  handlePwSubmit = () => {},
  loginState,
  profile = { username: '' },
  password,
  setErrorMessage = () => {},
  setEmailInputState = () => {},
  setLoginState = () => {},
  setPassword = () => {},
  setUserEmail = () => {},
  userEmail = ''
}: Props) => {
  const [inputType, setPwInputType] = useState('password');
  const helloRef = useRef<HTMLDivElement>(null);
  const goodbyeRef = useRef<HTMLDivElement>(null);
  const nodeRef = loginState ? helloRef : goodbyeRef;
  const validateEmail = (email: string) => /\S+@\S+\.\S+/.test(email);
  const {
    username
  } = profile;

  const handleEmail = () => {
    if (!validateEmail(userEmail)) {
      setErrorMessage('Invalid Email');
    } else {
      handleEmailSubmit();
    }
  };

  const handleEmailValidation = (e: { target: any; }) => {
    setUserEmail(e.target.value);
    // validate email after 3 characters
    if (userEmail.length >= 3) {
      setEmailInputState(!validateEmail(userEmail));
    }
  };
  const handleSwitchAccount = (e: {
    preventDefault(): unknown;target: any
  }) => {
    e.preventDefault();
    setLoginState('email');
  };

  const renderEmailInput = () => {
    return (
      <div
        className={
          `${className || ''}`
        }
      >
        <div>
          <Logo
            className='dis--inline-block'
            name='gridx-stacked'
            size='xl'
          />
          <Text
            className='body--xl pad--l-12 pad--t-96 text--bold text--left'
            color='stone'
            variant='body1'
          >
          Email
          </Text>
          <TextInput
            autoComplete="username"
            data-testid="loginInputEmail"
            className='mar--t-8 primary'
            error={emailInputState}
            errorMessage={errorCopy}
            onChange={(e: { target: { value: SetStateAction<string>; }; }) => handleEmailValidation(e)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleEmail();
                event.preventDefault();
                event.stopPropagation();
              }
            }}
            type='email'
            value={userEmail}
            size="lg"
          />
          <Button
            className='mar--b-24 mar--t-36'
            copy='Continue'
            color='magma'
            disabled={!validateEmail(userEmail)}
            name='Continue'
            size='full'
            onClick={() => handleEmail()}
            type='secondary'
          />
          <br />
          {!additionalLink || additionalLink}
        </div>
      </div>
    );
  };

  const renderPwModule = () => {
    return (
      <Fragment>
        <div className='avatar--container'>
          <Avatar
            alt={profile ? username : ''}
            size='md'
          />
          <Text
            className='body--lg pad--l-12 pad--t-52 text--center'
            color='stone'
            variant='body1'
          >
            {profile ? username : ''}
          </Text>
        </div>
        <div className='input--container'>
          <Link
            className='dis--block mar--b-80 underline'
            color='sky'
            copy='Switch account'
            icon={false}
            href='/'
            onClick={(e: { preventDefault(): unknown; target: any; }) => handleSwitchAccount(e)}
          />
          <Text
            className='body--xl pad--l-12 text--bold text--left'
            color='stone'
            variant='body1'
          >
          Password
          </Text>
          <TextInput
            data-testid="loginInputPassword"
            className='mar--t-8 primary'
            endAdornment={
              <div
                onClick={() => setPwInputType(inputType === 'password' ? '' : 'password')}
                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                  if (e.key === 'Enter') {
                    setPwInputType(inputType === 'password' ? '' : 'password');
                  }
                }}
                role="button"
                tabIndex={0}
              >
                {inputType === 'password' ? (<Icon.EyeOff />) : <Icon.Eye /> }
              </div>
            }
            onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setPassword?.(e.target.value)}
            size='lg'
            type={inputType}
            value={password}
          />
          <Button
            className='mar--b-24 mar--t-24'
            copy='Sign in'
            color='magma'
            name='Sign in'
            size='full'
            type='primary'
            onClick={() => handlePwSubmit()}
          />
          <br />
          <Link
            className='dis--block mar--b-80 underline'
            color='sky'
            copy='Forgot Password'
            icon={false}
            href='/'
          />
        </div>
      </Fragment>
    );
  };

  return (
    <div className='login--wrapper' data-testid="loginComponent">
      <section>
        <form className='login--container flex flex--align-center jus--center'>
          <SwitchTransition mode='out-in'>
            <CSSTransition
            /* @ts-ignore */
              key={loginState}
              nodeRef={nodeRef}
              addEndListener={(done: any) => {
                /* @ts-ignore */
                nodeRef.current.addEventListener('transitionend', done, false);
              }}
              classNames="animation--fade"
            >
              <div>
                {loginState === 'email'
                  ? (
                    <div ref={nodeRef} className="button-container">
                      <div ref={null} className='login--module module--username'>
                        {renderEmailInput()}
                      </div>
                    </div>
                  )
                  : null}
                {loginState === 'pw'
                  ? (
                    <div ref={nodeRef} className="button-container">
                      <div ref={null} className='login--module mar--t-80 module--password'>
                        {renderPwModule()}
                      </div>
                    </div>
                  )
                  : null}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </form>
      </section>
    </div>

  );
};

export default Login;
