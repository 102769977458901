import React from 'react';

import './image.scss';

interface Props {
  alt?: string | undefined,
  className?: string,
  src?: string,
};

/**
* Represents a Image.
* @constructor
* @param {string} alt
* @param {string} className
* @param {string} src
* Usage :
* ````js
* <Image alt='My Image Name' className='my-image' src='../../my_image.jpg />
* ````
* @augments {Component<Props, State>}
*/
/** */

const Image: React.FC<Props> = ({ alt, className, src }: Props) => {
  return (
    <div className={className || ''}>
      <img
        alt={alt}
        src={src}
      />
    </div>
  );
};

export default Image;
