import * as React from 'react';

import colors from '../../../style/colors';

const Rates = (props:any) => {
  const { color, showBrandName, showGridX } = props;

  let renderColor;
  switch (color) {
  case 'deep':
    renderColor = colors.deep;
    break;
  case 'energy':
    renderColor = colors.energy;
    break;
  case 'white':
    renderColor = colors.white;
    break;
  }

  if (showGridX) {
    return (
      <svg
        width={369}
        height={134}
        viewBox="0 0 369 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_11025_6700)">
          <path
            d="M88.3198 98.4343L74.2377 112.515C72.3028 114.446 69.734 115.512 67.0001 115.512C64.2663 115.512 61.6942 114.446 59.7626 112.515L45.6772 98.4277L32.6021 111.505L46.6875 125.588C52.1124 131.012 59.3268 134 67.0001 134C74.6735 134 81.8879 131.012 87.3128 125.588L101.395 111.508L88.3198 98.4343Z"
            fill={renderColor || '#354145'}
          />
          <path
            d="M21.4749 74.2284C17.483 70.2403 17.483 63.7465 21.4749 59.7551L35.557 45.678L22.4819 32.6045L8.39976 46.6849C-2.79992 57.8832 -2.79992 76.1036 8.39976 87.3019L21.4683 100.372L35.5405 88.2956L21.4749 74.2284Z"
            fill={renderColor || '#F9A350'}
          />
          <path
            d="M125.6 46.6849L111.518 32.6045L98.4461 45.678L112.525 59.7551C116.517 63.7465 116.517 70.2403 112.525 74.2284L97.8848 88.8667L110.96 101.94L125.6 87.3019C136.8 76.1036 136.8 57.8832 125.6 46.6849Z"
            fill={renderColor || '#F9A350'}
          />
          <path
            d="M87.3127 8.39873C76.113 -2.79958 57.8871 -2.79958 46.6874 8.39873L32.0439 23.0437L45.119 36.1172L59.7625 21.4722C63.7544 17.4808 70.2457 17.4808 74.2376 21.4722L88.8777 36.1106L101.95 23.0371L87.3127 8.39873Z"
            fill={renderColor || '#354145'}
          />
          <path
            d="M93.6125 84.5945L97.885 88.8665L88.3197 98.4339L84.0439 94.1586L81.7095 91.8246L80.8015 90.9167C73.1777 83.2938 60.8158 83.2938 53.1886 90.9167L52.2872 91.818L49.9529 94.1553L45.677 98.4273L35.5405 88.2954L40.1432 84.3469L42.6559 82.1878L43.6266 81.3559C51.2505 73.733 51.2505 61.3693 43.6266 53.7464L35.557 45.6778L45.119 36.117L53.1886 44.1855C60.8158 51.8084 73.1777 51.8084 80.8015 44.1855L82.2675 42.7197L84.6019 40.3856L88.8777 36.1104L98.4463 45.6778L91.8361 52.2872L90.3734 53.7464C82.7463 61.3693 82.7463 73.733 90.3734 81.3559L91.2748 82.2571L93.6125 84.5945Z"
            fill={renderColor || '#D3EAEC'}
          />
        </g>
        <path
          d="M212.328 114.016H200.232L177.264 92.5604V114.016H167.688V61.8164H191.952C203.544 61.8164 210.672 68.0084 210.672 77.9444C210.672 87.8084 203.976 93.8564 193.896 93.8564H190.584L212.328 114.016ZM177.264 70.3124V87.0884H191.52C197.64 87.0884 200.808 83.7044 200.808 78.7364C200.808 73.1924 197.136 70.3124 191.664 70.3124H177.264Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M216.002 103.72C216.002 97.1684 220.322 93.1364 228.026 92.2724L241.13 90.8324V89.1764C241.13 84.8564 237.818 82.8404 233.138 82.8404C228.026 82.8404 225.218 85.3604 225.362 89.4644H217.01C216.794 81.0404 223.058 75.6404 233.93 75.6404C243.938 75.6404 250.202 80.2484 250.202 89.4644V114.016H242.282L241.778 107.32C239.834 111.928 234.506 114.736 228.314 114.736C220.898 114.736 216.002 110.416 216.002 103.72ZM225.002 102.496C225.002 105.664 227.09 107.68 231.122 107.68C236.738 107.68 241.13 103.936 241.13 98.3924V95.9444L230.546 97.3124C226.586 97.8164 225.002 99.7604 225.002 102.496Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M266.192 114.016V83.8484H255.608V76.3604H266.192V66.7124L275.264 66.2084V76.3604H285.92V83.8484H275.264V114.016H266.192Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M318.967 102.496H327.967C326.023 110.2 318.823 114.736 309.607 114.736C297.439 114.736 289.519 106.888 289.519 95.1524C289.519 83.4884 297.439 75.6404 309.103 75.6404C320.047 75.6404 327.535 82.6244 327.535 92.9204C327.535 94.2164 327.391 95.0804 327.175 96.3044H298.663V97.0244C298.663 103.72 303.055 107.464 309.535 107.464C314.143 107.464 317.599 105.52 318.967 102.496ZM308.887 82.8404C303.703 82.8404 299.743 85.8644 298.879 90.5444H318.247C318.175 86.0084 314.287 82.8404 308.887 82.8404Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M349.94 114.736C340.22 114.736 333.956 109.84 333.884 102.496H342.308C342.452 105.952 345.476 108.04 349.94 108.04C354.332 108.04 356.636 106.168 356.636 103.36C356.636 100.912 354.908 99.6164 352.172 99.1124L345.26 97.8884C339.572 96.8084 334.532 94.0724 334.532 87.4484C334.532 80.3204 340.652 75.6404 349.724 75.6404C358.796 75.6404 364.988 80.0324 365.06 87.3764H356.924C356.78 84.0644 353.972 82.2644 349.868 82.2644C345.98 82.2644 343.532 83.9924 343.532 86.7284C343.532 89.6084 346.412 90.2564 348.356 90.6164L355.268 91.8404C362.828 93.1364 365.708 97.3124 365.708 102.712C365.708 109.84 359.804 114.736 349.94 114.736Z"
          fill={renderColor || '#344145'}
        />
        <g clipPath="url(#clip1_11025_6700)">
          <path
            d="M208.414 9.94043H203.654V29.35H208.414V9.94043Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M208.414 3.42676H203.654V7.55292H208.414V3.42676Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M233.159 1.5166V29.35H228.957L228.578 26.4555C227.219 28.4614 224.424 29.7216 221.1 29.7216C215.131 29.7216 211.278 25.6396 211.278 19.7024C211.278 13.5781 215.432 9.56886 221.478 9.56886C224.347 9.56886 226.878 10.5328 228.391 12.2399V1.5166H233.159ZM228.399 19.9258V19.4789C228.399 15.8412 225.905 13.5027 222.239 13.5027C218.463 13.5027 216.123 15.9504 216.123 19.6244C216.123 23.4466 218.577 25.7851 222.205 25.7851C225.833 25.7851 228.399 23.5999 228.399 19.9258Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M241.637 19.1486L234.465 9.94008H240.376L244.94 15.95C245.02 16.0539 245.124 16.1382 245.243 16.1962C245.362 16.2542 245.493 16.2844 245.625 16.2844C245.758 16.2844 245.889 16.2542 246.008 16.1962C246.126 16.1382 246.23 16.0539 246.31 15.95L251.028 9.92969H256.772L249.486 19.133C249.368 19.2813 249.304 19.4642 249.304 19.6527C249.304 19.8411 249.368 20.0241 249.486 20.1723L256.809 29.3367H250.991L246.26 23.4151C246.18 23.3131 246.077 23.2306 245.959 23.1738C245.841 23.117 245.712 23.0875 245.58 23.0875C245.449 23.0875 245.32 23.117 245.202 23.1738C245.084 23.2306 244.981 23.3131 244.901 23.4151L240.249 29.3419H234.465L241.637 20.1697C241.751 20.0231 241.813 19.8437 241.813 19.6592C241.813 19.4747 241.751 19.2953 241.637 19.1486V19.1486Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M198.883 9.94042L202.059 14.1545H195.696C195.212 14.1545 194.748 14.3434 194.406 14.6796C194.064 15.0158 193.872 15.6594 193.872 16.1349V29.35H189.204V15.8387C189.204 11.5384 191.661 9.93262 194.731 9.93262L198.883 9.94042Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M181.395 9.94026L181.093 12.6503C179.771 10.7951 177.127 9.57129 173.916 9.57129C168.098 9.57129 164.285 13.5779 164.285 19.1851C164.285 24.7508 168.101 28.76 173.916 28.76C176.939 28.76 179.62 27.5726 180.829 25.7174V28.6847C180.829 31.8027 178.488 33.7333 174.974 33.7333C173.022 33.7333 171.488 33.0915 170.441 32.1743C170.275 32.0261 169.939 31.7455 169.939 31.7455L166.895 34.7856C167.158 35.0508 167.441 35.2966 167.741 35.5209C169.449 36.7967 171.859 37.5216 174.9 37.5216C181.395 37.5216 185.589 34.1074 185.589 28.5028V9.94026H181.395ZM180.829 19.3307C180.829 22.7449 178.224 24.9353 174.86 24.9353C171.234 24.9353 169.119 22.5214 169.119 19.1462C169.119 15.6566 171.385 13.3934 174.9 13.3934C178.261 13.3934 180.829 15.4721 180.829 18.8863V19.3307Z"
            fill={renderColor || '#344145'}
          />
        </g>
        <defs>
          <clipPath id="clip0_11025_6700">
            <rect width={134} height={134} fill="white" />
          </clipPath>
          <clipPath id="clip1_11025_6700">
            <rect
              width={99}
              height={39}
              fill="white"
              transform="translate(162 0.0166016)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (showBrandName) {
    return (
      <svg
        width={361}
        height={134}
        viewBox="0 0 361 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_11025_6549)">
          <path
            d="M88.3198 98.4343L74.2377 112.515C72.3028 114.446 69.734 115.512 67.0001 115.512C64.2663 115.512 61.6942 114.446 59.7626 112.515L45.6772 98.4277L32.6021 111.505L46.6875 125.588C52.1123 131.012 59.3268 134 67.0001 134C74.6735 134 81.8879 131.012 87.3128 125.588L101.395 111.508L88.3198 98.4343Z"
            fill={renderColor || '#354145'}
          />
          <path
            d="M21.4749 74.2284C17.483 70.2403 17.483 63.7465 21.4749 59.7551L35.557 45.678L22.4819 32.6045L8.39976 46.6849C-2.79992 57.8832 -2.79992 76.1036 8.39976 87.3019L21.4683 100.372L35.5405 88.2956L21.4749 74.2284Z"
            fill={renderColor || '#F9A350'}
          />
          <path
            d="M125.6 46.6849L111.518 32.6045L98.4461 45.678L112.525 59.7551C116.517 63.7465 116.517 70.2403 112.525 74.2284L97.8848 88.8667L110.96 101.94L125.6 87.3019C136.8 76.1036 136.8 57.8832 125.6 46.6849Z"
            fill={renderColor || '#F9A350'}
          />
          <path
            d="M87.3127 8.39873C76.113 -2.79958 57.8871 -2.79958 46.6874 8.39873L32.0439 23.0437L45.119 36.1172L59.7625 21.4722C63.7544 17.4808 70.2457 17.4808 74.2376 21.4722L88.8777 36.1106L101.95 23.0371L87.3127 8.39873Z"
            fill={renderColor || '#354145'}
          />
          <path
            d="M93.6125 84.5945L97.885 88.8665L88.3197 98.4339L84.0439 94.1586L81.7095 91.8246L80.8015 90.9167C73.1777 83.2938 60.8158 83.2938 53.1886 90.9167L52.2872 91.818L49.9529 94.1553L45.677 98.4273L35.5405 88.2954L40.1432 84.3469L42.6559 82.1878L43.6266 81.3559C51.2505 73.733 51.2505 61.3693 43.6266 53.7464L35.557 45.6778L45.119 36.117L53.1886 44.1855C60.8158 51.8084 73.1777 51.8084 80.8015 44.1855L82.2675 42.7197L84.6019 40.3856L88.8777 36.1104L98.4463 45.6778L91.8361 52.2872L90.3734 53.7464C82.7463 61.3693 82.7463 73.733 90.3734 81.3559L91.2748 82.2571L93.6125 84.5945Z"
            fill={renderColor || '#D3EAEC'}
          />
        </g>
        <path
          d="M206.64 92.74H194.544L171.576 71.284V92.74H162V40.54H186.264C197.856 40.54 204.984 46.732 204.984 56.668C204.984 66.532 198.288 72.58 188.208 72.58H184.896L206.64 92.74ZM171.576 49.036V65.812H185.832C191.952 65.812 195.12 62.428 195.12 57.46C195.12 51.916 191.448 49.036 185.976 49.036H171.576Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M210.314 82.444C210.314 75.892 214.634 71.86 222.338 70.996L235.442 69.556V67.9C235.442 63.58 232.13 61.564 227.45 61.564C222.338 61.564 219.53 64.084 219.674 68.188H211.322C211.106 59.764 217.37 54.364 228.242 54.364C238.25 54.364 244.514 58.972 244.514 68.188V92.74H236.594L236.09 86.044C234.146 90.652 228.818 93.46 222.626 93.46C215.21 93.46 210.314 89.14 210.314 82.444ZM219.314 81.22C219.314 84.388 221.402 86.404 225.434 86.404C231.05 86.404 235.442 82.66 235.442 77.116V74.668L224.858 76.036C220.898 76.54 219.314 78.484 219.314 81.22Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M260.504 92.74V62.572H249.92V55.084H260.504V45.436L269.576 44.932V55.084H280.232V62.572H269.576V92.74H260.504Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M313.279 81.22H322.279C320.335 88.924 313.135 93.46 303.919 93.46C291.751 93.46 283.831 85.612 283.831 73.876C283.831 62.212 291.751 54.364 303.415 54.364C314.359 54.364 321.847 61.348 321.847 71.644C321.847 72.94 321.703 73.804 321.487 75.028H292.975V75.748C292.975 82.444 297.367 86.188 303.847 86.188C308.455 86.188 311.911 84.244 313.279 81.22ZM303.199 61.564C298.015 61.564 294.055 64.588 293.191 69.268H312.559C312.487 64.732 308.599 61.564 303.199 61.564Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M344.252 93.46C334.532 93.46 328.268 88.564 328.196 81.22H336.62C336.764 84.676 339.788 86.764 344.252 86.764C348.644 86.764 350.948 84.892 350.948 82.084C350.948 79.636 349.22 78.34 346.484 77.836L339.572 76.612C333.884 75.532 328.844 72.796 328.844 66.172C328.844 59.044 334.964 54.364 344.036 54.364C353.108 54.364 359.3 58.756 359.372 66.1H351.236C351.092 62.788 348.284 60.988 344.18 60.988C340.292 60.988 337.844 62.716 337.844 65.452C337.844 68.332 340.724 68.98 342.668 69.34L349.58 70.564C357.14 71.86 360.02 76.036 360.02 81.436C360.02 88.564 354.116 93.46 344.252 93.46Z"
          fill={renderColor || '#344145'}
        />
        <defs>
          <clipPath id="clip0_11025_6549">
            <rect width={134} height={134} fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 64 64"
        {...props}
      >
        <path
          fill={renderColor || '#354145'}
          d="m42.182 47.013-6.725 6.725A4.862 4.862 0 0 1 32 55.17a4.859 4.859 0 0 1-3.457-1.432l-6.727-6.728-6.245 6.246 6.727 6.726A13.632 13.632 0 0 0 32 64c3.665 0 7.11-1.427 9.702-4.018l6.725-6.724-6.245-6.245Z"
        />
        <path
          fill={renderColor || '#F9A350'}
          d="M10.257 35.452a4.892 4.892 0 0 1 0-6.912l6.726-6.724-6.245-6.244-6.726 6.725c-5.35 5.349-5.35 14.05 0 19.4l6.242 6.242 6.72-5.768-6.717-6.719ZM59.988 22.297l-6.726-6.725-6.243 6.244 6.724 6.724a4.892 4.892 0 0 1 0 6.912l-6.992 6.992 6.245 6.244 6.992-6.992c5.35-5.348 5.35-14.05 0-19.399Z"
        />
        <path
          fill={renderColor || '#354145'}
          d="M41.702 4.011c-5.35-5.348-14.054-5.348-19.403 0l-6.994 6.995 6.244 6.244 6.994-6.995a4.893 4.893 0 0 1 6.914 0l6.992 6.992 6.243-6.244-6.99-6.992Z"
        />
        <path
          fill={renderColor || '#D3EAEC'}
          d="m44.71 40.403 2.041 2.04-4.568 4.57-2.043-2.042-1.114-1.114-.434-.434c-3.641-3.64-9.546-3.64-13.188 0l-.431.43-1.115 1.117-2.042 2.04-4.841-4.839 2.198-1.886 1.2-1.031.464-.397a9.324 9.324 0 0 0 0-13.187l-3.854-3.854 4.566-4.566 3.855 3.854c3.642 3.64 9.547 3.64 13.188 0l.7-.7 1.115-1.115 2.042-2.042 4.57 4.57-3.157 3.156-.699.697a9.322 9.322 0 0 0 0 13.187l.431.43 1.117 1.116Z"
        />
      </svg>
    );
  }
};
export default Rates;
