import React from 'react';
import classnames from 'classnames';

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(
  // eslint-disable-next-line react/prop-types
  ({ className, ...props }, ref) => (
    <tfoot
      ref={ref}
      className={classnames('bg-primary font-medium text-primary-foreground', className)}
      {...props}
    />
  ));
TableFooter.displayName = 'TableFooter';

export default TableFooter;
