import * as React from 'react';
import Slider, { SliderProps } from '@mui/base/Slider';
import classnames from 'classnames';

// ASSETS
import styles from './Slider.module.scss';

interface CustomSliderProps extends SliderProps {
  maxLabel?: string
  ariaLabel?: string;
  color?: 'default' | 'depth';
  error?: boolean;
  inputRef?: any;
  max?: number;
}

const CustomSlider = React.forwardRef<HTMLDivElement, CustomSliderProps>(
  ({ max, error, maxLabel, color = 'default', ariaLabel, inputRef, ...props }, ref) => {
    const cssContainer = classnames(styles.container, styles[`container--color-${color}`], {
      [styles.container__error]: error
    });

    return (
      <div ref={ref} className={cssContainer}>
        <Slider
          {...props}
          ref={inputRef}
          max={max}
          className={styles.slider}
          aria-label={ariaLabel || 'Slider'}
        />
        <div className={styles.max}>
          {maxLabel ? <span>{maxLabel}</span> : <span>{max}</span>}
        </div>
      </div>
    );
  }
);

CustomSlider.displayName = 'CustomSlider';

export default CustomSlider;
