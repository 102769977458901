import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

// COMPONENTS
import ExploreNav from '../Explore/sections/ExplorePage/components/ExploreNav/ExploreNav';
import MainPage from 'pages/RateAnalysisWidgetDemo/components/MainPage/MainPage';
import GenericSkeleton from 'components/Skeleton/stories/scenes/GenericSkeleton';

// HELPERS
import { ThemeProvider } from 'hooks/Theme/Theme.context';
import { getTheme } from '../Explore/sections/ExplorePage/ExplorePage.helpers';

import styles from '../MyAccount/MyAccount.module.scss';

interface UsagePageProps {
  theme?: string;
  themeImages?: string;
}

const UsagePage:React.FC<UsagePageProps> = ({ theme = '', themeImages = '' }) => {
  const { theme: themeRoute } = useParams();
  const currentTheme = themeRoute || theme;

  const [isLoading, setLoading] = useState(true);
  const imageUrls = 'https://explore-frontend-dev.s3.us-west-2.amazonaws.com/myaccount-images';
  const query = `?version=${Math.random()}`;

  setTimeout(function () {
    setLoading(false);
  }, 200);

  return (
    <div>
      <ThemeProvider theme={getTheme(currentTheme)}>
        <ExploreNav
          onCmsChange={() => {}}
          company={currentTheme}
          menu={[
            {
              label: 'My Account',
              selected: false,
              href: `/${currentTheme}/rates-design`
            },
            {
              label: 'Billing',
              selected: false
            },
            {
              label: 'Usage',
              selected: true,
              href: `/${currentTheme}/usage`
            },
            {
              label: 'Rates & Programs',
              selected: false,
              href: `/${currentTheme}/rates-comparison`
            },
            {
              label: 'Explore',
              selected: false,
              href: `/${currentTheme}/explore`
            }
          ]}
        />
        <MainPage title="Usage">
          {isLoading
            ? <div className='bg--white pad--t-24 pad--l-24 pad--b-24 pad--r-24'>
              <GenericSkeleton />
            </div>
            : (
              <>
                <div className={styles.firstLine}>
                  <div>
                    <img src={`${imageUrls}${themeImages}/usage/date_toggle.svg${query}`} alt="Date Picker" className={styles.billingImage} />
                  </div>
                </div>
                <div className={styles.firstLine}>
                  <div className='mar--t-20'>
                    <img src={`${imageUrls}${themeImages}/usage/usage.svg${query}`} alt="Usage Dashboard" className={styles.billingImage} />
                  </div>
                </div>
                <div className={styles.secondLine}>
                  <div>
                    <img src={`${imageUrls}${themeImages}/usage/chart_01.svg${query}`} alt="Usage Chart" className={styles.billingImage} />
                  </div>
                  <div className='mar--b-24'>
                    <img src={`${imageUrls}${themeImages}/usage/chart_02.svg${query}`} alt="Usage Chart" className={styles.billingImage} />
                  </div>
                </div>
                <div className={`${styles.firstLine} bor--t-1`}>
                  <div className='mar--t-24'>
                    <img src={`${imageUrls}${themeImages}/usage/TOU_Rate.svg${query}`} alt="TOU Chart" className={styles.billingImage} />
                  </div>
                </div>
              </>
            )}

        </MainPage>
      </ThemeProvider>
    </div>
  );
};

export default UsagePage;
