import * as React from 'react';

import colors from '../../../style/colors';

const Balance = (props:any) => {
  const { color, showBrandName, showGridX } = props;

  let renderColor;
  switch (color) {
  case 'deep':
    renderColor = colors.deep;
    break;
  case 'energy':
    renderColor = colors.energy;
    break;
  case 'white':
    renderColor = colors.white;
    break;
  }

  if (showGridX) {
    return (
      <svg
        width={448}
        height={134}
        viewBox="0 0 448 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_9121_7595)">
          <path
            d="M28.3818 71.3703L14.2778 57.3701L7.21808e-05 71.5429L14.104 85.5431L28.3818 71.3703Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M64.5536 58.6032C55.2504 60.3233 45.7382 62.0677 38.6102 61.2382L24.8828 74.8647C30.421 77.4547 36.4974 78.7087 42.6167 78.5245C50.8384 78.5245 59.5272 76.9264 67.6997 75.4137C78.4162 73.4374 89.3908 71.4245 96.7399 73.3398L109.73 60.4452C97.1947 52.6011 79.9647 55.7486 64.5536 58.6032Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M24.5762 46.4162C24.3695 43.8987 24.7006 41.3663 25.5477 38.9845C26.3948 36.6027 27.7389 34.4252 29.492 32.5946L16.9567 20.8833C13.5529 24.4663 10.9486 28.7215 9.31378 33.3713C7.67897 38.021 7.05044 42.9607 7.46914 47.8679C7.84726 52.2879 9.06486 56.5969 11.0577 60.5673L24.6746 47.0506C24.6623 46.8432 24.5762 46.6236 24.5762 46.4162Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M110.358 87.7954C110.54 90.21 110.227 92.6362 109.437 94.9274C108.648 97.2185 107.399 99.3269 105.765 101.125C104.132 102.923 102.148 104.374 99.9335 105.39C97.7191 106.405 95.3203 106.964 92.882 107.034C89.6552 107.113 86.4653 106.338 83.6402 104.789L71.2769 117.11C77.3611 121.669 84.7799 124.128 92.4026 124.112H93.3981C102.342 123.855 110.851 120.23 117.201 113.972C123.55 107.715 127.264 99.2939 127.588 90.4182C127.792 84.7887 126.624 79.1935 124.183 74.1079L110.358 87.7954Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M105.644 64.5106L119.722 78.4849L134 64.3121L119.922 50.3378L105.644 64.5106Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M41.5723 9.90362C36.9478 10.031 32.3946 11.0669 28.1757 12.9514C23.9569 14.836 20.1562 17.5317 16.9932 20.8829L29.4916 32.5942C32.2229 29.716 35.8386 27.8182 39.7725 27.1979C43.7065 26.5776 47.7366 27.2698 51.2319 29.1662L63.6567 16.8328C57.2993 12.1048 49.5122 9.6615 41.5723 9.90362Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M75.9123 90.5893V45.1838C75.7772 38.2547 74.8186 28.4709 68.4649 21.2002L56.1753 33.3994C57.7606 35.8392 58.6332 39.8406 58.7069 45.44V91.2114C58.713 91.2437 58.713 91.2768 58.7069 91.3091V91.4676C59.3829 100.617 61.9022 107.607 66.4002 112.743L78.6898 100.544C77.1782 98.3236 76.2687 95.0298 75.9123 90.5893Z"
            fill={renderColor || '#FFA44F'}
          />
        </g>
        <path
          d="M200.736 86.9446C206.208 88.8166 209.304 93.5686 209.304 99.4006C209.304 107.321 203.904 114.017 192.024 114.017H167.688V61.8166H191.376C201.672 61.8166 207.576 67.4326 207.576 75.7846C207.576 80.6086 205.056 84.7126 200.736 86.9446ZM177.12 69.4486V83.8486H189.288C195.192 83.8486 197.784 80.8246 197.784 76.4326C197.784 71.9686 194.976 69.4486 190.152 69.4486H177.12ZM190.368 106.313C196.416 106.313 199.296 103.145 199.296 98.6086C199.296 93.4246 195.912 90.9046 190.656 90.9046H177.12V106.313H190.368Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M215.44 103.721C215.44 97.1686 219.76 93.1366 227.464 92.2726L240.568 90.8326V89.1766C240.568 84.8566 237.256 82.8406 232.576 82.8406C227.464 82.8406 224.656 85.3606 224.8 89.4646H216.448C216.232 81.0406 222.496 75.6406 233.368 75.6406C243.376 75.6406 249.64 80.2486 249.64 89.4646V114.017H241.72L241.216 107.321C239.272 111.929 233.944 114.737 227.752 114.737C220.336 114.737 215.44 110.417 215.44 103.721ZM224.44 102.497C224.44 105.665 226.528 107.681 230.56 107.681C236.176 107.681 240.568 103.937 240.568 98.3926V95.9446L229.984 97.3126C226.024 97.8166 224.44 99.7606 224.44 102.497Z"
          fill={renderColor || '#344145'}
        />
        <path d="M259.278 114.017V60.0166H268.35V114.017H259.278Z" fill={renderColor || '#344145'} />
        <path
          d="M276.806 103.721C276.806 97.1686 281.126 93.1366 288.83 92.2726L301.934 90.8326V89.1766C301.934 84.8566 298.622 82.8406 293.942 82.8406C288.83 82.8406 286.022 85.3606 286.166 89.4646H277.814C277.598 81.0406 283.862 75.6406 294.734 75.6406C304.742 75.6406 311.006 80.2486 311.006 89.4646V114.017H303.086L302.582 107.321C300.638 111.929 295.31 114.737 289.118 114.737C281.702 114.737 276.806 110.417 276.806 103.721ZM285.806 102.497C285.806 105.665 287.894 107.681 291.926 107.681C297.542 107.681 301.934 103.937 301.934 98.3926V95.9446L291.35 97.3126C287.39 97.8166 285.806 99.7606 285.806 102.497Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M320.855 114.017V76.3606H329.207L329.999 81.8326C332.231 78.0166 336.839 75.6406 342.167 75.6406C351.239 75.6406 355.919 81.2566 355.919 90.3286V114.017H346.847V91.6246C346.847 85.9366 343.823 83.2726 339.215 83.2726C333.671 83.2726 329.927 87.2326 329.927 93.7846V114.017H320.855Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M363.471 95.1526C363.471 83.4166 371.679 75.6406 383.343 75.6406C393.423 75.6406 400.983 81.2566 402.279 89.6086H393.063C391.839 85.7206 387.951 83.4886 383.343 83.4886C376.719 83.4886 372.759 88.1686 372.759 95.2246C372.759 102.353 376.935 106.889 383.415 106.889C388.311 106.889 391.839 104.513 393.063 100.769H402.279C400.911 109.265 393.423 114.737 383.487 114.737C371.679 114.737 363.471 106.889 363.471 95.1526Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M437.348 102.497H446.348C444.404 110.201 437.204 114.737 427.988 114.737C415.82 114.737 407.9 106.889 407.9 95.1526C407.9 83.4886 415.82 75.6406 427.484 75.6406C438.428 75.6406 445.916 82.6246 445.916 92.9206C445.916 94.2166 445.772 95.0806 445.556 96.3046H417.044V97.0246C417.044 103.721 421.436 107.465 427.916 107.465C432.524 107.465 435.98 105.521 437.348 102.497ZM427.268 82.8406C422.084 82.8406 418.124 85.8646 417.26 90.5446H436.628C436.556 86.0086 432.668 82.8406 427.268 82.8406Z"
          fill={renderColor || '#344145'}
        />
        <g clipPath="url(#clip1_9121_7595)">
          <path
            d="M208.414 9.94043H203.654V29.35H208.414V9.94043Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M208.414 3.42627H203.654V7.55243H208.414V3.42627Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M233.159 1.5166V29.35H228.957L228.578 26.4555C227.219 28.4614 224.424 29.7216 221.1 29.7216C215.131 29.7216 211.278 25.6396 211.278 19.7024C211.278 13.5781 215.432 9.56886 221.478 9.56886C224.347 9.56886 226.878 10.5328 228.391 12.2399V1.5166H233.159ZM228.399 19.9258V19.4789C228.399 15.8412 225.905 13.5027 222.239 13.5027C218.463 13.5027 216.123 15.9504 216.123 19.6244C216.123 23.4466 218.577 25.7851 222.205 25.7851C225.833 25.7851 228.399 23.5999 228.399 19.9258Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M241.637 19.1491L234.465 9.94057H240.376L244.94 15.9505C245.02 16.0544 245.124 16.1387 245.243 16.1967C245.362 16.2547 245.493 16.2849 245.625 16.2849C245.758 16.2849 245.889 16.2547 246.008 16.1967C246.126 16.1387 246.23 16.0544 246.31 15.9505L251.028 9.93018H256.772L249.486 19.1335C249.368 19.2817 249.304 19.4647 249.304 19.6532C249.304 19.8416 249.368 20.0246 249.486 20.1728L256.809 29.3372H250.991L246.26 23.4156C246.18 23.3136 246.077 23.2311 245.959 23.1743C245.841 23.1175 245.712 23.088 245.58 23.088C245.449 23.088 245.32 23.1175 245.202 23.1743C245.084 23.2311 244.981 23.3136 244.901 23.4156L240.249 29.3424H234.465L241.637 20.1702C241.751 20.0235 241.813 19.8441 241.813 19.6597C241.813 19.4752 241.751 19.2958 241.637 19.1491Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M198.883 9.94042L202.059 14.1545H195.696C195.212 14.1545 194.748 14.3434 194.406 14.6796C194.064 15.0158 193.872 15.6594 193.872 16.1349V29.35H189.204V15.8387C189.204 11.5384 191.661 9.93262 194.731 9.93262L198.883 9.94042Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M181.395 9.94026L181.093 12.6503C179.771 10.7951 177.127 9.57129 173.916 9.57129C168.098 9.57129 164.285 13.5779 164.285 19.1851C164.285 24.7508 168.101 28.76 173.916 28.76C176.939 28.76 179.62 27.5726 180.829 25.7174V28.6847C180.829 31.8027 178.488 33.7333 174.974 33.7333C173.022 33.7333 171.488 33.0915 170.441 32.1743C170.275 32.0261 169.939 31.7455 169.939 31.7455L166.895 34.7856C167.158 35.0508 167.441 35.2966 167.741 35.5209C169.449 36.7967 171.859 37.5216 174.9 37.5216C181.395 37.5216 185.589 34.1074 185.589 28.5028V9.94026H181.395ZM180.829 19.3307C180.829 22.7449 178.224 24.9353 174.86 24.9353C171.234 24.9353 169.119 22.5214 169.119 19.1462C169.119 15.6566 171.385 13.3934 174.9 13.3934C178.261 13.3934 180.829 15.4721 180.829 18.8863V19.3307Z"
            fill={renderColor || '#344145'}
          />
        </g>
        <defs>
          <clipPath id="clip0_9121_7595">
            <rect width={134} height={134} fill="white" />
          </clipPath>
          <clipPath id="clip1_9121_7595">
            <rect
              width={99}
              height={39}
              fill="white"
              transform="translate(162 0.0166016)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (showBrandName) {
    return (
      <svg
        width={447}
        height={134}
        viewBox="0 0 447 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_9087_4017)">
          <path
            d="M28.3818 71.3701L14.2778 57.3699L7.21808e-05 71.5426L14.104 85.5429L28.3818 71.3701Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M64.5536 58.6032C55.2504 60.3233 45.7382 62.0677 38.6102 61.2382L24.8828 74.8647C30.421 77.4547 36.4974 78.7087 42.6167 78.5245C50.8384 78.5245 59.5272 76.9264 67.6997 75.4137C78.4162 73.4374 89.3908 71.4245 96.7399 73.3398L109.73 60.4452C97.1947 52.6011 79.9647 55.7486 64.5536 58.6032Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M24.5762 46.416C24.3695 43.8985 24.7006 41.366 25.5477 38.9842C26.3948 36.6024 27.7389 34.425 29.492 32.5943L16.9567 20.8831C13.5529 24.466 10.9486 28.7213 9.31378 33.371C7.67897 38.0208 7.05044 42.9605 7.46914 47.8677C7.84726 52.2877 9.06486 56.5967 11.0577 60.5671L24.6746 47.0504C24.6623 46.843 24.5762 46.6234 24.5762 46.416Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M110.358 87.7952C110.54 90.2098 110.227 92.636 109.437 94.9271C108.648 97.2182 107.399 99.3266 105.765 101.125C104.132 102.923 102.148 104.374 99.9335 105.389C97.7191 106.405 95.3203 106.964 92.882 107.033C89.6552 107.113 86.4653 106.338 83.6402 104.789L71.2769 117.11C77.3611 121.668 84.7799 124.127 92.4026 124.112H93.3981C102.342 123.855 110.851 120.229 117.201 113.972C123.55 107.715 127.264 99.2936 127.588 90.418C127.792 84.7885 126.624 79.1933 124.183 74.1077L110.358 87.7952Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M105.644 64.5103L119.722 78.4846L134 64.3119L119.922 50.3376L105.644 64.5103Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M41.5723 9.90337C36.9478 10.0308 32.3946 11.0667 28.1757 12.9512C23.9569 14.8357 20.1562 17.5315 16.9932 20.8827L29.4916 32.5939C32.2229 29.7158 35.8386 27.818 39.7725 27.1977C43.7065 26.5774 47.7366 27.2696 51.2319 29.1659L63.6567 16.8325C57.2993 12.1045 49.5122 9.66125 41.5723 9.90337Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M75.9123 90.5891V45.1836C75.7772 38.2544 74.8186 28.4707 68.4649 21.2L56.1753 33.3992C57.7606 35.839 58.6332 39.8403 58.7069 45.4398V91.2112C58.713 91.2434 58.713 91.2766 58.7069 91.3088V91.4674C59.3829 100.617 61.9022 107.607 66.4002 112.743L78.6898 100.544C77.1782 98.3233 76.2687 95.0296 75.9123 90.5891Z"
            fill={renderColor || '#FFA44F'}
          />
        </g>
        <path
          d="M201.048 66.5679C206.52 68.4399 209.616 73.1919 209.616 79.0239C209.616 86.9439 204.216 93.6399 192.336 93.6399H168V41.4399H191.688C201.984 41.4399 207.888 47.0559 207.888 55.4079C207.888 60.2319 205.368 64.3359 201.048 66.5679ZM177.432 49.0719V63.4719H189.6C195.504 63.4719 198.096 60.4479 198.096 56.0559C198.096 51.5919 195.288 49.0719 190.464 49.0719H177.432ZM190.68 85.9359C196.728 85.9359 199.608 82.7679 199.608 78.2319C199.608 73.0479 196.224 70.5279 190.968 70.5279H177.432V85.9359H190.68Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M215.752 83.3439C215.752 76.7919 220.072 72.7599 227.776 71.8959L240.88 70.4559V68.7999C240.88 64.4799 237.568 62.4639 232.888 62.4639C227.776 62.4639 224.968 64.9839 225.112 69.0879H216.76C216.544 60.6639 222.808 55.2639 233.68 55.2639C243.688 55.2639 249.952 59.8719 249.952 69.0879V93.6399H242.032L241.528 86.9439C239.584 91.5519 234.256 94.3599 228.064 94.3599C220.648 94.3599 215.752 90.0399 215.752 83.3439ZM224.752 82.1199C224.752 85.2879 226.84 87.3039 230.872 87.3039C236.488 87.3039 240.88 83.5599 240.88 78.0159V75.5679L230.296 76.9359C226.336 77.4399 224.752 79.3839 224.752 82.1199Z"
          fill={renderColor || '#344145'}
        />
        <path d="M259.59 93.6399V39.6399H268.662V93.6399H259.59Z" fill={renderColor || '#344145'} />
        <path
          d="M277.118 83.3439C277.118 76.7919 281.438 72.7599 289.142 71.8959L302.246 70.4559V68.7999C302.246 64.4799 298.934 62.4639 294.254 62.4639C289.142 62.4639 286.334 64.9839 286.478 69.0879H278.126C277.91 60.6639 284.174 55.2639 295.046 55.2639C305.054 55.2639 311.318 59.8719 311.318 69.0879V93.6399H303.398L302.894 86.9439C300.95 91.5519 295.622 94.3599 289.43 94.3599C282.014 94.3599 277.118 90.0399 277.118 83.3439ZM286.118 82.1199C286.118 85.2879 288.206 87.3039 292.238 87.3039C297.854 87.3039 302.246 83.5599 302.246 78.0159V75.5679L291.662 76.9359C287.702 77.4399 286.118 79.3839 286.118 82.1199Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M321.167 93.6399V55.9839H329.519L330.311 61.4559C332.543 57.6399 337.151 55.2639 342.479 55.2639C351.551 55.2639 356.231 60.8799 356.231 69.9519V93.6399H347.159V71.2479C347.159 65.5599 344.135 62.8959 339.527 62.8959C333.983 62.8959 330.239 66.8559 330.239 73.4079V93.6399H321.167Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M363.783 74.7759C363.783 63.0399 371.991 55.2639 383.655 55.2639C393.735 55.2639 401.295 60.8799 402.591 69.2319H393.375C392.151 65.3439 388.263 63.1119 383.655 63.1119C377.031 63.1119 373.071 67.7919 373.071 74.8479C373.071 81.9759 377.247 86.5119 383.727 86.5119C388.623 86.5119 392.151 84.1359 393.375 80.3919H402.591C401.223 88.8879 393.735 94.3599 383.799 94.3599C371.991 94.3599 363.783 86.5119 363.783 74.7759Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M437.66 82.1199H446.66C444.716 89.8239 437.516 94.3599 428.3 94.3599C416.132 94.3599 408.212 86.5119 408.212 74.7759C408.212 63.1119 416.132 55.2639 427.796 55.2639C438.74 55.2639 446.228 62.2479 446.228 72.5439C446.228 73.8399 446.084 74.7039 445.868 75.9279H417.356V76.6479C417.356 83.3439 421.748 87.0879 428.228 87.0879C432.836 87.0879 436.292 85.1439 437.66 82.1199ZM427.58 62.4639C422.396 62.4639 418.436 65.4879 417.572 70.1679H436.94C436.868 65.6319 432.98 62.4639 427.58 62.4639Z"
          fill={renderColor || '#344145'}
        />
        <defs>
          <clipPath id="clip0_9087_4017">
            <rect width={134} height={134} fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 64 64"
        {...props}
      >
        <path
          fill={renderColor || '#D4E9EC'}
          d="m13.556 30.087-6.737-6.686L0 30.17l6.736 6.686 6.82-6.769Z"
        />
        <path
          fill={renderColor || '#344145'}
          d="M30.832 23.99c-4.444.821-8.987 1.654-12.391 1.258l-6.556 6.508a18.67 18.67 0 0 0 8.47 1.748c3.926 0 8.076-.763 11.98-1.485 5.118-.944 10.36-1.906 13.87-.991l6.204-6.159c-5.987-3.746-14.217-2.243-21.577-.88ZM11.738 18.169a8.512 8.512 0 0 1 .464-3.55 8.56 8.56 0 0 1 1.884-3.051L8.099 5.974a16.706 16.706 0 0 0-3.65 5.964 16.613 16.613 0 0 0-.882 6.924c.181 2.111.763 4.17 1.714 6.066l6.504-6.456c-.006-.1-.047-.204-.047-.303ZM52.708 37.932a8.496 8.496 0 0 1-.44 3.406 8.541 8.541 0 0 1-1.753 2.96 8.613 8.613 0 0 1-2.785 2.037 8.668 8.668 0 0 1-3.368.785 8.733 8.733 0 0 1-4.414-1.072l-5.905 5.885a16.774 16.774 0 0 0 10.09 3.345h.475a16.889 16.889 0 0 0 11.369-4.843 16.648 16.648 0 0 0 4.96-11.25 16.613 16.613 0 0 0-1.625-7.79l-6.604 6.537Z"
        />
        <path
          fill={renderColor || '#FFA44F'}
          d="m50.457 26.81 6.724 6.675L64 26.716l-6.725-6.674-6.819 6.769Z"
        />
        <path
          fill={renderColor || '#D4E9EC'}
          d="M19.855.73c-2.208.06-4.383.556-6.398 1.456-2.015.9-3.83 2.187-5.34 3.788l5.969 5.593a8.619 8.619 0 0 1 4.91-2.577 8.654 8.654 0 0 1 5.473.94l5.934-5.89A16.819 16.819 0 0 0 19.856.73Z"
        />
        <path
          fill={renderColor || '#FFA44F'}
          d="M36.257 39.267V17.58c-.065-3.31-.523-7.982-3.557-11.455l-5.87 5.827c.757 1.165 1.174 3.076 1.21 5.75v21.862a.126.126 0 0 1 0 .046v.076c.322 4.37 1.525 7.709 3.674 10.161l5.87-5.826c-.723-1.06-1.157-2.634-1.327-4.754Z"
        />
      </svg>
    );
  }
};
export default Balance;
