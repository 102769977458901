import { useContext } from 'react';
import axios from 'axios';
import { ExploreContext } from '../helpers/ExploreContext';

export const useAxios = () => {
  const { state: { apiUrl } } = useContext(ExploreContext);

  const axiosInstance = axios.create({
    baseURL: apiUrl
  });

  axiosInstance.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${sessionStorage.getItem('token')}`;
    return config;
  });

  return axiosInstance;
};
