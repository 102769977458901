import * as React from 'react';

import colors from '../../../style/colors';

const Calculate = (props:any) => {
  const { color, showBrandName, showGridX } = props;

  let renderColor;
  switch (color) {
  case 'deep':
    renderColor = colors.deep;
    break;
  case 'energy':
    renderColor = colors.energy;
    break;
  case 'white':
    renderColor = colors.white;
    break;
  }

  if (showGridX) {
    return (
      <svg
        width={504}
        height={134}
        viewBox="0 0 504 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M44.4106 90.3188C42.78 92.1213 41.2515 94.0124 39.832 95.9832C35.7391 101.496 33.8661 104.018 27.1509 104.018V123.313C43.6891 123.313 50.7373 113.748 55.4685 107.422C59.7279 101.689 61.3373 99.8697 66.8731 99.8697C62.6542 100.002 58.4574 99.2156 54.5769 97.5657C50.6965 95.9158 47.2265 93.4423 44.4106 90.3188Z"
          fill={renderColor || '#D4E9EC'}
        />
        <path d="M19.4241 104.046H0V123.34H19.4241V104.046Z" fill={renderColor || '#D4E9EC'} />
        <path d="M19.4241 57.3525H0V76.6471H19.4241V57.3525Z" fill={renderColor || '#344145'} />
        <path d="M134 57.3525H114.576V76.6471H134V57.3525Z" fill={renderColor || '#FFA44F'} />
        <path d="M19.4241 10.6602H0V29.9548H19.4241V10.6602Z" fill={renderColor || '#D4E9EC'} />
        <path
          d="M55.3595 73.2302C53.8375 71.1682 52.1928 69.1984 50.4341 67.3315C45.6891 72.3344 38.738 77.0615 27.1113 77.0615C33.9098 77.0615 35.9077 79.6663 39.8757 84.8759C41.2741 86.7654 42.7747 88.5781 44.371 90.3059C47.1915 93.4348 50.6682 95.9115 54.5563 97.5616C58.4444 99.2117 62.6493 99.9951 66.8752 99.8567V80.5621C61.0063 80.5621 59.3553 78.4259 55.3595 73.2302Z"
          fill={renderColor || '#FFA44F'}
        />
        <path
          d="M55.3595 26.9783C50.6561 20.8316 43.5663 11.5288 27.1113 11.5288V30.8234C33.9098 30.8234 35.9077 33.4282 39.8757 38.6377C41.2706 40.5299 42.7714 42.3428 44.371 44.0678C47.1571 40.895 50.6168 38.3755 54.5009 36.6907C58.3851 35.0058 62.5971 34.1975 66.8336 34.324C61.0063 34.324 59.3553 32.174 55.3595 26.9783Z"
          fill={renderColor || '#FFA44F'}
        />
        <path
          d="M44.4106 44.0671C42.7833 45.8724 41.255 47.7633 39.832 49.7315C35.7391 55.2443 33.8661 57.7663 27.1509 57.7663V77.0609C38.7776 77.0609 45.7286 72.3337 50.4736 67.3309C52.2682 65.3845 53.9365 63.3269 55.4685 61.1704C59.7279 55.4372 61.3373 53.618 66.8731 53.618V34.3234C62.6367 34.1969 58.4247 35.0052 54.5405 36.6901C50.6563 38.3749 47.1966 40.8944 44.4106 44.0671Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M93.9134 49.3461C89.3626 43.3786 82.481 34.3652 66.9556 34.3652V53.6598C72.8244 53.6598 74.4616 55.8098 78.4297 61.0055C79.9516 63.0676 81.5963 65.0373 83.355 66.9042C88.1001 61.9014 95.0511 57.1742 106.664 57.1742C99.8794 57.1328 97.8815 54.5556 93.9134 49.3461Z"
          fill={renderColor || '#FFA44F'}
        />
        <path
          d="M83.355 66.9037C81.5637 68.8565 79.8912 70.9136 78.3464 73.0641C74.1009 78.7974 72.4776 80.6166 66.9556 80.6166V99.9112C82.5642 99.9112 89.4321 90.636 93.9828 84.5031C98.1451 78.9903 99.9487 76.4683 106.664 76.4683V57.1323C95.0511 57.1323 88.1001 61.9008 83.355 66.9037Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M165.456 87.8806C165.456 71.8246 175.968 61.0966 191.592 61.0966C204.408 61.0966 214.056 68.2966 216.144 79.2406H205.992C204.192 73.3366 198.648 69.6646 191.592 69.6646C181.656 69.6646 175.464 77.0086 175.464 87.8806C175.464 99.1846 181.944 106.097 191.52 106.097C198.72 106.097 204.264 102.209 205.992 96.5206H216.144C214.056 107.537 204.408 114.737 191.592 114.737C175.968 114.737 165.456 103.937 165.456 87.8806Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M222.612 103.721C222.612 97.1686 226.932 93.1366 234.636 92.2726L247.74 90.8326V89.1766C247.74 84.8566 244.428 82.8406 239.748 82.8406C234.636 82.8406 231.828 85.3606 231.972 89.4646H223.62C223.404 81.0406 229.668 75.6406 240.54 75.6406C250.548 75.6406 256.812 80.2486 256.812 89.4646V114.017H248.892L248.388 107.321C246.444 111.929 241.116 114.737 234.924 114.737C227.508 114.737 222.612 110.417 222.612 103.721ZM231.612 102.497C231.612 105.665 233.7 107.681 237.732 107.681C243.348 107.681 247.74 103.937 247.74 98.3926V95.9446L237.156 97.3126C233.196 97.8166 231.612 99.7606 231.612 102.497Z"
          fill={renderColor || '#344145'}
        />
        <path d="M266.45 114.017V60.0166H275.522V114.017H266.45Z" fill={renderColor || '#344145'} />
        <path
          d="M283.12 95.1526C283.12 83.4166 291.328 75.6406 302.992 75.6406C313.072 75.6406 320.632 81.2566 321.928 89.6086H312.712C311.488 85.7206 307.6 83.4886 302.992 83.4886C296.368 83.4886 292.408 88.1686 292.408 95.2246C292.408 102.353 296.584 106.889 303.064 106.889C307.96 106.889 311.488 104.513 312.712 100.769H321.928C320.56 109.265 313.072 114.737 303.136 114.737C291.328 114.737 283.12 106.889 283.12 95.1526Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M329.644 99.0406V76.3606H338.716V99.0406C338.716 104.297 341.812 106.961 346.78 106.961C352.18 106.961 354.844 103.793 354.844 99.0406V76.3606H363.916V99.0406C363.916 109.481 357.148 114.737 346.708 114.737C335.26 114.737 329.644 108.473 329.644 99.0406Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M373.792 114.017V60.0166H382.864V114.017H373.792Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M391.319 103.721C391.319 97.1686 395.639 93.1366 403.343 92.2726L416.447 90.8326V89.1766C416.447 84.8566 413.135 82.8406 408.455 82.8406C403.343 82.8406 400.535 85.3606 400.679 89.4646H392.327C392.111 81.0406 398.376 75.6406 409.247 75.6406C419.255 75.6406 425.52 80.2486 425.52 89.4646V114.017H417.599L417.095 107.321C415.151 111.929 409.823 114.737 403.631 114.737C396.215 114.737 391.319 110.417 391.319 103.721ZM400.319 102.497C400.319 105.665 402.407 107.681 406.439 107.681C412.055 107.681 416.447 103.937 416.447 98.3926V95.9446L405.863 97.3126C401.903 97.8166 400.319 99.7606 400.319 102.497Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M441.509 114.017V83.8486H430.925V76.3606H441.509V66.7126L450.581 66.2086V76.3606H461.237V83.8486H450.581V114.017H441.509Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M494.284 102.497H503.284C501.34 110.201 494.14 114.737 484.924 114.737C472.756 114.737 464.836 106.889 464.836 95.1526C464.836 83.4886 472.756 75.6406 484.42 75.6406C495.364 75.6406 502.852 82.6246 502.852 92.9206C502.852 94.2166 502.708 95.0806 502.492 96.3046H473.98V97.0246C473.98 103.721 478.372 107.465 484.852 107.465C489.46 107.465 492.916 105.521 494.284 102.497ZM484.204 82.8406C479.02 82.8406 475.06 85.8646 474.196 90.5446H493.564C493.492 86.0086 489.604 82.8406 484.204 82.8406Z"
          fill={renderColor || '#344145'}
        />
        <g clipPath="url(#clip0_9122_7794)">
          <path
            d="M208.414 9.94043H203.654V29.35H208.414V9.94043Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M208.414 3.42627H203.654V7.55243H208.414V3.42627Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M233.159 1.5166V29.35H228.957L228.578 26.4555C227.219 28.4614 224.424 29.7216 221.1 29.7216C215.131 29.7216 211.278 25.6396 211.278 19.7024C211.278 13.5781 215.432 9.56886 221.478 9.56886C224.347 9.56886 226.878 10.5328 228.391 12.2399V1.5166H233.159ZM228.399 19.9258V19.4789C228.399 15.8412 225.905 13.5027 222.239 13.5027C218.463 13.5027 216.123 15.9504 216.123 19.6244C216.123 23.4466 218.577 25.7851 222.205 25.7851C225.833 25.7851 228.399 23.5999 228.399 19.9258Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M241.637 19.1491L234.465 9.94057H240.376L244.94 15.9505C245.02 16.0544 245.124 16.1387 245.243 16.1967C245.362 16.2547 245.493 16.2849 245.625 16.2849C245.758 16.2849 245.889 16.2547 246.008 16.1967C246.126 16.1387 246.23 16.0544 246.31 15.9505L251.028 9.93018H256.772L249.486 19.1335C249.368 19.2817 249.304 19.4647 249.304 19.6532C249.304 19.8416 249.368 20.0246 249.486 20.1728L256.809 29.3372H250.991L246.26 23.4156C246.18 23.3136 246.077 23.2311 245.959 23.1743C245.841 23.1175 245.712 23.088 245.58 23.088C245.449 23.088 245.32 23.1175 245.202 23.1743C245.084 23.2311 244.981 23.3136 244.901 23.4156L240.249 29.3424H234.465L241.637 20.1702C241.751 20.0235 241.813 19.8441 241.813 19.6597C241.813 19.4752 241.751 19.2958 241.637 19.1491Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M198.883 9.94042L202.059 14.1545H195.696C195.212 14.1545 194.748 14.3434 194.406 14.6796C194.064 15.0158 193.872 15.6594 193.872 16.1349V29.35H189.204V15.8387C189.204 11.5384 191.661 9.93262 194.731 9.93262L198.883 9.94042Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M181.395 9.94026L181.093 12.6503C179.771 10.7951 177.127 9.57129 173.916 9.57129C168.098 9.57129 164.285 13.5779 164.285 19.1851C164.285 24.7508 168.101 28.76 173.916 28.76C176.939 28.76 179.62 27.5726 180.829 25.7174V28.6847C180.829 31.8027 178.488 33.7333 174.974 33.7333C173.022 33.7333 171.488 33.0915 170.441 32.1743C170.275 32.0261 169.939 31.7455 169.939 31.7455L166.895 34.7856C167.158 35.0508 167.441 35.2966 167.741 35.5209C169.449 36.7967 171.859 37.5216 174.9 37.5216C181.395 37.5216 185.589 34.1074 185.589 28.5028V9.94026H181.395ZM180.829 19.3307C180.829 22.7449 178.224 24.9353 174.86 24.9353C171.234 24.9353 169.119 22.5214 169.119 19.1462C169.119 15.6566 171.385 13.3934 174.9 13.3934C178.261 13.3934 180.829 15.4721 180.829 18.8863V19.3307Z"
            fill={renderColor || '#344145'}
          />
        </g>
        <defs>
          <clipPath id="clip0_9122_7794">
            <rect
              width={99}
              height={39}
              fill="white"
              transform="translate(162 0.0166016)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (showBrandName) {
    return (
      <svg
        width={507}
        height={134}
        viewBox="0 0 507 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M44.4108 90.3186C42.7803 92.1211 41.2518 94.0121 39.8323 95.983C35.7394 101.496 33.8663 104.018 27.1511 104.018V123.312C43.6893 123.312 50.7375 113.748 55.4687 107.422C59.7281 101.689 61.3375 99.8695 66.8734 99.8695C62.6545 100.002 58.4576 99.2154 54.5772 97.5654C50.6967 95.9155 47.2267 93.442 44.4108 90.3186Z"
          fill={renderColor || '#D4E9EC'}
        />
        <path d="M19.4241 104.046H0V123.34H19.4241V104.046Z" fill={renderColor || '#D4E9EC'} />
        <path d="M19.4241 57.3523H0V76.6469H19.4241V57.3523Z" fill={renderColor || '#344145'} />
        <path d="M134 57.3523H114.576V76.6469H134V57.3523Z" fill={renderColor || '#FFA44F'} />
        <path d="M19.4241 10.6599H0V29.9545H19.4241V10.6599Z" fill={renderColor || '#D4E9EC'} />
        <path
          d="M55.3595 73.2299C53.8375 71.1679 52.1928 69.1982 50.4341 67.3313C45.6891 72.3341 38.738 77.0613 27.1113 77.0613C33.9098 77.0613 35.9077 79.6661 39.8757 84.8756C41.2741 86.7651 42.7747 88.5778 44.371 90.3057C47.1915 93.4345 50.6682 95.9112 54.5563 97.5613C58.4444 99.2115 62.6493 99.9949 66.8752 99.8565V80.5619C61.0063 80.5619 59.3553 78.4257 55.3595 73.2299Z"
          fill={renderColor || '#FFA44F'}
        />
        <path
          d="M55.3595 26.9778C50.6561 20.8311 43.5663 11.5283 27.1113 11.5283V30.8229C33.9098 30.8229 35.9077 33.4277 39.8757 38.6372C41.2706 40.5294 42.7714 42.3423 44.371 44.0673C47.1571 40.8945 50.6168 38.375 54.5009 36.6902C58.3851 35.0053 62.5971 34.197 66.8336 34.3235C61.0063 34.3235 59.3553 32.1735 55.3595 26.9778Z"
          fill={renderColor || '#FFA44F'}
        />
        <path
          d="M44.4108 44.0671C42.7836 45.8724 41.2552 47.7633 39.8323 49.7315C35.7394 55.2443 33.8663 57.7663 27.1511 57.7663V77.0609C38.7778 77.0609 45.7289 72.3337 50.4739 67.3309C52.2684 65.3845 53.9367 63.3269 55.4687 61.1704C59.7281 55.4372 61.3375 53.618 66.8734 53.618V34.3234C62.6369 34.1969 58.4249 35.0052 54.5407 36.6901C50.6566 38.3749 47.1969 40.8944 44.4108 44.0671Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M93.9134 49.3459C89.3626 43.3783 82.481 34.365 66.9556 34.365V53.6596C72.8244 53.6596 74.4616 55.8095 78.4297 61.0053C79.9516 63.0673 81.5963 65.037 83.355 66.9039C88.1001 61.9011 95.0511 57.1739 106.664 57.1739C99.8794 57.1326 97.8815 54.5554 93.9134 49.3459Z"
          fill={renderColor || '#FFA44F'}
        />
        <path
          d="M83.355 66.9037C81.5637 68.8565 79.8912 70.9136 78.3464 73.0641C74.1009 78.7974 72.4776 80.6166 66.9556 80.6166V99.9112C82.5642 99.9112 89.4321 90.636 93.9828 84.5031C98.1451 78.9903 99.9487 76.4683 106.664 76.4683V57.1323C95.0511 57.1323 88.1001 61.9008 83.355 66.9037Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M165.456 62.864C165.456 46.808 175.968 36.08 191.592 36.08C204.408 36.08 214.056 43.28 216.144 54.224H205.992C204.192 48.32 198.648 44.648 191.592 44.648C181.656 44.648 175.464 51.992 175.464 62.864C175.464 74.168 181.944 81.08 191.52 81.08C198.72 81.08 204.264 77.192 205.992 71.504H216.144C214.056 82.52 204.408 89.72 191.592 89.72C175.968 89.72 165.456 78.92 165.456 62.864Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M222.612 78.704C222.612 72.152 226.932 68.12 234.636 67.256L247.74 65.816V64.16C247.74 59.84 244.428 57.824 239.748 57.824C234.636 57.824 231.828 60.344 231.972 64.448H223.62C223.404 56.024 229.668 50.624 240.54 50.624C250.548 50.624 256.812 55.232 256.812 64.448V89H248.892L248.388 82.304C246.444 86.912 241.116 89.72 234.924 89.72C227.508 89.72 222.612 85.4 222.612 78.704ZM231.612 77.48C231.612 80.648 233.7 82.664 237.732 82.664C243.348 82.664 247.74 78.92 247.74 73.376V70.928L237.156 72.296C233.196 72.8 231.612 74.744 231.612 77.48Z"
          fill={renderColor || '#344145'}
        />
        <path d="M266.45 89V35H275.522V89H266.45Z" fill={renderColor || '#344145'} />
        <path
          d="M283.12 70.136C283.12 58.4 291.328 50.624 302.992 50.624C313.072 50.624 320.632 56.24 321.928 64.592H312.712C311.488 60.704 307.6 58.472 302.992 58.472C296.368 58.472 292.408 63.152 292.408 70.208C292.408 77.336 296.584 81.872 303.064 81.872C307.96 81.872 311.488 79.496 312.712 75.752H321.928C320.56 84.248 313.072 89.72 303.136 89.72C291.328 89.72 283.12 81.872 283.12 70.136Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M329.644 74.024V51.344H338.716V74.024C338.716 79.28 341.812 81.944 346.78 81.944C352.18 81.944 354.844 78.776 354.844 74.024V51.344H363.916V74.024C363.916 84.464 357.148 89.72 346.708 89.72C335.26 89.72 329.644 83.456 329.644 74.024Z"
          fill={renderColor || '#344145'}
        />
        <path d="M373.792 89V35H382.864V89H373.792Z" fill={renderColor || '#344145'} />
        <path
          d="M391.319 78.704C391.319 72.152 395.639 68.12 403.343 67.256L416.447 65.816V64.16C416.447 59.84 413.135 57.824 408.455 57.824C403.343 57.824 400.535 60.344 400.679 64.448H392.327C392.111 56.024 398.376 50.624 409.247 50.624C419.255 50.624 425.52 55.232 425.52 64.448V89H417.599L417.095 82.304C415.151 86.912 409.823 89.72 403.631 89.72C396.215 89.72 391.319 85.4 391.319 78.704ZM400.319 77.48C400.319 80.648 402.407 82.664 406.439 82.664C412.055 82.664 416.447 78.92 416.447 73.376V70.928L405.863 72.296C401.903 72.8 400.319 74.744 400.319 77.48Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M441.509 89V58.832H430.925V51.344H441.509V41.696L450.581 41.192V51.344H461.237V58.832H450.581V89H441.509Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M494.284 77.48H503.284C501.34 85.184 494.14 89.72 484.924 89.72C472.756 89.72 464.836 81.872 464.836 70.136C464.836 58.472 472.756 50.624 484.42 50.624C495.364 50.624 502.852 57.608 502.852 67.904C502.852 69.2 502.708 70.064 502.492 71.288H473.98V72.008C473.98 78.704 478.372 82.448 484.852 82.448C489.46 82.448 492.916 80.504 494.284 77.48ZM484.204 57.824C479.02 57.824 475.06 60.848 474.196 65.528H493.564C493.492 60.992 489.604 57.824 484.204 57.824Z"
          fill={renderColor || '#344145'}
        />
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 64 64"
        {...props}
      >
        <path
          fill={renderColor || '#D4E9EC'}
          d="M21.211 38.137a31.31 31.31 0 0 0-2.187 2.706c-1.955 2.632-2.85 3.837-6.057 3.837v9.215c7.9 0 11.266-4.568 13.525-7.59 2.035-2.737 2.803-3.606 5.447-3.606a13.898 13.898 0 0 1-5.872-1.1 13.814 13.814 0 0 1-4.856-3.462ZM9.277 44.694H0v9.215h9.277v-9.215Z"
        />
        <path fill={renderColor || '#344145'} d="M9.277 22.392H0v9.215h9.277v-9.215Z" />
        <path fill={renderColor || '#FFA44F'} d="M64 22.392h-9.277v9.215H64v-9.215Z" />
        <path fill={renderColor || '#D4E9EC'} d="M9.277.091H0v9.216h9.277V.09Z" />
        <path
          fill={renderColor || '#FFA44F'}
          d="M26.44 29.976a30.668 30.668 0 0 0-2.352-2.818c-2.266 2.39-5.586 4.647-11.14 4.647 3.248 0 4.202 1.244 6.097 3.733a30.969 30.969 0 0 0 2.147 2.593 13.814 13.814 0 0 0 4.865 3.465 13.898 13.898 0 0 0 5.883 1.097v-9.216c-2.803 0-3.591-1.02-5.5-3.501ZM26.44 7.885C24.194 4.949 20.808.506 12.95.506v9.215c3.247 0 4.201 1.245 6.096 3.733.666.903 1.383 1.77 2.147 2.593a13.685 13.685 0 0 1 4.838-3.523 13.77 13.77 0 0 1 5.89-1.13c-2.783 0-3.571-1.027-5.48-3.51Z"
        />
        <path
          fill={renderColor || '#344145'}
          d="M21.211 16.047a32.34 32.34 0 0 0-2.187 2.705c-1.955 2.633-2.85 3.838-6.057 3.838v9.215c5.554 0 8.873-2.258 11.14-4.647a29.788 29.788 0 0 0 2.385-2.942c2.035-2.739 2.803-3.607 5.447-3.607v-9.216a13.771 13.771 0 0 0-5.89 1.13 13.684 13.684 0 0 0-4.838 3.524Z"
        />
        <path
          fill={renderColor || '#FFA44F'}
          d="M44.854 18.568c-2.173-2.85-5.46-7.155-12.875-7.155v9.215c2.803 0 3.585 1.027 5.48 3.509a30.684 30.684 0 0 0 2.352 2.817c2.267-2.39 5.587-4.647 11.133-4.647-3.24-.02-4.195-1.25-6.09-3.739Z"
        />
        <path
          fill={renderColor || '#344145'}
          d="M39.811 26.954a31.797 31.797 0 0 0-2.392 2.942c-2.028 2.739-2.803 3.608-5.44 3.608v9.215c7.455 0 10.735-4.43 12.908-7.36 1.988-2.632 2.85-3.837 6.057-3.837v-9.235c-5.547 0-8.866 2.278-11.133 4.667Z"
        />
      </svg>
    );
  }
};
export default Calculate;
