export const columns = [
  {
    accessorKey: 'attribute',
    header: 'Attribute',
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    accessorKey: 'from',
    id: 'from',
    header: 'From',
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    accessorKey: 'to',
    header: 'To',
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    accessorKey: 'changedBy',
    header: 'Changed By',
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    accessorKey: 'changedOn',
    header: 'Changed on',
    enableColumnFilter: false
  }
];

export const data = [
  {
    attribute: 'Key',
    from: 'Value',
    to: 'Value',
    changedBy: 'person@company.com',
    changedOn: '2023-06-26 10:55 am'
  },
  {
    attribute: 'Key',
    from: 'Value',
    to: 'Value',
    changedBy: 'person@company.com',
    changedOn: '2023-06-26 10:55 am'
  },
  {
    attribute: 'Key',
    from: 'Value',
    to: 'Value',
    changedBy: 'person@company.com',
    changedOn: '2023-06-26 10:55 am'
  },
  {
    attribute: 'Key',
    from: 'Value',
    to: 'Value',
    changedBy: 'person@company.com',
    changedOn: '2023-06-26 10:55 am'
  },
  {
    attribute: 'Key',
    from: 'Value',
    to: 'Value',
    changedBy: 'person@company.com',
    changedOn: '2023-06-26 10:55 am'
  },
  {
    attribute: 'Key',
    from: 'Value',
    to: 'Value',
    changedBy: 'person@company.com',
    changedOn: '2023-06-26 10:55 am'
  },
  {
    attribute: 'Key',
    from: 'Value',
    to: 'Value',
    changedBy: 'person@company.com',
    changedOn: '2023-06-26 10:55 am'
  },
  {
    attribute: 'Key',
    from: 'Value',
    to: 'Value',
    changedBy: 'person@company.com',
    changedOn: '2023-06-26 10:55 am'
  },
  {
    attribute: 'Key',
    from: 'Value',
    to: 'Value',
    changedBy: 'person@company.com',
    changedOn: '2023-06-26 10:55 am'
  },
  {
    attribute: 'Key',
    from: 'Value',
    to: 'Value',
    changedBy: 'person@company.com',
    changedOn: '2023-06-26 10:55 am'
  }
];
