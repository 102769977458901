import React, { Children } from 'react';

// COMPONENTS
import Tag from 'components/Tag/Tag';

// ASSETS
import styles from './ImplementationItem.module.scss';
import RateThumb from '../../assets/rates-thumb.png';
import ModifierBattery from '../../assets/modifier-images/modifier-battery.jpg';

// HELPERS
import { Modifier } from 'widget/ExploreWidget/helpers/types';
import { getModifierIcon, getModifierImage } from 'widget/ExploreWidget/helpers/utils/getModifierIcon';

interface ImplementationItemProps {
  children?: React.ReactNode;
  footer?: React.ReactNode;
  body?: React.ReactNode;
  type?: 'modifier' | 'rate';
  modifier?: Modifier;
  tags?: Array<string>;
  title: string;
}

const ImplementationItem:React.FC<ImplementationItemProps> = ({
  footer = '',
  body = '',
  type = 'rate',
  modifier = 'solar',
  tags = [],
  title,
  children
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <div className={styles.titleContainer}>
            <div className={styles.icon}>
              <img src={type === 'rate' ? RateThumb : getModifierIcon(modifier)} alt="Battery" />
            </div>
            <p className={styles.title}>{title}</p>
          </div>
          {
            tags && tags.length > 0 && (
              <div className={styles.tags}>
                {
                  tags.map((item) => {
                    return (
                      <Tag key={item} copy={item} color='cloud' />
                    );
                  })
                }
              </div>
            )
          }
        </div>
        {
          type === 'modifier' && (
            <div className={styles.image} style={{ backgroundImage: `url(${getModifierImage(modifier)})` }} />
          )
        }
      </div>
      <div className={styles.body}>
        {children}
      </div>
      <div className={styles.footer}>
        {footer}
      </div>
    </div>
  );
};

export default ImplementationItem;
