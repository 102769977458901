import React from 'react';

// COMPONENTS
import { FilterSearchBar } from 'blocks/components';
import { Text } from 'components';
import { ListProjects } from './components';

// ASSETS
import styles from './Systems.module.scss';

// HELPERS
import { useSystems } from 'api/hooks';

const Systems = () => {
  const { data: systemsData, isLoading } = useSystems();

  return (
    <div className={`${styles['data-page']} admin--page pad--t-128 pad--l-128 pad--r-36`} role="main">
      <div className="pad--b-24">
        <Text className='title--md' variant='h2'>
          System
        </Text>
      </div>
      <FilterSearchBar/>
      <div className={styles.content}>
        {
          (!isLoading && systemsData) && (
            <>
              <ListProjects projects={systemsData} aria-label="List of Projects" />
            </>
          )
        }
      </div>
    </div>
  );
};

export default Systems;
