import React from 'react';

import FigmaEmbed from 'react-figma-embed';

import './figma-demos.scss';

interface Props {
  figmaEmbedLink?: string,
};

/**
* Represents a FigmaIframe.
* @constructor
* @param {string} figmaEmbedLink
* Usage :
* ````js
* <FigmaIframe figmaEmbedLink='www.figma.link' />
* ````
* @augments {Component<Props, State>}
*/
/** */

const FigmaIframe: React.FC<Props> = ({
  figmaEmbedLink
}) => {
  // const hideUI = '%26hide-ui%3D1';

  return (
    <div className='demo--page'>
      <FigmaEmbed className="figma--iframe" height="945px" width="1512px" url={`${figmaEmbedLink}` } />
    </div>
  );
};

export default FigmaIframe;
