import { InfoItem } from 'blocks/components';
import { Avatar, ExpandableSection, Tag } from 'components';
import React from 'react';

// ASSETS
import styles from './DeviceDetails.module.scss';

const DeviceDetails = () => {
  return (
    <div className={styles.main}>
      <ExpandableSection title="Virtual meter details" expanded>
        <div className={styles.container}>
          <div>
            <div className={styles['info-box']}>
              <InfoItem title="ID" description="2608237351137217" />
            </div>
            <div className={styles['info-box']}>
              <InfoItem title="Name" description="Time of Use Rate: 3-Period" />
            </div>
            <div className={`${styles['info-box']} ${styles['info-box__column']}`}>
              <InfoItem title="Status" description={<Tag copy='Draft' size="sm" color='bright' />} />
              <InfoItem title="Model" description={<Tag copy='Source' size="sm" color='sky' />} />
            </div>
            <div className={styles['info-box']}>
              <InfoItem title="Description" description="A revised rate plan with an optimised extended Time Of Use schedule." />
            </div>
          </div>
          <div>
            <div className={styles['info-box']}>
              <InfoItem title="Rate codes" description="A-RATE-1, A-RATE-1B, A-RATE-1C" />
            </div>
            <div className={styles['info-box']}>
              <InfoItem title="Contract model" description="234234234234234240" />
            </div>
            <div className={styles['info-box']}>
              <InfoItem
                title="Created"
                description={
                  <div className={styles.installed}>
                    <div>
                      <Avatar alt="ryan greenway" src='/images/login/ryan-greenway.png' customSize={24} />
                      <span>Ryan Greenway</span>
                    </div>
                    <div>
                      <span>March 7, 2023</span>
                    </div>
                  </div>
                }
              />
            </div>
            <div className={styles['info-box']}>
              <InfoItem
                title="Updated"
                description={
                  <div className={styles.installed}>
                    <div>
                      <Avatar alt="ryan greenway" src='/images/login/ryan-greenway.png' customSize={24} />
                      <span>Ryan Greenway</span>
                    </div>
                    <div>
                      <span>March 7, 2023</span>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </ExpandableSection>
    </div>
  );
};

export default DeviceDetails;
