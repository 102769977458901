import React from 'react';
import { TextInput } from 'components';

export const columns = [
  {
    accessorKey: 'attribute',
    header: 'Attribute',
    enableSorting: false
  },
  {
    accessorKey: 'from',
    id: 'from',
    header: 'From',
    enableColumnFilter: false,
    enableSorting: false
  },
  {
    accessorKey: 'to',
    header: 'To',
    enableColumnFilter: false,
    enableSorting: false,
    cell: (info: any) => {
      return (
        <TextInput defaultValue={info.getValue()} />
      );
    }
  }
];

export const data = [
  {
    attribute: 'Rate Code (id: 2)',
    from: 'T-2',
    to: 'T-1'
  }
];
