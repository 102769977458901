import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import { Drawer } from 'components';
import ProjectDetails from '../ProjectDetails/ProjectDetails';

// SELECTORS
import { getIsDataUpdateOpen } from 'store/modules/project/project.selector';

// ACTIONS
import { closeDataUpdate } from 'store/modules/project/project.slice';

// ASSETS
import styles from './DataUpdateDrawer.module.scss';

// TYPINGS
import { Project } from 'api/systems/systems.typings';
import AllocateesTable from '../AllocateesTable/AllocateesTable';
import AlertInline from 'components/AlertInline/AlertInline';
import { useTemporaryInvalidSystem } from 'api/systems/systems.api';

interface DataUpdateDrawerProps {
  project: Project;
}

const DataUpdateDrawer:React.FC<DataUpdateDrawerProps> = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsDataUpdateOpen);
  const { data: projectData, isLoading } = useTemporaryInvalidSystem();

  // EVENTS
  const handleClose = () => {
    dispatch(closeDataUpdate());
  };

  const fileName = localStorage.getItem('uploadedFile');

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      size="lg"
      onClose={handleClose}
      title="Update system"
    >
      <AlertInline
        alertMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        icon
        type="error"
      />
      <p className={styles['file-name']}>{fileName || '123456789-2032-05-15.xlsx'}</p>
      {
        (!isLoading && projectData) && (
          <>
            <ProjectDetails project={projectData} />
            <AllocateesTable allocatees={projectData.allocatees} isDataUpload hasWarning />
          </>
        )
      }
    </Drawer>
  );
};

export default DataUpdateDrawer;
