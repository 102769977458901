export const series = [{
  name: '',
  borderColor: 'rgba(251, 226, 136, 0)',
  pointWidth: 72,
  dataLabels: {
    enabled: true
  },
  data: [
    {
      x: Date.UTC(2023, 0, 1, 0, 0, 0),
      x2: Date.UTC(2023, 11, 31, 0, 0, 0),
      y: 0,
      color: 'rgba(173, 205, 255, 0.8)'
    },
    {
      x: Date.UTC(2023, 2, 1, 0, 0, 0),
      x2: Date.UTC(2023, 9, 31, 0, 0, 0),
      y: 0,
      color: 'rgba(82, 147, 255, 0.8)'
    },
    {
      x: Date.UTC(2023, 3, 20, 0, 0, 0),
      x2: Date.UTC(2023, 9, 7, 0, 0, 0),
      y: 0,
      color: 'rgba(237, 110, 120, 0.8)'
    },
    {
      x: Date.UTC(2023, 8, 10, 0, 0, 0),
      x2: Date.UTC(2023, 10, 30, 0, 0, 0),
      y: 0,
      color: 'rgba(242, 146, 154, 0.8)'
    }
  ]
}];
