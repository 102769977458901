import * as React from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import Popper, { Placement, PopperOptions } from 'popper.js';
import { BoxProps } from '@mui/system';

// COMPONENTS
import { Box } from '../Box/';
import OutsideClickDetector from '../OutsideClickDetector/OutsideClickDetector';

// ASSETS
import styles from './Popover.module.scss';

export interface IPopoverProps extends BoxProps {
  isPortal?: boolean;
  /** The content to display inside the popover */
  children?: React.ReactNode;
  /** The preferred direction to open the popover */
  /** Show or hide the Popover */
  isOpen: boolean;
  /** Making the Activator going width 100% */
  fullWidth?: boolean;
  /** Display a popup without the default card appearence */
  noCard?: boolean;
  /** The element to activate the Popover */
  renderReference: React.ReactElement<any>;
  /**
   * The element type to wrap the activator with
   * @default 'div'
   */
  offset?: number | string;
  /** position of the popover */
  placement?: Placement;
  /** layered position in the layout */
  zIndex?: number;
  /** Callback when popover is closed */
  onOutsideClickClose?: () => void;
  shadow?: string;
  showArrows?: boolean;
  hasFlip?: boolean;
}

export interface IPopoverState {
  activatorNode: HTMLElement | null;
}

const Popover: React.FC<IPopoverProps> = ({
  onOutsideClickClose,
  renderReference,
  children,
  offset,
  placement,
  zIndex,
  noCard,
  isOpen = false,
  isPortal = false,
  hasFlip = true,
  showArrows = false,
  fullWidth = false,
  pr = 3,
  pl = 3,
  pb = 3,
  pt = 3,
  shadow = 'sm',
  ...props
}) => {
  const popoverRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const activatorRef: React.RefObject<HTMLDivElement> = React.useRef(null);

  const popperOptions: PopperOptions = {
    modifiers: {
      offset: {
        offset: offset || '0,5'
      },
      flip: {
        enabled: hasFlip
      },
      arrow: {
        element: '#arrow'
      }
    },
    placement: placement || 'bottom-start'
  };

  React.useEffect(() => {
    let popperInstance:Popper | null = null;

    if (activatorRef.current && popoverRef.current) {
      // this returns a popper object
      popperInstance = new Popper(activatorRef.current, popoverRef.current, popperOptions);
    }

    return () => {
      if (popperInstance) {
        popperInstance.destroy();
        popperInstance = null;
      }
    };
  }, [popperOptions]);

  // STYLES
  const classPopover = classnames(
    styles.Popover,
    {
      [styles.NotVisible]: !isOpen,
      [styles['no-card']]: noCard,
      [styles[`p-shadow-${shadow}`]]: shadow
    }
  );

  const cssArrow = classnames(styles.arrow, {
    [styles['arrow-down']]: placement?.includes('top')
  });

  const stylePopover = {
    zIndex: zIndex || undefined
  };
  const classActivator = classnames(styles.Activator);

  // RENDERS
  const popoverContent = (
    <OutsideClickDetector
      onOutsideClick={(event) => {
        if (activatorRef.current) {
          if (!activatorRef.current.contains(event.target as Node)) {
            if (onOutsideClickClose) {
              onOutsideClickClose();
            }
          }
        }
      }}
    >
      <div className={classPopover} ref={popoverRef} style={stylePopover}>
        <Box pr={pr} pl={pl} pb={pb} pt={pt} {...props}>
          {children}
        </Box>
        {showArrows && <div id="arrow" className={cssArrow} data-popper-arrow></div>}
      </div>
    </OutsideClickDetector>
  );

  let portalNode;
  if (document.querySelector('.popover-portal')) {
    portalNode = document.querySelector('.popover-portal');
  } else {
    portalNode = document.createElement('div');
    portalNode.classList.add('popover-portal');
    document.body.appendChild(portalNode);
  }

  return (
    <>
      <div
        className={classActivator}
        ref={activatorRef}
        style={{
          width: fullWidth ? '100%' : 'auto'
        }}
      >
        {renderReference}
      </div>
      {isPortal
        ? portalNode
          ? createPortal(popoverContent, portalNode)
          : null
        : popoverContent}
    </>
  );
};

export default Popover;
