import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Card from '../components/Card/Card';
import Link from '../components/Link/Link';
import Logo from '../components/Logo/Logo';
import Icon from '../components/Icon/Icon';
import Text from '../components/Text/Text';

import './metronome-home.scss';
import metronomeList from '../json/metronomeLists.json';

const MetronomeHome: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [homeState, setHomeState] = useState('one');
  const checkForLocalHost = location.href.includes('localhost');
  const email = searchParams.get('email');
  const analyzeUrl = checkForLocalHost ? 'http://localhost:3002' : '/analyze';
  const calculateUrl = checkForLocalHost ? 'http://localhost:3001' : '/calculate';

  const prototypesList = [
    {
      title: 'Analyze Prod',
      description: 'Run billing quality analytics for every customer',
      logo: 'analyze',
      target: '_blank',
      url: `${analyzeUrl}/#/analyze/projects?email=${email}`
    },
    {
      title: 'Calculate Prod',
      description: 'Enhance your existing CIS to bill complex rates and programs',
      logo: 'calculate',
      target: '_blank',
      url: `${calculateUrl}/#/calculate/dashboard?email=${email}`
    },
    {
      title: 'Empower Prod',
      description: 'Communicate highly accurate cost data through APIs',
      logo: 'empower',
      target: '_blank',
      url: 'https://empower.gridx.com/'
    }
  ];

  const handleRenderList = (listArray: any[]) => {
    const list = listArray.map((item) => {
      const {
        description,
        logo,
        target,
        title,
        url
      } = item;
      return (
        <Card
          className='column--3 mar--b-8 mar--r-8'
          key={title}
          size='sm'
        >
          <Logo
            className='dis--inline-block'
            name={logo}
            size='sm'
          />
          <Link
            className='dis--block body--xl bor--b-1-wave pad--b-24 pad--t-8'
            color='stone'
            copy={title}
            key={title}
            href={url}
            icon={<Icon.ChevronRight />}
            size='lg'
            target={target}
          />
          <Text
            className='body--md pad--t-24'
            variant='body1'
          >
            {description}
          </Text>
        </Card>
      );
    });
    return list;
  };

  return (
    <div className='metronome--home'>
      <div
        className='bor--b-1 flex flex--align-center'
      >
        <div
          className='nav--header flex flex--align-center jus--space-between'
        >
          <div className='dis--inline-block flex flex--align-center mar--l-80'>
            <Logo
              className='dis--inline-block mar--r-12'
              name='metronome'
              size='sm'
            />
            <Text
              className='capitalize headline--sm'
              variant='body1'
            >
              Metronome
            </Text>
          </div>
          <div className='flex flex--align-center'>
            <Logo
              className='dis--inline-block'
              name='gridx-wordmark'
              size='md'
            />
          </div>
        </div>
      </div>
      <div className='metronome--container pad--t-80 pad--l-80'>
        <div
          className='metronome--section'
        >
          <Text
            className='headline--md pad--b-20'
            variant='h1'
          >
            {homeState === 'one'
              ? 'Welcome to GridX\'s Design Platform'
              : 'Product Demonstrations'
            }
          </Text>
          <Text
            className='body--xl'
            variant='body1'
          >
            Please choose an option below.
          </Text>
          {homeState === 'one'
            ? (
              <div
                className='columns mar--t-36'
              >
                {handleRenderList(metronomeList[0].home_list!)}
                <Card
                  className='column--3 mar--b-8 mar--r-8'
                  key='GridX Demos'
                  size='sm'
                >
                  <Logo
                    className='dis--inline-block'
                    name='gridx'
                    size='sm'
                  />
                  <div
                    onClick={() => setHomeState('two')}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter' || event.key === ' ') setHomeState('two');
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <Text
                      className='link link--lg link--stone body--xl pad--b-24 bor--b-1-wave underline'
                      variant='body1'
                    >
                      Demos & Prototypes <Icon.ChevronRight />
                    </Text>
                  </div>
                  <Text
                    className='body--md pad--t-24'
                    variant='body1'
                  >
                    View GridX demos and explore the features of our products
                  </Text>
                </Card>
              </div>
            )
            : ''
          }
          {homeState === 'two'
            ? (
              <div className='mar--t-36'>
                <div
                  onClick={() => setHomeState('one')}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' || event.key === ' ') setHomeState('one');
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <div
                    className='flex mar--b-16'
                  >
                    <Icon.ChevronLeft />
                    <Text>
                    Back
                    </Text>
                  </div>
                </div>
                <div
                  className='columns'
                >
                  {handleRenderList(metronomeList[1].demo_list!)}
                </div>
                <div
                  className='columns mar--t-24'
                >
                  {handleRenderList(prototypesList)}
                </div>
              </div>
            )
            : ''}
        </div>
      </div>
    </div>
  );
};

export default MetronomeHome;
