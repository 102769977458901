import React, { Fragment } from 'react';

import Header from '../../components/Header/Header';
import Accordion from '../../components/Accordion/Accordion';
import Link from '../../components/Link/Link';
import Text from '../../components/Text/Text';
import * as Icon from 'react-feather';

interface Props {
  shadowColor?: 'mangrove' | 'lumen' | 'wind' | 'temper' | null;
  expanded?: boolean;
  color?: 'sheen' | 'white';
  icon?: React.ReactNode | null;
  children?: React.ReactNode;
}

const AccordionPage: React.FC = () => {
  const renderAccordion: React.FC<Props> = ({
    shadowColor = null,
    expanded = false,
    color = 'sheen',
    icon = null,
    children = null
  }) => {
    return (
      <Accordion
        icon={icon}
        color={color}
        titleLeft="Lorem ipsum"
        titleRight={<Icon.Plus />}
        shadowColor={shadowColor}
        expanded={expanded}
        onChange={() => { }}
      >
        {children || 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum debitis voluptas aliquid tenetur quas suscipit assumenda a, nesciunt eum nobis eaque, exercitationem distinctio alias ullam quia. Corrupti beatae necessitatibus nihil.'}
      </Accordion>
    );
  };

  return (
    <Fragment>
      <Header
        className='design-studio pad--b-100 pad--l-128 pad--t-100'
        color='stone'
        text='Accordion'
      >
        <Text
          className='max-width--900'
          color='white'
        >
        </Text>
        <Link
          className='underline dis--block mar--t-60 body--xl'
          color='white'
          copy='See Our Guidelines'
          href='/'
        />

      </Header>
      <div
        className='mar--t-80 pad--l-128 max-width--lg  temp--min-width--sm'
      >
        <section
          className='pad--r-20 pad--l-20 max-width--lg'
        >
          {renderAccordion({
            shadowColor: 'mangrove',
            expanded: true,
            children: renderAccordion({
              icon: <Icon.Aperture />,
              color: 'white',
              expanded: true
            })
          })}
          {renderAccordion({
            shadowColor: 'lumen',
            children: renderAccordion({
              icon: <Icon.Aperture />,
              color: 'white'
            })
          })}
          {renderAccordion({
            shadowColor: 'temper',
            children: renderAccordion({
              icon: <Icon.Aperture />,
              color: 'white'
            })
          })}
          {renderAccordion({
            shadowColor: 'wind',
            children: renderAccordion({
              icon: <Icon.Aperture />,
              color: 'white'
            })
          })}
          {renderAccordion({
            children: renderAccordion({
              icon: <Icon.Aperture />,
              color: 'white'
            })
          })}
        </section>
      </div>
    </Fragment>
  );
};

export default AccordionPage;
