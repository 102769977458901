
import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

interface Props {
    allowedRoles?: string,
};

/**
* Represents a Button.
* @constructor
* @param {string} allowedRoles
* Usage :
* ````js
* <RequireAuth  allowedRoles='my-role' />
* ````
* @augments {Component<Props, State>}
*/
/** */

const RequireAuth: React.FC<Props> = ({ allowedRoles }) => {
  // @ts-ignore - auth does not exist on useAuth
  const { auth } = useAuth();
  const location = useLocation();
  // const allowed = auth?.roles?.find(role => allowedRoles?.includes(role));

  return (
    // @ts-ignore - role implicitely has any type
    auth?.roles?.find(role => allowedRoles?.includes(role))
      ? <Outlet />
      : auth?.user
        ? <Navigate to="/login" state={{ from: location }} replace />
        : <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
