import React from 'react';

// ASSETS
import styles from './CustomerDataItem.module.scss';

interface CustomerDataItemProps {
  title: string;
  value: string | number;
}

const CustomerDataItem: React.FC<CustomerDataItemProps> = ({ title, value }) => {
  return (
    <div className={styles.container}>
      <span className={styles.title}>{title}</span>
      <span className={styles.value}>{value}</span>
    </div>
  );
};

export default CustomerDataItem;
