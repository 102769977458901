import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// COMPONENTS
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

// ASSETS
import styles from './FooterNavigation.module.scss';

// HELPERS
import { ActionTypes, ExploreContext } from 'widget/ExploreWidget/helpers/ExploreContext';

interface FooterNavigationProps {
  description: string;
}

const FooterNavigation:React.FC<FooterNavigationProps> = ({ description }) => {
  const { state, dispatch } = useContext(ExploreContext);
  const navigate = useNavigate();

  // EVENTS
  const handleQuit = () => {
    navigate('/explore');
    dispatch({ type: ActionTypes.SET_CALCULATION_STEP, payload: 0 });
  };

  const handleSaveAndQuit = () => {
    if (state.activeCalculationStep === 0) {
      dispatch({
        type: ActionTypes.SET_SAVE_CALCULATION,
        payload: {
          isOpen: true,
          afterSaveAction: 'quit'
        }
      });
    }
  };

  const handleSaveAndContinue = () => {
    if (state.activeCalculationStep === 0) {
      dispatch({
        type: ActionTypes.SET_SAVE_CALCULATION,
        payload: {
          isOpen: state.activeCalculationStep === 0,
          afterSaveAction: 'continue'
        }
      });
    } else if (state.activeCalculationStep === 1) {
      dispatch({ type: ActionTypes.SET_CALCULATION_STEP, payload: state.activeCalculationStep + 1 });
    } else if (state.activeCalculationStep === 2) {
      navigate('/explore');
      dispatch({ type: ActionTypes.SET_CALCULATION_STEP, payload: 0 });
    }
  };

  return (
    <div>
      <div className={styles.footerButtons}>
        <Button
          copy='Quit'
          iconPosition="left"
          size="md"
          color="secondary"
          variant="outlined"
          onClick={handleQuit}
        />
        <Button
          copy='Save & quit'
          iconPosition="left"
          size="md"
          color="secondary"
          variant="outlined"
          onClick={handleSaveAndQuit}
        />
        <Button
          copy='Save & continue'
          icon={<Icon.ArrowRight />}
          iconPosition="right"
          size="md"
          onClick={handleSaveAndContinue}
        />
      </div>
      <p className={styles.footerText}>
        {description}
      </p>
    </div>
  );
};

export default FooterNavigation;
