import React from 'react';

// COMPONENTS
import ImplementationItem from 'widget/ExploreWidget/components/ImplementationItem/ImplementationItem';
import Button from 'components/Button/Button';

// ASSETS
import styles from '../../ImplementationPage.module.scss';
import { Resource } from 'widget/ExploreWidget/api/projects.typings';

// HELPERS
import { getModifierTags } from 'widget/ExploreWidget/helpers/utils/getModifierIcon';

interface HeatPumpImplementationProps {
  modifier: Resource;
}

const HeatPumpImplementation:React.FC<HeatPumpImplementationProps> = ({ modifier }) => {
  return (
    <ImplementationItem
      title="HVAC heat pump"
      type='modifier'
      modifier="heatPump"
      tags={getModifierTags(modifier)}
      footer={
        <div className={styles['content-footer']}>
          <Button
            copy="Get started"
            variant="outlined"
            color="secondary"
          />
        </div>
      }
    >
      <div className={styles['content-quote']}>
        <div className={styles['content-text']}>
          <p className={styles['content-title']}>Weatherize your home before installing your heat pump</p>
          <p className={styles['content-description']}>
            Old or improperly installed insulation can lead to a loss of up to 30% of your home’s heating or cooling
          </p>
          <br/>
          <p className={styles['content-description']}>
            Professionally install proper insulation and air and duct sealing to maximize your home’s efficiency and comfort, saving energy and money year-round. No more drafty rooms in winter or sweating in your home in summer! <a href="https://gridx.com/explore/" target='_blank' rel="noreferrer">Choose a contractor from our list</a>.
          </p>
        </div>
        <div className={styles['content-text']}>
          <p className={styles['content-title']}>Get Thousands Off an Air-Source Heat Pump</p>
          <p className={styles['content-description']}>
            Save energy while you heat and cool your home with an air-source heat pump. Compared to legacy heating and cooling systems, air-source heat pumps are quieter, more comfortable, and lower maintenance. Plus, they won’t take up space in your basement or block your windows. <a href="https://gridx.com/explore/" target='_blank' rel="noreferrer">Find a clean heat contractor</a>.
          </p>
        </div>
        <div className={styles['content-text']}>
          <p className={styles['content-title']}>Learn more about how heat pumps work</p>
          <p className={styles['content-description']}>
            Enjoy the year-round comfort and energy savings of the most efficient heating and cooling technology available. Not sure where to start? See how heat pumps work and find out which type is right for you. <a href="https://gridx.com/explore/" target='_blank' rel="noreferrer">Heat pump guide</a>.
          </p>
        </div>
      </div>
    </ImplementationItem>
  );
};

export default HeatPumpImplementation;
