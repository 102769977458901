import React, { ReactNode } from 'react';

import './side-sheet.scss';

interface Props {
    children?: ReactNode,
    className?: string,
    size?: string,
};

/**
* Represents a Button.
* @constructor
* @param {string} children
* @param {string} className
* @param {string} size
* Usage :
* ````js
* <SideSheet className='my-sideSheet' />
* ````
* @augments {Component<Props, State>}
*/
/** */
const SideSheet: React.FC<Props> = ({
  className,
  children,
  size
}: Props) => {
  return (
    <div
      data-testid="sideSheet"
      className={
        `side-sheet
        ${`${className} ` || ''}
        ${size ? `side-sheet--${size} ` : ''}`
      }
    >
        One sheet
      {children}
    </div>
  );
};

export default SideSheet;
