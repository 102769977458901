import React, { Fragment, ReactNode } from 'react';

import Icon from '../../components/Icon/Icon';
import Text from '../../components/Text/Text';

import './filter.scss';

interface Props {
  active?: boolean,
  className?: string,
  children?: ReactNode,
  id: string,
  label?: string,
  numberOfFilters?: number,
  size?: 'sm' | 'md' | 'lg',
  sort?: boolean
};

/**
* Represents a filter.
* @constructor
* @param {boolean} active
* @param {string} className
* @param {node} children
* @param {id} string
* @param {string} label
* @param {number} numberOfFilters
* @param {string} size
* @param {boolean} sort
* Usage :
* ````js
* <Filter className="my-filter" />
* ````
* @augments {Component<Props, State>}
*/
/** */
const Filter: React.FC<Props> = ({ active, className, children, id, label, numberOfFilters, size, sort }: Props) => {
  // Feather Icons do not have these
  // TODOs move into Icon component
  const renderSortIcon = () => {
    if (active) {
      return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height='16' width='16' viewBox="0 0 20 20">
          <path className="icon--style" stroke='currentColor' d="M8.8,4.7h7.5"/>
          <path className="icon--style" stroke='currentColor' d="M8.8,7.7H14"/>
          <path className="icon--style" stroke='currentColor' d="M8.8,10.7h3"/>
          <path className="icon--style" stroke='currentColor' d="M2.8,13.7L5,15.9l2.2-2.2"/>
          <path className="icon--style" stroke='currentColor' d="M5,14.4V3.9"/>
        </svg>
      );
    } else {
      return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height='16' width='16' viewBox="0 0 20 20">
          <path className="icon--style" stroke='currentColor' d="M5.8,15.2V3.9"/>
          <path className="icon--style" stroke='currentColor' d="M2,7.7l3.8-3.8l3.8,3.8"/>
          <path className="icon--style" stroke='currentColor' d="M13.3,4.7v11.2"/>
          <path className="icon--style" stroke='currentColor' d="M17,12.2l-3.8,3.8l-3.8-3.8"/>
        </svg>
      );
    }
  };

  return (
    <div
      className={
        `${className} 
        ${size ? `filter--${size} ` : 'filter--sm'}
        ${active ? 'filter--active' : 'bor--2-energy'}
        bor--r-4
        dis--inline-block
        pad--l-16
        pad--r-16
        pad--t-8
        filter--button
        filter--wrapper`
      }
    >
      <div
        className='flex filter--button'
      >
        {sort
          ? (<div data-testid="filter-sort-icon">
            {renderSortIcon()}
          </div>)
          : <span data-testid="filter-default-icon" className='filter--icon mar--l-4 mar--r-4'>
            <Icon.Filter />
          </span>}
        <Text className='body--lg pad--r-8'>
          {label || 'Filters'}
        </Text>
        { numberOfFilters
          ? (
            <Text
              className='body--sm dis--inline-block filter--number'
            >
              {numberOfFilters}
            </Text>
          )
          : null}
      </div>
      {children
        ? (
          <Fragment>
            <label
              className='filter--label'
              htmlFor={`filter--toggle-${id}`}
            >
              <span className="visually-hidden">
                [Descriptive Text for Checkbox]
              </span>
            </label>
            <input className='filter--checkbox' type='checkbox' id={`filter--toggle-${id}`} />
            {children}
          </Fragment>
        )
        : null}
    </div>
  );
};

export default Filter;
