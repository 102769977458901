import React from 'react';
// React 18.0:
// import ReactDOM from 'react-dom/client';
// React 17.0:
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import store from './store/configureStore';
import { ThemeProvider } from './hooks/Theme/Theme.context';

// THEMES
// import { southernTheme } from './hooks/Theme/Theme.config';
// import { semiloneTheme } from './hooks/Theme/Theme.config';
import { energyxTheme } from './hooks/Theme/Theme.config';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={true} />}
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={energyxTheme}>
            <AuthProvider>
              <Routes>
                <Route path='/*' element={<App />} />
              </Routes>
            </AuthProvider>
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
