import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';

// TYPINGS
import { ICustomerData } from '../../../../api/analyze/analyze.typings';

// COMPONENTS
import { ListItem } from '../../../../blocks/components';
import CustomerDataItem from '../CustomerDataItem/CustomerDataItem';
import Tag from '../../../../components/Tag/Tag';
import ListShowMore from 'components/ListShowMore/ListShowMore';

// ASSETS
import styles from './ListCustomers.module.scss';

// HELPERS
import { getCustomerTypeColor, getCustomerTypeCustomColor, getCustomerTypeTextColor } from './ListCustomers.helpers';
import { useTheme } from '../../../../hooks/Theme/Theme.context';
import { TagColor } from 'components/Tag/Tag.helpers';

interface ListCustomersProps {
  customers: Array<ICustomerData>
}

const ListCustomers:React.FC<ListCustomersProps> = ({ customers }) => {
  const { getThemeColor, hasThemePalette } = useTheme();
  const [limitShow, setLimitShow] = useState(10);

  // EVENTS
  const handleShowMore = () => {
    setLimitShow(limitShow + 10);
  };

  // RENDERS
  const filteredCustomers = customers.slice(0, limitShow);

  return (
    <>
      <AnimatePresence>
        <motion.ul
          className={styles['list-container']}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {
            filteredCustomers.map((customer) => {
              return (
                <motion.li
                  key={customer.id}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.5 }}
                >
                  <ListItem
                    selectable
                    columns={[
                      { body: <CustomerDataItem title="Account ID" value={customer.accountId} />, col: 2 },
                      { body: <CustomerDataItem title="Current rate" value={customer.currentRate} />, col: 2 },
                      { body: <CustomerDataItem title="Best rate" value={customer.bestRate} />, col: 2 },
                      { body: <CustomerDataItem title="Annual cost" value={customer.annualCost} />, col: 2 },
                      { body: <CustomerDataItem title="Best annual cost" value={customer.bestAnnualCost} />, col: 2 },
                      {
                        body: (
                          <div className={styles['customer-type']}>
                            <Tag
                              copy={customer.customerType}
                              color={getCustomerTypeColor(customer.customerType) as any}
                              backgroundColor={getCustomerTypeCustomColor(customer.customerType, getThemeColor)}
                              textColor={getCustomerTypeTextColor(customer.customerType, hasThemePalette)}
                            />
                          </div>
                        ),
                        col: 2
                      }
                    ]}
                  />
                </motion.li>
              );
            })
          }
        </motion.ul>
      </AnimatePresence>
      <div className={styles['list-footer']}>
        <ListShowMore total={customers.length} current={limitShow} onShowMore={handleShowMore} />
      </div>
    </>
  );
};

export default ListCustomers;
