import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// COMPONENTS
import { ShareButton } from 'blocks/components';
import { Breadcrumb, Dropdown } from 'components';
import { VirtualMeterInfo, VirtualMeterInsights, MeterAccountTabs } from './components';

// ASSETS
import styles from './MeterAccountDetails.module.scss';

// HELPERS
import { simpleListWithIcon } from 'components/ActionList/stories/ActionList.stories.helpers';
import { useSingleAccount } from 'api/accounts/accounts.api';
import { useTheme } from 'hooks/Theme/Theme.context';
import { IMeterAccount } from 'api/accounts/accounts.typings';

const MeterAccountDetails = () => {
  const { meterAccountId, accountId } = useParams();
  const { slug } = useTheme();
  const { data: accountData, isLoading } = useSingleAccount(accountId || '');
  const [meterAccountData, setMeterAccountData] = useState<IMeterAccount | null>(null);

  useEffect(() => {
    if (accountData) {
      const meterAccount = accountData.meterAccounts.find((meterAccount) => meterAccount.id === Number(meterAccountId));
      if (meterAccount) {
        setMeterAccountData(meterAccount);
      }
    }
  }, [accountData]);

  return (
    <div className={`${styles['account-page']} admin--page pad--t-128 pad--l-128 pad--r-36`} role="main">
      {(!isLoading && meterAccountData) && (
        <>
          <div className="pad--b-24">
            <Breadcrumb
              withRouter={true}
              items={[
                {
                  title: 'Accounts',
                  bold: true,
                  href: `/${slug}/accounts`
                },
                {
                  title: accountData?.serviceAgreement,
                  href: `/${slug}/accounts/${accountId}`
                },
                {
                  title: meterAccountData.meterAccount
                }
              ]}
            />
          </div>
          <div className={styles.header}>
            <h2>{meterAccountData.meterAccount}</h2>
            <div className={styles.actions}>
              <Dropdown label="Actions" options={simpleListWithIcon} color='depth' />
              <ShareButton/>
            </div>
          </div>
          <VirtualMeterInfo meterAccount={meterAccountData} />
          {/* <DeviceDetails/> */}
          <VirtualMeterInsights/>
          <MeterAccountTabs/>
        </>
      )}
    </div>
  );
};

export default MeterAccountDetails;
