export const columns = [
  {
    accessorKey: 'customer',
    header: 'Customer',
    enableSorting: false
  },
  {
    accessorKey: 'allocated',
    id: 'allocated',
    header: 'Allocated',
    enableColumnFilter: false
  },
  {
    accessorKey: 'rateCode',
    header: 'Rate Code',
    enableColumnFilter: false
  },
  {
    accessorKey: 'contractId',
    header: 'Contract ID',
    enableSorting: false
  },
  {
    accessorKey: 'servicePointId',
    header: 'Servicepoint ID',
    enableSorting: false
  },
  {
    accessorKey: 'meterId',
    header: 'Meter ID',
    enableSorting: false
  }
];

export const data = [
  {
    customer: 'Customer 1',
    allocated: '10%',
    rateCode: 'A-RATE-1',
    contractId: 111222333444,
    servicePointId: 111222333444,
    meterId: 111222333444
  },
  {
    customer: 'Customer 1',
    allocated: '10%',
    rateCode: 'A-RATE-1',
    contractId: 111222333444,
    servicePointId: 111222333444,
    meterId: 111222333444
  },
  {
    customer: 'Customer 1',
    allocated: '10%',
    rateCode: 'A-RATE-1',
    contractId: 111222333444,
    servicePointId: 111222333444,
    meterId: 111222333444
  },
  {
    customer: 'Customer 1',
    allocated: '10%',
    rateCode: 'A-RATE-1',
    contractId: 111222333444,
    servicePointId: 111222333444,
    meterId: 111222333444
  },
  {
    customer: 'Customer 1',
    allocated: '10%',
    rateCode: 'A-RATE-1',
    contractId: 111222333444,
    servicePointId: 111222333444,
    meterId: 111222333444
  },
  {
    customer: 'Customer 1',
    allocated: '10%',
    rateCode: 'A-RATE-1',
    contractId: 111222333444,
    servicePointId: 111222333444,
    meterId: 111222333444
  },
  {
    customer: 'Customer 1',
    allocated: '10%',
    rateCode: 'A-RATE-1',
    contractId: 111222333444,
    servicePointId: 111222333444,
    meterId: 111222333444
  },
  {
    customer: 'Customer 1',
    allocated: '10%',
    rateCode: 'A-RATE-1',
    contractId: 111222333444,
    servicePointId: 111222333444,
    meterId: 111222333444
  },
  {
    customer: 'Customer 1',
    allocated: '10%',
    rateCode: 'A-RATE-1',
    contractId: 111222333444,
    servicePointId: 111222333444,
    meterId: 111222333444
  },
  {
    customer: 'Customer 1',
    allocated: '10%',
    rateCode: 'A-RATE-1',
    contractId: 111222333444,
    servicePointId: 111222333444,
    meterId: 111222333444
  }
];
