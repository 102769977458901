import * as React from 'react';

import colors from '../../../style/colors';

const Design = (props:any) => {
  const { color, showBrandName, showGridX } = props;

  let renderColor;
  switch (color) {
  case 'deep':
    renderColor = colors.deep;
    break;
  case 'energy':
    renderColor = colors.energy;
    break;
  case 'white':
    renderColor = colors.white;
    break;
  }

  if (color === 'deep' || color === 'energy' || color === 'white') {
    if (showGridX) {
      return (
        <svg
          width={411}
          height={134}
          viewBox="0 0 411 134"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M60.6632 109.025L41.576 90.2402L23.029 71.9805C21.7019 70.6745 20.9564 68.9034 20.9564 67.0567C20.9564 65.21 21.7019 63.4389 23.029 62.1329L41.9113 43.5555L62.6377 23.1577H33.0135L27.0837 28.9918L8.20138 47.5447C2.94951 52.7225 0 59.7387 0 67.0536C0 74.3686 2.94951 81.3848 8.20138 86.5626L26.7483 104.81L45.8356 123.589C48.4375 126.154 51.5292 128.187 54.9328 129.572C58.3364 130.957 61.9846 131.666 65.6678 131.659C70.7126 131.663 75.6652 130.329 80.0049 127.798L61.4952 109.697C61.2015 109.494 60.9234 109.269 60.6632 109.025Z"
            fill={renderColor}
          />
          <path
            d="M70.6726 25.0271L79.4214 33.5797L94.2738 18.9732L85.494 10.4206C81.0844 6.08927 75.3361 3.32331 69.1538 2.55802C62.9714 1.79273 56.706 3.07156 51.3433 6.19323L70.4366 24.8256C70.5608 24.905 70.6043 24.9599 70.6726 25.0271Z"
            fill={renderColor}
          />
          <path
            d="M123.416 47.4532L121.423 45.4922L106.576 60.1048L108.576 62.0536C109.903 63.3596 110.648 65.1307 110.648 66.9774C110.648 68.8242 109.903 70.5953 108.576 71.9013L86.5453 93.2826L68.6006 110.944H98.3366L123.403 86.465C128.655 81.289 131.605 74.2751 131.608 66.9616C131.61 59.6482 128.664 52.6324 123.416 47.4532Z"
            fill={renderColor}
          />
          <path
            d="M35.9546 58.6809L52.8858 75.2988L70.3648 58.102L53.4646 41.4536L35.9546 58.6809Z"
            fill={renderColor}
          />
          <path
            d="M99.7811 23.1699L66.9467 55.4741L49.8589 72.286L64.9721 87.1613C65.2328 87.4184 65.5425 87.6224 65.8835 87.7616C66.2245 87.9008 66.5899 87.9724 66.959 87.9724C67.3282 87.9724 67.6937 87.9008 68.0347 87.7616C68.3756 87.6224 68.6853 87.4184 68.946 87.1613L84.0406 72.3043L134 23.1577L99.7811 23.1699Z"
            fill={renderColor}
          />
          <path
            d="M167.688 114.016V61.8165H187.632C205.056 61.8165 214.344 72.2565 214.344 87.8805C214.344 104.656 204.048 114.016 187.632 114.016H167.688ZM177.264 105.592H187.56C198.576 105.592 204.264 98.5365 204.264 87.9525C204.264 76.2885 197.568 70.2405 187.56 70.2405H177.264V105.592Z"
            fill={renderColor}
          />
          <path
            d="M249.937 102.496H258.937C256.993 110.2 249.793 114.736 240.577 114.736C228.409 114.736 220.489 106.888 220.489 95.1525C220.489 83.4885 228.409 75.6405 240.073 75.6405C251.017 75.6405 258.505 82.6245 258.505 92.9205C258.505 94.2165 258.361 95.0805 258.145 96.3045H229.633V97.0245C229.633 103.72 234.025 107.464 240.505 107.464C245.113 107.464 248.569 105.52 249.937 102.496ZM239.857 82.8405C234.673 82.8405 230.713 85.8645 229.849 90.5445H249.217C249.145 86.0085 245.257 82.8405 239.857 82.8405Z"
            fill={renderColor}
          />
          <path
            d="M280.91 114.736C271.19 114.736 264.926 109.84 264.854 102.496H273.278C273.422 105.952 276.446 108.04 280.91 108.04C285.302 108.04 287.606 106.168 287.606 103.36C287.606 100.912 285.878 99.6165 283.142 99.1125L276.23 97.8885C270.542 96.8085 265.502 94.0725 265.502 87.4485C265.502 80.3205 271.622 75.6405 280.694 75.6405C289.766 75.6405 295.958 80.0325 296.03 87.3765H287.894C287.75 84.0645 284.942 82.2645 280.838 82.2645C276.95 82.2645 274.502 83.9925 274.502 86.7285C274.502 89.6085 277.382 90.2565 279.326 90.6165L286.238 91.8405C293.798 93.1365 296.678 97.3125 296.678 102.712C296.678 109.84 290.774 114.736 280.91 114.736Z"
            fill={renderColor}
          />
          <path
            d="M309.3 72.0405C305.268 72.0405 303.036 69.5925 303.036 66.4965C303.036 63.5445 305.268 61.1685 309.3 61.1685C313.26 61.1685 315.492 63.5445 315.492 66.4965C315.492 69.5925 313.26 72.0405 309.3 72.0405ZM304.764 114.016V76.3605H313.836V114.016H304.764Z"
            fill={renderColor}
          />
          <path
            d="M341.805 129.856C330.357 129.856 323.589 124.384 322.581 115.96H331.437C332.229 119.992 336.333 122.512 341.949 122.512C348.645 122.512 353.109 118.768 353.109 112.72V106.96C350.805 110.56 345.693 112.864 339.933 112.864C328.845 112.864 321.573 105.088 321.573 94.2885C321.573 83.4165 328.845 75.6405 339.933 75.6405C346.053 75.6405 351.093 78.0165 353.613 81.6165L354.189 76.3605H362.181V112.36C362.181 123.232 354.189 129.856 341.805 129.856ZM341.733 105.448C348.141 105.448 353.109 101.2 353.109 94.5765V93.7125C353.109 87.0885 348.213 83.0565 341.805 83.0565C335.109 83.0565 330.789 87.4485 330.789 94.2165C330.789 100.768 334.821 105.448 341.733 105.448Z"
            fill={renderColor}
          />
          <path
            d="M372.034 114.016V76.3605H380.386L381.178 81.8325C383.41 78.0165 388.018 75.6405 393.346 75.6405C402.418 75.6405 407.098 81.2565 407.098 90.3285V114.016H398.026V91.6245C398.026 85.9365 395.002 83.2725 390.394 83.2725C384.85 83.2725 381.106 87.2325 381.106 93.7845V114.016H372.034Z"
            fill={renderColor}
          />
          <g clipPath="url(#clip0_9122_7974)">
            <path
              d="M208.414 9.94043H203.654V29.35H208.414V9.94043Z"
              fill={renderColor}
            />
            <path
              d="M208.414 3.42627H203.654V7.55243H208.414V3.42627Z"
              fill={renderColor}
            />
            <path
              d="M233.159 1.5166V29.35H228.957L228.578 26.4555C227.219 28.4614 224.424 29.7216 221.1 29.7216C215.131 29.7216 211.278 25.6396 211.278 19.7024C211.278 13.5781 215.432 9.56886 221.478 9.56886C224.347 9.56886 226.878 10.5328 228.391 12.2399V1.5166H233.159ZM228.399 19.9258V19.4789C228.399 15.8412 225.905 13.5027 222.239 13.5027C218.463 13.5027 216.123 15.9504 216.123 19.6244C216.123 23.4466 218.577 25.7851 222.205 25.7851C225.833 25.7851 228.399 23.5999 228.399 19.9258Z"
              fill={renderColor}
            />
            <path
              d="M241.637 19.1491L234.465 9.94057H240.376L244.94 15.9505C245.02 16.0544 245.124 16.1387 245.243 16.1967C245.362 16.2547 245.493 16.2849 245.625 16.2849C245.758 16.2849 245.889 16.2547 246.008 16.1967C246.126 16.1387 246.23 16.0544 246.31 15.9505L251.028 9.93018H256.772L249.486 19.1335C249.368 19.2817 249.304 19.4647 249.304 19.6532C249.304 19.8416 249.368 20.0246 249.486 20.1728L256.809 29.3372H250.991L246.26 23.4156C246.18 23.3136 246.077 23.2311 245.959 23.1743C245.841 23.1175 245.712 23.088 245.58 23.088C245.449 23.088 245.32 23.1175 245.202 23.1743C245.084 23.2311 244.981 23.3136 244.901 23.4156L240.249 29.3424H234.465L241.637 20.1702C241.751 20.0235 241.813 19.8441 241.813 19.6597C241.813 19.4752 241.751 19.2958 241.637 19.1491Z"
              fill={renderColor}
            />
            <path
              d="M198.883 9.94042L202.059 14.1545H195.696C195.212 14.1545 194.748 14.3434 194.406 14.6796C194.064 15.0158 193.872 15.6594 193.872 16.1349V29.35H189.204V15.8387C189.204 11.5384 191.661 9.93262 194.731 9.93262L198.883 9.94042Z"
              fill={renderColor}
            />
            <path
              d="M181.395 9.94026L181.093 12.6503C179.771 10.7951 177.127 9.57129 173.916 9.57129C168.098 9.57129 164.285 13.5779 164.285 19.1851C164.285 24.7508 168.101 28.76 173.916 28.76C176.939 28.76 179.62 27.5726 180.829 25.7174V28.6847C180.829 31.8027 178.488 33.7333 174.974 33.7333C173.022 33.7333 171.488 33.0915 170.441 32.1743C170.275 32.0261 169.939 31.7455 169.939 31.7455L166.895 34.7856C167.158 35.0508 167.441 35.2966 167.741 35.5209C169.449 36.7967 171.859 37.5216 174.9 37.5216C181.395 37.5216 185.589 34.1074 185.589 28.5028V9.94026H181.395ZM180.829 19.3307C180.829 22.7449 178.224 24.9353 174.86 24.9353C171.234 24.9353 169.119 22.5214 169.119 19.1462C169.119 15.6566 171.385 13.3934 174.9 13.3934C178.261 13.3934 180.829 15.4721 180.829 18.8863V19.3307Z"
              fill={renderColor}
            />
          </g>
          <defs>
            <clipPath id="clip0_9122_7974">
              <rect
                width={99}
                height={39}
                fill="white"
                transform="translate(162 0.0166016)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    } else if (showBrandName) {
      return (
        <svg
          width={402}
          height={135}
          viewBox="0 0 402 135"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M60.6632 109.525L41.576 90.74L23.029 72.4803C21.7019 71.1743 20.9564 69.4032 20.9564 67.5565C20.9564 65.7097 21.7019 63.9386 23.029 62.6326L41.9113 44.0553L62.6377 23.6575H33.0135L27.0837 29.4915L8.20138 48.0444C2.94951 53.2223 0 60.2384 0 67.5534C0 74.8683 2.94951 81.8845 8.20138 87.0624L26.7483 105.31L45.8356 124.089C48.4375 126.654 51.5292 128.687 54.9328 130.072C58.3364 131.457 61.9846 132.166 65.6678 132.159C70.7126 132.162 75.6652 130.829 80.0049 128.298L61.4952 110.197C61.2015 109.993 60.9234 109.769 60.6632 109.525Z"
            fill={renderColor}
          />
          <path
            d="M70.6726 25.5269L79.4214 34.0794L94.2738 19.4729L85.494 10.9204C81.0844 6.58902 75.3361 3.82307 69.1538 3.05778C62.9714 2.29249 56.706 3.57132 51.3433 6.69299L70.4366 25.3253C70.5608 25.4047 70.6043 25.4597 70.6726 25.5269Z"
            fill={renderColor}
          />
          <path
            d="M123.416 47.9529L121.423 45.9919L106.576 60.6046L108.576 62.5534C109.903 63.8594 110.648 65.6305 110.648 67.4772C110.648 69.3239 109.903 71.095 108.576 72.401L86.5453 93.7824L68.6006 111.443H98.3366L123.403 86.9647C128.655 81.7888 131.605 74.7748 131.608 67.4614C131.61 60.1479 128.664 53.1321 123.416 47.9529Z"
            fill={renderColor}
          />
          <path
            d="M35.9546 59.1804L52.8858 75.7983L70.3648 58.6016L53.4646 41.9531L35.9546 59.1804Z"
            fill={renderColor}
          />
          <path
            d="M99.7811 23.6697L66.9467 55.9739L49.8589 72.7858L64.9721 87.6611C65.2328 87.9182 65.5425 88.1222 65.8835 88.2614C66.2245 88.4006 66.5899 88.4722 66.959 88.4722C67.3282 88.4722 67.6937 88.4006 68.0347 88.2614C68.3756 88.1222 68.6853 87.9182 68.946 87.6611L84.0406 72.8041L134 23.6575L99.7811 23.6697Z"
            fill={renderColor}
          />
          <path
            d="M162 93.848V41.648H181.944C199.368 41.648 208.656 52.088 208.656 67.712C208.656 84.488 198.36 93.848 181.944 93.848H162ZM171.576 85.424H181.872C192.888 85.424 198.576 78.368 198.576 67.784C198.576 56.12 191.88 50.072 181.872 50.072H171.576V85.424Z"
            fill={renderColor}
          />
          <path
            d="M244.249 82.328H253.249C251.305 90.032 244.105 94.568 234.889 94.568C222.721 94.568 214.801 86.72 214.801 74.984C214.801 63.32 222.721 55.472 234.385 55.472C245.329 55.472 252.817 62.456 252.817 72.752C252.817 74.048 252.673 74.912 252.457 76.136H223.945V76.856C223.945 83.552 228.337 87.296 234.817 87.296C239.425 87.296 242.881 85.352 244.249 82.328ZM234.169 62.672C228.985 62.672 225.025 65.696 224.161 70.376H243.529C243.457 65.84 239.569 62.672 234.169 62.672Z"
            fill={renderColor}
          />
          <path
            d="M275.222 94.568C265.502 94.568 259.238 89.672 259.166 82.328H267.59C267.734 85.784 270.758 87.872 275.222 87.872C279.614 87.872 281.918 86 281.918 83.192C281.918 80.744 280.19 79.448 277.454 78.944L270.542 77.72C264.854 76.64 259.814 73.904 259.814 67.28C259.814 60.152 265.934 55.472 275.006 55.472C284.078 55.472 290.27 59.864 290.342 67.208H282.206C282.062 63.896 279.254 62.096 275.15 62.096C271.262 62.096 268.814 63.824 268.814 66.56C268.814 69.44 271.694 70.088 273.638 70.448L280.55 71.672C288.11 72.968 290.99 77.144 290.99 82.544C290.99 89.672 285.086 94.568 275.222 94.568Z"
            fill={renderColor}
          />
          <path
            d="M303.612 51.872C299.58 51.872 297.348 49.424 297.348 46.328C297.348 43.376 299.58 41 303.612 41C307.572 41 309.804 43.376 309.804 46.328C309.804 49.424 307.572 51.872 303.612 51.872ZM299.076 93.848V56.192H308.148V93.848H299.076Z"
            fill={renderColor}
          />
          <path
            d="M336.117 109.688C324.669 109.688 317.901 104.216 316.893 95.792H325.749C326.541 99.824 330.645 102.344 336.261 102.344C342.957 102.344 347.421 98.6 347.421 92.552V86.792C345.117 90.392 340.005 92.696 334.245 92.696C323.157 92.696 315.885 84.92 315.885 74.12C315.885 63.248 323.157 55.472 334.245 55.472C340.365 55.472 345.405 57.848 347.925 61.448L348.501 56.192H356.493V92.192C356.493 103.064 348.501 109.688 336.117 109.688ZM336.045 85.28C342.453 85.28 347.421 81.032 347.421 74.408V73.544C347.421 66.92 342.525 62.888 336.117 62.888C329.421 62.888 325.101 67.28 325.101 74.048C325.101 80.6 329.133 85.28 336.045 85.28Z"
            fill={renderColor}
          />
          <path
            d="M366.346 93.848V56.192H374.698L375.49 61.664C377.722 57.848 382.33 55.472 387.658 55.472C396.73 55.472 401.41 61.088 401.41 70.16V93.848H392.338V71.456C392.338 65.768 389.314 63.104 384.706 63.104C379.162 63.104 375.418 67.064 375.418 73.616V93.848H366.346Z"
            fill={renderColor}
          />
        </svg>
      );
    } else {
      return (
        <svg
          width={64}
          height={65}
          viewBox="0 0 64 65"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M28.9735 52.5717L19.8572 43.5997L10.9989 34.8787C10.3651 34.2549 10.009 33.409 10.009 32.527C10.009 31.645 10.3651 30.7991 10.9989 30.1753L20.0173 21.3025L29.9165 11.5603H15.7676L12.9355 14.3467L3.91708 23.2078C1.40872 25.6808 0 29.0318 0 32.5255C0 36.0192 1.40872 39.3702 3.91708 41.8432L12.7753 50.5584L21.8916 59.5275C23.1343 60.7524 24.611 61.7236 26.2366 62.3851C27.8621 63.0465 29.6046 63.3853 31.3637 63.3818C33.7732 63.3835 36.1386 62.7465 38.2113 61.5378L29.3709 52.8926C29.2306 52.7954 29.0978 52.6881 28.9735 52.5717Z"
            fill={renderColor}
          />
          <path
            d="M33.754 12.4532L37.9325 16.538L45.0262 9.56175L40.8329 5.47695C38.7268 3.40824 35.9814 2.08719 33.0286 1.72168C30.0759 1.35617 27.0834 1.96695 24.5221 3.4579L33.6413 12.3569C33.7006 12.3949 33.7214 12.4211 33.754 12.4532Z"
            fill={renderColor}
          />
          <path
            d="M58.9447 23.1641L57.9928 22.2275L50.902 29.2067L51.8569 30.1375C52.4908 30.7612 52.8469 31.6071 52.8469 32.4891C52.8469 33.3712 52.4908 34.2171 51.8569 34.8408L41.335 45.0528L32.7644 53.4879H46.9667L58.9388 41.7966C61.447 39.3245 62.8563 35.9746 62.8574 32.4816C62.8585 28.9886 61.4514 25.6378 58.9447 23.1641Z"
            fill={renderColor}
          />
          <path
            d="M17.1724 28.5265L25.2589 36.4635L33.6071 28.2501L25.5354 20.2986L17.1724 28.5265Z"
            fill={renderColor}
          />
          <path
            d="M47.6566 11.5661L31.9745 26.995L23.8131 35.0246L31.0314 42.1292C31.1559 42.252 31.3038 42.3494 31.4667 42.4159C31.6295 42.4824 31.804 42.5166 31.9804 42.5166C32.1567 42.5166 32.3312 42.4824 32.4941 42.4159C32.6569 42.3494 32.8049 42.252 32.9294 42.1292L40.1387 35.0333L64 11.5603L47.6566 11.5661Z"
            fill={renderColor}
          />
        </svg>
      );
    }
  }

  if (showGridX) {
    return (
      <svg
        width={411}
        height={134}
        viewBox="0 0 411 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_9122_7976)">
          <path
            d="M60.6231 109.559L41.4143 90.5146L22.7493 72.0026C21.4138 70.6786 20.6636 68.883 20.6636 67.0108C20.6636 65.1386 21.4138 63.343 22.7493 62.019L41.7518 43.1849L62.6101 22.5054H32.7974L26.8298 28.42L7.82731 47.2292C2.54201 52.4786 -0.42627 59.5917 -0.42627 67.0077C-0.42627 74.4237 2.54201 81.5368 7.82731 86.7862L26.4923 105.286L45.7011 124.324C48.3196 126.924 51.4309 128.986 54.8562 130.39C58.2814 131.794 61.9529 132.513 65.6595 132.506C70.7364 132.509 75.7205 131.157 80.0879 128.591L61.4604 110.24C61.1648 110.034 60.8849 109.806 60.6231 109.559Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M70.696 24.4934L79.5005 33.1641L94.4474 18.3558L85.6118 9.68506C81.1741 5.29387 75.3892 2.4897 69.1675 1.71384C62.9459 0.937979 56.6405 2.23447 51.2437 5.39927L70.4586 24.289C70.5835 24.3695 70.6273 24.4252 70.696 24.4934Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M123.775 47.2293L121.769 45.2412L106.828 60.0557L108.84 62.0314C110.176 63.3555 110.926 65.151 110.926 67.0233C110.926 68.8955 110.176 70.6911 108.84 72.0151L86.6697 93.6918L68.6108 111.597H98.5361L123.762 86.78C129.047 81.5326 132.017 74.4217 132.019 67.0072C132.021 59.5927 129.057 52.48 123.775 47.2293Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M31.0181 53.8373L49.7518 72.405L67.3421 54.9707L48.6395 36.3721L31.0181 53.8373Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M99.99 22.6105L66.9466 55.361L49.75 72.4051L64.9594 87.4859C65.2218 87.7466 65.5335 87.9534 65.8766 88.0945C66.2197 88.2356 66.5875 88.3082 66.959 88.3082C67.3305 88.3082 67.6984 88.2356 68.0415 88.0945C68.3846 87.9534 68.6963 87.7466 68.9587 87.4859L84.1493 72.4237L134.427 22.5981L99.99 22.6105Z"
            fill={renderColor || '#FFA44F'}
          />
        </g>
        <path
          d="M167.688 114.016V61.8165H187.632C205.056 61.8165 214.344 72.2565 214.344 87.8805C214.344 104.656 204.048 114.016 187.632 114.016H167.688ZM177.264 105.592H187.56C198.576 105.592 204.264 98.5365 204.264 87.9525C204.264 76.2885 197.568 70.2405 187.56 70.2405H177.264V105.592Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M249.937 102.496H258.937C256.993 110.2 249.793 114.736 240.577 114.736C228.409 114.736 220.489 106.888 220.489 95.1525C220.489 83.4885 228.409 75.6405 240.073 75.6405C251.017 75.6405 258.505 82.6245 258.505 92.9205C258.505 94.2165 258.361 95.0805 258.145 96.3045H229.633V97.0245C229.633 103.72 234.025 107.464 240.505 107.464C245.113 107.464 248.569 105.52 249.937 102.496ZM239.857 82.8405C234.673 82.8405 230.713 85.8645 229.849 90.5445H249.217C249.145 86.0085 245.257 82.8405 239.857 82.8405Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M280.91 114.736C271.19 114.736 264.926 109.84 264.854 102.496H273.278C273.422 105.952 276.446 108.04 280.91 108.04C285.302 108.04 287.606 106.168 287.606 103.36C287.606 100.912 285.878 99.6165 283.142 99.1125L276.23 97.8885C270.542 96.8085 265.502 94.0725 265.502 87.4485C265.502 80.3205 271.622 75.6405 280.694 75.6405C289.766 75.6405 295.958 80.0325 296.03 87.3765H287.894C287.75 84.0645 284.942 82.2645 280.838 82.2645C276.95 82.2645 274.502 83.9925 274.502 86.7285C274.502 89.6085 277.382 90.2565 279.326 90.6165L286.238 91.8405C293.798 93.1365 296.678 97.3125 296.678 102.712C296.678 109.84 290.774 114.736 280.91 114.736Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M309.3 72.0405C305.268 72.0405 303.036 69.5925 303.036 66.4965C303.036 63.5445 305.268 61.1685 309.3 61.1685C313.26 61.1685 315.492 63.5445 315.492 66.4965C315.492 69.5925 313.26 72.0405 309.3 72.0405ZM304.764 114.016V76.3605H313.836V114.016H304.764Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M341.805 129.856C330.357 129.856 323.589 124.384 322.581 115.96H331.437C332.229 119.992 336.333 122.512 341.949 122.512C348.645 122.512 353.109 118.768 353.109 112.72V106.96C350.805 110.56 345.693 112.864 339.933 112.864C328.845 112.864 321.573 105.088 321.573 94.2885C321.573 83.4165 328.845 75.6405 339.933 75.6405C346.053 75.6405 351.093 78.0165 353.613 81.6165L354.189 76.3605H362.181V112.36C362.181 123.232 354.189 129.856 341.805 129.856ZM341.733 105.448C348.141 105.448 353.109 101.2 353.109 94.5765V93.7125C353.109 87.0885 348.213 83.0565 341.805 83.0565C335.109 83.0565 330.789 87.4485 330.789 94.2165C330.789 100.768 334.821 105.448 341.733 105.448Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M372.034 114.016V76.3605H380.386L381.178 81.8325C383.41 78.0165 388.018 75.6405 393.346 75.6405C402.418 75.6405 407.098 81.2565 407.098 90.3285V114.016H398.026V91.6245C398.026 85.9365 395.002 83.2725 390.394 83.2725C384.85 83.2725 381.106 87.2325 381.106 93.7845V114.016H372.034Z"
          fill={renderColor || '#344145'}
        />
        <g clipPath="url(#clip1_9122_7976)">
          <path
            d="M208.414 9.94043H203.654V29.35H208.414V9.94043Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M208.414 3.42627H203.654V7.55243H208.414V3.42627Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M233.159 1.5166V29.35H228.957L228.578 26.4555C227.219 28.4614 224.424 29.7216 221.1 29.7216C215.131 29.7216 211.278 25.6396 211.278 19.7024C211.278 13.5781 215.432 9.56886 221.478 9.56886C224.347 9.56886 226.878 10.5328 228.391 12.2399V1.5166H233.159ZM228.399 19.9258V19.4789C228.399 15.8412 225.905 13.5027 222.239 13.5027C218.463 13.5027 216.123 15.9504 216.123 19.6244C216.123 23.4466 218.577 25.7851 222.205 25.7851C225.833 25.7851 228.399 23.5999 228.399 19.9258Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M241.637 19.1491L234.465 9.94057H240.376L244.94 15.9505C245.02 16.0544 245.124 16.1387 245.243 16.1967C245.362 16.2547 245.493 16.2849 245.625 16.2849C245.758 16.2849 245.889 16.2547 246.008 16.1967C246.126 16.1387 246.23 16.0544 246.31 15.9505L251.028 9.93018H256.772L249.486 19.1335C249.368 19.2817 249.304 19.4647 249.304 19.6532C249.304 19.8416 249.368 20.0246 249.486 20.1728L256.809 29.3372H250.991L246.26 23.4156C246.18 23.3136 246.077 23.2311 245.959 23.1743C245.841 23.1175 245.712 23.088 245.58 23.088C245.449 23.088 245.32 23.1175 245.202 23.1743C245.084 23.2311 244.981 23.3136 244.901 23.4156L240.249 29.3424H234.465L241.637 20.1702C241.751 20.0235 241.813 19.8441 241.813 19.6597C241.813 19.4752 241.751 19.2958 241.637 19.1491Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M198.883 9.94042L202.059 14.1545H195.696C195.212 14.1545 194.748 14.3434 194.406 14.6796C194.064 15.0158 193.872 15.6594 193.872 16.1349V29.35H189.204V15.8387C189.204 11.5384 191.661 9.93262 194.731 9.93262L198.883 9.94042Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M181.395 9.94026L181.093 12.6503C179.771 10.7951 177.127 9.57129 173.916 9.57129C168.098 9.57129 164.285 13.5779 164.285 19.1851C164.285 24.7508 168.101 28.76 173.916 28.76C176.939 28.76 179.62 27.5726 180.829 25.7174V28.6847C180.829 31.8027 178.488 33.7333 174.974 33.7333C173.022 33.7333 171.488 33.0915 170.441 32.1743C170.275 32.0261 169.939 31.7455 169.939 31.7455L166.895 34.7856C167.158 35.0508 167.441 35.2966 167.741 35.5209C169.449 36.7967 171.859 37.5216 174.9 37.5216C181.395 37.5216 185.589 34.1074 185.589 28.5028V9.94026H181.395ZM180.829 19.3307C180.829 22.7449 178.224 24.9353 174.86 24.9353C171.234 24.9353 169.119 22.5214 169.119 19.1462C169.119 15.6566 171.385 13.3934 174.9 13.3934C178.261 13.3934 180.829 15.4721 180.829 18.8863V19.3307Z"
            fill={renderColor || '#344145'}
          />
        </g>
        <defs>
          <clipPath id="clip0_9122_7976">
            <rect width={134} height={134} fill="white" />
          </clipPath>
          <clipPath id="clip1_9122_7976">
            <rect
              width={99}
              height={39}
              fill="white"
              transform="translate(162 0.0166016)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (showBrandName) {
    return (
      <svg
        width={402}
        height={134}
        viewBox="0 0 402 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_9085_4165)">
          <path
            d="M60.6228 109.559L41.4141 90.5148L22.7491 72.0029C21.4135 70.6789 20.6633 68.8833 20.6633 67.011C20.6633 65.1388 21.4135 63.3432 22.7491 62.0192L41.7516 43.1852L62.6099 22.5056H32.7971L26.8295 28.4203L7.82707 47.2295C2.54177 52.4788 -0.426514 59.5919 -0.426514 67.0079C-0.426514 74.4239 2.54177 81.5371 7.82707 86.7864L26.4921 105.286L45.7008 124.324C48.3193 126.925 51.4307 128.986 54.8559 130.39C58.2812 131.794 61.9527 132.513 65.6593 132.506C70.7362 132.509 75.7203 131.157 80.0876 128.592L61.4601 110.241C61.1645 110.034 60.8847 109.807 60.6228 109.559Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M70.6955 24.4936L79.5 33.1643L94.447 18.356L85.6113 9.68531C81.1736 5.29411 75.3887 2.48995 69.1671 1.71408C62.9454 0.938223 56.64 2.23472 51.2432 5.39952L70.4581 24.2892C70.583 24.3698 70.6268 24.4255 70.6955 24.4936Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M123.775 47.2295L121.769 45.2415L106.828 60.056L108.84 62.0317C110.176 63.3557 110.926 65.1513 110.926 67.0235C110.926 68.8957 110.176 70.6913 108.84 72.0153L86.6697 93.6921L68.6108 111.597H98.5361L123.762 86.7803C129.047 81.5328 132.017 74.422 132.019 67.0075C132.021 59.593 129.057 52.4803 123.775 47.2295Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M31.0176 53.8376L49.7513 72.4052L67.3416 54.971L48.6391 36.3723L31.0176 53.8376Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M99.9895 22.6108L66.9461 55.3612L49.7495 72.4054L64.959 87.4862C65.2213 87.7468 65.533 87.9537 65.8761 88.0948C66.2192 88.2359 66.587 88.3085 66.9585 88.3085C67.33 88.3085 67.6979 88.2359 68.041 88.0948C68.3841 87.9537 68.6958 87.7468 68.9582 87.4862L84.1488 72.4239L134.426 22.5984L99.9895 22.6108Z"
            fill={renderColor || '#FFA44F'}
          />
        </g>
        <path
          d="M162 93.348V41.148H181.944C199.368 41.148 208.656 51.588 208.656 67.212C208.656 83.988 198.36 93.348 181.944 93.348H162ZM171.576 84.924H181.872C192.888 84.924 198.576 77.868 198.576 67.284C198.576 55.62 191.88 49.572 181.872 49.572H171.576V84.924Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M244.249 81.828H253.249C251.305 89.532 244.105 94.068 234.889 94.068C222.721 94.068 214.801 86.22 214.801 74.484C214.801 62.82 222.721 54.972 234.385 54.972C245.329 54.972 252.817 61.956 252.817 72.252C252.817 73.548 252.673 74.412 252.457 75.636H223.945V76.356C223.945 83.052 228.337 86.796 234.817 86.796C239.425 86.796 242.881 84.852 244.249 81.828ZM234.169 62.172C228.985 62.172 225.025 65.196 224.161 69.876H243.529C243.457 65.34 239.569 62.172 234.169 62.172Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M275.222 94.068C265.502 94.068 259.238 89.172 259.166 81.828H267.59C267.734 85.284 270.758 87.372 275.222 87.372C279.614 87.372 281.918 85.5 281.918 82.692C281.918 80.244 280.19 78.948 277.454 78.444L270.542 77.22C264.854 76.14 259.814 73.404 259.814 66.78C259.814 59.652 265.934 54.972 275.006 54.972C284.078 54.972 290.27 59.364 290.342 66.708H282.206C282.062 63.396 279.254 61.596 275.15 61.596C271.262 61.596 268.814 63.324 268.814 66.06C268.814 68.94 271.694 69.588 273.638 69.948L280.55 71.172C288.11 72.468 290.99 76.644 290.99 82.044C290.99 89.172 285.086 94.068 275.222 94.068Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M303.612 51.372C299.58 51.372 297.348 48.924 297.348 45.828C297.348 42.876 299.58 40.5 303.612 40.5C307.572 40.5 309.804 42.876 309.804 45.828C309.804 48.924 307.572 51.372 303.612 51.372ZM299.076 93.348V55.692H308.148V93.348H299.076Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M336.117 109.188C324.669 109.188 317.901 103.716 316.893 95.292H325.749C326.541 99.324 330.645 101.844 336.261 101.844C342.957 101.844 347.421 98.1 347.421 92.052V86.292C345.117 89.892 340.005 92.196 334.245 92.196C323.157 92.196 315.885 84.42 315.885 73.62C315.885 62.748 323.157 54.972 334.245 54.972C340.365 54.972 345.405 57.348 347.925 60.948L348.501 55.692H356.493V91.692C356.493 102.564 348.501 109.188 336.117 109.188ZM336.045 84.78C342.453 84.78 347.421 80.532 347.421 73.908V73.044C347.421 66.42 342.525 62.388 336.117 62.388C329.421 62.388 325.101 66.78 325.101 73.548C325.101 80.1 329.133 84.78 336.045 84.78Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M366.346 93.348V55.692H374.698L375.49 61.164C377.722 57.348 382.33 54.972 387.658 54.972C396.73 54.972 401.41 60.588 401.41 69.66V93.348H392.338V70.956C392.338 65.268 389.314 62.604 384.706 62.604C379.162 62.604 375.418 66.564 375.418 73.116V93.348H366.346Z"
          fill={renderColor || '#344145'}
        />
        <defs>
          <clipPath id="clip0_9085_4165">
            <rect width={134} height={134} fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 65 63"
        {...props}
      >
        <path
          fill={renderColor || '#344145'}
          d="M29.454 51.827 20.28 42.73l-8.915-8.842a3.357 3.357 0 0 1-.996-2.384c0-.894.358-1.752.996-2.384l9.076-8.995 9.962-9.877H16.164l-2.85 2.825-9.076 8.983a13.313 13.313 0 0 0-3.942 9.447c0 3.542 1.418 6.939 3.942 9.446l8.915 8.836 9.174 9.093a13.44 13.44 0 0 0 4.373 2.897c1.636.67 3.39 1.014 5.16 1.01a13.588 13.588 0 0 0 6.89-1.87l-8.896-8.764a3.51 3.51 0 0 1-.4-.325ZM34.265 11.198l4.205 4.142 7.139-7.073-4.22-4.141A13.549 13.549 0 0 0 33.535.319a13.602 13.602 0 0 0-8.56 1.76l9.177 9.022c.06.038.08.065.113.097Z"
        />
        <path
          fill={renderColor || '#344145'}
          d="m59.616 22.057-.958-.95-7.136 7.076.961.944c.638.632.997 1.49.997 2.384 0 .894-.359 1.752-.997 2.384L41.894 44.248 33.27 52.8h14.293L59.61 40.947a13.313 13.313 0 0 0 3.944-9.444 13.313 13.313 0 0 0-3.938-9.446Z"
        />
        <path
          fill={renderColor || '#D4E9EC'}
          d="m15.314 25.213 8.948 8.869 8.401-8.327-8.932-8.883-8.417 8.341Z"
        />
        <path
          fill={renderColor || '#FFA44F'}
          d="M48.256 10.3 32.474 25.94l-8.213 8.14 7.264 7.203a1.35 1.35 0 0 0 .955.393 1.36 1.36 0 0 0 .955-.393l7.255-7.194 24.014-23.797-16.448.006Z"
        />
      </svg>
    );
  }
};
export default Design;
