import { RootState } from '../../configureStore';

export const getIsViewDeviceOpen = (state: RootState) => state.accounts.layout.isViewDeviceOpen;
export const getUsageSelectedYear = (state: RootState) => state.accounts.usageSelectedYear;
export const getIsUpdateSystemOpen = (state: RootState) => state.accounts.layout.isUpdateSystemOpen;
export const getIsDataUpdateOpen = (state: RootState) => state.accounts.layout.isDataUpdateOpen;
export const getUpdateSystemFile = (state: RootState) => state.accounts.updateSystemFile;
export const getIsConfigureMappingOpen = (state: RootState) => state.accounts.layout.isConfigureMappingOpen;
export const getIsAccountSettingsOpen = (state: RootState) => state.accounts.layout.isAccountSettingsOpen;
export const getSingleBilling = (state: RootState) => state.accounts.singleBilling;

// ACCOUNTS
export const getAccountsSearchString = (state: RootState) => state.accounts.accountsSearchString;
export const getAccountsSortBy = (state: RootState) => state.accounts.accountsSortBy;
export const getAccountsFilters = (state: RootState) => state.accounts.accountsFilters;
export const getAccountsFilterOpen = (state: RootState) => state.accounts.layout.isFilterOpen;
