const energyColors = {
  fulvous: '#c77a2b',
  ember: '#d47500',
  magma: '#F18A08',
  spark: '#FF9716',
  energy: '#FFA44F',
  amp: '#FFC085',
  burst: '#FFD5AD',
  ray: '#FEE8CD',
  sheen: '#FBF5E9',
  silt: '#FBF5E9',
  siltSubdued: '#fcf8f0',
  energyDisabled: '#FFC085'
};

const oceanColors = {
  stoneShadow: '#2B3B3E',
  stone: '#344145',
  deep: '#4A636D',
  ocean: '#5E7E8B',
  storm: '#7998A4',
  surf: '#A7C6CD',
  wave: '#D4E9EC',
  mist: '#E4F0F2',
  mistSubdued: '#f2f7f9',
  oceanDisabled: '#696969'
};

const earthColors = {
  kobicha: '#575348',
  root: '#575348',
  camel: '#cdb388',
  earth: '#928877',
  aspen: '#EFECE5'
};

const algaeColors = {
  algae: '#662940',
  anemone: '#410F2F',
  dusty: '#cb6d85',
  coral: '#D1C2C3',
  anemoneSecondary: '#FFEAF2'
};

const cleanColors = {
  altitude: '#15448E',
  blue: '#1D5DC3',
  sky: '#3979E2',
  clean: '#5293FF',
  wind: '#85B4FF',
  air: '#ADCDFF',
  cloud: '#E6F0FF'
};

const mangroveColors = {
  pine: '#007156',
  stem: '#009C77',
  vine: '#00B88C',
  mangrove: '#23CF9F',
  moss: '#51E7BA',
  spore: '#90F5D1',
  pollen: '#CCFFED'
};

const brightColors = {
  brightTextLow: '#856800',
  brightLow: '#A38000',
  dwindle: '#D3A600',
  watt: '#F1BD05',
  glow: '#FFD027',
  bright: '#FBDA63',
  lumen: '#FBE288',
  beacon: '#fff1bf'
};

const reactionColors = {
  reactionTextLow: '#C11524',
  fruit: '#C81927',
  heat: '#E42535',
  reaction: '#E84855',
  temper: '#ED6E78',
  mauvelous: '#F2929A',
  relax: '#F6B6BB',
  pale: '#FBDBDD',
  disabled: '#DBDBDB'
};

const greysColors = {
  greyLight: '#DBDBDB',
  greyMedium: '#696969',
  greyDark: '#5e7b8b'
};

const grapeColors = {
  blush: '#FFEAF2',
  rose: '#ffadd2',
  orion: '#df58a7',
  grape: '#c41d7f'
};

const limeColors = {
  lime: '#c1f278',
  pear: '#70a225',
  seaweed: '#344d0f'
};

const neutralColors = {
  white: '#FFFFFF',
  black: '#000000',
  gray: '#888888',
  grayBright: '#FAFAFA',
  blush: '#fff0f6'
};

const textColors = {
  colorTextDisplay: oceanColors.stone,
  colorTextDisplayVariant: oceanColors.ocean,
  colorTextInvert: neutralColors.white,
  colorTextBrand: energyColors.magma,
  colorTextInfo: cleanColors.blue,
  colorTextSuccess: mangroveColors.pine,
  colorTextWarning: brightColors.brightTextLow,
  colorTextError: reactionColors.reactionTextLow
};

const backgroundColors = {
  colorBackgroundDisplay: neutralColors.white,
  colorBackgroundDisplayBright: oceanColors.mistSubdued,
  colorBackgroundDisplayDim: oceanColors.mist,
  colorBackgroundDisplayDark: oceanColors.stone,
  colorBackgroundDisplayNeutral: neutralColors.grayBright,
  colorBackgroundBrand: energyColors.siltSubdued,
  colorBackgroundBrandVariant: energyColors.ray,
  colorBackgroundInfo: cleanColors.cloud,
  colorBackgroundSuccess: mangroveColors.pollen,
  colorBackgroundWarning: brightColors.beacon,
  colorBackgroundError: reactionColors.pale
};

const backgroundInvertColors = {
  colorBackgroundInvertDisplay: oceanColors.deep,
  colorBackgroundInvertBrand: energyColors.energy,
  colorBackgroundInvertInfo: cleanColors.sky,
  colorBackgroundInvertInfoVariant: cleanColors.blue,
  colorBackgroundInvertSuccess: mangroveColors.stem,
  colorBackgroundInvertSuccessVariant: mangroveColors.pine,
  colorBackgroundInvertWarning: brightColors.bright,
  colorBackgroundInvertError: reactionColors.reaction,
  colorBackgroundInvertErrorVariant: reactionColors.fruit
};

const backgroundAccentsColors = {
  colorBackgroundAccentsEnergy: energyColors.amp,
  colorBackgroundAccentsOcean: oceanColors.wave,
  colorBackgroundAccentsClean: cleanColors.air,
  colorBackgroundAccentsMangrove: mangroveColors.moss,
  colorBackgroundAccentsReaction: reactionColors.relax,
  colorBackgroundAccentsBright: brightColors.glow
};

const borderColors = {
  colorBorderDisplay: oceanColors.wave,
  colorBorderDisplayVariant: oceanColors.surf,
  colorBorderDark: oceanColors.deep,
  colorBorderBrand: energyColors.ray,
  colorBorderBrandVariant: energyColors.magma,
  colorBorderInfo: cleanColors.air,
  colorBorderSuccess: mangroveColors.moss,
  colorBorderWarning: brightColors.bright,
  colorBorderError: reactionColors.reaction,
  colorBorderInput: greysColors.greyDark,
  colorBorderInputHover: oceanColors.deep
};

const linkColors = {
  colorLinkDefault: cleanColors.blue
};

const iconColors = {
  colorIconDisplay: oceanColors.stone,
  colorIconDisplayVariant: oceanColors.ocean,
  colorIconInvert: neutralColors.white,
  colorIconBrand: energyColors.spark,
  colorIconBrandVariant: energyColors.ember,
  colorIconInfo: cleanColors.sky,
  colorIconSuccess: mangroveColors.stem,
  colorIconWarning: brightColors.brightLow,
  colorIconError: reactionColors.heat
};

const chartColors = {
  colorChartBackground: neutralColors.white,
  colorChartBackgroundBright: neutralColors.grayBright,
  colorChartBackgroundDim: earthColors.aspen,
  colorChartBorder: oceanColors.stone,
  colorChartBrand: cleanColors.cloud,
  colorChartBrandVariant: cleanColors.clean
};

export const categoricalSoftColors = {
  colorChartCategorySoft2000: oceanColors.ocean,
  colorChartCategorySoft2001: cleanColors.air,
  colorChartCategorySoft2002: cleanColors.clean,
  colorChartCategorySoft2003: reactionColors.temper,
  colorChartCategorySoft2004: reactionColors.relax,
  colorChartCategorySoft2005: mangroveColors.moss,
  colorChartCategorySoft2006: mangroveColors.stem,
  colorChartCategorySoft2007: brightColors.glow,
  colorChartCategorySoft2008: brightColors.dwindle,
  colorChartCategorySoft2009: oceanColors.wave,
  colorChartCategorySoft2010: oceanColors.storm,
  colorChartCategorySoft2011: energyColors.amp,
  colorChartCategorySoft2012: energyColors.magma,
  colorChartCategorySoft2013: algaeColors.coral,
  colorChartCategorySoft2014: algaeColors.dusty,
  colorChartCategorySoft2015: earthColors.camel,
  colorChartCategorySoft2016: earthColors.earth,
  colorChartCategorySoft2017: limeColors.lime,
  colorChartCategorySoft2018: limeColors.pear,
  colorChartCategorySoft2019: grapeColors.rose,
  colorChartCategorySoft2020: grapeColors.orion
};

export const progressionSoftColors = {
  colorChartProgressionSoft2000: oceanColors.ocean,
  colorChartProgressionSoft2001: mangroveColors.moss,
  colorChartProgressionSoft2002: cleanColors.air,
  colorChartProgressionSoft2003: brightColors.bright,
  colorChartProgressionSoft2004: algaeColors.dusty,
  colorChartProgressionSoft2005: energyColors.amp,
  colorChartProgressionSoft2006: reactionColors.temper,
  colorChartProgressionSoft2007: oceanColors.wave,
  colorChartProgressionSoft2008: earthColors.camel,
  colorChartProgressionSoft2009: limeColors.lime,
  colorChartProgressionSoft2010: grapeColors.rose,
  colorChartProgressionSoft2011: mangroveColors.stem,
  colorChartProgressionSoft2012: cleanColors.clean,
  colorChartProgressionSoft2013: brightColors.dwindle,
  colorChartProgressionSoft2014: algaeColors.coral,
  colorChartProgressionSoft2015: energyColors.magma,
  colorChartProgressionSoft2016: reactionColors.relax,
  colorChartProgressionSoft2017: oceanColors.storm,
  colorChartProgressionSoft2018: earthColors.aspen,
  colorChartProgressionSoft2019: limeColors.pear,
  colorChartProgressionSoft2020: grapeColors.orion
};

export const progressionSharpColors = {
  colorChartProgressionSharp1000: oceanColors.stone,
  colorChartProgressionSharp1001: mangroveColors.stem,
  colorChartProgressionSharp1002: cleanColors.clean,
  colorChartProgressionSharp1003: brightColors.brightLow,
  colorChartProgressionSharp1004: algaeColors.algae,
  colorChartProgressionSharp1005: energyColors.ember,
  colorChartProgressionSharp1006: reactionColors.reaction,
  colorChartProgressionSharp1007: oceanColors.storm,
  colorChartProgressionSharp1008: earthColors.earth,
  colorChartProgressionSharp1009: limeColors.pear,
  colorChartProgressionSharp1010: grapeColors.orion
};

export const progressionSheerColors = {
  colorChartProgressionSheer1000: 'rgba(52, 65, 69, 0.3)',
  colorChartProgressionSheer1001: 'rgba(35, 207, 159, 0.3)',
  colorChartProgressionSheer1002: 'rgba(82, 147, 255, 0.3)',
  colorChartProgressionSheer1003: 'rgba(255, 208, 39, 0.3)',
  colorChartProgressionSheer1004: 'rgba(203, 109, 133, 0.3)',
  colorChartProgressionSheer1005: 'rgba(212, 117, 0, 0.3)',
  colorChartProgressionSheer1006: 'rgba(232, 72, 85, 0.3)',
  colorChartProgressionSheer1007: 'rgba(121, 152, 164, 0.3)',
  colorChartProgressionSheer1008: 'rgba(146, 136, 119, 0.3)',
  colorChartProgressionSheer1009: 'rgba(112, 162, 37, 0.3)',
  colorChartProgressionSheer1010: 'rgba(223, 88, 167, 0.3)'
};

export const chartAccentsBright = {
  colorChartAccentsBrightSofter: brightColors.glow,
  colorChartAccentsBrightSoft: brightColors.dwindle,
  colorChartAccentsBrightSharp: brightColors.brightLow,
  colorChartAccentsBrightSheer: 'rgba(255, 208, 39, 0.3)'
};

export const chartAccentsClean = {
  colorChartAccentsCleanSoft: cleanColors.air,
  colorChartAccentsCleanSharp: cleanColors.clean,
  colorChartAccentsCleanSharper: cleanColors.altitude,
  colorChartAccentsCleanSheer: 'rgba(82, 147, 255, 0.3)'
};

export const chartAccentsEnergy = {
  colorChartAccentsEnergySofter: energyColors.amp,
  colorChartAccentsEnergySoft: energyColors.magma,
  colorChartAccentsEnergySharp: energyColors.ember,
  colorChartAccentsEnergySheer: 'rgba(212, 117, 0, 0.3)'
};

export const chartAccentsMangrove = {
  colorChartAccentsMangroveSoft: mangroveColors.moss,
  colorChartAccentsMangroveSharp: mangroveColors.stem,
  colorChartAccentsMangroveSharper: mangroveColors.pine,
  colorChartAccentsMangroveSheer: 'rgba(35, 207, 159, 0.3)'
};

export const chartAccentsReaction = {
  colorChartAccentsReactionSofter: reactionColors.relax,
  colorChartAccentsReactionSoft: reactionColors.temper,
  colorChartAccentsReactionSharp: reactionColors.reaction,
  colorChartAccentsReactionSheer: 'rgba(232, 72, 85, 0.3)'
};

const colors = {
  ...energyColors,
  ...oceanColors,
  ...earthColors,
  ...algaeColors,
  ...cleanColors,
  ...mangroveColors,
  ...brightColors,
  ...reactionColors,
  ...greysColors,
  ...grapeColors,
  ...limeColors,
  ...neutralColors,
  ...textColors,
  ...backgroundColors,
  ...backgroundInvertColors,
  ...backgroundAccentsColors,
  ...borderColors,
  ...linkColors,
  ...iconColors,
  ...chartColors,
  ...categoricalSoftColors,
  ...progressionSoftColors,
  ...progressionSheerColors,
  ...chartAccentsBright,
  ...chartAccentsClean,
  ...chartAccentsEnergy,
  ...chartAccentsMangrove
};

export const chartColorsSchema1 = [
  colors.clean,
  colors.energy,
  colors.fulvous,
  colors.temper,
  colors.surf,
  colors.mangrove,
  colors.bright,
  colors.reaction,
  colors.algae,
  colors.wind,
  colors.dwindle,
  colors.mauvelous
];

export default colors;
