import React, { useContext } from 'react';

// COMPONENTS
import Drawer from 'components/Drawer/Drawer';
import Box from 'components/Box/Box';
import BackLink from 'widget/ExploreWidget/components/BackLink/BackLink';
import Button from 'components/Button/Button';

// ASSETS
import styles from './DrawerModifiers.module.scss';

// HELPERS
import { ActionTypes, ExploreContext } from 'widget/ExploreWidget/helpers/ExploreContext';
import useMediaQuery, { breakpoints } from 'hooks/useMediaQuery';
import { useResourceList } from 'widget/ExploreWidget/api/resources.api';
import { buildResourceList } from './DrawerModifiers.helpers';
import Icon from 'components/Icon/Icon';
import ModifierItem from '../ModifierItem/ModifierItem';
import { getModifierTitle, getModifierIcon, getModifierImage } from 'widget/ExploreWidget/helpers/utils/getModifierIcon';
import { Modifier } from 'widget/ExploreWidget/helpers/types';
import colors from 'style/colors';
// import EnergyResourceItemLoading from '../EnergyResourceItem/EnergyResourceItemLoading';

interface DrawerModifiersProps {
  rateCode: string;
  resourceItem: any;
  onRemoveItem: (resource: any) => void;
}

const DrawerModifiers: React.FC<DrawerModifiersProps> = ({ rateCode, resourceItem, onRemoveItem }) => {
  const { state, dispatch } = useContext(ExploreContext);
  const isMobile = useMediaQuery(breakpoints.mobileMedium);
  const { sectionTitle, resources: resourcesContent } = state.content.resourceList;
  const { isLoading, isFetched, data } = useResourceList({
    rateCode,
    enabled: true
  });
  const buildResourceItem = resourceItem;

  const handleClose = () => {
    dispatch({ type: ActionTypes.TOGGLE_ENERGY_RESOURCES, payload: '' });
  };

  const handleSelectModifier = (modifier: Modifier) => {
    dispatch({ type: ActionTypes.TOGGLE_ENERGY_RESOURCES, payload: '' });
    dispatch({ type: ActionTypes.SET_SINGLE_MODIFIER_OPEN, payload: modifier });
  };

  return (
    <Drawer
      open={state.isEnergyResourcesOpen}
      onClose={handleClose}
      anchor="right"
      width={isMobile ? '100vw' : 400}
      BackdropProps={{ invisible: false }}
      footer={
        <Box display="flex" justifyContent="end">
          <Button copy="Close" color="secondary" variant="outlined" size='md' onClick={handleClose} />
        </Box>
      }
      bodyStyle={{
        backgroundColor: colors.colorBackgroundDisplayNeutral
      }}
      footerStyle={{
        backgroundColor: colors.white,
        borderTop: `1px solid ${colors.wave}`
      }}
    >
      <Box mb={3} my={3}>
        <div className={styles.titleContainer}>
          <Icon.Zap />
          <p className={styles.title}>{sectionTitle}</p>
        </div>
        <p className={styles.description}>Browse our list of clean energy technologies. Click “Select” to configure the details for your calculation.</p>
      </Box>
      <div className={styles.modifiers}>
        <ModifierItem
          title='Solar panels'
          description='Power your home with rooftop solar. Receive a bill credit for the electricity you don’t use.'
          icon={{ src: getModifierIcon('solar'), alt: 'modifier icon' }}
          image={{ src: getModifierImage('solar'), alt: 'modifier image' }}
          onSelected={() => handleSelectModifier('solar')}
        />
        <ModifierItem
          title='Electric vehicles'
          description='Save on driving costs with an electric vehicle. Charge at home when prices are lowest.'
          icon={{ src: getModifierIcon('ev'), alt: 'modifier icon' }}
          image={{ src: getModifierImage('ev'), alt: 'modifier image' }}
          onSelected={() => handleSelectModifier('ev')}
        />
        <ModifierItem
          title='Battery storage'
          description='When combined with a renewable energy source, battery storage can power your home when needed. You can also save money by using stored energy when rates are higher during peak hours of the day.'
          icon={{ src: getModifierIcon('battery'), alt: 'modifier icon' }}
          image={{ src: getModifierImage('battery'), alt: 'modifier image' }}
          onSelected={() => handleSelectModifier('battery')}
        />
        <ModifierItem
          title='HVAC heat pump'
          description='Electric heat pumps are 4 times more efficient at heating your home and can be a 2-for-1 replacement for your heating and AC units.'
          icon={{ src: getModifierIcon('heatPump'), alt: 'modifier icon' }}
          image={{ src: getModifierImage('heatPump'), alt: 'modifier image' }}
          onSelected={() => handleSelectModifier('heatPump')}
        />
        <ModifierItem
          title={getModifierTitle('waterHeater')}
          description='This technology is 3 to 4 times more efficient than gas water heaters.'
          icon={{ src: getModifierIcon('waterHeater'), alt: 'modifier icon' }}
          image={{ src: getModifierImage('waterHeater'), alt: 'modifier image' }}
          onSelected={() => handleSelectModifier('waterHeater')}
        />
        <ModifierItem
          title='Electric dryer'
          description='Energy Star® dryers use about 20% less energy than conventional dryer models.'
          icon={{ src: getModifierIcon('dryer'), alt: 'modifier icon' }}
          image={{ src: getModifierImage('dryer'), alt: 'modifier image' }}
          onSelected={() => handleSelectModifier('dryer')}
        />
        <ModifierItem
          title='Electric range'
          description='Electric resistance stoves are more energy-efficient than natural gas ranges. Electric resistance stoves are 75% efficient while gas stoves are 40% efficient.'
          icon={{ src: getModifierIcon('range'), alt: 'modifier icon' }}
          image={{ src: getModifierImage('range'), alt: 'modifier image' }}
          onSelected={() => handleSelectModifier('range')}
        />
      </div>
      {/* {isLoading && (
        <Box mb={3} display="flex" flexDirection="column" gap={1.5}>
          <EnergyResourceItemLoading/>
          <EnergyResourceItemLoading/>
          <EnergyResourceItemLoading/>
        </Box>
      )} */}
      {/* {
        isFetched && (
          <Box mb={3} display="flex" flexDirection="column" gap={1.5}>
            {buildResourceList(data, resourcesContent, buildResourceItem, onRemoveItem, dispatch)}
          </Box>
        )} */}
    </Drawer>
  );
};

export default DrawerModifiers;
