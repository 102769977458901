import React, { Fragment, ReactNode } from 'react';

import Header from '../../components/Header/Header';
import AlertSticky from '../../components/AlertSticky/AlertSticky';
import Icon from '../../components/Icon/Icon';
import Link from '../../components/Link/Link';
import Text from '../../components/Text/Text';

interface Props {
  type?: string,
  alertMessage?: string
  icon?: ReactNode
  action?: string
  closeButton?: boolean
};

const AlertStickyPage: React.FC = () => {
  const renderAlertSticky: React.FC<Props> = ({
    type = 'light' as any,
    alertMessage = 'Alert',
    icon,
    closeButton = false
  }) => {
    return (
      <AlertSticky
        color={type}
        alertMessage={alertMessage}
        icon={icon}
        closeButtonClickFn={() => {}}
        closeButton={closeButton}
      />
    );
  };
  const alertMessage = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.';
  return (
    <Fragment>
      <Header
        className='design-studio pad--b-100 pad--l-128 pad--t-100'
        color='stone'
        text='Alert Sticky'
      >
        <Text
          className='max-width--900 pad--t-40 title--sm'
          color='white'
          variant='body1'
        >
          Sticky alerts or toasts are alerts that stick to the top or bottom of the page, and are only displayed for a few seconds. They can be used to show notifications to users, for example, when a user submits a form, etc.
        </Text>
        <Link
          className='underline dis--block mar--t-60 body--xl'
          color='white'
          copy='See Our Guidelines'
          href='/'
        />
      </Header>
      <div
        className='mar--l-80 mar--r-80 mar--t-80 max-width--lg pad--b-156 temp--min-width--sm'
      >
        <section className='mar--r-20 mar--l-20 mar--b-20'>
          {renderAlertSticky({ type: 'dark', alertMessage, icon: <Icon.Info size={16}/>, action: 'none', closeButton: false })}
          {renderAlertSticky({ type: 'dark', alertMessage, icon: <Icon.Info size={16}/>, action: 'none', closeButton: true })}
          {renderAlertSticky({ type: 'dark', alertMessage, icon: true, action: 'none', closeButton: false })}
          {renderAlertSticky({ type: 'dark', alertMessage, icon: true, action: 'none', closeButton: true })}
          {renderAlertSticky({ type: 'dark', alertMessage, icon: <Icon.Info size={16}/>, action: 'primary', closeButton: false })}
          {renderAlertSticky({ type: 'dark', alertMessage, icon: <Icon.Info size={16}/>, action: 'primary-secondary', closeButton: true })}
          {renderAlertSticky({ type: 'dark', alertMessage, icon: true, action: 'primary', closeButton: false })}
          {renderAlertSticky({ type: 'dark', alertMessage, icon: true, action: 'primary', closeButton: true })}
          {renderAlertSticky({ type: 'dark', alertMessage, icon: <Icon.Info size={16}/>, action: 'primary-secondary', closeButton: false })}
          {renderAlertSticky({ type: 'dark', alertMessage, icon: true, action: 'primary-secondary', closeButton: false })}
          {renderAlertSticky({ type: 'dark', alertMessage, icon: true, action: 'primary-secondary', closeButton: true })}

          {renderAlertSticky({ type: 'light', alertMessage, icon: <Icon.Info size={16}/>, action: 'none', closeButton: false })}
          {renderAlertSticky({ type: 'light', alertMessage, icon: <Icon.Info size={16}/>, action: 'none', closeButton: true })}
          {renderAlertSticky({ type: 'light', alertMessage, icon: true, action: 'none', closeButton: false })}
          {renderAlertSticky({ type: 'light', alertMessage, icon: true, action: 'none', closeButton: true })}
          {renderAlertSticky({ type: 'light', alertMessage, icon: <Icon.Info size={16}/>, action: 'primary', closeButton: false })}
          {renderAlertSticky({ type: 'light', alertMessage, icon: <Icon.Info size={16}/>, action: 'primary-secondary', closeButton: true })}
          {renderAlertSticky({ type: 'light', alertMessage, icon: true, action: 'primary', closeButton: false })}
          {renderAlertSticky({ type: 'light', alertMessage, icon: true, action: 'primary', closeButton: true })}
          {renderAlertSticky({ type: 'light', alertMessage, icon: <Icon.Info size={16}/>, action: 'primary-secondary', closeButton: false })}
          {renderAlertSticky({ type: 'light', alertMessage, icon: true, action: 'primary-secondary', closeButton: false })}
          {renderAlertSticky({ type: 'light', alertMessage, icon: true, action: 'primary-secondary', closeButton: true })}
        </section>
      </div>
    </Fragment>
  );
};

export default AlertStickyPage;
