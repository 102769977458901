import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

// TYPINGS
import { BillingDetails } from 'api/billing/billing.typings';

// ASSETS
import colors from 'style/colors';
import styles from './ChartBillingDetails.module.scss';

interface ChartBillingDetailsProps {
  data: BillingDetails[]
}

const ChartBillingDetails:React.FC<ChartBillingDetailsProps> = () => {
  const dataChart = [
    {
      y: 1254245,
      name: 'Production Metered Demand 147,570 kW @ $8.50',
      color: colors.blue
    },
    {
      y: -16974.50,
      name: 'Customer Load Member Distributed Generation (1,997) kW @ 8.50',
      color: colors.clean
    },
    {
      y: 461580,
      name: 'Production Fixed Energy Charge',
      color: colors.wind
    },
    {
      y: 639125.67,
      name: 'Transmission Metered Demand —> 147,570 kW @ $ 4.33100',
      color: colors.algae
    },
    {
      y: 207930.17,
      name: 'Non-Fuel Metered Energy 49,272,551 @ $ 0.00422',
      color: colors.coral
    },
    {
      y: 524687.91,
      name: 'On-Peak Metered Energy 12,456,978 kWH @ 0.04212',
      color: colors.pine
    },
    {
      y: 889316.69,
      name: 'Off-Peak Metered Energy 25,129,039 kWH @ 0.0353',
      color: colors.vine
    },
    {
      y: 334468.60,
      name: 'Super Off-Peak Metered Energy 11,686,534 kWh @ $ 0.02862',
      color: colors.moss
    },
    {
      y: -10225.50,
      name: 'Excess Capacity Agreement (1,203) kW @ $ 8.50000',
      color: colors.bright
    }
  ];

  const options = {
    chart: {
      type: 'bar',
      height: (dataChart.length * 24) + 51
    },
    title: {
      text: ''
    },
    yAxis: {
      opposite: true,
      min: -1300000,
      max: 1300000,
      title: '',
      gridLineWidth: 0
    },
    xAxis: {
      visible: false
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
        pointWidth: 24
      }
    },
    series: [
      {
        color: colors.energy,
        data: dataChart
      }
    ]
  };

  return (
    <div className={styles.container}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ChartBillingDetails;
