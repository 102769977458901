import React from 'react';
import classnames from 'classnames';

// ASSETS
import styles from './Divider.module.scss';

type DividerColors = 'light-primary' | 'light-secondary' | 'light-tertiary' | 'dark-primary' | 'dark-secondary' | 'dark-tertiary';

interface Props {
    className?: string,
    color?: DividerColors | 'stone' | 'mist' | 'ocean' | 'wave' | 'white' | 'surf' | 'deep'
};

/**
* Represents a horizontal divider.
* @constructor
* @param {string} className
* @param {string} color
* Usage :
* ````js
* <Divider className="my-divider" />
* ````
* @augments {Component<Props, State>}
*/
/** */
const Divider: React.FC<Props> = ({ className, color }: Props) => {
  const cssDivider = classnames(styles.divider, styles[`divider--${color}`], className);

  return (
    <span
      className={cssDivider}
      aria-hidden
      role='presentation'
    >
    </span>
  );
};

export default Divider;
