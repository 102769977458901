/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';

// COMPONENTS
import Box from 'components/Box/Box';
import Table from 'components/Table/Table';

// ASSETS
import styles from './CostResultTable.module.scss';

// HELPERS
import { prepareTableData } from './CostResultTable.helpers';

import { CompareRateDetails } from './types';
import { ColumnType } from 'components/Table/Table.helpers';
import { Resource } from 'widget/ExploreWidget/api/projects.typings';
import { Icon } from 'components';

type Column = {
  title: string;
  dataIndex: string;
  key: string;
  render: (content: any) => React.ReactElement;
};

type ConstResultTableProps = {
  data: CompareRateDetails;
  resourseList?: Array<Resource>;
};

const ConstResultTable = ({ data, resourseList }: ConstResultTableProps) => {
  const [columns, setColumns] = useState<Array<Column>>([]);
  const [tableData, setTableData] = useState<Array<any>>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleExpandRow = (rowKey: string) => {
    setTableData((prev) => {
      return prev.map((prevObj) => ({
        ...prevObj,
        ...(rowKey === prevObj.key && { isExpanded: !prevObj.isExpanded })
      }));
    });
  };

  useEffect(() => {
    if (!data) return;

    const { tagAttributesList = [] } = data;

    const firstColumn: Column = {
      title: '',
      dataIndex: '',
      key: 'date',
      render: (rowObj) => {
        return (
          <>
            <div className={styles['month-value']}>
              {rowObj.isExpandable && (
                <button
                  data-testid="accordion-button"
                  onClick={() => toggleExpandRow && toggleExpandRow(rowObj.key)}>
                  {rowObj.isExpanded ? <Icon.ChevronDown /> : <Icon.ChevronRight />}
                </button>
              )}
              <span className={styles['cost-value__value']}>{rowObj.date}</span>
            </div>

            {rowObj.isExpanded && (
              <div
                data-testid="accordion-expanded-layout"
                className={styles['month-value__expanded']}>
                {tagAttributesList.map((tag: string) => (
                  <div key={tag}>
                    <span className={styles['tag-attributes']}>{tag}</span>
                  </div>
                ))}
              </div>
            )}
          </>
        );
      }
    };

    const columns: Array<Column> = data.availableRates.map((rate: string, index: number) => ({
      title: `${rate}-${index}`,
      dataIndex: '',
      key: `${rate}-${index}`,
      className: styles['cost-td'],
      width: 320,
      render: (rowObj: any) => {
        const children = rowObj[`${rate}-${index}`];
        return <div>{typeof children === 'function' ? children(rowObj.isExpanded) : children}</div>;
      }
    }));

    const tableData = prepareTableData(data, resourseList);

    setColumns([firstColumn, ...columns]);
    setTableData([...tableData]);
  }, [data]);

  return (
    <div className={styles['rates-table__container']}>
      <div>
        <div className={styles['rates-table']}>
          <Table
            columns={columns as Array<ColumnType<Column>>}
            dataSource={tableData}
            columnPinning={{ left: ['date'] }}
          />
        </div>
      </div>
    </div>
  );
};

export default ConstResultTable;
