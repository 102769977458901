import React from 'react';

// ASSETS
import styles from './BannerExplore.module.scss';
import BannerImage from '../../../../assets/banner-image.jpg';
import { getModifierIcon } from 'widget/ExploreWidget/helpers/utils/getModifierIcon';

const BannerExplore = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>Clean Energy Calculator</h1>
        <h2>Your all-electric home guide. Estimate costs and savings, review incentives, compare technologies and more</h2>
        <p>
          Evaluate how clean energy technologies like electric heat pumps, solar, electric vehicles would impact your energy costs. Start your calculation by selecting from our list of clean energy technologies. You’ll get cost estimates based on your past 12 months of usage and helpful implementation tips.
        </p>
        <div className={styles.icons}>
          <div className={styles.icon}><img src={getModifierIcon('solar')} alt="modifier icon" /></div>
          <div className={styles.icon}><img src={getModifierIcon('ev')} alt="modifier icon" /></div>
          <div className={styles.icon}><img src={getModifierIcon('battery')} alt="modifier icon" /></div>
          <div className={styles.icon}><img src={getModifierIcon('heatPump')} alt="modifier icon" /></div>
          <div className={styles.icon}><img src={getModifierIcon('waterHeater')} alt="modifier icon" /></div>
          <div className={styles.icon}><img src={getModifierIcon('dryer')} alt="modifier icon" /></div>
          <div className={styles.icon}><img src={getModifierIcon('range')} alt="modifier icon" /></div>
        </div>
      </div>
      <div
        className={styles.bannerImage}
        style={{ backgroundImage: `url(${BannerImage})` }}
      />
    </div>
  );
};

export default BannerExplore;
