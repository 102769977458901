import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { reducers } from '.';

const rootReducer = combineReducers({
  ...reducers
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware())
);

export type RootState = ReturnType<typeof rootReducer>;

export default store;
