import React from 'react';
import { Column } from '@tanstack/react-table';
import classnames from 'classnames';

// ASSETS
import styles from './ColumnHeader.module.scss';
import ColumnFilter from '../ColumnFilter/ColumnFilter';
import ColumnSearch from '../ColumnSearch/ColumnSearch';

interface ColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>
  children: React.ReactNode
}

const ColumnHeader = <TData, TValue>({
  column,
  children
}: ColumnHeaderProps<TData, TValue>) => {
  if (!column.getCanSort() && !column.getCanFilter()) {
    return <span className={styles['column-title']}>{children}</span>;
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      column.toggleSorting();
    }
  };

  const buildFilterOptions = () => {
    const options = Array.from(column.getFacetedUniqueValues().keys()).sort();
    const filterOptions = options.map((option) => {
      return {
        label: option,
        value: option
      };
    });

    return filterOptions;
  };

  // RENDERS
  const renderFilter = () => {
    switch ((column.columnDef.meta as any)?.filterType) {
    case 'search':
      return (
        <ColumnSearch column={column}/>
      );
    default:
      return <ColumnFilter column={column} options={buildFilterOptions()} />;
    }
  };

  // STYLES
  const cssSortContainer = classnames(styles['column-sorters'], styles['column-title']);

  return (
    <>
      <div className={styles['column-header']}>
        <div
          className={cssSortContainer}
          onClick={() => column.toggleSorting()}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex={0}
        >
          <span>{children}</span>
          {
            column.getCanSort() && (
              <span className={styles['column-sorter']}>
                <span className={styles['column-sorter__inner']} aria-hidden="true">
                  <span role="img" aria-label="caret-up" className={styles['column-sorter__up']}>
                    <svg viewBox="0 0 1024 1024" focusable="false" data-icon="caret-up" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                      <path d="M183.6 724h656.8c19.7 0 30.7-20.8 18.5-35L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35z"></path>
                    </svg>
                  </span>
                  <span role="img" aria-label="caret-down" className={styles['column-sorter__down']}>
                    <svg viewBox="0 0 1024 1024" focusable="false" data-icon="caret-down" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                      <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
                    </svg>
                  </span>
                </span>
              </span>
            )
          }
        </div>
        {
          column.getCanFilter() && <>{renderFilter()}</>
        }
      </div>
    </>
  );
};

export default ColumnHeader;
