/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable keyword-spacing */
/* eslint-disable no-trailing-spaces */
/* eslint-disable jsx-a11y/anchor-is-valid  */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Logo from '../Logo/Logo';
import Text from '../Text/Text';

import { TenantProductsPermissions } from 'components/Auth/User';
import { useNavigate } from 'react-router-dom';
import './product-menu.scss';

import { AuthorizationClient } from 'components/Auth/AuthorizationClient';
import { GRIDX_APPLICATIONS, GridxApplication, SEQUENTIAL_GRIDXAPPS } from '../constants';

type Props = {
  assumedTenant?: string,
  citadelApiUrl?: string,
  currentTenant: string,
  productPermissions?: Array<TenantProductsPermissions>,
};

const ProductMenu: React.FC<Props> = ({ assumedTenant, currentTenant, productPermissions = [] }: Props) => {
  const navigate = useNavigate();

  // First filter any applications out that are not in the permissions set.
  const tenantProductsPermissions = productPermissions.filter((permission: TenantProductsPermissions) => {
    return permission.tenant?.tenantName?.toLowerCase() === assumedTenant?.toLowerCase();
  });
  const productPermissionsList = tenantProductsPermissions[0]?.products ?? [];

  const permissableApps = GRIDX_APPLICATIONS.filter(gridxApp => {
    return productPermissionsList.find(prodPermission => prodPermission.productName === gridxApp.heading);
  });

  const orderApplications = (name: string) => {
    let itemIndex: any;
    permissableApps.map((item, index) => {
      if(item.heading === name) {
        itemIndex = index;
        return index;
      }
    });
    return itemIndex;
  };

  const sequentialPermissableApps: any = [];
  SEQUENTIAL_GRIDXAPPS.map((item : any) => {
    // eslint-disable-next-line prefer-const
    let appIndex: any = orderApplications(item);
    appIndex >= 0 ? sequentialPermissableApps.push(permissableApps[appIndex]) : '';
  });
 
  const [showOptions, setShowOptions] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const currentRef: any = ref.current;
    const handleClickOutside = (event: any) => {
      if (currentRef && !currentRef.contains(event.target)) {
        showOptions && setShowOptions(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [showOptions]);

  const renderApps = (list: Array<GridxApplication>) => {
    const apps = list.map((item: GridxApplication, index: number) => {
      const {
        icon,
        heading
      } = item;
   
      const product = productPermissionsList.find(prodPermission => prodPermission.productName === heading);
      const productUrl = product?.productUrl ?? `https://${heading.toLowerCase()}.gridx.com`;
      const sanitizedProductUrl = productUrl?.replace(/\/$/, '');
      const authUrl = `${sanitizedProductUrl}/authcode`;

      return (
        <>
          <li
            className='flex pad--b-12 pad--l-24 pad--r-24 pad--t-12 width--100-p'
            key={heading}
          >
            <a
              className='icon text--center'
              onClick={() =>
                AuthorizationClient.startAuthenticationRedirection(
                  currentTenant,
                  true,
                  authUrl
                )
              }
            >
              <Logo
                className='dis--inline-block'
                name={icon}
                size='sm'
              />
              <Text
                className='body--xl text--bold'
                variant="body1"
              >
                {heading}
              </Text>
            </a>
          </li>
          {index % 2 === 1 ? <hr className='horizontal-line'></hr> : ''}
        </>
      );
    });
    return apps;
  };

  return (
    <div
      className='menu--main'
      ref={ref}
      role='button'
      tabIndex={0}
      onClick={() => setShowOptions(!showOptions)}
      onKeyDown={() => setShowOptions(!showOptions)}
    >
      <Text
        className="visually-hidden"
        variant="body1"
      >
        Menu
      </Text>
      {showOptions &&
      <div className="sub-nav flex--column flex-align-center flex jus--space-between pad--l-0 bor--1 shadow--sm">
        <ul className='mar--b-0 mar--t-0 pad--t-12 row jus--start'>
          {renderApps(sequentialPermissableApps)}
        </ul>
        {sequentialPermissableApps.length % 2 === 0 ? '' : <hr className='horizontal-line-explore'></hr> }
        <div className='flex jus--center pad--t-16 pad--b-16 width--100-p'>
          { GRIDX_APPLICATIONS.length !== productPermissions.length // Need to use the product list as source of truth to compare to.
            ? <Button
              copy='Explore more'
              color='magma'
              size='md'
              icon={<Icon.Compass />}
              iconPosition='left'
              type={'secondary'}
              onClick={() => navigate('/') }
            />
            : <></> }
        </div>
        <hr className='horizontal-line-explore'></hr>
        <div className='flex home-naviagtion-section'>
          <Logo className="home-logo" name="citadel" size="sm" />
          <a
            className='home-link'
            href={'/'}
          >
            Home
          </a>
        </div>
      </div> }
    </div>
  );
};

export default ProductMenu;
