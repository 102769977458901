import React from 'react';
import classnames from 'classnames';

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(
  // eslint-disable-next-line react/prop-types
  ({ className, ...props }, ref) => (
    <caption
      ref={ref}
      className={classnames('mt-4 text-sm text-muted-foreground', className)}
      {...props}
    />
  ));

TableCaption.displayName = 'TableCaption';

export default TableCaption;
