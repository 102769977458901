import React, { useContext, useEffect } from 'react';

// COMPONENTS
import FooterNavigation from 'widget/ExploreWidget/components/FooterNavigation/FooterNavigation';
import ScenarioItem from 'widget/ExploreWidget/components/ScenarioItem/ScenarioItem';
import EletricCostResult from './components/EletricCostResult/EletricCostResult';

// ASSETS
import styles from './ResultsPage.module.scss';

// HELPERS
import { ExploreContext } from 'widget/ExploreWidget/helpers/ExploreContext';
import ScenarioItemLoading from 'widget/ExploreWidget/components/ScenarioItem/ScenarioItemLoading';
import { getModifierKey } from 'widget/ExploreWidget/helpers/utils/getModifierIcon';

interface ResultsPageProps {
  isLoading: boolean;
}

const ResultsPage:React.FC<ResultsPageProps> = ({ isLoading }) => {
  const { state } = useContext(ExploreContext);

  const renderScenarios = () => {
    // if (isLoading) {
    //   return (
    //     <>
    //       <ScenarioItemLoading/>
    //       <ScenarioItemLoading/>
    //     </>
    //   );
    // }

    const currentScenario = state.cache.rateDetails?.find((rate: any) => rate.scenarioTags.includes('base'));
    const cleanEnergyScenario = state.cache.rateDetails?.find((rate: any) => rate.scenarioTags.includes('best'));
    const modifiers = state.appliedResource?.map((resource: any) => getModifierKey(resource.name));

    return (
      <>
        <ScenarioItem
          rate="TOU"
        />
        <ScenarioItem
          rate="SPP"
          modifiers={modifiers}
          withChanges
        />
      </>
    );
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>Electric cost estimates</p>

      <div className={styles.scenarios}>
        {renderScenarios()}
      </div>
      <EletricCostResult/>
      <p className={styles.description}>Cost estimates are based on your previous 12 months of usage</p>
      <FooterNavigation
        description='Click “Save & continue” to explore next steps for implementation, or click “Save & quit” to return to your calculation at a later time.'
      />
    </div>
  );
};

export default ResultsPage;
