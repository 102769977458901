import React from 'react';
import Popper, { PopperProps } from '@mui/base/Popper';

// STYLES
import styles from './Popper.module.scss';

const CustomPopper:React.FC<PopperProps> = ({ children, open, className, ...props }) => {
  const anchorEl = props.anchorEl as HTMLElement;

  return (
    <Popper
      className={`${styles.popper} ${className}`} open={open} {...props}
      style={{ width: anchorEl.clientWidth }}
    >
      {children}
    </Popper>
  );
};

export default CustomPopper;
