import React, { createContext, useEffect, useReducer } from 'react';

import defaultCMSData from './cms-data.json';

import { LayoutActionTypes } from './context/layout/layout.store';
import { CompareRateDetails } from '../sections/RateComparison/types';
import { Rate, Resource } from '../api/projects.typings';
import { Modifier } from './types';

const projectID = Number(new URLSearchParams(location.search).get('projectID')) || undefined;

export const ActionTypes = {
  ...LayoutActionTypes,
  UPDATE_CONTENT: 'UPDATE_CONTENT',
  SET_PROJECT_MODE: 'SET_PROJECT_MODE',
  UPDATE_APP_CACHE: 'UPDATE_APP_CACHE',
  OVERRIDE_STATE: 'OVERRIDE_STATE',
  SET_SELECTED_RATE: 'SET_SELECTED_RATE',
  SET_APPLIED_RATE_CODE: 'SET_APPLIED_RATE_CODE',
  SET_BEST_RATE_CODE: 'SET_BEST_RATE_CODE',
  SET_SELECTED_RESOURCE: 'SET_SELECTED_RESOURCE',
  SET_APPLIED_RESOURCE: 'SET_APPLIED_RESOURCE',
  RESET_APP_CACHE: 'RESET_APP_CACHE',
  REFRESH_COST_SAVINGS: 'REFRESH_COST_SAVINGS',
  SET_SINGLE_MODIFIER_OPEN: 'SET_SINGLE_MODIFIER_OPEN',
  SET_SAVE_CALCULATION: 'SET_SAVE_CALCULATION',
  SET_CALCULATION_STEP: 'SET_CALCULATION_STEP'
};

type ProjectMode = 'NEW' | 'EDIT' | 'TEMPLATE_CUSTOMIZATION';

type Cache = {
  compareRatesDetails?: CompareRateDetails;
  projectResources?: Array<Resource>;
  rateDetails?: Array<Rate>;
  costSavings?: number;
};

// Define state and dispatch types
type State = {
  sectionPath: string;
  isProjectSettingsOpen: boolean;
  isProjectDeleteOpen: boolean;
  isProjectSaveOpen: boolean;
  isCostEstimatesOpen: boolean;
  isEnergyResourcesOpen: boolean;
  isSolarPanelsOpen: boolean;
  isElectricVehicleOpen: boolean;
  isHeatPumpOpen: boolean;
  isRatesOpen: boolean;
  activeCalculationStep: number;
  openSingleModifier: Modifier | null;
  saveCalculation: {
    isOpen: boolean;
    afterSaveAction?: 'quit' | 'continue'
  };
  cache: Cache;
  selectedRate: Rate | null;
  bestRate: Rate | null;
  appliedRateCode: string | null;
  appliedResource: Array<Resource> | null;
  isRateDetailsOpen: boolean;
  shouldRefreshCostSavings: boolean;
  projectMode: ProjectMode;
  apiUrl: string;
  contractId: string;
  content: any;
  mock: any;
};

export type Action = {
  type: string;
  payload?: any;
};

type ContextProps = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

// Define your reducer
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
  case ActionTypes.TOGGLE_PROJECT_SETTINGS:
    return { ...state, isProjectSettingsOpen: !state.isProjectSettingsOpen };
  case ActionTypes.TOGGLE_PROJECT_DELETE:
    return { ...state, isProjectDeleteOpen: !state.isProjectDeleteOpen };
  case ActionTypes.TOGGLE_PROJECT_SAVE:
    return { ...state, isProjectSaveOpen: !state.isProjectSaveOpen };
  case ActionTypes.TOGGLE_COST_ESTIMATES:
    return { ...state, isCostEstimatesOpen: !state.isCostEstimatesOpen };
  case ActionTypes.TOGGLE_ENERGY_RESOURCES:
    return { ...state, isEnergyResourcesOpen: !state.isEnergyResourcesOpen };
  case ActionTypes.TOGGLE_SOLAR_PANELS:
    return {
      ...state,
      isSolarPanelsOpen: !state.isSolarPanelsOpen,
      isEnergyResourcesOpen: false
    };
  case ActionTypes.TOGGLE_ELECTRIC_VEHICLE:
    return {
      ...state,
      isElectricVehicleOpen: !state.isElectricVehicleOpen,
      isEnergyResourcesOpen: false
    };
  case ActionTypes.TOGGLE_HEAT_PUMP:
    return {
      ...state,
      isHeatPumpOpen: !state.isHeatPumpOpen,
      isEnergyResourcesOpen: false
    };
  case ActionTypes.SET_SINGLE_MODIFIER_OPEN:
    return {
      ...state,
      openSingleModifier: action.payload
    };
  case ActionTypes.SET_SAVE_CALCULATION:
    return {
      ...state,
      saveCalculation: action.payload
    };
  case ActionTypes.SET_CALCULATION_STEP:
    return { ...state, activeCalculationStep: action.payload };
  case ActionTypes.TOGGLE_RATES:
    return { ...state, isRatesOpen: !state.isRatesOpen };
  case ActionTypes.TOGGLE_RATE_DETAILS:
    return { ...state, isRateDetailsOpen: !state.isRateDetailsOpen };
  case ActionTypes.UPDATE_CONTENT:
    return { ...state, content: action.payload };
  case ActionTypes.SET_PROJECT_MODE:
    return { ...state, projectMode: action.payload };
  case ActionTypes.UPDATE_APP_CACHE:
    return { ...state, cache: { ...state.cache, ...action.payload } };
  case ActionTypes.OVERRIDE_STATE:
    return { ...state, ...action.payload };
  case ActionTypes.SET_SELECTED_RATE:
    return { ...state, selectedRate: action.payload };
  case ActionTypes.SET_APPLIED_RATE_CODE:
    return { ...state, appliedRateCode: action.payload };
  case ActionTypes.SET_BEST_RATE_CODE:
    return { ...state, bestRate: action.payload };
  case ActionTypes.SET_APPLIED_RESOURCE:
    return { ...state, appliedResource: action.payload, cache: { ...state.cache, projectResources: action.payload } };
  case ActionTypes.REFRESH_COST_SAVINGS:
    return { ...state, shouldRefreshCostSavings: action.payload };
  case ActionTypes.RESET_APP_CACHE:
    return { ...state, cache: {}, selectedRate: null, appliedRateCode: null, appliedResource: null, shouldRefreshCostSavings: false };
  default:
    return state;
  }
};

const initialState: State = {
  sectionPath: '',
  isProjectSettingsOpen: false,
  isProjectDeleteOpen: false,
  isProjectSaveOpen: false,
  isCostEstimatesOpen: false,
  isEnergyResourcesOpen: false,
  isSolarPanelsOpen: false,
  isElectricVehicleOpen: false,
  isHeatPumpOpen: false,
  openSingleModifier: null,
  saveCalculation: {
    isOpen: false
  },
  isRatesOpen: false,
  isRateDetailsOpen: false,
  projectMode: projectID ? 'EDIT' : 'NEW',
  activeCalculationStep: 0,
  apiUrl: '',
  contractId: '',
  content: defaultCMSData,
  cache: {},
  selectedRate: null,
  bestRate: null,
  appliedRateCode: null,
  appliedResource: null,
  shouldRefreshCostSavings: false,
  mock: {
    projectsCount: 0
  }
};

// Create a context with dummy default values
export const ExploreContext = createContext<ContextProps>({
  state: initialState,
  dispatch: () => undefined
});

interface ExploreProviderProps {
  children: React.ReactNode;
  apiUrl: string;
  contractId: string;
  content: any;
  cache?: Cache;
}

export const ExploreProvider: React.FC<ExploreProviderProps> = ({
  children,
  apiUrl,
  contractId,
  content,
  cache
}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    apiUrl,
    contractId,
    ...(content ? { content } : {})
  });

  useEffect(() => {
    if (content) {
      dispatch({ type: ActionTypes.UPDATE_CONTENT, payload: content });
    }

    if (cache) {
      dispatch({ type: ActionTypes.UPDATE_APP_CACHE, payload: cache });
    }
  }, [content]);

  return <ExploreContext.Provider value={{ state, dispatch }}>{children}</ExploreContext.Provider>;
};
