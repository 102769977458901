import React, { Fragment } from 'react';

import Header from '../../components/Header/Header';
import Image from '../../components/Image/Image';
import Link from '../../components/Link/Link';
import Text from '../../components/Text/Text';

interface Props {
    className?: string,
};

const SpacingPage: React.FC<Props> = () => {
  return (
    <Fragment>
      <Header
        className='design-studio pad--b-100 pad--l-128 pad--t-100'
        color='stone'
        text='Spacing Usage'
      >
        <Text
          className='max-width--900 pad--t-40 title--sm'
          color='white'
          variant='body1'
        >
            ...
        </Text>
        <Link
          className='underline dis--block mar--t-60 body--xl'
          color='white'
          copy='See Our Guidelines'
          href='/'
        />
      </Header>
      <div
        className='mar--l-96 mar--t-80 max-width--lg temp--min-width--md'
      >
        <div
          className='pad--b-80'
        >
          <Text
            className='display-heading--lg max-width--900 pad--b-128 pad--t-40'
            color='stone'
            variant='body1'
          >
            Desktop
          </Text>
          <div className='mar--b-128'>
            <Image
              alt='Spacing Guide'
              src='../../images/styleguide/desktop-spacing.svg'
            />
          </div>
          <Text
            className='display-heading--lg max-width--900 pad--b-128 pad--t-40'
            color='stone'
            variant='body1'
          >
            Mobile
          </Text>
          <div>
            <Image
              alt='Spacing Guide'
              src='../../images/styleguide/mobile-spacing.svg'
            />
          </div>

        </div>
      </div>
    </Fragment>
  );
};

export default SpacingPage;
