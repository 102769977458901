import * as React from 'react';

export interface SortProps {
  alternate?: boolean,
  className?: string,
  style?: object,
};

const Sort:React.FC<SortProps> = ({
  alternate,
  className,
  style,
  ...props
}: SortProps) => {
  const colorEnergy = '#FFA44F';
  return (
    <div
      className={className}
      style={style}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30px"
        height="20px"
        fill="none"
        {...props}
      >
        <path d="M5.25 14.25V3" stroke="#344145" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1.5 6.75L5.25 3L9 6.75" stroke="#344145" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        {alternate
          ? (
            <>
              <path d="M12.75 3.75V15" stroke={colorEnergy} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M16.5 11.25L12.75 15L9 11.25" stroke={colorEnergy} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </>
          )
          : (
            <>
              <path d="M12.75 3.75V15" stroke="#344145" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M16.5 11.25L12.75 15L9 11.25" stroke="#344145" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </>
          )}

      </svg>
    </div>
  );
};
export default Sort;
