import React from 'react';

import Image from '../Image/Image';

// ASSETS
import styles from './Avatar.module.scss';

// HELPERS
import { generateInitials } from './Avatar.helpers';

type AvatarColors = 'ocean' | 'energy' | 'clean' | 'magrove' | 'bright' | 'reaction';

interface Props {
  alt: string,
  className?: string,
  color?: AvatarColors,
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
  customSize?: number,
  src?: string,
};

/**
* Represents a Avatar.
* @constructor
* @param {string} alt
* @param {string} className
* @param {string} color
* @param {string} size
* @param {string} src
* Usage :
* ````js
* <Avatar alt="My image alt" className="my-avatar" size="small"  src="../images/my_image.jpg" />
* ````
* @augments {Component<Props, State>}
*/
/** */

const Avatar: React.FC<Props> = ({
  alt = '',
  className,
  color = 'ocean',
  size = 'sm',
  customSize,
  src
}: Props) => {
  // STYLES
  let customStyles = {};
  if (customSize) {
    customStyles = {
      width: `${customSize}px`,
      height: `${customSize}px`
    };
  }

  // Add avatar bg color by first letter of first name
  const avatarColorByName = (alt:string) => {
    const colors = ['ocean', 'energy', 'clean', 'magrove', 'bright', 'reaction'];
    const hashFunction = (letter: string) => {
      const lowercase = letter.toLowerCase();
      const charCode = lowercase.charCodeAt(0) - 97;
      if (charCode <= 5) {
        return charCode;
      }

      return charCode % 5;
    };

    return colors[hashFunction(alt)];
  };

  return (
    <div
      className={
        `${styles.avatar}
        flex flex--align-center jus--center
        ${className || ''}
        ${src || `${styles['no--img']} ${styles[color] || avatarColorByName(Array.from(alt)[0])}`}
        ${styles[`avatar--${size}`]}
        `
      }
      style={customStyles}
    >
      {src
        ? (
          <Image
            alt={alt}
            src={src}
          />
        )
        : (
          <span className={styles.initials}>
            {generateInitials(alt)}
          </span>
        )}
    </div>
  );
};

export default Avatar;
