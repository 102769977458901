import React from 'react';
import classnames from 'classnames';

// COMPONENTS
import AttributeTag from '../AttributeTag/AttributeTag';
import { Tag } from 'components';

// ASSETS
import styles from './ScenarioItem.module.scss';

// HELPERS
import { modifierKeyLabel } from 'widget/ExploreWidget/helpers/utils/modifersKeyLabel';
import { Modifier } from 'widget/ExploreWidget/helpers/types';

interface ScenarioItemProps {
  rate?: string;
  modifiers?: Array<string>;
  withChanges?: boolean;
}

const ScenarioItem:React.FC<ScenarioItemProps> = ({ rate = '', modifiers, withChanges }) => {
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.header}>
          <p className={styles.title}>{withChanges ? 'Clean energy scenario' : 'Current Scenario'}</p>
          <Tag
            copy={withChanges ? 'With changes' : 'Without changes'}
            size="sm"
            color={withChanges ? 'invert-success' : 'mist'}
          />
        </div>
        <div className={styles.tags}>
          <AttributeTag
            type="rate"
            label={rate}
          />
          {
            modifiers && modifiers.map((modifier: string, index: number) => {
              const label = modifierKeyLabel.filter((item: any) => item.modifier === modifier)[0].label;
              return (
                <AttributeTag
                  key={index}
                  type="modifier"
                  modifier={modifier as Modifier}
                  label={label}
                />
              );
            })
          }
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.costs}>
          <div className={styles.costItem}>
            <p>12-month total electric cost</p>
            <p className={styles.costValue}>{withChanges ? '$2190' : '$3045'}</p>
          </div>
          <div className={styles.costItem}>
            <p>Average monthly electric cost</p>
            <p className={styles.costValue}>{withChanges ? '$183' : '$254'}</p>
          </div>
        </div>
        {
          withChanges && (
            <div className={styles.costs}>
              <div className={styles.costItem}>
                <p>12-month total electric savings</p>
                <p className={classnames(styles.costValue, styles.costSaving)}>$855</p>
              </div>
              <div className={styles.costItem}>
                <p>Average monthly electric savings</p>
                <p className={classnames(styles.costValue, styles.costSaving)}>$71</p>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default ScenarioItem;
