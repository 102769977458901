import React from 'react';

// COMPONENTS
import { Tag } from '../../../../components';
import { TagColor } from 'components/Tag/Tag.helpers';

interface StatusTagProps {
  status: string
}

const StatusTag:React.FC<StatusTagProps> = ({ status }) => {
  // RENDERS
  let color = 'stone';
  switch (status) {
  case 'Draft':
    color = 'bright';
    break;
  case 'Production':
    color = 'stem';
    break;
  case 'Discontinued':
    color = 'pale';
    break;
  }

  return (
    <Tag copy={status} color={color as any} size='sm' />
  );
};

export default StatusTag;
