import React from 'react';
import { useDispatch } from 'react-redux';

// COMPONENTS
import { FilterControl } from 'blocks/components';
import { Box, DatePicker, Icon } from 'components';

// ASSETS
import { setBillingFilter } from 'store/modules/billing/billing.slice';

// TYPINGS
import { FilterItem } from 'store/helpers/store.typings';
import { IBilling } from 'api/billing/billing.typings';

interface FilterBillCycleStartProps {
  selectedFilters: FilterItem<IBilling>[];
}

const FilterBillCycleStart:React.FC<FilterBillCycleStartProps> = ({ selectedFilters }) => {
  const dispatch = useDispatch();

  // EVENTS
  const handleDateChange = (dates: Date[]) => {
    dispatch(setBillingFilter({ key: 'startDate', type: 'daterange', value: dates }));
  };

  // RENDER
  const filterValue = selectedFilters.find((filter) => filter.key === 'startDate')?.value as Date[];

  return (
    <FilterControl title="Bill Cycle Start" expanded>
      <Box zIndex={9999}>
        <DatePicker
          size='md'
          format='y-MM-dd'
          dayPlaceholder='Datepicker'
          calendarIcon={<Icon.Calendar />}
          isRange={true}
          onChange={handleDateChange}
          value={filterValue}
          clearIcon={null}
        />
      </Box>
    </FilterControl>
  );
};

export default FilterBillCycleStart;
