import React from 'react';
import { useFormContext } from 'react-hook-form';

// COMPONENTS
import Select from 'components/CustomSelect/CustomSelect';
import Box from 'components/Box/Box';

const FormElectricDryer = () => {
  const { register, getValues } = useFormContext();

  // RENDERS
  const dryerTypeField = register('dryerTypeField');
  const occupantsField = register('occupantsField');
  const compareToField = register('compareToField');

  return (
    <div>
      <Box mb={3}>
        <Select
          label="Type"
          options={[
            { value: 'Electric', label: 'Electric' },
            { value: 'Heat Pump', label: 'Heat Pump' }
          ]}
          defaultValue={getValues().dryerTypeField}
          inputRef={dryerTypeField.ref}
          onChange={(_, value: any) => dryerTypeField.onChange({ target: { value, name: dryerTypeField.name } })}
        />
      </Box>
      <Box mb={3}>
        <Select
          label="Occupants"
          options={[
            { value: 1, label: '1' },
            { value: 2, label: '2' },
            { value: 3, label: '3' },
            { value: 4, label: '4' },
            { value: 5, label: '5' },
            { value: 6, label: '6' },
            { value: 7, label: '7' },
            { value: 8, label: '8' },
            { value: 9, label: '9' },
            { value: 10, label: '10+' }
          ]}
          defaultValue={getValues().occupantsField}
          inputRef={occupantsField.ref}
          onChange={(_, value: any) => occupantsField.onChange({ target: { value, name: occupantsField.name } })}
        />
      </Box>
      <Select
        label="Compare to"
        options={[
          { value: 'Natural Gas', label: 'Natural Gas' },
          { value: 'Electric', label: 'Electric' },
          { value: 'Propane', label: 'Propane' }
        ]}
        defaultValue={getValues().compareToField}
        inputRef={compareToField.ref}
        onChange={(_, value: any) => compareToField.onChange({ target: { value, name: compareToField.name } })}
      />
    </div>
  );
};

export default FormElectricDryer;
