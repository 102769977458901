import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { RatesGridOverview } from '@gridx/metronome-components';

// COMPONENTS
import ExploreNav from 'pages/Explore/sections/ExplorePage/components/ExploreNav/ExploreNav';
import MainPage from '../../components/MainPage/MainPage';
import BannerBlock from '../../components/BannerBlock/BannerBlock';
import Box from 'components/Box/Box';
import { Button, Icon } from 'components';

// ASSETS
import '@gridx/metronome-components/styles/css/bundle.css';

const RatesPrograms = () => {
  const navigate = useNavigate();

  return (
    <div>
      <ExploreNav onCmsChange={() => {}} company={''} />
      <MainPage title="Rates & Programs">
        <Box mb={3}>
          <Button
            copy="View details"
            color="secondary"
            variant="outlined"
            iconRight={<Icon.ArrowRight/>}
            size="md"
            onClick={() => navigate('/rate-analysis/rates-programs-details')}
          />
        </Box>
        {/* <RatesGridOverview
          contractId="3"
          apiUrl='https://312458b5-df28-4b21-ba38-1a4e2a44a667.mock.pstmn.io'
        /> */}
        <Box mt={3}>
          <BannerBlock/>
        </Box>
      </MainPage>
    </div>
  );
};

export default RatesPrograms;
