import React from 'react';
import Highcharts from 'highcharts';

// COMPONENTS
import OverlapTimeChart from '../../../../../../../../components/OverlapTimeChart/OverlapTimeChart';

// ASSETS
import styles from './SeasonCharts.module.scss';

// HELPERS
import { series } from './SeasonCharts.helpers';
import colors from '../../../../../../../../style/colors';

interface SeasonChartsProps {
  tdWidth: { first: number, second: number }
}

const SeasonCharts:React.FC<SeasonChartsProps> = ({ tdWidth }) => {
  return (
    <>
      <div className={styles['empty-box']} style={{ width: tdWidth.first }} />
      <OverlapTimeChart
        series={series}
        yAxis={{ categories: ['Peak'] }}
        width={tdWidth.second}
        height={160}
        xAxis={{
          min: Date.UTC(2023, 0, 1, 0, 0, 0),
          max: Date.UTC(2023, 11, 31, 23, 59, 59),
          tickInterval: 24 * 3600 * 1000 * 30,
          labels: {
            staggerLines: 1,
            style: {
              color: colors.stone,
              fontSize: '14px',
              fontFamily: 'Aktifo-B, sans-serif'
            },
            formatter: function (this: Highcharts.AxisLabelsFormatterContextObject): string {
              const date = new Date(this.value);
              const month = date.getUTCMonth();
              const months = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];
              return months[month];
            }
          },
          dateTimeLabelFormats: {
            month: '%b'
          }
        }}
      />
    </>
  );
};

export default SeasonCharts;
