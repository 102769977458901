import React from 'react';

import './video-background.scss';

interface Props {
    className?: string,
    mp4?: string,
    poster?: string,
    webm?: string
};

/**
* Represents a VideoBackground.
* @constructor
* @param {string} className
* @param {string} mp4
* @param {string} poster
* @param {string} webm
* Usage :
* ````js
* <VideoBackground className='my--video-bg' mp4='./my-video.mp4' poster='./my-video-poster.jpg' webm='./my-video.webm' />
* ````
* @augments {Component<Props, State>}
*/
/** */
const VideoBackground: React.FC<Props> = ({
  className,
  mp4,
  poster,
  webm
}: Props) => {
  return (
    <div
      className={
        `${className || ''}
        bg-video--wrapper`
      }
    >
      <video className='bg-video--container' id="bg--video" playsInline autoPlay muted loop poster={poster}>
        <source src={mp4} type="video/mp4" />
        <source src={webm} type="video/webm"></source>
      </video>
    </div>
  );
};

export default VideoBackground;
