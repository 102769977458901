import React from 'react';

// COMPONENTS
import { InfoItem } from 'blocks/components';
import { Tag } from 'components';

// ASSETS
import styles from './BillingDetailsInfo.module.scss';

// HELPERS
import { getStatusCustomColor, getStatusTextColor } from 'pages/Billing/sections/BillingList/components/ListBilling/ListBilling.helpers';
import { useTheme } from 'hooks/Theme/Theme.context';
import { IBilling } from 'api/billing/billing.typings';

interface BillingDetailsInfoProps {
  bill: IBilling;
}

const BillingDetailsInfo:React.FC<BillingDetailsInfoProps> = ({ bill }) => {
  const { getThemeColor, hasThemePalette } = useTheme();

  return (
    <div>
      <div className={styles['info-container']}>
        <InfoItem
          title="Status"
          description={
            <Tag
              copy={bill.status}
              size="sm"
              backgroundColor={getStatusCustomColor(bill.status, getThemeColor)}
              textColor={getStatusTextColor(bill.status, hasThemePalette)}
            />
          }
        />
      </div>
      <div className={styles['details-container']}>
        <div>
          <div className={styles['info-box']}>
            <InfoItem title="Service Agreement" description={bill.serviceAgreement} />
          </div>
          <div className={styles['info-box']}>
            <InfoItem title="SA ID" description={bill.saId} />
          </div>
          <div className={styles['info-box']}>
            <InfoItem title="Billing Account Number" description={bill.billingAccountNumber} />
          </div>
        </div>
        <div>
          <div className={styles['info-box']}>
            <InfoItem title="Billing Address" description={bill.billingAddress} />
          </div>
          <div className={styles['info-box']}>
            <InfoItem title="State" description={bill.state} />
          </div>
          <div className={styles['info-box']}>
            <InfoItem title="Zip" description={bill.zip} />
          </div>
        </div>
        <div>
          <div className={styles['info-box']}>
            <InfoItem title="Bill Cycle" description={bill.billCycle} />
          </div>
          <div className={styles['info-box']}>
            <InfoItem title="Bill Start Date" description={bill.startDate} />
          </div>
          <div className={styles['info-box']}>
            <InfoItem title="Bill End Date" description={bill.endDate} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingDetailsInfo;
