import React, { useState } from 'react';

// COMPONENTS
import FeatureIcon from 'components/FeatureIcon/FeatureIcon';
import Icon from 'components/Icon/Icon';
import Switch from 'components/Switch/Switch';
import DatePicker from 'components/DatePicker/DatePicker';
import { ActionList, Dropdown, Popover } from 'components';

// ASSETS
import styles from './TariffModelHeader.module.scss';
import { ActionListItem } from 'components/ActionList/ActionList.helpers';

interface TariffModelHeaderProps {
  title: string;
  description: string;
  hasCustomerPreview: boolean;
  contractId: string;
  dates: [Date, Date]
  onCopy: () => void;
  onDelete: () => void;
  onSettings: () => void;
  onCustomerPreviewChange: () => void;
  onChangeCustomer: () => void,
  onDatesChange: (newDates: [Date, Date]) => void;
  actionsList: ActionListItem[]
}

const TariffModelHeader:React.FC<TariffModelHeaderProps> = ({
  title,
  description,
  contractId,
  hasCustomerPreview = false,
  dates,
  actionsList,
  onCopy,
  onDelete,
  onSettings,
  onDatesChange,
  onChangeCustomer,
  onCustomerPreviewChange
}) => {
  const [moreActionsActive, setMoreActionsActive] = useState(false);

  // EVENTS
  const togglePopover = () => {
    setMoreActionsActive(!moreActionsActive);
  };

  const handleKeyDownPopover = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setMoreActionsActive(!moreActionsActive);
    }
  };

  const handleCustomerChange = () => {
    onChangeCustomer();
  };

  const handleKeyDownCustomerChange = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onChangeCustomer();
    }
  };

  // RENDERS
  const activator = (
    <div
      className={styles.moreVertical}
      onClick={togglePopover}
      onKeyDown={handleKeyDownPopover}
      aria-label="Open more actions"
      role="button"
      tabIndex={0}
    >
      <Icon.MoreVertical/>
    </div>
  );

  const headerActions = [
    {
      content: 'Settings',
      onClick: () => { onSettings(); }
    },
    {
      content: 'Copy',
      onClick: () => { onCopy(); }
    },
    {
      content: 'Delete',
      onClick: () => { onDelete(); }
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.containerLeft}>
        <div className={styles.modelIcon}>
          <FeatureIcon icon="rates-done-right" size="xl" />
        </div>
        <div>
          <div className={styles.titleContainer}>
            <p className={styles.title}>{title}</p>
            <div
              className={styles.actions}
            >
              <Popover
                p={0}
                placement="bottom-end"
                isOpen={moreActionsActive}
                renderReference={activator}
                onOutsideClickClose={() => setMoreActionsActive(false)}
              >
                <ActionList items={headerActions} />
              </Popover>
            </div>
          </div>
          <div className={styles.description}>{description}</div>
        </div>
      </div>
      <div className={styles.containerRight}>
        <div className={styles.containerCustomer}>
          <div className={styles.customerPreview}>
            <Switch
              checked={hasCustomerPreview}
              size="sm"
              onChange={onCustomerPreviewChange}
            />
            <span>Account Preview</span>
          </div>
          <div className={styles.user}>
            <div className={styles.userIcon}>
              <Icon.User size={16}/>
            </div>
            <div className={styles.userId}>
              <span>{contractId}</span>
            </div>
            <div
              className={styles.userEdit}
              onClick={handleCustomerChange}
              onKeyDown={handleKeyDownCustomerChange}
              aria-label="Change Customer action"
              role="button"
              tabIndex={0}
            >
              <Icon.Edit3 size={16}/>
            </div>
          </div>
        </div>
        <div>
          <DatePicker
            calendarIcon={<Icon.Calendar />}
            dayPlaceholder="date"
            format="MM/yy"
            maxDetail="year"
            value={dates}
            disabled={!hasCustomerPreview}
            onChange={onDatesChange}
          />
        </div>
        <div>
          <Dropdown
            label='Actions'
            options={actionsList}
            color="primary"
            variant="contained"
            size='md'
            disabled={false}
          />
        </div>
      </div>
    </div>
  );
};

export default TariffModelHeader;
