import React from 'react';
import { useParams } from 'react-router-dom';

// COMPONENTS
import { Breadcrumb, Dropdown, Icon } from 'components';
import { AccountInsights, AccountTabs, ContractDetails } from './components';

// ASSETS
import styles from './Account.module.scss';

// HELPERS
import { useSingleAccount } from 'api/accounts/accounts.api';
import { useTheme } from 'hooks/Theme/Theme.context';
import { ShareButton } from 'blocks/components';
import AccountSettingsDrawer from './components/AccountSettingsDrawer/AccountSettingsDrawer';
import { setIsAccountSettingsOpen } from 'store/modules/accounts/accounts.slice';
import { useDispatch } from 'react-redux';

const Account = () => {
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const { slug } = useTheme();
  const { data: accountData, isLoading } = useSingleAccount(accountId || '');

  // RENDERS
  const actionList = [
    {
      startIcon: <Icon.Settings size={18}/>,
      content: 'Settings',
      onClick: () => {
        dispatch(setIsAccountSettingsOpen(true));
      }
    },
    {
      startIcon: <Icon.Upload size={18} />,
      content: 'Upload',
      onClick: () => {}
    },
    {
      startIcon: <Icon.Download size={18} />,
      content: 'Export',
      onClick: () => {}
    },
    {
      startIcon: <Icon.EyeOff size={18} />,
      content: 'Deactivate',
      onClick: () => {}
    }
  ];

  return (
    <div className={`${styles['account-page']} admin--page pad--t-128 pad--l-128 pad--r-36`} role="main">
      {(!isLoading && accountData) && (
        <>
          <div className="pad--b-24">
            <Breadcrumb
              withRouter={true}
              items={[
                {
                  title: 'Accounts',
                  bold: true,
                  href: `/${slug}/accounts`
                },
                {
                  title: accountData.serviceAgreement
                }
              ]}
            />
          </div>
          <div className={styles.header}>
            <h2>{accountData.serviceAgreement}</h2>
            <div className={styles.actions}>
              <Dropdown label="Actions" options={actionList} color='depth' />
              <ShareButton/>
            </div>
          </div>
          <ContractDetails account={accountData}/>
          {/* <AccountDetails account={accountData} /> */}
          <AccountInsights />
          <AccountTabs/>
          <AccountSettingsDrawer account={accountData}/>
        </>
      )}
    </div>
  );
};

export default Account;
