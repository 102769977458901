import React from 'react';
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';
import { useSelector } from 'react-redux';

// COMPONENTS
import { Breadcrumb } from '../../../../components';
import { FilterSearchAnalyze } from '../../components';
import Text from '../../../../components/Text/Text';
import { FilterDateRange, FilterStatus, ListRates } from './components';

// SELECTORS
import { getFiltersSearch, getIsFilterOpen } from '../../../../store/modules/analyze/analyze.selector';

// API
import { useRates } from '../../../../api/analyze/analyze.api';

// STYLES
import styles from './Rates.module.scss';
import { IRate } from '../../../../api/analyze/analyze.typings';
import { FilterPanel } from 'blocks/components';

const Rates = () => {
  const { filters, searchString } = useSelector(getFiltersSearch);
  const { data: rates, isLoading } = useRates({ filters, searchString });
  const isFilterOpen = useSelector(getIsFilterOpen);

  return (
    <div className={`${styles['rates-page']} admin--page pad--t-128 pad--l-128 pad--r-36`} role="main">
      <div className="pad--b-24">
        <Breadcrumb
          withRouter={true}
          items={[
            {
              title: 'Home',
              bold: true
            },
            {
              title: 'Rates'
            }
          ]}
        />
      </div>
      <div className="pad--b-24">
        <Text className='title--md' variant='h2'>
          Rates
        </Text>
      </div>
      <FilterSearchAnalyze />
      <div className={styles.main}>
        <AnimatePresence>
          {isFilterOpen && (
            <motion.div
              initial={{ x: '-100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            >
              <FilterPanel aria-label="Filter Population" onClose={() => {}}>
                <FilterStatus/>
                <FilterDateRange/>
              </FilterPanel>
            </motion.div>
          )}
        </AnimatePresence>
        <div className={styles.content}>
          {
            (!isLoading && rates) && (
              <ListRates rates={rates as IRate[]} />
            )
          }
        </div>
      </div>
    </div>
  );
};

export default Rates;
