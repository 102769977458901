import React from 'react';

// COMPONENTS
import Button, { ButtonProps } from '../Button/Button';

// ASSETS
import styles from './ListShowMore.module.scss';
import { Box } from 'components/Box';

interface ListShowMoreProps {
  total: number;
  current: number;
  onShowMore: () => void;
  buttonProps?: {
    color?: 'primary' | 'secondary',
    size?: 'xs' | 'sm' | 'md' | 'lg',
    variant?: 'contained' | 'outlined',
    fullWidth?: boolean,
  }
}

const ListShowMore:React.FC<ListShowMoreProps> = ({ total, current, onShowMore, buttonProps }) => {
  return (
    <div className={styles.container}>
      <Box width={432} display="flex" justifyContent="center">
        {(current < total) && <Button color="primary" copy="Show more" size="md" onClick={onShowMore} fullWidth {...buttonProps} />}
      </Box>
      <p>{current >= total ? total : current} of {total}</p>
    </div>
  );
};

export default ListShowMore;
