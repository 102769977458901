import React from 'react';

// COMPONENTS
import { GraphCard, LegendGraphItem } from '../../../../../../blocks/components';
import Icon from '../../../../../../components/Icon/Icon';
import DonutChart from '../../../../../../components/DonutChart/DonutChart';

// ASSETS
import styles from './GraphBalance.module.scss';

// HELPERS
import { sampleData } from './GraphBalance.helpers';
import colors from 'style/colors';

const GraphBalance = () => {
  return (
    <GraphCard
      title="Estimated vs. Actual"
      icon={<Icon.User />}
    >
      <div className={styles['graph-container']}>
        <div className={styles['legend-container']}>
          <LegendGraphItem name="Estimated" color={colors.clean} />
          <LegendGraphItem name="Actual" color={colors.wind} />
        </div>
        <div>
          <DonutChart
            data={sampleData}
            height={288}
          />
        </div>
      </div>
    </GraphCard>
  );
};

export default GraphBalance;
