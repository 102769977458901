import { Color, ColorCode } from 'hooks/Theme/Theme.model';
import colors from 'style/colors';

export const getCustomerTypeColor = (customerType: string) => {
  switch (customerType) {
  case 'Active':
    return 'stem';
  }
};

export const getCustomerTypeCustomColor = (customerType: string, getThemeColor: Function) => {
  switch (customerType) {
  case 'Active':
    return getThemeColor(Color.Primary, ColorCode.C200, colors.stem);
  default:
    return '#333';
  }
};

export const getCustomerTypeTextColor = (customerType: string, hasThemePalette: boolean) => {
  switch (customerType) {
  case 'Active':
    return hasThemePalette ? '#fff' : '';
  default:
    return '#333';
  }
};
