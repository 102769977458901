import React, { Fragment } from 'react';

import Header from '../../components/Header/Header';
import Icon from '../../components/Icon/Icon';
import Link from '../../components/Link/Link';
import Tag from '../../components/Tag/Tag';
import Text from '../../components/Text/Text';
import { TagColor } from 'components/Tag/Tag.helpers';

interface Props {
  color?: any,
  copy?: string,
};

const TagPage: React.FC = () => {
  const renderTags: React.FC<Props> = ({
    color = 'stone',
    copy = 'Tag'
  }) => {
    return (
      <div className='mar--b-20'>
        <Tag
          className='mar--r-8'
          color={color}
          copy={copy}
        />
        <Tag
          className='mar--r-8'
          color={color}
          copy={copy}
          icon={<Icon.Clock />}
          iconPos='left'
          // @ts-ignore
          iconName='clock'
        />
        <Tag
          className='mar--r-8'
          color={color}
          copy={copy}
          icon={<Icon.X />}
          iconPos='right'
          // @ts-ignore
          iconName='close'
        />
      </div>
    );
  };

  return (
    <Fragment>
      <Header
        className='design-studio pad--b-100 pad--l-128 pad--t-100'
        color='stone'
        text='Tags'
      >
        <Text
          className='max-width--900'
          color='white'
        >
          Tags visually label items with small amounts of information or the item&apos;s status, usually with keywords that help organize and categorize the items. They may be persistent or dismissible
        </Text>
        <Link
          className='underline dis--block mar--t-60 body--xl'
          color='white'
          copy='See Our Guidelines'
          href='/'
        />

      </Header>
      <div
        className='mar--t-80 pad--l-128 max-width--lg  temp--min-width--md'
      >
        <section
          className='pad--b-80 pad--r-80'
        >
          <Text
            className='max-width--900 pad--b-40 pad--t-40 headline--md'
            color='stone'
            variant='body1'
          >
            Tag Colorful
          </Text>
          {renderTags({ color: 'stone', copy: 'Primary' })}
          {renderTags({ color: 'ocean', copy: 'Secondary' })}
          {renderTags({ color: 'wave', copy: 'Tertiary' })}
          {renderTags({ color: 'warning', copy: 'Warning' })}
          {renderTags({ color: 'alert', copy: 'Alert' })}
          {renderTags({ color: 'sky', copy: 'Info Primary' })}
          {renderTags({ color: 'algae', copy: 'Info secondary' })}
          {renderTags({ color: 'earth', copy: 'Info tertiary' })}
          {renderTags({ color: 'spore', copy: 'Success secondary' })}
          {renderTags({ color: 'beacon', copy: 'Warning secondary' })}
          {renderTags({ color: 'relax', copy: 'Alert secondary' })}
        </section>
        <section
          className='pad--b-80 pad--r-80'
        >
          <Text
            className='max-width--900 pad--b-40 pad--t-40 headline--md'
            color='stone'
            variant='body1'
          >
            Tag
          </Text>
          {renderTags({ color: 'sheen', copy: 'Primary' })}
          {renderTags({ color: 'mist', copy: 'Secondary' })}
          {renderTags({ color: 'pollen', copy: 'Success' })}
          {renderTags({ color: 'beacon', copy: 'Warning' })}
          {renderTags({ color: 'pale', copy: 'Alert' })}
          {renderTags({ color: 'cloud', copy: 'Info primary' })}
          {renderTags({ color: 'anemone-secondary', copy: 'Info secondary' })}
          {renderTags({ color: 'kobicha', copy: 'Info tertiary' })}
        </section>
      </div>
    </Fragment>
  );
};

export default TagPage;
