import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import { Drawer } from 'components';
import { BillingSingleView } from 'pages/Accounts/components';

// TYPINGS
import { IBilling } from 'api/billing/billing.typings';

// SELECTORS
import { getIsBillingDetailsOpen } from 'store/modules/billing/billing.selector';

// ACTIONS
import { setBillingDetailsOpen } from 'store/modules/billing/billing.slice';

interface IBillingDetailsProps {
  bill: IBilling;
}

const BillingDetails:React.FC<IBillingDetailsProps> = ({ bill }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsBillingDetailsOpen);

  // EVENTS
  const handleClose = () => {
    dispatch(setBillingDetailsOpen(false));
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      size="lg"
      onClose={handleClose}
      title="Bill Details"
    >
      {
        bill && (
          <BillingSingleView bill={bill}/>
        )
      }
    </Drawer>
  );
};

export default BillingDetails;
