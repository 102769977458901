import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import DataTable from 'components/DataTable/DataTable';
import { Drawer, Select, Box, Button } from 'components';
import TextInput from 'components/TextInput/TextInput';

// SELECTORS
import { getIsConfigureMappingOpen } from 'store/modules/accounts/accounts.selector';

// ACTIONS
import { setIsConfigureMappingOpen } from 'store/modules/accounts/accounts.slice';

// HELPERS
import { buildColumns, data } from './ConfigureMappingDrawer.helpers';

// ASSETS
import styles from './ConfigureMappingDrawer.module.scss';

const ConfigureMappingDrawer = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsConfigureMappingOpen);
  const [dataSource, setDataSource] = useState(data);

  // EVENTS
  const handleClose = () => {
    dispatch(setIsConfigureMappingOpen(false));
  };

  // RENDERS
  const conditionOptions = [
    { value: 'sum', label: 'Sum' },
    { value: 'min', label: 'Min' },
    { value: 'max', label: 'Max' },
    { value: 'avg', label: 'Average' }
  ];

  const pricingOptions = [
    { value: 'b6TOU', label: 'IntervalkWh(Seasons, Summer, T-1, Flat)' },
    { value: 'register', label: 'RegisterkWh(Seasons, Summer, T-1, Flat)' },
    { value: 'kVar', label: 'kVar(Seasons, Summer, T-1, Flat)' },
    { value: 'demand', label: 'Demand' }
  ];

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      size="md"
      onClose={handleClose}
      title="Mapping"
    >
      <p className={styles.title}>Virtual Meter ID 231234137</p>
      <Box mb={3}>
        <Box mb={3}>
          <TextInput label="Description" defaultValue="Total kWh Delivered" size="md" />
        </Box>
        <Select label="Condition" options={conditionOptions} defaultValue="sum" />
      </Box>
      <Box mb={3}>
        <DataTable columns={buildColumns(setDataSource) as any} data={dataSource} />
      </Box>
      <Box mb={3}>
        <Select label="Pricing Node Reference" defaultValue="b6TOU" options={pricingOptions} />
      </Box>
      <Box textAlign="right" mb={6}>
        <Button copy="Apply" color="depth" type="primary" size="md" onClick={handleClose} />
      </Box>
    </Drawer>
  );
};

export default ConfigureMappingDrawer;
