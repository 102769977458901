import { Color, ColorCode } from '../../../../hooks/Theme/Theme.model';

export const getCustomerTypeColor = (customerType: string) => {
  switch (customerType) {
  case 'residential':
    return 'altitude';
  case 'comercial':
    return 'clean';
  case 'industrial':
    return 'wind';
  case 'agricultural':
    return 'cloud';
  default:
    return 'primary';
  }
};

export const getCustomerTypeCustomColor = (customerType: string, getThemeColor: Function) => {
  switch (customerType) {
  case 'residential':
    return getThemeColor(Color.Primary, ColorCode.C200, '');
  case 'comercial':
    return getThemeColor(Color.Tertiary, ColorCode.C200, '');
  case 'industrial':
    return getThemeColor(Color.Quaternary, ColorCode.C200, '');
  case 'agricultural':
    return getThemeColor(Color.Secondary, ColorCode.C200, '');
  default:
    return '#333';
  }
};

export const getCustomerTypeTextColor = (customerType: string, hasThemePalette: boolean) => {
  switch (customerType) {
  case 'residential':
    return hasThemePalette ? '#fff' : '';
  case 'comercial':
    return hasThemePalette ? '#fff' : '';
  case 'industrial':
    return hasThemePalette ? '#fff' : '';
  case 'agricultural':
    return hasThemePalette ? '#fff' : '';
  default:
    return '#333';
  }
};
