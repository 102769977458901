import { PageLayoutButton } from './Layout/Layout';

import '../app.scss';

export enum GridxProduct {
  Analyze = 'Analyze',
  Balance = 'Balance',
  Calculate = 'Calculate',
  Citadel = 'Citadel',
  CSR = 'CSR',
  Design = 'Design',
  Empower = 'Empower',
  Explore = 'Explore',
  KAM = 'KAM',
  Rates = 'Rates'
}

/**
 * Permissions from the Citadel User Authorization System.
 * These Permissions came from this query:
 * `SELECT p.product_name, pp.permission_name
 * FROM products_permissions pp
 * INNER JOIN products p
 * ON p.id = pp.product_id`
*/
export const PERMISSIONS = {
  Citadel: {
    ChangeTenant: 'ChangeTenant',
    CreateRole: 'CreateRole',
    CreateUser: 'CreateUser',
    DeleteRole: 'DeleteRole',
    DeleteUser: 'DeleteUser',
    GetAllUsers: 'GetAllUsers',
    GetProductsAdmin: 'GetProductsAdmin',
    GetRoles: 'GetRoles',
    GetSelfProducts: 'GetSelfProducts',
    GetUsers: 'GetUsers',
    UpdateRole: 'UpdateRole',
    UpdateUser: 'UpdateUser'
  },
  Analyze: {
    GetAnalysis: 'GetAnalysis'
  }
};

export type GridxApplication = {
  access: boolean;
  icon: string;
  heading: string;
  description: null | string;
  cta: CallToAction | null;
  owner: null | string;
  url?: string;
}

export type CallToAction = {
  text: string;
  link?: string;
}

export const GRIDX_APPLICATIONS: GridxApplication[] = [
  {
    access: true,
    icon: 'analyze',
    heading: GridxProduct.Analyze,
    description: 'Run billing quality analytics for every customer',
    cta: {
      text: 'Explore',
      link: 'https://gridx.com/analyze'
    },
    owner: 'GridX',
    url: 'https://analyze.gridx.com'
  },
  {
    access: true,
    icon: 'balance',
    heading: GridxProduct.Balance,
    description: 'Automate your unbilled revenue recognition process',
    cta: {
      text: 'Explore',
      link: 'https://gridx.com/balance/'
    },
    owner: 'GridX',
    url: 'https://gridx.com/balance'
  },
  {
    access: true,
    icon: 'design',
    heading: GridxProduct.Design,
    description: 'Design pricing strategies that deliver our clean energy future',
    cta: {
      text: 'Explore',
      link: 'https://gridx.com/design'
    },
    owner: 'GridX',
    url: 'https://gridx.com/design'
  },
  {
    access: false,
    icon: 'calculate',
    heading: GridxProduct.Calculate,
    description: 'Enhance your existing CIS to bill complex rates and programs',
    cta: {
      text: 'Explore',
      link: 'https://gridx.com/calculate'
    },
    owner: 'GridX',
    url: 'https://gridx.com/calculate'
  },
  {
    access: false,
    icon: 'csr',
    heading: GridxProduct.CSR,
    description: 'Answer customer questions about rates, programs, and bills',
    cta: {
      text: 'Explore',
      link: 'https://gridx.com/csr'
    },
    owner: 'GridX',
    url: 'https://gridx.com/csr'
  },
  {
    access: false,
    icon: 'empower',
    heading: GridxProduct.Empower,
    description: 'Communicate highly accurate cost data through APIs',
    cta: {
      text: 'Explore',
      link: 'https://gridx.com/empower'
    },
    owner: 'GridX',
    url: 'https://gridx.com/empower'
  },
  {
    access: false,
    icon: 'explore',
    heading: GridxProduct.Explore,
    description: 'Allow customers to visualize and bundle clean energy options',
    cta: {
      text: 'Explore',
      link: 'https://gridx.com/explore'
    },
    owner: 'GridX',
    url: 'https://gridx.com/explore'
  },
  {
    access: true,
    icon: 'kam',
    heading: GridxProduct.KAM,
    description: 'Get your most strategic business customers on the right rate',
    cta: {
      text: 'Explore',
      link: 'https://gridx.com/kam'
    },
    owner: 'GridX',
    url: 'https://gridx.com/kam'
  }
];

export type MetronomeColor = 'white' | 'stone' | 'magma' | 'ocean' | undefined;

export const DEFAULT_METRONOME_LINKS_ARRAY: Array<PageLayoutButton> = [
  {
    color: 'stone',
    displayName: 'Alert Inline',
    href: '/metronome/alert-inline'
  },
  {
    color: 'stone',
    displayName: 'Alert Sticky',
    href: '/metronome/alert-sticky'
  },
  {
    color: 'stone',
    displayName: 'Button',
    href: '/metronome/button'
  },
  {
    color: 'stone',
    displayName: 'Color',
    href: '/metronome/color'
  },
  {
    color: 'stone',
    displayName: 'Divider',
    href: '/metronome/divider'
  },
  {
    color: 'stone',
    displayName: 'Grid',
    href: '/metronome/grid'
  },
  {
    color: 'stone',
    displayName: 'Input',
    href: '/metronome/input'
  },
  {
    color: 'stone',
    displayName: 'Key Value Pair',
    href: '/metronome/key-value-pair'
  },
  {
    color: 'stone',
    displayName: 'Shadow',
    href: '/metronome/shadow'
  },
  {
    color: 'stone',
    displayName: 'Tag',
    href: '/metronome/tag'
  },
  {
    color: 'stone',
    displayName: 'Tooltip',
    href: '/metronome/tooltip'
  },
  {
    color: 'stone',
    displayName: 'Typography',
    href: '/metronome/typography'
  },
  {
    color: 'stone',
    displayName: 'Analyze',
    href: '/analyze'
  }
];

export const DEFAULT_METRONOME_ASIDE_NAVIGATION_LINKS: Array<PageLayoutButton> = [
  {
    active: true,
    color: 'stone',
    displayName: 'Accordion',
    href: '/metronome/accordion?user=designer'
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Alert Inline',
    href: '/metronome/alert-inline?user=designer'
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Alert Sticky',
    href: '/metronome/alert-sticky?user=designer'
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Button',
    href: '/metronome/button?user=designer'
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Date Picker',
    href: '/metronome/datepicker?user=designer'
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Color',
    href: '/metronome/color?user=designer'
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Divider',
    href: '/metronome/divider?user=designer'
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Filters',
    href: '/metronome/filters?user=designer'
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Key Value Pairs',
    href: '/metronome/key-value-pairs?user=designer'
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Grid',
    href: '/metronome/grid?user=designer'
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Input',
    href: '/metronome/input?user=designer'
  },
  // {
  //   active: true,
  //   color: 'stone',
  //   displayName: 'Side Sheet',
  //   href: '/metronome/sidesheet?user=designer'
  // },
  {
    active: true,
    color: 'stone',
    displayName: 'Shadow/Elevation',
    href: '/metronome/shadow?user=designer'
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Tag',
    href: '/metronome/tag?user=designer'
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Spacing',
    href: '/metronome/spacing?user=designer'
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Spacing Usage',
    href: '/metronome/spacing-usage?user=designer'
  },
  {

    active: true,
    color: 'stone',
    displayName: 'Tooltip',
    href: '/metronome/tooltip?user=designer'
  },
  {
    active: true,
    color: 'stone',
    displayName: 'Typography',
    href: '/metronome/typography?user=designer'
  }
];

export const SEQUENTIAL_GRIDXAPPS: Array<string> = ['Design', 'Analyze', 'KAM', 'CSR', 'Calculate', 'Balance', 'Explore', 'Citadel'];

export const TENANT_KEY = 'TENANT';
