import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { RateDetailView } from '@gridx/metronome-components';

// COMPONENTS
import ExploreNav from 'pages/Explore/sections/ExplorePage/components/ExploreNav/ExploreNav';
import MainPage from '../../components/MainPage/MainPage';
import BannerBlock from '../../components/BannerBlock/BannerBlock';
import AdditionalBlock from '../../components/AdditionalBlock/AdditionalBlock';
import Box from 'components/Box/Box';
import { Button, Icon } from 'components';

// ASSETS
import '@gridx/metronome-components/styles/css/bundle.css';
import styles from './MyAccount.module.scss';

const MyAccount = () => {
  const navigate = useNavigate();

  return (
    <div>
      <ExploreNav onCmsChange={() => {}} company="pse" />
      <MainPage title="Rates comparison">
        <Box mb={3}>
          <BannerBlock/>
        </Box>
        <div className={styles.content}>
          {/* <RateDetailView
            contractId="3"
            content={{
              ctaText: 'Learn More',
              descriptionText: 'View your rate and potential savings.'
            }}
          /> */}
          <AdditionalBlock/>
        </div>
        <Box mt={3}>
          <Button
            copy="Compare all rates"
            color="secondary"
            variant="outlined"
            iconRight={<Icon.ArrowRight/>}
            size="md"
            onClick={() => navigate('/rate-analysis/rates-programs')}
          />
        </Box>
      </MainPage>
    </div>
  );
};

export default MyAccount;
