import React, { ReactNode } from 'react';
import classnames from 'classnames';

// COMPONENTS
import * as Icon from 'react-feather';
import Text from '../../components/Text/Text';
import Button from 'components/Button/Button';

// STYLES
import styles from './AlertSticky.module.scss';

export type AlertStickyActions = {
  primary: {
    label: string,
    onClick: () => void
  },
  secondary?: {
    label: string,
    onClick: () => void
  }
}

interface AlertStickyProps {
    className?: string,
    alertMessage?: string,
    color?: 'light' | 'dark',
    icon?: ReactNode,
    actions?: AlertStickyActions,
    closeButton?: boolean,
    closeButtonSize?: number,
    closeButtonClickFn?: () => void;
};

/**
* Represents a AlertSticky.
* @constructor
* @param {string} className
* @param {string} alertMessage
* @param {string} type
* @param {node} icon
* @param {string} action
* @param {boolean} closeButton
* @param {node} buttons
* Usage :
* ````js
* <AlertSticky className="my-alert-sticky" alertMessage="Alert Message" type="dark" icon=true action="none" closeButton=true />
* ````
* @augments {Component<Props, State>}
*/
/** */
const AlertSticky: React.FC<AlertStickyProps> = ({ className, alertMessage, color = 'light', icon, actions, closeButton, closeButtonSize = 16, closeButtonClickFn = () => null }) => {
  // STYLES
  const cssAlertSticky = classnames(styles['alert-sticky'], className, 'pad--l-24 pad--b-16 pad--r-24 pad--t-16', {
    [styles.light]: color === 'light'
  });

  return (
    <div
      className={cssAlertSticky}
      aria-hidden
      role='presentation'
    >
      <div className={`${styles.left} flex flex--align-center`}>
        <span className='icon'>
          {icon || null}
        </span>
        <Text
          className={`${styles['alert-message']} body--lg"`}
          color={color === 'dark' ? 'white' : 'stone'}
        >
          {alertMessage}
        </Text>
      </div>
      <div className={styles.right}>
        {
          actions && (
            <span className={styles.action}>
              {
                actions.secondary && (
                  <Button color={color === 'dark' ? 'tertiary' : 'secondary'} variant="outlined" copy={actions.secondary.label} onClick={actions.secondary.onClick} />
                )
              }
              <Button color={color === 'dark' ? 'tertiary' : 'secondary'} variant="contained" copy={actions.primary.label} onClick={actions.primary.onClick} />
            </span>
          )
        }
        {closeButton && <Button copy='' icon={<Icon.X/>} iconOnly variant="ghost" color="secondary" onClick={closeButtonClickFn} />}
      </div>
    </div>
  );
};

export default AlertSticky;
