import { ICustomerData } from '../../../../../../api/analyze/analyze.typings';
import { Color, ColorCode } from '../../../../../../hooks/Theme/Theme.model';

import colors from '../../../../../../style/colors';

export const calculateRates = (customers: Array<ICustomerData>) => {
  return customers.reduce((accumulator: any, currentValue) => {
    if (currentValue.currentRate in accumulator) {
      accumulator[currentValue.currentRate] += currentValue.annualCost;
    } else {
      accumulator[currentValue.currentRate] = currentValue.annualCost;
    }
    return accumulator;
  }, {});
};

export const transformData = (customers: Array<ICustomerData>, getThemeColor: Function) => {
  return [
    {
      key: '25%+',
      data: [90000],
      color: getThemeColor(Color.Tertiary, ColorCode.C100, colors.stone)
    },
    {
      key: '20-25%',
      data: [120000],
      color: getThemeColor(Color.Tertiary, ColorCode.C200, colors.storm)
    },
    {
      key: '10-15%',
      data: [166000],
      color: getThemeColor(Color.Tertiary, ColorCode.C300, colors.surf)
    },
    {
      key: '5-10%',
      data: [220000],
      color: getThemeColor(Color.Secondary, ColorCode.C100, colors.mist)
    },
    {
      key: '2.5-5%',
      data: [260000],
      color: getThemeColor(Color.Secondary, ColorCode.C200, colors.altitude)
    },
    {
      key: '0-2.5%+',
      data: [380000],
      color: getThemeColor(Color.Secondary, ColorCode.C300, colors.clean)
    }
  ];
};
