import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import colors from '../../style/colors';

// HELPERS
import { useTheme } from '../../hooks/Theme/Theme.context';
import { Color, ColorCode } from 'hooks/Theme/Theme.model';

interface ColumnChartProps {
  backgroundColor?: string | null,
  series: any;
  width?: number | string | null;
  height?: number | string | null;
  yAxis?: Highcharts.YAxisOptions;
  xAxis?: Highcharts.XAxisOptions;
  chart?: Highcharts.ChartOptions;
  legend?: Highcharts.LegendOptions;
  plotBackgroundColor?: string | null,
  plotOptions?: object | null,
  title?: Highcharts.TitleOptions;
  tooltip?: Highcharts.TooltipOptions;
}

const ColumnChart:React.FC<ColumnChartProps> = ({
  series,
  backgroundColor,
  height,
  width,
  yAxis,
  xAxis,
  chart,
  legend,
  plotBackgroundColor,
  plotOptions,
  title,
  tooltip
}) => {
  const { theme, getThemeColor } = useTheme();

  const options = {
    chart: {
      type: 'column',
      backgroundColor: backgroundColor || getThemeColor(Color.LightGray, ColorCode.C100, colors.white),
      plotBackgroundColor: plotBackgroundColor || getThemeColor(Color.LightGray, ColorCode.C100, colors.white),
      marginLeft: 0,
      marginBottom: 0,
      height,
      width,
      ...chart
    },
    accessibility: {
      enabled: false
    },
    title: {
      text: '',
      align: 'center',
      style: {
        color: colors.stone,
        fontSize: '12px',
        fontWeight: 600,
        fontFamily: Object.keys(theme).length > 0 ? theme.typography?.fontFamily : 'Aktifo-B-Medium'
      },
      ...title
    },
    subtitle: {
      text: ''
    },
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false,
      ...legend
    },
    xAxis: {
      visible: false,
      ...xAxis
    },
    yAxis: {
      title: '',
      opposite: true,
      gridLineWidth: 0,
      labels: {
        staggerLines: 1,
        style: {
          color: colors.stone,
          fontSize: '12px',
          fontFamily: 'Aktifo-B-Medium'
        }
      },
      ...yAxis
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      padding: 0,
      borderRadius: 0,
      borderWidth: 0,
      shadow: false,
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        return `
            <div class="donutTooltip">
              <span>${this.y}</span>
              <span>${this.series.name}</span>
            </div>
          `;
      },
      ...tooltip
    },
    plotOptions: {
      column: {
        grouping: true
      },
      ...plotOptions
    },
    series
  };
  return (
    <HighchartsReact highcharts={Highcharts} options={options} />
  );
};

export default ColumnChart;
