import React from 'react';
import { ColumnPinningState } from '@tanstack/react-table';

export interface ColumnType<T> {
  align?: 'left' | 'center' | 'right';
  className?: string;
  title: string;
  dataIndex: keyof T;
  key: string;
  render?: (value: any) => React.ReactNode;
  width?: string | number;
}

export interface TableProps<T> {
  columns: ColumnType<T>[];
  tableLayout?: 'auto' | 'fixed';
  dataSource: T[];
  showHeader? : boolean;
  columnPinning?: ColumnPinningState;
}

export const getColumnStyles = (columns: ColumnType<any>) => {
  let styles = {};

  if (columns.width) {
    styles = {
      ...styles,
      minWidth: columns.width,
      width: columns.width
    };
  }

  return styles;
};
