import * as React from 'react';

import colors from '../../../style/colors';

const Empower = (props:any) => {
  const { color, showBrandName, showGridX } = props;

  let renderColor;
  switch (color) {
  case 'deep':
    renderColor = colors.deep;
    break;
  case 'energy':
    renderColor = colors.energy;
    break;
  case 'white':
    renderColor = colors.white;
    break;
  }

  if (showGridX) {
    return (
      <svg
        width={503}
        height={134}
        viewBox="0 0 503 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_9122_8089)">
          <path
            d="M25.1282 93.0944L16.4282 101.794L31.3364 116.703L40.0364 108.003L25.1282 93.0944Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M31.3535 116.711L16.4453 101.803L1.5371 116.711L16.4453 131.619L31.3535 116.711Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M133.999 119.081L119.09 104.172L104.182 119.081L119.09 133.989L133.999 119.081Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M29.8174 14.9063L14.9092 -0.00195312L0.000971062 14.9063L14.9092 29.8145L29.8174 14.9063Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M133.99 15.7651L119.082 0.856934L104.174 15.7651L119.082 30.6734L133.99 15.7651Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M83.8969 65.2407L65.7515 47.0952L47.6061 65.2406L65.7515 83.3861L83.8969 65.2407Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M69.6044 100.056C68.5671 101.05 67.1859 101.605 65.7492 101.605C64.3125 101.605 62.9313 101.05 61.8939 100.056L30.9315 69.1082C29.9125 68.0838 29.3405 66.6978 29.3405 65.2529C29.3405 63.8081 29.9125 62.422 30.9315 61.3977L61.8939 30.4352C62.4002 29.9289 63.0012 29.5272 63.6627 29.2532C64.3242 28.9791 65.0332 28.8381 65.7492 28.8381C66.4652 28.8381 67.1742 28.9791 67.8357 29.2532C68.4972 29.5272 69.0982 29.9289 69.6044 30.4352L79.5588 40.3896L94.4678 25.4806L84.5134 15.5262C79.5332 10.5566 72.7848 7.76562 65.7492 7.76562C58.7136 7.76562 51.9652 10.5566 46.9849 15.5262L16.0225 46.4887C11.0538 51.4664 8.26318 58.2122 8.26318 65.2454C8.26318 72.2786 11.0538 79.0244 16.0225 84.0021L46.9849 114.965C49.449 117.429 52.3743 119.384 55.5939 120.717C58.8135 122.051 62.2643 122.738 65.7492 122.738C69.2341 122.738 72.6849 122.051 75.9045 120.717C79.1241 119.384 82.0494 117.429 84.5134 114.965L92.2841 107.194L77.3751 92.2848L69.6044 100.056Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M115.475 46.4898L109.452 40.3906L94.5426 55.2996L100.566 61.3234C101.585 62.3478 102.157 63.7338 102.157 65.1787C102.157 66.6235 101.585 68.0096 100.566 69.0339L92.2837 77.3016L107.193 92.2106L115.475 83.9278C120.421 78.9532 123.197 72.2236 123.197 65.2088C123.197 58.194 120.421 51.4643 115.475 46.4898Z"
            fill={renderColor || '#344145'}
          />
        </g>
        <path
          d="M167.688 114.016V61.8164H204.768V70.2404H177.264V83.9924H202.608V91.9124H177.264V105.592H204.768V114.016H167.688Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M255.57 75.6404C263.994 75.6404 268.602 80.8964 268.602 89.3924V114.016H259.53V90.3284C259.53 85.4324 256.938 83.2724 252.978 83.2724C248.01 83.2724 245.346 86.8004 245.346 92.3444V114.016H236.418V90.9764C236.418 85.7204 233.898 83.2724 229.794 83.2724C224.97 83.2724 222.234 86.7284 222.234 91.9124V114.016H213.162V76.3604H221.154L221.802 81.0404C223.962 77.6564 227.778 75.6404 232.386 75.6404C237.57 75.6404 241.674 78.1604 243.33 82.2644C245.418 78.1604 250.026 75.6404 255.57 75.6404Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M301.512 75.6404C312.6 75.6404 320.232 83.4164 320.232 95.0084C320.232 106.888 312.384 114.736 300.792 114.736C295.32 114.736 290.496 112.792 287.616 109.552V128.416H278.544V76.3604H286.608L287.256 81.8324C289.992 78.0164 295.248 75.6404 301.512 75.6404ZM299.424 107.104C306.408 107.104 311.016 102.64 311.016 95.2244C311.016 88.0244 306.552 83.2724 299.424 83.2724C292.512 83.2724 287.616 87.5204 287.616 94.6484V95.5124C287.616 102.496 292.296 107.104 299.424 107.104Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M325.87 95.1524C325.87 83.4884 334.294 75.6404 346.462 75.6404C358.63 75.6404 367.054 83.4884 367.054 95.1524C367.054 106.888 358.63 114.736 346.462 114.736C334.294 114.736 325.87 106.888 325.87 95.1524ZM335.158 95.2244C335.158 102.496 339.694 106.888 346.534 106.888C353.518 106.888 357.838 102.208 357.838 95.1524C357.838 87.8804 353.302 83.4164 346.606 83.4164C339.55 83.4164 335.158 88.0964 335.158 95.2244Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M421.615 76.3604H431.119L416.071 114.016H409.951L400.375 90.6884L390.799 114.016H384.679L369.559 76.3604H379.135L388.063 100.624L397.927 76.3604H402.823L412.759 100.696L421.615 76.3604Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M463.301 102.496H472.301C470.357 110.2 463.157 114.736 453.941 114.736C441.773 114.736 433.853 106.888 433.853 95.1524C433.853 83.4884 441.773 75.6404 453.437 75.6404C464.381 75.6404 471.869 82.6244 471.869 92.9204C471.869 94.2164 471.725 95.0804 471.509 96.3044H442.997V97.0244C442.997 103.72 447.389 107.464 453.869 107.464C458.477 107.464 461.933 105.52 463.301 102.496ZM453.221 82.8404C448.037 82.8404 444.077 85.8644 443.213 90.5444H462.581C462.509 86.0084 458.621 82.8404 453.221 82.8404Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M498.608 75.7844C499.616 75.7844 500.624 75.9284 501.2 76.0724V84.5684C500.696 84.4244 499.76 84.3524 498.968 84.3524C490.976 84.3524 488.816 90.7604 488.816 96.5924V114.016H479.744V76.3604H487.736L488.6 82.4084C490.328 78.0164 494.216 75.7844 498.608 75.7844Z"
          fill={renderColor || '#344145'}
        />
        <g clipPath="url(#clip1_9122_8089)">
          <path
            d="M208.414 9.94043H203.654V29.35H208.414V9.94043Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M208.414 3.42627H203.654V7.55243H208.414V3.42627Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M233.159 1.5166V29.35H228.957L228.578 26.4555C227.219 28.4614 224.424 29.7216 221.1 29.7216C215.131 29.7216 211.278 25.6396 211.278 19.7024C211.278 13.5781 215.432 9.56886 221.478 9.56886C224.347 9.56886 226.878 10.5328 228.391 12.2399V1.5166H233.159ZM228.399 19.9258V19.4789C228.399 15.8412 225.905 13.5027 222.239 13.5027C218.463 13.5027 216.123 15.9504 216.123 19.6244C216.123 23.4466 218.577 25.7851 222.205 25.7851C225.833 25.7851 228.399 23.5999 228.399 19.9258Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M241.637 19.1491L234.465 9.94057H240.376L244.94 15.9505C245.02 16.0544 245.124 16.1387 245.243 16.1967C245.362 16.2547 245.493 16.2849 245.625 16.2849C245.758 16.2849 245.889 16.2547 246.008 16.1967C246.126 16.1387 246.23 16.0544 246.31 15.9505L251.028 9.93018H256.772L249.486 19.1335C249.368 19.2817 249.304 19.4647 249.304 19.6532C249.304 19.8416 249.368 20.0246 249.486 20.1728L256.809 29.3372H250.991L246.26 23.4156C246.18 23.3136 246.077 23.2311 245.959 23.1743C245.841 23.1175 245.712 23.088 245.58 23.088C245.449 23.088 245.32 23.1175 245.202 23.1743C245.084 23.2311 244.981 23.3136 244.901 23.4156L240.249 29.3424H234.465L241.637 20.1702C241.751 20.0235 241.813 19.8441 241.813 19.6597C241.813 19.4752 241.751 19.2958 241.637 19.1491Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M198.883 9.94042L202.059 14.1545H195.696C195.212 14.1545 194.748 14.3434 194.406 14.6796C194.064 15.0158 193.872 15.6594 193.872 16.1349V29.35H189.204V15.8387C189.204 11.5384 191.661 9.93262 194.731 9.93262L198.883 9.94042Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M181.395 9.94026L181.093 12.6503C179.771 10.7951 177.127 9.57129 173.916 9.57129C168.098 9.57129 164.285 13.5779 164.285 19.1851C164.285 24.7508 168.101 28.76 173.916 28.76C176.939 28.76 179.62 27.5726 180.829 25.7174V28.6847C180.829 31.8027 178.488 33.7333 174.974 33.7333C173.022 33.7333 171.488 33.0915 170.441 32.1743C170.275 32.0261 169.939 31.7455 169.939 31.7455L166.895 34.7856C167.158 35.0508 167.441 35.2966 167.741 35.5209C169.449 36.7967 171.859 37.5216 174.9 37.5216C181.395 37.5216 185.589 34.1074 185.589 28.5028V9.94026H181.395ZM180.829 19.3307C180.829 22.7449 178.224 24.9353 174.86 24.9353C171.234 24.9353 169.119 22.5214 169.119 19.1462C169.119 15.6566 171.385 13.3934 174.9 13.3934C178.261 13.3934 180.829 15.4721 180.829 18.8863V19.3307Z"
            fill={renderColor || '#344145'}
          />
        </g>
        <defs>
          <clipPath id="clip0_9122_8089">
            <rect width={134} height={134} fill="white" />
          </clipPath>
          <clipPath id="clip1_9122_8089">
            <rect
              width={99}
              height={39}
              fill="white"
              transform="translate(162 0.0166016)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (showBrandName) {
    return (
      <svg
        width={503}
        height={134}
        viewBox="0 0 503 134"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_9087_4467)">
          <path
            d="M25.1282 93.0944L16.4282 101.794L31.3364 116.703L40.0364 108.003L25.1282 93.0944Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M31.3535 116.711L16.4453 101.802L1.5371 116.711L16.4453 131.619L31.3535 116.711Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M133.999 119.081L119.09 104.172L104.182 119.081L119.09 133.989L133.999 119.081Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M29.8174 14.9063L14.9092 -0.00195312L0.000971062 14.9063L14.9092 29.8145L29.8174 14.9063Z"
            fill={renderColor || '#D4E9EC'}
          />
          <path
            d="M133.99 15.7651L119.082 0.856934L104.174 15.7651L119.082 30.6734L133.99 15.7651Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M83.8969 65.2407L65.7515 47.0952L47.6061 65.2406L65.7515 83.3861L83.8969 65.2407Z"
            fill={renderColor || '#FFA44F'}
          />
          <path
            d="M69.6044 100.056C68.5671 101.05 67.1859 101.605 65.7492 101.605C64.3125 101.605 62.9313 101.05 61.8939 100.056L30.9315 69.1084C29.9125 68.0841 29.3405 66.698 29.3405 65.2532C29.3405 63.8083 29.9125 62.4223 30.9315 61.3979L61.8939 30.4355C62.4002 29.9291 63.0012 29.5275 63.6627 29.2534C64.3242 28.9794 65.0332 28.8383 65.7492 28.8383C66.4652 28.8383 67.1742 28.9794 67.8357 29.2534C68.4972 29.5275 69.0982 29.9291 69.6044 30.4355L79.5588 40.3898L94.4678 25.4809L84.5134 15.5265C79.5332 10.5569 72.7848 7.76587 65.7492 7.76587C58.7136 7.76587 51.9652 10.5569 46.9849 15.5265L16.0225 46.489C11.0538 51.4667 8.26318 58.2125 8.26318 65.2456C8.26318 72.2788 11.0538 79.0246 16.0225 84.0023L46.9849 114.965C49.449 117.429 52.3743 119.384 55.5939 120.718C58.8135 122.051 62.2643 122.738 65.7492 122.738C69.2341 122.738 72.6849 122.051 75.9045 120.718C79.1241 119.384 82.0494 117.429 84.5134 114.965L92.2841 107.194L77.3751 92.2851L69.6044 100.056Z"
            fill={renderColor || '#344145'}
          />
          <path
            d="M115.475 46.4898L109.452 40.3906L94.5426 55.2996L100.566 61.3234C101.585 62.3478 102.157 63.7338 102.157 65.1787C102.157 66.6235 101.585 68.0096 100.566 69.0339L92.2837 77.3016L107.193 92.2106L115.475 83.9278C120.421 78.9532 123.197 72.2236 123.197 65.2088C123.197 58.194 120.421 51.4643 115.475 46.4898Z"
            fill={renderColor || '#344145'}
          />
        </g>
        <path
          d="M167.688 89V36.8H204.768V45.224H177.264V58.976H202.608V66.896H177.264V80.576H204.768V89H167.688Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M255.57 50.624C263.994 50.624 268.602 55.88 268.602 64.376V89H259.53V65.312C259.53 60.416 256.938 58.2561 252.978 58.2561C248.01 58.2561 245.346 61.784 245.346 67.328V89H236.418V65.96C236.418 60.7041 233.898 58.2561 229.794 58.2561C224.97 58.2561 222.234 61.712 222.234 66.896V89H213.162V51.3441H221.154L221.802 56.024C223.962 52.6401 227.778 50.624 232.386 50.624C237.57 50.624 241.674 53.144 243.33 57.248C245.418 53.144 250.026 50.624 255.57 50.624Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M301.512 50.624C312.6 50.624 320.232 58.4 320.232 69.9921C320.232 81.872 312.384 89.7201 300.792 89.7201C295.32 89.7201 290.496 87.7761 287.616 84.536V103.4H278.544V51.3441H286.608L287.256 56.816C289.992 53 295.248 50.624 301.512 50.624ZM299.424 82.0881C306.408 82.0881 311.016 77.6241 311.016 70.208C311.016 63.008 306.552 58.2561 299.424 58.2561C292.512 58.2561 287.616 62.504 287.616 69.632V70.4961C287.616 77.48 292.296 82.0881 299.424 82.0881Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M325.87 70.136C325.87 58.472 334.294 50.624 346.462 50.624C358.63 50.624 367.054 58.472 367.054 70.136C367.054 81.872 358.63 89.7201 346.462 89.7201C334.294 89.7201 325.87 81.872 325.87 70.136ZM335.158 70.208C335.158 77.48 339.694 81.8721 346.534 81.8721C353.518 81.8721 357.838 77.192 357.838 70.136C357.838 62.864 353.302 58.4 346.606 58.4C339.55 58.4 335.158 63.08 335.158 70.208Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M421.615 51.3441H431.119L416.071 89H409.951L400.375 65.6721L390.799 89H384.679L369.559 51.3441H379.135L388.063 75.6081L397.927 51.3441H402.823L412.759 75.68L421.615 51.3441Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M463.301 77.48H472.301C470.357 85.1841 463.157 89.7201 453.941 89.7201C441.773 89.7201 433.853 81.872 433.853 70.136C433.853 58.472 441.773 50.624 453.437 50.624C464.381 50.624 471.869 57.608 471.869 67.904C471.869 69.2001 471.725 70.0641 471.509 71.2881H442.997V72.008C442.997 78.704 447.389 82.4481 453.869 82.4481C458.477 82.4481 461.933 80.5041 463.301 77.48ZM453.221 57.824C448.037 57.824 444.077 60.848 443.213 65.528H462.581C462.509 60.992 458.621 57.824 453.221 57.824Z"
          fill={renderColor || '#344145'}
        />
        <path
          d="M498.608 50.768C499.616 50.768 500.624 50.912 501.2 51.056V59.552C500.696 59.408 499.76 59.336 498.968 59.336C490.976 59.336 488.816 65.744 488.816 71.576V89H479.744V51.3441H487.736L488.6 57.392C490.328 53 494.216 50.768 498.608 50.768Z"
          fill={renderColor || '#344145'}
        />
        <defs>
          <clipPath id="clip0_9087_4467">
            <rect width={134} height={134} fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 64 64"
        {...props}
      >
        <path
          fill={renderColor || '#D4E9EC'}
          d="m12.001 44.463-4.155 4.155 7.12 7.12 4.156-4.155-7.12-7.12Z"
        />
        <path
          fill={renderColor || '#344145'}
          d="m14.975 55.742-7.12-7.12-7.12 7.12 7.12 7.12 7.12-7.12Z"
        />
        <path
          fill={renderColor || '#FFA44F'}
          d="m64 56.874-7.121-7.12-7.12 7.12 7.12 7.12 7.12-7.12Z"
        />
        <path
          fill={renderColor || '#D4E9EC'}
          d="M14.241 7.12 7.121-.002 0 7.119l7.12 7.12 7.12-7.12Z"
        />
        <path
          fill={renderColor || '#FFA44F'}
          d="M63.995 7.53 56.875.41l-7.12 7.12 7.12 7.12 7.12-7.12ZM40.07 31.16l-8.666-8.667-8.667 8.667 8.667 8.666 8.666-8.666Z"
        />
        <path
          fill={renderColor || '#344145'}
          d="M33.244 47.788a2.661 2.661 0 0 1-3.683 0L14.773 33.007a2.61 2.61 0 0 1 0-3.683l14.788-14.788a2.604 2.604 0 0 1 3.683 0l4.754 4.755 7.12-7.121-4.753-4.754a12.688 12.688 0 0 0-17.925 0L7.652 22.204a12.68 12.68 0 0 0 0 17.916L22.44 54.908a12.673 12.673 0 0 0 17.925 0l3.71-3.71-7.12-7.122-3.711 3.712Z"
        />
        <path
          fill={renderColor || '#344145'}
          d="m55.152 22.204-2.877-2.913-7.12 7.12 2.877 2.878a2.61 2.61 0 0 1 0 3.682l-3.956 3.95 7.12 7.12 3.956-3.956a12.68 12.68 0 0 0 0-17.881Z"
        />
      </svg>
    );
  }
};
export default Empower;
