import React, { Fragment } from 'react';

import Header from '../components/Header/Header';
import KeyValuePair from '../components/KeyValuePair/KeyValuePair';
import Link from '../components/Link/Link';
import Text from '../components/Text/Text';
import * as Icon from 'react-feather';

import './key-value-pair-page.scss';

interface Props {
    type?: string | null,
    keyPair?: string,
    valuePair?: string
};

const KeyValuePairPage: React.FC = () => {
  const renderKeyValuePair: React.FC<Props> = ({
    type = null,
    keyPair = 'name',
    valuePair = 'Spencer'
  }) => {
    return (
      <Fragment>
        <KeyValuePair
          type={type}
          keyPair={keyPair}
          valuePair={valuePair}
        />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Header
        className='design-studio pad--b-100 pad--l-128 pad--t-100'
        color='stone'
        text='Key value pairs'
      >
        <div className='mar--t-60'>
          <Text
            className='max-width--900 pad--t-40 title--sm'
            color='white'
            variant='body1'
          >
          Presenting key value pairs. Use a key value pair in the following cases:
            <ul>
              <li>When you need to display data in a consistent way.</li>
              <li>When you need to visually associate a key with a value.</li>
              <li>When you need to decrease the lookup time for a value.</li>
            </ul>
          </Text>
          <Link
            className='underline dis--block mar--t-60 body--xl'
            color='white'
            copy='See Our Guidelines'
            href='/'
          />
        </div>
      </Header>
      <div
        className='mar--l-96 mar--t-80 max-width--lg temp--min-width--md'
      >
        <section className='section mar--b-80 max-width--sm'>
          <span className='heading'><b>Key above value</b></span>
          <span className='description'>Use this type if the key has an equal importance to the value.</span>
          <div className='representation'>
            <div className='section'>
              <div className='light-border light-border--width-sm'></div>
              <div className='dark-border dark-border--width-sm'></div>
            </div>
            <div className='section'>
              <div className='light-border light-border--width-md'></div>
              <div className='dark-border dark-border--width-md'></div>
            </div>
            <div className='section'>
              <div className='light-border light-border--width-xs'></div>
              <div className='dark-border dark-border--width-xs'></div>
            </div>
          </div>
          <div className='flex width--100-p'>
            <section className='mar--r-20 mar--l-20 mar--b-20 width--100-p'>
              <h4>Examples</h4>
              <table className='width--100-p'>
                <tr>
                  <td className='pad--b-20'>{renderKeyValuePair({ type: 'above', keyPair: 'Name', valuePair: 'Spencer Hollywood' })}</td>
                  <td className='pad--b-20'>{renderKeyValuePair({ type: 'above', keyPair: 'City', valuePair: 'Sacramento' })}</td>
                </tr>
                <tr>
                  <td>{renderKeyValuePair({ type: 'above', keyPair: 'Contract ID', valuePair: '165194287792761' })}</td>
                  <td>{renderKeyValuePair({ type: 'above', keyPair: 'Rate code', valuePair: 'A-RATE-1' })}</td>
                </tr>
              </table>
            </section>
            <section className='keyValueWithIcons mar--r-20 mar--l-20 mar--b-20 width--100-p'>
              <h4>Examples</h4>
              <table className='width--100-p'>
                <tr className='flex'>
                  <td><Icon.User className='mar--r-12' size={18}/>{renderKeyValuePair({ type: 'above', keyPair: 'Name', valuePair: 'Spencer Hollywood' })}</td>
                  <td><Icon.BarChart2 className='mar--r-12' size={18}/>{renderKeyValuePair({ type: 'above', keyPair: 'Usage prediction', valuePair: 'You’re on track' })}</td>
                </tr>
                <tr className='flex'>
                  <td><Icon.Gift className='mar--r-12' size={18}/>{renderKeyValuePair({ type: 'above', keyPair: 'Earned points', valuePair: '1239' })}</td>
                  <td><Icon.Phone className='mar--r-12' size={18}/>{renderKeyValuePair({ type: 'above', keyPair: 'Phone', valuePair: '+15559875364' })}</td>
                </tr>
              </table>
            </section>
          </div>
        </section>
        <hr/>
        <section className='section mar--b-80 mar--t-80 max-width--sm'>
          <span className='heading'><b>Key below value</b></span>
          <span className='description'>Use this type if you want to emphasize the value.</span>
          <div className='representation'>
            <div className='section'>
              <div className='dark-border dark-border--width-sm'></div>
              <div className='light-border light-border--width-sm'></div>
            </div>
            <div className='section'>
              <div className='dark-border dark-border--width-md'></div>
              <div className='light-border light-border--width-md'></div>
            </div>
            <div className='section'>
              <div className='dark-border dark-border--width-xs'></div>
              <div className='light-border light-border--width-xs'></div>
            </div>
          </div>
          <section className='keyValueWithIcons mar--r-20 mar--l-20 mar--b-20'>
            <h4>Examples</h4>
            <table className='width--100-p'>
              <tr className='flex'>
                <td><Icon.User className='mar--r-12' size={18}/>{renderKeyValuePair({ type: 'below', keyPair: 'Name', valuePair: 'Spencer Hollywood' })}</td>
                <td><Icon.BarChart2 className='mar--r-12' size={18}/>{renderKeyValuePair({ type: 'below', keyPair: 'Usage prediction', valuePair: 'You’re on track' })}</td>
              </tr>
              <tr className='flex'>
                <td><Icon.Gift className='mar--r-12' size={18}/>{renderKeyValuePair({ type: 'below', keyPair: 'Earned points', valuePair: '1239' })}</td>
                <td><Icon.Phone className='mar--r-12' size={18}/>{renderKeyValuePair({ type: 'below', keyPair: 'Phone', valuePair: '+15559875364' })}</td>
              </tr>
            </table>
          </section>
        </section>
        <hr/>
        <section className='section mar--b-80 mar--t-80 max-width--sm'>
          <span className='heading'><b>Key next to value</b></span>
          <span className='description'>Use this type if you want to create a list with many items.</span>
          <div className='representation'>
            <div className='section flex'>
              <div className='light-border light-border--width-sm'></div>
              <div className='dark-border dark-border--width-sm'></div>
            </div>
            <div className='section flex'>
              <div className='light-border light-border--width-md'></div>
              <div className='dark-border dark-border--width-md'></div>
            </div>
            <div className='section flex'>
              <div className='light-border light-border--width-xs'></div>
              <div className='dark-border dark-border--width-xs'></div>
            </div>
          </div>
          <div className='flex width--100-p'>
            <section className='keyValueTable keyValueTable--one'>
              <table className='width--100-p'>
                <tr className='flex'>
                  <td>Contract ID</td>
                  <td>165194287792761</td>
                </tr>
                <tr className='flex'>
                  <td>Rate code</td>
                  <td>A-RATE-1</td>
                </tr>
                <tr className='flex'>
                  <td>Customer name</td>
                  <td>Spencer Hollywood</td>
                </tr>
                <tr className='flex'>
                  <td>Email</td>
                  <td><u>spencer.hollywood@gridx.com</u></td>
                </tr>
                {/* <tr className='flex'>
                  <td>{renderKeyValuePair({ type: 'nextTo', keyPair: 'Name', valuePair: 'Spencer Hollywood' })}</td>
                  <td>{renderKeyValuePair({ type: 'nextTo', keyPair: 'Usage prediction', valuePair: 'You’re on track' })}</td>
                </tr>
                <tr className='flex'>
                  <td>{renderKeyValuePair({ type: 'nextTo', keyPair: 'Earned points', valuePair: '1239' })}</td>
                  <td>{renderKeyValuePair({ type: 'nextTo', keyPair: 'Phone', valuePair: '+15559875364' })}</td>
                </tr> */}
              </table>
            </section>
            <section className='keyValueTable keyValueTable--two'>
              <table className='width--100-p'>
                <tr className='flex'>
                  <td>Contract ID</td>
                  <td>165194287792761</td>
                </tr>
                <tr className='flex'>
                  <td>Rate code</td>
                  <td>A-RATE-1</td>
                </tr>
                <tr className='flex'>
                  <td>Customer name</td>
                  <td>Spencer Hollywood</td>
                </tr>
                <tr className='flex'>
                  <td>Email</td>
                  <td><u>spencer.hollywood@gridx.com</u></td>
                </tr>
              </table>
            </section>
          </div>
          <div className='flex width--100-p'>
            <section className='keyValueTable keyValueTable--three'>
              <table className='width--100-p'>
                <tr className='flex'>
                  <td>Contract ID</td>
                  <td>165194287792761</td>
                </tr>
                <tr className='flex'>
                  <td>Rate code</td>
                  <td>A-RATE-1</td>
                </tr>
                <tr className='flex'>
                  <td>Customer name</td>
                  <td>Spencer Hollywood</td>
                </tr>
                <tr className='flex'>
                  <td>Email</td>
                  <td><u>spencer.hollywood@gridx.com</u></td>
                </tr>
              </table>
            </section>
            <section className='keyValueTable keyValueTable--four'>
              <table className='width--100-p'>
                <tr className='flex'>
                  <td>Contract ID</td>
                  <td>165194287792761</td>
                </tr>
                <tr className='flex'>
                  <td>Rate code</td>
                  <td>A-RATE-1</td>
                </tr>
                <tr className='flex'>
                  <td>Customer name</td>
                  <td>Spencer Hollywood</td>
                </tr>
                <tr className='flex'>
                  <td>Email</td>
                  <td><u>spencer.hollywood@gridx.com</u></td>
                </tr>
              </table>
            </section>
          </div>
        </section>
      </div>
      <div className='keyValue--dark'>
        <div className='mar--l-96 pad--t-40 max-width--sm'>
          <div className='flex width--100-p'>
            <section className='keyValueTable keyValueTable--one'>
              <table className='width--100-p'>
                <tr className='flex'>
                  <td>Contract ID</td>
                  <td>165194287792761</td>
                </tr>
                <tr className='flex'>
                  <td>Rate code</td>
                  <td>A-RATE-1</td>
                </tr>
                <tr className='flex'>
                  <td>Customer name</td>
                  <td>Spencer Hollywood</td>
                </tr>
                <tr className='flex'>
                  <td>Email</td>
                  <td><u>spencer.hollywood@gridx.com</u></td>
                </tr>
                {/* <tr className='flex'>
                  <td>{renderKeyValuePair({ type: 'nextTo', keyPair: 'Name', valuePair: 'Spencer Hollywood' })}</td>
                  <td>{renderKeyValuePair({ type: 'nextTo', keyPair: 'Usage prediction', valuePair: 'You’re on track' })}</td>
                </tr>
                <tr className='flex'>
                  <td>{renderKeyValuePair({ type: 'nextTo', keyPair: 'Earned points', valuePair: '1239' })}</td>
                  <td>{renderKeyValuePair({ type: 'nextTo', keyPair: 'Phone', valuePair: '+15559875364' })}</td>
                </tr> */}
              </table>
            </section>
            <section className='keyValueTable keyValueTable--two'>
              <table className='width--100-p'>
                <tr className='flex'>
                  <td>Contract ID</td>
                  <td>165194287792761</td>
                </tr>
                <tr className='flex'>
                  <td>Rate code</td>
                  <td>A-RATE-1</td>
                </tr>
                <tr className='flex'>
                  <td>Customer name</td>
                  <td>Spencer Hollywood</td>
                </tr>
                <tr className='flex'>
                  <td>Email</td>
                  <td><u>spencer.hollywood@gridx.com</u></td>
                </tr>
              </table>
            </section>
          </div>
          <div className='flex width--100-p'>
            <section className='keyValueTable keyValueTable--three'>
              <table className='width--100-p'>
                <tr className='flex'>
                  <td>Contract ID</td>
                  <td>165194287792761</td>
                </tr>
                <tr className='flex'>
                  <td>Rate code</td>
                  <td>A-RATE-1</td>
                </tr>
                <tr className='flex'>
                  <td>Customer name</td>
                  <td>Spencer Hollywood</td>
                </tr>
                <tr className='flex'>
                  <td>Email</td>
                  <td><u>spencer.hollywood@gridx.com</u></td>
                </tr>
              </table>
            </section>
            <section className='keyValueTable keyValueTable--four'>
              <table className='width--100-p'>
                <tr className='flex'>
                  <td>Contract ID</td>
                  <td>165194287792761</td>
                </tr>
                <tr className='flex'>
                  <td>Rate code</td>
                  <td>A-RATE-1</td>
                </tr>
                <tr className='flex'>
                  <td>Customer name</td>
                  <td>Spencer Hollywood</td>
                </tr>
                <tr className='flex'>
                  <td>Email</td>
                  <td><u>spencer.hollywood@gridx.com</u></td>
                </tr>
              </table>
            </section>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default KeyValuePairPage;
