import React, { useState, useMemo } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useDispatch } from 'react-redux';

// COMPONENTS
import DataTable from 'components/DataTable/DataTable';
import { Button, Dropdown } from 'components';
import BillingDetails from '../BillingDetails/BillingDetails';

// ASSETS
import styles from './Billing.module.scss';

// HELPERS
import { simpleListWithIcon } from 'components/ActionList/stories/ActionList.stories.helpers';
import { useBilling } from 'api/billing/billing.api';
import { IBilling } from 'api/billing/billing.typings';
import { setBillingDetailsOpen } from 'store/modules/billing/billing.slice';

const Billing = () => {
  const [billDetailsId, setBillDetailsId] = useState(0);
  const { data: billingData, isLoading } = useBilling({});
  const dispatch = useDispatch();

  // RENDERS
  const buttonDetails = (info: CellContext<IBilling, unknown>) => {
    const handleDetails = () => {
      setBillDetailsId(info.row.original.billId);
      dispatch(setBillingDetailsOpen(true));
    };

    return (
      <div className={styles.actionButton}><Button copy='Details' type='secondary' size="xs" onClick={() => handleDetails()} /></div>
    );
  };

  const columns: any[] = useMemo<ColumnDef<IBilling>[]>(
    () => [
      {
        accessorKey: 'billId',
        header: 'Bill ID',
        enableColumnFilter: false,
        enableSorting: false
      },
      {
        accessorKey: 'startDate',
        id: 'startDate',
        header: 'Start',
        enableColumnFilter: false
      },
      {
        accessorKey: 'endDate',
        header: 'End',
        enableColumnFilter: false
      },
      {
        accessorKey: 'status',
        header: 'Status',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        }
      },
      {
        accessorKey: 'transactionDate',
        header: 'Transaction Date',
        enableColumnFilter: false
      },
      {
        accessorKey: 'rateCode',
        header: 'Rate code',
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        }
      },
      {
        accessorKey: 'billUsage',
        header: 'Bill Usage',
        enableSorting: false
      },
      {
        accessorKey: 'billAmount',
        header: 'Bill Amount',
        enableSorting: false
      },
      {
        accessorKey: 'actions',
        header: 'Actions',
        enableColumnFilter: false,
        enableSorting: false,
        maxSize: 50,
        cell: (info) => buttonDetails(info)
      }
    ],
    []
  );

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <h3>Billing</h3>
        <div className={styles.actions}>
          <Dropdown label="Actions" options={simpleListWithIcon} color='depth' />
        </div>
      </div>
      {(!isLoading && billingData) && (
        <>
          <DataTable columns={columns} data={billingData as IBilling[]} columnPinning={{ right: ['actions'] }}/>
          <BillingDetails bill={(billingData as IBilling[]).filter((bill) => bill.billId === billDetailsId)[0]} />
        </>
      )}
    </div>
  );
};

export default Billing;
