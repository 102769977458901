import { ascend, descend, sortWith } from 'ramda';

interface Params<T> {
  attribute: keyof T;
  direction: 'asc' | 'desc';
  data: Array<T>;
}

export const sortBy = <T>({ attribute, direction, data }: Params<T>) => {
  if (!data) return;
  if (!attribute) return data;

  const isAscend = direction === 'asc';
  const sorter = isAscend
    ? ascend((a: T) => a[attribute] as string | number)
    : descend((a: T) => a[attribute] as string | number);
  const sorted = sortWith([sorter], data);

  return sorted;
};
