import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// COMPONENTS
import Logo from 'components/Logo/Logo';
import Box from 'components/Box/Box';
import Avatar from 'components/Avatar/Avatar';
import Popover from 'components/Popover/Popover';
import ActionList from 'components/ActionList/ActionList';

// ASSETS
import styles from './ExploreNav.module.scss';

// HELPERS
import { useTheme } from 'hooks/Theme/Theme.context';
import { gridxTheme, pgeTheme, conedTheme, bcHydroTheme, energyCoTheme, sceTheme, pseTheme, sclTheme, southernTheme } from 'hooks/Theme/Theme.config';
import { clearProjectsFromDatabase } from './ExploreNav.helpers';
import { removeBodyCSSVariables } from 'hooks/Theme/Theme.helpers';

type HeaderMenu = {
  label: string;
  selected: boolean;
  href?: string;
}

interface ExploreNavProps {
  onCmsChange: (url: string) => void;
  company: string;
  menu?: HeaderMenu[];
}

const ExploreNav:React.FC<ExploreNavProps> = ({
  onCmsChange = () => {},
  company,
  menu = [
    {
      label: 'My Account',
      selected: false
    },
    {
      label: 'Billing',
      selected: false
    },
    {
      label: 'Usage',
      selected: false
    },
    {
      label: 'Rates & Programs',
      selected: false
    },
    {
      label: 'Explore',
      selected: true
    }
  ]
}) => {
  const { theme, setTheme } = useTheme();
  const navigate = useNavigate();
  const [activeLogosMenu, setActiveLogosMenu] = useState(false);
  const [activeAvatarMenu, setActiveAvatarMenu] = useState(false);

  let isThemed = false;
  if (localStorage.getItem('explore-theme') === 'all') {
    isThemed = false;
  } else if (company || localStorage.getItem('explore-theme')) {
    isThemed = true;
  }

  useEffect(() => {
    if (company) {
      switch (company) {
      case 'energyx':
        setTheme(gridxTheme);
        break;
      case 'bchydro':
        setTheme(bcHydroTheme);
        break;
      case 'conedison':
        setTheme(conedTheme);
        break;
      case 'energyco':
        setTheme(energyCoTheme);
        break;
      case 'scl':
        setTheme(sclTheme);
        break;
      case 'pge':
        setTheme(pgeTheme);
        break;
      case 'pse':
        setTheme(pseTheme);
        break;
      case 'sce':
        setTheme(sceTheme);
        break;
      case 'southern':
        setTheme(southernTheme);
        break;
      default:
        break;
      }
    }
  }, [company, setTheme]);

  // EVENTS
  const handleMenuClick = (href: string) => {
    window.location.href = href;
  };

  const companiesThemes = [
    {
      content: 'EnergyX',
      onClick: () => {
        removeBodyCSSVariables();
        setTheme(gridxTheme);
        onCmsChange('https://explore-frontend-dev.s3.us-west-2.amazonaws.com/cms/energyx/cms-data.json');
      }
    },
    {
      content: 'EnergyX (Images)',
      onClick: () => {
        removeBodyCSSVariables();
        setTheme(gridxTheme);
        onCmsChange('https://explore-frontend-dev.s3.us-west-2.amazonaws.com/cms/energyx/cmd-data-images.json');
      }
    },
    {
      content: 'BC Hydro',
      onClick: () => {
        removeBodyCSSVariables();
        setTheme(bcHydroTheme);
        onCmsChange('https://explore-frontend-dev.s3.us-west-2.amazonaws.com/cms/energyx/cmd-data-images.json');
      }
    },
    {
      content: 'ConEdison',
      onClick: () => {
        removeBodyCSSVariables();
        setTheme(conedTheme);
        onCmsChange('https://explore-frontend-dev.s3.us-west-2.amazonaws.com/cms/energyx/cmd-data-images.json');
      }
    },
    {
      content: 'EnergyCo',
      onClick: () => {
        removeBodyCSSVariables();
        setTheme(energyCoTheme);
        onCmsChange('https://explore-frontend-dev.s3.us-west-2.amazonaws.com/cms/energyx/cmd-data-images.json');
      }
    },
    {
      content: 'PG&E',
      onClick: () => {
        removeBodyCSSVariables();
        setTheme(pgeTheme);
        onCmsChange('https://explore-frontend-dev.s3.us-west-2.amazonaws.com/cms/pge/cms-pge-data.json');
      }
    },
    {
      content: 'PSE',
      onClick: () => {
        removeBodyCSSVariables();
        setTheme(pseTheme);
        onCmsChange('https://explore-frontend-dev.s3.us-west-2.amazonaws.com/cms/pge/cms-pge-data.json');
      }
    },
    {
      content: 'SCE',
      onClick: () => {
        removeBodyCSSVariables();
        setTheme(sceTheme);
        onCmsChange('https://explore-frontend-dev.s3.us-west-2.amazonaws.com/cms/sce/cms-sce-data.json');
      }
    },
    {
      content: 'SCL',
      onClick: () => {
        removeBodyCSSVariables();
        setTheme(sclTheme);
        onCmsChange('https://explore-frontend-dev.s3.us-west-2.amazonaws.com/cms/sce/cms-sce-data.json');
      }
    }
  ];

  const avatarOptions = [
    {
      content: 'Clear Data',
      onClick: async () => {
        await clearProjectsFromDatabase();
        setActiveAvatarMenu(false);
        navigate('/explore');
        location.reload();
      }
    }
  ];

  // RENDERS
  const activatorLogos = (
    <Box onClick={() => setActiveLogosMenu(!activeLogosMenu)} data-testid="logoBox">
      <Logo
        align='left'
        className='logo'
        size='sm'
        backgroundImage={Object.keys(theme).length > 0 ? theme.brand?.brandLogo : ''}
        width={theme.brand?.slug === 'pse' || theme.brand?.slug === 'southern' ? theme.brand?.logoWidth : 146}
      />
    </Box>
  );

  const activatorAvatar = (
    <div onClick={() => setActiveAvatarMenu(!activeAvatarMenu)} role='presentation'>
      <Avatar alt="C" size='sm' color="ocean" />
    </div>
  );

  return (
    <div className={styles.nav}>
      <Box display="flex" alignItems="center">
        <Box mr={3}>
          <Popover
            isOpen={isThemed ? false : activeLogosMenu}
            renderReference={activatorLogos}
            onOutsideClickClose={() => setActiveLogosMenu(false)}
            p={0}
            placement='bottom-start'
            isPortal
            zIndex={999}
            fullWidth
          >
            <div data-testid="popoverCompanies">
              <ActionList items={companiesThemes} />
            </div>
          </Popover>
        </Box>
        <div className={styles.menu}>
          <ul>
            {
              menu.map((item, index) => (
                <li
                  key={index}
                  className={item.selected ? styles.selected : ''}
                >
                  <div onClick={() => handleMenuClick(item.href || '#')} role="presentation">{item.label}</div>
                </li>
              ))
            }
          </ul>
        </div>
      </Box>
      <div>
        <div>
          <Popover
            isOpen={activeAvatarMenu}
            renderReference={activatorAvatar}
            onOutsideClickClose={() => setActiveAvatarMenu(false)}
            p={0}
            placement='bottom-start'
            zIndex={999}
            isPortal
            fullWidth
          >
            <div data-testid="popoverCompanies">
              <ActionList items={avatarOptions} />
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default ExploreNav;
