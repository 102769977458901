import React, { Fragment } from 'react';

import Tile from '../../components/Tile/Tile';
import Header from '../../components/Header/Header';
import Link from '../../components/Link/Link';
import Icon from '../../components/Icon/Icon';

import './button-page.scss';

const TilePage: React.FC = () => {
  return (
    <Fragment>
      <Header
        className='design-studio pad--b-100 pad--l-128 pad--t-100'
        color='stone'
        text='Tile'
      >
        <Link
          className='underline dis--block mar--t-60 body--xl'
          color='white'
          copy='See Our Guidelines'
          href='/'
        />
      </Header>
      <div
        className='shadow--page mar--l-96 mar--t-80 max-width--lg temp--min-width--md'
      >
        <div
          className='flex pad--b-80'
        >
          <Tile
            owner='Shannon Ward'
            background='gridx--design'
            className='mar--r-24'
            icon='puzzle'
            size='sm'
            subTitle='Component'
            title='A-Rate-1'
            type='tariff'
          />
          <Tile
            owner='Shannon Ward'
            background='gradient'
            className='mar--r-24'
            icon='home'
            size='sm'
            subTitle='Component'
            title='A-Rate-1'
            type='tariff'
          />
          <Tile
            owner='Shannon Ward'
            background='gradient'
            className='mar--r-24'
            icon='power-plant'
            size='sm'
            subTitle='Component'
            title='A-Rate-1'
            type='tariff'
          />
          <Tile
            owner='Shannon Ward'
            background='gradient'
            className='mar--r-24'
            icon='all-four'
            size='sm'
            subTitle='Component'
            title='A-Rate-1'
            type='tariff'
          />
          <Tile
            owner='Shannon Ward'
            background='gradient'
            className='mar--r-24'
            icon='eligibility'
            size='sm'
            subTitle='Component'
            title='A-Rate-1'
            type='tariff'
          />
          <Tile
            owner='Shannon Ward'
            background='gradient'
            className='mar--r-24'
            icon='base-tariff'
            size='sm'
            subTitle='Component'
            title='A-Rate-1'
            type='tariff'
          />
          <Tile
            owner='Shannon Ward'
            background='gradient'
            className='mar--r-24'
            icon='taxes-fees'
            size='sm'
            subTitle='Component'
            title='A-Rate-1'
            type='tariff'
          />
          <Tile
            owner='Shannon Ward'
            background='gradient'
            className='mar--r-24'
            icon='rider-program'
            size='sm'
            subTitle='Component'
            title='A-Rate-1'
            type='tariff'
          />

        </div>
        <div
          className='flex pad--b-80'
        >
          <Tile
            owner='Shannon Ward'
            className='mar--r-24'
            topControl={<Fragment><Icon.MoreVertical/></Fragment>}
            icon='rider-program'
            ribbon
            size='sm'
            subTitle='Tariff Model'
            title='A-Rate-1'
            type='tariff'
          />
          <Tile
            owner='Shannon Ward'
            className='mar--r-24'
            bottomControl={<Icon.Plus/>}
            icon='rider-program'
            ribbon
            size='sm'
            subTitle='Tariff Model'
            title='A-Rate-1'
            type='tariff'
          />
        </div>
      </div>
      <div>
        Tile
      </div>
    </Fragment>
  );
};

export default TilePage;
