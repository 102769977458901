import React from 'react';
import classnames from 'classnames';
import { Column } from '@tanstack/react-table';

// ASSETS
import styles from './TableHead.module.scss';

interface TableHeadProps extends React.ThHTMLAttributes<HTMLTableCellElement> {
  column: Column<any, any>;
}

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  TableHeadProps
>(
  // eslint-disable-next-line react/prop-types
  ({ className, ...props }, ref) => {
    // STYLES
    const cssTableHead = classnames(
      styles['table-head'],
      className,
      {
        [styles.pinned]: props.column.getIsPinned(),
        [styles.pinned__left]: props.column.getIsPinned() === 'left',
        [styles.pinned__right]: props.column.getIsPinned() === 'right'
      }
    );

    const stylesObj: any = {
      maxWidth: props.column.columnDef.maxSize
    };

    if ((props.column.columnDef.meta as any)?.customWidth) {
      stylesObj.width = (props.column.columnDef.meta as any)?.customWidth;
    }

    return (
      (
        <th
          ref={ref}
          className={cssTableHead}
          style={stylesObj}
          {...props}
        />
      )
    );
  });
TableHead.displayName = 'TableHead';

export default TableHead;
