import React from 'react';

import './color.scss';

interface Props {
    className?: string,
    color?: string,
    copy?: string | null,
    textColor?: string | null,
    hex?: string | null,
};

/**
* Represents a Color.
* @constructor
* @param {string} className
* @param {string} color
* @param {string} copy
* @param {string} textColor
* @param {string} hex
* Usage :
* ````js
* <Color className='my-color' color='magma' copy='Magma' textColor='stone' hex='' />
* ````
* @augments {Component<Props, State>}
*/
/** */
const Color: React.FC<Props> = ({ className, color, copy, hex, textColor, ...props }: Props) => {
  return (
    <div className='flex flex--direction-col swatch width--100-p'>
      <div
        className={`color ${className} ${color}`}
        {...props}
      >
        <span className={`text ${textColor} mar--l-12 mar--t-12`}>{copy}</span>
      </div>
      <div className='hex flex flex--align-center jus--center'>{hex}</div>
    </div>
  );
};

export default Color;
