import React, { Fragment, ReactNode, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { ProductPermission } from 'components/Auth/User';
import AsideNavigation from '../AsideNavigation/AsideNavigation';
import Navigation from '../Navigation/Navigation';
import { DEFAULT_METRONOME_LINKS_ARRAY, GridxProduct, MetronomeColor } from '../constants';

export interface PageLayoutButton {
  active?: boolean;
  color: MetronomeColor;
  displayName: string;
  href: string;
  icon?: ReactNode;
  // Supply a few path matches, like "/" or "/home"
  pathMatches?: Array<string>;
  // Permission format: "<ProductName>.<PermissionName>"
  // Example: "Citadel.GetUsers"
  permissionRequired?: string;
  withRouter?: boolean;
}

export interface LayoutProperties {
  assumedTenant: string;
  citadelApiUrl?: string;
  currentTenant?: string;
  isClientUser?: boolean;
  logoutCallback: () => void;
  metronomeDemoMode?: boolean;
  pages?: Array<PageLayoutButton>;
  productName?: GridxProduct;
  productPermissions?: Array<ProductPermission>,
  showNavigation?: boolean;
  setAssumedTenant?: any;
  tenantOptions?: Array<any>;
  showSettings?: boolean;
  settingsUrl?: string,
  user: string
};

export const Layout: React.FC<LayoutProperties> = (props: LayoutProperties) => {
  const [expand, setExpand] = useState(false);
  const {
    assumedTenant,
    currentTenant,
    isClientUser,
    metronomeDemoMode,
    productName,
    setAssumedTenant,
    showNavigation: showNavigationProp,
    tenantOptions,
    showSettings,
    settingsUrl,
    user
  } = props;
  let {
    pages,
    productPermissions
  } = props;

  if (!pages) {
    // @ts-ignore ignoring the array having non-PageLayoutButton, instead regular JS objects.
    pages = DEFAULT_METRONOME_LINKS_ARRAY;
  }

  const pathname = useLocation().pathname.split('/');
  const showNavigation = showNavigationProp || (pathname[1] && pathname[1] !== 'up');

  return (
    <main className={`App ${expand ? 'expanded' : ''}`}>
      <Outlet />
      {showNavigation
        ? (
          <Fragment>
            <AsideNavigation metronomeDemoMode={metronomeDemoMode} pageLinks={pages} setExpand={setExpand} />
            <Navigation
              citadelApiUrl={props.citadelApiUrl}
              className='nav--main shadow--sm'
              currentTenant={currentTenant}
              logoutCallback={props.logoutCallback}
              productName={productName}
              productPermissions={productPermissions}
              isClientUser={isClientUser}
              tenantOptions={tenantOptions}
              assumedTenant={assumedTenant}
              setAssumedTenant={setAssumedTenant}
              showSettings={showSettings}
              settingsUrl={settingsUrl}
              user={user}/>
          </Fragment>
        )
        : null}
    </main>
  );
};

export default Layout;
