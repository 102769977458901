import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';
import { useDispatch } from 'react-redux';

// TYPINGS
import { IAccount } from 'api/accounts/accounts.typings';

// COMPONENTS
import { InfoItem, ListItem, ListToolbar } from 'blocks/components';
import Tag from 'components/Tag/Tag';
import ListShowMore from 'components/ListShowMore/ListShowMore';

// ASSETS
import styles from './ListAccounts.module.scss';

// HELPERS
import { getCustomerTypeColor, getCustomerTypeCustomColor, getCustomerTypeTextColor } from './ListAccounts.helpers';
import { useTheme } from 'hooks/Theme/Theme.context';
import { Dropdown, Icon } from 'components';
import { useNavigate } from 'react-router-dom';
import { setIsUpdateSystemOpen } from 'store/modules/accounts/accounts.slice';
import { truncate } from 'utils';
import { TagColor } from 'components/Tag/Tag.helpers';

interface ListAccountsProps {
  accounts: Array<IAccount>
}

const ListAccounts:React.FC<ListAccountsProps> = ({ accounts }) => {
  const { getThemeColor, hasThemePalette, slug } = useTheme();
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState<{ [key: string]: boolean }>({});
  const [limitShow, setLimitShow] = useState(10);
  const navigate = useNavigate();

  // EVENTS
  const handleShowMore = () => {
    setLimitShow(limitShow + 10);
  };

  const handleAccountClick = (accountId: string | number) => {
    navigate(`/${slug}/accounts/${accountId}`);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const newSelectedItems: { [key: string]: boolean } = {};

    accounts.forEach((account) => {
      newSelectedItems[account.id] = isChecked;
    });

    setSelectedItems(newSelectedItems);
  };

  const handleSelectItem = (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [id]: isChecked
    }));
  };

  // RENDERS
  const filteredAccounts = accounts.slice(0, limitShow);

  const actionList = [
    {
      startIcon: <Icon.Upload size={18}/>,
      content: 'Update',
      onClick: () => {
        dispatch(setIsUpdateSystemOpen(true));
      }
    },
    {
      startIcon: <Icon.Link size={18} />,
      content: 'Map',
      onClick: () => {}
    },
    {
      startIcon: <Icon.Users size={18} />,
      content: 'Assign',
      onClick: () => {}
    }
  ];

  return (
    <>
      <ListToolbar
        selectedCount={Object.values(selectedItems).filter(Boolean).length}
        onSelect={handleSelectAll}
        rightSlot={<Dropdown label="Actions" options={actionList} color='depth' />}
      />
      <AnimatePresence>
        <motion.ul
          className={styles['list-container']}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {
            filteredAccounts.map((account) => {
              return (
                <motion.li
                  key={account.id}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.5 }}
                >
                  <ListItem
                    selectable
                    isSelected={selectedItems[account.id]}
                    onSelect={handleSelectItem(account.id)}
                    onBodyClick={() => handleAccountClick(account.id)}
                    columns={[
                      { body: <InfoItem title="Service Agreement" description={truncate(account.serviceAgreement, 35)} />, col: 3 },
                      { body: <InfoItem title="SA ID" description={account.saId} />, col: 2 },
                      { body: <InfoItem title="Rate" description={account.rate} />, col: 1 },
                      { body: <InfoItem title="Bill Cycle" description={account.billCycle} />, col: 1 },
                      { body: <InfoItem title="Effective Date" description={account.effectiveDate} />, col: 2 },
                      { body: <InfoItem title="End Date" description={account.endDate} />, col: 2 },
                      {
                        body: (
                          <div className={styles['acount-status']}>
                            <Tag
                              copy={account.status}
                              color={getCustomerTypeColor(account.status) as any}
                              backgroundColor={getCustomerTypeCustomColor(account.status, getThemeColor)}
                              textColor={getCustomerTypeTextColor(account.status, hasThemePalette)}
                            />
                          </div>
                        ),
                        col: 1
                      }
                    ]}
                  />
                </motion.li>
              );
            })
          }
        </motion.ul>
      </AnimatePresence>
      <div className={styles['list-footer']}>
        <ListShowMore total={accounts.length} current={limitShow} onShowMore={handleShowMore} />
      </div>
    </>
  );
};

export default ListAccounts;
