import React from 'react';

// COMPONENTS
import { ExpandableSection } from 'components';
import GraphBalance from '../GraphBalance/GraphBalance';
import GraphInvoicing from '../GraphInvoicing/GraphInvoicing';

// ASSETS
import styles from './AccountInsights.module.scss';

const AccountInsights = () => {
  return (
    <div className={styles.main}>
      <ExpandableSection expanded>
        <div className={styles.container}>
          <GraphInvoicing/>
          <GraphBalance/>
        </div>
      </ExpandableSection>
    </div>
  );
};

export default AccountInsights;
