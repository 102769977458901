import React from 'react';
import { useParams } from 'react-router-dom';

// COMPONENTS
import ExploreNav from 'pages/Explore/sections/ExplorePage/components/ExploreNav/ExploreNav';
import MainPage from '../RateAnalysisWidgetDemo/components/MainPage/MainPage';

// ASSETS
import styles from './MyAccount.module.scss';

interface MyAccountProps {
  theme?: string;
  themeImages?: string;
}
const imageUrls = 'https://explore-frontend-dev.s3.us-west-2.amazonaws.com/myaccount-images';
const query = `?version=${Math.random()}`;

const MyAccount:React.FC<MyAccountProps> = ({ theme = '', themeImages = '' }) => {
  const { theme: themeRoute } = useParams();
  const currentTheme = themeRoute || theme;

  return (
    <div>
      <ExploreNav
        onCmsChange={() => {}}
        company={currentTheme}
        menu={[
          {
            label: 'My Account',
            selected: true
          },
          {
            label: 'Billing',
            selected: false
          },
          {
            label: 'Usage',
            selected: false,
            href: `/${currentTheme}/usage`
          },
          {
            label: 'Rates & Programs',
            selected: false,
            href: `/${currentTheme}/rates-comparison`
          },
          {
            label: 'Explore',
            selected: false,
            href: `/${currentTheme}/explore`
          }
        ]}
      />
      <MainPage title="Welcome Cory!">
        <div className={styles.firstLine}>
          <div>
            <img src={`${imageUrls}${themeImages}/billing.svg${query}`} alt="Billing" className={styles.billingImage} />
          </div>
          <div>
            <img src={`${imageUrls}${themeImages}/forecast.svg${query}`} alt="Forecast" className={styles.forecastImage} />
          </div>
          <div className='cursor--pointer'>
            <a href={`/${theme}/usage`} target='_self'>
              <img src={`${imageUrls}${themeImages}/touRate.svg${query}`} alt="TOU Rate" className={styles.billingImage} />
            </a>
          </div>
        </div>
        <div className={styles.secondLine}>
          <div className={styles.calculator}>
            <img src={`${imageUrls}${themeImages}/compare.svg${query}`} alt="Compare" className={styles.billingImage} />
            <div className='cursor--pointer'>
              <a href={`/${theme}/explore`} target='_self'>
                <img src={`${imageUrls}${themeImages}/calculator.svg${query}`} alt="Calculator" className={styles.billingImage} />
              </a>
            </div>
          </div>
          <div className='cursor--pointer'>
            <a href={`/${theme}/rates-comparison`} target='_self'>
              <img src={`${imageUrls}${themeImages}/rateComparison.svg${query}`} alt="Rate Comparison" className={styles.forecastImage} />
            </a>
          </div>
        </div>
      </MainPage>
    </div>
  );
};

export default MyAccount;
