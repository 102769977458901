
import React, { ReactNode } from 'react';
import classnames from 'classnames';
import Select, {
  SelectProps
} from '@mui/base/Select';

// COMPONENTS
import { ListBox, BaseRoot, Option, Popper } from './components';
import InputLabel from '../InputLabel/InputLabel';

// ASSETS
import styles from './CustomSelect.module.scss';

interface Props {
  className?: string,
  component?: ReactNode,
  disabled?: boolean,
  label?: string,
  error?: boolean,
  errorMessage?: string,
  placeholder?: string,
  selects?: Array<any>,
  size?: 'sm' | 'md' | 'lg' | 'xl',
  width?: 'auto',
  maxheightlist?: string | number,
};

/**
* Represents a CustomSelect.
* @constructor
* @param {string} className
* @param {node} component
* @param {boolean} disabled
* @param {string} label
* @param {boolean} error
* @param {string} errorMessage
* @param {function} onChange
* @param {string} placeholder
* @param {object} selects
* @param {string} size
* @param {object} slotProps
* @param {object} slots
* @param {string} width
* @param {value} value
* Usage :
* ````js
* <CustomSelect className='my-select-field' error placeholder='Select Value' size='lg' disabled errorMessage='My input error message' />
* ````
* @augments {Component<Props, State>}
*/
/** */

interface CustomSelectProps<TValue extends {}, Multiple extends boolean> extends SelectProps<TValue, Multiple>, Props {}

const CustomSelect = React.forwardRef(function CustomSelect<
  TValue extends {},
  Multiple extends boolean,
> (props: CustomSelectProps<TValue, Multiple>, ref: React.ForwardedRef<HTMLButtonElement>) {
  const { className, size, width, label, error } = props;

  const slots = {
    root: BaseRoot,
    listbox: ListBox,
    popper: Popper,
    ...props.slots
  };

  const cssSelect = classnames(className, styles['select-field'], {
    [styles[`select-field--${size}`]]: size,
    [styles[`select-field--w-${width}`]]: width,
    [styles['select-field--error']]: error
  });

  return (
    <div className={cssSelect}>
      <Select
        {...props}
        ref={ref}
        slots={slots}
        aria-label={props.placeholder || `Select an option for ${label} `}
      />
    </div>
  );
});

type OptionType = {
  value: string | number,
  label: string,
  isDisabled?: boolean
}

interface SelectComponentProps {
  options: Array<OptionType>,
  defaultValue?: number | string,
  size?: 'sm' | 'md' | 'lg' | 'xl',
  width?: 'auto',
  maxHeightList?: string | number,
  disabled?: boolean,
  label?: string,
  value?: number | string,
  placeholder?: string,
  inputRef?: any,
  name?: string,
  error?: boolean,
  errorMessage?: string,
  onChange?: (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | React.FocusEvent<Element, Element> | null, value: {} | null) => void
}

const SelectComp:React.FC<SelectComponentProps> = ({ options, name, error, errorMessage, maxHeightList = 'none', inputRef, defaultValue, size = 'md', disabled, label, value, placeholder, onChange }) => {
  return (
    <div className={styles.container}>
      {label && <InputLabel>{label}</InputLabel>}
      <CustomSelect
        defaultValue={defaultValue}
        size={size}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        maxheightlist={maxHeightList}
        label={label}
        ref={inputRef}
        name={name}
        error={error}
      >
        {options.map((option: OptionType) => (
          <Option key={option.value} value={option.value}>{option.label}</Option>
        ))}
      </CustomSelect>
      {error && errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  );
};

export default SelectComp;
