import React from 'react';
import { useFormContext } from 'react-hook-form';

// COMPONENTS
import TextInput from 'components/TextInput/TextInput';
import Select from 'components/CustomSelect/CustomSelect';
import Box from 'components/Box/Box';

// ASSETS
import styles from '../../DrawerSingleModifier.module.scss';

const FormModifierSolar = () => {
  const { register, getValues } = useFormContext();

  // RENDERS
  const capacityField = register('capacityField');
  const orientationField = register('orientationField');

  return (
    <div>
      <Box mb={3}>
        <TextInput
          label="System capacity"
          size="md"
          suffix="kW"
          inputRef={register('capacityField') as any}
          defaultValue={getValues().capacityField}
          onChange={(event: any) => capacityField.onChange({ target: { value: event.target.value, name: capacityField.name } })}
        />
        <p className={styles['form-description']}>6kW is the recommended system capacity based on your account information and previous 12 months of usage.</p>
      </Box>
      <Select
        label="Orientation"
        options={[
          { value: 'South', label: 'South' },
          { value: 'West', label: 'West' }
        ]}
        defaultValue={getValues().orientationField}
        inputRef={orientationField.ref}
        onChange={(_, value: any) => orientationField.onChange({ target: { value, name: orientationField.name } })}
      />
    </div>
  );
};

export default FormModifierSolar;
