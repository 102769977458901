import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

// HELPERS
import { generateTouData, getPeriodArray } from './UsageDetailsChart.helpers';

interface UsageDetailsChartProps {
  data: any;
  showPeriodControls?: true;
}

const UsageDetailsChart:React.FC<UsageDetailsChartProps> = ({ data, showPeriodControls }) => {
  const [billSelectForm, setBillSelectForm] = useState({
    selectedBill: showPeriodControls ? data.list[0].id : data.list[1].id,
    billRange: showPeriodControls ? [] : [moment(data.list[1].start), moment(data.list[1].end)]
  });

  const [periodTouData, setPeriodTouData] = useState({
    peak: [] as number[],
    offPeak: [] as number[],
    generationOffPeak: [] as number[],
    generationPeak: [] as number[]
  });

  // Use Effect Hook: Control data generation
  useEffect(() => {
    const dateStart = moment(billSelectForm.billRange[0]);
    const dateEnd = moment(billSelectForm.billRange[1]);
    setPeriodTouData(generateTouData(dateStart, dateEnd));
  }, [billSelectForm.billRange, setBillSelectForm]);

  const options = {
    chart: {
      type: 'column',
      height: 500
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: getPeriodArray(billSelectForm),
      tickInterval: 2
    },
    credits: {
      enabled: false
    },
    legend: {
      align: 'center',
      verticalAlign: 'top',
      layout: 'horizontal'
    },
    yAxis: {
      // min: -20,
      title: {
        text: ''
      }
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat:
    '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        pointWidth: 35,
        borderWidth: 0
      }
    },
    series: [
      {
        name: 'Delivered Peak',
        data: periodTouData.peak,
        color: '#bc1203'
      },
      {
        name: 'Delivered Off-Peak',
        data: periodTouData.offPeak,
        color: '#e07800'
      },
      {
        name: 'Received Peak',
        data: periodTouData.generationPeak,
        color: '#61ab1b'
      },
      {
        name: 'Received Off Peak',
        data: periodTouData.generationOffPeak,
        color: '#547e23'
      }
    ]
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default UsageDetailsChart;
