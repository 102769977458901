import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import { Button, Icon } from 'components';

// ASSETS
import styles from './UsageYearSelection.module.scss';

// ACTIONS
import { setUsageSelectedYear } from 'store/modules/accounts/accounts.slice';

// SELECTORS
import { getUsageSelectedYear } from 'store/modules/accounts/accounts.selector';

interface UsagePeriodSelectionProps {
  data: any
}

const UsageYearSelection:React.FC<UsagePeriodSelectionProps> = ({ data }) => {
  const dispatch = useDispatch();
  const selectedYear = useSelector(getUsageSelectedYear);
  const [yearsBetween, setYearsBetween] = useState<any>([]);

  useEffect(() => {
    const startYear = moment(data.list[data.list.length - 1].start).year();
    const endYear = moment(data.list[1].end).year();
    const yearsBetween = [];
    for (let year = startYear; year <= endYear; year++) {
      yearsBetween.push(year);
    }
    setYearsBetween(yearsBetween);
  }, []);

  // EVENTS
  const onChangePrevYear = () => {
    dispatch(setUsageSelectedYear(selectedYear - 1));
    // onChangePeriod(selectedYear - 1);
  };

  const onChangeNextYear = () => {
    dispatch(setUsageSelectedYear(selectedYear + 1));
    // onChangePeriod(selectedYear + 1);
  };

  return (
    <div>
      {data.list.length > 1 && (
        <div className={styles.container}>
          <Button
            copy={`${Number(selectedYear) - 1}`}
            onClick={onChangePrevYear}
            disabled={selectedYear <= yearsBetween[0] }
            icon={<Icon.ChevronLeft size={18} />}
            iconPosition='left'
            color='depth'
            size="sm"
            fullWidth={false}
          />
          <span className={styles['selected-year']}>{selectedYear}</span>
          <Button
            onClick={onChangeNextYear}
            disabled={selectedYear >= yearsBetween[yearsBetween.length - 1] }
            copy={`${selectedYear + 1}`}
            icon={<Icon.ChevronRight size={18} />}
            iconPosition="right"
            color='depth'
            size="sm"
            fullWidth={false}
          />
        </div>
      )}
    </div>
  );
};

export default UsageYearSelection;
