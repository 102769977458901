import React from 'react';
import Chart from '../components/Chart/Chart';
import ColumnChart from '../components/ColumnChart/ColumnChart';

import billFrequency from '../json/bill_frequency_02.json';
import billImpactByKwh from '../json/bill_impact_by_kwh_05.json';
import averageBillSeg from '../json/avg_bill_by_seg_05.json';
import billImpactBySegment from '../json/bill_impacts_by_segment_04.json';

import './impact--analysis.scss';

const scrubData = (dataSource: any, param: string, round?: boolean) => {
  const dataArray = dataSource.slice(1).map((item: any) => {
    const i = item[param as keyof typeof item];
    if (round) {
      return i * 100;
    }

    return i;
  });

  return dataArray;
};

const ImpactAnalyze: React.FC = () => {
  const optionsOne = {
    chart: {
      style: {
        fontFamily: 'Aktifo-B-Medium, sans-serif'
      }
    },
    credits: {
      enabled: false
    },
    title: {
      text: 'Customer Distribution by kWh'
    },
    xAxis: {
      labels: {
        align: 'right'
      },
      categories: ['100', '200', '300', '400', '500', '600', '700', '800', '900', '1000', '1100', '1200', '1500', '2000', '3000', '5000', ''],
      title: {
        text: 'Number of Customers',
        x: -15
      }
    },
    yAxis: {
      title: {
        text: 'Number of Customers',
        x: 15
      }
    },
    series: [
      {
        data: scrubData(billFrequency, 'Frequency', false),
        color: '#4A636D',
        name: 'Average Monthly kWh',
        type: 'column'
      }
    ]
  };

  const billImpactOptions = {
    chart: {
      style: {
        fontFamily: 'Aktifo-B-Medium, sans-serif'
      }
    },
    credits: {
      enabled: false
    },
    title: {
      text: 'Customer Impact by kWh Bin'
    },
    subtitle: {
      text: 'Rate FD'
    },
    xAxis: {
      title: {
        text: 'Average Monthly kWh'
      },
      categories: ['0', '100', '200', '300', '400', '500', '600', '700', '800', '900', '1000', '1100', '1200', '1500', '2000', '3000', '5000', '']
    },
    yAxis: {
      title: {
        text: 'Average Bill Impact %',
        x: -15
      }
    },
    series: [
      {
        data: scrubData(billImpactByKwh, 'RTA', true),
        color: '#4A636D',
        name: 'RTA',
        type: 'line'
      },
      {
        data: scrubData(billImpactByKwh, 'RTA-EO', true),
        color: '#F18A08',
        name: 'RTAEO',
        type: 'line'
      },
      {
        data: scrubData(billImpactByKwh, 'XFDE', true),
        color: '#696969',
        name: 'XFDE',
        type: 'line'
      },
      {
        data: scrubData(billImpactByKwh, 'XFDT', true),
        color: '#F1BD05',
        name: 'XFDT',
        type: 'line'
      },
      {
        data: scrubData(billImpactByKwh, 'FDD', true),
        color: '#007156',
        name: 'FDD',
        type: 'line'
      }
    ]
  };

  const sortStrata = (myArray: any, strata: string) => {
    const tempArray = myArray.filter((item: { strata: string }) => {
      return item.strata === strata;
    });

    return tempArray;
  };

  const strataOne = sortStrata(averageBillSeg, 'strata 1');
  const strataTwo = sortStrata(averageBillSeg, 'strata 2');
  const strataThree = sortStrata(averageBillSeg, 'strata 3');
  const strataFour = sortStrata(averageBillSeg, 'strata 4');

  const rateHandler = (rate: string) => {
    const averageBillSegment = {
      chart: {
        style: {
          fontFamily: 'Aktifo-B-Medium, sans-serif'
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Average Monthly Bill'
      },
      subtitle: {
        text: `Rate ${rate}`
      },
      xAxis: {
        labels: {
          enabled: false,
          align: 'center'
        }
      },
      yAxis: {
        title: {
          text: 'Average Monthly Bill',
          x: -15
        },
        labels: {
          align: 'left',
          format: '$ {text}',
          rotate: -45,
          fontSize: '8px',
          x: -20
        }
      },
      series: [
        {
          data: scrubData(strataOne, rate, false),
          color: '#4A636D',
          name: 'Strata 1',
          type: 'column',
          pointInterval: 1
        },
        {
          data: scrubData(strataTwo, rate, false),
          color: '#3979E2',
          name: 'Strata 2',
          type: 'column',
          pointInterval: 1
        },
        {
          data: scrubData(strataThree, rate, false),
          color: '#F18A08',
          name: 'Strata 3',
          type: 'column',
          pointInterval: 1
        },
        {
          data: scrubData(strataFour, rate, false),
          color: '#F1BD05',
          name: 'Strata 4',
          type: 'column',
          pointInterval: 1
        }
      ]
    };

    return (
      averageBillSegment
    );
  };

  const cloneArray = billImpactBySegment.slice();
  const strataOneBillImpactBySeg = cloneArray.splice(0, 10);
  const strataTwoBillImpactBySeg = cloneArray.splice(0, 10);
  const strataThreeBillImpactBySeg = cloneArray.splice(0, 10);
  const strataFourBillImpactBySeg = cloneArray.splice(0, 40);

  const strataRateHandler = (rate?: string) => {
    const optionStackColumn = {
      chart: {
        type: 'column'
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Bill Impact Distribution'
      },
      subtitle: {
        text: `Rate ${rate}`
      },
      xAxis: {
        categories: ['-30%', '-20%', '-10%', '0%', '10%', '20%', '30%', '40%', '50%']
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Number of Accounts'
        }
      },
      legend: {
        reversed: true
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false
          }
        }
      },
      series: [
        {
          name: 'Strata 4',
          color: '#F1BD05',
          data: scrubData(strataFourBillImpactBySeg, rate || '', false)
        },
        {
          name: 'Strata 3',
          color: '#F18A08',
          data: scrubData(strataThreeBillImpactBySeg, rate || '', false)
        },
        {
          name: 'Strata 2',
          color: '#3979E2',
          data: scrubData(strataTwoBillImpactBySeg, rate || '', false)
        },
        {
          name: 'Strata 1',
          color: '#4A636D',
          data: scrubData(strataOneBillImpactBySeg, rate || '', false)
        }
      ]
    };

    return optionStackColumn;
  };

  return (
    <div className='chart--wrapper'>
      <div
        className='chart--container mar--t-156'
      >
        <div className='bor--b-1 pad--b-100'>
          <ColumnChart yAxis={optionsOne.yAxis} series={optionsOne.series} width='700' />
        </div>
        <div className='bor--b-1 pad--b-100'>
          <Chart className='dis--inline-block' options={optionsOne} />
          <Chart className='dis--inline-block mar--l-48' options={billImpactOptions} />
        </div>
        <div
          className='bor--b-1 pad--t-100 pad--b-100'
        >
          <Chart className='dis--inline-block' options={rateHandler('RTA')} />
          <Chart className='dis--inline-block mar--l-48' options={strataRateHandler('RTA')} />
        </div>
        <div
          className='bor--b-1 pad--t-100 pad--b-100'
        >
          <Chart className='dis--inline-block' options={rateHandler('FDD')} />
          <Chart className='dis--inline-block mar--l-48' options={strataRateHandler('FDD')} />

        </div>
        <div
          className='bor--b-1 pad--t-100 pad--b-100'
        >
          <Chart className='dis--inline-block' options={rateHandler('RTA-EO')} />
          <Chart className='dis--inline-block mar--l-48' options={strataRateHandler('RTA-EO')} />
        </div>
        <div
          className='bor--b-1 pad--t-100 pad--b-100'
        >
          <Chart className='dis--inline-block' options={rateHandler('XFDT')} />
          <Chart className='dis--inline-block mar--l-48' options={strataRateHandler('XFDT')} />
        </div>
        <div
          className='bor--b-1 pad--t-100 pad--b-100'
        >
          <Chart className='dis--inline-block' options={rateHandler('XFDE')} />
          <Chart className='dis--inline-block mar--l-48' options={strataRateHandler('XFDE')} />
        </div>
      </div>
    </div>
  );
};

export default ImpactAnalyze;
