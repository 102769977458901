import React, { useState } from 'react';

// COMPONENTS
import { Avatar, Button, Icon, Popover } from 'components';

// ASSETS
import styles from './ShareButton.module.scss';
import TextInput from 'components/TextInput/TextInput';

const ShareButton = () => {
  const [active, setActive] = useState(false);

  // EVENTS
  const togglePopover = () => {
    setActive(!active);
  };

  // RENDERS
  const activator = (
    <Button
      size="md"
      copy='Share'
      type="secondary"
      color="depth"
      icon={<Icon.Share2 />}
      iconPosition="left"
      onClick={togglePopover}
    />
  );

  return (
    <div>
      <Popover
        isOpen={active}
        renderReference = { activator }
        onOutsideClickClose={() => setActive(false)}
        p={0}
        placement='bottom-end'
        zIndex={999}
      >
        <div className={styles.container}>
          <div className={styles.header}>
            <span>Share</span>
            <button type="button" onClick={() => setActive(false)}>
              <Icon.X/>
            </button>
          </div>
          <div>
            <TextInput
              endAdornment={<Icon.Search/>}
              errorMessage="Validation message text"
              id="my-text-input"
              infoMessage=""
              label=""
              placeholder="Search users"
              size="md"
              fullWidth
            />
          </div>
          <div className={styles.users}>
            <div className={styles['user-item']}>
              <div className={styles['user-item__info']}>
                <Avatar alt="Parker Anderson" src='/images/login/parker-anderson.png' size="xs" />
                <span>Parker Anderson</span>
              </div>
              <div>
                <Icon.X size={20}/>
              </div>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default ShareButton;
