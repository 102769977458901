import React from 'react';
import { useDispatch } from 'react-redux';

// COMPONENTS
import { FilterControl } from '../../../../../../blocks/components';
import Checkbox from '../../../../../../components/CustomCheckbox/CustomCheckbox';
import { StatusTag } from '../../../../components';

// ASSETS
import styles from 'blocks/components/FilterPanel/FilterPanel.module.scss';

// ACTIONS
import { setFilter, removeFilter } from '../../../../../../store/modules/analyze/analyze.slice';

const FilterStatus = () => {
  const dispatch = useDispatch();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = event.target;
    if (checked) {
      dispatch(setFilter({ key: 'status', value: id }));
    } else {
      dispatch(removeFilter({ key: 'status', value: id }));
    }
  };

  return (
    <FilterControl title="Account type" expanded>
      <div className={styles['control-item']}>
        <div><Checkbox id="Production" onChange={handleCheckboxChange} /></div>
        <div><StatusTag status="Production" /></div>
      </div>
      <div className={styles['control-item']}>
        <div><Checkbox id="Draft" onChange={handleCheckboxChange} /></div>
        <div><StatusTag status="Draft" /></div>
      </div>
      <div className={styles['control-item']}>
        <div><Checkbox id="Discontinued" onChange={handleCheckboxChange} /></div>
        <div><StatusTag status="Discontinued" /></div>
      </div>
    </FilterControl>
  );
};

export default FilterStatus;
