import { createSlice } from '@reduxjs/toolkit';

// TYPINGS
import { IAccount } from 'api/accounts/accounts.typings';
import { FilterItem, SortItem } from 'store/helpers/store.typings';
import { IBilling } from 'api/billing/billing.typings';

// HELPERS
import setFilter from 'store/helpers/setFilter';

const initialAccounts = {
  layout: {
    isBillingDetailsOpen: false,
    isApprovalBillingOpen: false,
    isFilterOpen: false
  },
  billingFilters: [{
    key: 'status',
    value: ['Pre-Billing']
  } as FilterItem<IBilling>],
  billingSortBy: {} as SortItem<IAccount>,
  billingSearchString: '',
  singleBilling: {} as IBilling,
  billingDetailsId: null
};

const accountsSlice = createSlice({
  name: 'BILLING',
  initialState: initialAccounts,
  reducers: {
    setBillingDetailsOpen: (state, { payload }) => {
      state.layout.isBillingDetailsOpen = payload;
    },
    setApproveBillingOpen: (state, { payload }) => {
      state.layout.isApprovalBillingOpen = payload;
    },
    setSingleBilling: (state, { payload }) => {
      state.singleBilling = payload;
    },
    setBillingFilterOpen: (state, { payload }) => {
      state.layout.isFilterOpen = payload;
    },
    toogleFilter: (state) => {
      state.layout.isFilterOpen = !state.layout.isFilterOpen;
    },
    setBillingSearchString: (state, { payload }) => {
      state.billingSearchString = payload;
    },
    setSortedBilling: (state, { payload }) => {
      state.billingSortBy.key = payload.sortKey;
      if (payload.sortKey === state.billingSortBy.key) {
        state.billingSortBy.ascending = !state.billingSortBy.ascending;
      }
    },
    setBillingFilter: (state, { payload }) => {
      setFilter<IBilling>(state.billingFilters, payload);
    },
    removeBillingFilter: (state, { payload }) => {
      const selectedFilter = state.billingFilters.find(filter => filter.key === payload.key);
      if (selectedFilter) {
        if (selectedFilter.value.includes(payload.value)) {
          selectedFilter.value = selectedFilter.value.filter((value: any) => value !== payload.value);
        }
      }
    },
    clearBillingFilter: (state) => {
      state.billingFilters = [];
    },
    setFirstApproveBilling: (state) => {
      const selectedBilling = state.singleBilling;
      if (selectedBilling) {
        selectedBilling.status = 'Pending approval';
      }
    },
    setApproveBilling: (state) => {
      const selectedBilling = state.singleBilling;
      if (selectedBilling) {
        selectedBilling.status = 'Approved';
      }
    }
  }
});

export const {
  setBillingDetailsOpen,
  setBillingFilter,
  setSingleBilling,
  setApproveBilling,
  setFirstApproveBilling,
  setSortedBilling,
  setBillingSearchString,
  setApproveBillingOpen,
  removeBillingFilter,
  clearBillingFilter,
  toogleFilter
} = accountsSlice.actions;

export default accountsSlice.reducer;
