import React from 'react';

// COMPONENTS

// ASSETS
import styles from './ProjectDetails.module.scss';

// TYPINGS
import { Project } from 'api/systems/systems.typings';
import { InfoItem } from 'blocks/components';

interface ProjectDetailsProps {
  project: Project;
}

const ProjectDetails:React.FC<ProjectDetailsProps> = ({ project }) => {
  const validateRequired = (value: string) => {
    if (!value) {
      return 'This field is required.';
    }

    return '';
  };

  return (
    <div className={styles['project-details']}>
      <div>
        <InfoItem title="Host Customer Name" description={project.hostCustomerName} error={validateRequired(project.hostCustomerName)} />
        <InfoItem title="Facility Street Address" description={project.facilityStreetAddress} />
        <InfoItem title="City" description={project.city} />
      </div>
      <div>
        <InfoItem title="State" description={project.state} />
        <InfoItem title="Zip" description={project.zip} />
        <InfoItem title="Billing Account Number" description={project.billingAccountNumber} />
      </div>
      <div>
        <InfoItem title="Smart Application ID" description={project.smartApplicationId} />
        <InfoItem title="Interconnection Application ID Number" description={project.interconnectionApplicationIdNumber} />
      </div>
    </div>
  );
};

export default ProjectDetails;
