import React from 'react';
import { useSelector } from 'react-redux';

// COMPONENTS
import { BillingFilterPanel, BillingTabsFilter, FilterSearchBilling, ListBilling } from './components';
import { Box } from 'components';
import { DataUpdateDrawer, UpdateSystemDrawer } from 'blocks/components';

// ASSETS
import styles from './BillingList.module.scss';

// SELECTORS
import { getBillingFilterOpen, getBillingSearchString, getBillingSortBy, getBillingFilters } from 'store/modules/billing/billing.selector';

// HELPERS
import { useBilling } from 'api/billing/billing.api';
import { IBilling } from 'api/billing/billing.typings';
import { useListWidth } from 'hooks/useListWidth';

const BillingList = () => {
  const searchString = useSelector(getBillingSearchString);
  const filters = useSelector(getBillingFilters);
  const isFilterOpen = useSelector(getBillingFilterOpen);
  const { key: sortKey, ascending } = useSelector(getBillingSortBy);

  const { data: billingData, isLoading } = useBilling({ filters, searchString, sortKey, orderAscending: ascending });

  const listWidth = useListWidth(isFilterOpen);

  return (
    <div className={`${styles['billing-page']} admin--page pad--t-128 pad--l-128 pad--r-36`} role="main">
      <div className={styles.main}>
        <div className={styles.header}>
          <h3>Billing</h3>
        </div>
        <Box mb={2}>
          <BillingTabsFilter/>
        </Box>
        <FilterSearchBilling/>
        {(!isLoading && billingData) && (
          <div id="mainListArea" className={styles['main-list-area']}>
            <BillingFilterPanel/>
            <Box width={listWidth}>
              <ListBilling billings={billingData as IBilling[]} />
            </Box>
          </div>
        )}
        <UpdateSystemDrawer/>
        <DataUpdateDrawer />
      </div>
    </div>
  );
};

export default BillingList;
