import React from 'react';

import './key-value-pair.scss';

interface Props {
    className?: string,
    type?: string | null,
    keyPair?: string,
    valuePair?: string,
};

/**
* Represents a Key Value Pair.
* @constructor
* @param {string} className
* @param {string} type
* @param {string} keyPair
* @param {string} valuePair
* Usage :
* ````js
* <KeyValuePair className="my-keyValuePair" type="above" keyPair="name" valuePair="Spencer"/>
* ````
* @augments {Component<Props, State>}
*/
/** */
const KeyValuePair: React.FC<Props> = ({
  className,
  type,
  keyPair,
  valuePair
}: Props) => {
  return (
    <div
      className={`${className} keyValue keyValue--${type}`}
      aria-hidden
      role='presentation'
    >
      <span className='keyPair'>{keyPair}</span>
      <span className='valuePair'>{valuePair}</span>
    </div>
  );
};

export default KeyValuePair;
