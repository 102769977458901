import React from 'react';
import { Icon } from 'components';
import colors from 'style/colors';

// ASSETS
import styles from './AllocateesTable.module.scss';

export const buildColumns = (isDataUpload: boolean) => {
  const columns: any = [
    {
      title: 'Corresponding Customer Disclosure Number',
      dataIndex: 'disclosureNumber',
      key: 'disclosureNumber',
      width: 150
    },
    {
      title: 'Customer First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 100
    },
    {
      title: 'Customer Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      width: 100
    },
    {
      title: 'Customer Service Address',
      dataIndex: 'serviceAddress',
      key: 'serviceAddress',
      width: 150,
      render: (text: boolean) => text ? <span>text</span> : <div><p>-</p><p className={styles['info-error']}>This field is required</p></div>
    },
    {
      title: 'Customer Service City',
      dataIndex: 'serviceCity',
      key: 'serviceCity',
      width: 150
    },
    {
      title: 'Customer Service Zip Code',
      dataIndex: 'serviceZipCode',
      key: 'serviceZipCode',
      width: 150
    },
    {
      title: 'Customer Account Number',
      dataIndex: 'accountNumber',
      key: 'accountNumber',
      width: 150
    },
    {
      title: 'Customer Rate',
      dataIndex: 'rate',
      key: 'rate',
      width: 150
    }
  ];

  if (isDataUpload) {
    columns.unshift({
      title: '',
      dataIndex: 'validated',
      key: 'validated',
      render: (validated: boolean) => validated ? <Icon.Check size={18} color={colors.stem} /> : <Icon.AlertCircle size={18} color={colors.mauvelous} />
    });
  }

  return columns;
};

export const buidlData = (allocatees: any) => {
  return allocatees.map((allocatee: any) => {
    return {
      key: allocatee.id,
      validated: !!allocatee.serviceAddress,
      disclosureNumber: allocatee.customerDisclosureNumber,
      firstName: allocatee.firstName,
      lastName: allocatee.lastName,
      serviceAddress: allocatee.serviceAddress,
      serviceCity: allocatee.serviceCity,
      serviceZipCode: allocatee.serviceZipCode,
      accountNumber: allocatee.accountNumber,
      rate: allocatee.rate
    };
  });
};
