import React, { Fragment } from 'react';

import Header from '../../components/Header/Header';
import Tooltip from '../../components/Tooltip/Tooltip';
import Link from '../../components/Link/Link';
import Text from '../../components/Text/Text';

import './button-page.scss';

interface Props {
    tip?: string
    type?: string
};

const TooltipPage: React.FC = () => {
  const renderTooltip: React.FC<Props> = ({
    tip = 'none',
    type = 'light'
  }) => {
    return (
      <Fragment>
        <Tooltip
          copy='My Tooltip'
          tip={tip}
          type={type}
          isOpen={true}
        >
          <p>Base Element</p>
        </Tooltip>
      </Fragment>
    );
  };
  return (
    <Fragment>
      <Header
        className='design-studio pad--b-100 pad--l-128 pad--t-100'
        color='stone'
        text='Tooltip'
      >
        <Text
          className='max-width--900 pad--t-40 title--sm'
          color='white'
          variant='body1'
        >
            A brief, informative message that appears when a user interacts with an element
        </Text>
        <Link
          className='underline dis--block mar--t-60 body--xl'
          color='white'
          copy='See Our Guidelines'
          href='/'
        />
      </Header>
      <div
        className='mar--l-96 mar--l-20 mar--r-10 mar--t-80 max-width--lg temp--min-width--md'
      >
        <div
          className='pad--b-80'
        >
          <section className='mar--b-80 max-width--md'>
            <div className='flex mar--t-20 jus--space-between'>
              {renderTooltip({ tip: 'none', type: 'light' })}
              {renderTooltip({ tip: 'top-left', type: 'light' })}
              {renderTooltip({ tip: 'top-center', type: 'light' })}
              {renderTooltip({ tip: 'top-right', type: 'light' })}
              {renderTooltip({ tip: 'left', type: 'light' })}
              {renderTooltip({ tip: 'right', type: 'light' })}
              {renderTooltip({ tip: 'bottom-left', type: 'light' })}
              {renderTooltip({ tip: 'bottom-center', type: 'light' })}
              {renderTooltip({ tip: 'bottom-right', type: 'light' })}
            </div>
          </section>
          <section className='mar--b-80 max-width--md'>
            <div className='flex mar--t-20 jus--space-between'>
              {renderTooltip({ tip: 'none', type: 'dark' })}
              {renderTooltip({ tip: 'top-left', type: 'dark' })}
              {renderTooltip({ tip: 'top-center', type: 'dark' })}
              {renderTooltip({ tip: 'top-right', type: 'dark' })}
              {renderTooltip({ tip: 'left', type: 'dark' })}
              {renderTooltip({ tip: 'right', type: 'dark' })}
              {renderTooltip({ tip: 'bottom-left', type: 'dark' })}
              {renderTooltip({ tip: 'bottom-center', type: 'dark' })}
              {renderTooltip({ tip: 'bottom-right', type: 'dark' })}
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  );
};

export default TooltipPage;
