import React, { useState } from 'react';

// COMPONENTS
import TextInput from 'components/TextInput/TextInput';
import { Icon, Table } from 'components';
import AlertInline from 'components/AlertInline/AlertInline';
import ListShowMore from 'components/ListShowMore/ListShowMore';

// ASSETS
import styles from './AllocateesTable.module.scss';

// HELPERS
import { buildColumns, buidlData } from './AllocateesTable.helpers';
import { Allocatee } from 'api/systems/systems.typings';

interface AllocateesTableProps {
  allocatees: Allocatee[];
  isDataUpload?: boolean;
  hasWarning?: boolean;
}

const AllocateesTable:React.FC<AllocateesTableProps> = ({ allocatees, isDataUpload = false, hasWarning = false }) => {
  const [limitShow, setLimitShow] = useState(10);

  // EVENTS
  const handleShowMore = () => {
    setLimitShow(limitShow + 10);
  };

  // RENDERS
  const filteredAllocatees = allocatees.slice(0, limitShow);

  return (
    <div className={styles['project-alocatees']}>
      <p className={styles['alocatees-title']}>Allocatees</p>
      <div className={styles['search-container']}>
        <TextInput
          endAdornment={<Icon.Search/>}
          errorMessage="Validation message text"
          id="my-text-input"
          infoMessage=""
          label=""
          placeholder="Search allocatees"
          size="md"
        />
      </div>
      {
        hasWarning && (
          <div className={styles.alert}>
            <AlertInline
              alertMessage='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
              type="warning"
            />
          </div>
        )
      }
      <Table columns={buildColumns(isDataUpload)} dataSource={buidlData(filteredAllocatees)} />
      <div className={styles['list-footer']}>
        <ListShowMore total={allocatees.length} current={limitShow} onShowMore={handleShowMore} />
      </div>
    </div>
  );
};

export default AllocateesTable;
