import React from 'react';
import Grid from '@mui/system/Unstable_Grid';
import classnames from 'classnames';

// COMPONENTS
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox';

// ASSETS
import styles from './ListItem.module.scss';

type ListColumn = {
  body: React.ReactNode;
  col: number;
}

interface ListItemProps {
  selectable?: boolean;
  isSelected?: boolean;
  onSelect?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  columns?: Array<ListColumn>;
  onBodyClick?: () => void;
}

const ListItem:React.FC<ListItemProps> = ({ selectable = false, children, columns, onBodyClick, onSelect, isSelected = false }) => {
  const renderColumns = () => {
    return columns?.map((column, index) => {
      const key = `${column.body?.toString()}-${index}`;

      return (
        <Grid xs={column.col} key={key}>
          {column.body}
        </Grid>
      );
    });
  };

  // EVENTS
  const handleListBodyClick = () => {
    if (onBodyClick) {
      onBodyClick();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleListBodyClick();
    }
  };

  // STYLES
  const cssListItem = classnames(styles['list-item'], {
    [styles['list-item--hoverable']]: onBodyClick,
    [styles['list-item--selected']]: isSelected
  });

  return (
    <div className={cssListItem}>
      {selectable &&
        (<div className={styles['list-selection']}>
          <Checkbox color="magma" id="my--checkbox" onChange={onSelect} checked={isSelected} />
        </div>
        )
      }
      <div
        className={styles['list-body']}
        onClick={handleListBodyClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        {children}
        {
          columns && (
            <Grid container spacing={2}>
              {renderColumns()}
            </Grid>
          )
        }
      </div>
    </div>
  );
};

export default ListItem;
