import React, { ReactNode } from 'react';

import * as Icon from 'react-feather';

import './link.scss';

type Props = {
    className?: string,
    copy: string,
    color?: string,
    href: string,
    icon?: boolean | ReactNode,
    name?: string,
    onClick?: (e: { preventDefault(): unknown; target: any; }) => void,
    size?: string,
    target?: string,
    type?: string,
};

/**
* Represents a Link.
* @constructor
* @param {string} className
* @param {string} copy
* @param {string} color
* @param {string} href
* @param {boolean | Node} icon
* @param {string} name
* @param {string} size
* @param {string} target
* @param {string} type
* Usage :
* ````js
* <Link className='my--link' copy='My link copy' />
* ````
* @augments {Component<Props, State>}
*/
/** */

const Link: React.FC<Props> = ({ className, color, copy, icon, href, onClick = () => {}, size, target }: Props) => (
  <a
    className={`
      link
      ${className || ''}
      ${color ? `link--${color}` : ''}
      ${size ? `link--${size}` : ''}
    `}
    href={href}
    target={target}
    onClick={onClick}
  >
    {copy}
    {icon
      ? (React.isValidElement(icon) ? icon : <Icon.ExternalLink />)
      : null}
  </a>
);

export default Link;
