import React from 'react';

import ColumnChart from '../../components/ColumnChart/ColumnChart';
import Icon from '../../components/Icon/Icon';
import Text from '../../components/Text/Text';
import { Tabs } from '../../components';

import './explore-prototype.scss';

const Explore: React.FC = () => {
  const seriesData = [
    {
      name: 'Rate1',
      data: [160, 150, 100, 90, 160, 200, 250, 330, 350, 250, 200, 220],
      color: 'transparent',
      borderColor: '#000000',
      borderTop: true,
      borderWidth: 0
    },
    {
      name: 'Increase',
      data: [160, 150, 100, 90, 160, 200, 250, 330, 350, 250, 210, 200],
      color: '#ED6E78'
    },
    {
      name: 'Key',
      data: [160, 150, 100, 90, 160, 200, 250, 330, 350, 250, 200, 180],
      color: '#D3A600'
    },
    {
      name: 'Key',
      data: [140, 130, 70, 60, 130, 180, 200, 300, 330, 230, 180, 150],
      color: '#F1BD05'
    },
    {
      name: 'Key',
      data: [40, 30, 20, 30, 40, 50, 60, 70, 80, 90, 70, 60],
      color: '#FFD027'
    },
    {
      name: 'Key',
      data: [30, 20, 10, 20, 30, 40, 50, 60, 70, 60, 50, 40],
      color: '#FCECB0'
    }
  ];

  const plotOptions = {
    pointWidth: 50,
    column: {
      grouping: false
    },
    series: {
      pointPadding: 0.18,
      groupPadding: 0,
      borderWidth: 0,
      shadow: false
    }
  };

  const xAxis = {
    top: 0,
    categories: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
  };

  const yAxis = {
    opposite: false,
    visible: false,
    min: 0,
    tickInterval: 0.15
  };

  const chartWidth = 295;

  const tabs = [
    {
      label: 'Cost'
    },
    {
      label: 'Usage'
    }
  ];

  const aspen = '#EFECE5';
  const chartHeight = 288;

  return (
    <div className='explore--page flex jus--center width--100-p'>
      <div className='bor--t-1 mar--t-60 min-width--lg pad--t-24'>
        <div
          className='flex link flex--align-center mar--b-24'
        >
          <Icon.ArrowLeft />
          <Text
            className='body--md text--bold link'
          >
            New Project
          </Text>
        </div>

        <Text
          className='headline--lg text--bold pad--b-24'
          variant='h1'
        >
            Explore
        </Text>

        <Text
          className='body--xl text--bold pad--b-24'
          variant='h1'
        >
            Rates comparison
        </Text>
        <div className='bor--1 bor-radius--t-l bor-radius--t-r pad--b-12 pad--l-12 pad--t-12'>
          <Tabs className='tabs stone' tabs={tabs} />
        </div>
        <div>
          <div className="chart bor--b-1-black flex">
            <div className='legend--container pad--t-128 pad--l-24 pad--r-156'>
              <div className="legend">
                <div className="legend-item flex flex--align-center">
                  <span className='legend-dot mar--r-12 temper' />
                  <Text>Increase</Text>
                </div>
                <div className="legend-item flex flex--align-center">
                  <span className='legend-bar mar--r-12' />
                  <Text>Rate1</Text>
                </div>
                <div className="legend-item flex flex--align-center">
                  <span className='legend-dot mar--r-12 dwindle' />
                  <Text>Key</Text>
                </div>
                <div className="legend-item flex flex--align-center">
                  <span className='legend-dot mar--r-12 watt' />
                  <Text>Key</Text>
                </div>
                <div className="legend-item flex flex--align-center">
                  <span className='legend-dot mar--r-12 glow' />
                  <Text>Key</Text>
                </div>
                <div className="legend-item flex flex--align-center">
                  <span className='legend-dot mar--r-12 beacon' />
                  <Text>Key</Text>
                </div>
              </div>
            </div>
            <ColumnChart
              xAxis={xAxis}
              yAxis={yAxis}
              backgroundColor={aspen}
              plotBackgroundColor={aspen}
              plotOptions={plotOptions}
              series={seriesData}
              height={chartHeight}
              width={chartWidth}
            />
            <ColumnChart
              xAxis={xAxis}
              yAxis={yAxis}
              backgroundColor={aspen}
              plotBackgroundColor={aspen}
              plotOptions={plotOptions}
              series={seriesData}
              height={chartHeight}
              width={chartWidth}
            />
            <ColumnChart
              xAxis={xAxis}
              yAxis={yAxis}
              backgroundColor={aspen}
              plotBackgroundColor={aspen}
              plotOptions={plotOptions}
              series={seriesData}
              height={chartHeight}
              width={chartWidth}
            />
            <ColumnChart
              xAxis={xAxis}
              yAxis={yAxis}
              backgroundColor={aspen}
              plotBackgroundColor={aspen}
              plotOptions={plotOptions}
              series={seriesData}
              height={chartHeight}
              width={chartWidth}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Explore;
