import React from 'react';

// COMPONENTS
import { ExpandableSection } from 'components';

// ASSETS
import styles from './VirtualMeterInsights.module.scss';
// import InsightsScenario from '../InsightsScenario/InsightsScenario';
// import InsightsImpacted from '../InsightsImpacted/InsightsImpacted';
// import InsightsDonutChart from '../InsightsDonutChart/InsightsDonutChart';

const VirtualMeterInsights = () => {
  return (
    <div className={styles.main}>
      <ExpandableSection title="Delivery point mapping">
        {/* <div className={styles.container}>
          <div>
            <InsightsScenario/>
          </div>
          <div>
            <InsightsImpacted
              cost="-$17,003,280"
              accounts="50,605"
            />
          </div>
          <div>
            <InsightsImpacted
              cost="+$25,888,008"
              accounts="190,353"
              negative
            />
          </div>
          <div>
            <InsightsDonutChart/>
          </div>
        </div> */}
      </ExpandableSection>
    </div>
  );
};

export default VirtualMeterInsights;
