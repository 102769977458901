import React from 'react';

// COMPONENTS
import Icon from 'components/Icon/Icon';
import Box from 'components/Box/Box';
import Tooltip from 'components/Tooltip/Tooltip';
import OptionalIcon from './OptionalIcon';

// ASSETS
import styles from './InputLabel.module.scss';

interface InputLabelProps {
  children?: React.ReactNode,
  infoMessage?: string,
  description?: string,
  isOptional?: boolean,
}

const InputLabel:React.FC<InputLabelProps> = ({ children, infoMessage, isOptional, description }) => {
  // RENDERS
  const renderInfoMessage = () => {
    if (infoMessage) {
      return (
        <Box mr={0.5} display="flex">
          <Tooltip
            copy={infoMessage}
          >
            <Icon.Info size={16} className="info-open-button" />
          </Tooltip>
        </Box>
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={`flex ${styles.label}`} data-element="label">
        <Box mr={0.5}>{children}</Box>
        {renderInfoMessage()}
        {isOptional && (<div className={styles.optional}><OptionalIcon fontSize="inherit" /><span>optional</span></div>)}
      </div>
      {description && <div className={styles['info-description']}>{description}</div>}
    </div>
  );
};

export default InputLabel;
