import moment from 'moment';

export const calculateYears = (
  billingHistory: any,
  // setBillsPerYear: any,
  setBillOverviewChart: any,
  billOverviewChart: any,
  selectedYear: any
) => {
  const startYear = moment(billingHistory[billingHistory.length - 1].start).year();
  const endYear = moment(billingHistory[1].end).year();
  const yearsBetween = [];
  for (let year = startYear; year <= endYear; year++) {
    yearsBetween.push(year);
  }
  // setYearsBetween(yearsBetween);
  const billsPerYear: any = {};
  yearsBetween.forEach(year => {
    const billsInYear = billingHistory.filter((bill: any) => moment(bill.end).year() === year);
    if (!billsPerYear[year]) {
      billsPerYear[year] = {
        bills: [],
        usage: [],
        period: []
      };
    }
    billsInYear.forEach((bill: any) => {
      if (bill.start) {
        billsPerYear[year].period.push(bill.start + ' - ' + bill.end);
        billsPerYear[year].usage.push({ value: bill.usage, name: bill.usage.toLocaleString(), billID: bill.id });
      }
    });
    billsPerYear[year].period.reverse();
    billsPerYear[year].usage.reverse();
    billsPerYear[year].bills = billsInYear;
  });
  // setBillsPerYear(billsPerYear);

  const newBillOverviewChart: any = { ...billOverviewChart };
  newBillOverviewChart.series[0].data = billsPerYear[selectedYear].usage.map((item: any) => item.value);
  newBillOverviewChart.xAxis.categories = billsPerYear[selectedYear].period;
  setBillOverviewChart(newBillOverviewChart);
};
