import React from 'react';

// COMPONENTS
import UsagePeriodSelection from '../UsagePeriodSelection/UsagePeriodSelection';
import BillOverviewChart from '../BillOverviewChart/BillOverviewChart';
import UsageDetailsChart from '../UsageDetailsChart/UsageDetailsChart';

// HELPERS
import { useBillingHistory } from 'api/billing/billing.api';
import UsageDetailsInfo from '../UsageDetailsInfo/UsageDetailsInfo';

// ASSETS
import styles from './Usage.module.scss';

const Usage = () => {
  const { data: dataBillingHistory, isLoading } = useBillingHistory();

  return (
    <div>
      {(!isLoading && dataBillingHistory) && (
        <>
          <UsagePeriodSelection data={dataBillingHistory} />
          <BillOverviewChart data={dataBillingHistory} />
          <div className={styles['usage-details']}>
            <div className={styles['usage-details__header']}>
              <span>Usage Details</span>
            </div>
            <div className={styles['usage-details__body']}>
              <UsageDetailsChart data={dataBillingHistory} />
              <UsageDetailsInfo/>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Usage;
