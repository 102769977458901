export const modifierKeyLabel = [
  {
    modifier: 'solar',
    label: 'Solar panels'
  },
  {
    modifier: 'ev',
    label: 'Electric vehicles'
  },
  {
    modifier: 'battery',
    label: 'Battery storage'
  },
  {
    modifier: 'heatPump',
    label: 'HVAC heat pump'
  },
  {
    modifier: 'waterHeater',
    label: 'Heat pump water heater'
  },
  {
    modifier: 'dryer',
    label: 'Electric dryer'
  },
  {
    modifier: 'range',
    label: 'Electric range'
  }
];
