import React, { useState } from 'react';

// COMPONENTS
import BrandIcon from 'components/BrandIcon/BrandIcon';
import Icon from 'components/Icon/Icon';
import Text from 'components/Text/Text';
import Logo from 'components/Logo/Logo';

// STYLE
import './prime-navigation.scss';

interface NavigationItemProps {
  active: number;
  item: any;
  setActive: (active: number) => void;
  setExpanded: (isExpanded: boolean) => void;
  setSubNavItem: (subNavItems: number) => void;
  setHovered: (isHovered: boolean) => void;
}

const NavigationItem: React.FC<NavigationItemProps> = ({ active, item, setActive, setSubNavItem, setHovered }) => {
  const {
    id,
    icon,
    title
  } = item;
  const [brandIconHover, setBrandIconHover] = useState(false);
  return (
    <div className={
      `brand-icon-wrapper bor--r-6 mar--b-16 mar--l-24 mar--r-24 jus--center cursor--pointer
      ${active === id && ' nav-active shadow--sm'
    }`
    }>
      <div
        className={'brand-icon-container flex flex--align-center'}
        onClick={() => {
          setSubNavItem(0);
          setActive(id);

          // Just for prototyping purposes
          // Remove once componentized
          window.scrollTo(0, 0);
        }}
        role='button'
        tabIndex={0}
        onKeyDown={() => {}}
        onMouseEnter={() => {
          setBrandIconHover(true);
          setHovered(true);
        }}
        onMouseLeave={() => {
          setBrandIconHover(false);
          setHovered(false);
        }}
      >
        <div
          className='pad--l-8 flex flex--align-center'
        >
          <BrandIcon

            color={`${active === id || brandIconHover ? 'full' : 'deep'}`}
            icon={icon}
            height={32}
            width={32}
          />
        </div>
        <div className='app--item'>
          <Text
            className={'pad--l-16 pad--r-24 product--title text--center label-sm'}
            appearance='title-sm'
            color='stone'
          >
            {title}
          </Text>
        </div>
      </div>
    </div>
  );
};

export interface PrimeNavProps {
  navItems: Array<any>;
  isExpanded: boolean;
  setExpanded: (isExpanded: boolean) => void;
  activeState: number;
  setActiveNav: (active: number) => void;
  setSubNavItem: (subNavItems: number) => void;
  subNav: Array<object> | undefined;
}

const PrimeNav: React.FC<PrimeNavProps> = ({
  navItems,
  isExpanded,
  setExpanded,
  activeState,
  setActiveNav,
  setSubNavItem,
  subNav
}) => {
  const [isHovered, setHovered] = useState(false);
  const renderArrow = () => {
    return (
      <div
        className='desktop--expand-menu-button bor--1 cursor--pointer transition--left flex jus--center flex--align-center'
        aria-roledescription='expand menu'
        role='button'
        onKeyDown={() => {}}
        onClick={() => setExpanded(!isExpanded)}
        tabIndex={0}
        style={{ left: isExpanded ? '370px' : '84px' }}
      >
        <Icon.ChevronRight
          className='transition--rotate'
          style={{ rotate: `${isExpanded ? '180deg' : '0deg'}` }}
          height={16}
          width={16}
        />
      </div>
    );
  };

  return (
    <div className='prime-nav--wrapper'>
      <div className='prime-nav--container'>
        <div className='bor--r-1 bg--white flex flex--align-center jus--center gridx--logo-container shadow--sm'>
          <Logo
            className='dis--inline-block'
            name='gridx'
            size='sm'
          />
        </div>
        <div className='height--100-p bg--white width--100-p'>
          <div className={
            `height--100-p bor--t-1 bor--r-1 pad--t-24 prime-nav
              ${isHovered && 'expand-menu'}
          `}>
            {navItems.map((item: any, index: number) => (
              <NavigationItem
                active={activeState}
                setActive={setActiveNav}
                setExpanded={setExpanded}
                key={index} item={item}
                setSubNavItem={setSubNavItem}
                setHovered={setHovered}
              />
            ))}
          </div>
        </div>
      </div>
      <div
        className='arrow--container'
      >
        {subNav && renderArrow()}
      </div>
    </div>
  );
};

export default PrimeNav;
