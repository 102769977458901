import React from 'react';

// COMPONENTS
import Skeleton from 'components/Skeleton/Skeleton';
import Box from 'components/Box/Box';

// eslint-disable-next-line
const GenericSkeleton = (args: any) => {
  return (
    <div>
      <Box mb={4}>
        <Box mb={2}>
          <Skeleton variant="rectangular" height={16} width={369} />
        </Box>
        <Box mb={2}>
          <Skeleton variant="rectangular" height={16} />
        </Box>
        <Box mb={2}>
          <Skeleton variant="rectangular" height={16} />
        </Box>
        <Skeleton variant="rectangular" height={16} />
      </Box>
      <Box mb={4}>
        <Box mb={2}>
          <Skeleton variant="rectangular" height={16} width={369} />
        </Box>
        <Box mb={2}>
          <Skeleton variant="rectangular" height={16} />
        </Box>
        <Box mb={2}>
          <Skeleton variant="rectangular" height={16} />
        </Box>
        <Skeleton variant="rectangular" height={16} />
      </Box>
      <Box>
        <Box mb={2}>
          <Skeleton variant="rectangular" height={16} width={369} />
        </Box>
        <Box mb={2}>
          <Skeleton variant="rectangular" height={16} />
        </Box>
        <Box mb={2}>
          <Skeleton variant="rectangular" height={16} />
        </Box>
        <Skeleton variant="rectangular" height={16} />
      </Box>
    </div>
  );
};

export default GenericSkeleton;
