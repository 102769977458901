import React from 'react';

// COMPONENTS
import { Select } from '../../../../../../../../components';

const TimeOfUseSelects = () => {
  return (
    <>
      <Select
        label='Rate code'
        options={[{ value: 1, label: 'Option 1' }, { value: 2, label: 'Option 2' }]}
        defaultValue={1}
      />
      <Select
        label='Rate option'
        options={[{ value: 1, label: 'Option 1' }, { value: 2, label: 'Option 2' }]}
        defaultValue={1}
      />
      <Select
        label='Day the week'
        options={[{ value: 1, label: 'Option 1' }, { value: 2, label: 'Option 2' }]}
        defaultValue={1}
      />
    </>
  );
};

export default TimeOfUseSelects;
