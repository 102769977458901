import { useQuery } from '@tanstack/react-query';

// DATA
import dataAccounts from './mocks/accounts.json';
import dataMeterAccounts from './mocks/meter-accounts.json';
import dataAttributes from './mocks/account-billing-attributes.json';
import dataCustomerExceptions from './mocks/customer-exceptions.json';
import dataMapping from './mocks/mapping.json';

// TYPINGS
import { IAccount } from './accounts.typings';
import { DataQueryParam } from 'api/helpers/api.typings';

// HELPERS
import { filterQueryData } from 'api/helpers/filterQueryData';

export const useAccounts = (queryParams: DataQueryParam<IAccount>) => {
  return useQuery({
    queryKey: ['accounts'],
    queryFn: () => {
      return dataAccounts;
    },
    select: filterQueryData(queryParams, 'serviceAgreement')
  });
};

export const useSingleAccount = (id: string | number) => {
  return useQuery({
    queryKey: ['single-account'],
    queryFn: () => {
      return dataAccounts.filter((account) => account.id === Number(id))[0];
    }
  });
};

export const useAttributes = () => {
  return useQuery({
    queryKey: ['attributes'],
    queryFn: () => {
      return dataAttributes;
    }
  });
};

export const useCustomerExceptions = () => {
  return useQuery({
    queryKey: ['customer-exceptions'],
    queryFn: () => {
      return dataCustomerExceptions;
    }
  });
};

export const useMeterAccounts = () => {
  return useQuery({
    queryKey: ['meter-accounts'],
    queryFn: () => {
      return dataMeterAccounts;
    }
  });
};

export const useSingleMeterAccount = (id: string | number) => {
  return useQuery({
    queryKey: ['single-meter-accounts'],
    queryFn: () => {
      return dataMeterAccounts.filter((account) => account.id === Number(id))[0];
    }
  });
};

export const useMapping = () => {
  return useQuery({
    queryKey: ['mapping'],
    queryFn: () => {
      return dataMapping;
    }
  });
};
