import { Children, cloneElement, Component, EventHandler, MouseEvent as ReactMouseEvent, ReactElement } from 'react';
import { idGenerator } from 'utils';

export interface IEvent extends Event {
  euiGeneratedBy: string[];
}

interface IProps {
  children: ReactElement<any>;
  onOutsideClick: (event: IEvent) => void;
  isDisabled?: boolean;
  onMouseDown?: (event: ReactMouseEvent<any, IEvent>) => void;
  onMouseUp?: (event: ReactMouseEvent<any, IEvent>) => void;
  onTouchStart?: (event: ReactMouseEvent<any, IEvent>) => void;
  onTouchEnd?: (event: ReactMouseEvent<any, IEvent>) => void;
}

export default class OutsideClickDetector extends Component<IProps> {
  private id: string;

  private capturedDownIds: string[];

  constructor (props: IProps) {
    super(props);

    this.id = idGenerator('outside')();

    this.capturedDownIds = [];
  }

  onClickOutside: EventHandler<any> = (event: IEvent) => {
    const { isDisabled, onOutsideClick } = this.props;

    if (isDisabled) {
      this.capturedDownIds = [];
      return;
    }

    if ((event.euiGeneratedBy && event.euiGeneratedBy.includes(this.id)) || this.capturedDownIds.includes(this.id)) {
      this.capturedDownIds = [];
      return;
    }

    this.capturedDownIds = [];
    return onOutsideClick(event);
  };

  componentDidMount () {
    document.addEventListener('mouseup', this.onClickOutside);
    document.addEventListener('touchend', this.onClickOutside);
  }

  componentWillUnmount () {
    document.removeEventListener('mouseup', this.onClickOutside);
    document.removeEventListener('touchend', this.onClickOutside);
  }

  onChildClick = (event: ReactMouseEvent<any, IEvent>, cb: (event: ReactMouseEvent<any, IEvent>) => void) => {
    // to support nested click detectors, build an array
    // of detector ids that have been encountered
    if (Object.prototype.hasOwnProperty.call(event.nativeEvent, 'euiGeneratedBy')) {
      event.nativeEvent.euiGeneratedBy.push(this.id);
    } else {
      event.nativeEvent.euiGeneratedBy = [this.id];
    }
    if (cb) cb(event);
  };

  onChildMouseDown = (event: ReactMouseEvent<any, IEvent>) => {
    this.onChildClick(event, (e) => {
      this.capturedDownIds = e.nativeEvent.euiGeneratedBy;
      if (this.props.onMouseDown) this.props.onMouseDown(e);
      if (this.props.onTouchStart) this.props.onTouchStart(e);
    });
  };

  onChildMouseUp = (event: ReactMouseEvent<any, IEvent>) => {
    this.onChildClick(event, (e) => {
      if (this.props.onMouseUp) this.props.onMouseUp(e);
      if (this.props.onTouchEnd) this.props.onTouchEnd(e);
    });
  };

  render () {
    const props = {
      ...this.props.children.props,
      ...{
        onMouseDown: this.onChildMouseDown,
        onTouchStart: this.onChildMouseDown,
        onMouseUp: this.onChildMouseUp,
        onTouchEnd: this.onChildMouseUp
      }
    };

    const child = Children.only(this.props.children);
    return cloneElement(child, props);
  }
}
