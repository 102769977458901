
// ASSETS
import BatteryThumbnail from '../../assets/modifier-icons/battery-thumbnail.png';
import SolarThumbnail from '../../assets/modifier-icons/solar-thumbnail.png';
import EvThumbnail from '../../assets/modifier-icons/electric-vehicle-thumbnail.png';
import HeatPumpThumbnail from '../../assets/modifier-icons/climate-thumbnail.png';
import WaterHeaterThumbnail from '../../assets/modifier-icons/heater-thumbnail.png';
import DryerThumbnail from '../../assets/modifier-icons/dryer-thumbnail.png';
import RangeThumbnail from '../../assets/modifier-icons/range-thumbnail.png';

import ModifierSolar from '../../assets/modifier-images/modifier-solar.jpg';
import ModifierEv from '../../assets/modifier-images/modifier-ev.jpg';
import ModifierBattery from '../../assets/modifier-images/modifier-battery.jpg';
import ModifierClimate from '../../assets/modifier-images/modifier-climate.jpg';
import ModifierHeater from '../../assets/modifier-images/modifier-heater.jpg';
import ModifierDryer from '../../assets/modifier-images/modifier-dryer.jpg';
import ModifierRanger from '../../assets/modifier-images/modifier-range.jpg';

// HELPERS
import { Modifier } from '../types';
import { Resource, ResourceEV, ResourceSolar } from 'widget/ExploreWidget/api/projects.typings';

export const getModifierIcon = (modifier: Modifier) => {
  switch (modifier) {
  case 'solar':
    return SolarThumbnail;
  case 'ev':
    return EvThumbnail;
  case 'battery':
    return BatteryThumbnail;
  case 'heatPump':
    return HeatPumpThumbnail;
  case 'waterHeater':
    return WaterHeaterThumbnail;
  case 'dryer':
    return DryerThumbnail;
  case 'range':
    return RangeThumbnail;
  default:
    return SolarThumbnail;
  }
};

export const getModifierImage = (modifier: Modifier) => {
  switch (modifier) {
  case 'solar':
    return ModifierSolar;
  case 'ev':
    return ModifierEv;
  case 'battery':
    return ModifierBattery;
  case 'heatPump':
    return ModifierClimate;
  case 'waterHeater':
    return ModifierHeater;
  case 'dryer':
    return ModifierDryer;
  case 'range':
    return ModifierRanger;
  default:
    return SolarThumbnail;
  }
};

export const getModifierTitle = (modifier: Modifier) => {
  switch (modifier) {
  case 'solar':
    return 'Solar panels';
  case 'ev':
    return 'Electric vehicle';
  case 'battery':
    return 'Battery storage';
  case 'heatPump':
    return 'HVAC heat pump';
  case 'waterHeater':
    return 'Heat pump water heater';
  case 'dryer':
    return 'Electric dryer';
  case 'range':
    return 'Electric range';
  }
};

export const getModifierKey = (modifier: string): Modifier => {
  switch (modifier) {
  case 'solarChannel':
    return 'solar';
  default:
    return modifier as Modifier;
  }
};

const buildSolarTags = (resource: Resource) => {
  const orientationAngle = {
    180: 'South',
    270: 'West'
  };
  const solarTags = [];
  const resourceSolar = resource as ResourceSolar;
  solarTags.push(`${parseFloat(resourceSolar.attributes.capacityField).toFixed(1)} kW capacity`);
  solarTags.push(`${orientationAngle[resourceSolar.attributes.azimuth as keyof object]} Orientation`);
  solarTags.push(`${resourceSolar.attributes.zipcode} Zip Code`);

  return solarTags;
};

const buildEvTags = (resource: Resource) => {
  const evResource = resource as ResourceEV;

  const make = evResource.attributes.make;
  const model = evResource.attributes.model;
  const year = evResource.attributes.year || '';
  const milesPerWeekDay = evResource.attributes.weekdayDistance;
  const milesPerWeekEnd = evResource.attributes.weekendDistance;

  const evTags = [];
  if (make) {
    const carTag = `${make.charAt(0).toUpperCase() + make.slice(1)} ${model.charAt(0).toUpperCase() + model.slice(1)} ${year}`;
    evTags.push(carTag);
  }

  if (milesPerWeekDay) {
    evTags.push(`${milesPerWeekDay} mi/wk`);
  }

  if (milesPerWeekEnd) {
    evTags.push(`${milesPerWeekEnd} mi/wknd`);
  }
  return evTags;
};

const buildHeatPumpTags = (resource: any) => {
  const homeYear = resource.attributes.homeYearField;
  const homeSize = resource.attributes.homeSizeField;
  const heatingType = resource.attributes.heatingType;
  return [homeYear, `${homeSize} sq ft`, heatingType];
};

const buildBatteryTags = (resource: any) => {
  const capacity = resource.attributes.capacityField;
  const resiliency = resource.attributes.resiliencyField;
  return [`Rated capacity: ${capacity}`, `Resiliency: ${resiliency}%`];
};

const buildWaterHeatTags = (resource: any) => {
  const occupants = resource.attributes.occupantsField;
  const waterHeater = resource.attributes.waterHeaterField;
  return [`Occupants: ${occupants}`, waterHeater];
};

const buildDryerTags = (resource: any) => {
  const dryerType = resource.attributes.dryerTypeField;
  const occupants = resource.attributes.occupantsField;
  const compareTo = resource.attributes.compareToField;
  return [`Occupants: ${occupants}`, dryerType, `Compare to: ${compareTo}`];
};

const buildRangeTags = (resource: any) => {
  const rangeType = resource.attributes.rangeTypeField;
  const occupants = resource.attributes.occupantsField;
  const compareTo = resource.attributes.compareToField;
  return [`Occupants: ${occupants}`, rangeType, `Compare to: ${compareTo}`];
};

export const getModifierTags = (resource: Resource) => {
  const lowerCaseResourceName = resource.name.toLowerCase();
  if (lowerCaseResourceName.includes('solar')) {
    return buildSolarTags(resource);
  } else if (lowerCaseResourceName.includes('ev')) {
    return buildEvTags(resource);
  } else if (lowerCaseResourceName.includes('battery')) {
    return buildBatteryTags(resource);
  } else if (lowerCaseResourceName.includes('water')) {
    return buildWaterHeatTags(resource);
  } else if (lowerCaseResourceName.includes('heat')) {
    return buildHeatPumpTags(resource);
  } else if (lowerCaseResourceName.includes('dryer')) {
    return buildDryerTags(resource);
  } else if (lowerCaseResourceName.includes('range')) {
    return buildRangeTags(resource);
  } else {
    return [];
  }
};
