import React, { useEffect } from 'react';
import MuiChip from '@mui/material/Chip';
import classnames from 'classnames';

// ASSETS
import styles from './Chip.module.scss';
import Tooltip from '@mui/material/Tooltip';

interface ChipProps {
  icon?: React.ReactElement;
  label: string;
  type?: 'info' | 'success' | 'warning' | 'error' | 'brand';
  variant?: 'outlined' | 'filled';
  backgroundColor?: string;
  borderColor?: string;
  color?: string;
  showTooltip?: boolean;
}

const Chip: React.FC<ChipProps> = ({
  icon,
  label,
  backgroundColor,
  borderColor,
  color,
  type,
  variant,
  showTooltip
}) => {
  const chipRoot = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chipRoot.current) {
      if (backgroundColor) {
        chipRoot.current.style.setProperty('background-color', backgroundColor);
      }

      if (borderColor) {
        chipRoot.current.style.setProperty('border', `2px solid ${borderColor}`);
      }

      if (color) {
        chipRoot.current.style.setProperty('color', color);
      }
    }
  }, [backgroundColor, color]);

  // STYLES
  const cssChipRoot = classnames(styles.root, styles[`root__type-${type}`], styles[`root__variant-${variant}`]);

  const renderChip = () => (
    <MuiChip
      ref={chipRoot}
      icon={icon}
      label={label}
      classes={{
        root: cssChipRoot,
        label: styles.label,
        icon: styles.icon
      }}
    />
  );

  return showTooltip ? <Tooltip title={label}>{renderChip()}</Tooltip> : renderChip();
};

export default Chip;
